import React, { useRef, useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import { Accordion, AccordionTab } from "primereact/accordion";
import { OverlayPanel } from "primereact/overlaypanel";

import QualitativeData from "./QualitativeData";
import APIServices from "../service/APIService";
import { API } from "../constants/api_url";
import { use } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import moment from "moment";
const LCAResponsePortal = () => {
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const [rawsitelist, setRawSitelist] = useState([]);


    const [assignedData, setAssignedData] = useState([]);
    const [categoryData, setCategoryData] = useState([
        {
            "id": 2,
            "name": "Environment",
            "created": null,
            "qTopics": [
                {
                    "id": 2,
                    "name": "Management of water-related issues",
                    "created": null,
                    "qCategoryId": 2,
                    "qSections": [
                        {
                            "id": 3,
                            "name": "Policy & Commitment",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 87
                        },
                        {
                            "id": 4,
                            "name": "Water Risk Assessment",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 88
                        },
                        {
                            "id": 5,
                            "name": "Efficiency & Reduction Initiatives",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 89
                        },
                        {
                            "id": 6,
                            "name": "Wastewater Treatment & Discharge",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 90
                        },
                        {
                            "id": 7,
                            "name": "Water Stewardship & Community Engagement",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 91
                        },
                        {
                            "id": 8,
                            "name": "Targets & Performance Tracking",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 92
                        },
                        {
                            "id": 9,
                            "name": "Water accounting or auditing performed",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 93
                        },
                        {
                            "id": 10,
                            "name": "Technologies or practices to recycle or reuse water",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 94
                        },
                        {
                            "id": 11,
                            "name": "Product Water footprint ",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": 95
                        },
                        {
                            "id": 13,
                            "name": "X",
                            "created": null,
                            "qTopicId": 2,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 9,
                    "name": "Management of waste-related Impacts",
                    "created": null,
                    "qCategoryId": 2,
                    "qSections": [
                        {
                            "id": 51,
                            "name": "Policy & Procedure",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 52,
                            "name": "Management of waste related impacts",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 53,
                            "name": "Hazardous Waste Handling & Transport",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 54,
                            "name": "Material Efficiency & Waste Reduction",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 55,
                            "name": "Hazardous Substance Management",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 56,
                            "name": "Waste Reduction & Recycling Technologies",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 57,
                            "name": "Employee Awareness & Waste Segregation",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 58,
                            "name": "Waste Stream Mapping & Control",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 59,
                            "name": "Extended Producer Responsibility",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        },
                        {
                            "id": 60,
                            "name": "Waste Reduction Targets",
                            "created": null,
                            "qTopicId": 9,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 10,
                    "name": "Climate Change",
                    "created": null,
                    "qCategoryId": 2,
                    "qSections": [
                        {
                            "id": 61,
                            "name": "Environmental policy & certifications",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 62,
                            "name": "Process for Identifying of Risks and Opportunities",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 63,
                            "name": "Goals and Targets Related to Climate Change",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 64,
                            "name": "Risks and opportunities",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 65,
                            "name": "Transition Planning",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 66,
                            "name": "Reduction of GHG emissions",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        },
                        {
                            "id": 67,
                            "name": "Governance structure of the organization",
                            "created": null,
                            "qTopicId": 10,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 27,
                    "name": "Biodiversity",
                    "created": null,
                    "qCategoryId": 2,
                    "qSections": [
                        {
                            "id": 119,
                            "name": "Policies and Commitments to Halt and Reverse Biodiversity Loss",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        },
                        {
                            "id": 120,
                            "name": "Goals and Targets on Biodiversity Loss",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        },
                        {
                            "id": 121,
                            "name": "Integration and Monitoring of Biodiversity in the Strategy of the Organization",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        },
                        {
                            "id": 123,
                            "name": "Actions taken to reduce impact on biodiversity",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        },
                        {
                            "id": 124,
                            "name": "Activities near biodiversity-sensitive areas",
                            "created": null,
                            "qTopicId": 27,
                            "srfId": null
                        }
                    ]
                }
            ]
        },
        {
            "id": 3,
            "name": "Social",
            "created": null,
            "qTopics": [
                {
                    "id": 11,
                    "name": "Labour & Human Rights Framework",
                    "created": null,
                    "qCategoryId": 3,
                    "qSections": [
                        {
                            "id": 68,
                            "name": "Labour & Human Rights Policies",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 69,
                            "name": "Working Conditions",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 70,
                            "name": "Accessibility of Workspace",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 71,
                            "name": "Social Dialogue",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 72,
                            "name": "Training & Career Management",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 73,
                            "name": "Child & Forced Labour Prevention",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 74,
                            "name": "Diversity, Equity & Inclusion (DE&I)",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        },
                        {
                            "id": 75,
                            "name": "Whistleblowing & Grievance Mechanisms",
                            "created": null,
                            "qTopicId": 11,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 12,
                    "name": "Employee health & safety",
                    "created": null,
                    "qCategoryId": 3,
                    "qSections": [
                        {
                            "id": 76,
                            "name": "Risk Assessment & Preparedness",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 77,
                            "name": "Preventive Measures & Training",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 78,
                            "name": "Subcontractor Health & Safety",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 79,
                            "name": "Occupational Health & Safety Governance",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 80,
                            "name": "Incident Reporting & Investigation",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 81,
                            "name": "Confidentiality of Health Data",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 82,
                            "name": "Access to Medical & Health Services",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        },
                        {
                            "id": 83,
                            "name": "Employees Wellness",
                            "created": null,
                            "qTopicId": 12,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 13,
                    "name": "Corporate Social Responsibility",
                    "created": null,
                    "qCategoryId": 3,
                    "qSections": [
                        {
                            "id": 84,
                            "name": "Social Impact Assessments",
                            "created": null,
                            "qTopicId": 13,
                            "srfId": null
                        },
                        {
                            "id": 85,
                            "name": "CSR Activities",
                            "created": null,
                            "qTopicId": 13,
                            "srfId": null
                        }
                    ]
                }
            ]
        },
        {
            "id": 4,
            "name": "Governance",
            "created": null,
            "qTopics": [
                {
                    "id": 14,
                    "name": "Anti-corruption",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 86,
                            "name": "Anti-Corruption Practices ",
                            "created": null,
                            "qTopicId": 14,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 15,
                    "name": "Anti-competitive practices",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 87,
                            "name": "Anti-competitive policy and actions",
                            "created": null,
                            "qTopicId": 15,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 17,
                    "name": "Information security",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 88,
                            "name": "Information Security & Data Protection",
                            "created": null,
                            "qTopicId": 17,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 18,
                    "name": "Management of Consumers/Customers",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 89,
                            "name": "Consumer Grievances",
                            "created": null,
                            "qTopicId": 18,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 19,
                    "name": "Intellectual Property",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 90,
                            "name": "Details related to Intellectual property rights",
                            "created": null,
                            "qTopicId": 19,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 20,
                    "name": "Member of associations",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 91,
                            "name": "Associations",
                            "created": null,
                            "qTopicId": 20,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 21,
                    "name": "Public policy advocacy",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 92,
                            "name": "Public policy advocacy ",
                            "created": null,
                            "qTopicId": 21,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 22,
                    "name": "Board Independence",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 93,
                            "name": "Board Structure",
                            "created": null,
                            "qTopicId": 22,
                            "srfId": null
                        },
                        {
                            "id": 94,
                            "name": "Leadership",
                            "created": null,
                            "qTopicId": 22,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 23,
                    "name": "Board Diversity & Composition",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 95,
                            "name": "Diversity Policy",
                            "created": null,
                            "qTopicId": 23,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 24,
                    "name": "Executive Compensation & Shareholding",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 96,
                            "name": "Long-Term Incentives",
                            "created": null,
                            "qTopicId": 24,
                            "srfId": null
                        },
                        {
                            "id": 97,
                            "name": "Executive Shareholding",
                            "created": null,
                            "qTopicId": 24,
                            "srfId": null
                        },
                        {
                            "id": 98,
                            "name": "Stock Ownership Requirements",
                            "created": null,
                            "qTopicId": 24,
                            "srfId": null
                        },
                        {
                            "id": 99,
                            "name": "Major Shareholders",
                            "created": null,
                            "qTopicId": 24,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 25,
                    "name": "Materiality Analysis",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 100,
                            "name": "Materiality Determination & Disclosure",
                            "created": null,
                            "qTopicId": 25,
                            "srfId": null
                        },
                        {
                            "id": 101,
                            "name": "ESG Targets & Performance",
                            "created": null,
                            "qTopicId": 25,
                            "srfId": null
                        },
                        {
                            "id": 102,
                            "name": "Public Reporting & Transparency",
                            "created": null,
                            "qTopicId": 25,
                            "srfId": null
                        }
                    ]
                },
                {
                    "id": 26,
                    "name": "Risk Management",
                    "created": null,
                    "qCategoryId": 4,
                    "qSections": [
                        {
                            "id": 103,
                            "name": "Risk Governance",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 104,
                            "name": "Risk Management Processes",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 105,
                            "name": "Risk Oversight & Structure",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 106,
                            "name": "Employee Involvement & Reporting",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 107,
                            "name": "Product Development & Business Operations",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        },
                        {
                            "id": 108,
                            "name": "Business ethics",
                            "created": null,
                            "qTopicId": 26,
                            "srfId": null
                        }
                    ]
                }
            ]
        },
        {
            "id": 5,
            "name": "Supply Chain",
            "created": null
        },
        {
            "id": 6,
            "name": "General Information",
            "qTopics": [
                {"id":28,"name":"General","created":null,"qCategoryId":6,

                    "qSections": [
                        {"id":133,"name":"General","created":null,"qTopicId":28,"srfId":9}
                    ]
                }
            ],
            "created": null
        }
    ]);


    function mergeGroupedSectionData(apiData, localData) {
        const updatedLocalData = {};

        Object.entries(localData).forEach(([categoryName, entries]) => {
            console.log(apiData,categoryName)
            const matchedCategory = apiData.find(cat => cat.name === categoryName);
            if (!matchedCategory) {
                updatedLocalData[categoryName] = entries;
                return;
            }

            updatedLocalData[categoryName] = entries.map(entry => {
                
                const matchedTopic = matchedCategory?.qTopics?.find(topic => topic.name === entry.subHeading);
                if (!matchedTopic) return entry;

                const enrichedData = matchedTopic.qSections.map(section => {
                    const existing = entry.data.find(d => d.name === section.name);
                    return existing || {
                        id: section.id,
                        name: section.name,
                        status: 'Blocked',
                        dueDate: null,
                        isLocked: false,
                        form: [],
                    };
                });

                return {
                    ...entry,
                    data: enrichedData
                };
            });
        });

        return updatedLocalData;
    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";

        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };

    const transformAssignedDataByCategory = (assignedData) => {
        const grouped = {};

        assignedData.forEach((item) => {
console.log(item)

            const title = item.qCategory.name;
            const subHeading = item.qTopic.name;
            const location = getCoverageText(item, rawsitelist) || 'N/A'
            const sectionName = item?.qSection.name;
            const dueDate = new Date(item.due_date).toLocaleDateString('en-GB', {
                day: '2-digit', month: 'short', year: 'numeric'
            });

            const key = `${title}__${subHeading}__${location}`;

            if (!grouped[title]) {
                grouped[title] = {};
            }

            if (!grouped[title][key]) {
                grouped[title][key] = {
                    title,
                    subHeading,
                    location,
                    dataMap: {}, // to map sectionName → status/dueDate
                };
            }

            grouped[title][key].dataMap[sectionName] = {


                name: sectionName,
                status: item.response?.[login_data.id]?.status || "Not Started",
                dueDate: moment.utc(item.due_date).local().format('DD/MM/YYYY'),
                isLocked: false,
                form: item.srf?.data1
                    ? JSON.parse(item.srf.data1).map((field) => {

                        // Safely get the current user's response using their ID
                        const currentUserResponse = item.response?.[`${login_data.id}`];


                        // Safely get the specific field value (e.g., "SDP90_11", etc.)
                        const responseValue = currentUserResponse?.[field.name];


                        if (field.type === "radio-group" && Array.isArray(field.values)) {
                            return {
                                ...field,
                                values: field.values.map(option => ({
                                    ...option,
                                    selected: option.value === responseValue
                                })),
                                value: responseValue || null
                            };
                        }

                        return {
                            ...field,
                            value: responseValue || ""
                        };
                    })
                    : null,
                id: item.id
            };
        });

        // Convert each group into an array of objects with blocked fallback
        const finalGroups = {};

        Object.entries(grouped).forEach(([category, group]) => {
            finalGroups[category] = [];

            Object.values(group).forEach(({ title, subHeading, location, dataMap }) => {
                const allSectionNames = Object.keys(dataMap); // or use master list
                const data = allSectionNames.map((name) => dataMap[name]);

                finalGroups[category].push({ title, subHeading, location, data });
            });
        });

        const merged = mergeGroupedSectionData(categoryData, finalGroups);

        return merged;
    };

    const [groupedData, setGroupedData] = useState({});

    useEffect(() => {
        if (assignedData.length) {
            const grouped = transformAssignedDataByCategory(assignedData);
            console.log(grouped)
            setGroupedData(grouped);
        }
    }, [assignedData]);

    const getAssignedQualitativeData = async () => {


        const response = await APIServices.post(API.GetAssignedQualitative, {
            userId: login_data.id,
            userProfileId: admin_data.id
        });

        if (response.status === 200) {

            setAssignedData(response.data.filter(x=> x.qCategory && x.qTopic && x.qSection && x.srf) );

        }
    };

    useEffect(async () => {
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        const locationData = await APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const shapedSite = locationData?.data?.map((item) => {
            if (item.locationTwos) {
                item.locationTwos = item.locationTwos.filter(
                    (locationTwo) =>
                        locationTwo.locationThrees &&
                        locationTwo.locationThrees.length > 0
                );
            }
            return item;
        })
            .filter((item) => item.locationTwos && item.locationTwos.length > 0);
        setRawSitelist(shapedSite)
        getAssignedQualitativeData();
    }, []);

    const op1 = useRef(null);
    const op2 = useRef(null);


    return (
        <div className="p-4 max-w-4xl mx-auto">
            <Card className="mb-4">
                <h2 className="text-2xl font-semibold">Welcome to the Navigos Qualitative Response Portal</h2>
                <p className="mt-2">
                    You have been identified as a key stakeholder responsible for providing specific qualitative
                    information that is critical to our organization’s sustainability reporting. Your input will be
                    used to meet the requirements of global frameworks such as SBTi, CDP, EcoVadis, and DJSI, and will
                    ultimately shape the organization’s public disclosures. As a key stakeholder, your expertise and
                    insights are vital to ensuring the accuracy and completeness of the data we report, which will be
                    used for external reporting, stakeholder communication, and assurance processes. To ensure the
                    process is smooth and efficient, please follow the provided steps carefully, and remember that
                    timely and accurate data submission is essential to the success of this process.
                </p>


                <h3 className="text-xl font-semibold">Getting Started</h3>
                <p className="mb-4">
                    This page lists out all the Categories and Topics for which your qualitative inputs are required.
                    Click on the hyperlinked section title to open a pop-up window for entering responses.
                </p>

                <Accordion>
                    <AccordionTab header={<span>Understanding the sections and color indicators</span>}>
                        <div className="mt-4">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div className="me-4" style={{ flex: "1 1 200px" }}>
                                    <p className="fw-bold">Greyed-Out Section:</p>
                                    <p className="text-muted">
                                        No input is required from you for this section. It is either not applicable or will be
                                        completed by another user.
                                    </p>
                                </div>
                                <div className="me-4" style={{ flex: "1 1 200px" }}>
                                    <p className="fw-bold">Grey bar</p>
                                    <div className="w-100 mb-2" style={{ height: "7px", backgroundColor: "#CCCED5" }}></div>
                                    <p>
                                        Input is required. This section has not been started or needs additional information from you.
                                    </p>
                                </div>
                                <div className="me-4" style={{ flex: "1 1 200px" }}>
                                    <p className="fw-bold">Yellow bar</p>
                                    <div className="w-100 mb-2" style={{ height: "7px", backgroundColor: "#F5C37B" }}></div>
                                    <p>
                                        This section is In Progress. You or a colleague have started entering data but have not
                                        finalized it.
                                    </p>
                                </div>
                                <div className="me-4" style={{ flex: "1 1 200px" }}>
                                    <p className="fw-bold">Green bar</p>
                                    <div className="w-100 mb-2" style={{ height: "7px", backgroundColor: "#29C76F" }}></div>
                                    <p>
                                        This section is Complete. All required information has been entered and finalized.
                                    </p>
                                </div>
                                <div className="me-4" style={{ flex: "1 1 200px" }}>
                                    <p className="fw-bold">Red bar</p>
                                    <div className="w-100 mb-2" style={{ height: "7px", backgroundColor: "#e63946" }}></div>
                                    <p>
                                        This section is Overdue. The deadline has passed, but it may still be open for input if required
                                        by the administrator or data consolidator.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h5 className="fw-bold">Finding sections</h5>
                                <ul>
                                    <li>
                                        Once all required information is entered and verified for accuracy, click Mark as Complete to
                                        change the status bar to Green.
                                    </li>
                                    <li>
                                        Do note that you will not be able to mark a section as complete if there are missing mandatory
                                        fields or supporting documents that need to be provided for this section.
                                    </li>
                                    <li>
                                        If you need to make changes after marking as complete, click on the section title hyperlink to
                                        open the response form, make necessary changes.
                                    </li>
                                    <li>
                                        However, when you see <i className="pi pi-lock"></i> it is an indication that this section is
                                        Locked for editing. You can still view your submission by clicking the hyperlink, but no further
                                        edits can be made until the next reporting cycle.
                                    </li>
                                    <li>
                                        If a section turns Red, it means the deadline has passed, but it may still be open for input if
                                        required by the administrator or data consolidator.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header={<span>Understanding your role in the process</span>}>
                        <div className="mt-4">
                            <p>
                                As a key stakeholder, your contributions are essential to the organization’s sustainability reporting.
                                By submitting timely and accurate information, you are helping the organization:
                            </p>
                            <ul>
                                <li>Meet its commitments to global sustainability frameworks.</li>
                                <li>Build trust with stakeholders, including investors, customers, and regulators.</li>
                                <li>Demonstrate leadership in our organization’s environmental, social, and governance (ESG) performance.</li>
                            </ul>

                            <p className="fw-bold mt-3">Know more about:</p>
                            <p>
                                Collaboration & transparency
                                <i
                                    className="pi pi-info-circle ms-1 text-primary"
                                    onClick={(e) => op1.current.toggle(e)}
                                    style={{ cursor: "pointer" }}
                                ></i>
                                <OverlayPanel ref={op1} style={{ backgroundColor: "#fff", color: "#000", padding: "15px", borderRadius: "10px", boxShadow: "0px 4px 10px rgba(0,0,0,0.15)" }}>
                                    <h6 className="fw-bold">Collaboration & Transparency</h6>
                                    <p><strong>Shared Access:</strong> This data entry form may be accessed by multiple colleagues simultaneously. Some information may have already been entered by a peer.</p>
                                    <p><strong>Respondent Identification:</strong> The name of the respondent is captured for each response, allowing you to identify who has previously answered a particular question (if applicable).</p>
                                </OverlayPanel>
                            </p>
                            <p>
                                Importance of timely and accurate data
                                <i
                                    className="pi pi-info-circle ms-1 text-primary"
                                    onClick={(e) => op2.current.toggle(e)}
                                    style={{ cursor: "pointer" }}
                                ></i>
                                <OverlayPanel ref={op2} style={{ backgroundColor: "#fff", color: "#000", padding: "15px", borderRadius: "10px", boxShadow: "0px 4px 10px rgba(0,0,0,0.15)" }}>
                                    <h6 className="fw-bold">Importance of Timely and Accurate Data</h6>
                                    <h6 className="fw-bold">Why timeliness matters:</h6>
                                    <ul>
                                        <li>Meeting deadlines ensures the organization can compile and review data without delays.</li>
                                        <li>Late submissions can delay the entire reporting process, impacting the organization’s ability to meet external reporting deadlines (e.g., CDP, EcoVadis).</li>
                                        <li>Timely data submission reflects positively on the organization’s commitment to sustainability and accountability.</li>
                                    </ul>
                                    <h6 className="fw-bold">Why accuracy matters:</h6>
                                    <ul>
                                        <li>The data you provide will be used for public disclosures and external assurance. Inaccurate or incomplete data can lead to reputational risks, regulatory challenges, or loss of stakeholder trust.</li>
                                        <li>Accurate data ensures the organization’s sustainability performance is represented factually and transparently.</li>
                                        <li>Supporting documents (e.g., policies, certifications) must be authentic and verifiable to withstand scrutiny during external audits or assurance processes.</li>
                                    </ul>
                                </OverlayPanel>
                            </p>
                            <h5 className="fw-bold mt-4">Key reminders</h5>
                            <ul>
                                <li>
                                    <strong>Start Early:</strong> Begin entering responses well before the deadline to avoid overdue sections.
                                </li>
                                <li>
                                    <strong>Verify Data:</strong> Double-check all entries and supporting documents for accuracy and completeness.
                                </li>
                                <li>
                                    <strong>Collaborate:</strong> Coordinate with colleagues to ensure consistent and accurate responses.
                                </li>
                            </ul>
                            <Tooltip target=".pi-info-circle" />
                        </div>
                    </AccordionTab>
                </Accordion>


                <div className="d-flex justify-content-between align-items-center mt-3 p-3">
                    <div className="d-flex gap-3 align-items-center">
                        <span className="d-flex align-items-center gap-2">
                            <span className="rounded-circle bg-secondary" style={{ width: "12px", height: "12px" }}></span> Information required
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <span className="rounded-circle bg-warning" style={{ width: "12px", height: "12px" }}></span> Draft
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <span className="rounded-circle bg-success" style={{ width: "12px", height: "12px" }}></span> Data finalised
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <span className="rounded-circle bg-danger" style={{ width: "12px", height: "12px" }}></span> Overdue submission
                        </span>
                    </div>
                    <div className="d-flex gap-4 align-items-center">
                        <span className="d-flex align-items-center gap-2">
                            <i className="pi pi-lock"></i> Locked for submission
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <i className="pi pi-lock-open"></i> Unlocked for updates
                        </span>
                    </div>
                </div>

            </Card>
            <div className="w-100">
                {groupedData["Environment"] && <QualitativeData data={groupedData["Environment"] || []} />}
                {groupedData["Social"] && <QualitativeData data={groupedData["Social"] || []} />}
                {groupedData["Ethics"] && <QualitativeData data={groupedData["Ethics"] || []} />}
                {groupedData["Sustainable Procurement"] && <QualitativeData data={groupedData["Sustainable Procurement"] || []} />}
            </div>

        </div>
    );
};

export default LCAResponsePortal;