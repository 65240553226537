import React, { useEffect, useState } from "react";
import APIServices from "../service/APIService";
import { API } from "../constants/api_url";
import { getDate, getReportingFiscalYearByReportingperiod, getRPLuxon, getRPTextFormat } from "../components/BGHF/helper";
import { useSelector } from "react-redux";
import { tvssection } from "../assets/tvs/js/tvssection";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabMenu } from "primereact/tabmenu";
import { Badge } from "primereact/badge";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import AuditPanel from "../pages/Auditor/AuditPanel";
import { Dialog } from "primereact/dialog";
import SupplierReport from "./SupplierScreen/SupplierReport";
import AuditPanelAdmin from "../pages/Auditor/AuditPanelAdmin";
import ApproveSupplierAction from "./ApproveSupplierAction";
import DealerReviewAction from "./DealerReviewAction";
import ApproveDealerAction from "./ApproveDealerAction";
import { TabView, TabPanel } from 'primereact/tabview';
import { Tooltip } from "primereact/tooltip";



const AIOActionDashboard = () => {

    const [actions, setActions] = useState([])
    const [reload, setReload] = useState(false)
    const [archived, setArchived] = useState([])
    const [dcfList, setDCFList] = useState([])

    const [actionmodal, setActionModal] = useState(false)
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [sourcedialog, setSourceDialog] = useState(false)
    const [selectedIndicator, setSelectedIndicator] = useState(null)

    const [reportdialog, setReportDialog] = useState(false)

    const [actionsbk, setActionsBk] = useState([])
    const [archivedbk, setArchivedBk] = useState([])
    const [activeindex, setActiveIndex] = useState(0)
    const [load, setLoad] = useState(true)
    const [search, setSearch] = useState({ action: '', archive: '' })

    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const allRoles = useSelector((state) => state.user.allRoles);

    console.log(login_data)

    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [rawsitelist, setRawSitelist] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const userList = useSelector((state) => state.userlist.userList);
    const supplierList = useSelector((state) => state.userlist.supplierList);
    const dealerList = useSelector((state) => state.userlist.dealerList);
    const [qnsubmission, setQnSubmission] = useState([]);
    const [qlsubmission, setQlSubmission] = useState([]);
    const [locList, setLocList] = useState({
        country: [],
        city: [],
        location: [],
    });
    const [takeActionData, setTakeActionData] = useState([])
    const [takeActionModal, setTakeActionModal] = useState(false)
    const [dealerActionData, setDealerActionData] = useState([])
    const [dealerReviewModal, setDealerReviewModal] = useState(false)
    const [actionDealerModal, setActionDealerModal] = useState(false)
    const [actionDealerData, setActionDealerData] = useState([])

    useEffect(() => {
        renderData()
    }, [])
    useEffect(() => {
        const handleFocus = () => {
            if (localStorage.getItem("reloadaio")) {

                renderData();

                // Clear the flag to avoid repeated execution
                localStorage.removeItem("reloadaio");
            }
        };

        // Add event listener when component mounts
        window.addEventListener("focus", handleFocus);

        // Cleanup event listener when component unmounts
        return () => {
            window.removeEventListener("focus", handleFocus);
        };
    }, []);
    const renderData = () => {
        try {
            setTakeActionModal(false)
            let uriString = {
                include: [
                    {
                        relation: "locationTwos",
                        scope: { include: [{ relation: "locationThrees" }] },
                    },
                ],
            };
            let uriString2 = {
                include: [
                    {
                        relation: "newTopics",
                        scope: {
                            include: [
                                {
                                    relation: "newMetrics",
                                    scope: { include: [{ relation: "newDataPoints" }] },
                                },
                            ],
                        },
                    },
                ],
            };
            let Overall =
                API.Categories +
                `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
            let audString = {

                "include": [
                    {
                        "relation": "supplierAssignmentSubmission", scope: { fields: { type: true, supplierMSIScore: true, submitted_on: true, id: true } }
                    },
                    {
                        "relation": "supplierActions",
                        scope: { include: ['supplierActionHistories'] }
                    },

                    {
                        "relation": "auditorAssignmentSubmission", scope: { fields: { type: true, approved_on: true, approverComments: true, rejected_on: true, auditorMSIScore: true, submitted_on: true, modified_on: true, id: true } }
                    },
                    {
                        "relation": "vendor"
                    }
                ]

            };

            let dealerAssUri = {
                "include": ['dealer', 'dealerAuditorChecklistSubmission', 'vendor']
            }



            const promise0 = APIServices.get(API.DCF_Title_Only);
            const promise1 = APIServices.get(API.QN_Submit_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify({ include: [{ relation: 'dcf', scope: { fields: { id: true, title: true, type: true, tags: true, categoryId: true, categoryAltId: true, subCategoryDpIds: true, subCategoryOrder: true, calculationDpIds: true, suffix: true } } }], fields: { id: true, reject: true, type: true, edit: true, dcfId: true, entityUserAssId: true, entityAssId: true, tier0_id: true, tier1_id: true, tier2_id: true, tier3_id: true, reporting_period: true, locationId: true, level: true, frequency: true, self: true } }))}`);
            const promise2 = APIServices.get(
                API.LocationOne_UP(admin_data.id) +
                `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
            );
            const promise3 = APIServices.get(API.DCF_Entity_UP(admin_data.id));
            const promise4 = APIServices.get(API.DF_Entity_UP(admin_data.id));
            const promise5 = []
            const promise6 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify({ include: [{ relation: 'dcf', scope: { fields: { id: true, title: true, type: true, tags: true, categoryId: true, categoryAltId: true, subCategoryDpIds: true, subCategoryOrder: true, calculationDpIds: true, suffix: true } } }] }))}`);
            const promise7 = APIServices.get(API.DF_Entity_User_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify({ include: [{ relation: 'df', scope: { fields: { id: true, title: true, type: true, tags: true, categoryId: true, categoryAltId: true, subCategoryDpIds: true, subCategoryOrder: true, calculationDpIds: true, suffix: true } } }] }))}`);
            const promise8 = []
            const promise9 = APIServices.get(API.AssignDCFClient_UP(admin_data.id));
            const promise10 = APIServices.get(Overall);
            const promise11 = APIServices.get(API.GetRole_Up(admin_data.id));
            const promise12 = APIServices.get(API.RF);
            const promise13 = APIServices.get(API.QL_Submit_UP(admin_data.id));
            const promise14 = APIServices.get(API.QL_Approval_UP(admin_data.id));
            const promise15 = APIServices.get(API.SupplierAssessmentAss_Up_Global(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(audString))}`)
            const promise16 = APIServices.get(API.DealerAssessmentAss_Up(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(dealerAssUri))}`)

            const promise17 = APIServices.get(API.Actions)
            const promise18 = APIServices.post(API.GetAssignedQualitative, { userId: login_data.id, userProfileId: admin_data.id });
             const promise19 = APIServices.post(API.Get_AssignedIndicator_approver, { year: DateTime.utc().year, fymonth, userId: login_data.id, userProfileId: admin_data.id });

            Promise.all([
                promise0,
                promise1,
                promise2,
                promise3,
                promise4,
                promise5,
                promise6,
                promise7,
                promise8,
                promise9,
                promise10,
                promise11, promise12, promise13, promise14, promise15, promise16, promise17, promise18, promise19
            ]).then((values) => {
                let qlAssignment = []
                let qnApproverAssignment = values[19].data.list.map(x=> ({...x,formType:9}))
                if (values[18].data.length && values[18].data?.filter(x => x.reporter_ids.includes(login_data.id)).length) {
                    qlAssignment = [{
                        formType: 8, coverage: 'Corporate',
                        name: "Add / Update Qualitative Form", title: "Add / Update Qualitative Form", dueDate: null, currentStatus: 'Update', timeLine: "Due Now", statusCode: 6
                    }]
                }

                let action = values[17].data
                    .filter(item =>
                        item.status === 'Initiated' &&
                        Array.isArray(item.assignedToId) &&
                        item.assignedToId.map(id => Number(id)).includes(login_data.id)
                    )
                    .map(item => ({
                        ...item,
                        statusCode: 6 // Add new key-value
                    }));





                console.log(action)
                console.log('test')


                let loc = values[15].data.filter(i => i.auditStartDate && ((i?.group1?.assessors?.includes(login_data.id) || i?.group2?.assessors?.includes(login_data.id) || i?.group3?.assessors?.includes(login_data.id) || i?.group4?.assessors?.includes(login_data.id))))
                // let loc = values[15].data.filter(i => i.auditStartDate && ((i?.group1?.assessors?.length || i?.group2?.assessors?.length || i?.group3?.assessors?.length || i?.group4?.assessors?.length)))
                // let approved = values[15].data.filter(x => x.auditStartDate && x.assessmentEndDate && x?.auditorAssignmentSubmission?.type >= 1).map(x => ({ ...x,title:  getMSIIDByAssignment(x) + x?.vendor?.supplierName, statusCode: x?.auditorAssignmentSubmission?.type === 2 ? 0 : 6, formType: x?.auditorAssignmentSubmission?.type === 2 ? 4 : 5, currentStatus: x?.auditorAssignmentSubmission?.type === 2 ? 'Approved' : 'Pending Approval' }))
                let actionToApprove = []
                console.log(actionToApprove)
                let approved = []
                // let actionToApprove = values[15].data.filter(x => x.auditStartDate && x.assessmentEndDate && x?.supplierActions && x?.auditorAssignmentSubmission?.type === 2).flatMap(x => x.supplierActions.map(y=>({...y,vendor:y.vendor})) ).filter( x => x.categoryOfFinding === 3 && x.type >= 1 && !x.reject  ).map( x => ( {title:'Approve Action '+x.actionId +' of '+x?.vendor?.supplierName ,formType: 6 , statusCode : x.type === 2 ? 0 :6 ,currentStatus:  x.type === 2 ?  "Approved" :'Pending Approval'   }  ) )
                let dealerApproved = []
                console.log(allRoles)
                if (allRoles.includes(13) || allRoles.includes(20) ||  login_data.role === 'clientadmin') {
                    dealerApproved = values[16].data.filter(x => x.auditStartDate && x?.dealerAuditorChecklistSubmission?.type >= 1).map(x => ({ ...x, title: getMSIIDByAssignment(x) + x?.vendor?.dealerName, statusCode: x?.dealerAuditorChecklistSubmission?.type === 2 ? 0 : 6, formType: 7, currentStatus: x?.dealerAuditorChecklistSubmission?.type === 2 ? 'Approved' : 'Pending Approval' }))

                }
                if (allRoles.includes(12) ||allRoles.includes(18)|| login_data.role === 'clientadmin') {
                    approved = values[15].data.filter(x => x.auditStartDate && x.assessmentEndDate && x?.auditorAssignmentSubmission?.type >= 1).map(x => ({ ...x, title: getMSIIDByAssignment(x) + x?.vendor?.supplierName, statusCode: x?.auditorAssignmentSubmission?.type === 2 ? 0 : 6, formType: x?.auditorAssignmentSubmission?.type === 2 ? 4 : 5, currentStatus: x?.auditorAssignmentSubmission?.type === 2 ? 'Approved' : 'Pending Approval' }))
                    actionToApprove = values[15].data.filter(x => x.auditStartDate && x.assessmentEndDate && x?.supplierActions && x?.auditorAssignmentSubmission?.type === 2).flatMap(x => x.supplierActions.map(y => ({ ...y, vendor: x.vendor }))).filter(x => x.categoryOfFinding === 3 && x.type >= 2 && !x.reject).map(x => ({ title: (x.type === 3 ? 'View ' : 'Approve Action ') + (x.actionId + ' of ' + x?.vendor?.supplierName), formType: 6, statusCode: x.type === 3 ? 0 : 6, currentStatus: x.type === 3 ? "Approved" : 'Pending Approval', ...x }))

                }
                const finished = loc.filter(x => !approved.map(i => i.id).includes(x.id)).filter(x => x?.auditorAssignmentSubmission?.type >= 1).map(x => ({ ...x, statusCode: 0, formType: 4, currentStatus: 'Completed' }))
                console.log(finished, approved)
                const filteredMSI = loc.filter(x => (!x.auditorAssignmentSubmission || (x.auditorAssignmentSubmission && (x.auditorAssignmentSubmission?.type !== 1 && x.auditorAssignmentSubmission?.type !== 2))))

                let quantitative_submitted = values[1].data, qualitative_submitted = values[13].data, assignment = [],
                    qn_submit_list = [],
                    qn_review_list = [],
                    qn_approve_list = [],
                    dcf_list = JSON.parse(JSON.stringify(values[0].data)),
                    rf_list = JSON.parse(JSON.stringify(values[12].data)),

                    quantitative_ass_list = values[6].data,
                    qualitative_ass_list = values[7].data,
                    approveddata = values[14].data;
                let quantitative_entity_list = values[3].data,
                    qualitative_entity_list = values[4].data
                setDCFList(dcf_list)
                for (const x of filteredMSI) {
                    let stat = getOverdueDaysByUTC(x.auditEndDate) >= 0
                        ? 100
                        : getOverdueDaysByUTC(x.auditEndDate) >= -10
                            ? 6 : getOverdueDaysByUTC(x.auditEndDate) >= -15 ? 9
                                : 7
                    x.title = getMSIIDByAssignment(x) + ' for ' + x?.vendor?.supplierName
                    x.dueDate = getDate(x.auditEndDate)
                    x.currentStatus = x?.auditorAssignmentSubmission ? x?.auditorAssignmentSubmission?.type ? x?.auditorAssignmentSubmission?.type === 2 ? 'Approved' : 'Completed' : 'Started' : 'Not Started'
                    x.timeLine = stat === 6 ? 'Due Now' : stat === 9 ? "Due Soon" : stat === 100 ? "Upcoming" : "Overdue"
                    x.formType = 4
                    x.statusCode = stat
                    assignment.push(x)
                }
                let curated_dcf_ids = [], curated_rf_ids = [];
                if (values[9].data && values[9].data.length > 0) {
                    values[10].data.forEach((cat) => {
                        if (cat.newTopics !== undefined) {
                            cat.newTopics.forEach((topic) => {
                                if (
                                    topic.newMetrics !== undefined &&
                                    values[9].data[0].topic_ids.includes(topic.id) &&
                                    (topic.tag === null || parseFloat(topic.tag) === admin_data.id)
                                ) {
                                    topic.newMetrics.forEach((metric) => {
                                        if (
                                            Array.isArray(metric.data1) &&
                                            values[9].data[0].metric_ids.includes(metric.id) &&
                                            metric.data1[0].type !== undefined &&
                                            metric.data1[0].type === 0 &&
                                            (metric.tag === null || metric.tag === admin_data.id)
                                        ) {
                                            if (metric.newDataPoints !== undefined) {
                                                metric.newDataPoints.forEach((dp) => {
                                                    if (
                                                        Array.isArray(dp.data1) &&
                                                        dp.data1.length !== 0 &&
                                                        dp.data1[0].datasource !== null &&
                                                        typeof dp.data1[0].datasource === "number"
                                                    ) {
                                                        let dcf_index = values[0].data.findIndex(
                                                            (i) => i.id === dp.data1[0].datasource
                                                        );
                                                        if (
                                                            !curated_dcf_ids.includes(dp.data1[0].datasource) &&
                                                            dcf_index !== -1
                                                        ) {
                                                            if (
                                                                values[0].data[dcf_index].tags === null ||
                                                                !values[0].data[dcf_index].tags.length ||
                                                                values[0].data[dcf_index].tags.includes(
                                                                    admin_data.id
                                                                )
                                                            ) {
                                                                let dcfIndex = dcf_list.findIndex(
                                                                    (i) => i.id === dp.data1[0].datasource
                                                                );
                                                                if (dcfIndex !== -1) {
                                                                    dcf_list[dcfIndex]["section"] = findKeyById(
                                                                        tvssection,
                                                                        metric.id
                                                                    );
                                                                    console.log(
                                                                        dcf_list,
                                                                        dcfIndex,
                                                                        dp.data1[0].datasource,
                                                                        "xyz"
                                                                    );
                                                                }
                                                                curated_dcf_ids.push(dp.data1[0].datasource);
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        } else if (Array.isArray(metric.data1) && metric.data1.length && (metric.tag === admin_data.id) &&
                                            metric.data1[0].type != null &&
                                            metric.data1[0].type === 1 && metric.data1[0].source === 1 && metric.data1[0].rf && rf_list.map(y => y.id).includes(metric.data1[0].rf)
                                        ) {
                                            let rf_index = rf_list.findIndex(
                                                (i) => i.id === metric.data1[0].rf
                                            );
                                            if (
                                                rf_list[rf_index].tags === null ||
                                                !rf_list[rf_index].tags.length ||
                                                rf_list[rf_index].tags.includes(
                                                    admin_data.id
                                                ) && !curated_rf_ids.includes(metric.data1[0].rf)
                                            ) {
                                                rf_list[rf_index]["section"] = findKeyById(
                                                    tvssection,
                                                    metric.id
                                                );
                                                rf_list[rf_index]['formatted'] = { df: { dfId: metric.data1[0].rf, cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric } }

                                                curated_rf_ids.push(metric.data1[0].rf);
                                            }

                                        }
                                    });
                                }
                            });
                        }
                    });
                }

                const shapedSite = values[2].data
                    .map((item) => {
                        if (item.locationTwos) {
                            item.locationTwos = item.locationTwos.filter(
                                (locationTwo) =>
                                    locationTwo.locationThrees &&
                                    locationTwo.locationThrees.length > 0
                            );
                        }
                        return item;
                    })
                    .filter((item) => item.locationTwos && item.locationTwos.length > 0);
                setQnSubmission(quantitative_submitted);
                setQlSubmission(qualitative_submitted);

                let filtered_qn_ass = quantitative_ass_list.filter(
                    (i) =>
                        curated_dcf_ids.includes(i.dcfId) && dcf_list.map((j) => j.id).includes(i.dcfId) &&
                        checkEnity(i, quantitative_entity_list, shapedSite, "dcfId")
                );
                let filtered_ql_ass = qualitative_ass_list.filter(
                    (i) =>
                        curated_rf_ids.includes(i.dfId) && rf_list.map((j) => j.id).includes(i.dfId) &&
                        checkEnity(i, qualitative_entity_list, shapedSite, "dfId")
                );
                console.log(filtered_ql_ass, filtered_qn_ass)
                for (const item of filtered_qn_ass.filter(x => x.reporter_ids.includes(login_data.id))) {

                    let months = getMonthsBetween(
                        item.start_date,
                        item.end_date,
                        item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency,
                        0,
                        fymonth
                    );
                    let entityName = getCoverageText(item, shapedSite)
                    for (const item2 of months) {

                        let found = quantitative_submitted.find(x => x.dcfId === item.dcfId && x.entityUserAssId === item.id &&
                            x.entityAssId === item.entityAssId &&
                            x.tier0_id === item.tier0_id &&
                            x.tier1_id === item.tier1_id &&
                            x.tier2_id === item.tier2_id &&
                            x.tier3_id === item.tier3_id && getRPTextFormat(x.reporting_period) === item2)
                        if (item.dcfId === 36) {
                            console.log(found)
                        }
                        if (found) {
                            let type = found.type;
                            let reject = found.reject;
                            let status =
                                type === 0 && (!reject)
                                    ? 0
                                    : type === 0 && (reject === 1 || reject === 2)
                                        ? 1
                                        : type === 1 && reject === 1
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null;
                            if (status === 0 || status === 1) {
                                status =
                                    getOverdueDays(item2) >= 0
                                        ? 99
                                        : getOverdueDays(item2) >= -10
                                            ? 6 : getOverdueDays(item2) >= -15 ? 9
                                                : 7;
                            }


                            assignment.push({
                                coverage: entityName, data: found, dcfId: item.dcfId, formType: 1, standard: item.standard, frequency: item.frequency, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: item2, self: item.reviewer_ids.length ? false : true, locationId: item.locationId, level: item.level,
                                reporting_period: item2, name: item.dcf?.title, title: item.dcf?.title + ' for the reporting period of ' + item2 + ' for ' + entityName || '', dueDate: getDueDate(item2), currentStatus: !type && reject ? "Returned" : status === 3 ? 'Under Review' : status === 4 ? 'Under Approval' : status === 5 ? 'Approved' : 'Draft', timeLine: status === 6 ? 'Due Now' : status === 7 ? "Overdue" : status === 9 ? "Duesoon" : "Upcoming", statusCode: status
                            })
                        } else if (!found) {
                            let stat = getOverdueDays(item2) >= 0
                                ? 100
                                : getOverdueDays(item2) >= -10
                                    ? 6 : getOverdueDays(item2) >= -15 ? 9
                                        : 7
                            assignment.push({
                                coverage: entityName, dcfId: item.dcfId, standard: item.standard, formType: 1, frequency: item.frequency, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: item2, self: item.reviewer_ids.length ? false : true, locationId: item.locationId, level: item.level,
                                reporting_period: item2, name: item.dcf?.title, title: item.dcf?.title + ' for the reporting period of ' + item2 + ' for ' + entityName || '', dueDate: getDueDate(item2), currentStatus: 'Not Started', timeLine: stat === 6 ? 'Due Now' : stat === 7 ? "Overdue" : stat === 9 ? "Duesoon" : "Upcoming", statusCode: stat
                            })
                        }

                    }
                }
                for (const item of filtered_ql_ass.filter(x => x.reporter_ids.includes(login_data.id))) {

                    let months = getMonthsBetween(
                        item.start_date,
                        item.end_date,
                        item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency,
                        0,
                        fymonth
                    );
                    let formatted = rf_list.find(x => x.id === item.dfId)?.formatted?.df || null
                    for (const item2 of months) {

                        let index1 = approveddata.find((i) => { return i.dfId === item.dfId && formatted.top_id === i.topicId && formatted.id === i.indicatorId && formatted.cat_id === i.categoryId && getReportingFiscalYearByReportingperiod(getRPLuxon(item2), fymonth) === i.reporting_year })

                        let found = qualitative_submitted.find(x => x.dfId === item.dfId && x.entityUserAssId === item.id &&
                            x.entityAssId === item.entityAssId &&
                            x.tier0_id === item.tier0_id &&
                            x.tier1_id === item.tier1_id &&
                            x.tier2_id === item.tier2_id &&
                            x.tier3_id === item.tier3_id && getRPTextFormat(x.reporting_period) === item2)

                        if (found && !index1) {
                            let type = found.type;
                            let reject = found.reject;
                            let status =
                                type === 0 && (!reject)
                                    ? 0
                                    : type === 0 && (reject === 1 || reject === 2)
                                        ? 1
                                        : type === 1 && reject === 1
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null;
                            if (status === 0 || status === 1) {
                                status =
                                    getOverdueDays(item2) >= 0
                                        ? 99
                                        : getOverdueDays(item2) >= -10
                                            ? 6 : getOverdueDays(item2) >= -15 ? 9
                                                : 7;
                            }

                            assignment.push({
                                formatted: rf_list.find(x => x.id === item.dfId)?.formatted, coverage: getCoverageText(item, shapedSite), data: found, dfId: item.dfId, formType: 2, frequency: item.frequency, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: item2, self: item?.reviewer_ids?.length ? false : true, locationId: item.locationId, level: item.level,
                                reporting_period: item2, name: item.df?.title, title: item.df?.title + ' for the reporting period of ' + item2 || '', dueDate: getDueDate(item2), currentStatus: !type && reject ? "Returned" : status === 3 ? 'Under Review' : status === 4 ? 'Under Approval' : status === 5 ? 'Approved' : 'Draft', timeLine: status === 6 ? 'Due Now' : status === 7 ? "Overdue" : status === 9 ? "Duesoon" : "Upcoming", statusCode: status
                            })
                        } else if (!found && !index1) {
                            let stat = getOverdueDays(item2) >= 0
                                ? 100
                                : getOverdueDays(item2) >= -10
                                    ? 6 : getOverdueDays(item2) >= -15 ? 9
                                        : 7
                            console.log(stat)
                            assignment.push({
                                formatted: rf_list.find(x => x.id === item.dfId)?.formatted, coverage: getCoverageText(item, shapedSite), dfId: item.dfId, formType: 2, frequency: item.frequency, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: item2, self: item?.reviewer_ids?.length ? false : true, locationId: item.locationId, level: item.level,
                                reporting_period: item2, name: item.df?.title, title: item.df?.title + ' for the reporting period of ' + item2 || '', dueDate: getDueDate(item2), currentStatus: 'Not Started', timeLine: stat === 6 ? 'Due Now' : stat === 7 ? "Overdue" : stat === 9 ? "Duesoon" : "Upcoming", statusCode: stat
                            })
                        } else if (index1) {
                            assignment.push({
                                formatted: rf_list.find(x => x.id === item.dfId)?.formatted, coverage: getCoverageText(item, shapedSite), dfId: item.dfId, formType: 2, frequency: item.frequency, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: item2, self: item?.reviewer_ids?.length ? false : true, locationId: item.locationId, level: item.level,
                                reporting_period: item2, name: item.df?.title, title: item.df?.title + ' for the reporting period of ' + item2 || '', dueDate: 'NA', currentStatus: 'Approved', timeLine: "Done", statusCode: 5
                            })
                        }

                    }
                }


                setRawSitelist(shapedSite);
                setLocList((prev) => ({
                    ...prev,
                    country: [
                        { name: "Enterprise-wide", id: 0 },
                        ...shapedSite.map((i) => ({ name: i.name, id: i.id })),
                    ],
                }));
                console.log(filtered_ql_ass);
                quantitative_submitted.forEach((item) => {
                    let filtered_qn_ass_index = filtered_qn_ass.findIndex((i) => {

                        return (
                            i.id === item.entityUserAssId && i.entityAssId === item.entityAssId
                        );
                    });

                    if (filtered_qn_ass_index !== -1) {

                        if (
                            filtered_qn_ass[filtered_qn_ass_index].reporter_ids.includes(
                                login_data.id
                            )
                        ) {
                            console.log(item);
                            qn_submit_list.push(item);
                        }
                        if (
                            filtered_qn_ass[filtered_qn_ass_index].reviewer_ids.includes(
                                login_data.id
                            )
                        ) {

                            let status = item.type === 2 ? 4 : item.type === 1 ? getOverdueDays(getRPTextFormat(item.reporting_period)) >= -20 ? 9 : 7 : 5

                            if (status) {
                                assignment.push({
                                    coverage: getCoverageText(item, shapedSite), data: item, dcfId: item.dcfId, formType: 3, frequency: item.frequency, entityAssId: item.entityAssId, entityUserAssId: item.entityUserAssId, reporting_period: getRPTextFormat(item.reporting_period), self: item?.self ? true : false, locationId: item.locationId, level: item.level,
                                    reporting_period: getRPTextFormat(item.reporting_period), name: item.dcf?.title, title: item.dcf?.title + ' for the reporting period of ' + getRPTextFormat(item.reporting_period) || '', dueDate: getDueDate(getRPTextFormat(item.reporting_period)), currentStatus: status === 100 ? 'Under Approval' : 'Under Review', timeLine: status === 6 ? 'Due Now' : status === 7 ? "Overdue" : status === 9 ? "Duesoon" : 'Upcoming', statusCode: status
                                })
                            }

                            qn_review_list.push(item);
                        }

                    }
                });
                assignment.push(...qnApproverAssignment, ...qlAssignment, ...finished, ...approved, ...actionToApprove, ...dealerApproved, ...action)
                setActions(assignment.filter(x => [100, 99, 6, 7, 9].includes(x.statusCode)));
                setArchived(assignment.filter(x => [3, 4, 5, 0].includes(x.statusCode)))
                setActionsBk(assignment.filter(x => [100, 99, 6, 7, 9].includes(x.statusCode)));
                setArchivedBk(assignment.filter(x => [3, 4, 5, 0].includes(x.statusCode)))
                console.log(assignment.filter(x => [6, 7, 9].includes(x.statusCode)));


            }).then(() => {
                setLoad(false)
            })
        } catch (e) {
            setLoad(false)
        }
    }
    const getMSIIDByAssignment = (item) => {

        return 'MSI-' + item?.vendor?.code + '-' + DateTime.fromISO(item.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')
    }
    const coverageTemplate = (rowData) => {
        let txt = 'Not Found'
        let find = getCoverageText(rowData, rawsitelist)
        if (find) {
            txt = find
        }

        return <>
            <div >{txt} </div>
        </>
    }
    const indicatorApproveTemplate = (rowData) => {
      return  <div className='clr-navy fw-6 fs-14 cur-pointer text-three-dot text-underline' onClick={() => { if(rowData.type === 2){window.open(window.origin + '/data_input_approver/' + rowData.dcfId + '/' + rowData.id)}else if(rowData.type === 3){window.open(window.origin + '/data_input_view/' + rowData.dcfId + '/' + rowData.id)} }} >{rowData.type >=3 ? "View": rowData.type === 2 ? "Approve"  :"NA"} </div>

    }
    const reviewerTemplate = (rowData) => { 
        let txt = 'Not Found'
        let find = getUser(rowData.reviewer_modified_by)
        if (find) {
            txt = find
        } else {
            txt = 'Self'
        }

        return <>
            <div >{txt} </div>
        </>
    }
    const getUser = (id) => {
        if (id === admin_data.id) {
            return "Enterprise Admin";
        }
        console.log(userLookup[id])
        return userLookup[id] ? userLookup[id].information.empname : ''
    };
    const userLookup = userList.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
    }, {});
    const reporterTemplate = (rowData) => {
        console.log(rowData)
        let txt = 'Not Found'
        let find = getUser(rowData.reporter_modified_by)
        if (find) {
            txt = find
        }

        return <>
            <div >{txt} </div>
        </>
    }
    const responsibilityTemplate = (rowData) => {
        console.log(rowData)
        let text = []
        if (rowData.status === 6 || rowData.status === 7 || rowData.status === 1) {
            text = rowData.reporter_ids?.map(x=> getUser(x))
        } else if (rowData.status === 3) {
            text = rowData.reviewer_ids?.map(x=> getUser(x))
        }
            
        
        console.log(text)
        return <div><Tooltip target={'.reviewer'} position="top" />  <div className="text-three-dot reviewer" data-pr-tooltip={text.join(",")} style={{ maxWidth: 150 }}>{text.join(',')}</div></div>


    }
    const formTemplate = (rowData) => {
        let txt = 'Not Found'
        let find = dcfList.find(i => i.id === rowData.dcfId)
        if (find) {
            txt = find.title
        }

        return <>
            <div className='clr-navy fw-6 fs-14  text-three-dot '  >{txt} </div>
        </>
    }
    const formTemplate_ = (rowData) => {
        let txt = 'Not Found'
        let find = dcfList.find(i => i.id === rowData.dcfId)
        if (find) {
            txt = find.title
        }

        return <>
            <div className=' fw-6 fs-14  text-three-dot '>{txt} </div>
        </>
    }
    const statusTemplate = (rowData) => {
        let txt = 'Not Found'
        if (rowData.status === 6) {
            txt = 'Submissions Due'
        } else if (rowData.status === 7) {
            txt = 'Submissions Overdue'
        } else if (rowData.status === 1) {
            txt = 'Resubmission Required'
        } else if (rowData.status === 3) {
            txt = 'Under Review'
        } else if (rowData.status === 4) {
            txt = 'Under Approval'
        } else if (rowData.status === 5) {
            txt = 'Approved'
        }else{
            txt = 'Submissions Overdue'
        }
        return <>{txt}</>
    }
    function findKeyById(tvssection, id) {
        for (const key in tvssection) {
            if (tvssection[key].includes(id)) {
                return parseFloat(key);
            }
        }
        return null;
    }
    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];
            console.log(entity);
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            }
        } else {
            return false;
        }
    };
    function getOverdueDays(monthString) {
        console.log(monthString);
        const [startMonth, endMonth] = monthString.split(" to ");

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split("-");
        const endOfMonth = DateTime.fromObject({
            year: parseInt(year),
            month: DateTime.fromFormat(monthValue, "LLL").month,
        }).endOf("month");
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, "days").days);
        return endOfMonth.diff(currentDate, "days").days;
    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";

        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    function getOverdueDaysByUTC(monthString) {

        const endOfMonth = DateTime.fromISO(monthString, { zone: 'Asia/Calcutta' })
        const currentDate = DateTime.local();

        return endOfMonth.diff(currentDate, "days").days;
    }
    function getMonthsBetween(start_date, end_date, frequency, filterYear, fymonth) {
        let fyStartDate, fyEndDate;
        // to show annual 3 months earlier
        if (filterYear === 0) {
            fyStartDate = start_date ? DateTime.fromISO(start_date, { zone: "utc" }).toLocal() : null;
            fyEndDate = end_date ? DateTime.fromISO(end_date, { zone: "utc" }).toLocal() : DateTime.utc().toLocal();
        } else {
            // Fiscal Year: Apr (fymonth) of the previous year to Mar (fymonth - 1) of filterYear
            fyStartDate = DateTime.fromObject({ year: filterYear - 1, month: fymonth, day: 1 });
            fyEndDate = DateTime.fromObject({ year: filterYear, month: fymonth - 1, day: 31 });
        }

        // Convert user-defined start/end dates
        const userStartDate = start_date ? DateTime.fromISO(start_date, { zone: "utc" }).toLocal() : fyStartDate;
        const userEndDate = end_date ? DateTime.fromISO(end_date, { zone: "utc" }).toLocal() : fyEndDate;

        // Adjust final start and end dates within fiscal year boundaries
        const finalStartDate = userStartDate < fyStartDate ? fyStartDate : userStartDate;
        const finalEndDate = userEndDate > fyEndDate ? fyEndDate : userEndDate;

        // Calculate last 3 months start date
        const lastThreeMonthsStart = finalEndDate.minus({ months: 3 });

        const months = [];
        let currentMonth = finalStartDate;

        while (currentMonth <= finalEndDate) {
            const periodEnd = currentMonth.plus({ months: frequency - 1 });
            if (frequency === 12) {


            }
            // ✅ ALLOW period only when it's within the last 3 months for frequency = 12
            if ((frequency === 12 && finalEndDate >= lastThreeMonthsStart) ||
                (frequency !== 12 && periodEnd >= finalStartDate && periodEnd <= finalEndDate)) {

                if (frequency === 12) {
                    months.push(currentMonth.toFormat("LLL-yyyy") + " to " + periodEnd.toFormat("LLL-yyyy"));
                } else if (frequency === 1) {
                    months.push(currentMonth.toFormat("LLL-yyyy"));
                } else {
                    months.push(currentMonth.toFormat("LLL-yyyy") + " to " + periodEnd.toFormat("LLL-yyyy"));
                }
            }

            // Move to next period
            currentMonth = currentMonth.plus({ months: frequency });
        }

        return months;
    }

    const getDueDate = (dateStr) => {
        const [from, to] = dateStr.split(" to ");
        if (to) {
            return DateTime.fromFormat(to, "LLL-yyyy").plus({ month: 1 }).startOf('month').toFormat('dd-LLL-yyyy');
        }
        return DateTime.fromFormat(from, "LLL-yyyy").plus({ month: 1 }).startOf('month').toFormat('dd-LLL-yyyy')
    };

    const approveSupplierAction = (row) => {
        console.log(row)
        setTakeActionModal(true)
        setTakeActionData(row)
    }

    const checklistReviewer = (row) => {
        console.log(row)
        setDealerReviewModal(true)
        setDealerActionData(row)
    }

    const checkReviewerDealer = (row) => {

        setActionDealerModal(true)
        setActionDealerData(row)

    }

    const actionTemplate = (rowData) => {


        if (rowData.actionType === "Checklist Submission Review") {
            return <div className="table-link-clickable" onClick={() => checkReviewerDealer(rowData)}>{rowData.actionType} - {rowData.maskId}  </div>
        }

        switch (rowData.formType) {

            case 1:


                return <div className="table-link-clickable" onClick={() => {
                    if (rowData?.data) {
                        window.open(
                            window.location.origin +
                            "/data_input_past/" +
                            rowData.dcfId +
                            "/" +
                            rowData.data.id
                        );
                    } else {
                        window.open(
                            window.location.origin + "/data_input_newer/" + rowData.dcfId
                        ).sessionStorage.setItem('newer', JSON.stringify({ ...rowData, company_id: admin_data.id }))
                    }
                }} > {'Submit ' + rowData.title} </div>
            case 2:

                return <div className="table-link-clickable" onClick={() => {
                    if (rowData?.data) {
                        window.open(window.origin + '/df_input_entry').sessionStorage.setItem('dfpreview', JSON.stringify({ id: rowData.dfId, submitId: rowData.data.id, params: { state: { data: { ...rowData.formatted, id: rowData.entityUserAssId, entityAssId: rowData.entityAssId, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period, tier0_id: rowData.tier0_id, tier1_id: rowData.tier1_id, tier2_id: rowData.tier2_id, tier3_id: rowData.tier3_id }, status: true } } }))
                    } else {
                        window.open(window.origin + '/df_input_entry').sessionStorage.setItem('dfpreview', JSON.stringify({ id: rowData.dfId, submitId: null, params: { state: { data: { ...rowData.formatted, id: rowData.entityUserAssId, entityAssId: rowData.entityAssId, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period, tier0_id: rowData.tier0_id, tier1_id: rowData.tier1_id, tier2_id: rowData.tier2_id, tier3_id: rowData.tier3_id }, status: true } } }))
                    }
                }} >{'Submit ' + rowData.title} </div>
            case 3:
                return <div className="table-link-clickable" onClick={() => {
                    window.open(
                        window.location.origin +
                        "/data_input_reviewer/" +
                        rowData?.dcfId +
                        "/" +
                        rowData?.data?.id
                    );
                }} > {'Review ' + rowData.title} </div>
            case 4:
                return <div className="table-link-clickable" onClick={() => {
                    setSelectedAudit(rowData)
                    setActionModal(true)
                }} >{'Conduct Audit ' + rowData.title} </div>
            case 5:
                return <div className="table-link-clickable" onClick={() => {
                    setSelectedAudit(rowData)
                    setActionModal(true)
                }} >{'Review ' + getMSIIDByAssignment(rowData) + ' of ' + rowData?.vendor?.supplierName} </div>

            case 0:
                return <div className="table-clickable" onClick={() => {

                    window.open(window.origin + '/lca')
                }} >{'Review ' + rowData.title} </div>

            case 6:
                return <div className="table-link-clickable" onClick={() => approveSupplierAction(rowData)} >{rowData.title} </div>

            case 7:
                return <div className="table-link-clickable" onClick={() => checklistReviewer(rowData)} >{rowData.title} </div>
            case 8:
                return <div className="table-link-clickable" onClick={() => window.open(window.location.origin + '/qualitative')} >{rowData.title} </div>
            case 9:
                return <div className="table-link-clickable" onClick={() => { setSelectedIndicator(rowData); setSourceDialog(true) }} >  {rowData.title} </div>


            default:
                <></>
        }
    }
    const actionArchiveTemplate = (rowData) => {

        switch (rowData.formType) {

            case 1:


                return <div className="table-link-clickable" onClick={() => {

                    window.open(
                        window.location.origin +
                        "/data_input_view/" +
                        rowData.dcfId +
                        "/" +
                        rowData?.data?.id
                    );

                }} > {'View ' + rowData.title} </div>
            case 2:

                return <div className="table-link-clickable" onClick={() => {
                    if (rowData?.data) {
                        window.open(window.origin + '/df_input_entry').sessionStorage.setItem('dfpreview', JSON.stringify({ id: rowData.dfId, submitId: rowData.data.id, params: { state: { data: { ...rowData.formatted, id: rowData.entityUserAssId, entityAssId: rowData.entityAssId, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period, tier0_id: rowData.tier0_id, tier1_id: rowData.tier1_id, tier2_id: rowData.tier2_id, tier3_id: rowData.tier3_id }, status: true } } }))
                    } else {
                        window.open(window.origin + '/df_input_entry').sessionStorage.setItem('dfpreview', JSON.stringify({ id: rowData.dfId, submitId: null, params: { state: { data: { ...rowData.formatted, id: rowData.entityUserAssId, entityAssId: rowData.entityAssId, locationId: rowData.locationId, level: rowData.level, reporting_period: rowData.reporting_period, tier0_id: rowData.tier0_id, tier1_id: rowData.tier1_id, tier2_id: rowData.tier2_id, tier3_id: rowData.tier3_id }, status: true } } }))
                    }
                }} >{'View ' + rowData.title} </div>
            case 3:
                return <div className="table-link-clickable" onClick={() => {
                    window.open(
                        window.location.origin +
                        "/data_input_view/" +
                        rowData?.dcfId +
                        "/" +
                        rowData?.data?.id
                    );
                }} >{rowData.title} </div>
            case 4:
                return <div className="clr-navy fs-14 fw-7" > View<span onClick={() => {
                    setSelectedAudit(rowData); setActionModal(true)
                }} className="table-link-clickable"  > Response</span> {rowData?.auditorAssignmentSubmission?.type === 2 && <> / <span className="table-link-clickable" onClick={() => {
                    setSelectedAudit(rowData); setReportDialog(true);
                }} >Report</span> </>}  {getMSIIDByAssignment(rowData) + ' of ' + rowData?.vendor?.supplierName} </div>
            case 5:
                return <div className="clr-navy fs-14 fw-7"  > View<span onClick={() => {
                    setSelectedAudit(rowData); setActionModal(true)
                }}  > Response</span> / <span onClick={() => {
                    setSelectedAudit(rowData); setReportDialog(true);
                }} >Report</span>  {getMSIIDByAssignment(rowData) + ' of ' + rowData?.vendor?.supplierName} </div>
            case 6:
                return <div className="table-link-clickable" onClick={() => approveSupplierAction(rowData)} >{rowData.title} </div>
            case 7:
                return <div className="table-link-clickable" onClick={() => checklistReviewer(rowData)} >{rowData.title} </div>
            case 9:
                    return <div className="table-link-clickable" onClick={() => { setSelectedIndicator(rowData); setSourceDialog(true) }} >  {rowData.title} </div>
    
            default:
                <></>

        }
    }
    const RowFilterTemplate = (options, obj) => {
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(actions.map((i) => i[obj])))}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const timelineTemplate = (rowData) => {

        switch (rowData.statusCode) {
            case 6:
                return <Badge severity="warning" value="Due Now" />;
            case 7:
                return <Badge severity="danger" value="Overdue" />;
            case 9:
                return <Badge severity="info" value="Due Soon" />;
            default:
                return <Badge severity="info" value="Upcoming" />;

        }
    };
    const currentStatusTemplate = (rowData) => {

        if (rowData.status === 'Initiated') {
            return <Tag value={'Pending'} />
        }

        return <Tag value={rowData.currentStatus} />

    };
    const searchAction = (e) => {
        let val = e.target.value
        setActions(actionsbk.filter(x => x?.title?.trim().toLowerCase().includes(val?.trim().toLowerCase()) || x?.dueDate?.trim().toLowerCase().includes(val?.trim().toLowerCase())))
        setSearch((prev) => ({ ...prev, action: val }))
    }
    const searchArchived = (e) => {
        let val = e.target.value

        setArchived(archivedbk.filter(x => x?.title?.trim().toLowerCase().includes(val?.trim().toLowerCase()) || x?.dueDate?.trim().toLowerCase().includes(val?.trim().toLowerCase())))
        setSearch((prev) => ({ ...prev, archive: val }))

    }
    const dueDateBodyTemplate = (rowData) => {
        if (!rowData || !rowData.dueDate) {
            return '';
        }

        // Attempt to parse the date
        const parsedDate = new Date(rowData.dueDate);

        // If date is invalid, just return the original value
        if (isNaN(parsedDate)) {
            return rowData.dueDate;
        }

        // Format the date as DD-MM-YYYY
        const day = String(parsedDate.getDate()).padStart(2, '0');
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
        const year = parsedDate.getFullYear();

        return `${day}-${month}-${year}`;
    };


    return (
        <div>
            <div className="col-12 flex align-items-center" >
                <span className="text-big-one"> Hello  {login_data?.role === 'clientadmin' ? login_data?.information?.companyname : login_data?.information?.empname} ! </span>   <span className="ml-1">{`<${login_data.email}>`} </span>
            </div>
            <div
                className="col-12 "
                style={{
                    justifyContent: "flex-start",
                }}
            >
                <label className="text-big-one clr-navy flex fw-7 flex justify-content-start">

                    My Actions
                </label>
                <label className="fs-14 clr-navy flex  justify-content-start">
                    This page lists your tasks for the Sustainability Reporting process, including submissions, reviews, or approvals, as applicable. Please complete each action to keep the reporting process on track.
                </label>
            </div>
            <TabMenu model={[{ label: 'Actions' }, { label: 'Archived Actions' }
            ]} onTabChange={(e) => { setActiveIndex(e.index) }} activeIndex={activeindex} />
            {activeindex === 0 &&
                <div>
                    <div className="col-12 flex justify-content-end" >
                        <div className='col-5'>
                            <span className="p-input-icon-left" style={{ width: '100%' }}>
                                <i className="pi pi-search" />
                                <InputText value={search.action} style={{ width: '100%' }} onChange={searchAction} placeholder="Search Title/Due Month" />
                            </span>
                        </div>
                    </div>
                    <DataTable loading={load} scrollable showGridlines paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} value={actions} filters={{
                        timeLine: { value: null, matchMode: "in" },
                        title: { value: null, matchMode: "in" },
                        currentStatus: { value: null, matchMode: "in" }
                    }} >
                        <Column header="Timeline" field='timeLine' showFilterMatchModes={false}
                            filter body={timelineTemplate}
                            filterElement={(options) =>
                                RowFilterTemplate(options, "timeLine")
                            } />
                        <Column header="Required Action" field='title' body={actionTemplate} showFilterMatchModes={false}
                            filter
                            filterElement={(options) =>
                                RowFilterTemplate(options, "title")
                            } />
                        <Column header="Due Date" field='dueDate' body={dueDateBodyTemplate} />
                        <Column header="Current Status" field='currentStatus' showFilterMatchModes={false}
                            filter body={currentStatusTemplate}
                            filterElement={(options) =>
                                RowFilterTemplate(options, "currentStatus")
                            } />

                    </DataTable>
                </div>
            }
            {activeindex === 1 &&
                <div>
                    <div className="col-12 flex justify-content-end" >
                        <div className='col-5'>
                            <span className="p-input-icon-left" style={{ width: '100%' }}>
                                <i className="pi pi-search" />
                                <InputText value={search.archive} style={{ width: '100%' }} onChange={searchArchived} placeholder="Search Title" />
                            </span>
                        </div>
                    </div>
                    <DataTable loading={load} scrollable value={archived} paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} filters={{

                        title: { value: null, matchMode: "in" },
                        currentStatus: { value: null, matchMode: "in" }
                    }} >

                        <Column header="Action" field='title' body={actionArchiveTemplate} showFilterMatchModes={false}
                            filter
                            filterElement={(options) =>
                                RowFilterTemplate(options, "title")
                            } />

                        <Column header="Current Status" field='currentStatus' showFilterMatchModes={false}
                            filter body={currentStatusTemplate}
                            filterElement={(options) =>
                                RowFilterTemplate(options, "currentStatus")
                            } />

                    </DataTable>
                </div>
            }
            <Dialog
                visible={actionmodal}
                style={{ width: '75%' }}
                onHide={() => { setActionModal(false); setTimeout(() => { setSelectedAudit(null) }, 500) }}


            >

                {selectedAudit?.formType === 5 ? <AuditPanelAdmin auditId={selectedAudit} refresh={() => { renderData() }} closeModal={(e) => { setActionModal(e) }} /> : <AuditPanel auditId={selectedAudit} editable={(!selectedAudit?.auditorAssignmentSubmission || selectedAudit?.auditorAssignmentSubmission?.type === 0)} refresh={() => { renderData() }} closeModal={(e) => { setActionModal(e) }} />}



            </Dialog>

            <Dialog
                visible={takeActionModal}
                style={{ width: '75%' }}
                onHide={() => { setTakeActionModal(false) }}
                header="Approve Action"


            >

                <ApproveSupplierAction data={takeActionData} refresh={() => { renderData() }} />



            </Dialog>

            <Dialog
                visible={dealerReviewModal}
                style={{ width: '75%' }}
                onHide={() => { setDealerReviewModal(false) }}
                header="Review Dealer Findings"


            >

                <DealerReviewAction edit={dealerActionData?.dealerAuditorChecklistSubmission?.type === 1 ? 1 : 0} data={dealerActionData} refresh={() => { renderData() }} />



            </Dialog>


            <Dialog
                visible={actionDealerModal}
                style={{ width: '75%' }}
                onHide={() => { setActionDealerModal(false) }}
                header="Approve Action"


            >

                <ApproveDealerAction data={actionDealerData} refresh={() => console.log('test')} />



            </Dialog>
            <Dialog visible={reportdialog} style={{ width: 750 }} onHide={() => { setReportDialog(false) }} >
                <SupplierReport report={selectedAudit} />
            </Dialog>
            <Dialog header={'Data Source for ' + selectedIndicator?.title} style={{ width: '75%' }} visible={sourcedialog} onHide={() => { setSourceDialog(false) }}>
                <TabView>
                    <TabPanel header="Data Source List">
                        <div>
                            <DataTable value={selectedIndicator?.datasources.filter(i => i.data && (i.data.type === 2 || i.data.type === 3)).map(i => i.data)} >
                                <Column header='Form' body={formTemplate} />
                                <Column header='Entity' body={coverageTemplate} />
                                <Column header='Reporting Period' field='reporting_period' />
                                <Column header='Reporter' body={reporterTemplate} />
                                <Column header='Reviewer' body={reviewerTemplate} />
                                <Column header='Action' body={indicatorApproveTemplate} />
                            </DataTable>
                        </div>
                    </TabPanel>
                    <TabPanel header="Incomplete Data Submissions">
                        <div>
                            <DataTable value={selectedIndicator?.datasources.filter(i => (!i.data || (i.data && i.data.type < 2))).filter(x => x.status !== 100 && x.status !== 99)} >
                                <Column header='Entity' body={coverageTemplate} />
                                <Column header='Reporting Period' field='reporting_period' />
                                <Column header='Responsibility' body={responsibilityTemplate} />
                                <Column header='Status' body={statusTemplate} />
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>

            </Dialog>
        </div>
    )

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AIOActionDashboard, comparisonFn);