const ReportSection = ({ imgSrc, heading, content,imgWidth =150 }) => {
    return (
        <div className="flex items-start gap-4 p-4">
        <div style={{ width: imgWidth, height: 100, overflow: "hidden" }}>
          <img 
            src={imgSrc} 
            alt="Icon" 
            style={{ 
              width: "100%", 
              height: "100%", 
              objectFit: "contain" // Ensures the image fits without stretching
            }} 
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold">{heading}</h2>
          <p className="text-gray-600 fs-14">{content}</p>
        </div>
      </div>
    );
  };
  
  export default ReportSection;