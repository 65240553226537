import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from 'luxon';

const SuppliersTableCompleted = ({ data, assessorList, globalFilter, supplierList, editSupplier }) => {

    console.log(data)
    const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }]

    const calibrationIdBodyTemplate = (rowData) => {
        return (
            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                {'MSI-' +  (rowData?.vendorCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}
            </a>
        );
    };
    const nameTemplate = (rowData) => {
       
        return (

            rowData?.vendor?.supplierName || 'NA'

        );
    };
    const locationTemplate = (rowData) => {
        return (
            rowData?.vendor?.supplierLocation || 'NA'

           

        );
    };
    const categoryTemplate = (rowData) => {
        console.log(rowData)
        return (

            categoryList.find(i => i.value ===    rowData?.vendor?.supplierCategory)?.name || 'Not Found'

        );
    };
    const assessmentDueDate = (rowData) => {
        return (

            DateTime.fromISO(rowData.assessmentEndDate, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')

        );
    };
    const calibrationStartDate = (rowData) => {
        return (

            rowData.auditorStartDate ? DateTime.fromISO(rowData.auditorStartDate, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy') : 'Not Set'

        );
    };
    const calibrationEndDate = (rowData) => {
        return (

            rowData.auditorEndDate ? DateTime.fromISO(rowData.auditorEndDate, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy') : 'Not Set'


        );
    };
    const contactTemplate = (rowData) => {
        return (

            supplierList.find(i => i.id === rowData.supplierId)?.information?.supplierContact || 'NA'

        );
    };
    const pySpendBodyTemplate = (rowData) => {
        return `${supplierList.find(i => i.id === rowData.supplierId)?.information?.supplierSpentOn || '- '} Cr. INR`;
    };

    const statusOfActionsTemplate = (rowData) => {
        return <span>{rowData.statusOfActions}</span>;
    };
    const team1Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group1?.assessors?.length) {
            return rowData?.group1?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team2Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group2?.assessors?.length) {
            return rowData?.group2?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team3Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group3?.assessors?.length) {
            return rowData?.group3?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const team4Template = (rowData) => {
        console.log(rowData)
        if (rowData?.group4?.assessors?.length) {
            return rowData?.group4?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const handleCalibrationClick = (rowData) => {
        // Logic when calibration ID is clicked
        alert('Clicked on: ' + rowData.calibrationId);
    };
    const actionBodytemplate = (rowData) => {
        return (
            <div>
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => editSupplier(rowData)}
                >
                    <i className="pi pi-pencil" />
                </button>
            </div>
        )
    }
    return (
        <DataTable value={data} paginator rows={10} responsiveLayout="scroll" globalFilter={globalFilter} className="mt-2 h-500">
            <Column field="calibrationId" header="Calibration ID" body={calibrationIdBodyTemplate} ></Column>
            <Column field="name" header="Name" body={nameTemplate}></Column>
            <Column field="location" header="Location" body={locationTemplate}></Column>
            <Column field="supplierContact" header="Supplier Contact" body={contactTemplate}></Column>
            <Column field="pinCode" header="Pin Code" ></Column>
            <Column field="pySpend" header="PY Spend (Cr. INR)" body={pySpendBodyTemplate} ></Column>
            <Column field="category" header="Category" body={categoryTemplate}></Column>
            <Column field="selfAssessmentDueDate" header="Self-Assessment Due Date" body={assessmentDueDate} ></Column>
            <Column field="status" header="Status" ></Column>
            <Column field="status" header="MSI Self-assessment Score" ></Column>
            <Column field="calibrationStartDate" header="Calibration Start Date" body={calibrationStartDate}></Column>
            <Column field="calibrationEndDate" header="Calibration End Date" body={calibrationEndDate}></Column>
            {/* <Column field="calibrationStatus" header="Calibration Status" ></Column> */}
            {/* <Column field="report" header="Report" ></Column> */}
            <Column field="msiScore" header="MSI Score" ></Column>
            <Column field="msiRating" header="MSI Rating" ></Column>
            <Column field="statusOfActions" header="Status of Actions" body={statusOfActionsTemplate}></Column>
            <Column field="teamMember1" header="Calibration Team Member 1" body={team1Template}></Column>
            <Column field="teamMember2" header="Calibration Team Member 2" body={team2Template}></Column>
            <Column field="teamMember3" header="Calibration Team Member 3" body={team3Template}></Column>
            <Column field="teamMember3" header="Calibration Team Member 4" body={team4Template}></Column>
            {/* <Column header="Schedule MSI Calibration" body={actionBodytemplate}></Column> */}

        </DataTable>
    );
};

export default SuppliersTableCompleted;
