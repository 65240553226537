import React, { useState, useEffect } from "react";

import { Tab, Row, Col, Nav, Table, Form, Modal, Card } from 'react-bootstrap';

//import {_utilities} from '../SupplierScreen';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import Swal from "sweetalert2";
import moment from 'moment'
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea'
import { RadioButton } from "primereact/radiobutton";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { AccordionTab, Accordion } from "primereact/accordion";
import { Tag } from "primereact/tag";
import "../../Styles/_utilities.scss"
import { InputText } from 'primereact/inputtext';
import { AttachmentAsIcon } from "../../components/Forms/AttachmentAsIcon";



const categoryOptions = [
    { label: 'Good Practices', id: 1 },
    { label: 'Opportunity of Improvement', id: 2 },
    { label: 'Non-compliance', id: 3 },
];

const nonComplianceOptions = [
    { label: 'Regulatory (Major)', id: 1 },
    { label: 'Regulatory (Minor)', id: 2 },
    { label: 'Minor', id: 3 },
];

// export const lawsOptions = [
//     {
//         label: 'The Air (Prevention and Control of Pollution) Act, 1981',
//         value: 'The Air (Prevention and Control of Pollution) Act, 1981'
//     },
//     {
//         label: 'The Environment Protection Act, 1986',
//         value: 'The Environment Protection Act, 1986'
//     },
//     {
//         label: 'The Motor Vehicles Act, 1988 (Section on Emission Standards)',
//         value: 'The Motor Vehicles Act, 1988 (Section on Emission Standards)'
//     },
//     {
//         label: 'National Auto Fuel Policy, 2003',
//         value: 'National Auto Fuel Policy, 2003'
//     },
//     {
//         label: 'Bharat Stage (BS) Emission Standards',
//         value: 'Bharat Stage (BS) Emission Standards'
//     },
//     {
//         label: 'The Hazardous Wastes (Management, Handling and Transboundary Movement) Rules, 2008',
//         value: 'The Hazardous Wastes (Management, Handling and Transboundary Movement) Rules, 2008'
//     },
//     {
//         label: 'The E-Waste (Management) Rules, 2016',
//         value: 'The E-Waste (Management) Rules, 2016'
//     },
//     {
//         label: 'The Plastic Waste Management Rules, 2016',
//         value: 'The Plastic Waste Management Rules, 2016'
//     },
//     {
//         label: 'The Batteries (Management and Handling) Rules, 2001',
//         value: 'The Batteries (Management and Handling) Rules, 2001'
//     },
//     {
//         label: 'Automobile Recycling Rules (End-of-Life Vehicles – ELV)',
//         value: 'Automobile Recycling Rules (End-of-Life Vehicles – ELV)'
//     },
//     {
//         label: 'Factories Act, 1948',
//         value: 'Factories Act, 1948'
//     },
//     {
//         label: 'The Payment of Wages Act, 1936',
//         value: 'The Payment of Wages Act, 1936'
//     },
//     {
//         label: 'The Industrial Disputes Act, 1947',
//         value: 'The Industrial Disputes Act, 1947'
//     },
//     {
//         label: 'The Employees’ Provident Funds and Miscellaneous Provisions Act, 1952',
//         value: 'The Employees’ Provident Funds and Miscellaneous Provisions Act, 1952'
//     },
//     {
//         label: 'The Child labor (Prohibition and Regulation) Act, 1986',
//         value: 'The Child labor (Prohibition and Regulation) Act, 1986'
//     },
//     {
//         label: 'The Equal Remuneration Act, 1976',
//         value: 'The Equal Remuneration Act, 1976'
//     },
//     {
//         label: 'The Companies Act, 2013',
//         value: 'The Companies Act, 2013'
//     },
//     {
//         label: 'Securities and Exchange Board of India (SEBI) Regulations',
//         value: 'Securities and Exchange Board of India (SEBI) Regulations'
//     },
//     {
//         label: 'The Prevention of Corruption Act, 1988',
//         value: 'The Prevention of Corruption Act, 1988'
//     },
//     {
//         label: 'The Competition Act, 2002',
//         value: 'The Competition Act, 2002'
//     },
//     {
//         label: 'The Right to Information Act, 2005',
//         value: 'The Right to Information Act, 2005'
//     },
//     {
//         label: 'Corporate Social Responsibility (CSR) Rules under the Companies Act, 2013',
//         value: 'Corporate Social Responsibility (CSR) Rules under the Companies Act, 2013'
//     },
//     {
//         label: 'The Environment Impact Assessment (EIA) Notification, 2006',
//         value: 'The Environment Impact Assessment (EIA) Notification, 2006'
//     },
//     {
//         label: 'Fuel Efficiency Standards (CAFE Norms)',
//         value: 'Fuel Efficiency Standards (CAFE Norms)'
//     },
//     {
//         label: 'The Green Tribunal Act, 2010',
//         value: 'The Green Tribunal Act, 2010'
//     },
//     {
//         label: 'The Plastic Waste Management Amendment Rules, 2021',
//         value: 'The Plastic Waste Management Amendment Rules, 2021'
//     },
//     {
//         label: 'The Solid Waste Management Rules, 2016',
//         value: 'The Solid Waste Management Rules, 2016'
//     },
//     {
//         label: 'The Construction and Demolition Waste Management Rules, 2016',
//         value: 'The Construction and Demolition Waste Management Rules, 2016'
//     },
//     {
//         label: 'The Water (Prevention and Control of Pollution) Cess Act, 1977',
//         value: 'The Water (Prevention and Control of Pollution) Cess Act, 1977'
//     },
//     {
//         label: 'The Groundwater (Protection and Management) Rules, 2012',
//         value: 'The Groundwater (Protection and Management) Rules, 2012'
//     },
//     {
//         label: 'The National Water Quality Monitoring Programme (NWQMP)',
//         value: 'The National Water Quality Monitoring Programme (NWQMP)'
//     },
//     {
//         label: 'Environmental Standards for Industrial Effluents (EPA)',
//         value: 'Environmental Standards for Industrial Effluents (EPA)'
//     },
//     {
//         label: 'The Chemical Accidents (Emergency Planning, Preparedness, and Response) Rules, 1996',
//         value: 'The Chemical Accidents (Emergency Planning, Preparedness, and Response) Rules, 1996'
//     },
//     {
//         label: 'The Bio-Medical Waste Management Rules, 2016',
//         value: 'The Bio-Medical Waste Management Rules, 2016'
//     },
//     {
//         label: 'The Wastewater Recycling and Reuse Guidelines, 2019',
//         value: 'The Wastewater Recycling and Reuse Guidelines, 2019'
//     },
//     {
//         label: 'The Extended Producer Responsibility (EPR) Guidelines for Plastic Waste Management, 2021',
//         value: 'The Extended Producer Responsibility (EPR) Guidelines for Plastic Waste Management, 2021'
//     },
//     {
//         label: 'The Water (Prevention and Control of Pollution) Rules, 1975',
//         value: 'The Water (Prevention and Control of Pollution) Rules, 1975'
//     },
//     {
//         label: 'The Air (Prevention and Control of Pollution) Rules, 1982',
//         value: 'The Air (Prevention and Control of Pollution) Rules, 1982'
//     },
//     {
//         label: 'The Environment (Protection) (Plastic Waste Management) Amendment Rules, 2018',
//         value: 'The Environment (Protection) (Plastic Waste Management) Amendment Rules, 2018'
//     },
//     {
//         label: 'The Municipal Solid Waste (Management and Handling) Rules, 2000',
//         value: 'The Municipal Solid Waste (Management and Handling) Rules, 2000'
//     },
//     {
//         label: 'The Hazardous and Other Wastes (Management and Transboundary Movement) Rules, 2016',
//         value: 'The Hazardous and Other Wastes (Management and Transboundary Movement) Rules, 2016'
//     },
//     {
//         label: 'The Chemical Waste (Management and Handling) Rules, 2008',
//         value: 'The Chemical Waste (Management and Handling) Rules, 2008'
//     },
//     {
//         label: 'The Environmental Protection (Sixth Amendment) Rules, 2012',
//         value: 'The Environmental Protection (Sixth Amendment) Rules, 2012'
//     },
//     {
//         label: '(POSH)The Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act, 2013',
//         value: '(POSH)The Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act, 2013'
//     }
// ];


const AuditPanel = ({ auditId, closeModal, refresh, editable }) => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const [lawsOption, setLawOption] = useState([])
    const [data, setData] = useState({ data1: [], id: null })
    const [formId, setFormId] = useState(null)
    const [assessmentsection, setAssessmentSection] = useState([])
    const [subsectiondata, setSubSectionData] = useState([])
    const [selectedsubsection2, setSelectedSubSection2] = useState(null)
    const [steps, setSteps] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const forceUpdate = useForceUpdate()
    const [headerValues, setHeaderValues] = useState({});
    const [files, setFiles] = useState([]);
    const [activeQuestionId, setActiveQuestionId] = useState(null);
    const [commentText, setCommentText] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [selectSection, setSelectSection] = useState([])
    const [allCompleted, setAllCompleted] = useState(false)
    const [grandTotalScore, setGrandTotalScore] = useState(0);
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);
    const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }, { name: 'IDM (Indirect Material)', value: 10 }, { name: 'Import', value: 11 }]

    const [supplierResponseData, setSupplierResponseData] = useState([])
    const [findings, setFindings] = useState([]);
    const [findingDialog, setFindingDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectSubSection1, setSelectSubSection1] = useState('')

    // This holds the current item being edited or created
    const [currentFinding, setCurrentFinding] = useState({

        categoryOfFinding: null,
        nonComplianceType: null,
        applicableLaw: null,
        otherLaw: '',
        finding: '',
        description: '',
        auditorAttachments: [],
    });

    useEffect(() => {
        console.log(editable, auditId)
        // Fetch findings when selectSubSection1 changes
        const fetchFindings = async () => {
            if (!selectSubSection1) {
                setFindings([]); // Reset findings if selectSubSection1 is not provided
                return;
            }

            try {

                let uriString = {

                    where: {

                        assessmentSubSection2Id: selectSubSection1.id
                    }

                };
                const response = await APIServices.get(API.getFindingAllAuditor(auditId.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`);
                setFindings(response.data); // Populate findings state with the response data
            } catch (err) {


            }
        };

        fetchFindings();
    }, [selectSubSection1]);

    const [activeId, setActiveId] = useState(1); // State to hold the active framework ID
    const [labelColor, setLabelColor] = useState("#007bff"); // Default label color (black)
    const [result, setResult] = useState(null)
    const [supplierId, setSupplierId] = useState(null)
    const [highlightedQuestion, setHighlightedQuestion] = useState(null);
    const handleFileChange = (file) => {

        setFiles(file)

    }

    const handleComment = (question, questionIndex, sectionId, subsection1Id, subsection2Id) => {

        setSelectedQuestion({
            ...question,
            questionIndex,
            sectionId,
            subsection1Id,
            subsection2Id,
        });
        setComment(question.comment || '');
        setShowModal(true)
    }

    const openNew = () => {
        setSubmitted(false)
        setCurrentFinding({
            otherLaw: '',
            categoryOfFinding: null,
            nonComplianceType: null,
            applicableLaw: null,
            finding: '',
            description: '',
            auditorAttachments: [],
        });
        setFindingDialog(true);
    };

    // Handle editing an existing finding
    const editFinding = (rowData) => {
        setCurrentFinding({ ...rowData }); // clone the existing data
        setFindingDialog(true);
    };

    // Hide the dialog
    const hideDialog = () => {
        setFindingDialog(false);
    };

    // Handle saving (create or update)
    const saveFinding = async () => {
        setSubmitted(true)
        console.log(currentFinding)
        if (currentFinding.categoryOfFinding && (currentFinding.categoryOfFinding === 3 ? ((currentFinding.applicableLaw != null && (currentFinding.applicableLaw === 0 ? currentFinding?.otherLaw?.trim().length : true)) && currentFinding.nonComplianceType) : true) && currentFinding.finding.trim().length) {
            if (currentFinding.id) {

                let newObj = { ...currentFinding }
                delete newObj.priority
                delete newObj.supplierAttachments
                delete newObj.created_on
                delete newObj.created_by
                delete newObj.vendorCodeId

                // UPDATE
                APIServices.patch(`${API.SupplierAction_Edit(currentFinding.id)}`, { ...newObj, auditor_modified_by: login_data.id, auditor_modified_on: DateTime.utc() }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(findings))
                    let index = loc.findIndex(x => x.id === currentFinding.id)
                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...newObj }
                        setFindings(loc)
                    }
                })

                // Update the state with the updated finding
                // setFindings((prev) =>
                //     prev.map((f) => (f.id === currentFinding.id ? response.data : f))
                // );

                setSelectSubSection1(selectSubSection1)
            } else {
                // CREATE

                const response = await APIServices.post(API.addFinding(auditId.id), { assessmentSubSection2Id: selectSubSection1.id, created_on: DateTime.utc(), created_by: login_data.id, ...currentFinding });
                setFindings((prev) => [...prev, response.data]);
            }
            setFindingDialog(false);
        }
        // setCurrentFinding({
        //     id: null,
        //     categoryOfFinding: null,
        //     nonComplianceType: null,
        //     applicableLaw: null,
        //     finding: '',
        //     description: '',
        //     auditorAttachments: [],
        // });

    };

    // Handle opening the delete confirmation
    const confirmDeleteFinding = async (rowData) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${rowData.finding}"</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            APIServices.delete(API.SupplierAction_Edit(rowData.id)).then((res) => {
                let loc = JSON.parse(JSON.stringify(findings))
                let index = loc.findIndex(x => x.id === rowData.id)
                if (index !== -1) {
                    loc.splice(index, 1);
                    setFindings(loc)
                }


                forceUpdate()
            })
        }

    };

    // Hide delete confirmation
    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    // Perform delete
    const deleteFinding = () => {
        setFindings((prev) => prev.filter((f) => f.id !== currentFinding.id));
        setDeleteDialog(false);
        setCurrentFinding({});
    };

    // Action column (Edit/Delete buttons)
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-success mr-2"
                    onClick={() => editFinding(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-warning"
                    onClick={() => confirmDeleteFinding(rowData)}
                />
            </React.Fragment>
        );
    };

    // *** DIALOG FOOTERS ***

    const findingDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDialog}
            />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveFinding} />
        </React.Fragment>
    );

    const deleteDialogFooter = (
        <React.Fragment>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteDialog}
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteFinding}
            />
        </React.Fragment>
    );

    const uploadFilesApi = async (file, item, event) => {

        setSelectedQuestion(item)
        let promise = new Promise((resolve, rej) => {
            if (file.size <= 10000000) {
                let formData = new FormData();
                formData.append("file", file);
                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                    mode: "no-cors",
                }).then((res) => {

                    if (res.status === 200) {
                        resolve({
                            url: API.Docs + res.data.files[0].originalname,
                            name: res.data.files[0].originalname,
                            size: res.data.files[0].size,
                        });
                    } else {
                        resolve(null);
                    }
                });
            } else {
                resolve(null);
            }
        });

        return promise;
    };
    const checkQuestionary = (data) => {
        console.log(data)
        let loc = JSON.parse(data)
        if (loc.length) {
            return loc.every((item) => { return (item.type === 'select' ? item.value : item.type === 'radio-group' ? item?.values?.some((v) => v?.selected) : item.type === 'textarea' ? item.value && item.value?.trim()?.length : true) })

            // return loc.every((item) => { return (item.type === 'select' ? item.value : item.type === 'radio-group' ? (item?.values?.some((v) => v?.selected) && (item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('n/a') || item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('not applicable') || item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('no') || item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('na')) ? item?.comment?.trim()?.length : item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('yes')) : item.type === 'textarea' ? item.value && item.value?.trim()?.length : true) })

            // return loc.every((item) => { return ( item.isAttachmentMandatory ? item?.attachments?.length : true) && (item.type === 'select' ? item.value : item.type === 'radio-group' ? (item?.values?.some((v) => v?.selected) && (item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('n/a') || item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('not applicable') || item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('no') || item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('na')) ? item?.comment?.trim()?.length : item?.values?.find(v => v.selected)?.label?.trim()?.toLowerCase()?.includes('yes')) : item.type === 'textarea' ? item.value && item.value?.trim()?.length : true) })
        } else {
            return false
        }
    }
    const checkSubSection1 = (data) => {
        if (data?.assessmentSubSection2s?.length) {
            return data?.assessmentSubSection2s.every((item) => { return checkQuestionary(item?.form?.data1 || []) })
        } else {
            return false
        }
    }
    const handleSaveComment = () => {
        if (!selectedQuestion) return; // If somehow nothing selected

        const { sectionId, subsection1Id, subsection2Id, questionIndex } = selectedQuestion;

        const updated = assessmentsection.map((sectionItem) => {
            if (sectionItem.id !== sectionId) return sectionItem;
            return {
                ...sectionItem,
                assessmentSubSection1s: sectionItem.assessmentSubSection1s.map((s1Item) => {
                    if (s1Item.id !== subsection1Id) return s1Item;
                    return {
                        ...s1Item,
                        assessmentSubSection2s: s1Item.assessmentSubSection2s.map((s2Item) => {
                            if (s2Item.id !== subsection2Id) return s2Item;

                            // Parse data1
                            const parsedData = JSON.parse(s2Item.form.data1);

                            // Grab the specific question from parsedData
                            const questionToUpdate = { ...parsedData[questionIndex] };
                            // Update its comment
                            questionToUpdate.comment = comment;

                            // Put it back
                            parsedData[questionIndex] = questionToUpdate;

                            // Return the updated sub-subsection
                            return {
                                ...s2Item,
                                form: {
                                    ...s2Item.form,
                                    data1: JSON.stringify(parsedData), // Overwrite data1 with updated questions
                                },
                            };
                        }),
                    };
                }),
            };
        });

        // Set the new array in state
        setAssessmentSection(updated);

        // Optionally, if you want to keep local `selectSection` in sync, re-find the updated subsection:
        const newSection = updated.find((s) => s.id === sectionId);
        const newSubSection1 = newSection.assessmentSubSection1s.find((x) => x.id === subsection1Id);
        const newSubSection2 = newSubSection1.assessmentSubSection2s.find((x) => x.id === subsection2Id);
        setSelectSection(newSubSection2);

        // Close the dialog
        setShowModal(false);
    };

    const handleAttachment = (file, ques) => {
        let loc = JSON.parse(JSON.stringify(data))
        let index = loc.data1.findIndex(i => i.name === ques?.name)
        if (index !== -1) {
            loc.data1[index].attachments = [file]
            setData(loc)
            forceUpdate()
        }

    };
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');

    const handleOpenModal = () => { setShowModal(true) };
    const handleCloseModal = () => setShowModal(false);



    useEffect(() => {
        const promise0 = APIServices.post(API.sectionAuditData_UP, { assignmentId: auditId?.id })
        const promise1 = APIServices.get(API.GetList_DD(2))
        Promise.all([promise0, promise1]).then((values) => {
            setLawOption([...values[1].data, { id: 0, title: 'Others' }])

            setResult(values[0]?.data?.result)
            console.log(values[0]?.data?.result)
            if (values[0]?.data?.result === 2) {
                setSupplierId(values[0]?.data?.id)
                setGrandTotalScore(values[0]?.data?.auditorMSIScore || 0)
            }

            if (values[0]?.data?.response) {
                //  setSteps(values[0]?.data.sort((a, b) => { return a.order - b.order }, 0).map(i => ({ label: i.title, id: i.id })))
                setAssessmentSection(JSON.parse(values[0]?.data?.response).sort((a, b) => { return a.order - b.order }, 0))
                setSupplierResponseData(values[0]?.data?.supplierResponse?.length !== 0 ? (JSON.parse(values[0]?.data?.supplierResponse).sort((a, b) => { return a.order - b.order }, 0)) : [])
                setSubSectionData(JSON.parse(values[0]?.data?.response).sort((a, b) => { return a.order - b.order }, 0)[0])
            } else {
                setSteps([])
                setAssessmentSection([])
            }

        })
    }, [auditId])





    const handleStepClick = (sec, index) => {

        APIServices.post(API.AuditorChecklistSubmission_Recent, { supplierAssessmentAssignmentId: auditId.id, sectionId: sec.id }).then(res => {
            if (res?.data?.result === 2) {
                setSupplierId(res?.data?.id || null)
                setAssessmentSection(JSON.parse(res?.data?.response || '[]'))
                setResult(2)
            }
        })
        setActiveAccordionIndex(null)
        setSelectedSubSection2(null);
        setSelectSection(null);
        setActiveIndex(index)
        setSubSectionData(assessmentsection.find(i => i.id === sec.id))
    };

    const [expandedItems, setExpandedItems] = useState([]); // Track expanded items
    const [expandAll, setExpandAll] = useState(false); // State to toggle expand all items




    const calculateSum = (data1) => {
        let sum = 0;

        try {
            const parsedData = JSON.parse(data1); // Parse the data1 JSON string
            parsedData.forEach((item) => {
                if (item.type === "radio-group") {
                    item.values.forEach((value) => {
                        if (value.selected) {
                            const numericValue = parseFloat(value.value);
                            if (!isNaN(numericValue)) {
                                sum += numericValue;
                            }
                        }
                    });
                }
            });
        } catch (error) {
            console.error("Error parsing data1:", error);
        }

        return sum;
    };

    /**
 * Checks if all mandatory questions in parsedData are answered.
 * Extend the logic for attachments, comments, etc., if needed.
 */
    const checkAllQuestionsAnswered = (parsedData) => {

        console.log(parsedData)
        return parsedData.every((question) => {
            // If question is NOT mandatory, skip checking it
            // if (!question.mandatory) return true;

            // For mandatory questions:
            if (question.type === "radio-group" && question.values) {
                // True if at least one value is selected
                return question.values.some((v) => v.selected);
            }


            // For other question types, add your own checks
            return true;
        });
    }


    const onRadioButtonSelected = (
        question,
        cbind,
        auditId,
        section,
        subsection1,
        selectedsubsection2,
        formId,
        index
    ) => {
        const updatedAssessmentSection = assessmentsection.map((sectionItem) => {
            if (sectionItem.id !== section) return sectionItem;

            const updatedSubSection1s = sectionItem.assessmentSubSection1s.map(
                (subsection1Item) => {
                    if (subsection1Item.id !== subsection1) return subsection1Item;

                    // Update *all* subSection2
                    const updatedSubSection2s = subsection1Item.assessmentSubSection2s.map(
                        (subsection2Item) => {
                            if (subsection2Item.id !== selectedsubsection2) return subsection2Item;

                            const parsedData = JSON.parse(subsection2Item.form.data1);
                            const updatedQuestion = { ...parsedData[index] };

                            if (updatedQuestion && updatedQuestion.values) {
                                updatedQuestion.values = updatedQuestion.values.map((value, idx) => ({
                                    ...value,
                                    selected: idx === cbind,
                                }));
                                updatedQuestion.modified_by = login_data.id;
                                updatedQuestion.modified_on = DateTime.utc();
                            }

                            parsedData[index] = updatedQuestion;
                            const newScore = calculateSum(JSON.stringify(parsedData));
                            const allAnswered = checkAllQuestionsAnswered(parsedData);

                            return {
                                ...subsection2Item,
                                completed: allAnswered,
                                form: {
                                    ...subsection2Item.form,
                                    data1: JSON.stringify(parsedData),
                                    score: newScore,
                                },
                            };
                        }
                    );

                    // Summation for subSection1
                    const totalScoreForSubsection1 = updatedSubSection2s.reduce((acc, sub2) => {
                        return acc + (sub2.form?.score || 0);
                    }, 0);

                    const allSub2Completed =
                        updatedSubSection2s.length > 0 &&
                        updatedSubSection2s.every((sub2) => sub2.completed);

                    return {
                        ...subsection1Item,
                        assessmentSubSection2s: updatedSubSection2s,
                        totalScore: totalScoreForSubsection1,
                        totalCompleted: allSub2Completed,
                    };
                }
            );

            // Now check if entire Section is completed
            const allSub1Completed =
                updatedSubSection1s.length > 0 &&
                updatedSubSection1s.every((sub1) => sub1.totalCompleted);

            // 5) Compute total score for the entire Section
            const totalScoreForSection = updatedSubSection1s.reduce((acc, sub1) => {
                return acc + (sub1.totalScore || 0);
            }, 0);

            return {
                ...sectionItem,
                assessmentSubSection1s: updatedSubSection1s,
                sectionTotalScore: totalScoreForSection,
                completed: allSub1Completed, // entire section
            };
        });

        setAssessmentSection(updatedAssessmentSection);

        const overallScore = updatedAssessmentSection.reduce((acc, sec) => {
            return acc + (sec.sectionTotalScore || 0);
        }, 0);

        // 2) Save it in your separate state
        setGrandTotalScore(overallScore);

        const isEverythingCompleted =
            updatedAssessmentSection.length > 0 &&
            updatedAssessmentSection.every((sec) => sec.completed === true);

        // 2) Store that in local state
        setAllCompleted(isEverythingCompleted);

        // -- Re-find the updated SubSection2 for `selectSection` --
        const newSectionItem = updatedAssessmentSection.find((s) => s.id === section);
        const newSubSection1Item = newSectionItem.assessmentSubSection1s.find(
            (s1) => s1.id === subsection1
        );
        const newSubSection2Item = newSubSection1Item.assessmentSubSection2s.find(
            (s2) => s2.id === selectedsubsection2
        );

        // Update local state reference (if you're using `selectSection` in rendering):
        setSelectSection(newSubSection2Item);

        // Optionally force a re-render:
        forceUpdate();

        console.log("Updated Assessment Section:", updatedAssessmentSection);
    };



    const getDate = (date, format) => {
        if (!date) {
            return 'Not Set'
        }
        if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const onChangeDropwdown = (newValue,
        questionIndex,
        sectionId,
        subsection1Id,
        subsection2Id) => {
        const updatedAssessmentSection = assessmentsection.map((sec) => {
            // Only update the matching section
            if (sec.id !== sectionId) return sec;

            return {
                ...sec,
                assessmentSubSection1s: sec.assessmentSubSection1s.map((sub1) => {
                    if (sub1.id !== subsection1Id) return sub1;

                    // 2) Update SubSection2 array
                    const updatedSub2s = sub1.assessmentSubSection2s.map((sub2) => {
                        if (sub2.id !== subsection2Id) return sub2;

                        // 3) Parse the questions in data1
                        const parsedData = JSON.parse(sub2.form.data1);
                        const updatedQuestion = { ...parsedData[questionIndex] };

                        // 4) Set the new text value + metadata
                        updatedQuestion.value = newValue;
                        updatedQuestion.modified_by = login_data.id;
                        updatedQuestion.modified_on = DateTime.utc().toFormat('dd-MM-yyyy');

                        // 5) Put the updated question back
                        parsedData[questionIndex] = updatedQuestion;

                        return {
                            ...sub2,
                            form: {
                                ...sub2.form,
                                data1: JSON.stringify(parsedData), // Overwrite data1 with updated questions
                            },
                        };
                    });

                    return {
                        ...sub1,
                        assessmentSubSection2s: updatedSub2s,
                    };
                }),
            };
        });

        // 6) Update your state
        setAssessmentSection(updatedAssessmentSection);

        // (Optional) Re-find the updated sub2 item if you’re using selectSection in your UI
        const newSection = updatedAssessmentSection.find((s) => s.id === sectionId);
        const newSubSection1 = newSection.assessmentSubSection1s.find(
            (s1) => s1.id === subsection1Id
        );
        const newSubSection2 = newSubSection1.assessmentSubSection2s.find(
            (s2) => s2.id === subsection2Id
        );
        setSelectSection(newSubSection2);

        // Force a re-render if needed
        forceUpdate();
        forceUpdate()
    }

    const onTextareaChange = (
        newValue,
        questionIndex,
        sectionId,
        subsection1Id,
        subsection2Id
    ) => {
        // 1) Build an updated copy of assessmentsection
        const updatedAssessmentSection = assessmentsection.map((sec) => {
            // Only update the matching section
            if (sec.id !== sectionId) return sec;

            return {
                ...sec,
                assessmentSubSection1s: sec.assessmentSubSection1s.map((sub1) => {
                    if (sub1.id !== subsection1Id) return sub1;

                    // 2) Update SubSection2 array
                    const updatedSub2s = sub1.assessmentSubSection2s.map((sub2) => {
                        if (sub2.id !== subsection2Id) return sub2;

                        // 3) Parse the questions in data1
                        const parsedData = JSON.parse(sub2.form.data1);
                        const updatedQuestion = { ...parsedData[questionIndex] };

                        // 4) Set the new text value + metadata
                        updatedQuestion.value = newValue;
                        updatedQuestion.modified_by = login_data.id;
                        updatedQuestion.modified_on = DateTime.utc().toFormat('dd-MM-yyyy');

                        // 5) Put the updated question back
                        parsedData[questionIndex] = updatedQuestion;

                        return {
                            ...sub2,
                            form: {
                                ...sub2.form,
                                data1: JSON.stringify(parsedData), // Overwrite data1 with updated questions
                            },
                        };
                    });

                    return {
                        ...sub1,
                        assessmentSubSection2s: updatedSub2s,
                    };
                }),
            };
        });

        // 6) Update your state
        setAssessmentSection(updatedAssessmentSection);

        // (Optional) Re-find the updated sub2 item if you’re using selectSection in your UI
        const newSection = updatedAssessmentSection.find((s) => s.id === sectionId);
        const newSubSection1 = newSection.assessmentSubSection1s.find(
            (s1) => s1.id === subsection1Id
        );
        const newSubSection2 = newSubSection1.assessmentSubSection2s.find(
            (s2) => s2.id === subsection2Id
        );
        setSelectSection(newSubSection2);

        // Force a re-render if needed
        forceUpdate();
    };
    const onFileUpload = async (
        uploadedFile,
        questionIndex,
        sectionId,
        subsection1Id,
        subsection2Id
    ) => {
        console.log(uploadedFile, questionIndex,
            sectionId,
            subsection1Id,
            subsection2Id)
        try {
            console.log(uploadedFile)
            // Ensure assessmentsection exists
            if (!assessmentsection || assessmentsection.length === 0) return;


            // Update assessmentsection
            const updatedAssessmentSection = assessmentsection.map((sec) => {
                if (sec.id !== sectionId) return sec;

                return {
                    ...sec,
                    assessmentSubSection1s: sec.assessmentSubSection1s.map((sub1) => {
                        if (sub1.id !== subsection1Id) return sub1;

                        return {
                            ...sub1,
                            assessmentSubSection2s: sub1.assessmentSubSection2s.map((sub2) => {
                                if (sub2.id !== subsection2Id) return sub2;

                                // Parse data1 for questions
                                const parsedData = JSON.parse(sub2.form.data1);
                                const updatedQuestion = { ...parsedData[questionIndex] };

                                // Update attachments (single or multiple)
                                updatedQuestion.attachments = uploadedFile

                                // Update metadata
                                updatedQuestion.modified_by = login_data.id;
                                updatedQuestion.modified_on = DateTime.utc();

                                // Save back to parsedData
                                parsedData[questionIndex] = updatedQuestion;

                                return {
                                    ...sub2,
                                    form: {
                                        ...sub2.form,
                                        datas: parsedData,
                                        data1: JSON.stringify(parsedData),
                                    },
                                };
                            }),
                        };
                    }),
                };
            });
            console.log(updatedAssessmentSection)
            // Update state
            setAssessmentSection(updatedAssessmentSection);

            // Update selected section
            const newSection = updatedAssessmentSection.find((s) => s.id === sectionId);
            const newSubSection1 = newSection?.assessmentSubSection1s.find(
                (s1) => s1.id === subsection1Id
            );
            const newSubSection2 = newSubSection1?.assessmentSubSection2s.find(
                (s2) => s2.id === subsection2Id
            );

            if (newSubSection2) setSelectSection(newSubSection2);
            forceUpdate();

        } catch (error) {
            console.error("File upload failed: ", error);
        }
    };



    /**
 * Validate all questions in the assessmentsection structure.
 * Return { valid: boolean, message: string } so we can show a message if invalid.
 */
    function validateAssessment(assessmentsection) {
        for (const sectionItem of assessmentsection) {
            const sectionId = sectionItem.id;

            for (const sub1Item of sectionItem.assessmentSubSection1s || []) {
                const subsection1Id = sub1Item.id;

                for (const sub2Item of sub1Item.assessmentSubSection2s || []) {
                    const subsection2Id = sub2Item.id;

                    let parsedQuestions = [];
                    try {
                        parsedQuestions = JSON.parse(sub2Item.form?.data1 || "[]");
                    } catch (err) {
                        return {
                            valid: false,
                            message: "Invalid data structure found.",
                            location: { sectionId, subsection1Id, subsection2Id, questionIndex: 0 }
                        };
                    }

                    for (let questionIndex = 0; questionIndex < parsedQuestions.length; questionIndex++) {
                        const question = parsedQuestions[questionIndex];

                        // 1) Check question.required (or "required": true, or whatever your field is)
                        if (question.required) {
                            // Check based on question type
                            if (question.type === "radio-group") {
                                const selectedValue = question.values?.find((v) => v.selected)?.label;
                                if (!selectedValue) {
                                    return {
                                        valid: false,
                                        message: `Please select a response for "${question.label}".`,
                                        location: { sectionId, subsection1Id, subsection2Id, questionIndex }
                                    };
                                }

                            }
                            if (question.type === "textarea") {
                                if (!question.value?.trim()) {
                                    return {
                                        valid: false,
                                        message: `Please enter text for "${question.label}".`,
                                        location: { sectionId, subsection1Id, subsection2Id, questionIndex }
                                    };
                                }
                            }
                            if (question.type === "select") {
                                if (!question.value) {
                                    return {
                                        valid: false,
                                        message: `Please select a value for "${question.label}".`,
                                        location: { sectionId, subsection1Id, subsection2Id, questionIndex }
                                    };
                                }
                            }
                        }

                        // 2) Check isAttachmentMandatory
                        // if (question.isAttachmentMandatory) {
                        //     if (!question.attachments || question.attachments.length === 0) {
                        //         return {
                        //             valid: false,
                        //             message: `Attachment is required for "${question.label}".`,
                        //             location: { sectionId, subsection1Id, subsection2Id, questionIndex }
                        //         };
                        //     }
                        // }
                    }
                }
            }
        }

        // If we never fail, it's valid:
        return { valid: true, message: "All required fields are filled.", location: null };
    }


    const renderResponse = (question, index, auditId, section, subsection1, selectedsubsection2, formId, id) => {



        const highlightThis =
            highlightedQuestion &&
            highlightedQuestion.sectionId === section &&
            highlightedQuestion.subsection1Id === subsection1 &&
            highlightedQuestion.subsection2Id === selectedsubsection2 &&
            highlightedQuestion.questionIndex === index;



        const previousResponses = supplierResponseData.find(sec => sec.id === id)


        let previousResponse = null;

        if (previousResponses) {
            const subsection1Data = previousResponses.assessmentSubSection1s.find(sec => sec.id === subsection1)

            if (subsection1Data) {
                const subsection2Data = subsection1Data.assessmentSubSection2s.find(sub2 => sub2.id === selectedsubsection2);

                if (subsection2Data && subsection2Data.form?.data1) {
                    const parsedQuestions = JSON.parse(subsection2Data.form.data1);
                    previousResponse = parsedQuestions.find(q => q.name === question.name);
                }
            }
        }

        console.log("Previous Response Found:", previousResponse);

        return (
            <>  <div key={question.id + Math.random()} className='questDisp row  m-0 p-0' style={{
                // If we should highlight, show a red border (or any style you prefer)
                border: highlightThis ? "2px solid red" : "none",
                padding: highlightThis ? "8px" : "0",

            }}>
                <div className="col-8 fs-16 fw-4">
                    <p style={{ color: question.isDedicated ? "red" : '#374151' }}>{question.label}</p>

                    {question.type === 'radio-group' ? (
                        <div className='grid m-0 p-0'>
                            {question.values.map((cb, cbind) => {
                                return (
                                    <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                        <RadioButton
                                            inputId={"rg" + index + cbind}
                                            name={cb.label}
                                            value={cb.value}
                                            disabled={!editable}
                                            onChange={(e) =>
                                                onRadioButtonSelected(
                                                    question,
                                                    cbind,
                                                    auditId,
                                                    section,
                                                    subsection1,
                                                    selectedsubsection2,
                                                    formId,
                                                    index
                                                )
                                            }
                                            checked={cb.selected || false} // Ensure `checked` reflects the updated state
                                        />
                                        <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                    </div>
                                )
                            })}
                        </div>

                    ) :
                        question.type === 'select' ? <Dropdown disabled={!editable} placeholder={question.placeholder} options={question.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={question.value} onChange={(e) => {
                            onChangeDropwdown(e.value,        // new textarea text
                                index,                 // question index
                                section,               // section id
                                subsection1,           // subsection1 id
                                selectedsubsection2)
                        }} />



                            : question.type === 'textarea' ? (
                                <InputTextarea
                                    disabled={!editable}
                                    placeholder="Enter your response here"
                                    value={question.value}
                                    style={{ width: '100%', height: 120, overflow: 'auto' }}
                                    onChange={(e) => {
                                        onTextareaChange(
                                            e.target.value,        // new textarea text
                                            index,                 // question index
                                            section,               // section id
                                            subsection1,           // subsection1 id
                                            selectedsubsection2    // subsection2 id
                                        );
                                    }}
                                />


                            ) : null}
                </div>
                <div className="col-2 flex justify-content-between" style={{ flexDirection: 'column' }}>
                    {question.isAttachmentMandatory && editable &&
                        <>

                            <div>

                                <AttachmentAsIcon mandatory={true}  edit={1} refId={section + subsection1 + selectedsubsection2 + index} getFiles={(e) => {

                                    console.log(e);
                                    onFileUpload(
                                        e,       // new textarea text
                                        index,                 // question index
                                        section,               // section id
                                        subsection1,           // subsection1 id
                                        selectedsubsection2
                                    );
                                }} documents={question.attachments} hint={''} />


                            </div>

                        </>
                    }
                    {!editable && question?.attachments?.length &&
                        <AttachmentAsIcon mandatory={true} edit={0} getFiles={(e) => {
                            onFileUpload(
                                e,       // new textarea text
                                index,                 // question index
                                section,               // section id
                                subsection1,           // subsection1 id
                                selectedsubsection2
                            );
                        }} documents={question.attachments} hint={''} />



                    }
                    {question.type !== 'textarea' && ((!editable && question.comment) || editable) && <div style={{ color: '#315975' }} className="" onClick={(e) => { e.stopPropagation(); handleComment(question, index, section, subsection1, selectedsubsection2); }}>
                        <i className="pi pi-comment fs-14 fw-6 mr-2" style={{ marginRight: '5px', cursor: 'pointer' }}></i>
                        <span>{question.comment ? editable ? 'Update ' : 'View' : 'Add '} Comment</span>
                    </div>}
                </div>
                <div className="col-2 ">
                    <p style={{ fontWeight: 'bold', color: '#315975' }}>Supplier Response</p>
                    <div style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px", minHeight: "50px" }}>

                        {question.type === 'radio-group' ?
                            (previousResponse && previousResponse.values
                                ? previousResponse.values.find(v => v.selected)?.label || "No previous response"
                                : "No previous response")
                            : question.type === 'textarea' ?
                                (previousResponse && previousResponse.value
                                    ? <p>{previousResponse.value}</p>
                                    : <p>No previous response</p>)
                                : ''
                        }

                        {/* Check if previousResponse exists before accessing comment */}
                        <p>{previousResponse?.comment ? previousResponse.comment : ''}</p>

                        {/* Check if previousResponse and attachments exist before accessing */}
                        {previousResponse?.attachments && previousResponse.attachments.length > 0 ? (
                            previousResponse.attachments.map(i => {
                                <div className="clr-navy grid m-0  p-0">
                                    <i className="pi pi-paperclip fs-14 fw-6 mr-2" />
                                    <label
                                        style={{ width: '80%' }}
                                        className='text-three-dot text-underline cur-pointer'
                                        onClick={() => { window.open(API.Docs + i.name) }}
                                    >
                                        {i.name}
                                    </label>
                                </div>
                            })

                        ) : (
                            <p>No attachments</p> // Show message when there are no attachments
                        )}
                    </div>
                </div>

            </div>
                < hr className="p-1 m-0" />
            </>
        )
    }


    const handleSubmit = () => {
        console.log(assessmentsection)
        const { valid, message, location } = validateAssessment(assessmentsection);

        if (!valid) {
            // Show an error message to the user (Swal, Toast, etc.)
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: message,
            });

            // location = { sectionId, subsection1Id, subsection2Id, questionIndex }
            // 1) Move the Stepper to the correct "section"
            const sectionIndex = assessmentsection.findIndex(
                (sec) => sec.id === location.sectionId
            );
            if (sectionIndex !== -1) {
                setActiveIndex(sectionIndex);  // or whatever function sets your stepper index
            }

            // 2) Expand the correct Accordion tab
            // You can find which sub1 is in the array:
            const sub1Index = assessmentsection[sectionIndex]?.assessmentSubSection1s?.findIndex(
                (s1) => s1.id === location.subsection1Id
            );
            // setActiveAccordionIndex(sub1Index) if you are using that logic

            // 3) Set selected sub-subsection
            const sub2Item = assessmentsection[sectionIndex]?.assessmentSubSection1s?.[sub1Index].assessmentSubSection2s?.find(
                (s2) => s2.id === location.subsection2Id
            );
            if (sub2Item) {
                setSelectedSubSection2(sub2Item.id);
                setSelectSection(sub2Item);
            }

            // 4) Optionally, highlight the question itself
            // For example, store a highlight state that renderResponse checks
            setHighlightedQuestion({
                sectionId: location.sectionId,
                subsection1Id: location.subsection1Id,
                subsection2Id: location.subsection2Id,
                questionIndex: location.questionIndex
            });

            return;
        }
        if ((result === 1 || result === 0) && auditId?.vendor?.code && auditId?.vendor?.id) {
            const requestBody = {
                type: 1,
                response: JSON.stringify(assessmentsection),
                modified_on: DateTime.utc(),
                modified_by: login_data.id,
                auditorMSIScore: grandTotalScore,
                submitted_on: DateTime.utc(),
                submitted_by: login_data.id,
                vendorCode: auditId?.vendor?.code,
                vendorId: auditId?.vendor?.id,
                userProfileId: admin_data.id
            };

            APIServices.post(API.AuditorAssessmentSubmission(auditId.id), requestBody)
                .then(response => {
                    Swal.fire("Success!", "Response Updated successfully.", "success").then(() => {
                        window.location.reload(); // Reloads the page after clicking OK
                    });
                    refresh();

                })
                .catch(error => {
                    console.log(error)
                    Swal.fire("Error!", "There was an issue submitting the response.", "error");
                });
        } else if (result === 2 && auditId?.vendor?.code && auditId?.vendor?.id && auditId?.auditorAssignmentSubmission?.id) {
            const requestBody = {

                modified_on: DateTime.utc(),
                modified_by: login_data.id,
                type: 1

            };

            APIServices.patch(API.AuditorAssessmentSubmission_Edit(auditId?.auditorAssignmentSubmission?.id), requestBody)
                .then(response => {
                    Swal.fire("Success!", "Response Updated successfully.", "success")
                    refresh();
                    closeModal(false)
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire("Error!", "There was an issue submitting the response.", "error");
                });
        }
    }

    const handleProgress = () => {

        if ((result === 1 || result === 0) && auditId?.vendor?.code && auditId?.vendor?.id && subsectiondata.id) {
            const requestBody = {
                type: 0,
                response: JSON.stringify(assessmentsection),
                created_on: DateTime.utc(), // If you need a timestamp
                modified_on: DateTime.utc(),
                created_by: login_data.id,
                userProfileId: admin_data.id,
                auditorMSIScore: grandTotalScore,
                status: 0,
                submitted_on: null,
                supplierAssessmentAssignmentId: auditId.id,
                submitted_by: null,
                vendorCode: auditId?.vendor?.code,
                vendorId: auditId?.vendor?.id
            };

            APIServices.post(API.AuditorChecklistSubmission_Custom(subsectiondata.id), requestBody)
                .then(response => {
                    if (response?.data?.result) {
                        setSupplierId(response?.data?.id || null)
                        setAssessmentSection(JSON.parse(response?.data?.response || '[]'))
                        setResult(response?.data?.id ? 2 : 0)
                        Swal.fire("Success!", "Response Updated successfully.", "success")
                        refresh();
                    }

                })
                .catch(error => {
                    Swal.fire("Error!", "There was an issue submitting the response.", "error");
                });
        } else if (result === 2 && auditId?.vendor?.code && auditId?.vendor?.id && subsectiondata.id) {
            const requestBody = {
                submitted_on: null,
                submitted_by: null,
                response: JSON.stringify(assessmentsection),
                modified_on: DateTime.utc(),
                supplierAssessmentAssignmentId: auditId.id,
                modified_by: login_data.id,
                auditorMSIScore: grandTotalScore,
                type: 0

            };

            APIServices.post(API.AuditorChecklistSubmission_Custom(subsectiondata.id), requestBody)
                .then(response => {
                    if (response?.data?.result) {
                        setSupplierId(response?.data?.id || null)
                        setAssessmentSection(JSON.parse(response?.data?.response || '[]'))
                        setResult(response?.data?.id ? 2 : 0)
                    }
                    refresh();
                    Swal.fire("Success!", "Response Updated successfully.", "success")
                })
                .catch(error => {


                    Swal.fire("Error!", "There was an issue submitting the response.", "error");
                });
        } else {

            Swal.fire("Error!", "There was an issue with saving section response.", "error");
            console.log(auditId, result)
        }
    };
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="col-12 flex justify-content-end">
                    <Button
                        label="Record Observation"
                        icon="pi pi-plus"
                        className=" mr-2"
                        onClick={openNew}
                    />
                </div>
            </React.Fragment>
        );
    };

    // ** FileUpload Handlers **

    // When user selects files (before upload)
    const handleFileUpload = async (event) => {
        console.log(event[0])
        const selectedFiles = event[0];


        const formData = new FormData();

        formData.append('file', selectedFiles);


        try {
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                Swal.fire({
                    title: 'Upload Successful!',
                    text: `${res?.data?.files?.length} file(s) uploaded successfully.`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
                setCurrentFinding((prev) => ({
                    ...prev,
                    auditorAttachments: [...prev.auditorAttachments, ...res.data.files], // Store uploaded file info
                }));
            })





        } catch (error) {
            console.error('File upload error:', error);
            Swal.fire({
                title: 'Upload Failed!',
                text: 'An error occurred while uploading. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
    };

    const handleFileRemove = (fileIndex) => {
        setCurrentFinding((prev) => ({
            ...prev,
            auditorAttachments: prev.auditorAttachments.filter((_, index) => index !== fileIndex), // Remove by index
        }));

        Swal.fire({
            title: 'Deleted!',
            text: 'File has been removed successfully.',
            icon: 'success',
            confirmButtonText: 'OK',
        });
    };


    // Custom upload handler (if user clicks "Upload" in FileUpload)
    const onFileUploadHandler = (event) => {
        // In a real app, you might upload to backend here.
        // For now, just store them in local state (if not already).
        setCurrentFinding((prev) => ({
            ...prev,
            auditorAttachments: [...prev.files, ...event.files],
        }));
        // Clear the file queue so we don’t double-store them
        event.options.clear();
    };
    const onFileUploadSuccess = (event) => {

        setCurrentFinding((prev) => ({
            ...prev,
            auditorAttachments: event,
        }));
        Swal.fire({
            title: 'Upload Successful!',
            text: 'Your files have been uploaded successfully.',
            icon: 'success',
            confirmButtonText: 'OK',
        });
    };
    const getNoOfCritical = () => {
        console.log(assessmentsection.flatMap(x => x?.assessmentSubSection1s?.flatMap(y => y?.assessmentSubSection2s?.flatMap(z => JSON.parse(z?.form?.data1 || '[]').flatMap(xyz => xyz)))).filter(x => x?.isDedicated))
        return assessmentsection.flatMap(x => x?.assessmentSubSection1s?.flatMap(y => y?.assessmentSubSection2s?.flatMap(z => JSON.parse(z?.form?.data1 || '[]').flatMap(xyz => xyz)))).filter(x => x?.isDedicated && x.type === "radio-group").filter(x => x?.values?.find(y => y?.selected && y?.label?.trim().toLowerCase() === 'yes'))?.length || 0

    }
    const onFileUploadError = (event) => {
        Swal.fire({
            title: 'Upload Failed!',
            text: 'An error occurred while uploading. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK',
        });
    };
    const categoryTemplate = (rowData) => {
        return categoryOptions.find(x => x.id === rowData.categoryOfFinding)?.label || 'NA'
    }
    const complianceTemplate = (rowData) => {
        return nonComplianceOptions.find(x => x.id === rowData.nonComplianceType)?.label || 'NA'
    }
    const lawTemplate = (rowData) => {
        return rowData?.applicableLaw === 0 ? rowData.otherLaw : lawsOption.find(x => x.id === rowData.applicableLaw)?.title || 'NA'
    }
    const stepss = assessmentsection
        ?.sort((a, b) => a.order - b.order)
        .map((section) => {
            // Validate only this one section by passing an array with a single element [section]
            const { valid, message } = validateAssessment([section]);
            console.log('steps', valid, message)
            return {
                label: section.title,
                id: section.id,
                completed: valid, // or use section.completed if you prefer
            };
        }) || [];
    return (
        <>


            <div className="row mb-3" style={{ padding: '10px' }}>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Self-assessment Due Date</p>
                    <p className="obs-content">
                        {getDate(auditId?.assessmentEndDate, 'dd MMM yyyy')}
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Self-assessment Submitted Date</p>
                    <p className="obs-content">
                        {auditId?.supplierAssignmentSubmission?.submitted_on ? getDate(auditId?.supplierAssignmentSubmission?.submitted_on, 'dd MMM yyyy') : 'Not Submitted'}
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Status</p>

                    <Tag style={{ width: 'unset' }} className={auditId?.auditorAssignmentSubmission ? auditId?.auditorAssignmentSubmission?.type ? "status-tag-green" : auditId?.auditorAssignmentSubmission?.rejected_on ? 'status-tag-red' : "status-tag-orange" : 'status-tag-red'} >{auditId?.auditorAssignmentSubmission ? auditId?.auditorAssignmentSubmission?.type ? auditId?.auditorAssignmentSubmission?.type === 1 ? "Completed" : "Approved" : auditId?.auditorAssignmentSubmission?.rejected_on ? "Retunred" : "In Progress" : 'Not Started'}</Tag>

                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Category</p>

                    <p className="obs-content">
                        {categoryList.find(x => x.value === auditId?.vendor?.supplierCategory)?.name || 'NA'}


                    </p>
                </div>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Vendor Code</p>
                    <p className="obs-content"> {auditId?.vendor?.code || 'NA'}</p>
                </div>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">No. Of Critical Regulatory Gaps Identified</p>
                    <p className="obs-content">
                        {getNoOfCritical()}
                    </p>
                </div>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">MSI Self-Assessment Score</p>
                    <p className="obs-content">
                        {auditId?.supplierAssignmentSubmission?.supplierMSIScore || '-'}
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">MSI Audit Score</p>
                    <p className="obs-content">
                        {grandTotalScore}
                    </p>
                </div>
                {!auditId?.auditorAssignmentSubmission?.type && auditId?.auditorAssignmentSubmission?.rejected_on && <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Approver Remarks</p>
                    <p className="obs-content">
                        {
                            auditId?.auditorAssignmentSubmission?.approverComments?.map(i =>
                                <div className="flex">
                                    {i.remarks}
                                </div>
                            )
                        }
                    </p>
                </div>}
            </div>

            {assessmentsection.length ?

                <Tab.Container id="left-tabs-example" className="mt-3 audit-tab" activeKey={assessmentsection?.[activeIndex]?.id} >
                    <Row style={{ marginTop: 20 }}>
                        <div style={{ width: '90%', margin: '0 auto' }}>
                            <Stepper
                                steps={stepss}
                                onClickStep={handleStepClick}
                                activeBGColor="green"
                                labelColor={labelColor}
                                activeIndex={activeIndex} // Current active step index
                            />
                        </div>

                        <Col sm={12}>

                            <Tab.Content>
                                {assessmentsection.map((section, index) => {

                                    return (<Tab.Pane eventKey={section.id} key={section.id}>
                                        <label className="mb-4" style={{ fontSize: 14, fontWeight: 'bold' }}> Expand each of the sections below, click on the sub-section and provide your responses to all the check points. Where required, attach relevant documents. Please note that check points in red are considered critical questions from TVS Motors perspective and require documentary evidence.</label>

                                        <Accordion activeIndex={activeAccordionIndex}
                                            onTabChange={(e) => {
                                                setActiveAccordionIndex(e.index);
                                                // RESET your sub-subsection selection so you don't see old data
                                                setSelectedSubSection2(null);
                                                setSelectSection(null);
                                            }}>
                                            {section?.assessmentSubSection1s?.sort((a, b) => { return a.order - b.order }, 0).map((subsection1) => {
                                                return (
                                                    <AccordionTab key={subsection1.id} header={`${subsection1.title} - ${subsection1.totalScore || 0}`} headerStyle={{ borderLeft: '5px solid ' + (checkSubSection1(subsection1) ? 'green' : '#F59E0B') }} onClick={() => setSelectSubSection1(subsection1)}>
                                                        {
                                                            <>
                                                                <Nav variant="pills" className="flex-row custom-nav">
                                                                    {subsection1?.assessmentSubSection2s?.map(subsection2 => (
                                                                        <Nav.Item key={subsection2.id}>
                                                                            <Nav.Link eventKey={subsection2.id} style={{ borderLeft: '5px solid ' + (checkQuestionary(subsection2?.form?.data1 || []) ? 'green' : '#F59E0B') }} onClick={(ev) => { setSelectedSubSection2(subsection2.id); setFormId(subsection2?.formId); setSelectSection(subsection2); }}  >

                                                                                {subsection2.title} - {subsection2?.form?.score || 0}
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    ))}
                                                                </Nav>




                                                                {selectedsubsection2 && selectSection && (
                                                                    <div>
                                                                        {selectSection.form?.data1 && (
                                                                            <div>
                                                                                {/* Parse and iterate over data1 */}
                                                                                {JSON.parse(selectSection.form.data1).map((question, index) => (
                                                                                    <div key={index}>
                                                                                        {renderResponse(
                                                                                            question,
                                                                                            index,
                                                                                            auditId.id,
                                                                                            section.id,
                                                                                            subsection1.id,
                                                                                            selectedsubsection2,
                                                                                            formId,
                                                                                            assessmentsection?.[activeIndex]?.id
                                                                                        )}


                                                                                    </div>


                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}


                                                                <Toolbar className="mb-4" right={auditId?.auditorAssignmentSubmission?.type ? <></> : leftToolbarTemplate} ></Toolbar>

                                                                <DataTable value={findings} responsiveLayout="scroll">
                                                                    <Column field="categoryOfFinding" header="Category" body={categoryTemplate}></Column>
                                                                    <Column field="nonComplianceType" header="Non-Compliance Type" body={complianceTemplate}></Column>
                                                                    <Column field="applicableLaw" header="Applicable Law" body={lawTemplate}></Column>
                                                                    <Column field="finding" header="Finding"></Column>
                                                                    <Column field="description" header="Description"></Column>
                                                                    {/* Example of showing how many files */}
                                                                    {/* <Column
                                                                    header="Files"
                                                                    body={(rowData) => rowData.auditorAttachments && rowData.auditorAttachments.length}
                                                                ></Column> */}
                                                                    {auditId?.auditorAssignmentSubmission?.type ? null : <Column body={actionBodyTemplate} header="Actions" style={{ minWidth: '8rem' }}></Column>}
                                                                </DataTable>
                                                            </>


                                                        }
                                                    </AccordionTab>
                                                )
                                            })
                                            }
                                        </Accordion>
                                    </Tab.Pane>)
                                })}
                            </Tab.Content>



                        </Col>


                    </Row>
                </Tab.Container>
                :
                <div className="col-12 flex justify-content-center"><i className="fs-36 pi pi-spin pi-spinner" /> </div>

            }

            <div className="mt-4" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                {/* Outlined + warning color */}
                {((assessmentsection?.length && selectedsubsection2 && selectSection && selectSection.form?.data1 && !auditId?.auditorAssignmentSubmission?.type)) && editable && <Button
                    label="Save Section Progress"
                    className=" p-button-warning"
                    onClick={() => handleProgress()}
                />}

                {/* Outlined + success color */}
                {validateAssessment(assessmentsection).valid && !auditId?.auditorAssignmentSubmission?.type && editable &&
                    <Button
                        label="Submit"
                        onClick={handleSubmit}
                        disabled={!validateAssessment(assessmentsection).valid}  // Only enabled if everything is completed
                    />}

            </div>




            <Dialog
                header={
                    'Add Comment '
                }
                visible={showModal}
                style={{ width: '75%' }}
                onHide={handleCloseModal}
            >
                <div>
                    <InputTextarea
                        value={comment}
                        disabled={!editable}
                        onChange={(e) => setComment(e.target.value)}
                        style={{ width: '100%', height: 150, overflow: 'auto' }}
                    />
                    <div className="flex justify-content-end col-12">
                        <Button
                            style={{ width: 110 }}
                            className="mr-2"
                            label="Close"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        />
                        {editable && <Button
                            style={{ width: 110 }}
                            label="Save & Exit"
                            onClick={() => handleSaveComment()}
                        />}
                    </div>
                </div>
            </Dialog>

            <Dialog
                visible={findingDialog}
                style={{ width: '65%' }}
                header={`Finding Details of "${selectSubSection1.title}"`}
                modal
                className="p-fluid"
                footer={findingDialogFooter}
                onHide={hideDialog}
            >
                <div className="field">
                    <label htmlFor="categoryOfFinding">Category of Finding</label>
                    <Dropdown
                        id="categoryOfFinding"
                        optionLabel="label"
                        optionValue="id"
                        value={currentFinding.categoryOfFinding}
                        options={categoryOptions}
                        onChange={(e) => setCurrentFinding({ ...currentFinding, categoryOfFinding: e.value })}
                        placeholder="Select Category"
                    />
                </div>

                {currentFinding.categoryOfFinding === 3 && (
                    <>
                        <div className="field">
                            <label htmlFor="nonComplianceType">Non-Compliance Type</label>
                            <Dropdown
                                id="nonComplianceType"
                                optionLabel="label"
                                optionValue="id"
                                value={currentFinding.nonComplianceType}
                                options={nonComplianceOptions}
                                onChange={(e) =>
                                    setCurrentFinding({ ...currentFinding, nonComplianceType: e.value })
                                }
                                placeholder="Select Type"
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="applicableLaw">Applicable Law</label>
                            <Dropdown
                                id="applicableLaw"
                                filter
                                panelClassName="hidefilter"
                                value={currentFinding.applicableLaw}
                                options={lawsOption}
                                optionLabel="title"
                                optionValue="id"
                                onChange={(e) => setCurrentFinding({ ...currentFinding, applicableLaw: e.value })}
                                placeholder="Select Law"
                            />
                        </div>
                        {currentFinding?.applicableLaw === 0 && <div className="field">
                            <label htmlFor="finding">Please mention name of Law<span className="ml-1 mandatory">*</span> </label>
                            <InputText
                                id="otherlaw"
                                value={currentFinding.otherLaw}
                                onChange={(e) => setCurrentFinding({ ...currentFinding, otherLaw: e.target.value })}
                            />
                        </div>}
                    </>
                )}



                <div className="field">
                    <label htmlFor="finding">Provide a short title for this observation</label>
                    <InputText
                        id="finding"
                        value={currentFinding.finding}
                        onChange={(e) => setCurrentFinding({ ...currentFinding, finding: e.target.value })}
                    />
                </div>

                <div className="field">
                    <label htmlFor="description">Describe the observation in detail. Where applicable, refer to the samples and supporting evidence for the finding.</label>
                    <InputTextarea
                        id="description"
                        rows={2}
                        value={currentFinding.description}
                        onChange={(e) => setCurrentFinding({ ...currentFinding, description: e.target.value })}
                    />
                </div>



                <div
                    style={{
                        marginBottom: "15px",
                        padding: "15px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        backgroundColor: "#f9f9f9",
                    }}
                >
                    <h1 style={{ marginBottom: "15px", fontSize: 15, color: "#333" }}>Upload Evidence</h1>

                    <input
                        type="file"
                        multiple
                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                        onChange={(e) => handleFileUpload(e.target.files)}
                        style={{
                            display: "block",
                            marginBottom: "15px",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            width: "100%",
                            backgroundColor: "#fff",
                            color: "#333",
                        }}
                    />

                    {/* Display Uploaded Files */}
                    {currentFinding.auditorAttachments?.length > 0 && (
                        <div style={{ marginTop: "10px" }}>
                            <h1 style={{ color: "#555", fontSize: 15, marginBottom: "10px" }}>Uploaded Files:</h1>
                            <ul style={{ listStyleType: "none", padding: 0 }}>
                                {currentFinding.auditorAttachments?.map((file, fileIndex) => (
                                    <li
                                        key={fileIndex}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginBottom: "8px",
                                            padding: "10px",
                                            backgroundColor: "#fff",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <span onClick={() => { window.open(API.Docs + file.originalname) }} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                            {file.originalname}
                                        </span>
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-danger p-button-sm"
                                            onClick={() => handleFileRemove(fileIndex)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>


            </Dialog>

            {/* DELETE CONFIRMATION DIALOG */}
            <Dialog
                visible={deleteDialog}
                style={{ width: '350px' }}
                header="Confirm"
                modal
                footer={deleteDialogFooter}
                onHide={hideDeleteDialog}
            >
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle mr-3"
                        style={{ fontSize: '2rem' }}
                    />
                    {currentFinding && (
                        <span>
                            Are you sure you want to delete <b>{currentFinding.finding}</b>?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};

const Stepper = ({ steps, onClickStep, labelColor = 'black', activeBGColor = '#6c757d', activeIndex = 0 }) => {

    console.log(steps)

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <div style={{ position: 'relative' }}>
                        <div
                            onClick={() => onClickStep && onClickStep(step, index)}
                            style={{
                                display: 'flex',
                                position: 'relative',
                                flexDirection: 'column',
                                zIndex: 1,
                                alignItems: 'center',
                                cursor: activeIndex !== index ? 'pointer' : 'default',
                                textAlign: 'center',
                                width: '170px', // Fixed width to keep all steps aligned
                            }}
                        >
                            <div
                                style={{
                                    //backgroundColor: (step.status === 1 ? activeBGColor : step.status === 0 ? 'rgb(255, 165, 0)' : 'gray'),
                                    backgroundColor: step.completed === undefined
                                        ? 'grey'
                                        : step.completed === true
                                            ? 'green'
                                            : 'rgb(255, 165, 0)',
                                    color: '#fff',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    transition: 'background-color 0.3s ease', // Smooth color transition
                                }}
                            >
                                {index + 1} {/* Display step number starting from 1 */}
                            </div>
                            <div
                                style={{
                                    fontSize: 16,
                                    marginTop: '8px',
                                    fontWeight: activeIndex === index ? 700 : 'normal', // Adjusted for 1-based index
                                    color: activeIndex === index ? labelColor : 'black',
                                    textDecoration: activeIndex === index ? 'underline' : 'none',
                                }}
                            >
                                {step.label}
                            </div>
                        </div>

                        {/* Add line between steps, except for the last step */}
                        {index < steps.length - 1 && (
                            <hr
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    left: '50%',
                                    top: '8px',
                                    width: '100%',
                                    position: 'absolute',
                                    zIndex: 0,
                                }}
                            />
                        )}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default AuditPanel;