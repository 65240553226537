import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import APIServices from "../service/APIService";
import { API } from "../constants/api_url";

const criteriaColors = {
    environment: "#41AA95",
    social: "#FF9D7C",
    governance: "#5B8FF7",
    general: "#4F4F4F"
};

const DealerReviewAction = ({ data, refresh,edit }) => {

    console.log(data)
    const [originalChecklist, setOriginalChecklist] = useState([]);
    const [modifiedChecklist, setModifiedChecklist] = useState([]);
    const [headersList, setHeadersList] = useState([]);
    const [error, setError] = useState("");

    // We track which question is in edit mode by indexes
    const [editIndex, setEditIndex] = useState({ headerIdx: null, criteriaIdx: null, qIdx: null });

    // We'll only allow editing actionToBeTaken & dueDate, but we'll display personResponsible
    const [editData, setEditData] = useState({
        actionToBeTaken: "",
        dueDate: null
    });

    useEffect(() => {
        if (data) {
            try {
                const parsedChecklist = JSON.parse(
                    data.dealerAuditorChecklistSubmission?.response || "[]"
                );

                setOriginalChecklist(parsedChecklist);

                // Clone the original for editing
                const cloned = JSON.parse(JSON.stringify(parsedChecklist));
                setModifiedChecklist(cloned);

                const grouped = buildHeadersAndCriteria(cloned);
                setHeadersList(grouped);
            } catch (e) {
                console.error(e);
                setOriginalChecklist([]);
                setModifiedChecklist([]);
                setHeadersList([]);
                setError("Invalid checklist format");
            }
        }
    }, [data]);

    const buildHeadersAndCriteria = (checklistArray) => {
        let currentHeaderLabel = null;
        const headersMap = {};

        for (const item of checklistArray) {
            if (item.type === "header") {
                currentHeaderLabel = item.label;
                if (!headersMap[currentHeaderLabel]) {
                    headersMap[currentHeaderLabel] = {
                        headerLabel: currentHeaderLabel,
                        groups: {}
                    };
                }
            } else if (item.type === "checklist-group") {
                if (!currentHeaderLabel) {
                    console.warn("Found checklist-group but no currentHeader:", item);
                    continue;
                }
                const criteriaKey = item.criteria || "general";
                if (!headersMap[currentHeaderLabel].groups[criteriaKey]) {
                    headersMap[currentHeaderLabel].groups[criteriaKey] = {
                        criteria: criteriaKey,
                        questions: []
                    };
                }
                headersMap[currentHeaderLabel].groups[criteriaKey].questions.push(
                    ...(item.questions || [])
                );
            }
        }

        const finalHeadersArray = Object.keys(headersMap).map((headerKey) => {
            const h = headersMap[headerKey];
            const groupArray = Object.keys(h.groups).map((critKey) => ({
                criteria: critKey,
                questions: h.groups[critKey].questions
            }));
            return {
                headerLabel: h.headerLabel,
                groups: groupArray
            };
        });

        return finalHeadersArray;
    };

    const renderSelectedOption = (options = []) => {
        const selected = options.find(opt => opt.checked === 1);
        return selected ? selected.label : "N/A";
    };

    // Edit button sets the fields we want to edit: actionToBeTaken, dueDate
    const handleEditClick = (question, headerIdx, criteriaIdx, qIdx) => {
        setEditIndex({ headerIdx, criteriaIdx, qIdx });
        setEditData({
            actionToBeTaken: question.actions?.actionToBeTaken || "",
            dueDate: question.actions?.dueDate ? new Date(question.actions.dueDate) : null
        });
    };

    const handleSaveClick = (headerIdx, criteriaIdx, qIdx) => {
        const updatedHeaders = [...headersList];
        const headerItem = updatedHeaders[headerIdx];
        if (!headerItem) return;

        const critGroup = headerItem.groups[criteriaIdx];
        if (!critGroup) return;

        const question = critGroup.questions[qIdx];
        if (!question || !question.actions) return;

        // Update only the two fields
        question.actions.actionToBeTaken = editData.actionToBeTaken;
        question.actions.dueDate = editData.dueDate
            ? editData.dueDate.toISOString()
            : null;

        setHeadersList(updatedHeaders);
        setEditIndex({ headerIdx: null, criteriaIdx: null, qIdx: null });
    };

    const handleCancelClick = () => {
        setEditIndex({ headerIdx: null, criteriaIdx: null, qIdx: null });
    };

    const getCriteriaColor = (criteria) => {
        return criteriaColors[criteria] || criteriaColors.general;
    };

    // Approve: send the final "modifiedChecklist" (which has updates)
    const handleApprove = () => {
        const finalPayload = JSON.stringify(modifiedChecklist);
        console.log("APPROVE -> sending updated data:\n", finalPayload);
        // e.g. call your API

        APIServices.post(API.Review_Submission_Dealer(data.id), {
            response: finalPayload,
            formId:data.formId,
            vendorId:data.vendorId,
            dealerId:data.dealerId,
            created_by:data.created_by
        }).then((res) => {
refresh()
        })
    };

    // Reject: revert changes to original
    const handleReject = () => {
        const revertedClone = JSON.parse(JSON.stringify(originalChecklist));
        setModifiedChecklist(revertedClone);

        const reGrouped = buildHeadersAndCriteria(revertedClone);
        setHeadersList(reGrouped);

        console.log("REJECT -> changes reverted to original data");
    };

    return (
        <div>
            <Card>
                {error && <Message severity="error" text={error} />}
                {headersList.length === 0 && <p>No data found.</p>}

                {headersList.map((headerItem, headerIdx) => (
                    <div key={headerIdx} style={{ marginBottom: "2rem" }}>
                        <h2 dangerouslySetInnerHTML={{ __html: headerItem.headerLabel }} />
                        <Divider />

                        {headerItem.groups.map((group, criteriaIdx) => {
                            const bgColor = getCriteriaColor(group.criteria);

                            return (
                                <div
                                    key={criteriaIdx}
                                    style={{
                                        marginBottom: "2rem",
                                    }}
                                >
                                    <h4 style={{ marginTop: 0, backgroundColor: bgColor, color: '#fff', padding: '7px 12px' }}>
                                        {group.criteria.toUpperCase()}
                                    </h4>
                                    <Divider />

                                    <div style={{ paddingLeft: "1rem" }}>
                                        {group.questions.map((q, qIdx) => {
                                            if (!q.actions) return null;

                                            const isEditing =
                                                editIndex.headerIdx === headerIdx &&
                                                editIndex.criteriaIdx === criteriaIdx &&
                                                editIndex.qIdx === qIdx;

                                            return (
                                                <div
                                                    key={qIdx}
                                                    style={{
                                                        marginBottom: "1rem",
                                                        border: "1px solid #ddd",
                                                        padding: "1rem",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#fff"
                                                    }}
                                                >
                                                    <strong>{q.label}</strong>
                                                    <p>
                                                        <b>Selected Option:</b>{" "}
                                                        {renderSelectedOption(q.options)}
                                                    </p>
                                                    <p>
                                                        <b>Remarks:</b> {q.remarks || "N/A"}
                                                    </p>

                                                    {/* Show personResponsible as read-only (no editing) */}
                                                    <p>
                                                        <b>Person Responsible:</b>{" "}
                                                        {q.actions.personResponsible || "N/A"}
                                                    </p>

                                                    <div
                                                        style={{
                                                            backgroundColor: "#f9f9f9",
                                                            padding: "0.5rem",
                                                            borderRadius: "6px",
                                                            marginTop: "0.5rem"
                                                        }}
                                                    >
                                                        {isEditing ? (
                                                            <>
                                                                <div className="p-field">
                                                                    <label>
                                                                        <b>Action To Be Taken:</b>
                                                                    </label>
                                                                    <br />
                                                                    <InputText
                                                                        value={editData.actionToBeTaken}
                                                                        onChange={(e) =>
                                                                            setEditData((prev) => ({
                                                                                ...prev,
                                                                                actionToBeTaken: e.target.value
                                                                            }))
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className="p-field mt-2">
                                                                    <label>
                                                                        <b>Due Date:</b>
                                                                    </label>
                                                                    <br />
                                                                    <Calendar
                                                                        value={editData.dueDate}
                                                                        onChange={(e) =>
                                                                            setEditData((prev) => ({
                                                                                ...prev,
                                                                                dueDate: e.value
                                                                            }))
                                                                        }
                                                                        dateFormat="dd-mm-yy"
                                                                    />
                                                                </div>

                                                                <div className="p-mt-2">
                                                                    <Button
                                                                        label="Save"
                                                                        icon="pi pi-check"
                                                                        className="p-mr-2"
                                                                        onClick={() =>
                                                                            handleSaveClick(headerIdx, criteriaIdx, qIdx)
                                                                        }
                                                                    />
                                                                    <Button
                                                                        label="Cancel"
                                                                        icon="pi pi-times"
                                                                        severity="danger"
                                                                        onClick={handleCancelClick}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p>
                                                                    <b>Action To Be Taken:</b>{" "}
                                                                    {q.actions.actionToBeTaken || "N/A"}
                                                                </p>
                                                                <p>
                                                                    <b>Due Date:</b>{" "}
                                                                    {q.actions.dueDate
                                                                        ? moment(q.actions.dueDate).format("DD-MM-YYYY")
                                                                        : "N/A"}
                                                                </p>

                                                            {edit === 1 &&    <Button
                                                                    label="Edit"
                                                                    icon="pi pi-pencil"
                                                                    className="p-button-text p-mt-2"
                                                                    onClick={() =>
                                                                        handleEditClick(q, headerIdx, criteriaIdx, qIdx)
                                                                    }
                                                                />}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </Card>

          {edit === 1 &&  <div style={{ textAlign: "right", marginTop: "2rem" }}>
                <Button
                    label="Save and Assign Action"
                    icon="pi pi-check"
                    className="p-mr-2"
                    onClick={handleApprove}
                />
                {/* <Button
                    label="Reject"
                    icon="pi pi-times"
                    severity="danger"
                    onClick={handleReject}
                /> */}
            </div>}
        </div>
    );
};

export default DealerReviewAction;
