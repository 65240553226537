import React, { useState, useEffect } from "react";
import APIServices from "../service/APIService";
import { API } from "../constants/api_url";
import { AttachmentComponent } from "../components/Forms/Attachment";

import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useSelector } from "react-redux";

const ApproveSupplierAction = ({ data, refresh }) => {
    const [rootCause, setRootCause] = useState("");
    const [correctiveAction, setCorrectiveAction] = useState("");
    const [actionDesc, setActionDesc] = useState("");
    const [document, setDocument] = useState([]);
    const [applicableLaw, setApplicableLaw] = useState("");
    const [commands, setCommands] = useState("");      // <-- New state for Commands
    const [errors, setErrors] = useState({});
    const login_data = useSelector((state) => state.user.userdetail);
    const vendorCode = useSelector((state) => state.user.currentVendorCode);

    const nonComplianceOptions = [
        { label: "Regulatory (Major)", id: 1 },
        { label: "Regulatory (Minor)", id: 2 },
        { label: "Minor", id: 3 },
    ];
    const nonComplianceLabel =
        nonComplianceOptions.find((option) => option.id === data.nonComplianceType)?.label || "Unknown";

    // Fetch applicable law
    useEffect(() => {
        APIServices.get(API.GetList_DD(2))
            .then((res) => {
                if (res?.data && Array.isArray(res.data)) {
                    const foundLaw = res.data.find((law) => law.id === data.applicableLaw);
                    setApplicableLaw(foundLaw ? foundLaw.title : "Not Available");
                } else {
                    setApplicableLaw("Not Available");
                }
            })
            .catch((error) => {
                console.error("Error fetching applicable law:", error);
                setApplicableLaw("Error fetching data");
            });
    }, [data.applicableLaw]);

    // Extract supplier action history including attachments
    useEffect(() => {
        if (data.supplierActionHistories && data.supplierActionHistories.length > 0) {
            const lastAction = data.supplierActionHistories[data.supplierActionHistories?.length -1];
            setRootCause(lastAction.rootCause || "");
            setCorrectiveAction(lastAction.correctiveAction || "");
            setActionDesc(lastAction.actionTaken || "");
        }
    }, [data]);

    // Validation
    const validateFields = () => {
        let newErrors = {};

        if (!rootCause.trim()) newErrors.rootCause = "Root cause is required.";
        if (!correctiveAction.trim()) newErrors.correctiveAction = "Corrective action is required.";
        if (!actionDesc.trim()) newErrors.actionDesc = "Action description is required.";
        if (document.length === 0) newErrors.document = "You must upload at least one document.";
        if (!commands.trim()) newErrors.commands = "Commands field is required."; // <-- Validation for the new field

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Common function to submit the patch call (approve = true or false)
    const handleSubmit = (rejectValue) => {
        if (!validateFields()) return;


        if (rejectValue === 0) {
            try {
                APIServices.patch(API.SupplierAction_Edit(data.id), {
                    approved_on: new Date().toISOString(),
                    approved_by: login_data.id,
                    type: 3,
                    reject: 0
                })

                APIServices.patch(API.SupplierActionHistory_Edit(data.supplierActionHistories[data.supplierActionHistories.length - 1]?.id), {
                    approved_on: new Date().toISOString(), approved_by: login_data.id, type: 2, reject: 0, approverComments: commands

                })

            } catch {

            } finally {
                refresh()
            }


        } else if (rejectValue === 1) {
            try {
                APIServices.patch(API.SupplierAction_Edit(data.id), {

                    type: 1,
                    reject: 1
                }).then((res) => {

                })

                APIServices.patch(API.SupplierActionHistory_Edit(data.supplierActionHistories[data.supplierActionHistories.length - 1]?.id), {
                    returned_on: new Date().toISOString(),
                    returned_by: login_data.id,
                    type: 1,
                    reject: 1,
                    approverComments: commands

                }).then((res) => {

                })
            } catch {

            } finally {
                refresh()
            }

        }


    };

    return (
        <div className="container-fluid">
            {/* Action Details */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Observation</h4>
                <Divider />
                <div className="row">
                    <div className="col-md-6">
                        <p><strong>ID:</strong> {data.actionId}</p>
                        <p><strong>Findings:</strong> {data.finding}</p>
                        <p><strong>Description:</strong> {data.description}</p>
                    </div>
                    <div className="col-md-6">
                        <p><strong>Non-Compliance Type:</strong> {nonComplianceLabel}</p>
                        <p><strong>Applicable Law:</strong> {applicableLaw}</p>
                    </div>
                </div>
            </Card>

            {/* Input Fields */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Action Taken by Supplier</h4>
                <Divider />

                {/* Root Cause */}
                <div className="mb-4">
                    <h5 className="text-secondary">Identify the Root Cause(s)</h5>
                    <div
                        className="border p-2"
                        style={{ backgroundColor: "#f8f9fa" }}
                        dangerouslySetInnerHTML={{ __html: rootCause }}
                    />
                    {errors.rootCause && <Message severity="error" text={errors.rootCause} />}
                </div>

                {/* Corrective Actions */}
                <div className="mb-4">
                    <h5 className="text-secondary">Identified Corrective Actions</h5>
                    <div
                        className="border p-2"
                        style={{ backgroundColor: "#f8f9fa" }}
                        dangerouslySetInnerHTML={{ __html: correctiveAction }}
                    />
                    {errors.correctiveAction && <Message severity="error" text={errors.correctiveAction} />}
                </div>

                {/* Action Description */}
                <div className="mb-4">
                    <h5 className="text-secondary">Description of the Action Taken</h5>
                    <div
                        className="border p-2"
                        style={{ backgroundColor: "#f8f9fa" }}
                        dangerouslySetInnerHTML={{ __html: actionDesc }}
                    />
                    {errors.actionDesc && <Message severity="error" text={errors.actionDesc} />}
                </div>
            </Card>

            {/* Attachment */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Upload Evidence</h4>
                <Divider />
                <AttachmentComponent
                    mandatory={true}
                    edit={0}
                    getFiles={(e) => setDocument(e)}
                    documents={
                        data.supplierActionHistories[data.supplierActionHistories.length - 1]?.supplierAttachments || []
                    }
                    labels="Please attach any evidence or records to support the data input."
                    hint=""
                />
                {errors.document && <Message severity="error" text={errors.document} />}
            </Card>

            {/* Commands Textarea */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">{'Comments'}</h4>
                <Divider />
                <textarea
                    className="form-control"
                    rows={3}
                    value={data.type === 3 ? data.supplierActionHistories[data.supplierActionHistories.length - 1]?.approverComments : commands}
                    disabled={data.type !== 2}
                    onChange={(e) => setCommands(e.target.value)}
                    placeholder="Enter your comments here..."
                />
                {errors.commands && <Message severity="error" text={errors.commands} />}
            </Card>

            {/* Approve / Reject Buttons */}
            {data.type === 2 && <div className="flex justify-content-end mt-3 gap-2">
                <Button
                    label="Approve"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => handleSubmit(0)}   // reject=0
                />
                <Button
                    label="Reject"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => handleSubmit(1)}   // reject=1
                />
            </div>}
        </div>
    );
};

export default ApproveSupplierAction;
