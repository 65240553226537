
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";

const initialState = {

    userList: [],
    admindetail:{},
    clientList:[],
    supplierList: [],
    dealerList: [],
    tvsExtUserList: []
}
export const fetchUserList = createAsyncThunk('api/userlist', async (cid) => {
    return await APIServices.get(API.UserProfile).then((res) => [ {id:cid,information:{empname:'Enterprise Admin'},clientId:cid }, ...res.data.filter((i)=>{return (i.clientId === cid || i.id === cid) })] )  
   
})
export const fetchClientList = createAsyncThunk('api/clientlist', async () => {
    return await APIServices.get(API.UserProfile).then((res) => res.data)  
   
})


const userProfileList = createSlice({
    name: 'userlist',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUserList.fulfilled, (state, action) => { return {...state,supplierList:action.payload.filter((i)=>{return i.role === 'clientsupplier'}), userList : action.payload.filter((i)=>{return (i.role === 'clientuser' || i.role === 'clientadmin')}),dealerList : action.payload.filter((i)=>{return (i.role === 'clientdealer')}) ,tvsExtUserList: action.payload.filter((i) => { return (i.role === 'clientextuser') }),  admindetail : action.payload.filter((i)=>{return i.role === 'clientadmin'})[0]  }})
        .addCase(fetchClientList.fulfilled, (state, action) => { return {...state,clientList : action.payload.filter((i)=>{return i.role === 'clientadmin' && i.role !== 'eisqradmin' })  }})

    }



})

export default userProfileList.reducer

