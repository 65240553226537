import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from 'luxon';
import { Tag } from 'primereact/tag';

const DealersTableCompleted = ({ data,dealerList, assessorList, globalFilter, editDealer }) => {
    const zonalOfficeList = [{ name: "Central", value: 1 }, { name: "East", value: 2 }, { name: "North", value: 3 }, { name: "South1", value: 4 }, { name: "South2", value: 5 }, { name: "West1", value: 6 }, { name: "West2", value: 7 }]


    const pyInvoiceBodyTemplate = (rowData) => {
        return `${rowData.pyInvoiceValue} Cr. INR`;
    };
    const calibrationIdBodyTemplate = (rowData) => {
        return (
            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                {'MSI-' + (rowData?.code || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMMyyyy')}
            </a>
        );
    };
    const dealerType = [{ name: 'Authorized Main Dealer', value: 1 }, { name: 'Authorized Dealer', value: 2 }, { name: 'Area Office', value: 3 }, { name: 'Authorized Parts Stockist (APS)', value: 4 }]

    const calibiratorTemplate = (rowData) => {
        console.log(rowData)
        if (rowData?.assessors?.length) {
            return rowData?.assessors.map(x => assessorList.find(i => i.id === x)).filter(i => i).map(i => i?.information?.empname || '')
        } else {
            return 'Not Assigned'
        }
    }
    const nameTemplate = (rowData) => {
        console.log(rowData.vendorId, dealerList)
        return (

            rowData?.vendor?.dealerName || 'NA'


        );
    };

    const contact1Template = (rowData) => {
        return (

            rowData.dealer?.information?.principalName || 'NA'

        );
    };
    const contact2Template = (rowData) => {
        return (

            rowData.dealer?.information?.workersmName || 'NA'

        );
    };
    const actionBodytemplate = (rowData) => {
        return (
            <div>
                <button className="btn btn-sm btn-primary" onClick={() => { editDealer(rowData) }}>Edit</button>
            </div>
        )
    }
    const contact3Template = (rowData) => {
        return (

            rowData.dealer?.information?.salesmName || 'NA'

        );
    };
    const locationTemplate = (rowData) => {
        return (

            rowData.vendor?.dealerLocation || 'NA'

        );
    };
    const zoneTemplate = (rowData) => {
        return (

            zonalOfficeList.find(x => x.value === rowData?.vendor?.dealerZone)?.name || 'NA'

        );
    };
    const categoryTemplate = (rowData) => {
        return (
            dealerType.find(x => x.value === rowData.dealer?.information?.dealerType)?.name || 'NA'

        );
    };
    const statusTemplate = (rowData) => {
return rowData?.dealerAuditorChecklistSubmission?.type === 1 ? <Tag className='status-tag-green' >Completed</Tag> : rowData?.dealerAuditorChecklistSubmission?.type === 0 ? <Tag className='status-tag-orange' >In Progress</Tag> :  <Tag className='status-tag-gray' >Not Started</Tag>
    }
    const statusOfActionsTemplate = (rowData) => {
        return <span>{rowData.statusOfActions}</span>;
    };

    const handleCalibrationClick = (rowData) => {
        // Logic when calibration ID is clicked
        alert('Clicked on: ' + rowData.calibrationId);
    };

    return (
        <DataTable value={data} paginator rows={10} responsiveLayout="scroll" globalFilter={globalFilter} className="mt-2 h-500">
            <Column field="calibrationId" header="Calibration ID" body={calibrationIdBodyTemplate} ></Column>
            <Column field="name" header="Name" body={nameTemplate}></Column>
            <Column field="location" header="Location" body={locationTemplate}></Column>
            <Column field="location" header="Zone" body={zoneTemplate}></Column>
            {/* <Column field="pinCode" header="Pin Code" body={pincodeTemplate}></Column> */}
            {/* <Column field="dealerContact" header="Dealer Contact" ></Column> */}
            {/* <Column field="pyInvoiceValue" header="Spent by TVS(in INR)" body={pyInvoiceBodyTemplate} ></Column> */}
            {/* <Column field="status" header="Status" body={statusTemplate}  ></Column> */}
          
            <Column field="category" header="Category" body={categoryTemplate}></Column>
            <Column field="status" header="Latest Self-assessment Month" ></Column>
            <Column field="status" header="MSI Self-assessment Score" ></Column>
            <Column field="msiScore" header="MSI Calibration Score" sortable></Column>
            <Column field="msiRating" header="MSI Rating" ></Column>
         
          
            {/* <Column field="selfAssessmentReportingMonth" header="Self-Assessment Reporting Month" ></Column> */}
           
            <Column field="calibrationDate" header="Planned Calibration Date" ></Column>
            <Column field="calibrationTeamMember" header="Calibration Team Member" body={calibiratorTemplate}></Column>
            <Column field="calibrationStatus" header="Calibration Status" ></Column>
{/*            
            <Column field="statusOfActions" header="Status of Actions" body={statusOfActionsTemplate}></Column>
            <Column field="zonalManager" header="Zonal Manager" body={contact1Template}></Column>
            <Column field="areaManager" header="Area Manager" body={contact2Template}></Column>
            <Column field="territoryManager" header="Territory Manager" body={contact3Template}></Column> */}
            {/* <Column header="Action" body={actionBodytemplate}></Column> */}

        </DataTable>
    );
};

export default DealersTableCompleted;
