import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import CriticalNonCompliances from "../NonComplianceComponent";

const SubGraph3Demo = ({ supplyData,count=0 }) => {
  const [chartData, setChartData] = useState([]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            fontSize: "14px",
            fontFamily: "Lato",
            lineHeight: "1.5",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
          {payload.map((entry) => (
            <p key={entry.name} style={{ margin: 0, color: "black" }}>{`${entry.name
              }:${entry.name === "Maximum" ? 10 : entry.value}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
          padding: 0,
          marginTop: "10px",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              color: entry.color,

              marginRight: "5px",
            }}
          >
            <span
              style={{
                color: entry.color,
                backgroundColor: entry.color,
                marginRight: 4,
                fontSize: "20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}

      </ul>
    );
  };

  useEffect(() => {
    console.log("supplyData:", supplyData);
    if (supplyData.length > 0) {
      // Compute average scores for environmental factors
      const totalSuppliers = 1;

      const totalWater = supplyData.filter(x=>x.title === 'Water').reduce((sum, item) => sum + item.totalScore, 0);
      const totalWaste = supplyData.filter(x=>x.title === 'Waste').reduce((sum, item) => sum + item.totalScore, 0);
      const totalProductStewardship = supplyData.filter(x=>x.title === 'Product Stewardship').reduce(
        (sum, item) => sum + item.totalScore,
        0
      );
      const totalEnergy = supplyData.filter(x=>x.title === 'Energy').reduce(
        (sum, item) => sum + item.totalScore,
        0
      );

      const avgWater = (totalWater / totalSuppliers).toFixed(1);
      const avgWaste = (totalWaste / totalSuppliers).toFixed(1);
      const avgProductStewardship = (
        totalProductStewardship / totalSuppliers
      ).toFixed(1);
      const avgEnergy = (totalEnergy / totalSuppliers).toFixed(1);

      setChartData([
        { category: "Water", maxScore: 10 - avgWater, avgScore: avgWater },
        { category: "Waste", maxScore: 10 - avgWaste, avgScore: avgWaste },
        { category: "Energy", maxScore: 10 - avgEnergy, avgScore: avgEnergy },
        {
          category: "Product Stewardship",
          maxScore: 10 - avgProductStewardship,
          avgScore: avgProductStewardship,
        },
      ]);
    }
  }, [supplyData]);

  const wrapText = (text, width = 40) => {
    let words = text.split(" ");
    let lines = [];
    let currentLine = "";

    words.forEach((word) => {
      if ((currentLine + " " + word).length > width) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += (currentLine ? " " : "") + word;
      }
    });

    lines.push(currentLine); // Push the remaining line
    return lines.map((line, index) => (
      <tspan key={index} x="0" dy={index === 0 ? 0 : 10}>
        {line}
      </tspan>
    ));
  };

  const CustomizedTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          textAnchor="middle"
          fontSize={10}
          fill="#666"
          dominantBaseline="middle"
        >
          {wrapText(payload.value, 20)} {/* Wrap text with custom width */}
        </text>
      </g>
    );
  };

  return (
    <div className="container mt-4">
      <h5 className="mb-3 text-center text-dark">
        Environmental Section Performance
      </h5>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData} barSize={50}>
          <XAxis
            dataKey="category"
            fontSize={12}
            tick={<CustomizedTick />} // Custom tick rendering
            interval={0} // Show all labels
          />
          <YAxis domain={[0, 10]} />
          <Tooltip content={CustomTooltip} />
          <Legend content={CustomLegend} />
          <Bar dataKey="avgScore" stackId="a" fill="#2C7C69" name="Achieved">
            <LabelList
              dataKey="avgScore"
              position="insideBottom"
              style={{ fontSize: "12px", fill: "white" }}
            />
          </Bar>
          <Bar dataKey="maxScore" stackId="a" fill="#7FC8A9" name="Maximum">
            <LabelList
              position="top" // Position label above the bar
              content={({ x, y, width, height }) => {
                return (
                  <text
                    x={x + width / 2} // Center the label
                    y={y - 10} // Position label slightly above the top of the bar
                    textAnchor="middle"
                    fill="black"
                    fontSize="12px"
                  >
                    10
                  </text>
                );
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="col-12 flex justify-content-center">
        <CriticalNonCompliances count={count} />

      </div>
    </div>
  );
};

export default SubGraph3Demo;
