import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import APIServices from '../../service/APIService';
import { API } from '../../constants/api_url';
import { DateTime } from 'luxon';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useSelector } from 'react-redux';
import useForceUpdate from 'use-force-update';
import { MultiSelect } from 'primereact/multiselect';
import SuppliersTableCompleted from './SuppliersTableCompleted';
import DealersTableCompleted from './DealersTableCompleted';

const MSIDealerReviewerDashboard = () => {
    // Mock authorization states
    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const allRoles = useSelector((state) => state.user.allRoles);
    const [show, setShow] = useState(false)
    const [isSupplierScheduler, setIsSupplierScheduler] = useState((login_data.role === 'clientadmin' || allRoles.some((el) => [12, 21].includes(el))));   // Display supplier buttons if true
    const [isDealerScheduler, setIsDealerScheduler] = useState((login_data.role === 'clientadmin' || allRoles.some((el) => [13, 22].includes(el))));       // Display dealer button if true
    const [assobj, setAssObj] = useState({ supplierId: null, assessmentEndDate: null, auditStartDate: null, auditEndDate: null, group1: { sections: [], assessors: [], updated_on: '', updated_by: null }, group2: { sections: [], assessors: [], updated_on: '', updated_by: null }, group3: { sections: [], assessors: [], updated_on: '', updated_by: null }, group4: { sections: [], assessors: [], updated_on: '', updated_by: null } })
    const [dealerassobj, setDealerAssObj] = useState({ dealerId: null, assessmentStartDate: null, assessors: [], auditStartDate: null })
    const supplierList = useSelector(state => state.userlist.supplierList)
    const dealerList = useSelector(state => state.userlist.dealerList)

    const userList = useSelector(state => state.userlist.userList)
    const tvsExtUserList = useSelector(state => state.userlist.tvsExtUserList)

    const forceUpdate = useForceUpdate();
    const categoryList = [{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }]

    const [activeIndex, setActiveIndex] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');
    const [adddialog, setAddDialog] = useState(false)
    const [assauditdialog, setAssAuditDialog] = useState(false)
    const [assdealerdialog, setAssDealerDialog] = useState(false)

    const [submitted, setSubmitted] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const [dealerData, setDealerData] = useState([]);
    const [assessmentsections, setAssessmentSections] = useState([])
    const [supplierassessorlist, setSupplierAssessorList] = useState([])
    const [dealerassessorlist, setDealerAssessorList] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [supplierTabIndex, setSupplierTabIndex] = useState(0);
    const [dealerTabIndex, setDealerTabIndex] = useState(0);
    const onOptionChange = (e) => {
        setSelectedOption(e.value);
    };

    const dropdownOptions = [];
    if (isSupplierScheduler) {
        dropdownOptions.push({ label: 'Suppliers', value: 'suppliers' });
    }
    if (isDealerScheduler) {
        dropdownOptions.push({ label: 'Dealers', value: 'dealers' });
    }

    console.log(supplierList)
    useEffect(() => {
        let dealerAssUri = {
            "include": ['dealer', 'dealerAuditorChecklistSubmission', 'vendor']
        }

        const promise0 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise1 = APIServices.get(API.DealerAssessmentAss_Up(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(dealerAssUri))}`)


        Promise.all([promise0, promise1]).then((values) => {

            setDealerData(values[1].data.filter(i=>i?.assessors?.includes(login_data.id)))
            setDealerAssessorList([...tvsExtUserList, ...userList].filter(i => values[0].data.some(x => x.user_id === i.id && x.roles && x.roles.includes(19))))

        })
    }, []);
   
    function transformDealerData(data) {
        console.log(data)
        return data.flatMap(item => {
            if (!item.vendorCodes || !Array.isArray(item.vendorCodes)) {
                // Handle undefined or null vendorCodes
                return null
            }
            const { vendorCodes, ...rest } = item
            // Transform vendorCodes into individual objects
            console.log(vendorCodes)
            return item.vendorCodes.map(vendor => ({
                customId: vendor.userProfileId + '~' + vendor.code + '~' + vendor.id,
                customLabel: vendor.code + ' : ' + vendor?.dealerName,
                ...rest,
                dealerCode: vendor.code,
            }));
        }).filter(x => x)
    }
    return (
        <div className="p-4">
            <h1>MSI Calibration Status</h1>

     
                <div className='mt-4'>

                



                        {allRoles.includes(20) &&

                            <DealersTableCompleted
                                data={dealerData.filter(x => (x.dealerAuditorChecklistSubmission && x.dealerAuditorChecklistSubmission?.type === 1))}
                                dealerList={transformDealerData(dealerList)}
                                assessorList={userList}
                                globalFilter={globalFilter}
                      
                            />
                        }

                </div>
        



        </div>
    );
};

export default MSIDealerReviewerDashboard;
