import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useSelector } from "react-redux";
import { API } from "../constants/api_url";
import APIServices from "../service/APIService";
import { AttachmentAsIcon } from "../components/Forms/AttachmentAsIcon";
import { Tooltip } from "primereact/tooltip";

const statusColors = {
    Completed: "#29C76F",
    "In Progress": "#F5C37B",
    "Not Started": "#CCCED5",
    Blocked: "#fff",
};

const QualitativeData = ({ data }) => {
    const login_data = useSelector((state) => state.user.userdetail);
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [formData, setFormData] = useState({});

    const openDialog = (item) => {
        console.log(item, "Open Item");
        setSelectedItem(item);

        const prefilled = {};
        item.form?.forEach((field) => {
            if (field.type === "radio-group" || field.type === "textarea") {
                prefilled[field.name] = field.value || "";
            }
        });

        setFormData(prefilled);
        setVisible(true);
    };

    const handleSave = async (status) => {
        if (!selectedItem?.id) return;
        formData["status"] = status;
        try {
            const payload = {
                response: {
                    [login_data.id]: formData
                },
                status,
            };

            const response = await APIServices.patch(`${API.SaveQualitativeResponse(selectedItem.id)}`, payload);

            if (response.status === 204) {
                console.log("Saved successfully:", status);
                setVisible(false);
            } else {
                console.error("Save failed", response);
            }
        } catch (err) {
            console.error("Error saving response", err);
        }
    };

    return (
        <div className="p-4 border rounded-lg shadow-sm bg-white mb-4">
            <h2 className="fs-4 fw-bold mb-4">{data[0]?.title || "Environment"}</h2>

            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                <table className="table table-bordered" style={{ minWidth: "1000px", width: "100%" }}>
                    <tbody>
                        {data.map((env, index) => (
                            <tr key={index}>
                                <td
                                    className="p-4 align-top bg-white"
                                    style={{
                                        width: "300px",
                                        minWidth: "250px",
                                        position: "sticky",
                                        left: 0,
                                        zIndex: 2,
                                    }}
                                >
                                    <h3 className="fw-semibold fs-5">{env.subHeading}</h3>
                                    <p className="text-muted">{env.location}</p>
                                </td>

                                <td className="p-0">
                                    <div style={{ display: "flex", overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100%" }}>
                                        {env.data.map((item, i) => {
                                            const isBlocked = item.status === "Blocked";

                                            return (
                                                <div
                                                    key={i}
                                                    className={`d-flex flex-column border flex-shrink-0 ${isBlocked ? "text-muted" : "hover-effect"}`}
                                                    style={{
                                                        width: "250px",
                                                        minWidth: "250px",
                                                        cursor: isBlocked ? "not-allowed" : "pointer",
                                                        opacity: isBlocked ? 0.8 : 1,
                                                        pointerEvents: isBlocked ? "none" : "auto",
                                                        borderColor: isBlocked ? "transparent" : undefined,
                                                    }}
                                                    onClick={() => {
                                                        if (!isBlocked) openDialog(item);
                                                    }}
                                                >
                                                    <div
                                                        className="w-100"
                                                        style={{
                                                            height: "10px",
                                                            backgroundColor: statusColors[item.status] || "#fff"
                                                        }}
                                                    ></div>
                                                    <div className="p-3 flex-grow-1">
                                                        <h5 className="fw-bold text-wrap" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                            {item.name}
                                                        </h5>
                                                        {!isBlocked && <p className="text-muted mt-2">
                                                            Due date:<br /> {item.dueDate}
                                                        </p>}
                                                    </div>
                                                    {item.isLocked && (
                                                        <div className="p-2 d-flex justify-content-end">
                                                            <i className="pi pi-lock text-secondary"></i>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Dialog */}
            <Dialog header={selectedItem?.name} visible={visible} style={{ width: "50vw" }} onHide={() => setVisible(false)}>
                <div className="p-3">
                    <h6 className="fw-bold">Instructions for entering data:</h6>
                    <p className="text-muted">Enter your response in the provided text box. Be as clear, specific, and accurate as possible.</p>
                    <ul>
                        <li>If supporting documents are required, use the Upload button to attach files.</li>
                        <li>Save your progress regularly by clicking Save Draft.</li>
                    </ul>

                    {Array.isArray(selectedItem?.form) && selectedItem.form.map((field, index) => (
                        <div className="mt-3" key={index}>
                            {field.type === "textarea" && (
                                <>
                                    <label className="fw-bold">{field.label} {field.required && <span className="mandatory mr-2">*</span>}
                                        {field.description && <span><i style={{
                                            fontSize: '14px',
                                            marginLeft: '2px'
                                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${field.description}`}

                                        > help</i></span>} </label>
                                    <div className="col-12 flex justify-content-end"> </div>
                                    <InputTextarea
                                        className="w-100 mt-2"
                                        value={formData[field.name] || ""}
                                        onChange={(e) => setFormData({ ...formData, [field.name]: e.target.value })}
                                    />
                                </>
                            )}

                            {field.type === "radio-group" && (
                                <>
                                    <Tooltip target={".tooltip" + index} position='top' />

                                    <label className="fw-bold">{field.label} {field.required && <span className="mandatory mr-2">*</span>}
                                        {field.description && <span><i style={{
                                            fontSize: '14px',
                                            marginLeft: '2px'
                                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${field.description}`}

                                        > help</i></span>}
                                    </label>
                                    <div className="mt-2">
                                        {field.values.map((option, i) => (
                                            <div className="form-check" key={i}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={field.name}
                                                    id={`${field.name}_${option.value}`}
                                                    value={option.value}
                                                    checked={formData[field.name] === option.value}
                                                    onChange={(e) => setFormData({ ...formData, [field.name]: e.target.value })}
                                                />
                                                <label className="form-check-label" htmlFor={`${field.name}_${option.value}`}>
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>

                <div className="d-flex justify-content-start gap-2 mt-4">
                    <Button
                        label="Mark as Completed"
                        className="p-button-sm p-button-primary"
                     
                        style={{ backgroundColor: "#004C77", borderColor: "#004C77" }}
                        onClick={() => handleSave("Completed")}
                    />
                    <Button
                        label="Save Progress"
                        className="p-button-sm p-button-outlined p-button-primary"
                        style={{ color: "#004C77", borderColor: "#004C77" }}
                        onClick={() => handleSave("In Progress")}
                    />
                    <Button
                        label="Cancel"
                        className="p-button-sm p-button-text"
                        style={{ color: "#004C77" }}
                        onClick={() => setVisible(false)}
                    />
                </div>
            </Dialog>
        </div>
    );
};

export default QualitativeData;
