import React from "react";

const CriticalNonCompliances = ({ count }) => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid black",
    borderRadius: "4px",
    padding: "10px",
    width: '90%',
    backgroundColor: "#f8f9fa",
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
  };

  const circleStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "red",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
  };

  return (
    <div style={containerStyle}>
      <span>No. of critical regulatory non-compliances</span>
      <div style={circleStyle}>{count}</div>
    </div>
  );
};

export default CriticalNonCompliances;
