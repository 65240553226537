import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { InputNumber } from "primereact/inputnumber";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";

import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { Slider } from "primereact/slider";
import { BGSQ14, BGSQ15, BGSQ21, BGSQ24, BP1EQ2, BP4EQ2, BP7EQ1B, BP7EQ2, BP7LQ1, BP8EQ1, BP8LQ1, BP8LQ2, BP8LQ4, BP8LQ5, BP8LQ6, BP9LQ1, BP2LQ1, BP2LQ2, BP6EQ10, BP6EQ11, BP6EQ12, BP6LQ6, SGXGSQ7, SGXGSQ6, SGXGSQ4 } from "../../components/hardcoded/hardcodedRF";
import { hardcoded } from "../../components/hardcoded/hardcodedid";
import APIServices from "../../service/APIService";
import { AttachmentComponent } from "../../components/Forms/Attachment";
import { DateTime } from "luxon";
import { getRPTextFormat } from "../../components/BGHF/helper";
import { ReadMoreComponent } from "../../components/Forms/ReadMoreComponent";
window.jQuery = $;
window.$ = $;

const QualitativeNewSubmission = () => {
    const login_data = useSelector((state) => state.user.userdetail);
    const userList = useSelector(state => state.userlist.userList)

    const [response, setResponse] = useState({})
    const [data, setData] = useState([])
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const admin_data = useSelector((state) => state.user.admindetail)
    const [status, setStatus] = useState(true)

    const { id, params, submitId } = JSON.parse(sessionStorage.getItem('dfpreview'))
    const [document, setDocument] = useState(null)
    // const hardcodedrf = ['85', '89', '102', '104', '110', '111', '112', '113', '114', '115', '116', '117', '118', '121', '133', '134', '139', '140','148','149','150','151','181','182','183']
    const [show, setShow] = useState(true)
    useEffect(async () => {
        console.log(params)
        setStatus(params.state.status)
        APIServices.get(API.RF_Edit(id)).then((res) => {
            if (submitId) {
                APIServices.get(API.QL_Submission_Edit(submitId)).then((res2) => {

                    setResponse(res2.data)
                    if (hardcoded.rf.includes((id).toString())) {
                        res.data.data1 = res2.data.response
                    } else {
                        res.data.data1 = migrateResponse(res.data.data1, res2.data.response)
                    }


                    setData(res.data)

                })
            } else {
                APIServices.post(API.QL_Recent_RP_Data, { dfId: id, userProfileId: admin_data.id, categoryId: params.state.data.df.cat_id, topicId: params.state.data.df.top_id, indicatorId: params.state.data.df.id, reporting_period: params.state.data.reporting_period, locationId: params.state.data.locationId, level: params.state.data.level }).then((res3) => {
                    if (res3.data.result) {
                        if (hardcoded.rf.includes(id.toString())) {
                            res.data.data1 = res3.data.data.response
                        } else {
                            res.data.data1 = migrateResponse(res.data.data1, res3.data.data.response)
                        }
                        setData(res.data)
                    } else {
                        setData(res.data)
                    }
                })

            }

        })



    }, [login_data]);
    const getUser = (id) => {
        let user_name = 'Not Found'
        if (id === admin_data.id) {
            return 'Enterprise Admin'
        }
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }
    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }
    const checkHardcoded = () => {
        console.log(id)
        if (hardcoded.rf.includes(id.toString())) {
            return true
        } else {
            return false
        }

    }
    const migrateResponse = (formData, oldData) => {

        formData.forEach((i) => {
            let index = oldData.findIndex((j) => { return getType(j.type) === i.type && i.name === j.name })
            if (index !== -1) {
                if (i.type !== 'checkbox-group' && i.type !== 'radio-group') {

                    i.value = oldData[index].value
                } else {

                    i.values.forEach((k, l) => {

                        k.selected = oldData[index].value.includes(k.value)
                    })
                }
            }
        })
        console.log(formData)
        return formData
    }
    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.label === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const getDisplayStatus = (rp) => {
        console.log(rp)
        const [startMonth, endMonth] = rp.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days <= 0;

    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <Calendar placeholder={item.placeholder} className="col-5 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputNumber min={0} placeholder={item.placeholder} maxFractionDigits={(item.fraction === undefined || item.fraction) ? 0 : item.fraction < 0 ? 0 : item.fraction} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-18 fw-5 text-justify fw-7" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>} </label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}

                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <Editor className="text-area" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >
                        <div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF,.xls,.xlsx,.ppt,.doc,.docx,.pptx" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>
                        {item.value != null && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (!(item.value != null)) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()

            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && item.value !== null && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined && item.value !== null) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value !== null && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            } else if (item.type === 'file') {
                item['value'] = []
            }


        })


        return data_
    }


    const checkResponse_ = () => {
        console.log(data)
        if (data.data1.length === 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Data set is Empty, requires minimum 1 record to submit/draft",
                showConfirmButton: false,
                timer: 1500,
            });
            return false
        } else {

            return true
        }
    }

    const getType = (item) => {
        if (item === 1) {
            return 'text'
        } else if (item === 2) {
            return 'textarea'
        } else if (item === 3) {
            return 'select'
        } else if (item === 4) {
            return 'checkbox-group'
        } else if (item === 5) {
            return 'number'
        } else if (item === 6) {
            return 'radio-group'
        } else if (item === 7) {
            return 'file'
        } else if (item === 'paragraph') {
            return 'paragraph'
        } else if (item === 9) {
            return 'date'
        }
    }

    const reduceResponse = (item) => {
        if (item.type === 'text') {
            return { type: 1, value: item.value === undefined ? '' : item.value, name: item.name }
        } else if (item.type === 'textarea') {

            return { type: 2, value: item.value === undefined ? '<p></p>' : item.value, name: item.name }
        } else if (item.type === 'select') {

            return { type: 3, value: item.value === undefined ? null : item.value, name: item.name }
        } else if (item.type === 'checkbox-group') {
            return { type: 4, value: item.values.map((k, i) => { if (k.selected === true) { return k.value } }).filter((i) => { return i !== undefined }), name: item.name }
        } else if (item.type === 'number') {
            return { type: 5, value: item.value, name: item.name }
        } else if (item.type === 'radio-group') {
            return { type: 6, value: item.values.map((k, i) => { if (k.selected === true) { return k.value } }).filter((i) => { return i !== undefined }), name: item.name }
        } else if (item.type === 'file') {
            return { type: 7, value: item.value, name: item.name }
        } else if (item.type === 'paragraph') {
            return item
        } if (item.type === 'date') {
            return { type: 9, value: item.value, name: item.name }
        }
    }
    const saveAsDraft = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        console.log(data.data1)
        if (true) {
            if (!submitId) {
                if (document) {
                    newObj['documents'] = document
                } else {
                    newObj['documents'] = null
                }
                newObj['dfId'] = parseInt(id)
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['edit'] = 0
                newObj['categoryId'] = params.state.data.df.cat_id
                newObj['indicatorId'] = params.state.data.df.id
                newObj['topicId'] = params.state.data.df.top_id
                newObj['reject'] = 0
                newObj['locationId'] = params.state.data.locationId
                newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
                newObj['level'] = params.state.data.level
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['self'] = true
                newObj['form_type'] = 1
                newObj['response_type'] = 1

                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
                newObj['type'] = 0
                newObj['entityUserAssId'] = params.state.data.id
                newObj['entityAssId'] = params.state.data.entityAssId
                newObj['frequency'] = params.state.data.frequency
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = dt
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id

                console.log(newObj)
                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true
                })
                if (isConfirmed) {
                    if (typeof return_remarks === 'string' && return_remarks.trim()) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                    }
                    APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Changes Saved Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }


            } else {
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['type'] = 0

                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if ((response.return_remarks !== null && response.return_remarks.filter(i => (i.user_type === 2 || i.user_type === 3)).length !== 0) ? !value.trim() : false) {
                            return 'Enter Remarks'
                        }
                    }
                })

                if (isConfirmed) {


                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() })
                            newObj['return_remarks'] = lt
                        }


                    }
                    APIServices.patch(API.QL_Submission_Edit(submitId), newObj).then((res) => {
                        Swal.fire({
                            title: "Form Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }
            }
        }
        forceUpdate()
    }
    const saveRF = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        console.log(data.data1)
        if (checkResponse()) {
            if (!submitId) {
                if (document) {
                    newObj['documents'] = document
                } else {
                    newObj['documents'] = null
                }
                newObj['dfId'] = parseInt(id)
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['edit'] = 0
                newObj['categoryId'] = params.state.data.df.cat_id
                newObj['indicatorId'] = params.state.data.df.id
                newObj['topicId'] = params.state.data.df.top_id
                newObj['reject'] = 0
                newObj['locationId'] = params.state.data.locationId
                newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
                newObj['level'] = params.state.data.level
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['self'] = true
                newObj['form_type'] = 1
                newObj['response_type'] = 1

                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
                newObj['type'] = 1
                newObj['entityUserAssId'] = params.state.data.id
                newObj['entityAssId'] = params.state.data.entityAssId
                newObj['frequency'] = params.state.data.frequency
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = dt
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id

                console.log(newObj)
                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true
                })
                if (isConfirmed) {
                    if (typeof return_remarks === 'string' && return_remarks.trim()) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                    }
                    APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Changes Saved Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }


            } else {
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['reject'] = 0
                newObj['type'] = 1

                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if ((response.return_remarks !== null && response.return_remarks.filter(i => (i.user_type === 2 || i.user_type === 3)).length !== 0) ? !value.trim() : false) {
                            return 'Enter Remarks'
                        }
                    }
                })

                if (isConfirmed) {


                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() })
                            newObj['return_remarks'] = lt
                        }


                    }
                    APIServices.patch(API.QL_Submission_Edit(submitId), newObj).then((res) => {
                        Swal.fire({
                            title: "Form Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }
            }
        }
        forceUpdate()

    }
    const saveRFNA = () => {
        let newObj = {}

        newObj['rfid'] = parseInt(id)
        newObj['framework'] = params.state.data.tags

        newObj['response'] = []
        newObj['type'] = 0
        newObj['edit'] = 1
        newObj['categoryId'] = params.state.data.cat_id
        newObj['indicatorId'] = params.state.data.id
        newObj['topicId'] = params.state.data.top_id

        newObj['coverage'] = params.state.data.coverage
        newObj['level'] = params.state.data.level
        newObj['entity_type'] = params.state.data.type



        newObj['submitted_by'] = login_data.id

        newObj['created_on'] = moment.utc()

        APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
            Swal.fire({
                title: "Submitted as NA",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.close()
                }
            })
        }).catch((e) => {
            Swal.fire({
                title: "Something went wrong, try after some time. Contact admin if issue still persist",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.close()
                }
            })

        })




        forceUpdate()

    }
    const saveAsDraft2 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (data.data1.length !== 0) {
            if (!submitId) {

                if (document) {
                    newObj['documents'] = document
                } else {
                    newObj['documents'] = null
                }
                newObj['dfId'] = parseInt(id)
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['edit'] = 0
                newObj['categoryId'] = params.state.data.df.cat_id
                newObj['indicatorId'] = params.state.data.df.id
                newObj['topicId'] = params.state.data.df.top_id
                newObj['reject'] = 0
                newObj['locationId'] = params.state.data.locationId
                newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
                newObj['level'] = params.state.data.level
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['self'] = true
                newObj['form_type'] = 2
                newObj['response_type'] = 1

                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
                newObj['type'] = 0
                newObj['entityUserAssId'] = params.state.data.id
                newObj['entityAssId'] = params.state.data.entityAssId
                newObj['frequency'] = params.state.data.frequency
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = dt
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id


                APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Changes Saved Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })
            } else {
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['type'] = 0


                APIServices.patch(API.QL_Submission_Edit(submitId), newObj).then((res) => {
                    Swal.fire({
                        title: "Form Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })

            }


        } else {
            Swal.fire({
                title: "Cannot submit as empty",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                Swal.close()
            })



        }

        forceUpdate()
    }

    const saveRF__ = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (data.data1.length !== 0) {
            if (!submitId) {

                if (document) {
                    newObj['documents'] = document
                } else {
                    newObj['documents'] = null
                }
                newObj['dfId'] = parseInt(id)
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['edit'] = 0
                newObj['categoryId'] = params.state.data.df.cat_id
                newObj['indicatorId'] = params.state.data.df.id
                newObj['topicId'] = params.state.data.df.top_id
                newObj['reject'] = 0
                newObj['locationId'] = params.state.data.locationId
                newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
                newObj['level'] = params.state.data.level
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['self'] = true
                newObj['form_type'] = 2
                newObj['response_type'] = 1

                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
                newObj['type'] = 1
                newObj['entityUserAssId'] = params.state.data.id
                newObj['entityAssId'] = params.state.data.entityAssId
                newObj['frequency'] = params.state.data.frequency
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = dt
                newObj['last_modified_on'] = dt
                newObj['reporter_modified_on'] = dt
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_by'] = login_data.id

                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true
                })



                if (isConfirmed) {
                    if (typeof return_remarks === 'string' && return_remarks.trim()) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                    }
                    APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Changes Saved Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }


            } else {
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['reject'] = 0
                newObj['type'] = 1

                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if ((response.return_remarks !== null && response.return_remarks.filter(i => (i.user_type === 2 || i.user_type === 3)).length !== 0) ? !value.trim() : false) {
                            return 'Enter Remarks'
                        }
                    }
                })

                if (isConfirmed) {


                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() })
                            newObj['return_remarks'] = lt
                        }


                    }
                    APIServices.patch(API.QL_Submission_Edit(submitId), newObj).then((res) => {
                        Swal.fire({
                            title: "Form Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }
            }
        } else {
            Swal.fire({
                title: "Cannot submit as empty",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                Swal.close()
            })


        }

        forceUpdate()

    }
    const saveRFNA__ = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (document) {
            newObj['documents'] = document
        } else {
            newObj['documents'] = null
        }
        newObj['dfId'] = parseInt(id)
        newObj['response'] = []
        newObj['edit'] = 0
        newObj['categoryId'] = params.state.data.df.cat_id
        newObj['indicatorId'] = params.state.data.df.id
        newObj['topicId'] = params.state.data.df.top_id
        newObj['locationId'] = params.state.data.locationId
        newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
        newObj['level'] = params.state.data.level
        newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
        newObj['self'] = true
        newObj['form_type'] = params.state.data.type
        newObj['response_type'] = 1

        newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
        newObj['type'] = 1
        newObj['entityUserAssId'] = params.state.data.id
        newObj['entityAssId'] = params.state.data.entityAssId
        newObj['frequency'] = params.state.data.frequency
        newObj['submitted_by'] = login_data.id
        newObj['submitted_on'] = dt

        newObj['last_modified_on'] = dt
        newObj['reporter_modified_on'] = dt
        newObj['reporter_modified_by'] = login_data.id
        newObj['last_modified_by'] = login_data.id

        APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
            Swal.fire({
                title: "Submitted as NA",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.close()
                }
            })
        }).catch((e) => {
            Swal.fire({
                title: "Something went wrong, try after some time. Contact admin if issue still persist",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.close()
                }
            })

        })




        forceUpdate()

    }
    const saveRF_ = () => {
        let newObj = {}
        console.log(data.justification)
        let dt = DateTime.utc()
        if (typeof data.justification === 'string' && data.justification.trim().length !== 0) {

            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dfId'] = parseInt(id)
            newObj['response'] = []
            newObj['edit'] = 0
            newObj['categoryId'] = params.state.data.df.cat_id
            newObj['indicatorId'] = params.state.data.df.id
            newObj['topicId'] = params.state.data.df.top_id
            newObj['reject'] = 0
            newObj['locationId'] = params.state.data.locationId
            newObj[`tier${params.state.data.level}_id`] = params.state.data.locationId
            newObj['level'] = params.state.data.level
            newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
            newObj['self'] = true
            newObj['form_type'] = 3
            newObj['response_type'] = 1

            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 1
            newObj['entityUserAssId'] = params.state.data.id
            newObj['entityAssId'] = params.state.data.entityAssId
            newObj['frequency'] = params.state.data.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id


            console.log(newObj)
            APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                Swal.fire({
                    title: "Changes Saved Successfully",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })



        }
        forceUpdate()

    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }


    return (
        <div className="grid" style={{ margim: 10 }} >
            <div className="col-12">
                {(login_data.id !== undefined && data.length !== 0) ?
                    <div>
                        <div className="fs-20 fw-4  clr-gray-900">
                            <div >Qualitative Inputs pertaining to <span className="fw-7 clr-navy">{data.title}</span> for the Reporting Period  <span className="fw-7 clr-navy">{params.state.data.reporting_period}</span> regarding the entity <span className="fw-7 clr-navy">{params.state.data.coverage_}</span> &nbsp; <small style={{ color: 'grey' }}>(DF {data.id})</small>   </div>



                        </div>


                        <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }}  >
                        {data?.comments &&
                                    <ReadMoreComponent content={data.comments} />

                                }
                            <div className="grid col-12 justify-content-between">
                                <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Aspects:&nbsp;</span> <div className="clr-gray-900 fs-16 fw-7" style={{ display: 'flex' }}>{params.state.data.df.title}   <Tooltip className="tag-tooltip" target={".tags"} position={'top'} autoHide={true}> {params.state.data.df.overallTags.map((i, j) => {
                                    if (i.length !== 0) {
                                        return (
                                            <>
                                                <label style={{ color: 'black', display: 'flex' }}> {
                                                    j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                                }
                                                </label>
                                                {
                                                    i.map((tag, k) => {

                                                        return (
                                                            <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                                        )

                                                    })
                                                }
                                                <div style={{ marginBottom: 10 }} />
                                            </>
                                        )
                                    }
                                })} </Tooltip>
                                    <div style={{ alignItems: 'center' }} ><i className={"material-icons ml-2 tags"} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

                                </div>  </div>
                                <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Category:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.data.df.cat_title}  </span>  </div>


                            </div>
                            <div className="grid col-12 justify-content-between mt-2">
                                <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Requirement:&nbsp;</span>  <span className="clr-gray-900 fw-7">{params.state.data.df.data1[0].title}</span> </div>
                                <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Topic:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.data.df.top_title}   </span>  </div>

                                {/* <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Reporting Period:&nbsp;</span> <span className="clr-gray-900 fw-7">  {params.state.data.reporting_period}</span>  </div> */}
                            </div>
                            {submitId && <div className="grid col-12 justify-content-between mt-2">
                                <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4"> {(response.type === 0 && !response.reject) ? 'Drafted On' : (response.type === 0 && response.reject) ? 'Rejected On' : response.type === 1 ? 'Submitted On' : (response.type === 3) ? 'Approved On' : ''}&nbsp;</span>  <span className="clr-gray-900 fw-7">{DateTime.fromISO(response.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy hh:mm a')}</span> </div>
                                <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4"> {(response.type === 0 && !response.reject) ? 'Drafted By' : (response.type === 0 && response.reject) ? 'Rejected By' : response.type === 1 ? 'Submitted By' : (response.type === 3) ? 'Approved By' : ''}&nbsp;</span> <span className="clr-gray-900 fw-7">{getUser(response.last_modified_by)}   </span>  </div>


                            </div>}
                        </div>

                        {/* end */}

                        <div >

                            {(data.data1.length !== 0 && !checkHardcoded(id)) ?
                                <div>
                                    <div className="bg-white" style={{ padding: 24 }} >
                                        {
                                            data.data1.map((item, index) => {

                                                return renderItems(item, index)
                                            })

                                        }
                                        <div className="m-3">
                                            <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Add Supporting Document '} hint={'Please attach any evidence or records to support the data inputted.'} />
                                        </div>
                                    </div>
                                    <div style={{ borderTop: '1px solid #E0E0E0' }} />


                                    <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                    <div className="bg-white grid m-0 p-0" style={{ paddingTop: 24 }} >
                                        <label className="col-5 fw-7 fs-16">Comments</label>
                                        <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                            {submitId && response !== undefined && response.return_remarks !== null &&
                                                response.return_remarks.map((cmnt) => {
                                                    return (
                                                        <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                            <div className="col-5">
                                                                <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                            </div>
                                                            <div className="col-5">
                                                                {cmnt.remarks}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                        <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                            <Button label='Close' className="mr-2" onClick={() => { window.close() }} text></Button>

                                            {(!submitId || (submitId && response.type <= 0)) && <Button label='Save as Draft' onClick={() => { saveAsDraft() }} ></Button>}

                                            {((!submitId || (submitId && response.type === 0)) && getDisplayStatus(params.state.data.reporting_period)) && <Button label='Save & Submit' onClick={() => { saveRF() }} ></Button>}

                                        </div>
                                    </div>
                                </div>
                                : checkHardcoded(id) &&
                                <div>
                                    <div className="bg-white" style={{ padding: 24 }}>
                                        {id === '85' ?
                                            <div>
                                                <BGSQ14 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                            </div>
                                            : id === '89' ?
                                                <div>
                                                    <BGSQ15 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                </div> : id === '102' ?
                                                    <div>
                                                        <BGSQ21 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                    </div> : id === '104' ?
                                                        <div>
                                                            <BP1EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '110' ? <div>
                                                            <BGSQ24 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '111' ? <div>
                                                            <BP4EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '112' ? <div>
                                                            <BP7EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '113' ? <div>
                                                            <BP7LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '114' ? <div>
                                                            <BP8LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '115' ? <div>
                                                            <BP8LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '116' ? <div>
                                                            <BP8LQ4 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '117' ? <div>
                                                            <BP8LQ5 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '118' ? <div>
                                                            <BP8LQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '121' ? <div>
                                                            <BP7EQ1B data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '133' ? <div>
                                                            <BP8EQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '134' ? <div>
                                                            <BP9LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '139' ? <div>
                                                            <BP2LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '140' ? <div>
                                                            <BP2LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '148' ? <div>
                                                            <BP6EQ10 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '149' ? <div>
                                                            <BP6EQ11 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '150' ? <div>
                                                            <BP6EQ12 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '151' ? <div>
                                                            <BP6LQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '181' ? <div>
                                                            <SGXGSQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '182' ? <div>
                                                            <SGXGSQ7 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '183' &&
                                                        <div>
                                                            <SGXGSQ4 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div>
                                        }
                                        <div className="m-3">
                                            <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Add Supporting Document '} hint={'Please attach any evidence or records to support the data inputted.'} />
                                        </div>
                                    </div>



                                    <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                    <div className="bg-white grid m-0 p-0" style={{ paddingTop: 24 }} >
                                        <label className="col-5 fw-7 fs-16">Comments</label>
                                        <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                            {submitId && response !== undefined && response.return_remarks !== null &&
                                                response.return_remarks.map((cmnt) => {
                                                    return (
                                                        <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                            <div className="col-5">
                                                                <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                            </div>
                                                            <div className="col-5">
                                                                {cmnt.remarks}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                        <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                            <Button label='Close' onClick={() => { window.close() }}></Button>

                                            {(!submitId || (submitId && response.type <= 0)) && <Button label='Save as Draft' onClick={() => { saveAsDraft2() }} ></Button>}

                                            {((!submitId || (submitId && response.type === 0)) && getDisplayStatus(params.state.data.reporting_period)) && <Button label='Save & Submit' onClick={() => { saveRF__() }} ></Button>}


                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>
                    :
                    <></>
                }
            </div>
        </div >
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QualitativeNewSubmission, comparisonFn);
