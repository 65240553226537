import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { ContextMenu } from "primereact/contextmenu";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Tag } from "primereact/tag";
import { SplitButton } from "primereact/splitbutton";
import Axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { ref } from "yup";
import * as XLSX from "xlsx";
import { InputNumber } from "primereact/inputnumber";
import FileSaver from "file-saver";
import { AutoComplete as AC } from "primereact/autocomplete";
import {
    GoogleMap,
    useJsApiLoader,
    Autocomplete,
} from "@react-google-maps/api";
import { gastype } from "../../constants/EmissionFactor/Fugitive/gastype";
import { fuelcategory } from "../../constants/EmissionFactor/Stationary Combustion/fuelcategory";
import { bt_road_category } from "../../constants/EmissionFactor/Business Travel/road_category";
import IATACodes from "../../assets/dummy/IATACodes";

import { mobile_combustion } from "../../constants/EmissionFactor/Mobile Combustion/mobile_combustion";
import { purchase_gs } from "../../constants/EmissionFactor/Purchase Goods & Services/purchase_gs";
import { currency_codes } from "../../constants/EmissionFactor/Purchase Goods & Services/currency_codes";
import APIServices from "../../service/APIService";
import { DateTime } from "luxon";
import { TabMenu } from "primereact/tabmenu";
import { ListBox } from "primereact/listbox";
import { Accordion, AccordionTab } from "primereact/accordion";
const google = window.google;

const getUploadedFileSize = (files) => {
    let fileSize = 0;
    if (files !== null) {
        files.forEach((file) => {
            fileSize = fileSize + file.size;
        });
    }
    return fileSize;
};
function isDateInRange({ minDate, maxDate }, utcDate) {
    const min = new Date(minDate);
    const max = new Date(maxDate);
    const dateToCheck = new Date(utcDate);

    return dateToCheck >= min && dateToCheck <= max;
}
const getDate = (date, format) => {

    if (typeof date === 'string') {
        return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
    } else if (DateTime.isDateTime(date)) {
        return date.toFormat(format ? format : 'dd-MM-yyyy')
    } else {
        return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
    }

};
const getYearRange = (startDate, endDate) => {
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    if (startYear === endYear) {
        return `${startYear}:${startYear}`;
    } else if (endYear === startYear + 1) {
        return `${startYear}:${endYear}`;
    } else {
        throw new Error('Date range should not span more than two years');
    }
}
const dateTemplate = (date) => {
    console.log(date)
    return (
        <span className="p-disabled">

        </span>
    );

};



const uploadFilesApi = async (file, filelist) => {
    let promise = new Promise((resolve, rej) => {
        if (getUploadedFileSize(filelist) + file.size <= 10000000) {
            let formData = new FormData();
            formData.append("file", file);
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
                mode: "no-cors",
            }).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    resolve({
                        url: API.Docs + res.data.files[0].originalname,
                        name: res.data.files[0].originalname,
                        size: res.data.files[0].size,
                    });
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });

    return promise;
};
const dateFormatting = (rowData) => {
    return (
        <>
            <span>{moment(rowData.DPA0137).local().format("DD-MM-YYYY")}</span>
        </>
    );
};
const dateFormatting_sc = (rowData) => {

    return (
        <>
            <span>{moment(rowData.DPAN460).local().format("DD-MM-YYYY")}</span>
        </>
    );
};
const dateFormatting_bt = (rowData) => {
    return (
        <>
            <span>{moment(rowData.DPA0290).local().format("DD-MM-YYYY")}</span>
        </>
    );
};
const dateFormatting_pg = (rowData) => {
    return (
        <>
            <span>{moment(rowData.DPA0285).local().format("DD-MM-YYYY")}</span>
        </>
    );
};
const dateFormatting_cg = (rowData) => {
    return (
        <>
            <span>{moment(rowData.DPAN1131).local().format("DD-MM-YYYY")}</span>
        </>
    );
};
const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};
const getID = () => {
    return (
        parseInt(Date.now() * Math.random()) +
        moment().unix() +
        randomIntFromInterval(1, 1000)
    );
};
const exportToCSV = (csvData, sheetname, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {
        Sheets: {
            [sheetname]: ws,
        },
        SheetNames: [sheetname],
    };
    const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
    });
    const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
};

const excelDateToJSDate = (excelDate) => {
    var date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
    var converted_date = date.toISOString().split("T")[0];
    return date;
};

//Stationary combustion
const Stationary_Combustion = ({ data, getData, setData, edit }) => {
    const [scdata, setSCData] = useState(data);
    const forceUpdate = useForceUpdate();
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [scfilter, setScFilter] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [fuelcatlist, setFuelCatList] = useState([]);
    const [fuellist, setFuelList] = useState([]);
    const [scVal, setSCVal] = useState({
        DPAN460: DateTime.utc().toJSDate(),
        DPA0130: null,
        DPA0131: null,
        DPA0132: null,
        DPA0133: "",
        DPA0134: "",
        DPA0336: "",
        attachment: null,
    });
    const [scdialog, setScDialog] = useState(false);
    useEffect(() => {
        let fuel_cat = [];
        fuelcategory.forEach((i) => {
            if (
                fuel_cat.findIndex((k) => {
                    return k.name === i.fuel_type;
                }) === -1
            ) {
                fuel_cat.push({ name: i.fuel_type });
            }
        });
        setFuelCatList(fuel_cat);
    }, []);
    useState(() => {
        setData();
    }, [scdata]);
    getData(scdata);
    const [unitlist, setUnitList] = useState([]);
    const scheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setSCVal({
                                DPAN460: DateTime.utc().toJSDate(),
                                DPA0130: null,
                                DPA0131: null,
                                DPA0132: null,
                                DPA0133: "",
                                DPA0134: "",
                                DPA0336: "",
                                attachment: null,
                            });
                            setSubmitted(false);

                            setScDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setScFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const saveStationary = () => {
        setSubmitted(true);
        console.log(
            scVal,
            scVal.DPA0131 !== null ||
            (scVal.DPA0130.name === "Solid Fuels" ||
                scVal.DPA0130.name === "Biomass"
                ? parseInt(scVal.DPA0133) > 0 && parseInt(scVal.DPA0134) > 0
                : false)
        );
        if (
            scVal.DPA0130 !== null && scVal.DPAN460 !== null &&
            (scVal.DPA0131 !== null ||
                (scVal.DPA0130.name === "Solid Fuels" ||
                    scVal.DPA0130.name === "Biomass"
                    ? parseInt(scVal.DPA0133) > 0 && parseInt(scVal.DPA0134) > 0
                    : false)) &&
            (scVal.DPA0132 !== null ||
                (scVal.DPA0130.name === "Solid Fuels" ||
                    scVal.DPA0130.name === "Biomass"
                    ? parseInt(scVal.DPA0133) > 0 && parseInt(scVal.DPA0134) > 0
                    : false)) &&

            parseInt(scVal.DPA0336) > 0 &&
            (scVal.DPA0130.name === "Solid Fuels" || scVal.DPA0130.name === "Biomass"
                ? parseInt(scVal.DPA0133) >= 0 && parseInt(scVal.DPA0134) >= 0
                : true)
        ) {
            if (scVal.id === undefined) {
                scVal["id"] = getID();
                setSCData((prev) => [scVal, ...prev]);
            } else {
                let loc = scdata;
                let index = loc.findIndex((k) => {
                    return k.id === scVal.id;
                });
                loc[index] = scVal;
                setSCData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setScDialog(false);
        }

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Stationary Combustion Emissions") {
                    let local = {};
                    let local2 = scdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_SC") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items['Fuel Category'] !== undefined &&
                                    items['Fuel Category'].trim().length !== 0 &&
                                    items['Fuel Type'] !== undefined &&
                                    items['Fuel Type'].trim().length !== 0 &&
                                    items.Unit !== undefined &&
                                    items.Unit.trim().length !== 0 &&
                                    (items['Fuel Category'] !== undefined &&
                                        (items['Fuel Category'].trim() === "Solid Fuels" ||
                                            items['Fuel Category'].trim() === "Biomass")
                                        ? items['Avg Net Calorific'] !== undefined &&
                                        typeof items['Avg Net Calorific'] === "number" &&
                                        items['Avg Net Calorific'] > 0 &&
                                        items['Avg carbon Content'] !== undefined &&
                                        typeof items['Avg carbon Content'] === "number" &&
                                        items['Avg carbon Content'] > 0
                                        : true) &&
                                    items['Quantity Used'] !== undefined &&
                                    typeof items['Quantity Used'] === "number" &&
                                    items['Quantity Used'] > 0
                                ) {
                                    let fuel_cat_ind = fuelcategory.findIndex((i) => {
                                        return (
                                            i.fuel_type.toLowerCase().trim() ===
                                            items['Fuel Category'].toLowerCase().trim()
                                        );
                                    });
                                    let fuel_ind = fuelcategory.findIndex((i) => {
                                        return (
                                            i.fuel.toLowerCase().trim() ===
                                            items['Fuel Type'].toLowerCase().trim()
                                        );
                                    });
                                    let unit_ind = fuelcategory.findIndex((i) => {
                                        return (
                                            i.unit.toLowerCase().trim() ===
                                            items.Unit.toLowerCase().trim()
                                        );
                                    });
                                    if (
                                        fuel_cat_ind !== -1 &&
                                        fuel_ind !== -1 &&
                                        unit_ind !== -1
                                    ) {
                                        local["DPA0130"] = {
                                            name: fuelcategory[fuel_cat_ind].fuel_type,
                                        };
                                        local["DPA0131"] = {
                                            name: fuelcategory[fuel_ind].fuel,
                                        };
                                        local["DPA0132"] = {
                                            name: fuelcategory[unit_ind].unit,
                                        };
                                        local['DPAN460'] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPA0336"] = items['Quantity Used'].toString();
                                        console.log(items['Fuel Category'].trim() === "Solid Fuels");
                                        if (
                                            items['Fuel Category'].trim() === "Solid Fuels" ||
                                            items['Fuel Category'].trim() === "Biomass"
                                        ) {
                                            local["DPA0133"] = items['Avg Net Calorific'].toString();
                                            local["DPA0134"] = items['Avg carbon Content'].toString();
                                        } else {
                                            local["DPA0133"] = "";
                                            local["DPA0134"] = "";
                                        }

                                        local2.push(local);
                                    }
                                }
                            });
                        }
                    });

                    setSCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const updateScVal = (obj, val) => {
        let local = scVal;

        local[obj] = val;
        if (obj === "DPA0130") {
            local["DPA0132"] = null;
            local["DPA0131"] = null;
            let fuel_type = [];
            fuelcategory.forEach((i) => {
                if (i.fuel_type === val.name) {
                    if (
                        fuel_type.findIndex((k) => {
                            return k.name === i.fuel;
                        }) === -1
                    ) {
                        fuel_type.push({ name: i.fuel });
                    }
                }
            });
            setFuelList(fuel_type);
        } else if (obj === "DPA0131") {
            local["DPA0132"] = null;

            let units = [];
            fuelcategory.forEach((i) => {
                if (i.fuel === val.name && i.fuel_type === local["DPA0130"].name) {
                    if (
                        units.findIndex((k) => {
                            return k.name === i.unit;
                        }) === -1
                    ) {
                        units.push({ name: i.unit });
                    }
                }
            });
            setUnitList(units);
        }
        if (obj === "DPA0132") {
            local.DPA0133 = "";
            local.DPA0134 = "";
            local.DPA0336 = "";
        }
        setSCVal(local);

        forceUpdate();
    };
    const editScVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPAN460 = DateTime.fromISO(items.DPAN460, { zone: 'utc' }).toJSDate()
        let fuel_type = [],
            units = [];
        if (item.DPA0131 !== null) {
            fuelcategory.forEach((i) => {
                if (i.fuel_type === item.DPA0130.name) {
                    if (
                        fuel_type.findIndex((k) => {
                            return k.name === i.fuel;
                        }) === -1
                    ) {
                        fuel_type.push({ name: i.fuel });
                    }
                }
            });
            setFuelList(fuel_type);
        }
        if (item.DPA0132 !== null) {
            fuelcategory.forEach((i) => {
                if (
                    i.fuel === item.DPA0131.name &&
                    i.fuel_type === item["DPA0130"].name
                ) {
                    if (
                        units.findIndex((k) => {
                            return k.name === i.unit;
                        }) === -1
                    ) {
                        units.push({ name: i.unit });
                    }
                }
            });
            setUnitList(units);
        }

        setSCVal(items);
        console.log(items);
        setScDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = scdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setSCData(loc);
        forceUpdate();
    };
    const scDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveStationary();
                }}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplateSC = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editScVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 11 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                "date": "",
                                                "Fuel Category": "",
                                                "Fuel Type": "",
                                                "Unit": "",
                                                "Quantity Used": "",
                                                "Avg Net Calorific": "",
                                                "Avg carbon Content": "",
                                            },
                                        ],
                                        "S1_SC",
                                        "Stationary Combustion Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Stationary Combustion Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={scdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0130.name", "DPA0131.name", "DPA0132.name"]}
                globalFilter={scfilter}
                emptyMessage="No data entered yet"
                header={edit === 1 && scheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPAN460"
                    header="Date"
                    body={dateFormatting_sc}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0130.name"
                    header="Fuel Category"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0131.name"
                    header="Fuel Type"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0132.name"
                    header="Unit"
                    sortable
                    headerStyle={{
                        width: "12%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0336"
                    header="Quantity Used"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0133"
                    header="Avg Net Calorific"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0134"
                    header="Avg Carbon Content"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateSC}> </Column>}
            </DataTable>
            <Dialog
                visible={scdialog}
                style={{
                    width: "450px",
                }}
                header="Add New"
                modal
                className="p-fluid"
                footer={scDialogFooter}
                onHide={() => {
                    setScDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate">Date </label>
                    <Calendar
                        showIcon dateFormat={'dd/mm/yy'}
                        value={scVal.DPAN460}
                        onChange={(e) => updateScVal("DPAN460", e.value)}
                    ></Calendar>
                    {submitted && scVal.DPAN460 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Date required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="DPA0130">Select Fuel Category</label>
                    <Dropdown
                        value={scVal.DPA0130}
                        onChange={(e) => {
                            updateScVal("DPA0130", e.value);
                        }}
                        options={fuelcatlist}
                        optionLabel="name"
                        placeholder="Select"
                    />
                    {submitted && scVal.DPA0130 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Fuel Category is required
                        </small>
                    )}
                </div>
                {scVal.DPA0130 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0131">Select Fuel Type</label>
                        <Dropdown
                            value={scVal.DPA0131}
                            onChange={(e) => {
                                updateScVal("DPA0131", e.value);
                            }}
                            options={fuellist}
                            optionLabel="name"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0131 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Fuel type is required
                            </small>
                        )}
                    </div>
                )}
                {scVal.DPA0131 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0132">Select Unit</label>
                        <Dropdown
                            value={scVal.DPA0132}
                            onChange={(e) => {
                                updateScVal("DPA0132", e.value);
                            }}
                            options={unitlist}
                            optionLabel="name"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0132 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Unit
                            </small>
                        )}
                    </div>
                )}
                {((scVal.DPA0130 !== null &&
                    scVal.DPA0131 !== null &&
                    scVal.DPA0132 !== null) ||
                    (scVal.DPA0130 !== null &&
                        (scVal.DPA0130.name === "Solid Fuels" ||
                            scVal.DPA0130.name === "Biomass"))) && (
                        <>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="gasrefill"> Quantity of fuel used </label>
                                    <div id="gasrefill" className="p-inputgroup">
                                        <InputText
                                            keyfilter={"pnum"}
                                            value={scVal.DPA0336}
                                            onChange={(e) => {
                                                updateScVal("DPA0336", e.target.value);
                                            }}
                                            placeholder="Quantity"
                                        />
                                        {scVal.DPA0132 !== null && (
                                            <span className="p-inputgroup-addon">
                                                {scVal.DPA0132.name}
                                            </span>
                                        )}
                                    </div>
                                    {submitted &&
                                        (scVal.DPA0336.trim() === "" ||
                                            parseInt(scVal.DPA0336) <= 0) && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Quantity is required
                                            </small>
                                        )}
                                </div>
                            </div>
                            {(scVal.DPA0130.name === "Solid Fuels" ||
                                scVal.DPA0130.name === "Biomass") && (
                                    <div>
                                        <div className="formgrid grid">
                                            <div className="field col">
                                                <label htmlFor="DPA0133">

                                                    Average Net Calorific value of the fuel used
                                                </label>
                                                <div id="DPA0133" className="p-inputgroup">
                                                    <InputText
                                                        keyfilter={"pnum"}
                                                        value={scVal.DPA0133}
                                                        onChange={(e) => {
                                                            updateScVal("DPA0133", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {submitted &&
                                                    (scVal.DPA0133.trim() === "" ||
                                                        parseInt(scVal.DPA0133) < 0) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Calorific Value required
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="formgrid grid">
                                            <div className="field col">
                                                <label htmlFor="gasrefill">
                                                    Average Carbon Content of the fuel used
                                                </label>
                                                <div id="gasrefill" className="p-inputgroup">
                                                    <InputText
                                                        keyfilter={"pnum"}
                                                        value={scVal.DPA0134}
                                                        onChange={(e) => {
                                                            updateScVal("DPA0134", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {submitted &&
                                                    (scVal.DPA0134.trim() === "" ||
                                                        parseInt(scVal.DPA0134) < 0) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Carbon Content is required
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}

                <>
                    <FileUpload
                        mode="basic"
                        customUpload
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(scVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], scVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = scVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setSCVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(scVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {scVal.attachment !== null && (
                    <>
                        {scVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    scVal.attachment.splice(index, 1);
                                                    if (scVal.attachment.length === 0) {
                                                        scVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Stationary_Combustion_ = ({ extractedData = () => { }, data, getData, setData, allowedCategories = [], edit = 0, standard, dates = { minDate: null, maxDate: null } }) => {
    const [scdata, setSCData] = useState(data);
    const forceUpdate = useForceUpdate();
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [scfilter, setScFilter] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [fuelcatlist, setFuelCatList] = useState([]);
    const [fuellist, setFuelList] = useState([]);
    const [category, setCategory] = useState([]);
    const [load, setLoad] = useState(true)
    console.log(dates)
    const [scVal, setSCVal] = useState({
        DPAN460: null,
        DPA0130: null,
        DPA0131: null,
        DPA0132: null,
        DPA0133: 0,
        DPA0134: 0,
        DPA0336: 0,
        DPAN1000: 0,
        attachment: null,
    });
    const [scdialog, setScDialog] = useState(false);
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            setLoad(false)
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );

                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 1);
                        setCategory(
                            JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories[
                                cat_index
                            ].newEfSubcategory1s
                        );
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setFuelCatList(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(scdata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [scdata, category]);
    getData(scdata);

    function extractData(data) {
        let mappedData = category
            .flatMap(
                (i) =>
                    i.newEfSubcategory2s?.length &&
                    i.newEfSubcategory2s.flatMap(
                        (x) =>
                            x.newEfSubcategory3s?.length &&
                            x.newEfSubcategory3s.map((y) => ({
                                key: i.id + "-" + x.id + "-" + y.id,
                                parent0id: i.id,
                                currentId: y.id,
                                parentId: x.id,
                                title1: i.title,
                                title2: x.title,
                                title3: y.title,
                            }))
                    )
            )
            .filter((i) => i);
        let result = [];

        for (const item of data) {
            let index = mappedData.find(
                (i) =>
                    i.key ===
                    item["DPA0130"] + "-" + item["DPA0131"] + "-" + item["DPA0132"]
            );
            console.log(index, mappedData);
            if (index) {

                result.push({
                    title: index.title1 + ">" + index.title2 + ">" + index.title3,
                    label: index.title2,
                    value: item.DPA0336,
                    subCategory1: index.parent0id,
                    subCategory2: index.parentId,
                    subCategory3: index.currentId,
                    currentId: index.currentId,
                    parentId: index.parentId,
                    formType: 2,
                    dataType: 1,
                    attachment: item.attachment,
                    uom: index.title3,
                    uniqueId: index.key,
                    maskId: item.id,
                    date: item.DPAN460,
                    additionalValue1: item.DPA0133,
                    additionalValue2: item.DPA0134
                });

            }
        }
        console.log(result, "RESULT");
        return result;
    }
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    const [unitlist, setUnitList] = useState([]);
    const scheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setSCVal({
                                DPAN460: null,
                                DPA0130: null,
                                DPA0131: null,
                                DPA0132: null,
                                DPA0133: 0,
                                DPA0134: 0,
                                DPA0336: 0,
                                DPAN1000: 0,
                                attachment: null,
                            });
                            setSubmitted(false);

                            setScDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setScFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const saveStationary = () => {
        setSubmitted(true);

        if (
            scVal.DPA0130 !== null && scVal.DPAN460 !== null &&
            (scVal.DPA0131 !== null ||
                ((scVal.DPA0130 === 5 || scVal.DPA0130 === 7 || scVal.DPA0130 === 4)
                    ? parseFloat(scVal.DPA0133) >= 0 && parseFloat(scVal.DPA0134) >= 0
                    : false)) &&
            (scVal.DPA0132 !== null ||
                ((scVal.DPA0130 === 5 || scVal.DPA0130 === 7 || scVal.DPA0130 === 4)
                    ? parseFloat(scVal.DPA0133) >= 0 && parseFloat(scVal.DPA0134) >= 0
                    : false)) &&
            parseFloat(scVal.DPA0336) >= 0 &&
            ((scVal.DPA0130 === 5 || scVal.DPA0130 === 7 || scVal.DPA0130 === 4)
                ? parseFloat(scVal.DPA0133) >= 0 && parseFloat(scVal.DPA0134) >= 0
                : true)
        ) {
            if (scVal.id === undefined) {
                scVal["id"] = getID();
                setSCData((prev) => [scVal, ...prev]);
            } else {
                let loc = [...scdata];

                let index = loc.findIndex((k) => {
                    return k.id === scVal.id;
                });
                loc[index] = scVal;
                setSCData(loc);
            }
            console.log(scVal);
            forceUpdate();
            setSubmitted(false);
            setScDialog(false);
        }

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Stationary Combustion Emissions") {
                    let local = {};
                    let local2 = [...scdata];

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "Data Entry") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                let index = -1;
                                if (items.fuel_category !== undefined) {
                                    index = category.findIndex(
                                        (i) =>
                                            i.title.trim().toLowerCase() ===
                                            items.fuel_category.toString().trim().toLowerCase()
                                    );
                                }
                                console.log(items.date, typeof items.date === "number")
                                if (
                                    items.date && typeof items.date === "number" &&
                                    items.fuel_category !== undefined &&
                                    items.fuel_category.trim().length !== 0 &&
                                    items.fuel_type !== undefined &&
                                    items.fuel_type.trim().length !== 0 &&
                                    items.unit !== undefined &&
                                    items.unit.trim().length !== 0 &&
                                    (index !== -1 &&
                                        (category[index].id === 5 || category[index].id === 7)
                                        ? items.calorific !== undefined &&
                                        parseFloat(items.calorific.toString()) > 0 &&
                                        items.carboncontent !== undefined &&
                                        parseFloat(items.carboncontent.toString()) > 0
                                        : true) &&
                                    items.quantity !== undefined &&
                                    parseFloat(items.quantity.toString()) > 0
                                ) {
                                    const { result, id } = findMatchingIds(
                                        category,
                                        items.fuel_category.toString(),
                                        items.fuel_type.toString(),
                                        items.unit.toString()
                                    );
                                    console.log(result, id, items);
                                    if (result) {
                                        local["DPA0130"] = id[0];
                                        local["DPA0131"] = id[1];
                                        local["DPA0132"] = id[2];
                                        local['DPAN460'] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPA0336"] = parseFloat(items.quantity.toString());
                                        console.log(items.fuel_category.trim() === "Solid Fuels");
                                        if (category[index].id === 5 || category[index].id === 7) {
                                            local["DPA0133"] = parseFloat(items.calorific.toString());
                                            local["DPA0134"] = parseFloat(
                                                items.carboncontent.toString()
                                            );
                                        } else {
                                            local["DPA0133"] = "";
                                            local["DPA0134"] = "";
                                        }
                                        local2.push(local);
                                    }
                                }
                            });
                        }
                    });

                    setSCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const updateScVal = (obj, val) => {
        let local = scVal;

        local[obj] = val;
        if (obj === "DPA0130") {
            local["DPA0132"] = null;
            local["DPA0131"] = null;
            let fuel_type = [];
            fuelcatlist.forEach((i) => {
                console.log(i, val);
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === val) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        } else if (obj === "DPA0131") {
            local["DPA0132"] = null;

            let units = [];
            let index = fuelcatlist.findIndex((i) => {
                return i.id === local["DPA0130"];
            });
            fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                console.log(i, val);
                if (i.newEfSubcategory3s !== undefined) {
                    if (i.id === val) {
                        units = i.newEfSubcategory3s;
                    }
                }
            });
            setUnitList(units);
            // fuelcategory.forEach((i) => {
            //     if (i.fuel === val.name && i.fuel_type === local['DPA0130'].name) {
            //         if (units.findIndex((k) => { return k.name === i.unit }) === -1) {
            //             units.push({ name: i.unit })
            //         }
            //     }

            // })
        }
        if (obj === "DPA0132") {
            local.DPA0133 = 0;
            local.DPA0134 = 0;
            local.DPA0336 = 0;
            local.DPAN1000 = 0;
        }
        setSCVal(local);

        forceUpdate();
    };
    const editScVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPAN460 = DateTime.fromISO(items.DPAN460, { zone: 'utc' }).toJSDate()
        let fuel_type = [],
            units = [];
        if (item.DPA0131 !== null) {
            fuelcatlist.forEach((i) => {
                console.log(i, item.DPA0130);
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === item.DPA0130) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        }
        if (item.DPA0132 !== null) {
            let index = fuelcatlist.findIndex((i) => {
                return i.id === item.DPA0130;
            });
            if (index !== -1 && fuelcatlist[index].newEfSubcategory2s !== undefined) {
                fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                    console.log(i, item.DPA0131, item.DPA0132);
                    if (i.newEfSubcategory3s !== undefined) {
                        if (i.id === item.DPA0131) {
                            units = i.newEfSubcategory3s;
                        }
                    }
                });
            }

            setUnitList(units);
        }
        items.DPA0133 = parseFloat(items.DPA0133);
        items.DPA0134 = parseFloat(items.DPA0134);
        items.DPA0336 = parseFloat(items.DPA0336);
        items.DPAN1000 = parseFloat(items.DPAN1000);
        setSCVal(items);
        console.log(items);
        setScDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...scdata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setSCData(loc);
        forceUpdate();
    };
    const scDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveStationary();
                }}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const subcategory1template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1) {
            text = category[index].title;
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory2template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPA0131;
            });
            if (index2 !== -1) {
                text = category[index].newEfSubcategory2s[index2].title;
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory3template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPA0131;
            });
            if (
                index2 !== -1 &&
                category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                undefined
            ) {
                let index3 = category[index].newEfSubcategory2s[
                    index2
                ].newEfSubcategory3s.findIndex((i) => {
                    return i.id === rowData.DPA0132;
                });
                if (index3 !== -1) {
                    text =
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                            index3
                        ].title;
                }
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const actionBodyTemplateSC = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editScVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        window.open('https://api.eisqr.com/docs/1721638529608Bulk%20Upload_Stationary%20Combustion%20Emissions_Template.xlsx')
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Stationary Combustion Emissions");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={() => {
                                setSCVal({
                                    DPAN460: null,
                                    DPA0130: null,
                                    DPA0131: null,
                                    DPA0132: null,
                                    DPA0133: 0,
                                    DPA0134: 0,
                                    DPA0336: 0,
                                    DPAN1000: 0,
                                    attachment: null,
                                });
                                setSubmitted(false);

                                setScDialog(true);
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable loading={load}
                value={scdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0130.name", "DPA0131.name", "DPA0132.name"]}
                globalFilter={scfilter}
                emptyMessage="No data entered yet"

                responsiveLayout="scroll"
            >
                <Column
                    body={subcategory1template}
                    header="Fuel Category"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory2template}
                    header="Fuel Type"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory3template}
                    header="Unit"
                    sortable
                    headerStyle={{
                        width: "12%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0336"
                    header="Quantity Used"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateSC}> </Column>}
            </DataTable>
            <Dialog
                visible={scdialog}
                style={{
                    width: "450px",
                }}
                header="Add New"
                modal
                className="p-fluid"
                footer={scDialogFooter}
                onHide={() => {
                    setScDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate">Date </label>
                    <Calendar
                        showIcon minDate={dates.minDate} maxDate={dates.maxDate} dateFormat={'dd/mm/yy'}
                        value={scVal.DPAN460}

                        showMinMaxRange={true}
                        showOtherMonths={false}
                        viewDate={dates.minDate}
                        onChange={(e) => updateScVal("DPAN460", e.value)}
                    ></Calendar>
                    {submitted && scVal.DPAN460 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Date required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="DPA0130">Select Fuel Category</label>
                    <Dropdown
                        value={scVal.DPA0130}
                        onChange={(e) => {
                            updateScVal("DPA0130", e.value);
                        }}
                        optionValue="id"
                        options={fuelcatlist.filter(i => { return allowedCategories.includes('1-' + i.id) || allowedCategories.length === 0 })}
                        optionLabel="title"
                        placeholder="Select"
                    />
                    {submitted && scVal.DPA0130 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Fuel Category is required
                        </small>
                    )}
                </div>
                {scVal.DPA0130 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0131">Select Fuel Type</label>
                        <Dropdown
                            value={scVal.DPA0131}
                            onChange={(e) => {
                                updateScVal("DPA0131", e.value);
                            }}
                            options={fuellist.filter(i => { return allowedCategories.includes('2-' + i.id) || allowedCategories.length === 0 })}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0131 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Fuel type is required
                            </small>
                        )}
                    </div>
                )}
                {scVal.DPA0131 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0132">Select Unit</label>
                        <Dropdown
                            value={scVal.DPA0132}
                            onChange={(e) => {
                                updateScVal("DPA0132", e.value);
                            }}
                            options={unitlist.filter(i => { return allowedCategories.includes('3-' + i.id) || allowedCategories.length === 0 })}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0132 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Unit
                            </small>
                        )}
                    </div>
                )}
                {((scVal.DPA0130 !== null &&
                    scVal.DPA0131 !== null &&
                    scVal.DPA0132 !== null) ||
                    (scVal.DPA0130 !== null &&
                        (scVal.DPA0130 === 5 || scVal.DPA0130 === 7 || scVal.DPA0130 === 4))) && (
                        <>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="gasrefill"> Quantity of fuel used </label>
                                    <div id="gasrefill" className="p-inputgroup">
                                        <InputNumber
                                            maxFractionDigits={4}
                                            useGrouping={false}
                                            value={scVal.DPA0336}
                                            onChange={(e) => {
                                                updateScVal("DPA0336", e.value);
                                            }}
                                            placeholder="Quantity"
                                        />
                                        {/* {scVal.DPA0132 !== null && <span className="p-inputgroup-addon">{scVal.DPA0132.name} </span>} */}
                                    </div>
                                    {submitted && parseFloat(scVal.DPA0336) <= 0 && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Quantity is required
                                        </small>
                                    )}
                                </div>
                            </div>
                            {(scVal.DPA0130 === 5 || scVal.DPA0130 === 7 || scVal.DPA0130 === 4) && (
                                <div>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="DPA0133">

                                                Average Net Calorific value of the fuel used
                                            </label>
                                            <div id="DPA0133" className="p-inputgroup">
                                                <InputNumber
                                                    maxFractionDigits={4}
                                                    useGrouping={false}
                                                    value={scVal.DPA0133}
                                                    onChange={(e) => {
                                                        updateScVal("DPA0133", e.value);
                                                    }}
                                                />
                                            </div>
                                            {submitted && parseFloat(scVal.DPA0133) < 0 && (
                                                <small
                                                    className="p-invalid"
                                                    style={{
                                                        color: "red",
                                                    }}
                                                >
                                                    Calorific Value required
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="gasrefill">
                                                Density of Fuel
                                            </label>
                                            <div id="gasrefill" className="p-inputgroup">
                                                <InputNumber
                                                    maxFractionDigits={4}
                                                    useGrouping={false}
                                                    value={scVal.DPA0134}
                                                    onChange={(e) => {
                                                        updateScVal("DPA0134", e.value);
                                                    }}
                                                />
                                            </div>
                                            {submitted && parseFloat(scVal.DPA0134) < 0 && (
                                                <small
                                                    className="p-invalid"
                                                    style={{
                                                        color: "red",
                                                    }}
                                                >
                                                    Density of fuel is required
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                <>
                    <FileUpload
                        mode="basic"
                        customUpload
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(scVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], scVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = scVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setSCVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(scVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {scVal.attachment !== null && (
                    <>
                        {scVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    scVal.attachment.splice(index, 1);
                                                    if (scVal.attachment.length === 0) {
                                                        scVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
// Fugitive

const Fugitive = ({ data, getData, setData, edit, dates }) => {
    const [fujdata, setFujData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [fujdata]);
    getData(fujdata);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [selectedRef, setSelectedRef] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [refVal, setRefVal] = useState({
        DPA0137: moment(moment.utc()).toDate(),
        DPA0135: "",
        DPA0136: null,
        DPA0138: "",
        attachment: null,
    });

    const updateRefVal = (obj, val) => {
        let local = refVal;
        local[obj] = val;
        console.log(val);
        setRefVal(local);

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Fugitive Emissions") {
                    let local = {};
                    let local2 = fujdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_R") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items['Date of refilled'] !== undefined &&
                                    typeof items['Date of refilled'] === "number" &&
                                    items['AC Type'] !== undefined &&
                                    items['AC Type'].trim().length !== 0 &&
                                    items['Gas Type'] !== undefined &&
                                    items['Gas Type'].trim().length !== 0 &&
                                    items['Gas Refilled'] !== undefined &&
                                    typeof items['Gas Refilled'] === "number" &&
                                    items['Gas Refilled'] > 0
                                ) {
                                    local["DPA0135"] = items['AC Type'];
                                    local["DPA0136"] = {
                                        name: items['Gas Type'],
                                    };

                                    local["DPA0137"] = moment(excelDateToJSDate(items['Date of refilled']))._d;
                                    local["id"] = getID();
                                    local["attachment"] = null;
                                    local["DPA0138"] = items['Gas Refilled'].toString();

                                    local2.push(local);
                                }
                            });
                        }
                    });

                    setFujData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const refheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setRefVal({
                                DPA0137: moment(moment.utc()).toDate(),
                                DPA0135: "",
                                DPA0136: null,
                                DPA0138: "",
                                attachment: null,
                            });

                            setSubmitted(false);
                            setRefDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setRefFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const editRefVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPA0137 = moment.utc(item.DPA0137).toDate();
        console.log(typeof items.DPA0137, items);
        setRefVal(items);
        console.log(items);
        setRefDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = fujdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setFujData(loc);
        forceUpdate();
    };

    const saveRefrigerent = () => {
        setSubmitted(true);
        console.log(refVal);
        if (
            refVal.DPA0135.trim().length !== 0 &&
            refVal.DPA0137 !== null &&
            parseInt(refVal.DPA0138) > 0 &&
            refVal.DPA0136 !== null

        ) {
            if (refVal.id === undefined) {
                refVal["id"] = getID();
                setFujData((prev) => [refVal, ...prev]);
            } else {
                let loc = fujdata;
                let index = loc.findIndex((k) => {
                    return k.id === refVal.id;
                });
                loc[index] = refVal;
                setFujData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setRefDialog(false);
        }

        forceUpdate();
    };
    const refDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveRefrigerent}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplateRF = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editRefVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 11 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                "Date of refilled": "",
                                                "AC Type": "",
                                                "Gas Type": "",
                                                "Gas Refilled": "",
                                            },
                                        ],
                                        "S1_R",
                                        "Fugitive Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Fugitive Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={fujdata}
                scrollable
                onSelectionChange={(e) => setSelectedRef(e.value)}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={refFilter}
                emptyMessage="No data entered yet"
                header={edit === 1 && refheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0137"
                    header="Date of refilled"
                    body={dateFormatting}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0135"
                    header="AC Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0136.name"
                    header="Gas Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0138"
                    header="Gas Refilled"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateRF}> </Column>}
            </DataTable>
            <Dialog
                visible={refDialog}
                style={{
                    width: "450px",
                }}
                header="Add Refrigerent"
                modal
                className="p-fluid"
                footer={refDialogFooter}
                onHide={() => {
                    setRefDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate"> Date of Refill </label>
                    <Calendar
                        minDate={dates.minDate} maxDate={dates.maxDate}
                        dateFormat={'dd/mm/yy'}
                        showIcon
                        value={refVal.DPA0137}
                        showMinMaxRange={true}
                        showOtherMonths={false}
                        viewDate={dates.minDate}
                        onChange={(e) => updateRefVal("DPA0137", e.value)}
                    ></Calendar>
                </div>
                <div className="field">
                    <label htmlFor="DPA0135">Type of AC/ Chiller / freezer used </label>
                    <InputText
                        value={refVal.DPA0135}
                        onChange={(e) => {
                            updateRefVal("DPA0135", e.target.value);
                        }}
                    />
                    {submitted && refVal.DPA0135.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            AC Type is required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="DPA0136">Type of gas refilled</label>
                    <Dropdown
                        value={refVal.DPA0136}
                        onChange={(e) => {
                            updateRefVal("DPA0136", e.value);
                        }}
                        options={gastype}
                        optionLabel="name"
                        placeholder="Select"
                    />
                    {submitted && refVal.DPA0136 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Gas Type is required
                        </small>
                    )}
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="gasrefill"> Gas Refilled </label>
                        <div id="gasrefill" className="p-inputgroup">
                            <InputText
                                keyfilter={"pnum"}
                                value={refVal.DPA0138}
                                onChange={(e) => {
                                    updateRefVal("DPA0138", e.target.value);
                                }}
                                placeholder="Gas Refilled"
                            />
                            <span className="p-inputgroup-addon">KG </span>
                        </div>
                        {submitted &&
                            (refVal.DPA0138.trim() === "" ||
                                parseInt(refVal.DPA0138) <= 0) && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Gas Refilled is required
                                </small>
                            )}
                    </div>
                </div>
                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(refVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], refVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = refVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setRefVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(refVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {refVal.attachment !== null && (
                    <>
                        {refVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    refVal.attachment.splice(index, 1);
                                                    if (refVal.attachment.length === 0) {
                                                        refVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Fugitive_ = ({ data, extractedData = () => { }, getData, setData, allowedCategories = [], edit = 0, standard, dates = { minDate: null, maxDate: null } }) => {
    const [fujdata, setFujData] = useState([...data]);
    const [load, setLoad] = useState(true)
    const [category, setCategory] = useState([]);
    const forceUpdate = useForceUpdate();

    useState(() => {
        setData();
    }, [fujdata]);
    getData(fujdata);
    useEffect(() => {
        if (typeof extractedData === 'function') {

            extractedData(extractData(fujdata));

        } else {
            console.error('extractedData is not a function');
        }


    }, [fujdata, category]);
    function extractData(data) {


        let mappedData = category.flatMap(i => i.newEfSubcategory2s?.length && i.newEfSubcategory2s.map(x => ({ key: i.id + '-' + x.id, currentId: x.id, parentId: i.id, title1: i.title, title2: x.title }))).filter(i => i)

        console.log(mappedData)
        let result = []

        for (const item of data) {
            let index = mappedData.find(i => i.key.split('-')[0] === (item['DPA0136'].toString()))
            console.log(index, mappedData)
            if (index) {

                result.push({
                    title: index.title1 + '>' + index.title2, label: index.title1, value: item.DPA0138, subCategory1: item.DPA0136, subCategory2: item.DPA0136 === 346 ? null : index.currentId, currentId: index.currentId, parentId: item.DPA0136, formType: 2, dataType: 1, isNull: false, attachment: item.attachment, uom: index.title2, uniqueId: index.key, maskId: item.id, reportedDate: item.DPA0137
                })


            }
        }
        console.log(result)
        return result
    }


    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [selectedRef, setSelectedRef] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [refVal, setRefVal] = useState({
        DPA0137: null,
        DPA0135: "",
        DPA0136: null,
        DPA0138: 0,
        attachment: null,
    });

    const updateRefVal = (obj, val) => {
        let local = refVal;
        local[obj] = val;
        console.log(val);
        setRefVal(local);

        forceUpdate();
    };
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            setLoad(false)

            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 5);

                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Fugitive Emissions") {
                    let local = {};
                    let local2 = [...fujdata];

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_R") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.actype !== undefined &&
                                    items.actype.trim().length !== 0 &&
                                    items.gastype !== undefined &&
                                    items.gastype.trim().length !== 0 &&
                                    items.gasrefilled !== undefined &&
                                    typeof items.gasrefilled === "number" &&
                                    items.gasrefilled > 0
                                ) {
                                    let index = category.findIndex(
                                        (i) =>
                                            i.title.trim().toLowerCase() ===
                                            items.gastype.toString().toLowerCase()
                                    );
                                    if (index !== -1) {
                                        local["DPA0135"] = items.actype;
                                        local["DPA0136"] = category[index].id;
                                        local["DPA0137"] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPA0138"] = items.gasrefilled;

                                        local2.push(local);
                                    }
                                }
                            });
                        }
                    });

                    setFujData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const refheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setRefVal({
                                DPA0137: null,
                                DPA0135: "",
                                DPA0136: null,
                                DPA0138: 0,
                                attachment: null,
                            });

                            setSubmitted(false);
                            setRefDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setRefFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const editRefVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        if (items.DPA0137) {
            items.DPA0137 = moment.utc(item.DPA0137).toDate();
        }

        items.DPA0138 = parseFloat(items.DPA0138);
        console.log(typeof items.DPA0137, items);
        setRefVal(items);
        console.log(items);
        setRefDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...fujdata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setFujData(loc);
        forceUpdate();
    };

    const saveRefrigerent = () => {
        setSubmitted(true);
        console.log(refVal);
        if (
            refVal.DPA0135.trim().length !== 0 &&
            refVal.DPA0137 !== null &&
            parseFloat(refVal.DPA0138) >= 0 &&
            refVal.DPA0136 !== null

        ) {
            if (refVal.id === undefined) {
                refVal["id"] = getID();
                setFujData((prev) => [refVal, ...prev]);
            } else {
                let loc = [...fujdata];

                let index = loc.findIndex((k) => {
                    return k.id === refVal.id;
                });
                loc[index] = refVal;
                setFujData(loc);
            }
            console.log(refVal);
            forceUpdate();
            setSubmitted(false);
            setRefDialog(false);
        }

        forceUpdate();
    };
    const refDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveRefrigerent}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const subcategoryTemplate = (rowData) => {
        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0136;
        });
        let text = "NA";
        if (index !== -1) {
            text = category[index].title;
        }
        return <span>{text} </span>;
    };
    const actionBodyTemplateRF = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editRefVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        exportToCSV(
                                            [
                                                {
                                                    sno: null,
                                                    date: "",
                                                    actype: "",
                                                    gastype: "",
                                                    gasrefilled: "",
                                                },
                                            ],
                                            "S1_R",
                                            "Fugitive Emissions"
                                        );
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Fugitive Emissions");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={() => {
                                setRefVal({
                                    DPA0137: null,
                                    DPA0135: "",
                                    DPA0136: null,
                                    DPA0138: 0,
                                    attachment: null,
                                });

                                setSubmitted(false);
                                setRefDialog(true);
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                loading={load}
                value={fujdata}
                scrollable
                onSelectionChange={(e) => setSelectedRef(e.value)}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={refFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && refheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0137"
                    header="Date of refilled"
                    body={dateFormatting}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0135"
                    header="AC Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0136.name"
                    header="Gas Type"
                    body={subcategoryTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0138"
                    header="Gas Refilled"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateRF}> </Column>}
            </DataTable>
            <Dialog
                visible={refDialog}
                style={{
                    width: "450px",
                }}
                header="Add Refrigerent"
                modal
                className="p-fluid"
                footer={refDialogFooter}
                onHide={() => {
                    setRefDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate"> Date of Refill </label>
                    <Calendar
                        minDate={dates.minDate} maxDate={dates.maxDate}
                        dateFormat={'dd/mm/yy'}
                        showIcon
                        showMinMaxRange={true}
                        showOtherMonths={false}
                        viewDate={dates.minDate}
                        value={refVal.DPA0137}
                        onChange={(e) => updateRefVal("DPA0137", e.value)}
                    ></Calendar>
                </div>
                <div className="field">
                    <label htmlFor="DPA0135">Type of AC/ Chiller / freezer used </label>
                    <InputText
                        value={refVal.DPA0135}
                        onChange={(e) => {
                            updateRefVal("DPA0135", e.target.value);
                        }}
                    />
                    {submitted && refVal.DPA0135.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            AC Type is required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="DPA0136">Type of gas refilled</label>
                    <Dropdown
                        value={refVal.DPA0136}
                        onChange={(e) => {
                            updateRefVal("DPA0136", e.value);
                        }}
                        options={category.filter(i => { return allowedCategories.includes('1-' + i.id) || allowedCategories.length === 0 })}
                        optionLabel="title"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && refVal.DPA0136 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Gas Type is required
                        </small>
                    )}
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="gasrefill"> Gas Refilled </label>
                        <div id="gasrefill" className="p-inputgroup">
                            <InputNumber
                                maxFractionDigits={4}
                                useGrouping={false}
                                value={refVal.DPA0138}
                                onChange={(e) => {
                                    updateRefVal("DPA0138", e.value);
                                }}
                                placeholder="Gas Refilled"
                            />
                            <span className="p-inputgroup-addon">KG </span>
                        </div>
                        {submitted && parseInt(refVal.DPA0138) <= 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Gas Refilled is required
                            </small>
                        )}
                    </div>
                </div>
                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(refVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], refVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = refVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setRefVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(refVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {refVal.attachment !== null && (
                    <>
                        {refVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    refVal.attachment.splice(index, 1);
                                                    if (refVal.attachment.length === 0) {
                                                        refVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
// Business Travel
const Business_Travel = ({ data, getData, setData, edit, dates }) => {
    const [btdata, setBTData] = useState(data);
    const [btDialog, setBTDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [submitted, setSubmitted] = useState(false);
    const [btFilter, setBTFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [legData, setLegData] = useState({});
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [addLegDialog, setAddLegDialog] = useState(false);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(IATACodes);

    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        let listed_f = [
            { name: "Diesel" },
            { name: "Petrol" },
            { name: "Hybrid" },
            { name: "CNG" },
            { name: "LPG" },
            { name: "Plug-in Hybrid Electric Vehicle" },
            { name: "Battery Electric Vehicle" },
        ];
        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }
                let local2 = btdata;
                if (topic === "BT_Air") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Air") {
                            sname[Object.keys(sname)[0]].forEach((items, legind) => {
                                local = {};
                                let id = getID();
                                console.log(items);
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.from !== undefined &&
                                    items.from.trim().Length !== 0 &&
                                    IATACodes.findIndex((i) => {
                                        return (
                                            i.label.split("/")[2].trim().toLowerCase() ===
                                            items.from.trim().toLowerCase()
                                        );
                                    }) !== -1 &&
                                    items.to !== undefined &&
                                    items.to.trim().Length !== 0 &&
                                    IATACodes.findIndex((i) => {
                                        return (
                                            i.label.split("/")[2].trim().toLowerCase() ===
                                            items.to.trim().toLowerCase()
                                        );
                                    }) !== -1 &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.no_of_passenger.toString().trim()) >= 1 &&
                                    items.class !== undefined &&
                                    items.class.toString().trim().length !== 0 &&
                                    (items.class.toString().trim().toLowerCase() === "premium" ||
                                        items.class.toString().trim().toLowerCase() === "economy" ||
                                        items.class.toString().trim().toLowerCase() === "first")
                                ) {
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Air" };
                                    local["id"] = id;
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from.trim().toLowerCase();
                                    local["DPA0294"] = items.to.trim().toLowerCase();
                                    local["DPA0296"] = { name: items.class };
                                    local["attachment"] = null;
                                    local2.push(local);
                                    const encodedParams = new URLSearchParams();
                                    encodedParams.append(
                                        "iata_airport_from",
                                        items.from.toString().trim()
                                    );
                                    encodedParams.append(
                                        "iata_airport_to",
                                        items.to.toString().trim()
                                    );
                                    encodedParams.append(
                                        "number_of_passengers",
                                        parseInt(items.no_of_passenger)
                                    );
                                    encodedParams.append(
                                        "flight_class",
                                        items.class.toString().trim()
                                    );
                                    encodedParams.append("round_trip", "N");
                                    encodedParams.append("add_rf", "N");
                                    encodedParams.append("include_wtt", "N");
                                    const options = {
                                        method: "POST",
                                        url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                                        headers: {
                                            "content-type": "application/x-www-form-urlencoded",
                                            "X-RapidAPI-Key":
                                                "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                                            "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                                        },
                                        data: encodedParams,
                                    };
                                    Axios.request(options)
                                        .then((res) => {
                                            if (res.status === 200) {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });

                                                if (index !== -1) {
                                                    local2[index]["DP_co2e_mt"] = res.data.data.co2e_mt;
                                                }

                                                setBTData(local2);

                                                forceUpdate();
                                            }
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                            let index = local2.findIndex((i) => {
                                                return i.id === id;
                                            });

                                            if (index !== -1) {
                                                local2.splice(index, 1);
                                                setBTData(local2);
                                                forceUpdate();
                                            }
                                        });
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Rail") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Rail") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.passenger.toString().trim()) >= 1 &&
                                    items.from !== undefined &&
                                    items.from.toString().trim().length !== 0 &&
                                    items.to !== undefined &&
                                    items.to.toString().trim().length !== 0
                                ) {
                                    let from = items.from;
                                    let to = items.to;
                                    let id = getID();
                                    console.log(from, to);
                                    var directionService = new google.maps.DirectionsService();
                                    local["id"] = id;
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Rail" };
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from;
                                    local["DPA0294"] = items.to;
                                    local["attachment"] = null;
                                    local2.push(local);

                                    setBTData(local2);
                                    var request = {
                                        provideRouteAlternatives: true,
                                        waypoints: [],
                                        origin: from,
                                        destination: to,
                                        travelMode: google.maps.TravelMode.TRANSIT,
                                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                                    };

                                    directionService
                                        .route(request, (result, status) => {
                                            if (status === google.maps.DirectionsStatus.OK) {
                                                let distance =
                                                    result.routes[0].legs[0].distance.text.toString();
                                                let km =
                                                    1.60934 * parseFloat(distance.replace("mi", ""));

                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2[index]["DP_KM"] = km;

                                                    setBTData(local2);
                                                }

                                                forceUpdate();
                                            } else {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2.splice(index, 1);
                                                    setBTData(local2);
                                                    forceUpdate();
                                                }
                                            }
                                        })
                                        .catch((a) => console.log(a, "err"));
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Road") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Road") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};

                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.from !== undefined &&
                                    items.from.trim().Length !== 0 &&
                                    items.to !== undefined &&
                                    items.to.trim().Length !== 0 &&
                                    items.from !== undefined &&
                                    items.from.trim().Length !== 0 &&
                                    items.vehicle_type !== undefined &&
                                    items.vehicle_type.toString().trim().length !== 0 &&
                                    (items.vehicle_type.includes("Car") ||
                                        items.vehicle_type === "Motorbike" ||
                                        items.vehicle_type === "Taxis" ||
                                        items.vehicle_type === "Bus") &&
                                    (items.vehicle_type !== undefined &&
                                        items.vehicle_type.toString().trim().length !== 0 &&
                                        items.vehicle_type.includes("Car")
                                        ? items.vehicle_capacity !== undefined &&
                                        items.vehicle_capacity.toString().trim().length !== 0 &&
                                        bt_road_category.findIndex((i) => {
                                            return (
                                                i.subtype.trim().toLowerCase() ===
                                                items.vehicle_capacity.trim().toLowerCase()
                                            );
                                        }) !== -1 &&
                                        items.fuel_used !== undefined &&
                                        items.fuel_used.toString().trim().length !== 0 &&
                                        listed_f.findIndex((k) => {
                                            return (
                                                k.name.trim().toLowerCase() ===
                                                items.fuel_used.trim().toLowerCase()
                                            );
                                        }) !== -1
                                        : true) &&
                                    (items.vehicle_type !== undefined &&
                                        items.vehicle_type.toString().trim().length !== 0 &&
                                        items.vehicle_type === "Motorbike"
                                        ? items.vehicle_capacity !== undefined &&
                                        items.vehicle_capacity.toString().trim().length !== 0 &&
                                        bt_road_category.findIndex((i) => {
                                            return (
                                                i.subtype.trim().toLowerCase() ===
                                                items.vehicle_capacity.trim().toLowerCase()
                                            );
                                        }) !== -1
                                        : true) &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.no_of_passenger.toString().trim()) >= 1
                                ) {
                                    let from = items.from;
                                    let to = items.to;
                                    let btr = {};
                                    let id = getID();
                                    console.log(from, to);
                                    var directionService = new google.maps.DirectionsService();
                                    local["id"] = id;
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Road" };
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from;
                                    local["DPA0294"] = items.to;
                                    local["attachment"] = null;
                                    local["DPA0295"] = { name: items.vehicle_type };
                                    if (items.vehicle_type.includes("Car")) {
                                        local["DPA0337"] = { name: items.vehicle_capacity };
                                        local["DPA0338"] = { name: items.fuel_used };
                                    } else if (items.vehicle_type === "Motorbike") {
                                        local["DPA0337"] = items.vehicle_capacity;
                                        local["DPA0338"] = null;
                                    } else {
                                        local["DPA0337"] = null;
                                        local["DPA0338"] = null;
                                    }
                                    local2.push(local);
                                    var request = {
                                        provideRouteAlternatives: true,
                                        waypoints: [],
                                        origin: from,
                                        destination: to,
                                        travelMode: google.maps.TravelMode.DRIVING,
                                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                                    };

                                    directionService
                                        .route(request, (result, status) => {
                                            if (status === google.maps.DirectionsStatus.OK) {
                                                let distance =
                                                    result.routes[0].legs[0].distance.text.toString();
                                                let km =
                                                    1.60934 * parseFloat(distance.replace("mi", ""));

                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                console.log(index, local2);
                                                if (index !== -1) {
                                                    local2[index]["DP_KM"] = km;
                                                    setBTData(local2);

                                                    forceUpdate();
                                                }
                                            } else {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2.splice(index, 1);
                                                    setBTData(local2);
                                                    forceUpdate();
                                                }
                                            }
                                        })
                                        .catch((a) => {
                                            if (a.code === "NOT_FOUND") {
                                            }
                                        });
                                }
                            });
                        }
                    });
                }
                setBTData(local2);
                console.log(local2);
                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.value = null;
    };
    console.log(btdata, "testing");
    const [btVal, setBtVal] = useState({
        DPA0290: moment(moment.utc()).toDate(),
        legs: [],
        DPA0291: { name: "Air" },
        DPA0295: null,
        DPA0337: null,
        DPA0338: null,
        DPA0293: "",
        DPA0294: "",
        DPA0292: 1,
        attachment: null,
        DPA0296: null,
    });
    const [selectedAutoValue, setSelectedAutoValue] = useState({
        DPA0293: "",
        DPA0294: "",
    });
    useState(() => {
        setData();
    }, [btdata]);
    getData(btdata);
    useEffect(() => {
        let vehcat = [];
        bt_road_category.forEach((item) => {
            if (
                vehcat.findIndex((i) => {
                    return i.name === item.vehicle_type;
                }) === -1
            ) {
                vehcat.push({ name: item.vehicle_type });
            }
        });
        setVehCatList(vehcat);
    }, []);
    console.log(btdata);
    const deleteRow = (item) => {
        let loc = btdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBTData(loc);
        forceUpdate();
    };
    const bt_export = [
        {
            label: "Road",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                            vehicle_type: null,
                            vehicle_capacity: null,
                            fuel_used: null,
                        },
                    ],
                    "S3_BT_Road",
                    "BT_Road"
                );
            },
        },
        {
            label: "Rail",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                        },
                    ],
                    "S3_BT_Rail",
                    "BT_Rail"
                );
            },
        },
        {
            label: "Air",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                            class: null,
                        },
                    ],
                    "S3_BT_Air",
                    "BT_Air"
                );
            },
        },
    ];
    const bt_import = [
        {
            label: "Road",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    console.log(e.target.files[0]);
                    importFromExcel(e.target, "BT_Road");
                };
            },
        },
        {
            label: "Rail",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Rail");
                };
            },
        },
        {
            label: "Air",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Air");
                };
            },
        },
    ];
    const export2Excel = () => {
        let iata_code = [];
        IATACodes.forEach((item) => {
            iata_code.push({
                Code: item.label.split("/")[2],
                Country: item.label.split("/")[1],
                Airport: item.label.split("/")[0],
            });
        });

        const ws = XLSX.utils.json_to_sheet(iata_code);
        let sheet_name = "IATA Airline Code";
        const wb = {
            Sheets: { [sheet_name]: ws },
            SheetNames: [sheet_name],
        };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, sheet_name + ".xlsx");
    };
    const btheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setBtVal({
                                DPA0290: moment(moment.utc()).toDate(),
                                legs: [],
                                DPA0291: { name: "Air" },
                                DPA0295: null,
                                DPA0337: null,
                                DPA0338: null,
                                DPA0293: "",
                                DPA0294: "",
                                DPA0292: 1,
                                attachment: null,
                                DPA0296: null,
                            });
                            setSubmitted(false);
                            setBTDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBTFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const updateBtVal = (obj, val) => {
        let local = btVal;
        local[obj] = val;
        console.log(obj, val);
        if (
            obj === "DPA0295" &&
            (val.name === "Cars (by market segment)" ||
                val.name === "Cars (by size)" ||
                val.name === "Motorbike")
        ) {
            let sub = [];
            bt_road_category.forEach((i) => {
                if (i.vehicle_type === val.name) {
                    if (
                        sub.findIndex((j) => {
                            return j.name === i.subtype;
                        }) === -1
                    ) {
                        sub.push({ name: i.subtype });
                    }
                }
            });
            local.DPA0337 = null;
            setVehFuelList([]);
            setVehTypeList(sub);
        }
        if (obj === "DPA0337") {
            local.DPA0338 = null;

            let sub = [];
            bt_road_category.forEach((item) => {
                if (
                    item.subtype === val.name &&
                    item.vehicle_type === local.DPA0295.name
                ) {
                    sub = item.fuelcat;
                }
            });
            console.log(sub);
            setVehFuelList(sub);
        }
        if (obj === "mode") {
            setBtVal({
                DPA0290: moment(moment.utc()).toDate(),
                legs: [],
                DPA0291: { name: "Air" },
                DPA0295: null,
                DPA0337: null,
                DPA0338: null,
                DPA0293: "",
                DPA0294: "",
                DPA0292: 1,
                attachment: null,
                DPA0296: null,
            });
        } else {
            setBtVal(local);
        }

        forceUpdate();
    };
    const saveBusinessTravel = () => {
        setSubmitted(true);
        let local = btVal;
        let local2 = btdata;

        if (btVal.DPA0291 !== null) {
            if (
                btVal.DPA0291.name === "Road" &&
                parseInt(btVal.DPA0292) > 0 &&
                btVal.DPA0295 !== null &&

                btVal.DPA0293.trim().length !== 0 &&
                btVal.DPA0294.trim().length !== 0
            ) {
                if (
                    btVal.DPA0295.name === "Bus" ||
                    btVal.DPA0295.name === "Taxis" ||
                    (btVal.DPA0295.name === "Motorbike" &&
                        btVal.DPA0337 !== null &&
                        btVal.DPA0337.name !== undefined) ||
                    (btVal.DPA0295.name.includes("Cars") &&
                        btVal.DPA0337 !== null &&
                        btVal.DPA0338 !== null &&
                        btVal.DPA0337.name !== undefined &&
                        btVal.DPA0338.name !== undefined)
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }

                    setBtVal({
                        DPA0290: moment(moment.utc()).toDate(),
                        legs: [],
                        DPA0291: { name: "Air" },
                        DPA0295: null,
                        DPA0337: null,
                        DPA0338: null,
                        DPA0293: "",
                        DPA0294: "",
                        DPA0292: 1,
                        attachment: null,
                        DPA0296: null,
                    });
                    setBTData(local2);
                    setBTDialog(false);
                    forceUpdate();
                }
            } else if (
                btVal.DPA0291.name === "Air" &&
                parseInt(btVal.DPA0292) > 0 &&

                btVal.legs.length !== 0
            ) {
                let local2 = btdata;

                if (
                    btVal.legs.findIndex((i) => {
                        return i.DP_co2e_mt === undefined;
                    }) === -1
                ) {
                    btVal.legs.forEach((item) => {
                        local2.push({
                            id: getID(),
                            DPA0290: item.DPA0290,
                            legs: [item],
                            DPA0291: btVal.DPA0291,
                            DP_co2e_mt: item.DP_co2e_mt,
                            DPA0293: item.DPA0293,
                            DPA0294: item.DPA0294,
                            DPA0292: btVal.DPA0292,
                            attachment: btVal.attachment,
                        });
                    });

                    setBtVal({
                        DPA0290: moment(moment.utc()).toDate(),
                        legs: [],
                        DPA0291: { name: "Air" },
                        DPA0295: null,
                        DPA0337: null,
                        DPA0338: null,
                        DPA0293: "",
                        DPA0294: "",
                        DPA0292: 1,
                        attachment: null,
                        DPA0296: null,
                    });
                    setBTData(local2);
                    setBTDialog(false);
                    forceUpdate();
                } else {
                    alert("calculate emission factor before saving");
                }
            } else if (
                btVal.DPA0291.name === "Rail" &&

                btVal.DPA0293.trim().length !== 0 &&
                btVal.DPA0294.trim().length !== 0
            ) {
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }

                setBtVal({
                    DPA0290: moment(moment.utc()).toDate(),
                    legs: [],
                    DPA0291: { name: "Air" },
                    DPA0295: null,
                    DPA0337: null,
                    DPA0338: null,
                    DPA0293: "",
                    DPA0294: "",
                    DPA0292: 1,
                    attachment: null,
                    DPA0296: null,
                });
                setBTData(local2);
                setBTDialog(false);
                forceUpdate();
            }
        }
    };
    const btDialogFooter = (
        <>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setBTDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveBusinessTravel();
                }}
            />
        </>
    );
    const btAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplateBt = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const addLegDialogFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setAddLegDialog(false);
                    setLegData({});
                    setSelectedAutoValue({
                        DPA0293: "",
                        DPA0294: "",
                    });
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    setSubmitted(true);
                    if (
                        legData.DPA0290 !== undefined &&
                        legData.DPA0296 !== undefined &&
                        legData.DPA0293 !== undefined &&
                        legData.DPA0294 !== undefined
                    ) {
                        let local = btVal;
                        local.legs.push(legData);
                        setBtVal(local);
                        setLegData([]);
                        setSelectedAutoValue({
                            DPA0293: "",
                            DPA0294: "",
                        });
                        setSubmitted(false);
                        setAddLegDialog(false);
                    }
                }}
            />
        </>
    );
    const updateACValue = (obj, val) => {
        if (obj !== "DPA0290") {
            let value = selectedAutoValue;
            value[obj] = val;
            setSelectedAutoValue(value);
        }

        let leg = legData;
        if (obj === "DPA0290") {
            leg[obj] = val;
        } else if (obj === "DPA0296") {
            leg[obj] = val;
        } else {
            if (typeof val === "object" && val.value.includes("/")) {
                leg[obj] = val.value.split("/")[2];
            }
        }

        setLegData(leg);

        forceUpdate();
    };
    function roundValues(num) {
        var m = Number((Math.abs(num) * 100).toPrecision(6));
        return (Math.round(m) / 100) * Math.sign(num);
    }
    // RenderMap

    const renderMapRoute = () => {
        let check = 0;

        if (btVal.DPA0291.name === "Road") {
            if (btVal.DPA0295.name !== "Car" && btVal.DPA0295.name !== "Bike") {
                check = 1;
            } else if (btVal.DPA0295.name === "Car") {
                if (btVal.DPA0337.name !== "") {
                    if (btVal.DPA0338.name !== "") {
                        check = 1;
                    } else {
                        alert("Select Fuel Type");
                    }
                } else {
                    check = 0;
                    alert("Select Car Capacity");
                }
            } else if (btVal.DPA0295.name === "Bike") {
                if (btVal.DPA0337.name !== "") {
                    check = 1;
                } else {
                    check = 0;
                    alert("Select Bike Capacity");
                }
            }
        } else {
            check = 1;
        }

        if (check === 1) {
            //   if (document.getElementById('resultTabel') !== null) { document.getElementById('resultTabel').innerHTML = "" }
            let from = document.getElementById("fromplace").value.trim();
            let to = document.getElementById("toplace").value.trim();

            var directionService = new google.maps.DirectionsService();
            var directionDisplay = new google.maps.DirectionsRenderer({
                draggable: true,
            });

            let btr = btVal;
            var request = {
                provideRouteAlternatives: true,
                waypoints: [],
                origin: from,
                destination: to,
                travelMode:
                    btVal.DPA0291.name === "Road"
                        ? google.maps.TravelMode.DRIVING
                        : google.maps.TravelMode.TRANSIT,
                unitSystem: google.maps.UnitSystem.IMPERIAL,
            };

            if (from.split(",").length !== 1 && to.split(",").length !== 1) {
                directionService
                    .route(request, (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            let distance = result.routes[0].legs[0].distance.text.toString();
                            let km = 1.60934 * parseFloat(distance.replace("mi", ""));
                            btr["DP_KM"] = roundValues(km);
                            if (btVal.DPA0291.name === "Rail") {
                                btr.DPA0293 = document
                                    .getElementById("fromplace")
                                    .value.trim()
                                    .split(",")[0];
                                btr.DPA0294 = document
                                    .getElementById("toplace")
                                    .value.trim()
                                    .split(",")[0];
                            } else {
                                btr.DPA0293 = document
                                    .getElementById("fromplace")
                                    .value.trim()
                                    .split(",")[0];
                                btr.DPA0294 = document
                                    .getElementById("toplace")
                                    .value.trim()
                                    .split(",")[0];
                            }
                            setBtVal(btr);
                        } else {
                            btr.tco2e = 0;
                            btr.DPA0293 = "";
                            btr.DPA0294 = "";
                            btr["direction"] = "";
                            btr.km = 0;
                            alert("Route not found between" + from + " & " + to);
                        }
                    })
                    .catch((a) => console.log(a, "err"));
            }
        }
    };
    //   Map Dialog
    const onLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds({
            lat: 13.017092278167553,
            lng: 80.1987183651303,
        });
        map.fitBounds(bounds);
    };
    const calculateEmission = () => {
        let apiLegData = [];
        btVal.legs.forEach((item, i) => {
            console.log(item);
            apiLegData.push({
                iata_airport_from: item.DPA0293,
                iata_airport_to: item.DPA0294,
                number_of_passengers: parseInt(btVal.DPA0292),
                flight_class: item.DPA0296.name.toLowerCase(),
                round_trip: "N",
                add_rf: "N",
                include_wtt: "N",
            });
        });
        apiLegData.forEach((legs, legind) => {
            const encodedParams = new URLSearchParams();
            encodedParams.append("iata_airport_from", legs.iata_airport_from);
            encodedParams.append("iata_airport_to", legs.iata_airport_to);
            encodedParams.append("number_of_passengers", legs.number_of_passengers);
            encodedParams.append("flight_class", legs.flight_class);
            encodedParams.append("round_trip", legs.round_trip);
            encodedParams.append("add_rf", legs.add_rf);
            encodedParams.append("include_wtt", legs.include_wtt);

            const options = {
                method: "POST",
                url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    "X-RapidAPI-Key":
                        "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                    "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                },
                data: encodedParams,
            };
            // 'fd38be8d58msh9125eea580172f9p1dd1cbjsn67325a6ce234'
            Axios.request(options).then((res) => {
                if (res.status === 200) {
                    let local = btVal;
                    let total = 0;
                    local.legs[legind]["DP_co2e_mt"] = res.data.data.co2e_mt;

                    setBtVal(local);
                    forceUpdate();
                }
            });
        });

        forceUpdate();
    };
    const onUnmount = (map) => {
        console.log("unmount");
    };
    // Autocomplete Airport Code
    const searchAirCode = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...autoValue]);
            } else {
                setAutoFilteredValue(
                    autoValue.filter((country) => {
                        return country.value
                            .toLowerCase()
                            .includes(event.query.toLowerCase());
                    })
                );
            }
        }, 250);
    };
    return (
        <div className="col-12 lg:col-12">

            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        export2Excel();
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Stationary Combustion Emissions");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={() => {
                                setBtVal({
                                    DPA0290: moment(moment.utc()).toDate(),
                                    legs: [],
                                    DPA0291: { name: "Air" },
                                    DPA0295: null,
                                    DPA0337: null,
                                    DPA0338: null,
                                    DPA0293: "",
                                    DPA0294: "",
                                    DPA0292: 1,
                                    attachment: null,
                                    DPA0296: null,
                                });
                                setSubmitted(false);
                                setBTDialog(true);
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                scrollable
                value={btdata}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehtype.name", "vehno"]}
                globalFilter={btFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && btheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0290"
                    header="Date"
                    body={dateFormatting_bt}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0291.name"
                    header="Travel Mode"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0293"
                    header="From"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0294"
                    header="To"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0292"
                    header="Passenger"
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>

                <Column
                    field="attachment"
                    header="Attachment"
                    body={btAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateBt}> </Column>}
            </DataTable>
            <Dialog
                visible={btDialog}
                style={{
                    width: "50%",
                }}
                header="Add Business Travel"
                modal
                className="p-fluid"
                footer={btDialogFooter}
                onHide={() => {
                    setBTDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ectype"> Mode </label>
                    <Dropdown
                        value={btVal.DPA0291}
                        onChange={(e) => {
                            updateBtVal("DPA0291", e.value);
                        }}
                        options={[{ name: "Air" }, { name: "Rail" }, { name: "Road" }]}
                        optionLabel="name"
                        placeholder="Select"
                    />
                    {submitted && btVal.DPA0291 === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select Mode
                        </small>
                    )}
                </div>
                {(btVal.DPA0291.name === "Rail" || btVal.DPA0291.name === "Road") && (
                    <div className="field">
                        <label htmlFor="ecdate"> Date </label>
                        <Calendar
                            minDate={dates.minDate} maxDate={dates.maxDate}
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            showMinMaxRange={true}
                            showOtherMonths={false}
                            viewDate={dates.minDate}
                            value={btVal.DPA0290}
                            onChange={(e) => updateBtVal("DPA0290", e.value)}
                        ></Calendar>
                    </div>
                )}
                <div className="field">
                    <label htmlFor="ecpsngr"> Passenger Travelled </label>
                    <InputText
                        min={1}
                        type="number"
                        value={btVal.DPA0292}
                        onChange={(e) => {
                            updateBtVal("DPA0292", e.target.value);
                        }}
                        placeholder="Passenger Travelled"
                    />
                    {submitted &&
                        (parseInt(btVal.DPA0292) <= 0 || btVal.DPA0292 === "") && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Passenger is required.
                            </small>
                        )}
                </div>
                {btVal.DPA0291.name === "Road" && (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr"> Vehicle Type </label>
                            <Dropdown
                                value={btVal.DPA0295}
                                onChange={(e) => {
                                    updateBtVal("DPA0295", e.value);
                                }}
                                options={vehcatlist}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted && btVal.DPA0295 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Type
                                </small>
                            )}
                        </div>
                        {btVal.DPA0295 !== null &&
                            (btVal.DPA0295.name === "Cars (by market segment)" ||
                                btVal.DPA0295.name === "Cars (by size)" ||
                                btVal.DPA0295.name === "Motorbike") && (
                                <>
                                    <div className="field">
                                        <label htmlFor="ecpsngr"> Seating Capacity </label>
                                        <Dropdown
                                            value={btVal.DPA0337}
                                            onChange={(e) => {
                                                updateBtVal("DPA0337", e.value);
                                            }}
                                            options={vehtypelist}
                                            optionLabel="name"
                                            placeholder="Select"
                                        />
                                        {submitted && btVal.DPA0337 === null && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Select Capacity
                                            </small>
                                        )}
                                    </div>
                                    {btVal.DPA0337 !== null &&
                                        btVal.DPA0337.name !== null &&
                                        vehfuellist.length !== 0 && (
                                            <div className="field">
                                                <label htmlFor="ecpsngr"> Fuel </label>
                                                <Dropdown
                                                    value={btVal.DPA0338}
                                                    onChange={(e) => {
                                                        updateBtVal("DPA0338", e.value);
                                                    }}
                                                    options={vehfuellist}
                                                    optionLabel="name"
                                                    placeholder="Select"
                                                />
                                                {submitted && btVal.DPA0338 === null && (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Select Fuel Category
                                                    </small>
                                                )}
                                            </div>
                                        )}
                                </>
                            )}
                    </>
                )}
                {(btVal.DPA0291.name === "Rail" || btVal.DPA0291.name === "Road") && (
                    <>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="eckmdriven"> From </label>
                                <div id="eckmdriven" className="p-inputgroup">
                                    <Autocomplete
                                        className="autocomplete_width"
                                        onLoad={(place) => console.log("")}
                                        onPlaceChanged={() => {
                                            renderMapRoute("DPA0293");
                                        }}
                                    >
                                        <input
                                            id="fromplace"
                                            type="text"
                                            placeholder="From"
                                            style={{
                                                boxSizing: `border-box`,
                                                border: `1px solid transparent`,
                                                width: `100%`,
                                                height: `35px`,
                                                padding: `0 12px`,
                                                borderRadius: `3px`,
                                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                fontSize: `14px`,
                                                outline: `none`,
                                                textOverflow: `ellipses`,
                                            }}
                                        ></input>
                                    </Autocomplete>
                                </div>
                            </div>
                            <div className="field col">
                                <label htmlFor="ovGHG"> To </label>
                                <div id="ovGHG" className="p-inputgroup">
                                    <Autocomplete
                                        className="autocomplete_width"
                                        onLoad={(place) => console.log("")}
                                        onPlaceChanged={() => {
                                            renderMapRoute("DPA0294");
                                        }}
                                    >
                                        <input
                                            id="toplace"
                                            type="text"
                                            placeholder="To"
                                            style={{
                                                boxSizing: `border-box`,
                                                border: `1px solid transparent`,
                                                width: `100%`,
                                                height: `35px`,
                                                padding: `0 12px`,
                                                borderRadius: `3px`,
                                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                fontSize: `14px`,
                                                outline: `none`,
                                                textOverflow: `ellipses`,
                                            }}
                                        ></input>
                                    </Autocomplete>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {btVal.DPA0291.name === "Air" && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                style={{
                                    color: "green",
                                    textDecoration: "underline",
                                    marginBottom: 20,
                                }}
                                onClick={() => {
                                    setAddLegDialog(true);
                                }}
                            >
                                add Leg
                            </span>
                        </div>
                        {btVal.legs.length !== 0 && (
                            <div
                                style={{
                                    marginBottom: 20,
                                    overflow: "scroll",
                                    maxHeight: 250,
                                }}
                            >
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        width: "100%",
                                        background: "lightgrey",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            width: "40%",
                                        }}
                                    >
                                        Date
                                    </span>
                                    <span
                                        style={{
                                            width: "20%",
                                        }}
                                    >
                                        From
                                    </span>
                                    <span
                                        style={{
                                            width: "20%",
                                        }}
                                    >
                                        To
                                    </span>
                                </div>
                                {btVal.legs.map((i) => {
                                    return (
                                        <div
                                            style={{
                                                flexDirection: "row",
                                                display: "flex",
                                                width: "100%",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: "40%",
                                                }}
                                            >
                                                {dateFormatting_bt(i.DPA0290)}
                                            </span>
                                            <span
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                {i.DPA0293}
                                            </span>
                                            <span
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                {i.DPA0294}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {submitted && btVal.legs.length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Travel Legs required
                            </small>
                        )}
                        {btVal.legs.length !== 0 && (
                            <div>
                                <Button
                                    style={{ marginBottom: 10 }}
                                    label="Calculate Emission"
                                    className="p-button-text"
                                    onClick={(e) => {
                                        calculateEmission();
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(btVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], btVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = btVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setBtVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(btVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {btVal.attachment !== null && (
                    <>
                        {btVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    btVal.attachment.splice(index, 1);
                                                    if (btVal.attachment.length === 0) {
                                                        btVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>

            <Dialog
                visible={addLegDialog}
                style={{
                    width: "40%",
                }}
                header="Add Leg"
                modal
                footer={addLegDialogFooter}
                onHide={() => {
                    setAddLegDialog(false);
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="field col-6">
                            <label htmlFor="ecdate"> Travel Date </label>
                            <Calendar
                                minDate={dates.minDate} maxDate={dates.maxDate}
                                dateFormat={'dd/mm/yy'}
                                showIcon
                                style={{
                                    width: "100%",
                                }}
                                showMinMaxRange={true}
                                showOtherMonths={false}
                                viewDate={dates.minDate}
                                value={legData.DPA0290}
                                onChange={(e) => updateACValue("DPA0290", e.value)}
                            ></Calendar>
                            {submitted &&
                                (legData.DPA0290 === "" || legData.DPA0290 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Date
                                    </small>
                                )}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="ectype"> Class </label>
                            <Dropdown
                                style={{
                                    width: "100%",
                                }}
                                value={legData.DPA0296}
                                onChange={(e) => {
                                    updateACValue("DPA0296", e.value);
                                }}
                                options={[
                                    { name: "Economy" },
                                    { name: "Premium" },
                                    { name: "First" },
                                ]}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted &&
                                (legData.DPA0296 === "" || legData.DPA0296 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Class
                                    </small>
                                )}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="field col-6">
                            <label htmlFor="aufrom"> From </label>
                            <AC
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search"
                                id="fromau"
                                dropdown
                                completeMethod={searchAirCode}
                                suggestions={autoFilteredValue}
                                onChange={(e) => updateACValue("DPA0293", e.value)}
                                value={selectedAutoValue.DPA0293}
                                field="value"
                            />
                            {submitted &&
                                (legData.DPA0293 === "" || legData.DPA0293 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select From
                                    </small>
                                )}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="auto"> To </label>
                            <AC
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search"
                                id="toau"
                                dropdown
                                completeMethod={searchAirCode}
                                suggestions={autoFilteredValue}
                                onChange={(e) => updateACValue("DPA0294", e.value)}
                                value={selectedAutoValue.DPA0294}
                                field="value"
                            />
                            {submitted &&
                                (legData.DPA0294 === "" || legData.DPA0294 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select To
                                    </small>
                                )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Business_Travel_ = ({ data, getData, setData, edit, standard, dates = { minDate: null, maxDate: null } }) => {
    const [btdata, setBTData] = useState(data);
    const [btDialog, setBTDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [submitted, setSubmitted] = useState(false);
    const [btFilter, setBTFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [unitlist, setUnitList] = useState([]);
    const [legData, setLegData] = useState({});
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [category, setCategory] = useState([]);
    const [unit_, setUnitList_] = useState([]);
    const [addLegDialog, setAddLegDialog] = useState(false);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(IATACodes);

    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        let listed_f = [
            { name: "Diesel" },
            { name: "Petrol" },
            { name: "Hybrid" },
            { name: "CNG" },
            { name: "LPG" },
            { name: "Plug-in Hybrid Electric Vehicle" },
            { name: "Battery Electric Vehicle" },
        ];
        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }
                let local2 = btdata;
                if (topic === "BT_Air") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Air") {
                            sname[Object.keys(sname)[0]].forEach((items, legind) => {
                                local = {};
                                let id = getID();

                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.from !== undefined &&
                                    items.from.trim().length !== 0 &&
                                    IATACodes.findIndex((i) => {
                                        return (
                                            i.label.split("/")[2].trim().toLowerCase() ===
                                            items.from.trim().toLowerCase()
                                        );
                                    }) !== -1 &&
                                    items.to !== undefined &&
                                    items.to.trim().length !== 0 &&
                                    IATACodes.findIndex((i) => {
                                        return (
                                            i.label.split("/")[2].trim().toLowerCase() ===
                                            items.to.trim().toLowerCase()
                                        );
                                    }) !== -1 &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.no_of_passenger.toString().trim()) >= 1 &&
                                    items.class !== undefined &&
                                    items.class.toString().trim().length !== 0 &&
                                    (items.class.toString().trim().toLowerCase() === "premium" ||
                                        items.class.toString().trim().toLowerCase() === "economy" ||
                                        items.class.toString().trim().toLowerCase() === "first" ||
                                        items.class.toString().trim().toLowerCase() === "business")
                                ) {
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Air" };
                                    local["id"] = id;
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from.trim().toLowerCase();
                                    local["DPA0294"] = items.to.trim().toLowerCase();
                                    local["DPA0296"] = { name: items.class };
                                    local["attachment"] = null;
                                    local2.push(local);
                                    const encodedParams = new URLSearchParams();
                                    encodedParams.append(
                                        "iata_airport_from",
                                        items.from.toString().trim()
                                    );
                                    encodedParams.append(
                                        "iata_airport_to",
                                        items.to.toString().trim()
                                    );
                                    encodedParams.append(
                                        "number_of_passengers",
                                        parseInt(items.no_of_passenger)
                                    );
                                    encodedParams.append(
                                        "flight_class",
                                        items.class.toString().trim()
                                    );
                                    encodedParams.append("round_trip", "N");
                                    encodedParams.append("add_rf", "N");
                                    encodedParams.append("include_wtt", "N");
                                    const options = {
                                        method: "POST",
                                        url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                                        headers: {
                                            "content-type": "application/x-www-form-urlencoded",
                                            "X-RapidAPI-Key":
                                                "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                                            "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                                        },
                                        data: encodedParams,
                                    };
                                    Axios.request(options)
                                        .then((res) => {
                                            if (res.status === 200) {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });

                                                if (index !== -1) {
                                                    local2[index]["DP_co2e_mt"] = res.data.data.co2e_mt;
                                                }

                                                setBTData(local2);

                                                forceUpdate();
                                            }
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                            let index = local2.findIndex((i) => {
                                                return i.id === id;
                                            });

                                            if (index !== -1) {
                                                local2.splice(index, 1);
                                                setBTData(local2);
                                                forceUpdate();
                                            }
                                        });
                                } else {
                                    console.log(items)
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Rail") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Rail") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.passenger.toString().trim()) >= 1 &&
                                    items.from !== undefined &&
                                    items.from.toString().trim().length !== 0 &&
                                    items.to !== undefined &&
                                    items.to.toString().trim().length !== 0
                                ) {
                                    let from = items.from;
                                    let to = items.to;
                                    let id = getID();
                                    console.log(from, to);
                                    var directionService = new google.maps.DirectionsService();
                                    local["id"] = id;
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Rail" };
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from;
                                    local["DPA0294"] = items.to;
                                    local["attachment"] = null;
                                    local2.push(local);

                                    setBTData(local2);
                                    var request = {
                                        provideRouteAlternatives: true,
                                        waypoints: [],
                                        origin: from,
                                        destination: to,
                                        travelMode: google.maps.TravelMode.TRANSIT,
                                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                                    };

                                    directionService
                                        .route(request, (result, status) => {
                                            if (status === google.maps.DirectionsStatus.OK) {
                                                let distance =
                                                    result.routes[0].legs[0].distance.text.toString();
                                                let km =
                                                    1.60934 * parseFloat(distance.replace("mi", ""));

                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2[index]["DP_KM"] = km;

                                                    setBTData(local2);
                                                }

                                                forceUpdate();
                                            } else {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2.splice(index, 1);
                                                    setBTData(local2);
                                                    forceUpdate();
                                                }
                                            }
                                        })
                                        .catch((a) => console.log(a, "err"));
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Road") {
                    let local = {};
                    let unit_list = JSON.parse(JSON.stringify(unit_));

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Road") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};

                                if (
                                    items.date !== null &&
                                    typeof items.date === "number" &&
                                    items.from !== null &&
                                    items.from.trim().length !== 0 &&
                                    items.to !== null &&
                                    items.to.trim().length !== 0 &&
                                    items.vehicle_type !== null &&
                                    items.vehicle_capacity !== null &&
                                    items.fuel_used !== null &&
                                    items.unit !== null &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseFloat(items.no_of_passenger.toString().trim()) >= 1
                                ) {
                                    const { result, id_ } = findMatchingIds(
                                        category,
                                        items.vehicle_type.toString(),
                                        items.vehicle_capacity.toString(),
                                        items.fuel_used.toString(),
                                        items.unit.toString()
                                    );
                                    if (result) {

                                        let from = items.from;
                                        let to = items.to;
                                        let btr = {};
                                        let id = getID();
                                        console.log(from, to);
                                        var directionService = new google.maps.DirectionsService();
                                        local["id"] = id;
                                        local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                        local["DPA0291"] = { name: "Road" };
                                        local["DPA0292"] = parseFloat(
                                            items.no_of_passenger.toString()
                                        );
                                        local["DPA0293"] = items.from;
                                        local["DPA0294"] = items.to;
                                        local["attachment"] = null;
                                        local["DPA0295"] = id_[0];
                                        local["DPA0337"] = id_[1];
                                        local["DPA0338"] = id_[2];
                                        local["DPA0338G"] = id_[3];

                                        local2.push(local);
                                        var request = {
                                            provideRouteAlternatives: true,
                                            waypoints: [],
                                            origin: from,
                                            destination: to,
                                            travelMode: google.maps.TravelMode.DRIVING,
                                            unitSystem: google.maps.UnitSystem.IMPERIAL,
                                        };

                                        directionService
                                            .route(request, (result, status) => {
                                                if (status === google.maps.DirectionsStatus.OK) {
                                                    let distance =
                                                        result.routes[0].legs[0].distance.text.toString();
                                                    let km =
                                                        1.60934 * parseFloat(distance.replace("mi", ""));

                                                    let uindex = unit_list.findIndex((k) => {
                                                        return (
                                                            k.id === local["DPA0338G"] &&
                                                            k.newEfSubcategory3Id === local["DPA0338"]
                                                        );
                                                    });

                                                    let index = local2.findIndex((i) => {
                                                        return i.id === id;
                                                    });

                                                    if (index !== -1 && uindex !== -1) {
                                                        if (
                                                            unit_list[uindex].title.trim().toLowerCase() ===
                                                            "km" ||
                                                            unit_list[uindex].title.trim().toLowerCase() ===
                                                            ".km"
                                                        ) {
                                                            local2[index]["DP_KM"] = km;
                                                        } else {
                                                            local2[index]["DP_KM"] = parseFloat(
                                                                distance.replace("mi", "")
                                                            );
                                                        }

                                                        setBTData(local2);

                                                        forceUpdate();
                                                    }
                                                } else {
                                                    let index = local2.findIndex((i) => {
                                                        return i.id === id;
                                                    });
                                                    if (index !== -1) {
                                                        local2.splice(index, 1);
                                                        setBTData(local2);
                                                        forceUpdate();
                                                    }
                                                }
                                            })
                                            .catch((a) => {
                                                if (a.code === "NOT_FOUND") {
                                                }
                                            });
                                    }
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Air_Direct") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Air") {
                            sname[Object.keys(sname)[0]].forEach((items, legind) => {
                                local = {};
                                let id = getID() + '_' + legind;

                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.from !== null &&
                                    items.from.trim().length === 3 &&
                                    items.to != null &&
                                    items.to.trim().length === 3 &&
                                    items['Emission Factor_in_tCO2e/passenger km'] != null &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    items.distance_in_km != null && items.emissions_in_tco2e != null &&
                                    parseInt(items.no_of_passenger.toString().trim()) >= 1 &&
                                    items.class !== undefined &&
                                    items.class.toString().trim().length !== 0
                                ) {
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Air" };
                                    local["id"] = id;
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from.trim().toUpperCase();
                                    local["DPA0294"] = items.to.trim().toUpperCase();
                                    local["DPA0296"] = { name: items.class };
                                    local["attachment"] = null;
                                    local['DP_co2e_mt'] = parseFloat(items.emissions_in_tco2e.toString());
                                    local['DP_KM'] = parseFloat(items.distance_in_km.toString());
                                    local['efValue'] = parseFloat(items['Emission Factor_in_tCO2e/passenger km'].toString());


                                    local2.push(local);


                                } else {
                                    console.log(items)
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Road_Direct") {
                    let local = {};
                    let unit_list = JSON.parse(JSON.stringify(unit_));

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Road") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};

                                if (
                                    items.date !== null &&
                                    typeof items.date === "number" &&
                                    items.from !== null &&
                                    items.from.trim().length !== 0 &&
                                    items.to !== null &&
                                    items.to.trim().length !== 0 &&
                                    items.vehicle_type !== null &&
                                    items.vehicle_capacity !== null &&
                                    items.fuel_used !== null &&
                                    items.unit !== null &&
                                    items.no_of_passenger != null &&
                                    items.no_of_passenger >= 1 &&
                                    items['Emission Factor_in_tCO2e/passenger km'] != null &&
                                    items.distance_in_km != null && items.emissions_in_tco2e != null
                                ) {
                                    const { result, id_ } = findMatchingIds(
                                        category,
                                        items.vehicle_type.toString(),
                                        items.vehicle_capacity.toString(),
                                        items.fuel_used.toString(),
                                        items.unit.toString()
                                    );
                                    console.log(result, id_)
                                    if (result) {

                                        let from = items.from;
                                        let to = items.to;
                                        let btr = {};
                                        let id = getID();
                                        console.log(from, to);
                                        var directionService = new google.maps.DirectionsService();
                                        local["id"] = id;
                                        local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                        local["DPA0291"] = { name: "Road" };
                                        local["DPA0292"] = parseFloat(
                                            items.no_of_passenger.toString()
                                        );
                                        local["DPA0293"] = items.from;
                                        local["DPA0294"] = items.to;
                                        local["attachment"] = null;
                                        local["DPA0295"] = id_[0];
                                        local["DPA0337"] = id_[1];
                                        local["DPA0338"] = id_[2];
                                        local["DPA0338G"] = id_[3];
                                        local['DP_co2e_mt'] = parseFloat(items.emissions_in_tco2e.toString());
                                        local['DP_KM'] = parseFloat(items.distance_in_km.toString());
                                        local['efValue'] = parseFloat(items['Emission Factor_in_tCO2e/passenger km'].toString());

                                        local2.push(local);

                                    } else {
                                        console.log(items)
                                    }
                                }
                            });
                        }
                    });
                }
                setBTData(local2);
                console.log(local2);
                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.value = null;
    };
    console.log(btdata, "testing");
    const [btVal, setBtVal] = useState({
        DPA0290: moment(moment.utc()).toDate(),
        legs: [],
        DPA0291: { name: "Air" },
        DPA0295: null,
        DPA0337: null,
        DPA0338: null,
        DPA0338G: null,
        DPA0293: "",
        DPA0294: "",
        DPA0292: 1,
        attachment: null,
        DPA0296: null,
    });
    const [selectedAutoValue, setSelectedAutoValue] = useState({
        DPA0293: "",
        DPA0294: "",
    });
    useEffect(() => {
        let veh_cat = [];
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(API.EF_SC4).then((res) => {
            setUnitList_(res.data);
        });
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 6);
                        let cat_index2 = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 7);
                        console.log(cat_index, cat_index2);
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    useState(() => {
        setData();
    }, [btdata]);
    getData(btdata);

    console.log(btdata);
    const deleteRow = (item) => {
        let loc = btdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBTData(loc);
        forceUpdate();
    };

    const bt_export = [
        {
            label: "Road",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                            vehicle_type: null,
                            vehicle_capacity: null,
                            fuel_used: null,
                            unit: null,
                        },
                    ],
                    "S3_BT_Road",
                    "BT_Road"
                );
            },
        },
        {
            label: "Rail",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                        },
                    ],
                    "S3_BT_Rail",
                    "BT_Rail"
                );
            },
        },
        {
            label: "Air",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                            class: null,
                        },
                    ],
                    "S3_BT_Air",
                    "BT_Air"
                );
            },
        },
    ];
    const bt_import = [
        {
            label: "Road",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    console.log(e.target.files[0]);
                    importFromExcel(e.target, "BT_Road");
                };
            },
        },
        {
            label: "Rail",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Rail");
                };
            },
        },
        {
            label: "Air",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Air");
                };
            },
        }, {
            label: "Air Direct",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Air_Direct");
                };
            },
        }
    ];
    const export2Excel = () => {
        let iata_code = [];
        IATACodes.forEach((item) => {
            iata_code.push({
                Code: item.label.split("/")[2],
                Country: item.label.split("/")[1],
                Airport: item.label.split("/")[0],
            });
        });

        const ws = XLSX.utils.json_to_sheet(iata_code);
        let sheet_name = "IATA Airline Code";
        const wb = {
            Sheets: { [sheet_name]: ws },
            SheetNames: [sheet_name],
        };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, sheet_name + ".xlsx");
    };
    const btheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="co-12 justify-content-end flex">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setBtVal({
                                DPA0290: moment(moment.utc()).toDate(),
                                legs: [],
                                DPA0291: { name: "Air" },
                                DPA0295: null,
                                DPA0337: null,
                                DPA0338: null,
                                DPA0338G: null,
                                DPA0293: "",
                                DPA0294: "",
                                DPA0292: 1,
                                attachment: null,
                                DPA0296: null,
                            });
                            setSubmitted(false);
                            setBTDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBTFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const updateBtVal = (obj, val) => {
        let local = btVal;
        local[obj] = val;
        console.log(obj, val);

        if (obj === "DPA0295") {
            let vehtype = [];
            category.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory2s !== undefined) {
                        vehtype = item.newEfSubcategory2s;
                    }
                }
            });
            setVehTypeList(vehtype);
            local.DPA0337 = null;
            local.DPA0338 = null;
            local.DPA0338G = null;
        }
        if (obj === "DPA0337") {
            let fueltype = [];
            vehtypelist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory3s !== undefined) {
                        fueltype = item.newEfSubcategory3s;
                    }
                }
            });
            console.log(fueltype);

            setVehFuelList(fueltype);

            local.DPA0338 = null;
            local.DPA0338G = null;
        }
        if (obj === "DPA0338") {
            let units = [];
            vehfuellist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory4s !== undefined) {
                        units = item.newEfSubcategory4s;
                    }
                }
            });
            setUnitList(units);
            local.DPA0338G = null;
        }
        if (obj === "DPA0338G") {
            local.DPA0293 = "";
            local.DPA0294 = "";
            setTimeout(() => {
                if (document.getElementById("fromplace")) {
                    document.getElementById("fromplace").value = "";
                }
                if (document.getElementById("toplace")) {
                    document.getElementById("toplace").value = "";
                }

            }, 1000);
        }

        if (obj === "mode") {
            setBtVal({
                DPA0290: moment(moment.utc()).toDate(),
                legs: [],
                DPA0291: { name: "Air" },
                DPA0295: null,
                DPA0337: null,
                DPA0338: null,
                DPA0338G: null,
                DPA0293: "",
                DPA0294: "",
                DPA0292: 1,

                attachment: null,
                DPA0296: null,
            });
        } else {
            setBtVal(local);
        }

        forceUpdate();
    };
    const saveBusinessTravel = () => {
        setSubmitted(true);
        let local = btVal;
        let local2 = btdata;

        if (btVal.DPA0291 !== null) {
            if (
                btVal.DPA0291.name === "Road" &&
                btVal.DP_KM !== undefined &&
                parseFloat(btVal.DP_KM) > 0 &&
                parseInt(btVal.DPA0292) > 0 &&
                btVal.DPA0295 !== null &&
                btVal.DPA0337 !== null &&
                btVal.DPA0338 !== null &&
                btVal.DPA0338G !== null &&

                btVal.DPA0293.trim().length !== 0 &&
                btVal.DPA0294.trim().length !== 0
            ) {
                if (true) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }

                    setBtVal({
                        DPA0290: moment(moment.utc()).toDate(),
                        legs: [],
                        DPA0291: { name: "Air" },
                        DPA0295: null,
                        DPA0337: null,
                        DPA0338: null,
                        DPA0338G: null,
                        DPA0293: "",
                        DPA0294: "",
                        DPA0292: 1,
                        attachment: null,
                        DPA0296: null,
                    });
                    setBTData(local2);
                    setBTDialog(false);
                    forceUpdate();
                }
            } else if (
                btVal.DPA0291.name === "Air" &&
                parseInt(btVal.DPA0292) > 0 &&

                btVal.legs.length !== 0
            ) {
                let local2 = btdata;

                if (
                    btVal.legs.findIndex((i) => {
                        return i.DP_co2e_mt === undefined;
                    }) === -1
                ) {
                    btVal.legs.forEach((item) => {
                        local2.push({
                            id: getID(),
                            DPA0290: item.DPA0290,
                            legs: [item],
                            DPA0291: btVal.DPA0291,
                            DP_co2e_mt: item.DP_co2e_mt,
                            DPA0293: item.DPA0293,
                            DPA0294: item.DPA0294,
                            DPA0292: btVal.DPA0292,
                            attachment: btVal.attachment,
                        });
                    });

                    setBtVal({
                        DPA0290: moment(moment.utc()).toDate(),
                        legs: [],
                        DPA0291: { name: "Air" },
                        DPA0295: null,
                        DPA0337: null,
                        DPA0338: null,
                        DPA0293: "",
                        DPA0294: "",
                        DPA0292: 1,
                        attachment: null,
                        DPA0296: null,
                    });
                    setBTData(local2);
                    setBTDialog(false);
                    forceUpdate();
                } else {
                    alert("calculate emission factor before saving");
                }
            } else if (
                btVal.DPA0291.name === "Rail" &&
                btVal.DP_KM !== undefined &&
                parseFloat(btVal.DP_KM) > 0 &&
                btVal.DPA0293.trim().length !== 0 &&
                btVal.DPA0294.trim().length !== 0
            ) {
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }

                setBtVal({
                    DPA0290: moment(moment.utc()).toDate(),
                    legs: [],
                    DPA0291: { name: "Air" },
                    DPA0295: null,
                    DPA0337: null,
                    DPA0338: null,
                    DPA0293: "",
                    DPA0294: "",
                    DPA0292: 1,
                    attachment: null,
                    DPA0296: null,
                });
                setBTData(local2);
                setBTDialog(false);
                forceUpdate();
            }
        }
    };
    const btDialogFooter = (
        <>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setBTDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveBusinessTravel();
                }}
            />
        </>
    );
    const btAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplateBt = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const addLegDialogFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setAddLegDialog(false);
                    setLegData({});
                    setSelectedAutoValue({
                        DPA0293: "",
                        DPA0294: "",
                    });
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    setSubmitted(true);
                    if (
                        legData.DPA0290 !== undefined &&
                        legData.DPA0296 !== undefined &&
                        legData.DPA0293 !== undefined &&
                        legData.DPA0294 !== undefined
                    ) {
                        let local = btVal;
                        local.legs.push(legData);
                        setBtVal(local);
                        setLegData([]);
                        setSelectedAutoValue({
                            DPA0293: "",
                            DPA0294: "",
                        });
                        setSubmitted(false);
                        setAddLegDialog(false);
                    }
                }}
            />
        </>
    );
    const updateACValue = (obj, val) => {
        if (obj !== "DPA0290") {
            let value = selectedAutoValue;
            value[obj] = val;
            setSelectedAutoValue(value);
        }

        let leg = legData;
        if (obj === "DPA0290") {
            leg[obj] = val;
        } else if (obj === "DPA0296") {
            leg[obj] = val;
        } else {
            if (typeof val === "object" && val.value.includes("/")) {
                leg[obj] = val.value.split("/")[2];
            }
        }

        setLegData(leg);

        forceUpdate();
    };
    function roundValues(num) {
        var m = Number((Math.abs(num) * 100).toPrecision(6));
        return (Math.round(m) / 100) * Math.sign(num);
    }
    // RenderMap

    const renderMapRoute = () => {
        let check = 0;

        if (btVal.DPA0291.name === "Road") {
            if (
                btVal.DPA0295 !== null &&
                btVal.DPA0337 !== null &&
                btVal.DPA0338 !== null &&
                btVal.DPA0338G !== null
            ) {
                check = 1;
            }
        } else {
            check = 1;
        }

        if (check === 1) {
            //   if (document.getElementById('resultTabel') !== null) { document.getElementById('resultTabel').innerHTML = "" }
            let from = document.getElementById("fromplace").value.trim();
            let to = document.getElementById("toplace").value.trim();

            var directionService = new google.maps.DirectionsService();
            var directionDisplay = new google.maps.DirectionsRenderer({
                draggable: true,
            });

            let btr = btVal;
            var request = {
                provideRouteAlternatives: true,
                waypoints: [],
                origin: from,
                destination: to,
                travelMode:
                    btVal.DPA0291.name === "Road"
                        ? google.maps.TravelMode.DRIVING
                        : google.maps.TravelMode.TRANSIT,
                unitSystem: google.maps.UnitSystem.IMPERIAL,
            };

            if (from.split(",").length !== 1 && to.split(",").length !== 1) {
                directionService
                    .route(request, (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            let distance = result.routes[0].legs[0].distance.text.toString();

                            if (btVal.DPA0291.name === "Rail") {
                                let km = 1.60934 * parseFloat(distance.replace("mi", ""));
                                btr["DP_KM"] = roundValues(km);
                                btr.DPA0293 = document
                                    .getElementById("fromplace")
                                    .value.trim()
                                    .split(",")[0];
                                btr.DPA0294 = document
                                    .getElementById("toplace")
                                    .value.trim()
                                    .split(",")[0];
                            } else {
                                let unit_ = unitlist.find((i) => i.id === btVal.DPA0338G).title;

                                if (unit_.trim().toLowerCase() === "km") {
                                    let km = 1.60934 * parseFloat(distance.replace("mi", ""));
                                    console.log(km);
                                    btr["DP_KM"] = roundValues(km);
                                } else {
                                    let km = parseFloat(distance.replace("mi", ""));
                                    btr["DP_KM"] = roundValues(km);
                                }

                                btr.DPA0293 = document
                                    .getElementById("fromplace")
                                    .value.trim()
                                    .split(",")[0];
                                btr.DPA0294 = document
                                    .getElementById("toplace")
                                    .value.trim()
                                    .split(",")[0];
                            }
                            setBtVal(btr);
                        } else {
                            btr.tco2e = 0;
                            btr.DPA0293 = "";
                            btr.DPA0294 = "";
                            btr["direction"] = "";
                            btr.km = 0;
                            alert("Route not found between" + from + " & " + to);
                        }
                    })
                    .catch((a) => console.log(a, "err"));
            }
        }
    };
    //   Map Dialog
    const onLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds({
            lat: 13.017092278167553,
            lng: 80.1987183651303,
        });
        map.fitBounds(bounds);
    };
    const calculateEmission = () => {
        let apiLegData = [];
        btVal.legs.forEach((item, i) => {
            console.log(item);
            apiLegData.push({
                iata_airport_from: item.DPA0293,
                iata_airport_to: item.DPA0294,
                number_of_passengers: parseInt(btVal.DPA0292),
                flight_class: item.DPA0296.name.toLowerCase(),
                round_trip: "N",
                add_rf: "N",
                include_wtt: "N",
            });
        });
        apiLegData.forEach((legs, legind) => {
            const encodedParams = new URLSearchParams();
            encodedParams.append("iata_airport_from", legs.iata_airport_from);
            encodedParams.append("iata_airport_to", legs.iata_airport_to);
            encodedParams.append("number_of_passengers", legs.number_of_passengers);
            encodedParams.append("flight_class", legs.flight_class);
            encodedParams.append("round_trip", legs.round_trip);
            encodedParams.append("add_rf", legs.add_rf);
            encodedParams.append("include_wtt", legs.include_wtt);

            const options = {
                method: "POST",
                url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    "X-RapidAPI-Key":
                        "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                    "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                },
                data: encodedParams,
            };
            // 'fd38be8d58msh9125eea580172f9p1dd1cbjsn67325a6ce234'
            Axios.request(options).then((res) => {
                if (res.status === 200) {
                    let local = btVal;
                    let total = 0;
                    local.legs[legind]["DP_co2e_mt"] = res.data.data.co2e_mt;

                    setBtVal(local);
                    forceUpdate();
                }
            });
        });

        forceUpdate();
    };
    const onUnmount = (map) => {
        console.log("unmount");
    };
    // Autocomplete Airport Code
    const searchAirCode = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...autoValue]);
            } else {
                setAutoFilteredValue(
                    autoValue.filter((country) => {
                        return country.value
                            .toLowerCase()
                            .includes(event.query.toLowerCase());
                    })
                );
            }
        }, 250);
    };
    return (
        <div className="col-12 lg:col-12">
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-3">
                        <SplitButton
                            label="Template"
                            icon="pi pi-download"
                            model={bt_export}
                        ></SplitButton>
                    </div>
                    <div className="col-3">
                        <SplitButton
                            label="Import"
                            icon="pi pi-file-excel"
                            model={bt_import}
                        ></SplitButton>
                    </div>
                    <div className="col-3">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    export2Excel();
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "IATA Airport Code",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    {/* <div className="col-3">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button mr-2"
                            style={{
                                width: 100,
                            }}
                            onClick={() => {
                                setBtVal({
                                    DPA0290: moment(moment.utc()).toDate(),
                                    legs: [],
                                    DPA0291: { name: "Air" },
                                    DPA0295: null,
                                    DPA0337: null,
                                    DPA0338: null,
                                    DPA0338G: null,
                                    DPA0293: "",
                                    DPA0294: "",
                                    DPA0292: 1,
                                    attachment: null,
                                    DPA0296: null,
                                });
                                setSubmitted(false);
                                setBTDialog(true);
                            }}
                        />
                    </div> */}
                </div>
            )}
            <DataTable
                scrollable
                value={btdata}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehtype.name", "vehno"]}
                globalFilter={btFilter}
                emptyMessage="No data entered yet"

                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0290"
                    header="Date"
                    body={dateFormatting_bt}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0291.name"
                    header="Travel Mode"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0293"
                    header="From"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0294"
                    header="To"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0292"
                    header="Passenger"
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DP_KM"
                    header="Distance in KM"
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DP_co2e_mt"
                    header="Emissions in tCo2e"
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="attachment"
                    header="Attachment"
                    body={btAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateBt}> </Column>}
            </DataTable>
            <Dialog
                visible={btDialog}
                style={{
                    width: "50%",
                }}
                header="Add Business Travel"
                modal
                className="p-fluid"
                footer={btDialogFooter}
                onHide={() => {
                    setBTDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ectype"> Mode </label>
                    <Dropdown
                        value={btVal.DPA0291}
                        onChange={(e) => {
                            updateBtVal("DPA0291", e.value);
                        }}
                        options={[{ name: "Air" }, { name: "Rail" }, { name: "Road" }]}
                        optionLabel="name"
                        placeholder="Select"
                    />
                    {submitted && btVal.DPA0291 === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select Mode
                        </small>
                    )}
                </div>
                {(btVal.DPA0291.name === "Rail" || btVal.DPA0291.name === "Road") && (
                    <div className="field">
                        <label htmlFor="ecdate"> Date </label>
                        <Calendar
                            minDate={dates.minDate} maxDate={dates.maxDate}
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            value={btVal.DPA0290}
                            showMinMaxRange={true}
                            showOtherMonths={false}
                            viewDate={dates.minDate}
                            onChange={(e) => updateBtVal("DPA0290", e.value)}
                        ></Calendar>
                    </div>
                )}
                <div className="field">
                    <label htmlFor="ecpsngr"> Passenger Travelled </label>
                    <InputNumber
                        min={1}
                        value={btVal.DPA0292}
                        onChange={(e) => {
                            updateBtVal("DPA0292", e.value);
                        }}
                        placeholder="Passenger Travelled"
                    />
                    {submitted && parseInt(btVal.DPA0292) <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Passenger is required.
                        </small>
                    )}
                </div>
                {btVal.DPA0291.name === "Road" && (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr"> Vehicle Type </label>
                            <Dropdown
                                value={btVal.DPA0295}
                                onChange={(e) => {
                                    updateBtVal("DPA0295", e.value);
                                }}
                                options={category}
                                optionValue="id"
                                optionLabel="title"
                                placeholder="Select"
                            />
                            {submitted && btVal.DPA0295 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Type
                                </small>
                            )}
                        </div>
                        {btVal.DPA0295 !== null && (
                            <>
                                <div className="field">
                                    <label htmlFor="ecpsngr"> Seating Capacity </label>
                                    <Dropdown
                                        value={btVal.DPA0337}
                                        onChange={(e) => {
                                            updateBtVal("DPA0337", e.value);
                                        }}
                                        options={vehtypelist}
                                        optionValue="id"
                                        optionLabel="title"
                                        placeholder="Select"
                                    />
                                    {submitted && btVal.DPA0337 === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Select Capacity
                                        </small>
                                    )}
                                </div>
                                {btVal.DPA0337 !== null && (
                                    <div className="field">
                                        <label htmlFor="ecpsngr"> Fuel </label>
                                        <Dropdown
                                            value={btVal.DPA0338}
                                            onChange={(e) => {
                                                updateBtVal("DPA0338", e.value);
                                            }}
                                            options={vehfuellist}
                                            optionValue="id"
                                            optionLabel="title"
                                            placeholder="Select"
                                        />
                                        {submitted && btVal.DPA0338 === null && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Select Fuel Category
                                            </small>
                                        )}
                                    </div>
                                )}
                                {btVal.DPA0338 !== null && (
                                    <div className="field">
                                        <label htmlFor="ecpsngr"> Unit of Measurement </label>
                                        <Dropdown
                                            value={btVal.DPA0338G}
                                            onChange={(e) => {
                                                updateBtVal("DPA0338G", e.value);
                                            }}
                                            options={unitlist}
                                            optionValue="id"
                                            optionLabel="title"
                                            placeholder="Select"
                                        />
                                        {submitted && btVal.DPA0338G === null && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Select unit
                                            </small>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
                {(btVal.DPA0291.name === "Rail" ||
                    (btVal.DPA0291.name === "Road" &&
                        btVal.DPA0338 !== null &&
                        btVal.DPA0338G !== null)) && (
                        <>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="eckmdriven"> From </label>
                                    <div id="eckmdriven" className="p-inputgroup">
                                        <Autocomplete
                                            className="autocomplete_width"
                                            onLoad={(place) => console.log("")}
                                            onPlaceChanged={() => {
                                                renderMapRoute("DPA0293");
                                            }}
                                        >
                                            <input
                                                id="fromplace"
                                                type="text"
                                                placeholder="From"
                                                style={{
                                                    boxSizing: `border-box`,
                                                    border: `1px solid transparent`,
                                                    width: `100%`,
                                                    height: `35px`,
                                                    padding: `0 12px`,
                                                    borderRadius: `3px`,
                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    fontSize: `14px`,
                                                    outline: `none`,
                                                    textOverflow: `ellipses`,
                                                }}
                                            ></input>
                                        </Autocomplete>
                                    </div>
                                </div>
                                <div className="field col">
                                    <label htmlFor="ovGHG"> To </label>
                                    <div id="ovGHG" className="p-inputgroup">
                                        <Autocomplete
                                            className="autocomplete_width"
                                            onLoad={(place) => console.log("")}
                                            onPlaceChanged={() => {
                                                renderMapRoute("DPA0294");
                                            }}
                                        >
                                            <input
                                                id="toplace"
                                                type="text"
                                                placeholder="To"
                                                style={{
                                                    boxSizing: `border-box`,
                                                    border: `1px solid transparent`,
                                                    width: `100%`,
                                                    height: `35px`,
                                                    padding: `0 12px`,
                                                    borderRadius: `3px`,
                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    fontSize: `14px`,
                                                    outline: `none`,
                                                    textOverflow: `ellipses`,
                                                }}
                                            ></input>
                                        </Autocomplete>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                {btVal.DPA0291.name === "Air" && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                style={{
                                    color: "green",
                                    textDecoration: "underline",
                                    marginBottom: 20,
                                }}
                                onClick={() => {
                                    setAddLegDialog(true);
                                }}
                            >
                                add Leg
                            </span>
                        </div>
                        {btVal.legs.length !== 0 && (
                            <div
                                style={{
                                    marginBottom: 20,
                                    overflow: "scroll",
                                    maxHeight: 250,
                                }}
                            >
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        width: "100%",
                                        background: "lightgrey",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            width: "40%",
                                        }}
                                    >
                                        Date
                                    </span>
                                    <span
                                        style={{
                                            width: "20%",
                                        }}
                                    >
                                        From
                                    </span>
                                    <span
                                        style={{
                                            width: "20%",
                                        }}
                                    >
                                        To
                                    </span>
                                </div>
                                {btVal.legs.map((i) => {
                                    return (
                                        <div
                                            style={{
                                                flexDirection: "row",
                                                display: "flex",
                                                width: "100%",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: "40%",
                                                }}
                                            >
                                                {dateFormatting_bt(i.DPA0290)}
                                            </span>
                                            <span
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                {i.DPA0293}
                                            </span>
                                            <span
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                {i.DPA0294}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {submitted && btVal.legs.length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Travel Legs required
                            </small>
                        )}
                        {btVal.legs.length !== 0 && (
                            <div>
                                <Button
                                    style={{ marginBottom: 10 }}
                                    label="Calculate Emission"
                                    className="p-button-text"
                                    onClick={(e) => {
                                        calculateEmission();
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        className="mt-5"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(btVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], btVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = btVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setBtVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(btVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {btVal.attachment !== null && (
                    <>
                        {btVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    btVal.attachment.splice(index, 1);
                                                    if (btVal.attachment.length === 0) {
                                                        btVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>

            <Dialog
                visible={addLegDialog}
                style={{
                    width: "40%",
                }}
                header="Add Leg"
                modal
                footer={addLegDialogFooter}
                onHide={() => {
                    setAddLegDialog(false);
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="field col-6">
                            <label htmlFor="ecdate"> Travel Date </label>
                            <Calendar
                                minDate={dates.minDate} maxDate={dates.maxDate}
                                dateFormat={'dd/mm/yy'}
                                showIcon
                                style={{
                                    width: "100%",
                                }}
                                showMinMaxRange={true}
                                showOtherMonths={false}
                                viewDate={dates.minDate}
                                value={legData.DPA0290}
                                onChange={(e) => updateACValue("DPA0290", e.value)}
                            ></Calendar>
                            {submitted &&
                                (legData.DPA0290 === "" || legData.DPA0290 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Date
                                    </small>
                                )}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="ectype"> Class </label>
                            <Dropdown
                                style={{
                                    width: "100%",
                                }}
                                value={legData.DPA0296}
                                onChange={(e) => {
                                    updateACValue("DPA0296", e.value);
                                }}
                                options={[
                                    { name: "Economy" },
                                    { name: "Premium" },
                                    { name: "First" },
                                    { name: "Business" },
                                ]}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted &&
                                (legData.DPA0296 === "" || legData.DPA0296 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Class
                                    </small>
                                )}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="field col-6">
                            <label htmlFor="aufrom"> From </label>
                            <AC
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search"
                                id="fromau"
                                dropdown
                                completeMethod={searchAirCode}
                                suggestions={autoFilteredValue}
                                onChange={(e) => updateACValue("DPA0293", e.value)}
                                value={selectedAutoValue.DPA0293}
                                field="value"
                            />
                            {submitted &&
                                (legData.DPA0293 === "" || legData.DPA0293 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select From
                                    </small>
                                )}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="auto"> To </label>
                            <AC
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search"
                                id="toau"
                                dropdown
                                completeMethod={searchAirCode}
                                suggestions={autoFilteredValue}
                                onChange={(e) => updateACValue("DPA0294", e.value)}
                                value={selectedAutoValue.DPA0294}
                                field="value"
                            />
                            {submitted &&
                                (legData.DPA0294 === "" || legData.DPA0294 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select To
                                    </small>
                                )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
// Mobile Combustion
const Mobile_Combustion = ({ data, getData, setData, edit, dates }) => {
    const [mcdata, setMCData] = useState(data);
    const [mcDialog, setMCDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [attachment, setAttachment] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [mcFilter, setMCFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [mcVal, setMcVal] = useState({
        DPA0290: moment(moment.utc()).toDate(),
        DP_MODE: true,
        DPA0341: "",
        DPA0140: null,
        DPA0139: null,
        DPA0339: null,
        DPA0141: null,
        DPA0340: null,
        DPA0144: "",
        DPA0143: "",
        DPA0142: null,
        attachment: null,
    });

    useEffect(() => {
        let veh_cat = [];
        mobile_combustion.forEach((i) => {
            if (
                veh_cat.findIndex((j) => {
                    return j.name === i.vehicle_category;
                }) === -1
            ) {
                veh_cat.push({ name: i.vehicle_category });
            }
        });
        setVehCatList(veh_cat);
    }, []);
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Mobile Combustion Emissions") {
                    let local = {};
                    let local2 = mcdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_OV") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                if (
                                    items['Data Captured By'] !== null &&
                                    items['Vehicle No'] !== null &&
                                    items['Vehicle No'].toString().trim().length !== 0 &&
                                    items['Data Captured By'].toString().trim().length !== 0 &&
                                    (items['Data Captured By'].toString().toLowerCase() === "fuel" ||
                                        items['Data Captured By'].toString().toLowerCase() === "distance")
                                ) {
                                    let categoryind = mobile_combustion.findIndex((i) => {
                                        return (
                                            i.vehicle_category.trim().toLowerCase() ===
                                            items['Vehicle Category'].trim().toString().toLowerCase()
                                        );
                                    });

                                    if (
                                        items['Vehicle Category'] !== null &&
                                        items['Vehicle Category'].toString().trim().length !== 0 &&
                                        categoryind !== -1
                                    ) {
                                        let typeind = mobile_combustion.findIndex((i) => {
                                            return (
                                                i.vehicle_category === items['Vehicle Category'] &&
                                                i.vehicle_type.trim().toLowerCase() ===
                                                items['Vehicle Type'].trim().toString().toLowerCase()
                                            );
                                        });
                                        if (
                                            items['Vehicle Type'] !== null &&
                                            items['Vehicle Type'].toString().trim().length !== 0 &&
                                            typeind !== -1
                                        ) {
                                            let fuelind = mobile_combustion[
                                                typeind
                                            ].fuel_type.findIndex((i) => {
                                                return (
                                                    i.name.toString().trim().toLowerCase() ===
                                                    items['Fuel Type'].trim().toString().toLowerCase()
                                                );
                                            });
                                            if (
                                                items['Fuel Type'] !== null &&
                                                items['Fuel Type'].toString().trim().length !== 0 &&
                                                fuelind !== -1
                                            ) {
                                                if (
                                                    items['Vehicle Category']
                                                        .toString()
                                                        .toLowerCase()
                                                        .includes("hgv")
                                                ) {
                                                    if (
                                                        items['Laden Percentage'] !== null &&
                                                        (items['Laden Percentage'].toString() === "0%" ||
                                                            items['Laden Percentage'].toString() === "50%" ||
                                                            items['Laden Percentage'].toString() === "100%" ||
                                                            items['Laden Percentage'].toString() === "Average")
                                                    ) {
                                                        if (
                                                            items['Data Captured By'] === "fuel" &&
                                                            items['Fuel Refilled'] !== null &&
                                                            parseInt(items['Fuel Refilled'].toString()) > 0 &&
                                                            items['Fuel Refill Date'] !== null &&
                                                            typeof items['Fuel Refill Date'] === "number"
                                                        ) {
                                                            local["attachment"] = null;
                                                            local["DP_MODE"] = false;
                                                            local["DPA0341"] = items['Vehicle No'];
                                                            local["DPA0140"] = {
                                                                name: mobile_combustion[categoryind]
                                                                    .vehicle_category,
                                                            };
                                                            local["DPA0139"] = {
                                                                name: mobile_combustion[typeind].vehicle_type,
                                                            };
                                                            local["DPA0141"] = {
                                                                name: mobile_combustion[typeind].fuel_type[
                                                                    fuelind
                                                                ].name,
                                                            };
                                                            local["DPA0142"] = moment(
                                                                excelDateToJSDate(items['Fuel Refill Date'])
                                                            )._d;
                                                            local["DPA0143"] = items['Fuel Refilled'];
                                                            local["DPA0340"] = {
                                                                name: items['Laden Percentage'],
                                                            };
                                                            local["id"] = getID();
                                                            local2.push(local);
                                                        } else if (
                                                            items['Data Captured By'] === "distance" &&
                                                            items['Travelled Distance'] !== null &&
                                                            parseInt(items['Travelled Distance'].toString()) > 0 &&
                                                            items['Fuel Refill Date'] !== null &&
                                                            typeof items['Fuel Refill Date'] === "number"
                                                        ) {
                                                            if (
                                                                items['Distance Measurement Unit'] !== null &&
                                                                (items['Distance Measurement Unit']
                                                                    .toString()
                                                                    .trim() === "km" ||
                                                                    items['Distance Measurement Unit']
                                                                        .toString()
                                                                        .trim() === "mi")
                                                            ) {
                                                                local["DPA0341"] = items['Vehicle No'];
                                                                local["DPA0140"] = {
                                                                    name: mobile_combustion[categoryind]
                                                                        .vehicle_category,
                                                                };
                                                                local["DPA0139"] = {
                                                                    name: mobile_combustion[typeind].vehicle_type,
                                                                };
                                                                local["DPA0141"] = {
                                                                    name: mobile_combustion[typeind].fuel_type[
                                                                        fuelind
                                                                    ].name,
                                                                };
                                                                local["DPA0144"] =
                                                                    items['Travelled Distance'].toString();
                                                                local["DPA0339"] = {
                                                                    name: items['Distance Measurement Unit'].toString().trim().toLowerCase(),
                                                                };
                                                                local["DPA0340"] = {
                                                                    name: items['Laden Percentage'],
                                                                };
                                                                local["attachment"] = null;
                                                                local["DP_MODE"] = true;
                                                                local["id"] = getID();
                                                                local2.push(local);
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if (
                                                        items['Data Captured By'] === "fuel" &&
                                                        items['Fuel Refilled'] !== null &&
                                                        parseInt(items['Fuel Refilled'].toString()) > 0 &&
                                                        items['Fuel Refill Date'] !== null &&
                                                        typeof items['Fuel Refill Date'] === "number"
                                                    ) {
                                                        local["DPA0341"] = items['Vehicle No'];
                                                        local["DPA0140"] = {
                                                            name: mobile_combustion[categoryind]
                                                                .vehicle_category,
                                                        };
                                                        local["DPA0139"] = {
                                                            name: mobile_combustion[typeind].vehicle_type,
                                                        };
                                                        local["DPA0141"] = {
                                                            name: mobile_combustion[typeind].fuel_type[
                                                                fuelind
                                                            ].name,
                                                        };
                                                        local["DPA0142"] = moment(
                                                            excelDateToJSDate(items['Fuel Refill Date'])
                                                        )._d;
                                                        local["DPA0143"] = items['Fuel Refilled'].toString();
                                                        local["DPA0340"] = null;
                                                        local["attachment"] = null;
                                                        local["DP_MODE"] = false;
                                                        local["id"] = getID();
                                                        local2.push(local);
                                                    } else if (
                                                        items['Data Captured By'] === "distance" &&
                                                        items['Travelled Distance'] !== null &&
                                                        parseInt(items['Travelled Distance'].toString()) > 0 &&
                                                        items['Fuel Refill Date'] !== null &&
                                                        typeof items['Fuel Refill Date'] === "number"
                                                    ) {
                                                        if (
                                                            items['Distance Measurement Unit'] !== null &&
                                                            (items['Distance Measurement Unit']
                                                                .toString()
                                                                .trim() === "km" ||
                                                                items['Distance Measurement Unit']
                                                                    .toString()
                                                                    .trim() === "mi")
                                                        ) {
                                                            local["DPA0341"] = items['Vehicle No'];
                                                            local["DPA0140"] = {
                                                                name: mobile_combustion[categoryind]
                                                                    .vehicle_category,
                                                            };
                                                            local["DPA0139"] = {
                                                                name: mobile_combustion[typeind].vehicle_type,
                                                            };
                                                            local["DPA0141"] = {
                                                                name: mobile_combustion[typeind].fuel_type[
                                                                    fuelind
                                                                ].name,
                                                            };
                                                            local["DPA0144"] = items['Travelled Distance'];
                                                            local["DPA0339"] = {
                                                                name: items['Distance Measurement Unit'],
                                                            };
                                                            local["DPA0340"] = null;
                                                            local["attachment"] = null;
                                                            local["DP_MODE"] = true;
                                                            local["id"] = getID();
                                                            local2.push(local);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    });

                    setMCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });

        e.options.clear();
    };
    const updateMcVal = (obj, val) => {
        let local = mcVal;
        let vehcat = [],
            vehtype = [],
            fueltype = [];
        if (obj === "DPA0140") {
            mobile_combustion.forEach((item) => {
                if (item.vehicle_category === val.name) {
                    console.log(item);
                    if (
                        vehtype.findIndex((i) => {
                            return i.name === item.vehicle_type;
                        }) === -1
                    ) {
                        vehtype.push({ name: item.vehicle_type });
                    }
                    console.log(val);
                }
            });
            setVehTypeList(vehtype);
            local["DPA0139"] = null;
            local["DPA0141"] = null;
        }
        if (obj === "DPA0139") {
            mobile_combustion.forEach((item) => {
                if (
                    item.vehicle_category === mcVal.DPA0140.name &&
                    item.vehicle_type === val.name
                ) {
                    if (
                        fueltype.findIndex((i) => {
                            return i.name === item.fuel_type;
                        }) === -1
                    ) {
                        fueltype = item.fuel_type;
                    }
                }
            });
            console.log(fueltype);
            setVehFuelList(fueltype);

            local["DPA0141"] = null;
        }
        if (obj === "DPA0141") {
            local.DPA0340 = null;
        }
        if (obj === "DP_MODE") {
            local["DPA0140"] = null;
            local["DPA0141"] = null;
            local["DPA0139"] = null;
            local["DPA0339"] = null;
            local["DPA0144"] = "";
            local["DPA0143"] = "";
        }
        local[obj] = val;
        setMcVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setMcVal({
            DPA0290: moment(moment.utc()).toDate(),
            DP_MODE: true,
            DPA0341: "",
            DPA0140: null,
            DPA0139: null,
            DPA0339: null,
            DPA0141: null,
            DPA0340: null,
            DPA0144: "",
            DPA0143: "",
            DPA0142: null,
            attachment: null,
        });
        setMCDialog(true);
        setSubmitted(false);
    };
    const saveMobileCombustion = () => {
        setSubmitted(true);
        let local = mcVal;
        let local2 = mcdata;
        if (
            mcVal.DPA0341.trim().length !== 0 &&
            ((mcVal.DP_MODE && mcVal.DPA0140 !== null && mcVal.DPA0139 !== null) ||
                (!mcVal.DP_MODE && mcVal.DPA0141 !== null)) &&
            mcVal.DPA0141 !== null

        ) {
            if (
                mcVal.DP_MODE &&
                mcVal.DPA0339 !== null &&
                mcVal.DPA0144.trim().length !== 0 &&
                parseInt(mcVal.DPA0144) > 0 &&
                (mcVal.DPA0140.name.includes("HGV") ? mcVal.DPA0340 !== null : true)
            ) {
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }
                setMcVal({
                    DPA0290: moment(moment.utc()).toDate(),
                    DP_MODE: true,
                    DPA0341: "",
                    DPA0140: null,
                    DPA0139: null,
                    DPA0339: null,
                    DPA0141: null,
                    DPA0340: null,
                    DPA0144: "",
                    DPA0143: "",
                    DPA0142: null,
                    attachment: null,
                });
                setMCData(local2);
                forceUpdate();
                setMCDialog(false);
            } else if (
                !mcVal.DP_MODE &&
                mcVal.DPA0142 !== null &&
                mcVal.DPA0143.trim().length !== 0 &&
                parseInt(mcVal.DPA0143) > 0
            ) {
                local.DPA0139 = null;
                local.DPA0140 = null;
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }
                setMcVal({
                    DPA0290: moment(moment.utc()).toDate(),
                    DP_MODE: true,
                    DPA0341: "",
                    DPA0140: null,
                    DPA0139: null,
                    DPA0339: null,
                    DPA0141: null,
                    DPA0340: null,
                    DPA0144: "",
                    DPA0143: "",
                    DPA0142: null,
                    attachment: null,
                });
                setMCData(local2);
                forceUpdate();
                setMCDialog(false);
            }
        }
        console.log(local2);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setMCFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const mcDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveMobileCombustion();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        if (!items.DP_MODE) {
            items.DPA0142 = moment.utc(item.DPA0142).toDate();
        } else {
            items.DPA0142 = null;
        }
        let vehtype = [],
            fueltype = [];
        if (items.DP_MODE) {
            mobile_combustion.forEach((item_) => {
                if (item_.vehicle_category === items.DPA0140.name) {
                    console.log(item_);
                    if (
                        vehtype.findIndex((i) => {
                            return i.name === item_.vehicle_type;
                        }) === -1
                    ) {
                        vehtype.push({ name: item_.vehicle_type });
                    }
                }
            });
            setVehTypeList(vehtype);

            mobile_combustion.forEach((item_) => {
                if (
                    item_.vehicle_category === items.DPA0140.name &&
                    item_.vehicle_type === items.DPA0139.name
                ) {
                    if (
                        fueltype.findIndex((i) => {
                            return i.name === item_.fuel_type;
                        }) === -1
                    ) {
                        fueltype = item_.fuel_type;
                    }
                }
            });

            setVehFuelList(fueltype);
        }

        setMcVal(items);

        setMCDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = mcdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setMCData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const mcAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    return (
        <div>
            {edit === 11 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                'Data Captured By': null,
                                                "Fuel Refill Date": null,
                                                'Vehicle No': null,
                                                'Vehicle Category': null,
                                                'Vehicle Type': null,
                                                'Fuel Type': null,
                                                'Laden Percentage': null,
                                                'Distance Measurement Unit': null,
                                                'Fuel Refilled': null,
                                                "Travelled Distance": null,
                                            },
                                        ],
                                        "S1_OV",
                                        "Mobile Combustion Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Mobile Combustion Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={mcdata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={mcFilter}
                emptyMessage="No data entered yet"
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Data Captured by"
                    body={(rowData) => {
                        return <span>{rowData.DP_MODE ? "by Distance" : "by Fuel"}</span>;
                    }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0140.name"
                    header="Vehicle Category"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0139.name"
                    header="Vehicle Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0141.name"
                    header="Fuel type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={mcAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={mcDialog}
                style={{
                    width: "450px",
                }}
                header="Add Mobile Combustion"
                modal
                className="p-fluid"
                footer={mcDialogFooter}
                onHide={() => {
                    setMCDialog(false);
                }}
            >
                <div
                    className="flex flex-wrap gap-3"
                    style={{ justifyContent: "space-between", marginBottom: 10 }}
                >
                    <label>Data captured ?</label>
                    <div className="flex align-items-center">
                        <RadioButton
                            inputId="ingredient1"
                            name="pizza"
                            value={true}
                            onChange={(e) => updateMcVal("DP_MODE", e.value)}
                            checked={mcVal.DP_MODE === true}
                        />
                        <label htmlFor="ingredient1" className="ml-2">
                            by Distance
                        </label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton
                            inputId="ingredient2"
                            name="pizza"
                            value={false}
                            onChange={(e) => updateMcVal("DP_MODE", e.value)}
                            checked={mcVal.DP_MODE === false}
                        />
                        <label htmlFor="ingredient2" className="ml-2">
                            by Fuel
                        </label>
                    </div>
                </div>

                {!mcVal.DP_MODE ? (
                    <div className="field">
                        <label htmlFor="ecpsngr"> Fuel Type </label>
                        <Dropdown
                            value={mcVal.DPA0141}
                            onChange={(e) => {
                                updateMcVal("DPA0141", e.value);
                            }}
                            options={[
                                { name: "Diesel" },
                                { name: "Petrol" },
                                { name: "CNG" },
                            ]}
                            optionLabel="name"
                            placeholder="Select"
                        />
                        {submitted && mcVal.DPA0141 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Fuel Type
                            </small>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr"> Vehicle Category </label>
                            <Dropdown
                                value={mcVal.DPA0140}
                                onChange={(e) => {
                                    updateMcVal("DPA0140", e.value);
                                }}
                                options={vehcatlist}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPA0140 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Category
                                </small>
                            )}
                        </div>
                        {mcVal.DPA0140 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Vehicle Type </label>
                                <Dropdown
                                    value={mcVal.DPA0139}
                                    onChange={(e) => {
                                        updateMcVal("DPA0139", e.value);
                                    }}
                                    options={vehtypelist}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0139 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Vehicle Type
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPA0139 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Fuel Type </label>
                                <Dropdown
                                    value={mcVal.DPA0141}
                                    onChange={(e) => {
                                        updateMcVal("DPA0141", e.value);
                                    }}
                                    options={vehfuellist}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0141 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Fuel Type
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPA0141 !== null && mcVal.DPA0140.name.includes("HGV") && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> % of Laden </label>
                                <Dropdown
                                    value={mcVal.DPA0340}
                                    onChange={(e) => {
                                        updateMcVal("DPA0340", e.value);
                                    }}
                                    options={[
                                        { name: "0%" },
                                        { name: "50%" },
                                        { name: "100%" },
                                        { name: "Average" },
                                    ]}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0340 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select % of Laden
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                )}
                {mcVal.DPA0141 !== null &&
                    mcVal.DP_MODE &&
                    (mcVal.DPA0140.name.includes("HGV")
                        ? mcVal.DPA0340 !== null
                        : mcVal.DPA0141 !== null) && (
                        <div className="field">
                            <label htmlFor="ecpsngr"> Select Unit of Measurement </label>
                            <Dropdown
                                value={mcVal.DPA0339}
                                onChange={(e) => {
                                    updateMcVal("DPA0339", e.value);
                                }}
                                options={[{ name: "km" }, { name: "mi" }]}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPA0339 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Unit of distance measurement
                                </small>
                            )}
                        </div>
                    )}
                <div className="field">
                    <label htmlFor="vehno"> Vehicle Registration Number </label>
                    <InputText
                        value={mcVal.DPA0341}
                        onChange={(e) => {
                            updateMcVal("DPA0341", e.target.value);
                        }}
                        placeholder="Vehicle Number"
                    />
                    {submitted && mcVal.DPA0341.trim() === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Vehicle Number is required
                        </small>
                    )}
                </div>
                {mcVal.DPA0339 !== null && mcVal.DP_MODE !== null && mcVal.DP_MODE && (
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="gasrefill"> Total Distance Traveled </label>
                            <div id="gasrefill" className="p-inputgroup">
                                <InputText
                                    keyfilter={"pnum"}
                                    value={mcVal.DPA0144}
                                    onChange={(e) => {
                                        updateMcVal("DPA0144", e.target.value);
                                    }}
                                    placeholder="distance travelled"
                                />
                                <span className="p-inputgroup-addon">{mcVal.DPA0339.name}</span>
                            </div>
                            {submitted &&
                                (mcVal.DPA0144.trim() === "" ||
                                    parseInt(mcVal.DPA0144) <= 0) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Distance travelled is required
                                    </small>
                                )}
                        </div>
                    </div>
                )}
                {mcVal.DP_MODE !== null && !mcVal.DP_MODE && (
                    <div className="formgrid grid">
                        <div>
                            <div className="field">
                                <label htmlFor="refdate"> Date of Refill </label>
                                <Calendar
                                    minDate={dates.minDate} maxDate={dates.maxDate}
                                    dateFormat={'dd/mm/yy'}
                                    showIcon
                                    showMinMaxRange={true}
                                    showOtherMonths={false}
                                    viewDate={dates.minDate}
                                    value={mcVal.DPA0142}
                                    onChange={(e) => updateMcVal("DPA0142", e.value)}
                                ></Calendar>
                            </div>
                            {submitted && mcVal.DPA0142 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select date of fuel refilled
                                </small>
                            )}
                            <div className="field">
                                <label htmlFor="gasrefill">Total Fuel Filled </label>
                                <div id="gasrefill" className="p-inputgroup">
                                    <InputText
                                        keyfilter={"pnum"}
                                        value={mcVal.DPA0143}
                                        onChange={(e) => {
                                            updateMcVal("DPA0143", e.target.value);
                                        }}
                                        placeholder="Fuel Refilled"
                                    />
                                </div>
                                {submitted &&
                                    (mcVal.DPA0143.trim() === "" ||
                                        parseInt(mcVal.DPA0143) <= 0) && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Fuel is required
                                        </small>
                                    )}
                            </div>
                        </div>
                    </div>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(mcVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], mcVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = mcVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setMcVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(mcVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={10000000}
                    />

                </>
                {mcVal.attachment !== null && (
                    <>
                        {mcVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    mcVal.attachment.splice(index, 1);
                                                    if (mcVal.attachment.length === 0) {
                                                        mcVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Mobile_Combustion_ = ({ extractedData = () => { }, data, getData, setData, edit, standard, dates = { minDate: null, maxDate: null } }) => {
    const [mcdata, setMCData] = useState(data);
    const [mcDialog, setMCDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [load, setLoad] = useState(true)
    const [attachment, setAttachment] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [mcFilter, setMCFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [category, setCategory] = useState([]);
    const [category2, setCategory2] = useState([]);
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [unitlist, setUnitList] = useState([]);
    const [mcVal, setMcVal] = useState({
        DPA0290: moment(moment.utc()).toDate(),
        DPGMode: null,
        DP_MODE: true,
        DPA0341: "",
        DPA0140: null,
        DPA0139: null,
        DPA0339: null,
        DPA0141: null,

        DPA0144: 0,
        DPA0143: 0,
        DPA0142: null,
        attachment: null,
    });

    getData(mcdata);

    useEffect(() => {
        let veh_cat = [];
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 6);
                        let cat_index2 = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 7);
                        console.log(cat_index, cat_index2);
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setLoad(false)
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                setLoad(true)
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                        if (cat_index2 !== -1) {
                            let subcat2 = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index2].newEfSubcategory1s;
                            if (subcat2 !== undefined) {
                                setLoad(false)
                                setCategory2(subcat2);
                                console.log(subcat2);
                            } else {
                                setLoad(true)
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(mcdata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [mcdata, category]);
    let fuel_type_f = [
        { title: "Diesel", id: 13, key: "4-13-51" },
        { title: "Petrol", id: 19, key: "4-19-75" },
        { title: "CNG", id: 2, key: "1-2-7" },
    ];

    function extractData(data) {

        let result = [];
        let mappedData = category
            .flatMap(
                (i) =>
                    i.newEfSubcategory2s?.length &&
                    i.newEfSubcategory2s.flatMap(
                        (x) =>
                            x.newEfSubcategory3s?.length &&
                            x.newEfSubcategory3s?.flatMap((y) => y.newEfSubcategory4s && y.newEfSubcategory4s.map((z) => ({

                                title: y.title, key: i.id + '-' + x.id + '-' + y.id + '-' + z.id, title2: z.title, title3: x.title,
                            }))))).filter((i) => i);
        let mappedData2 = category2
            .flatMap(
                (i) =>
                    i.newEfSubcategory2s?.length &&
                    i.newEfSubcategory2s.flatMap(
                        (x) =>
                            x.newEfSubcategory3s?.length &&
                            x.newEfSubcategory3s?.flatMap((y) => y.newEfSubcategory4s && y.newEfSubcategory4s.map((z) => ({

                                title: y.title, key: i.id + '-' + x.id + '-' + y.id + '-' + z.id, title2: z.title,
                            }))))).filter((i) => i);
        for (const item of data) {
            let type = item.DP_MODE
            let key = type ? `${item.DPA0140}-${item.DPA0139}-${item.DPA0141}-${item.DPA0339}` : fuel_type_f.find(i => i.id === item.DPA0141).key
            let categoryItem = mappedData.find(x => x.key === key)
            result.push({
                title: type ? ("by Distance > " + categoryItem?.title3 + " > " + categoryItem?.title) : "by Fuel > " + fuel_type_f.find(i => i.id === item.DPA0141)?.title,
                label: type ? ("by Distance > " + categoryItem?.title3 + " > " + categoryItem?.title) : "by Fuel > " + fuel_type_f.find(i => i.id === item.DPA0141)?.title,
                value: type ? item.DPA0144 : item.DPA0143,
                subCategory1: type ? item.DPA0140 : Number(fuel_type_f.find(i => i.id === item.DPA0141)?.key?.split('-')[0]),
                subCategory2: type ? item.DPA0139 : Number(fuel_type_f.find(i => i.id === item.DPA0141)?.key?.split('-')[1]),
                subCategory3: type ? item.DPA0141 : Number(fuel_type_f.find(i => i.id === item.DPA0141)?.key?.split('-')[2]),
                subCategory4: item?.DPA0339 || null,
                currentId: type ? item.DPA0141 : Number(fuel_type_f.find(i => i.id === item.DPA0141)?.key?.split('-')[2]),
                parentId: type ? item.DPA0139 : item.DPA0141,
                formType: 2,
                dataType: 1,
                attachment: item.attachment,
                uom: type ? category?.title2 : "Litres",
                uniqueId: key,
                maskId: item.id,
                date: type ? null : item.DPA0142,
            });

        }

        console.log(result, "RESULT");
        return result;
    }
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Mobile Combustion Emissions") {
                    let local = {};
                    let local2 = [...mcdata];

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_OV") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                console.log(items);
                                if (
                                    items.fuel_type !== undefined &&
                                    items.fuel_type.toString().length !== 0 &&
                                    items.category !== undefined &&
                                    items.vehicle_no !== undefined &&
                                    items.vehicle_no.toString().trim().length !== 0 &&
                                    items.category.toString().trim().length !== 0 &&
                                    (items.category.toString().toLowerCase() === "fuel" ||
                                        items.category.toString().toLowerCase() === "distance")
                                ) {
                                    if (items.category.toString().toLowerCase() === "distance") {
                                        console.log(items);
                                        if (
                                            items.unit !== undefined &&
                                            items.vehicle_mode !== undefined &&
                                            (items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "passenger" ||
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "delivery") &&
                                            items.unit.toString().trim().length !== 0 &&
                                            items.km_travelled !== undefined &&
                                            parseFloat(items.km_travelled.toString()) > 0 &&
                                            items.vehicle_type !== undefined &&
                                            items.vehicle_type.toString().length !== 0 &&
                                            items.vehicle_category !== undefined &&
                                            items.vehicle_category.toString().length !== 0
                                        ) {
                                            if (
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "passenger"
                                            ) {
                                                const { result, id } = findMatchingIds(
                                                    category,
                                                    items.vehicle_category.toString(),
                                                    items.vehicle_type.toString(),
                                                    items.fuel_type.toString(),
                                                    items.unit.toString()
                                                );
                                                console.log(id);
                                                if (result) {
                                                    local["DPA0341"] = items.vehicle_no;
                                                    local["DPA0140"] = id[0];
                                                    local["DPA0139"] = id[1];
                                                    local["DPA0141"] = id[2];
                                                    local["DPA0144"] = parseFloat(
                                                        items.km_travelled.toString()
                                                    );
                                                    local["DPA0339"] = id[3];
                                                    local["DPGMode"] = 6;
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    console.log(local);
                                                    local2.push(local);
                                                }
                                            } else if (
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "delivery"
                                            ) {
                                                const { result, id } = findMatchingIds(
                                                    category2,
                                                    items.vehicle_category.toString(),
                                                    items.vehicle_type.toString(),
                                                    items.fuel_type.toString(),
                                                    items.unit.toString()
                                                );

                                                if (result) {
                                                    local["DPA0341"] = items.vehicle_no;
                                                    local["DPA0140"] = id[0];
                                                    local["DPA0139"] = id[1];
                                                    local["DPA0141"] = id[2];
                                                    local["DPA0144"] = parseFloat(
                                                        items.km_travelled.toString()
                                                    );
                                                    local["DPA0339"] = id[3];
                                                    local["DPGMode"] = 7;
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    local2.push(local);
                                                }
                                            }
                                        }
                                    } else if (
                                        items.category.toString().toLowerCase() === "fuel"
                                    ) {
                                        console.log(typeof items.fuel_refill_date);
                                        let fuel_type_f = [
                                            { title: "Diesel", id: 13 },
                                            { title: "Petrol", id: 19 },
                                            { title: "CNG", id: 2 },
                                        ];
                                        let findex = fuel_type_f.findIndex(
                                            (x) =>
                                                x.title.trim().toLowerCase() ===
                                                items.fuel_type.toString().trim().toLowerCase()
                                        );
                                        if (
                                            findex !== -1 &&
                                            items.fuel_refill_date !== undefined &&
                                            typeof items.fuel_refill_date === "number" &&
                                            items.fuel_refilled !== undefined &&
                                            parseFloat(items.fuel_refilled) > 0
                                        ) {
                                            local["attachment"] = null;
                                            local["DP_MODE"] = false;
                                            local["DPA0341"] = items.vehicle_no;
                                            local["DPA0140"] = null;
                                            local["DPA0139"] = null;
                                            local["DPA0141"] = fuel_type_f[findex].id;
                                            local["DPA0142"] = moment(
                                                excelDateToJSDate(items.fuel_refill_date)
                                            )._d;
                                            local["DPA0143"] = parseFloat(
                                                items.fuel_refilled.toString()
                                            );
                                            local["DPA0339"] = null;
                                            local["id"] = getID();
                                            local2.push(local);
                                        }
                                    }

                                    // if (items.vehicle_category !== null && items.vehicle_category.toString().trim().length !== 0) {
                                    //     let typeind = mobile_combustion.findIndex((i) => { return i.vehicle_category === items.vehicle_category && i.vehicle_type.trim().toLowerCase() === items.vehicle_type.trim().toString().toLowerCase() })
                                    //     if (items.vehicle_type !== null && items.vehicle_type.toString().trim().length !== 0 && typeind !== -1) {
                                    //         let fuelind = mobile_combustion[typeind].fuel_type.findIndex((i) => { return i.name.toString().trim().toLowerCase() === items.fuel_type.trim().toString().toLowerCase() })
                                    //         if (items.fuel_type !== null && items.fuel_type.toString().trim().length !== 0 && fuelind !== -1) {
                                    //             if (items.vehicle_category.toString().toLowerCase().includes('hgv')) {
                                    //                 if (items.laden_percentage !== null && (items.laden_percentage.toString() === '0%' || items.laden_percentage.toString() === '50%' || items.laden_percentage.toString() === '100%' || items.laden_percentage.toString() === 'Average')) {
                                    //                     if (items.category === 'fuel' && (items.fuel_refilled !== null && parseInt(items.fuel_refilled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                         local['attachment'] = null
                                    //                         local['DP_MODE'] = false
                                    //                         local['DPA0341'] = items.vehicle_no
                                    //                         local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                         local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                         local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                         local['DPA0142'] = moment(excelDateToJSDate(items.fuel_refill_date))._d
                                    //                         local['DPA0143'] = items.fuel_refilled
                                    //                         local['DPA0340'] = { name: items.laden_percentage }
                                    //                         local['id'] = getID()
                                    //                         local2.push(local)

                                    //                     } else if (items.category === 'distance' && (items.km_travelled !== null && parseInt(items.km_travelled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                         if (items.distance_measurement_unit !== null && (items.distance_measurement_unit.toString().trim() === 'km' || items.distance_measurement_unit.toString().trim() === 'mi')) {
                                    //                             local['DPA0341'] = items.vehicle_no
                                    //                             local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                             local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                             local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                             local['DPA0144'] = items.km_travelled.toString()
                                    //                             local['DPA0339'] = { name: items.distance_measurement_unit }
                                    //                             local['DPA0340'] = { name: items.laden_percentage }
                                    //                             local['attachment'] = null
                                    //                             local['DP_MODE'] = true
                                    //                             local['id'] = getID()
                                    //                             local2.push(local)
                                    //                         }
                                    //                     }
                                    //                 }

                                    //             } else {
                                    //                 if (items.category === 'fuel' && (items.fuel_refilled !== null && parseInt(items.fuel_refilled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                     local['DPA0341'] = items.vehicle_no
                                    //                     local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                     local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                     local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                     local['DPA0142'] = moment(excelDateToJSDate(items.fuel_refill_date))._d
                                    //                     local['DPA0143'] = items.fuel_refilled.toString()
                                    //                     local['DPA0340'] = null
                                    //                     local['attachment'] = null
                                    //                     local['DP_MODE'] = false
                                    //                     local['id'] = getID()
                                    //                     local2.push(local)

                                    //                 } else if (items.category === 'distance' && (items.km_travelled !== null && parseInt(items.km_travelled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                     if (items.distance_measurement_unit !== null && (items.distance_measurement_unit.toString().trim() === 'km' || items.distance_measurement_unit.toString().trim() === 'mi')) {
                                    //                         local['DPA0341'] = items.vehicle_no
                                    //                         local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                         local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                         local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                         local['DPA0144'] = items.km_travelled
                                    //                         local['DPA0339'] = { name: items.distance_measurement_unit }
                                    //                         local['DPA0340'] = null
                                    //                         local['attachment'] = null
                                    //                         local['DP_MODE'] = true
                                    //                         local['id'] = getID()
                                    //                         local2.push(local)

                                    //                     }
                                    //                 }
                                    //             }

                                    //         }

                                    //     }

                                    // }
                                }
                            });
                        }
                    });

                    setMCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });

        e.options.clear();
    };
    const updateMcVal = (obj, val) => {
        let local = mcVal;
        let vehcat = [],
            vehtype = [],
            fueltype = [];
        if (obj === "DPGMode") {
            local["DPA0140"] = null;
            local["DPA0139"] = null;
            local["DPA0141"] = null;
            local["DPA0339"] = null;

            if (val === 6) {
                setVehCatList(category);
            } else if (val === 7) {
                setVehCatList(category2);
            }
        }

        if (obj === "DPA0140") {
            vehcatlist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory2s !== undefined) {
                        vehtype = item.newEfSubcategory2s;
                    }
                }
            });
            setVehTypeList(vehtype);
            local["DPA0139"] = null;
            local["DPA0141"] = null;
            local["DPA0339"] = null;
        }
        if (obj === "DPA0139") {
            vehtypelist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory3s !== undefined) {
                        fueltype = item.newEfSubcategory3s;
                    }
                }
            });
            console.log(fueltype);

            setVehFuelList(fueltype);

            local["DPA0141"] = null;
            local["DPA0339"] = null;
        }
        if (obj === "DPA0141") {
            let units = [];
            vehfuellist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory4s !== undefined) {
                        units = item.newEfSubcategory4s;
                    }
                }
            });
            setUnitList(units);
            local["DPA0339"] = null;
        }
        if (obj === "DP_MODE") {
            local["DPGMode"] = null;
            local["DPA0140"] = null;
            local["DPA0141"] = null;
            local["DPA0139"] = null;
            local["DPA0339"] = null;
            local["DPA0144"] = 0;
            local["DPA0143"] = 0;
        }
        local[obj] = val;
        setMcVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setMcVal({
            DPGMode: null,
            DPA0290: moment(moment.utc()).toDate(),
            DP_MODE: true,
            DPA0341: "",
            DPA0140: null,
            DPA0139: null,
            DPA0339: null,
            DPA0141: null,

            DPA0144: 0,
            DPA0143: 0,
            DPA0142: null,
            attachment: null,
        });
        setMCDialog(true);
        setSubmitted(false);
    };
    const saveMobileCombustion = () => {
        setSubmitted(true);
        let local = mcVal;
        let local2 = [...mcdata];
        if (
            mcVal.DPA0341.trim().length !== 0 &&
            mcVal.DPA0141 !== null

        ) {
            if (
                mcVal.DP_MODE &&
                mcVal.DPA0339 !== null &&
                parseFloat(mcVal.DPA0144) >= 0 &&
                mcVal.DPA0140 &&
                mcVal.DPA0139
            ) {
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }
                setMcVal({
                    DPGMode: null,
                    DPA0290: moment(moment.utc()).toDate(),
                    DP_MODE: true,
                    DPA0341: "",
                    DPA0140: null,
                    DPA0139: null,
                    DPA0339: null,
                    DPA0141: null,

                    DPA0144: "",
                    DPA0143: "",
                    DPA0142: null,
                    attachment: null,
                });
                setMCData(local2);
                forceUpdate();
                setMCDialog(false);
            } else if (
                !mcVal.DP_MODE &&
                mcVal.DPA0142 !== null &&
                parseFloat(mcVal.DPA0143) > 0
            ) {
                local.DPA0139 = null;
                local.DPA0140 = null;
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }
                setMcVal({
                    DPGMode: null,
                    DPA0290: moment(moment.utc()).toDate(),
                    DP_MODE: true,
                    DPA0341: "",
                    DPA0140: null,
                    DPA0139: null,
                    DPA0339: null,
                    DPA0141: null,

                    DPA0144: 0,
                    DPA0143: 0,
                    DPA0142: null,
                    attachment: null,
                });
                setMCData(local2);
                forceUpdate();
                setMCDialog(false);
            }
        }
        console.log(mcdata);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setMCFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const mcDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveMobileCombustion();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        if (!items.DP_MODE) {
            items.DPA0142 = moment.utc(item.DPA0142).toDate();
        } else {
            items.DPA0142 = null;
        }
        items.DPA0144 = parseFloat(items.DPA0144);
        items.DPA0143 = parseFloat(items.DPA0143);
        console.log(items);
        let vehtype = [],
            fueltype = [];
        if (items.DP_MODE === true) {
            if (items.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === items.DPA0140);
                setVehCatList(category);
                if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
                    setVehTypeList(category[index].newEfSubcategory2s);
                    let index2 = category[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === items.DPA0139
                    );
                    console.log(index2);
                    if (
                        index2 !== -1 &&
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        setVehFuelList(
                            category[index].newEfSubcategory2s[index2].newEfSubcategory3s
                        );
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === items.DPA0141);
                        console.log(index3);

                        if (
                            index3 !== -1 &&
                            category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                index3
                            ].newEfSubcategory4s !== undefined
                        ) {
                            setUnitList(
                                category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                    index3
                                ].newEfSubcategory4s
                            );
                        }
                    }
                }
            } else if (items.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === items.DPA0140);
                setVehCatList(category);
                if (index !== -1 && category2[index].newEfSubcategory2s !== undefined) {
                    setVehTypeList(category2[index].newEfSubcategory2s);
                    let index2 = category2[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === items.DPA0139
                    );
                    if (
                        index2 !== -1 &&
                        category2[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        setVehFuelList(
                            category2[index].newEfSubcategory2s[index2].newEfSubcategory3s
                        );
                        let index3 = category2[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === items.DPA0141);
                        if (
                            index3 !== -1 &&
                            category2[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                index3
                            ].newEfSubcategory4s !== undefined
                        ) {
                            setUnitList(
                                category2[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                    index3
                                ].newEfSubcategory4s
                            );
                        }
                    }
                }
            }
        }

        setMcVal(items);
        console.log(vehcatlist, vehfuellist, unitlist);
        setMCDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...mcdata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setMCData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const mcAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const vehicleCatTemplate = (rowData) => {
        let text = "NA";
        if (rowData.DP_MODE) {
            console.log(rowData, category);
            if (rowData.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1) {
                    text = category[index].title;
                }
            } else if (rowData.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1) {
                    text = category2[index].title;
                }
            }
        }
        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleTypeTemplate = (rowData) => {
        let text = "NA";
        if (rowData.DP_MODE) {
            if (rowData.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
                    let index2 = category[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (index2 !== -1) {
                        text = category[index].newEfSubcategory2s[index2].title;
                    }
                }
            } else if (rowData.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category2[index].newEfSubcategory2s !== undefined) {
                    let index2 = category2[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (index2 !== -1) {
                        text = category2[index].newEfSubcategory2s[index2].title;
                    }
                }
            }
        }
        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const valueTemplate = (rowData) => {
        let text = "NA";
        if (rowData.DP_MODE) {
            if (rowData.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
                    let index2 = category[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (
                        index2 !== -1 &&
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPA0141);
                        if (index3 !== -1 && category[index].newEfSubcategory2s[index2].newEfSubcategory3s[index3]?.newEfSubcategory4s?.length) {
                            let index4 = category[index].newEfSubcategory2s[index2].newEfSubcategory3s[index3]?.newEfSubcategory4s.find(i => i.id === rowData.DPA0339)
                            if (index4) {
                                text = rowData?.DPA0144 + "/" + index4?.title
                            }

                        }
                    }
                }
            } else if (rowData.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category2[index].newEfSubcategory2s !== undefined) {
                    let index2 = category2[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (
                        index2 !== -1 &&
                        category2[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPA0141);
                        if (index3 !== -1 && category[index].newEfSubcategory2s[index2].newEfSubcategory3s[index3]?.newEfSubcategory4s?.length) {
                            let index4 = category[index].newEfSubcategory2s[index2].newEfSubcategory3s[index3]?.newEfSubcategory4s.find(i => i.id === rowData.DPA0339)
                            if (index4) {
                                text = rowData?.DPA0144 + "/" + index4?.title
                            }

                        }
                    }
                }
            }
        } else {
            let index = [
                { title: "Diesel", id: 13 },
                { title: "Petrol", id: 19 },
                { title: "CNG", id: 2 },
            ].findIndex((i) => i.id === rowData.DPA0141);
            if (index !== -1) {
                text = rowData?.DPA0143 + "/litres";
            }
        }

        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleFuelTemplate = (rowData) => {
        let text = "NA";
        if (rowData.DP_MODE) {
            if (rowData.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
                    let index2 = category[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (
                        index2 !== -1 &&
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPA0141);
                        if (index3 !== -1) {
                            text =
                                category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                    index3
                                ].title;
                        }
                    }
                }
            } else if (rowData.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category2[index].newEfSubcategory2s !== undefined) {
                    let index2 = category2[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (
                        index2 !== -1 &&
                        category2[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        let index3 = category2[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPA0141);
                        if (index3 !== -1) {
                            text =
                                category2[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                    index3
                                ].title;
                        }
                    }
                }
            }
        } else {
            let index = [
                { title: "Diesel", id: 13 },
                { title: "Petrol", id: 19 },
                { title: "CNG", id: 2 },
            ].findIndex((i) => i.id === rowData.DPA0141);
            if (index !== -1) {
                text = [
                    { title: "Diesel", id: 13 },
                    { title: "Petrol", id: 19 },
                    { title: "CNG", id: 2 },
                ][index].title;
            }
        }

        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        exportToCSV(
                                            [
                                                {
                                                    sno: null,
                                                    category: null,
                                                    fuel_refill_date: null,
                                                    vehicle_mode: null,
                                                    vehicle_no: null,
                                                    vehicle_category: null,
                                                    vehicle_type: null,
                                                    fuel_type: null,
                                                    unit: null,
                                                    fuel_refilled: null,
                                                    km_travelled: null,
                                                },
                                            ],
                                            "S1_OV",
                                            "Mobile Combustion Emissions"
                                        );
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Mobile Combustion Emissions");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={mcdata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                loading={load}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={mcFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Data Captured by"
                    body={(rowData) => {
                        return <span>{rowData.DP_MODE ? "by Distance" : "by Fuel"}</span>;
                    }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0140.name"
                    header="Vehicle Category"
                    body={vehicleCatTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0139.name"
                    header="Vehicle Type"
                    body={vehicleTypeTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0141.name"
                    header="Fuel type"
                    body={vehicleFuelTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0141.name"
                    header="Distance/Fuel"
                    body={valueTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={mcAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={mcDialog}
                style={{
                    width: "450px",
                }}
                header="Add Mobile Combustion"
                modal
                className="p-fluid"
                footer={mcDialogFooter}
                onHide={() => {
                    setMCDialog(false);
                }}
            >
                <div
                    className="flex flex-wrap gap-3"
                    style={{ justifyContent: "space-between", marginBottom: 10 }}
                >
                    <label>Data captured ?</label>
                    <div className="flex align-items-center">
                        <RadioButton
                            inputId="ingredient1"
                            name="pizza"
                            value={true}
                            onChange={(e) => updateMcVal("DP_MODE", e.value)}
                            checked={mcVal.DP_MODE === true}
                        />
                        <label htmlFor="ingredient1" className="ml-2">
                            by Distance
                        </label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton
                            inputId="ingredient2"
                            name="pizza"
                            value={false}
                            onChange={(e) => updateMcVal("DP_MODE", e.value)}
                            checked={mcVal.DP_MODE === false}
                        />
                        <label htmlFor="ingredient2" className="ml-2">
                            by Fuel
                        </label>
                    </div>
                </div>

                {!mcVal.DP_MODE ? (
                    <div className="field">
                        <label htmlFor="ecpsngr"> Fuel Type </label>
                        <Dropdown
                            value={mcVal.DPA0141}
                            onChange={(e) => {
                                updateMcVal("DPA0141", e.value);
                            }}
                            options={[
                                { title: "Diesel", id: 13 },
                                { title: "Petrol", id: 19 },
                                { title: "CNG", id: 2 },
                            ]}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && mcVal.DPA0141 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Fuel Type
                            </small>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="field">
                            <label> Vehicle Mode </label>
                            <Dropdown
                                value={mcVal.DPGMode}
                                onChange={(e) => {
                                    updateMcVal("DPGMode", e.value);
                                }}
                                options={[
                                    { name: "Passenger", id: 6 },
                                    { name: "Delivery", id: 7 },
                                ]}
                                optionLabel="name"
                                optionValue="id"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPGMode === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Category
                                </small>
                            )}
                        </div>
                        {mcVal.DPGMode !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Vehicle Category </label>
                                <Dropdown
                                    value={mcVal.DPA0140}
                                    onChange={(e) => {
                                        updateMcVal("DPA0140", e.value);
                                    }}
                                    options={vehcatlist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0140 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Vehicle Category
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPA0140 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Vehicle Type </label>
                                <Dropdown
                                    value={mcVal.DPA0139}
                                    onChange={(e) => {
                                        updateMcVal("DPA0139", e.value);
                                    }}
                                    options={vehtypelist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0139 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Vehicle Type
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPA0139 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Fuel Type </label>
                                <Dropdown
                                    value={mcVal.DPA0141}
                                    onChange={(e) => {
                                        updateMcVal("DPA0141", e.value);
                                    }}
                                    options={vehfuellist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0141 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Fuel Type
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                )}
                {mcVal.DPA0141 !== null &&
                    mcVal.DPA0140 !== null &&
                    mcVal.DPA0139 !== null && (
                        <div className="field">
                            <label htmlFor="ecpsngr"> Select Unit of Measurement </label>
                            <Dropdown
                                value={mcVal.DPA0339}
                                onChange={(e) => {
                                    updateMcVal("DPA0339", e.value);
                                }}
                                options={unitlist}
                                optionLabel="title"
                                optionValue="id"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPA0339 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Unit of distance measurement
                                </small>
                            )}
                        </div>
                    )}
                <div className="field">
                    <label htmlFor="vehno"> Vehicle Registration Number </label>
                    <InputText
                        value={mcVal.DPA0341}
                        onChange={(e) => {
                            updateMcVal("DPA0341", e.target.value);
                        }}
                        placeholder="Vehicle Number"
                    />
                    {submitted && mcVal.DPA0341.trim() === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Vehicle Number is required
                        </small>
                    )}
                </div>
                {mcVal.DPA0339 !== null && mcVal.DP_MODE !== null && mcVal.DP_MODE && (
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="gasrefill">

                                Total Distance Traveled ( in km )
                            </label>
                            <div id="gasrefill" className="p-inputgroup">
                                <InputNumber
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    value={mcVal.DPA0144}
                                    onChange={(e) => {
                                        updateMcVal("DPA0144", e.value);
                                    }}
                                    placeholder="distance travelled"
                                />
                                {/* <span className="p-inputgroup-addon">{mcVal.DPA0339.name}</span> */}
                            </div>
                            {submitted && parseInt(mcVal.DPA0144) <= 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Distance travelled is required
                                </small>
                            )}
                        </div>
                    </div>
                )}
                {mcVal.DP_MODE !== null && !mcVal.DP_MODE && (
                    <div className="formgrid grid">
                        <div>
                            <div className="field">
                                <label htmlFor="refdate"> Date of Refill </label>
                                <Calendar
                                    minDate={dates.minDate} maxDate={dates.maxDate}
                                    dateFormat={'dd/mm/yy'}
                                    showIcon
                                    showMinMaxRange={true}
                                    showOtherMonths={false}
                                    viewDate={dates.minDate}
                                    value={mcVal.DPA0142}
                                    onChange={(e) => updateMcVal("DPA0142", e.value)}
                                ></Calendar>
                            </div>
                            {submitted && mcVal.DPA0142 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select date of fuel refilled
                                </small>
                            )}
                            <div className="field">
                                <label htmlFor="gasrefill">Total Fuel Filled (in litre) </label>
                                <div id="gasrefill" className="p-inputgroup">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={mcVal.DPA0143}
                                        onChange={(e) => {
                                            updateMcVal("DPA0143", e.value);
                                        }}
                                        placeholder="Fuel Refilled"
                                    />
                                </div>
                                {submitted && parseInt(mcVal.DPA0143) <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Fuel is required
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(mcVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], mcVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = mcVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setMcVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(mcVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={10000000}
                    />

                </>
                {mcVal.attachment !== null && (
                    <>
                        {mcVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    mcVal.attachment.splice(index, 1);
                                                    if (mcVal.attachment.length === 0) {
                                                        mcVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};

//Purchase Goods & Services
const Purchase_Goods_Services = ({ data, getData, setData, edit, dates }) => {
    const [pgdata, setPGData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [pgdata]);
    getData(pgdata);
    const [priceconvert, setPriceConvert] = useState({
        amount: 0,
        from: null,
        to: "USD",
    });
    const [currencysugesstion, setCurrencySugesstion] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [pgFilter, setPgFilter] = useState(null);
    const [conversiondialog, setConversionDialog] = useState(false);
    const [pgDialog, setPgDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [pgVal, setPgVal] = useState({
        DPA0285: moment(moment.utc()).toDate(),
        DPA0286: "",
        DPA0288: "",
        DPA0289: "",
        DPA0287: null,
        attachment: null,
    });
    const updatePgVal = (obj, val) => {
        let local = pgVal;
        local[obj] = val;
        console.log(val);
        setPgVal(local);

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Purchased_goods_and_services") {
                    let local = {};
                    let local2 = pgdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_PG") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items.date !== null &&
                                    typeof items.date === "number" &&
                                    items.item_name !== null &&
                                    items.item_name.toString().trim().length !== 0 &&
                                    items.qty_purchased !== null &&
                                    parseInt(items.qty_purchased.toString()) > 0 &&
                                    items.price_per_item !== null &&
                                    parseInt(items.price_per_item.toString()) > 0 &&
                                    items.currency_code !== null &&
                                    (currency_codes.findIndex((i) => {
                                        return (
                                            i.name.split("-")[0].toLowerCase() ===
                                            items.currency_code.toString().toLowerCase()
                                        );
                                    }) !== -1 ||
                                        items.currency_code.toString().toLowerCase() === "usd") &&
                                    items.item_class !== null &&
                                    purchase_gs.findIndex((i) => {
                                        return (
                                            i.name.toLowerCase() ===
                                            items.item_class.toString().toLowerCase()
                                        );
                                    }) !== -1
                                ) {
                                    console.log(typeof items.date === "number");
                                    if (items.currency_code.toString().toLowerCase() === "usd") {
                                        local["DPA0286"] = items.item_name;
                                        local["DPA0287"] = purchase_gs.filter((i) => {
                                            return (
                                                i.name.toLowerCase() ===
                                                items.item_class.toString().toLowerCase()
                                            );
                                        })[0];
                                        local["DPA0285"] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["DPA0288"] = items.qty_purchased.toString();
                                        local["attachment"] = null;
                                        local["DPA0289"] = items.price_per_item.toString();
                                        local2.push(local);
                                    } else {
                                        console.log(items.qty_purchased);
                                        let obj = {
                                            from: items.currency_code,
                                            to: "USD",
                                            amount: parseInt(items.price_per_item.toString()),
                                            date: moment(excelDateToJSDate(items.date)).format(
                                                "YYYY-MM-DD"
                                            ),
                                        };
                                        const options = {
                                            method: "GET",
                                            url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                                            params: obj,
                                            headers: {
                                                "X-RapidAPI-Key":
                                                    "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                                                "X-RapidAPI-Host":
                                                    "currency-conversion-and-exchange-rates.p.rapidapi.com",
                                            },
                                        };
                                        Axios.request(options)
                                            .then(function (response) {
                                                local["DPA0286"] = items.item_name;
                                                local["DPA0287"] = purchase_gs.filter((i) => {
                                                    return (
                                                        i.name.toLowerCase() ===
                                                        items.item_class.toString().toLowerCase()
                                                    );
                                                })[0];
                                                local["DPA0285"] = moment(
                                                    excelDateToJSDate(items.date)
                                                )._d;
                                                local["id"] = getID();
                                                local["DPA0288"] = items.qty_purchased.toString();
                                                local["attachment"] = null;
                                                local["DPA0289"] = response.data.result
                                                    .toFixed(2)
                                                    .toString();

                                                local2.push(local);

                                                setPGData(local2);
                                                forceUpdate();
                                            })
                                            .catch(function (error) {
                                                console.error(error);
                                            });
                                    }
                                }
                            });
                        }
                    });

                    setPGData(local2);
                    forceUpdate();
                    console.log(local2);
                }
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const pgheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setPgVal({
                                DPA0285: moment(moment.utc()).toDate(),
                                DPA0286: "",
                                DPA0288: "",
                                DPA0289: "",
                                DPA0287: null,
                                attachment: null,
                            });

                            setSubmitted(false);
                            setPgDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setPgFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const editPgVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPA0285 = moment.utc(item.DPA0285).toDate();

        setPgVal(items);
        console.log(items);
        setPgDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = pgdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setPGData(loc);
        forceUpdate();
    };
    const savePurchaseGoodsServices = () => {
        setSubmitted(true);

        if (
            pgVal.DPA0286.trim().length !== 0 &&
            pgVal.DPA0285 !== null &&
            parseInt(pgVal.DPA0289) > 0 &&
            parseInt(pgVal.DPA0288) > 0 &&
            pgVal.DPA0287 !== null

        ) {
            if (pgVal.id === undefined) {
                pgVal["id"] = getID();
                setPGData((prev) => [pgVal, ...prev]);
            } else {
                let loc = pgdata;
                let index = loc.findIndex((k) => {
                    return k.id === pgVal.id;
                });
                loc[index] = pgVal;
                setPGData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setPgDialog(false);
        }

        forceUpdate();
    };
    const convertCurrency = () => {
        if (
            priceconvert.from !== null &&
            parseInt(priceconvert.amount) > 0 &&
            pgVal.DPA0285 !== null
        ) {
            let obj = {
                from: priceconvert.from.name.split("-")[0],
                to: "USD",
                amount: parseInt(priceconvert.amount),
                date: moment(pgVal.DPA0285).format("YYYY-MM-DD"),
            };
            const options = {
                method: "GET",
                url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                params: obj,
                headers: {
                    "X-RapidAPI-Key": "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                    "X-RapidAPI-Host":
                        "currency-conversion-and-exchange-rates.p.rapidapi.com",
                },
            };
            Axios.request(options)
                .then(function (response) {
                    let loc = pgVal;
                    loc.DPA0289 = response.data.result.toFixed(2).toString();
                    setPgVal(loc);
                    forceUpdate();
                    setConversionDialog(false);
                    setPriceConvert({ from: "", to: "USD", amount: 0 });
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    };
    const pgDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={savePurchaseGoodsServices}
            />
        </>
    );
    const pgAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplatePG = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editPgVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...currency_codes];
            } else {
                _filteredCountries = currency_codes.filter((country) => {
                    console.log(country);
                    return country.name
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }

            setCurrencySugesstion(_filteredCountries);
        }, 100);
    };
    return (
        <div>
            {edit === 11 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                date: "",
                                                item_name: "",
                                                item_class: "",
                                                qty_purchased: "",
                                                price_per_item: "",
                                                currency_code: "",
                                            },
                                        ],
                                        "S3_PG",
                                        "Purchased_goods_and_services"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Purchased_goods_and_services");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={pgdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={pgFilter}
                emptyMessage="No data entered yet"
                header={edit === 1 && pgheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0285"
                    header="Date of Purchase"
                    body={dateFormatting_pg}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0286"
                    header="Item Name"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0287.name"
                    header="Item Classification"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0288"
                    header="Quantity Purchased"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0289"
                    header="Cost per Item "
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={pgAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplatePG}> </Column>}
            </DataTable>
            <Dialog
                visible={pgDialog}
                style={{
                    width: "450px",
                }}
                header="Add Purchase Item"
                modal
                className="p-fluid"
                footer={pgDialogFooter}
                onHide={() => {
                    setPgDialog(false);
                }}
            >
                <div>
                    <div className="field">
                        <label htmlFor="refdate"> Date of purchase </label>
                        <Calendar
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            value={pgVal.DPA0285}
                            onChange={(e) => updatePgVal("DPA0285", e.value)}
                        ></Calendar>
                    </div>

                    {pgVal.DPA0285 !== null && (
                        <>
                            <div className="field">
                                <label htmlFor="DPA0286">Name of the Item Purchased</label>
                                <InputText
                                    value={pgVal.DPA0286}
                                    onChange={(e) => {
                                        updatePgVal("DPA0286", e.target.value);
                                    }}
                                />
                                {submitted && pgVal.DPA0286.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Item name is required
                                    </small>
                                )}
                            </div>
                            <div className="field">
                                <label htmlFor="DPA0287">
                                    Classification of Item Purcahsed
                                </label>
                                <Dropdown
                                    value={pgVal.DPA0287}
                                    onChange={(e) => {
                                        updatePgVal("DPA0287", e.value);
                                    }}
                                    options={purchase_gs}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                                {submitted && pgVal.DPA0287 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Classification is required
                                    </small>
                                )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Quantity Purchased </label>

                                <InputText
                                    keyfilter={"num"}
                                    value={pgVal.DPA0288}
                                    onChange={(e) => {
                                        updatePgVal("DPA0288", e.target.value);
                                    }}
                                    placeholder="Quantity purchased"
                                />

                                {submitted &&
                                    (pgVal.DPA0288.trim() === "" ||
                                        parseInt(pgVal.DPA0288) <= 0) && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Quantity is required
                                        </small>
                                    )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Price per item ( in USD ) </label>

                                <InputText
                                    keyfilter={"num"}
                                    value={pgVal.DPA0289}
                                    onChange={(e) => {
                                        updatePgVal("DPA0289", e.target.value);
                                    }}
                                    placeholder="Price per item in USD"
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "green",
                                            textDecoration: "underline",
                                        }}
                                        onClick={() => {
                                            setConversionDialog(true);
                                        }}
                                    >
                                        convert to USD
                                    </span>
                                </div>

                                {submitted && parseInt(pgVal.DPA0289) < 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        price is required
                                    </small>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(pgVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], pgVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = pgVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setPgVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(pgVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {pgVal.attachment !== null && (
                    <>
                        {pgVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    pgVal.attachment.splice(index, 1);
                                                    if (pgVal.attachment.length === 0) {
                                                        pgVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
            <Dialog
                visible={conversiondialog}
                style={{
                    width: "50%",
                }}
                header="Currency conversion"
                modal
                className="p-fluid"
                onHide={() => {
                    setConversionDialog(false);
                }}
            >
                <div>
                    <div className="field ">
                        <label htmlFor="gasrefill"> Enter price for conversion </label>

                        <InputText
                            keyfilter={"num"}
                            value={priceconvert.amount}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.amount = e.target.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                            placeholder="Price per item in USD"
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="gasrefill">select your currency code</label>
                        <AutoComplete
                            field="name"
                            forceSelection
                            value={priceconvert.from}
                            suggestions={currencysugesstion}
                            completeMethod={search}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.from = e.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                        />
                    </div>
                    <Button
                        label="Convert & Save"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={convertCurrency}
                    />
                </div>
            </Dialog>
        </div>
    );
};
const Purchase_Goods_Services_ = ({
    standard,
    data, allowedCategories = [],
    getData,
    setData,
    edit, dates = { minDate: null, maxDate: null }
}) => {
    const [pgdata, setPGData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [pgdata]);
    getData(pgdata);
    const [priceconvert, setPriceConvert] = useState({
        amount: 0,
        from: null,
        to: "USD",
    });
    const [currencysugesstion, setCurrencySugesstion] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [pgFilter, setPgFilter] = useState(null);
    const [conversiondialog, setConversionDialog] = useState(false);
    const [pgDialog, setPgDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [category, setCategory] = useState([]);
    const [pgVal, setPgVal] = useState({
        DPA0285: moment(moment.utc()).toDate(),
        DPA0286: "",
        DPA0288: 0,
        DPA0289: 0,
        DPA0287: null,
        attachment: null,
    });
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };

        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 11);

                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    const updatePgVal = (obj, val) => {
        let local = pgVal;
        local[obj] = val;
        console.log(val);
        setPgVal(local);

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Purchased_goods_and_services") {
                    let local = {};
                    let local2 = pgdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_PG") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                let index = category.findIndex((i) => {
                                    return (
                                        i.title.trim().toLowerCase() ===
                                        items.item_class.toString().trim().toLowerCase()
                                    );
                                });
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.item_name !== undefined &&
                                    items.item_name.toString().trim().length !== 0 &&
                                    items.qty_purchased !== undefined &&
                                    parseFloat(items.qty_purchased.toString()) > 0 &&
                                    items.price_per_item !== undefined &&
                                    parseFloat(items.price_per_item.toString()) > 0 &&
                                    items.currency_code !== undefined &&
                                    (currency_codes.findIndex((i) => {
                                        return (
                                            i.name.split("-")[0].toLowerCase() ===
                                            items.currency_code.toString().toLowerCase()
                                        );
                                    }) !== -1 ||
                                        items.currency_code.toString().toLowerCase() === "usd") &&
                                    items.item_class !== undefined &&
                                    index !== -1
                                ) {
                                    console.log(typeof items.date === "number");
                                    if (items.currency_code.toString().toLowerCase() === "usd") {
                                        local["DPA0286"] = items.item_name;
                                        local["DPA0287"] = category[index].id;
                                        local["DPA0285"] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["DPA0288"] = parseFloat(
                                            items.qty_purchased.toString()
                                        );
                                        local["attachment"] = null;
                                        local["DPA0289"] = parseFloat(
                                            items.price_per_item.toString()
                                        );
                                        local2.push(local);
                                    } else {
                                        let obj = {
                                            from: items.currency_code,
                                            to: "USD",
                                            amount: parseInt(items.price_per_item.toString()),
                                            date: moment(excelDateToJSDate(items.date)).format(
                                                "YYYY-MM-DD"
                                            ),
                                        };
                                        const options = {
                                            method: "GET",
                                            url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                                            params: obj,
                                            headers: {
                                                "X-RapidAPI-Key":
                                                    "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                                                "X-RapidAPI-Host":
                                                    "currency-conversion-and-exchange-rates.p.rapidapi.com",
                                            },
                                        };
                                        Axios.request(options)
                                            .then(function (response) {
                                                local["DPA0286"] = items.item_name;
                                                local["DPA0287"] = category[index].id;
                                                local["DPA0285"] = moment(
                                                    excelDateToJSDate(items.date)
                                                )._d;
                                                local["id"] = getID();
                                                local["DPA0288"] = items.qty_purchased.toString();
                                                local["attachment"] = null;
                                                local["DPA0289"] = response.data.result
                                                    .toFixed(2)
                                                    .toString();

                                                local2.push(local);

                                                setPGData(local2);
                                                forceUpdate();
                                            })
                                            .catch(function (error) {
                                                console.error(error);
                                            });
                                    }
                                }
                            });
                        }
                    });

                    setPGData(local2);
                    forceUpdate();
                    console.log(local2);
                }
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const pgheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setPgVal({
                                DPA0285: moment(moment.utc()).toDate(),
                                DPA0286: "",
                                DPA0288: "",
                                DPA0289: "",
                                DPA0287: null,
                                attachment: null,
                            });

                            setSubmitted(false);
                            setPgDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setPgFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const editPgVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPA0285 = moment.utc(item.DPA0285).toDate();
        items.DPA0288 = parseFloat(items.DPA0288);
        items.DPA0289 = parseFloat(items.DPA0289);
        setPgVal(items);
        console.log(items);
        setPgDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = pgdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setPGData(loc);
        forceUpdate();
    };
    const savePurchaseGoodsServices = () => {
        setSubmitted(true);
        console.log(pgVal);
        if (
            pgVal.DPA0286.trim().length !== 0 &&
            pgVal.DPA0285 !== null &&
            parseFloat(pgVal.DPA0289) > 0 &&
            parseFloat(pgVal.DPA0288) > 0 &&
            pgVal.DPA0287 !== null

        ) {
            if (pgVal.id === undefined) {
                pgVal["id"] = getID();
                setPGData((prev) => [pgVal, ...prev]);
            } else {
                let loc = pgdata;
                let index = loc.findIndex((k) => {
                    return k.id === pgVal.id;
                });
                loc[index] = pgVal;
                setPGData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setPgDialog(false);
        }

        forceUpdate();
    };
    const convertCurrency = () => {
        if (
            priceconvert.from !== null &&
            parseInt(priceconvert.amount) > 0 &&
            pgVal.DPA0285 !== null
        ) {
            let obj = {
                from: priceconvert.from.name.split("-")[0],
                to: "USD",
                amount: parseInt(priceconvert.amount),
                date: moment(pgVal.DPA0285).format("YYYY-MM-DD"),
            };
            const options = {
                method: "GET",
                url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                params: obj,
                headers: {
                    "X-RapidAPI-Key": "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                    "X-RapidAPI-Host":
                        "currency-conversion-and-exchange-rates.p.rapidapi.com",
                },
            };
            Axios.request(options)
                .then(function (response) {
                    let loc = pgVal;
                    loc.DPA0289 = response.data.result.toFixed(2).toString();
                    setPgVal(loc);
                    forceUpdate();
                    setConversionDialog(false);
                    setPriceConvert({ from: "", to: "USD", amount: 0 });
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    };
    const pgDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={savePurchaseGoodsServices}
            />
        </>
    );
    const pgAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplatePG = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editPgVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const itemClassificationTemplate = (rowData) => {
        let index = category.findIndex((i) => i.id === rowData.DPA0287);
        let text = "Not Found";
        if (index !== -1) {
            text = category[index].title;
        }
        return <>{text}</>;
    };
    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...currency_codes];
            } else {
                _filteredCountries = currency_codes.filter((country) => {
                    console.log(country);
                    return country.name
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }

            setCurrencySugesstion(_filteredCountries);
        }, 100);
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        exportToCSV(
                                            [
                                                {
                                                    sno: null,
                                                    date: "",
                                                    item_name: "",
                                                    item_class: "",
                                                    qty_purchased: "",
                                                    price_per_item: "",
                                                    currency_code: "",
                                                },
                                            ],
                                            "S3_PG",
                                            "Purchased_goods_and_services"
                                        );
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Purchased_goods_and_services");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={() => {
                                setPgVal({
                                    DPA0285: moment(moment.utc()).toDate(),
                                    DPA0286: "",
                                    DPA0288: "",
                                    DPA0289: "",
                                    DPA0287: null,
                                    attachment: null,
                                });

                                setSubmitted(false);
                                setPgDialog(true);
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={pgdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={pgFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && pgheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0285"
                    header="Date of Purchase"
                    body={dateFormatting_pg}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0286"
                    header="Item Name"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0287.name"
                    header="Item Classification"
                    body={itemClassificationTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0288"
                    header="Quantity Purchased"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0289"
                    header="Cost per Item "
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={pgAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplatePG}> </Column>}
            </DataTable>
            <Dialog
                visible={pgDialog}
                style={{
                    width: "450px",
                }}
                header="Add Purchase Item"
                modal
                className="p-fluid"
                footer={pgDialogFooter}
                onHide={() => {
                    setPgDialog(false);
                }}
            >
                <div>
                    <div className="field">
                        <label htmlFor="refdate"> Date of purchase </label>
                        <Calendar
                            minDate={dates.minDate} maxDate={dates.maxDate}
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            value={pgVal.DPA0285}
                            onChange={(e) => updatePgVal("DPA0285", e.value)}
                            showMinMaxRange={true}
                            showOtherMonths={false}
                            viewDate={dates.minDate}
                        ></Calendar>
                    </div>

                    {pgVal.DPA0285 !== null && (
                        <>
                            <div className="field">
                                <label htmlFor="DPA0286">Name of the Item Purchased</label>
                                <InputText
                                    value={pgVal.DPA0286}
                                    onChange={(e) => {
                                        updatePgVal("DPA0286", e.target.value);
                                    }}
                                />
                                {submitted && pgVal.DPA0286.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Item name is required
                                    </small>
                                )}
                            </div>
                            <div className="field">
                                <label htmlFor="DPA0287">
                                    Classification of Item Purcahsed
                                </label>
                                <Dropdown
                                    value={pgVal.DPA0287}
                                    onChange={(e) => {
                                        updatePgVal("DPA0287", e.value);
                                    }}
                                    options={category.filter(i => { return allowedCategories.includes('1-' + i.id) || allowedCategories.length === 0 })}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && pgVal.DPA0287 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Classification is required
                                    </small>
                                )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Quantity Purchased </label>

                                <InputNumber
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    value={pgVal.DPA0288}
                                    onChange={(e) => {
                                        updatePgVal("DPA0288", e.value);
                                    }}
                                    placeholder="Quantity purchased"
                                />

                                {submitted && parseInt(pgVal.DPA0288) <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Quantity is required
                                    </small>
                                )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Price per item ( in USD ) </label>

                                <InputNumber
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    value={pgVal.DPA0289}
                                    onChange={(e) => {
                                        updatePgVal("DPA0289", e.value);
                                    }}
                                    placeholder="Price per item in USD"
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "green",
                                            textDecoration: "underline",
                                        }}
                                        onClick={() => {
                                            setConversionDialog(true);
                                        }}
                                    >
                                        convert to USD
                                    </span>
                                </div>

                                {submitted && parseInt(pgVal.DPA0289) < 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        price is required
                                    </small>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(pgVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], pgVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = pgVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setPgVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(pgVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {pgVal.attachment !== null && (
                    <>
                        {pgVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    pgVal.attachment.splice(index, 1);
                                                    if (pgVal.attachment.length === 0) {
                                                        pgVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
            <Dialog
                visible={conversiondialog}
                style={{
                    width: "50%",
                }}
                header="Currency conversion"
                modal
                className="p-fluid"
                onHide={() => {
                    setConversionDialog(false);
                }}
            >
                <div>
                    <div className="field ">
                        <label htmlFor="gasrefill"> Enter price for conversion </label>

                        <InputText
                            keyfilter={"num"}
                            value={priceconvert.amount}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.amount = e.target.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                            placeholder="Price per item in USD"
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="gasrefill">select your currency code</label>
                        <AutoComplete
                            field="name"
                            forceSelection
                            value={priceconvert.from}
                            suggestions={currencysugesstion}
                            completeMethod={search}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.from = e.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                        />
                    </div>
                    <Button
                        label="Convert & Save"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={convertCurrency}
                    />
                </div>
            </Dialog>
        </div>
    );
};
const BP8EQ2 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const QLBP7L1D4Options = [
        { name: "Annually", id: 1 },
        { name: "Half yearly", id: 2 },
        { name: "Quarterly", id: 3 },
        { name: "Others", id: 4 },
    ];
    getData(badata);
    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPS0184: "",
        DPS0185: "",
        DPS0186: "",
        DPS0187: 0,
        DPK0183: 0,
        DPS0190: 0,
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPS0184: "",
            DPS0185: "",
            DPS0186: "",
            DPS0187: 0,
            DPK0183: 0,
            DPS0190: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPS0184.trim().length !== 0 &&
            dataVal.DPS0185.trim().length !== 0 &&
            dataVal.DPS0186.trim().length !== 0 &&
            dataVal.DPS0187 >= 0 &&
            dataVal.DPK0183 >= 0 &&
            dataVal.DPS0190 >= 0
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPS0184: "",
                DPS0185: "",
                DPS0186: "",
                DPS0187: 0,
                DPK0183: 0,
                DPS0190: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {

                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>

                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    console.log(e);
                                }}
                            />
                        </div> */}

                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPS0184", "DPS0185", "DPS0186"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Name of Project"
                    field="DPS0184"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="State"
                    field="DPS0185"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="District"
                    field="DPS0186"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="No of PAFs"
                    field="DPS0187"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="% of PAFs covered by R&R"
                    field="DPK0183"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPS0190"
                    header="Amounts paid to PAFs in the FY (In INR)"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Name of Project for which Rehabilitation and Resettlement (R&R) is
                        ongoing
                    </label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPS0184}
                        onChange={(e) => {
                            updateDataVal("DPS0184", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPS0184.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter project name
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Name of the state</label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPS0185}
                        onChange={(e) => {
                            updateDataVal("DPS0185", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPS0185.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter state name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Name of the district</label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPS0186}
                        onChange={(e) => {
                            updateDataVal("DPS0186", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPS0186.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter district name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">
                        No. of Project Affected Families (PAFs)
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPS0187}
                        onChange={(e) => {
                            updateDataVal("DPS0187", e.value);
                        }}
                    />
                    {submitted && dataVal.DPS0187 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            No. of Project Affected Families (PAFs) should be greater / equal
                            than zero
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">% of PAFs covered by R&R</label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPK0183}
                        onChange={(e) => {
                            updateDataVal("DPK0183", e.value);
                        }}
                    />
                    {submitted && dataVal.DPK0183 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            % of PAFs covered by R&R is required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Amounts paid to PAFs in the FY (In INR)
                    </label>

                    <InputNumber
                        maxFractionDigits={2}
                        value={dataVal.DPS0190}
                        onChange={(e) => {
                            updateDataVal("DPS0190", e.value);
                        }}
                        locale="en-IN"
                        minFractionDigits={2}
                    />

                    {submitted && dataVal.DPS0190 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            amount paid should not be lesser than
                        </small>
                    )}
                </div>
            </Dialog>
        </div>
    );
};
const BP2LQ3 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPBP2LQ3D1: "",
        DPBP2LQ3D2: 0,
        DPBP2LQ3D3: 0,
    });
    getData(badata);
    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPBP2LQ3D1: "",
            DPBP2LQ3D2: 0,
            DPBP2LQ3D3: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPBP2LQ3D1.trim().length !== 0 &&
            dataVal.DPBP2LQ3D2 >= 0 &&
            dataVal.DPBP2LQ3D3 >= 0
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPBP2LQ3D1: "",
                DPBP2LQ3D2: 0,
                DPBP2LQ3D3: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}


                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ3D1"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Indicate Input Material"
                    field="DPBP2LQ3D1"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total Quantity of raw material used"
                    field="DPBP2LQ3D2"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total Quantity of recycled or reused raw material used"
                    field="DPBP2LQ3D3"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Indicate Input Material</label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPBP2LQ3D1}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ3D1", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ3D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter inout material
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Total Quantity of raw material used</label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPBP2LQ3D2}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ3D2", e.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ3D2 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Total Quantity of recycled or reused raw material used
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPBP2LQ3D3}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ3D3", e.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ3D3 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
            </Dialog>
        </div>
    );
};
const BP2LQ5 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate();
    getData(badata);
    const [dataVal, setDataVal] = useState({
        DPBP2LQ5D1: "",
        DPBP2LQ5D2: 0,
        DPBP2LQ5D3: 0,
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPBP2LQ5D1: "",
            DPBP2LQ5D2: 0,
            DPBP2LQ5D3: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPBP2LQ5D1.trim().length !== 0 &&
            dataVal.DPBP2LQ5D2 >= 0 &&
            dataVal.DPBP2LQ5D3 >= 0
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPBP2LQ5D1: "",
                DPBP2LQ5D2: 0,
                DPBP2LQ5D3: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>

            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Category of Product/packaging material "
                    field="DPBP2LQ5D1"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total quantity of product / packaging material sold"
                    field="DPBP2LQ5D2"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total quantity of product / packaging material reclaimed"
                    field="DPBP2LQ5D3"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Category of Product/packaging material
                    </label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPBP2LQ5D1}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ5D1", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ5D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter category
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Total quantity of product / packaging material sold
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPBP2LQ5D2}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ5D2", e.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ5D2 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Total quantity of product / packaging material reclaimed
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPBP2LQ5D3}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ5D3", e.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ5D3 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
            </Dialog>
        </div>
    );
};
const GR2LQ1 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);
    getData(badata);
    let DPAN0040Options = [
        { name: "Hazardous Waste", id: 1 },
        { name: "Non- Hazardous waste", id: 2 },
    ];
    let DPAN0042Options = [
        { name: "Paper - all types", id: 1 },
        { name: "Plastic - all types", id: 2 },
        { name: "Metals - all types", id: 3 },
        { name: "Electrical items - WEEE - (ex. printers)", id: 4 },
        { name: "Electrical items - Batteries", id: 5 },
        { name: "General Waste - Commecial and industrial waste", id: 6 },
        { name: "General Waste - Organic: mixed food and garden waste", id: 7 },
        { name: "General Waste - Organic: garden waste", id: 8 },
        { name: "General Waste - Organic: food and drink waste", id: 9 },
        { name: "General Waste - Household residual waste", id: 10 },
        { name: "Glass - all types", id: 11 },
        { name: "Construction and demolition waste", id: 19 },
        { name: "Others", id: 12 },
    ];
    let DPAN0044Options = [
        { name: "Recovery of waste", id: 1 },
        { name: "Disposal of waste", id: 2 },
    ];
    let DPAN0041Options = [
        { name: "Bio-medical waste", id: 7 },
        { name: "Radioactive waste", id: 8 },
        { name: "Others - Please include in remarks", id: 6 }

    ];
    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN0040: null,
        DPAN0041: null,
        DPAN0041A: "",
        DPAN0042A: "",
        DPAN0042: null,
        DPAN0043: 0,
        DPAN0044: null,
        DPA0068A: false,
        DPA0069A: false,
        DPA0070A: false,
        DPA0068: 0,
        DPA0069: 0,
        DPA0070: [
            { mode: "", qty: 0 },
            { mode: "", qty: 0 },
        ],
        DPA0087A: false,
        DPA0088A: false,
        DPA0089A: false,
        DPA0087: 0,
        DPA0088: 0,
        DPA0089: 0,
        DPA0090A: false,
        DPA0090: [
            { mode: "", qty: 0 },
            { mode: "", qty: 0 },
        ],
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        if (obj === "DPAN0040") {
            local["DPAN0041"] = null;
            local["DPAN0041A"] = '';
            local["DPAN0043"] = 0;
            local["DPAN0042"] = null;
            local["DPAN0044"] = null;
        }
        if (obj === "DPAN0044") {
            local["DPA0068A"] = false;
            local["DPA0069A"] = false;
            local["DPGRWAD7"] = false;
            local["DPGRWAD8"] = false;
            local["DPGRWAD9"] = false;
            local["DPGRWAD10"] = false;
            local["DPGRWAD11"] = false;

            local["DPA0068"] = 0;
            local["DPA0069"] = 0;
            local["DPA0087"] = 0;
            local["DPA0088"] = 0;
            local["DPA0089"] = 0;
            local["DPA0070"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
            local["DPA0090"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        if (obj === "DPA0068A") {
            local["DPA0068"] = 0;
        }
        if (obj === "DPA0069A") {
            local["DPA0069"] = 0;
        }
        if (obj === "DPA0087A") {
            local["DPA0087"] = 0;
        }
        if (obj === "DPA0088A") {
            local["DPA0088"] = 0;
        }
        if (obj === "DPA0089A") {
            local["DPA0089"] = 0;
        }
        if (obj === "DPA0070A") {
            local["DPA0070"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        if (obj === "DPA0090A") {
            local["DPA0090"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPAN0040: null,
            DPAN0041: null,
            DPAN0041A: '',
            DPAN0042A: "",
            DPAN0042: null,
            DPAN0043: 0,
            DPAN0044: null,
            DPA0068A: false,
            DPA0069A: false,
            DPA0070A: false,
            DPA0068: 0,
            DPA0069: 0,
            DPA0070: [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ],
            DPA0087A: false,
            DPA0088A: false,
            DPA0089A: false,
            DPA0087: 0,
            DPA0088: 0,
            DPA0089: 0,
            DPA0090A: false,
            DPA0090: [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ],
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPAN0040 !== null &&
            dataVal.DPAN0044 !== null &&
            dataVal.DPAN0043 > 0 &&
            (dataVal.DPAN0040 === 1
                ? dataVal.DPAN0041 !== null
                : dataVal.DPAN0042 !== null) &&
            (dataVal.DPAN0040 === 1
                ? dataVal.DPAN0041 === 6
                    ? dataVal.DPAN0041A.trim().length !== 0
                    : true
                : true) &&
            (dataVal.DPAN0040 === 2
                ? dataVal.DPAN0042 === 12
                    ? dataVal.DPAN0042A.trim().length !== 0
                    : true
                : true)
        ) {
            if (dataVal.DPAN0044 === 1) {
                if (
                    (dataVal.DPA0068A === true && dataVal.DPA0068 > 0) ||
                    (dataVal.DPA0069A === true && dataVal.DPA0069 > 0) ||
                    (dataVal.DPA0070A === true &&
                        dataVal.DPA0070.some(
                            (item) => item.mode.trim().length !== 0 && item.qty > 0
                        ))
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }
                    setBAData(local2);
                    forceUpdate();
                    setBADialog(false);
                }
            } else {
                if (
                    (dataVal.DPA0087A === true && dataVal.DPA0087 > 0) ||
                    (dataVal.DPA0088A === true && dataVal.DPA0088 > 0) ||
                    (dataVal.DPA0089A === true && dataVal.DPA0089 > 0) ||
                    (dataVal.DPA0090A === true &&
                        dataVal.DPA0090.some(
                            (item) => item.mode.trim().length !== 0 && item.qty > 0
                        ))
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }
                    setBAData(local2);
                    forceUpdate();
                    setBADialog(false);
                }
            }
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const categoryTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN0040Options.findIndex((i) => i.id === rowData.DPAN0040);
        if (index !== -1) {
            txt = DPAN0040Options[index].name;
        }
        console.log(rowData.DPAN0040, index);
        return <> {txt} </>;
    };
    const typeTemplate = (rowData) => {
        let txt = "Not Found";
        if (rowData.DPAN0040 === 1) {
            let index = DPAN0041Options.find((i) => i.id === rowData?.DPAN0041);

            if (rowData?.DPAN0041 === 6) {
                return <> {index?.name + "/" + rowData.DPAN0041A} </>;
            }
            if (index !== -1) {
                txt = index?.name;
            }
            txt = rowData.DPAN0041;
        } else {
            let index = DPAN0042Options.find((i) => i.id === rowData?.DPAN0042);

            if (rowData?.DPAN0042 === 12) {
                return <> {index?.name + "/" + rowData.DPAN0042A} </>;
            }
            if (index !== -1) {
                txt = index?.name;
            }
        }

        return <> {txt} </>;
    };
    const managementTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN0044Options.findIndex((i) => i.id === rowData.DPAN0044);
        if (index !== -1) {
            txt = DPAN0044Options[index].name;
        }
        return <> {txt} </>;
    };

    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>

            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Category of Waste Generated"
                    body={(rowData) => {
                        return categoryTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Type of Hazardous waste generated"
                    body={(rowData) => {
                        return typeTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Quantity Generated"
                    field="DPAN0043"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Management of waste"
                    body={(rowData) => {
                        return managementTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Category of Waste Generated</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN0040}
                        options={DPAN0040Options}
                        onChange={(e) => {
                            updateDataVal("DPAN0040", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0040 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select category
                        </small>
                    )}
                </div>
                {dataVal.DPAN0040 === 1 ? (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">Type of Hazardous waste generated</label>
                            <Dropdown
                                optionLabel="name"
                                optionValue="id"
                                value={dataVal.DPAN0041}
                                options={DPAN0041Options}
                                onChange={(e) => {
                                    updateDataVal("DPAN0041", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN0041 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select category
                                </small>
                            )}
                        </div>
                        {dataVal.DPAN0041 === 6 && (
                            <div className="field">
                                <label htmlFor="ecpsngr">Please mention type  <span className="mandatory"> *</span> </label>
                                <InputText
                                    type={"text"}
                                    value={dataVal.DPAN0041A}
                                    onChange={(e) => {
                                        updateDataVal("DPAN0041A", e.target.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN0041A.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Mention hazardous Other category
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">
                                Type of Non-Hazardous waste generated
                            </label>
                            <Dropdown
                                optionLabel="name"
                                optionValue="id"
                                value={dataVal.DPAN0042}
                                options={DPAN0042Options}
                                onChange={(e) => {
                                    updateDataVal("DPAN0042", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN0042 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select category
                                </small>
                            )}
                        </div>
                        {dataVal.DPAN0042 === 12 && (
                            <div className="field">
                                <label htmlFor="ecpsngr">Please mention type  <span className="mandatory"> *</span> </label>
                                <InputText
                                    type={"text"}
                                    value={dataVal.DPAN0042A}
                                    onChange={(e) => {
                                        updateDataVal("DPAN0042A", e.target.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN0042A.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Mention Non-hazardous Other category
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                )}

                <div className="field">
                    <label htmlFor="ecpsngr">Quantity Generated (In Metric Tonnes)</label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPAN0043}
                        onChange={(e) => {
                            updateDataVal("DPAN0043", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0043 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Management of waste</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN0044}
                        options={DPAN0044Options}
                        onChange={(e) => {
                            updateDataVal("DPAN0044", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0044 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select type
                        </small>
                    )}
                </div>
                {dataVal.DPAN0044 === 1 ? (
                    <div className="flex grid justify-content-center gap-3">
                        <div className="flex align-items-center col-12">
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient1"
                                    name="pizza"
                                    value="Cheese"
                                    onChange={(e) => {
                                        updateDataVal("DPA0068A", e.checked);
                                    }}
                                    checked={dataVal.DPA0068A === true}
                                />
                                <label htmlFor="ingredient1" className="ml-2">
                                    Preparation for reuse
                                </label>
                            </div>
                            <div className="col-4">
                                <InputNumber
                                    value={dataVal.DPA0068}
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    disabled={dataVal.DPA0068A === false}
                                    onValueChange={(e) => {
                                        updateDataVal("DPA0068", e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex align-items-center col-12">
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient2"
                                    name="pizza"
                                    value="Mushroom"
                                    onChange={(e) => {
                                        updateDataVal("DPA0069A", e.checked);
                                    }}
                                    checked={dataVal.DPA0069A === true}
                                />
                                <label htmlFor="ingredient2" className="ml-2">
                                    Recycling
                                </label>
                            </div>
                            <div className="col-4">
                                <InputNumber
                                    value={dataVal.DPA0069}
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    disabled={dataVal.DPA0069A === false}
                                    onValueChange={(e) => {
                                        updateDataVal("DPA0069", e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex  col-12" style={{ flexDirection: "column" }}>
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient3"
                                    name="pizza"
                                    value="Pepper"
                                    onChange={(e) => {
                                        updateDataVal("DPA0070A", e.checked);
                                    }}
                                    checked={dataVal.DPA0070A === true}
                                />
                                <label htmlFor="ingredient3" className="ml-2">
                                    Other recovery operations
                                </label>
                            </div>
                            {dataVal.DPA0070A === true && (
                                <div className="col-12">
                                    <div className="col-12 grid">
                                        <div className="col-8">Mode</div>
                                        <div className="col-4">Quantity</div>
                                    </div>
                                    {dataVal.DPA0070.map((item) => {
                                        return (
                                            <div className="col-12 grid">
                                                <div className="col-8">
                                                    <InputText
                                                        type={"text"}
                                                        value={item.mode}
                                                        onChange={(e) => {
                                                            item.mode = e.target.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <InputNumber
                                                        maxFractionDigits={4}
                                                        useGrouping={false}
                                                        value={item.qty}
                                                        onChange={(e) => {
                                                            item.qty = e.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    dataVal.DPAN0044 === 2 && (
                        <div className="flex grid justify-content-center gap-3">
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="pizza"
                                        value="Cheese"
                                        onChange={(e) => {
                                            updateDataVal("DPA0087A", e.checked);
                                        }}
                                        checked={dataVal.DPA0087A === true}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        Incineration (with energy recovery)
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0087}
                                        disabled={dataVal.DPA0087A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0087", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="pizza"
                                        value="Cheese"
                                        onChange={(e) => {
                                            updateDataVal("DPA0088A", e.checked);
                                        }}
                                        checked={dataVal.DPA0088A === true}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        Incineration (without energy recovery)
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0088}
                                        disabled={dataVal.DPA0088A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0088", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient2"
                                        name="pizza"
                                        value="Mushroom"
                                        onChange={(e) => {
                                            updateDataVal("DPA0089A", e.checked);
                                        }}
                                        checked={dataVal.DPA0089A === true}
                                    />
                                    <label htmlFor="ingredient2" className="ml-2">
                                        Landfilling
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0089}
                                        disabled={dataVal.DPA0089A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0089", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex  col-12" style={{ flexDirection: "column" }}>
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient3"
                                        name="pizza"
                                        value="Pepper"
                                        onChange={(e) => {
                                            updateDataVal("DPA0090A", e.checked);
                                        }}
                                        checked={dataVal.DPA0090A === true}
                                    />
                                    <label htmlFor="ingredient3" className="ml-2">
                                        Other disposal operations
                                    </label>
                                </div>
                                {dataVal.DPA0090A === true && (
                                    <div className="col-12">
                                        <div className="col-12 grid">
                                            <div className="col-8">Mode</div>
                                            <div className="col-4">Quantity</div>
                                        </div>
                                        {dataVal.DPA0090.map((item) => {
                                            return (
                                                <div className="col-12 grid">
                                                    <div className="col-8">
                                                        <InputText
                                                            type={"text"}
                                                            value={item.mode}
                                                            onChange={(e) => {
                                                                item.mode = e.target.value;
                                                                forceUpdate();
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <InputNumber
                                                            maxFractionDigits={4}
                                                            useGrouping={false}
                                                            value={item.qty}
                                                            onChange={(e) => {
                                                                item.qty = e.value;
                                                                forceUpdate();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                )}
            </Dialog>
        </div>
    );
};
const GR2LQ2 = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const DPAN0048Options = [
        { name: "Surface water", id: 1 },
        { name: "Ground Water", id: 2 },
        { name: "Sea Water", id: 3 },
        { name: "Produced Water", id: 4 },
        { name: "Third-Party Water", id: 5 },
        { name: "Others", id: 6 }
    ];
    const DPAN0050Options = [
        { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
        { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
    ];
    const unitOptions = [
        { name: 'L', id: 1 }, { name: 'KL', id: 2 }, { name: 'ML', id: 3 }
    ]
    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN0048: null,
        DPAN0048A: '',
        DPAN0049: 0,
        DPAN0050: null,
        DPAN0051: false,
        DPAN0049A: null
    });
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata, submitted]);
    getData(badata);
    function extractData(data) {
        let mappedData = data
            .map((i) => ({
                key: `${i.DPAN0048}-${i.DPAN0049A}`,
                currentId: i.DPAN0049A,
                parentId: i.DPAN0048,
                title1: i.DPAN0048 === 6 ? i.DPAN0048A : DPAN0048Options.find((it) => it.id === i.DPAN0048).name,
                title2: unitOptions.find((it) => it.id === i.DPAN0049A).name,
            }))
            .filter((i) => i.key);

        let result = [];

        for (const item of data) {
            let index = mappedData.find(
                (i) => i.key === `${i.parentId}-${i.currentId}`
            );
            if (index) {
                result.push({
                    title: index.title1 + ">" + index.title2,
                    label: index.title1,
                    value: item.DPAN0049,
                    currentId: index.currentId,
                    parentId: index.parentId,
                    formType: 2,
                    dataType: 2,
                    isNull: false,
                    attachment: item.attachment || null,
                    uom: index.title2,
                    uniqueId: index.key,
                    maskId: item.id,
                    reportedDate: null,
                    additionalValue1: DPAN0050Options.find((i) => i.id === item.DPAN0050)
                        .name,
                    additionalValue2: item?.DPAN0051,
                });
            }
        }
        console.log(data, result, "RESULT");
        return result;
    }
    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === "DPAN0048") {
            local["DPAN0049"] = 0;
            local['DPAN0048A'] = ''
            local["DPAN0050"] = null;
        }

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPAN0048: null,
            DPAN0048A: '',
            DPAN0049: 0,
            DPAN0050: null,
            DPAN0051: false,
            DPAN0049A: null
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = [...badata];

        if (
            dataVal.DPAN0048 !== null && (dataVal.DPAN0048 === 6 ? dataVal.DPAN0048A.trim().length : true) && dataVal.DPAN0049A !== null &&
            parseFloat(dataVal.DPAN0049) >= 0 &&
            dataVal.DPAN0050 !== null
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPAN0048: null,
                DPAN0048A: '',
                DPAN0049: 0,
                DPAN0050: null,
                DPAN0051: false,
                DPAN0049A: null
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    const sourceTemplate = (rowData) => {
        let txt = "Not Found";

        if (rowData.DPAN0048 === 6) {
            return DPAN0048Options.find((i) => i.id === rowData?.DPAN0048)?.name + "/" + rowData.DPAN0048A
        }
        let index = DPAN0048Options.findIndex((i) => i.id === rowData.DPAN0048);
        if (index !== -1) {
            txt = DPAN0048Options[index].name;
        }
        return <>{txt}</>;
    };

    const withdrawlTemplate = (rowData) => {
        let txt = "Not Found";
        let index = DPAN0050Options.findIndex((i) => i.id === rowData.DPAN0050);

        if (index !== -1) {
            txt = DPAN0050Options[index].name;
        }
        return <>{txt}</>;
    };
    const qtyTemplate = (rowData) => {
        let txt = "Not Found";

        let unitindex = unitOptions.findIndex((i) => i.id === rowData.DPAN0049A);
        if (unitindex !== -1) {
            txt = rowData.DPAN0049 + ' ' + unitOptions[unitindex].name
        } else {
            txt = rowData.DPAN0049
        }
        return <>{txt}</>;
    };
    return (
        <div>

            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Source of Water Withdrawal"
                    body={(rowData) => {
                        return sourceTemplate(rowData);
                    }}
                    field="DPBP2LQ5D1"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Quantity of Water withdrawn"
                    body={(rowData) => {
                        return qtyTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="TDS of Water Withdrawal"
                    body={(rowData) => {
                        return withdrawlTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                <Column
                    header="Come under water-stressed area "
                    body={(rowData) => {
                        return <>{rowData.DPAN0051 ? "Yes" : "No"}</>;
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Source of Water Withdrawal <span className="mandatory"> *</span>  </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPAN0048", e.value);
                        }}
                        optionValue="id"
                        options={DPAN0048Options}
                        value={dataVal.DPAN0048}
                    />

                    {submitted && dataVal.DPAN0048 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select source
                        </small>
                    )}
                </div>
                {dataVal.DPAN0048 === 6 && (
                    <div className="field">
                        <label htmlFor="ecpsngr">Please mention type  <span className="mandatory"> *</span> </label>
                        <InputText
                            type={"text"}
                            value={dataVal.DPAN0048A}
                            onChange={(e) => {
                                updateDataVal("DPAN0048A", e.target.value);
                            }}
                        />
                        {submitted && dataVal.DPAN0048A.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Mention Other Source Name
                            </small>
                        )}
                    </div>
                )}
                <div className="field">

                    <label htmlFor="ecpsngr">
                        Quantity of Water withdrawn & unit <span className="mandatory"> *</span>
                    </label>
                    <div className="p-inputgroup flex-1">

                        <InputNumber
                            maxFractionDigits={4}
                            useGrouping={false}
                            value={dataVal.DPAN0049}
                            onChange={(e) => {
                                updateDataVal("DPAN0049", e.value);
                            }}
                        />
                        <span><Dropdown options={unitOptions} placeholder="unit" style={{ width: 100 }} onChange={(e) => {
                            updateDataVal("DPAN0049A", e.value);
                        }} value={dataVal.DPAN0049A} optionValue="id" optionLabel="name" /> </span>
                    </div>

                    {submitted && (dataVal.DPAN0049 <= 0 || dataVal.DPAN0049A === null) && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Required {dataVal.DPAN0049 <= 0 ? ' Quantity ' : ' Unit'}
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">TDS of Water Withdrawal <span className="mandatory"> *</span>  </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPAN0050", e.value);
                        }}
                        optionValue="id"
                        options={DPAN0050Options}
                        value={dataVal.DPAN0050}
                    />

                    {submitted && dataVal.DPAN0050 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select TDS
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Does the area of water withdrawal come under water-stressed area <span className="mandatory"> *</span>
                    </label>

                    <div className="flex flex-wrap gap-3">
                        <div className="flex align-items-center">
                            <RadioButton
                                inputId="ingredient1"
                                name="pizza"
                                value={dataVal.DPAN0051}
                                onChange={(e) => updateDataVal("DPAN0051", true)}
                                checked={dataVal.DPAN0051 === true}
                            />
                            <label htmlFor="ingredient1" className="ml-2">
                                Yes
                            </label>
                        </div>
                        <div className="flex align-items-center ml-5">
                            <RadioButton
                                inputId="ingredient2"
                                name="pizza"
                                value={dataVal.DPAN0051}
                                onChange={(e) => updateDataVal("DPAN0051", false)}
                                checked={dataVal.DPAN0051 === false}
                            />
                            <label htmlFor="ingredient2" className="ml-2">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const GR2LQ3 = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);
    const unitOptions = [
        { name: 'L', id: 1 }, { name: 'KL', id: 2 }, { name: 'ML', id: 3 }
    ]
    const DPANN0030Options = [
        { name: 'No Treatment', id: 1 }, { name: 'With Treatment', id: 2 }
    ]
    const DPAN0052Options = [
        { name: "Surface water", id: 1 },
        { name: "Ground Water", id: 2 },
        { name: "Sea Water", id: 3 },
        { name: "Produced Water", id: 4 },
        { name: "Third-Party Water", id: 5 },
        { name: "Others", id: 6 }
    ];
    const DPAN0054Options = [
        { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
        { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
        { name: "Not Available", id: 3 },
    ];

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN0052: null,
        DPAN0052A: '',
        DPANN0030: null,
        DPAN0053: 0,
        DPAN0053A: null,
        DPAN0054: null,
        DPAN0055: false,
    });
    getData(badata);
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata, submitted]);

    function extractData(data) {
        console.log(data)
        let mappedData = data
            .map((i) => ({
                key: `${i.DPAN0052}-${i.DPANN0030}-${i.DPAN0053A}`,
                currentId: i.DPAN0053A,
                parentId: i.DPANN0030,
                title1: i.DPAN0052 === 6 ? i.DPAN0052A : DPAN0052Options.find((it) => it.id === i.DPAN0052).name,
                title2: DPANN0030Options.find((it) => it.id === i.DPANN0030).name,
                title3: unitOptions.find((it) => it.id === i.DPAN0053A).name,
            }))
            .filter((i) => i.key);

        let result = [];

        for (const item of data) {
            let index = mappedData.find(
                (i) => i.key === `${item.DPAN0052}-${item.DPANN0030}-${item.DPAN0053A}`
            );

            if (index) {
                result.push({
                    title: index.title1 + ">" + index.title2,
                    label: index.title1,
                    value: item.DPAN0053,
                    currentId: index.currentId,
                    parentId: index.parentId,
                    formType: 2,
                    dataType: 2,
                    isNull: false,
                    attachment: item.attachment || null,
                    uom: index.title3,
                    uniqueId: index.key,
                    maskId: item.id,
                    reportedDate: null,
                    additionalValue1: DPAN0054Options.find((i) => i.id === item.DPAN0054)
                        .name,
                    additionalValue2: item?.DPAN0055,
                });
            }
        }
        console.log(result, "RESULT");
        return result;
    }
    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === "DPAN0052") {
            local["DPAN0052A"] = ''
            local["DPAN0053"] = 0;
            local["DPANN0030"] = null;
            local["DPAN0054"] = null;
        }

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPAN0052: null,
            DPAN0052A: '',
            DPANN0030: null,
            DPAN0053: 0,
            DPAN0054: null,
            DPAN0055: false,
            DPAN0053A: null,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = [...badata];

        if (
            dataVal.DPAN0052 !== null && dataVal.DPANN0030 !== null && (dataVal.DPAN0052 === 6 ? dataVal.DPAN0052A.trim().length : true) &&
            parseFloat(dataVal.DPAN0053) >= 0 && dataVal.DPAN0053A !== null &&
            dataVal.DPAN0054 !== null
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPAN0052: null,
                DPAN0052A: '',
                DPANN0030: null,
                DPAN0053: 0,
                DPAN0054: null,
                DPAN0055: false,
                DPAN0053A: null,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    const sourceTemplate = (rowData) => {
        let txt = "Not Found";
        if (rowData.DPAN0052 === 6) {
            return DPAN0052Options.find((i) => i.id === rowData?.DPAN0052)?.name + "/" + rowData.DPAN0052A
        }
        let index = DPAN0052Options.findIndex((i) => i.id === rowData.DPAN0052);
        if (index !== -1) {
            txt = DPAN0052Options[index].name;
        }
        return <>{txt}</>;
    };
    const qtyTemplate = (rowData) => {
        let txt = "Not Found";

        let unitindex = unitOptions.findIndex((i) => i.id === rowData.DPAN0053A);
        if (unitindex !== -1) {
            txt = rowData.DPAN0053 + ' ' + unitOptions[unitindex].name
        } else {
            txt = rowData.DPAN0053
        }
        return <>{txt}</>;
    };
    const withdrawlTemplate = (rowData) => {
        let txt = "Not Found";
        let index = DPAN0054Options.findIndex((i) => i.id === rowData.DPAN0054);
        if (index !== -1) {
            txt = DPAN0054Options[index].name;
        }
        return <>{txt}</>;
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Destination of Water discharge"
                    body={(rowData) => {
                        return sourceTemplate(rowData);
                    }}
                    field="DPBP2LQ5D1"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Quantity of water discharged"
                    body={(rowData) => {
                        return qtyTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="TDS of the destination of disposal"
                    body={(rowData) => {
                        return withdrawlTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                <Column
                    header="Disposal area come under water stress"
                    body={(rowData) => {
                        return <>{rowData.DPAN0055 ? "Yes" : "No"}</>;
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Destination of Water discharge <span className="mandatory"> *</span>  </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPAN0052", e.value);
                        }}
                        optionValue="id"
                        options={DPAN0052Options}
                        value={dataVal.DPAN0052}
                    />

                    {submitted && dataVal.DPAN0052 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select source
                        </small>
                    )}
                </div>
                {dataVal.DPAN0052 === 6 && (
                    <div className="field">
                        <label htmlFor="ecpsngr">Please mention type  <span className="mandatory"> *</span> </label>
                        <InputText
                            type={"text"}
                            value={dataVal.DPAN0052A}
                            onChange={(e) => {
                                updateDataVal("DPAN0052A", e.target.value);
                            }}
                        />
                        {submitted && dataVal.DPAN0052A.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Mention Other Destination Name
                            </small>
                        )}
                    </div>
                )}
                <div className="field">
                    <label htmlFor="ecpsngr">Type of Disposal<span className="mandatory"> *</span>  </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPANN0030", e.value);
                        }}
                        optionValue="id"
                        options={DPANN0030Options}
                        value={dataVal.DPANN0030}
                    />

                    {submitted && dataVal.DPANN0030 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select source
                        </small>
                    )}
                </div>
                <div className="field">

                    <label htmlFor="ecpsngr">
                        Quantity of water discharged & unit <span className="mandatory"> *</span>
                    </label>
                    <div className="p-inputgroup flex-1">

                        <InputNumber
                            maxFractionDigits={4}
                            useGrouping={false}
                            value={dataVal.DPAN0053}
                            onChange={(e) => {
                                updateDataVal("DPAN0053", e.value);
                            }}
                        />
                        <span><Dropdown options={unitOptions} placeholder="unit" style={{ width: 100 }} onChange={(e) => {
                            updateDataVal("DPAN0053A", e.value);
                        }} value={dataVal.DPAN0053A} optionValue="id" optionLabel="name" /> </span>
                    </div>

                    {submitted && (parseFloat(dataVal.DPAN0053) < 0 || dataVal.DPAN0053A === null) && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Required {dataVal.DPAN0053 <= 0 ? ' Quantity ' : ' Unit'}
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">TDS of the destination of disposal <span className="mandatory"> *</span> </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPAN0054", e.value);
                        }}
                        optionValue="id"
                        options={DPAN0054Options}
                        value={dataVal.DPAN0054}
                    />

                    {submitted && dataVal.DPAN0054 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select TDS
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Does the disposal area come under water stress <span className="mandatory"> *</span>
                    </label>

                    <div className="flex flex-wrap gap-3">
                        <div className="flex align-items-center">
                            <RadioButton
                                inputId="ingredient1"
                                name="pizza"
                                value={dataVal.DPAN0055}
                                onChange={(e) => updateDataVal("DPAN0055", true)}
                                checked={dataVal.DPAN0055 === true}
                            />
                            <label htmlFor="ingredient1" className="ml-2">
                                Yes
                            </label>
                        </div>
                        <div className="flex align-items-center ml-5">
                            <RadioButton
                                inputId="ingredient2"
                                name="pizza"
                                value={dataVal.DPAN0055}
                                onChange={(e) => updateDataVal("DPAN0055", false)}
                                checked={dataVal.DPAN0055 === false}
                            />
                            <label htmlFor="ingredient2" className="ml-2">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const HotelStay = ({ extractedData = () => { }, data, getData, setData, edit, standard, dates = { minDate: null, maxDate: null } }) => {
    const [hsdata, setHSData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [hsdata]);
    getData(hsdata);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [selectedRef, setSelectedRef] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [hsVal, setHsVal] = useState({
        DPAN091: [null, null],
        DPAN090: null,
        DPAN090A: null,
        DPAN092: 0,
        attachment: null,
    });
    const [category, setCategory] = useState([]);
    const [unitlist, setUnitList] = useState([]);
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 13);

                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(hsdata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [hsdata, category]);

    function extractData(data) {


        let mappedData = category.map(i => ({ key: i?.id.toString(), parentId: i.id, title1: i.title })).filter(i => i && i.key)

        console.log(mappedData)
        let result = []

        for (const item of data) {
            let index = mappedData.find(i => i.key === (item['DPAN090'].toString()))
            console.log(index, mappedData)
            if (index) {

                result.push({
                    title: index.title1, label: index.title1 + (item.DPAN090 === 248 ? ">" + item.DPAN090A : ''), value: item.DPAN092, subCategory1: index.parentId, currentId: index.parentId, parentId: index.parentId, formType: 2, dataType: 1, isNull: false, attachment: item.attachment, uom: "Room per night", uniqueId: index.key, maskId: item.id, reportedDate: item.DPAN091?.[0]
                })


            }
        }
        console.log(result)
        return result
    }
    const updateHsVal = (obj, val) => {
        let local = hsVal;
        local[obj] = val;
        if (obj === "DPAN090") {
            local["DPAN090A"] = "";
            local["DPAN092"] = 0;
        }
        setHsVal(local);

        forceUpdate();
    };

    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Hotel Stay") {
                    let local = {};
                    let local2 = hsdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "BT_HS") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items.from_date !== undefined &&
                                    typeof items.from_date === "number" &&
                                    items.to_date !== undefined &&
                                    typeof items.to_date === "number" &&
                                    items.country !== undefined &&
                                    items.country.trim().length !== 0 &&
                                    items.no_of_night_stay !== undefined &&
                                    parseFloat(items.no_of_night_stay.toString()) > 0
                                ) {
                                    let index = category.findIndex(
                                        (i) =>
                                            i.title.trim().toLowerCase() ===
                                            items.country.toString().toLowerCase()
                                    );

                                    local["DPAN091"] = [
                                        moment(excelDateToJSDate(items.from_date))._d,
                                        moment(excelDateToJSDate(items.to_date))._d,
                                    ];
                                    if (index === -1) {
                                        local["DPAN090"] = 248;
                                        local["DPAN090A"] = items.country.toString();
                                    } else {
                                        local["DPAN090"] = category[index].id;
                                        local["DPAN090A"] = "";
                                    }

                                    local["DPAN092"] = parseFloat(items.no_of_night_stay);

                                    local["id"] = getID();
                                    local["attachment"] = null;

                                    local2.push(local);
                                }
                            });
                        }
                    });

                    setHSData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const refheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setHsVal({
                                DPAN091: null,
                                DPAN090: null,
                                DPAN090A: null,
                                DPAN092: 0,
                                attachment: null,
                            });

                            setSubmitted(false);
                            setRefDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
        </div>
    );
    const edithsVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        let date1 = DateTime.fromFormat(
            items.DPAN091[0],
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
            { zone: "UTC" }
        ).toJSDate();
        let date2 = DateTime.fromFormat(
            items.DPAN091[1],
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
            { zone: "UTC" }
        ).toJSDate();

        items.DPAN091 = [date1, date2];

        setHsVal(items);
        console.log(items);
        setRefDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...hsdata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setHSData(loc);
        forceUpdate();
    };

    const saveHotelstay = () => {
        setSubmitted(true);
        console.log(hsVal);
        if (
            hsVal.DPAN091 !== null &&
            hsVal.DPAN091.filter((i) => i !== null).length === 2 &&
            hsVal.DPAN090 !== null &&
            (hsVal.DPAN090 === 248 ? hsVal.DPAN090A.trim().length !== 0 : true) &&
            hsVal.DPAN092 > 0

        ) {
            if (hsVal.id === undefined) {
                hsVal["id"] = getID();
                setHSData((prev) => [hsVal, ...prev]);
            } else {
                let loc = [...hsdata];
                let index = loc.findIndex((k) => {
                    return k.id === hsVal.id;
                });
                loc[index] = hsVal;
                setHSData(loc);
            }
            console.log(hsVal);
            forceUpdate();
            setSubmitted(false);
            setRefDialog(false);
        }

        forceUpdate();
    };
    const refDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveHotelstay}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const countryTemplate = (rowData) => {
        let index = category.findIndex((i) => {
            return i.id === rowData.DPAN090;
        });
        let text = "NA";
        if (index !== -1) {
            text = category[index].title;
        }
        return <span>{text} </span>;
    };
    const HSStayTemplate = (rowData) => {
        let date = JSON.parse(JSON.stringify(rowData.DPAN091));
        let from = DateTime.fromISO(date[0], { zone: "UTC" })
            .toLocal()
            .toFormat("dd/MM/yyyy");
        let to = DateTime.fromISO(date[1], { zone: "UTC" })
            .toLocal()
            .toFormat("dd/MM/yyyy");
        return (
            <span>
                {from}-{to}
            </span>
        );
    };
    const actionBodyTemplateRF = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            edithsVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        exportToCSV(
                                            [
                                                {
                                                    sno: null,
                                                    from_date: "",
                                                    to_date: "",
                                                    country: "",
                                                    no_of_night_stay: "",
                                                },
                                            ],
                                            "BT_HS",
                                            "Hotel Stay"
                                        );
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Hotel Stay");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={() => {
                                setHsVal({
                                    DPAN091: null,
                                    DPAN090: null,
                                    DPAN090A: null,
                                    DPAN092: 0,
                                    attachment: null,
                                });

                                setSubmitted(false);
                                setRefDialog(true);
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={hsdata}
                scrollable
                onSelectionChange={(e) => setSelectedRef(e.value)}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={refFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && refheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Date of Stay"
                    body={(rowData) => {
                        return HSStayTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Country"
                    body={(rowData) => {
                        return countryTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="No of night stay"
                    field="DPAN092"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateRF}> </Column>}
            </DataTable>
            <Dialog
                visible={refDialog}
                style={{
                    width: "450px",
                }}
                header="Add Hotel Stay"
                modal
                className="p-fluid"
                footer={refDialogFooter}
                onHide={() => {
                    setRefDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate">Hotel Stay Date (From - To)</label>
                    <Calendar
                        minDate={dates.minDate} maxDate={dates.maxDate}
                        dateFormat={'dd/mm/yy'}
                        showMinMaxRange={true}
                        showOtherMonths={false}
                        viewDate={dates.minDate}
                        value={hsVal.DPAN091}
                        onChange={(e) => updateHsVal("DPAN091", e.value)}
                        selectionMode="range"
                    />
                </div>
                {submitted &&
                    (hsVal.DPAN091 === null ||
                        hsVal.DPAN091.filter((i) => i !== null).length !== 2) && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select stay date
                        </small>
                    )}
                <div className="field">
                    <label htmlFor="DPAN090">Select Country</label>
                    <Dropdown
                        value={hsVal.DPAN090}
                        onChange={(e) => {
                            updateHsVal("DPAN090", e.value);
                        }}
                        options={category}
                        optionLabel="title"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && hsVal.DPAN090 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select country
                        </small>
                    )}
                </div>
                {hsVal.DPAN090 !== null && hsVal.DPAN090 === 248 && (
                    <div className="field">
                        <label htmlFor="DPAN090A">Specify country name </label>
                        <InputText
                            value={hsVal.DPAN090A}
                            onChange={(e) => {
                                updateHsVal("DPAN090A", e.target.value);
                            }}
                        />
                        {submitted && hsVal.DPAN090A.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                mention country name
                            </small>
                        )}
                    </div>
                )}

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="gasrefill">No of night stay </label>
                        <div id="gasrefill" className="p-inputgroup">
                            <InputNumber
                                maxFractionDigits={4}
                                useGrouping={false}
                                value={hsVal.DPAN092}
                                onChange={(e) => {
                                    updateHsVal("DPAN092", e.value);
                                }}
                            />
                            <span className="p-inputgroup-addon">Room per night</span>
                        </div>
                        {submitted && parseInt(hsVal.DPAN092) <= 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                days required
                            </small>
                        )}
                    </div>
                </div>
                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(hsVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], hsVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = hsVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setHsVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(hsVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {hsVal.attachment !== null && (
                    <>
                        {hsVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    hsVal.attachment.splice(index, 1);
                                                    if (hsVal.attachment.length === 0) {
                                                        hsVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Electricity = ({ extractedData = () => { }, data, getData, setData, edit, standard }) => {
    const [scdata, setSCData] = useState(data);
    const forceUpdate = useForceUpdate();
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [scfilter, setScFilter] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [load, setLoad] = useState(true);

    const [submitted, setSubmitted] = useState(false);
    const [fuelcatlist, setFuelCatList] = useState([]);
    const [fuellist, setFuelList] = useState([]);
    const [category, setCategory] = useState([]);

    const [scVal, setSCVal] = useState({
        DPAN095: null,
        DPAN096: null,
        DPAN099: null,
        DPAN097: "",
        DPAN098: 0,
        attachment: null,
    });
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(scdata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [scdata, fuelcatlist]);
    function extractData(data) {
        let mappedData = category
            .flatMap((i) =>
                i.newEfSubcategory2s?.flatMap((x) =>
                    x.newEfSubcategory3s?.map((y) => ({
                        key: `${i.id}-${x.id}-${y.id}`,
                        parent0id: i.id,
                        currentId: y.id,
                        parentId: x.id,
                        title1: i.title,
                        title2: x.title,
                        title3: y.title,
                    }))
                )
            )
            .filter(Boolean);

        let result = [];

        for (const item of data) {
            let index = mappedData.find(
                (i) =>
                    i.key === `${item["DPAN095"]}-${item["DPAN096"]}-${item["DPAN099"]}`
            );
            //   console.log(index, mappedData, "MAPPED");
            if (index) {
                result.push({
                    title: index.title1 + '>' + index.title2 + ">" + index.title3,
                    label: index.title1 + '>' + index.title2,
                    value: item.DPAN098,
                    currentId: index.currentId,
                    parentId: index.parentId,
                    formType: 2,
                    dataType: 1,
                    subCategory1: index.parent0id,
                    subCategory2: index.parentId,
                    subCategory3: index.currentId,
                    isNull: false,
                    attachment: item.attachment || null,
                    uom: index.title3,
                    uniqueId: index.key,
                    maskId: item.id,
                    reportedDate: null,
                });
            }
        }
        // console.log(result, "RESULT");
        return result;
    }
    const [scdialog, setScDialog] = useState(false);
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            setLoad(false)
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let efcat = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories
                        let cat_index = (efcat === undefined || efcat === null || !efcat.length) ? -1 : 0


                        if (cat_index !== -1) {
                            setCategory(
                                JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories[
                                    cat_index
                                ].newEfSubcategory1s
                            );
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setFuelCatList(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    useState(() => {
        setData();
    }, [scdata]);
    getData(scdata);
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    const [unitlist, setUnitList] = useState([]);
    const scheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setSCVal({
                                DPAN095: null,
                                DPAN096: null,
                                DPAN099: null,
                                DPAN097: "",
                                DPAN098: 0,
                                attachment: null,
                            });
                            setSubmitted(false);

                            setScDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setScFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const saveStationary = () => {
        setSubmitted(true);

        if (
            scVal.DPAN095 !== null &&
            scVal.DPAN096 !== null &&
            scVal.DPAN099 !== null &&
            scVal.DPAN098 >= 0
        ) {
            if (scVal.id === undefined) {
                scVal["id"] = getID();
                setSCData((prev) => [scVal, ...prev]);
            } else {
                let loc = [...scdata];

                let index = loc.findIndex((k) => {
                    return k.id === scVal.id;
                });
                loc[index] = scVal;
                setSCData(loc);
            }
            console.log(scVal);
            forceUpdate();
            setSubmitted(false);
            setScDialog(false);
        }

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Electricity") {
                    let local = {};
                    let local2 = scdata;
                    console.log("Electricity");
                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S2_EL") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                let index = -1;
                                if (items.type_of_energy !== undefined) {
                                    index = category.findIndex(
                                        (i) =>
                                            i.title.trim().toLowerCase() ===
                                            items.type_of_energy.toString().trim().toLowerCase()
                                    );
                                }
                                console.log(items);
                                if (
                                    items.type_of_energy !== undefined &&
                                    items.type_of_energy.trim().length !== 0 &&
                                    items.source_of_energy !== undefined &&
                                    items.source_of_energy.trim().length !== 0 &&
                                    items.unit !== undefined &&
                                    items.unit.trim().length !== 0 &&
                                    items.energy_procured_from !== undefined &&
                                    items.energy_procured_from.toString().length !== 0 &&
                                    items.qty_used !== undefined &&
                                    parseFloat(items.qty_used.toString()) > 0
                                ) {
                                    const { result, id } = findMatchingIds(
                                        category,
                                        items.type_of_energy.toString(),
                                        items.source_of_energy.toString(),
                                        items.unit.toString()
                                    );
                                    console.log(result, id, items);
                                    if (result) {
                                        local["DPAN095"] = id[0];
                                        local["DPAN096"] = id[1];
                                        local["DPAN099"] = id[2];

                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPAN098"] = parseFloat(items.qty_used.toString());
                                        local["DPAN097"] = items.energy_procured_from.toString();

                                        local2.push(local);
                                    }
                                }
                            });
                        }
                    });

                    setSCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const updateScVal = (obj, val) => {
        let local = scVal;

        local[obj] = val;
        if (obj === "DPAN095") {
            local["DPAN096"] = null;
            local["DPAN099"] = null;
            let fuel_type = [];
            fuelcatlist.forEach((i) => {
                console.log(i, val);
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === val) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        } else if (obj === "DPAN096") {
            local["DPAN099"] = null;

            let units = [];
            let index = fuelcatlist.findIndex((i) => {
                return i.id === local["DPAN095"];
            });
            fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                if (i.newEfSubcategory3s !== undefined) {
                    if (i.id === val) {
                        units = i.newEfSubcategory3s;
                    }
                }
            });
            setUnitList(units);
            // fuelcategory.forEach((i) => {
            //     if (i.fuel === val.name && i.fuel_type === local['DPA0130'].name) {
            //         if (units.findIndex((k) => { return k.name === i.unit }) === -1) {
            //             units.push({ name: i.unit })
            //         }
            //     }

            // })
        }
        if (obj === "DPAN099") {
            local.DPAN098 = 0;
            local.DPAN097 = "";
        }
        setSCVal(local);

        forceUpdate();
    };
    const editScVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        let fuel_type = [],
            units = [];
        if (item.DPAN096 !== null) {
            fuelcatlist.forEach((i) => {
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === item.DPAN095) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        }
        if (item.DPAN099 !== null) {
            let index = fuelcatlist.findIndex((i) => {
                return i.id === item.DPAN095;
            });
            if (index !== -1 && fuelcatlist[index].newEfSubcategory2s !== undefined) {
                fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                    if (i.newEfSubcategory3s !== undefined) {
                        if (i.id === item.DPAN096) {
                            units = i.newEfSubcategory3s;
                        }
                    }
                });
            }

            setUnitList(units);
        }
        items.DPAN098 = parseFloat(items.DPAN098);
        items.DPAN097 = items.DPAN097;

        setSCVal(items);
        console.log(items);
        setScDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...scdata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setSCData(loc);
        forceUpdate();
    };
    const scDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveStationary();
                }}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const subcategory1template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPAN095;
        });
        if (index !== -1) {
            text = category[index].title;
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory2template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPAN095;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPAN096;
            });
            if (index2 !== -1) {
                text = category[index].newEfSubcategory2s[index2].title;
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory3template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPAN095;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPAN096;
            });
            if (
                index2 !== -1 &&
                category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                undefined
            ) {
                let index3 = category[index].newEfSubcategory2s[
                    index2
                ].newEfSubcategory3s.findIndex((i) => {
                    return i.id === rowData.DPAN099;
                });
                if (index3 !== -1) {
                    text =
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                            index3
                        ].title;
                }
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const actionBodyTemplateSC = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editScVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        exportToCSV(
                                            [
                                                {
                                                    sno: null,
                                                    type_of_energy: "",
                                                    source_of_energy: "",
                                                    unit: "",
                                                    qty_used: 0,
                                                    energy_procured_from: "",
                                                },
                                            ],
                                            "S2_EL",
                                            "Electricity"
                                        );
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Electricity");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={() => {
                                setSCVal({
                                    DPAN095: null,
                                    DPAN096: null,
                                    DPAN099: null,
                                    DPAN097: "",
                                    DPAN098: 0,
                                    attachment: null,
                                });
                                setSubmitted(false);

                                setScDialog(true);
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={scdata}
                loading={load}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0130.name", "DPA0131.name", "DPA0132.name"]}
                globalFilter={scfilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && scheader}
                responsiveLayout="scroll"
            >
                <Column
                    body={subcategory1template}
                    header="Type of Energy used"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory2template}
                    header="Source of Energy"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory3template}
                    header="Unit"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN098"
                    header="Quantity Used"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN097"
                    header="Source from where the energy is procured"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column header='Action' body={actionBodyTemplateSC}> </Column>}
            </DataTable>
            <Dialog
                visible={scdialog}
                style={{
                    width: "450px",
                }}
                header="Add New"
                modal
                className="p-fluid"
                footer={scDialogFooter}
                onHide={() => {
                    setScDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="DPAN095">Type of Energy used <span className="mandatory"> *</span> </label>
                    <Dropdown
                        value={scVal.DPAN095}
                        onChange={(e) => {
                            updateScVal("DPAN095", e.value);
                        }}
                        optionValue="id"
                        options={fuelcatlist}
                        optionLabel="title"
                        placeholder="Select"
                    />
                    {submitted && scVal.DPAN095 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Type of energy is required
                        </small>
                    )}
                </div>
                {scVal.DPAN095 !== null && (
                    <div className="field">
                        <label htmlFor="DPAN096">Select Source of Energy <span className="mandatory"> *</span>  </label>
                        <Dropdown
                            value={scVal.DPAN096}
                            onChange={(e) => {
                                updateScVal("DPAN096", e.value);
                            }}
                            options={fuellist}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPAN096 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Source of Energy is required
                            </small>
                        )}
                    </div>
                )}
                {scVal.DPAN096 !== null && (
                    <div className="field">
                        <label htmlFor="DPAN099">Select Unit <span className="mandatory"> *</span>  </label>
                        <Dropdown
                            value={scVal.DPAN099}
                            onChange={(e) => {
                                updateScVal("DPAN099", e.value);
                            }}
                            options={unitlist}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPAN099 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Unit
                            </small>
                        )}
                    </div>
                )}
                {scVal.DPAN095 !== null && scVal.DPAN099 !== null && (
                    <>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="gasrefill"> Quantity of Energy used <span className="mandatory"> *</span> </label>
                                <div id="gasrefill" className="p-inputgroup">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={scVal.DPAN098}
                                        onChange={(e) => {
                                            updateScVal("DPAN098", e.value);
                                        }}
                                        placeholder="Quantity"
                                    />
                                </div>
                                {submitted && parseFloat(scVal.DPAN098) < 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Quantity is required
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="DPAN097">
                                    Source from where the energy is procured
                                </label>
                                <div id="DPAN097" className="p-inputgroup">
                                    <InputText
                                        type="text"
                                        value={scVal.DPAN097}
                                        onChange={(e) => {
                                            updateScVal("DPAN097", e.target.value);
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        customUpload
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(scVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], scVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = { ...scVal };
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setSCVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(scVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {scVal.attachment !== null && (
                    <>
                        {scVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    scVal.attachment.splice(index, 1);
                                                    if (scVal.attachment.length === 0) {
                                                        scVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Employee_Category = ({ data, getData, setData, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [
        {
            id: 1,
            title: "Permanent",
            type2: [
                {
                    id: 3,
                    title: "Full Time",
                    type3: [
                        {
                            id: 7,
                            title: "Male",
                            data: [{ DPAN400: null }, { DPAN401: null }, { DPAN402: null }],
                        },
                        {
                            id: 8,
                            title: "Female",
                            data: [{ DPAN403: null }, { DPAN404: null }, { DPAN405: null }],
                        },
                        {
                            id: 9,
                            title: "LGBTQ +",
                            data: [{ DPAN406: null }, { DPAN407: null }, { DPAN408: null }],
                        },
                    ],
                },
                {
                    id: 4,
                    title: "Part-Time",
                    type3: [
                        {
                            id: 10,
                            title: "Male",
                            data: [{ DPAN409: null }, { DPAN410: null }, { DPAN411: null }],
                        },
                        {
                            id: 11,
                            title: "Female",
                            data: [{ DPAN412: null }, { DPAN413: null }, { DPAN414: null }],
                        },
                        {
                            id: 12,
                            title: "LGBTQ +",
                            data: [{ DPAN415: null }, { DPAN416: null }, { DPAN417: null }],
                        },
                    ],
                },
            ],
        },
        {
            title: "Other than Permanent",
            id: 2,
            type2: [
                {
                    id: 5,
                    title: "Temporary",
                    type3: [
                        {
                            id: 13,
                            title: "Male",
                            data: [{ DPAN418: null }, { DPAN419: null }, { DPAN420: null }],
                        },
                        {
                            id: 14,
                            title: "Female",
                            data: [{ DPAN421: null }, { DPAN422: null }, { DPAN423: null }],
                        },
                        {
                            id: 15,
                            title: "LGBTQ +",
                            data: [{ DPAN424: null }, { DPAN425: null }, { DPAN426: null }],
                        },
                    ],
                },
                {
                    id: 6,
                    title: "Non-Guaranteed hours",
                    type3: [
                        {
                            id: 16,
                            title: "Male",
                            data: [{ DPAN427: null }, { DPAN428: null }, { DPAN429: null }],
                        },
                        {
                            id: 17,
                            title: "Female",
                            data: [{ DPAN430: null }, { DPAN431: null }, { DPAN432: null }],
                        },
                        {
                            id: 18,
                            title: "LGBTQ +",
                            data: [{ DPAN433: null }, { DPAN434: null }, { DPAN435: null }],
                        },
                    ],
                },
            ],
        },
    ];

    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [emp]);
    getData(emp);
    const updateVal = (obj, val_) => {
        console.log(emp);
        let loc = val;
        loc[obj] = val_;
        if (obj === "type") {
            loc.type2 = null;
            loc.type3 = null;
            setObj(null);
            let a = [];
            temp.forEach((i) => {
                if (i.id === val_) {
                    a = i.type2;
                }
            });
            setType(a);
        }
        if (obj === "type2") {
            loc.type3 = null;
            setObj(null);
            let a = [];
            temp.forEach((i) => {
                if (i.id === loc["type"]) {
                    i.type2.forEach((j) => {
                        if (j.id === val_) {
                            a = j.type3;
                        }
                    });
                }
            });
            setType2(a);
        }
        if (obj === "type3") {
            let a = [];
            emp.forEach((i) => {
                if (i.id === loc["type"]) {
                    i.type2.forEach((j) => {

                        j.type3.forEach((k) => {
                            if (k.id === val_) {
                                setObj(k.data);
                                forceUpdate();
                            }
                        });

                    });
                }
            });
        }
        setVal(loc);
        forceUpdate();
    };
    const updateNumbers = (index, value) => {
        console.log(value);
        let loc = JSON.parse(JSON.stringify(emp));
        let loc2 = JSON.parse(JSON.stringify(obj));
        loc.forEach((i) => {
            if (i.id === val.type) {
                i.type2.forEach((j) => {

                    j.type3.forEach((k) => {
                        if (k.id === val.type3) {
                            const key = Object.keys(k.data[index])[0];
                            k.data[index][key] = value;
                        }
                    });

                });
            }
        });
        const key = Object.keys(loc2[index])[0];

        loc2[index][key] = value;
        setObj(loc2);
        setEmp(loc);
        forceUpdate();
    };
    const groupTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <div>{option.title}</div>
            </div>
        );
    };

    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <TabMenu model={[{ label: 'Permanent', id: 1 }, { label: 'Other Than Permanent', id: 2 }]} activeIndex={activeindex} onTabChange={(e) => { updateVal("type", e.value.id); setActiveIndex(e.index) }} />
                    {activeindex === 0 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox optionValue="id" value={val.type3} onChange={(e, a) => { console.log(e, a); updateVal("type3", e.value) }} options={type} optionLabel="title"
                                    optionGroupLabel="title" optionGroupChildren="type3" optionGroupTemplate={groupTemplate} className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type3 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>

                    }
                    {activeindex === 1 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox optionValue="id" value={val.type3} onChange={(e, a) => { console.log(e, a); updateVal("type3", e.value) }} options={type} optionLabel="title"
                                    optionGroupLabel="title" optionGroupChildren="type3" optionGroupTemplate={groupTemplate} className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type3 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>

                    }


                    {/* <div className="field">
                      <label className="col-5" htmlFor="DPAN096">
                          Select Employee Category
                      </label>
                      <Dropdown
                          value={val.type}
                          className="col-5"
                          onChange={(e) => {
                              updateVal("type", e.value);
                          }}
                          options={[
                              { title: "Permanent", id: 1 },
                              { title: "Other than Permanent", id: 2 },
                          ]}
                          optionLabel="title"
                          optionValue="id"
                          placeholder="Select"
                      />
                  </div>
                  {val.type !== null && (
                      <div className="field">
                          <label className="col-5" htmlFor="DPAN096">
                              Select Employement Type
                          </label>
                          <Dropdown
                              className="col-5"
                              value={val.type2}
                              onChange={(e) => {
                                  updateVal("type2", e.value);
                              }}
                              options={type}
                              optionLabel="title"
                              optionValue="id"
                              placeholder="Select"
                          />
                      </div>
                  )}
                  {val.type !== null && val.type2 !== null && (
                      <div className="field">
                          <label className="col-5" htmlFor="DPAN096">
                              Select Gender
                          </label>
                          <Dropdown
                              className="col-5"
                              value={val.type3}
                              onChange={(e) => {
                                  updateVal("type3", e.value);
                              }}
                              options={type2}
                              optionLabel="title"
                              optionValue="id"
                              placeholder="Select"
                          />
                      </div>
                  )}
                  {val.type !== null &&
                      val.type2 !== null &&
                      val.type3 !== null &&
                      obj !== null &&
                      obj.map((i, index) => {
                          return (
                              <div>
                                  <div className="formgrid grid">
                                      <div className="field col">
                                          <label htmlFor="gasrefill">
                                              {index === 0
                                                  ? "Under 30 Years"
                                                  : index === 1
                                                      ? "30-50 Years"
                                                      : "Above 50 Years"}
                                          </label>
                                          <div id="gasrefill" className="p-inputgroup col-10">
                                              <InputNumber
                                                  useGrouping={false}
                                                  value={i[Object.keys(i)[0]]}
                                                  onChange={(e) => {
                                                      updateNumbers(index, e.value);
                                                  }}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          );
                      })}
                       */}
                </div>
            ) : (
                <div className="gridlines-container">
                    <table className="gridlines">
                        <tbody>
                            <tr>
                                <td className="table-h1 text-center" colSpan="18">Permanent</td>

                            </tr>

                            <tr>
                                <td colSpan="9" className="table-h2 text-center">Full Time</td>
                                <td colSpan="9" className="table-h2 text-center">Part Time</td>

                            </tr>
                            <tr>
                                <td colSpan="3" className="table-h3 text-center">Male</td>
                                <td colSpan="3" className="table-h3 text-center">Female</td>
                                <td colSpan="3" className="table-h3 text-center">LGBTQ+</td>
                                <td colSpan="3" className="table-h3 text-center">Male</td>
                                <td colSpan="3" className="table-h3 text-center">Female</td>
                                <td colSpan="3" className="table-h3 text-center">LGBTQ+</td>

                            </tr>
                            <tr>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>


                            </tr>
                            <tr>
                                <td className="table-no">{emp[0].type2[0].type3[0].data[0].DPAN400}</td>
                                <td className="table-no">{emp[0].type2[0].type3[0].data[1].DPAN401}</td>
                                <td className="table-no">{emp[0].type2[0].type3[0].data[2].DPAN402}</td>
                                <td className="table-no">{emp[0].type2[0].type3[1].data[0].DPAN403}</td>
                                <td className="table-no">{emp[0].type2[0].type3[1].data[1].DPAN404}</td>
                                <td className="table-no">{emp[0].type2[0].type3[1].data[2].DPAN405}</td>
                                <td className="table-no">{emp[0].type2[0].type3[2].data[0].DPAN406}</td>
                                <td className="table-no">{emp[0].type2[0].type3[2].data[1].DPAN407}</td>
                                <td className="table-no">{emp[0].type2[0].type3[2].data[2].DPAN408}</td>

                                <td className="table-no">{emp[0].type2[1].type3[0].data[0].DPAN409}</td>
                                <td className="table-no">{emp[0].type2[1].type3[0].data[1].DPAN410}</td>
                                <td className="table-no">{emp[0].type2[1].type3[0].data[2].DPAN411}</td>
                                <td className="table-no">{emp[0].type2[1].type3[1].data[0].DPAN412}</td>
                                <td className="table-no">{emp[0].type2[1].type3[1].data[1].DPAN413}</td>
                                <td className="table-no">{emp[0].type2[1].type3[1].data[2].DPAN414}</td>
                                <td className="table-no">{emp[0].type2[1].type3[2].data[0].DPAN415}</td>
                                <td className="table-no">{emp[0].type2[1].type3[2].data[1].DPAN416}</td>
                                <td className="table-no">{emp[0].type2[1].type3[2].data[2].DPAN417}</td>


                            </tr>
                        </tbody>
                    </table>
                    <table className="gridlines">
                        <tbody>
                            <tr>

                                <td colSpan="18" className="table-h1 text-center">Other than Permanent </td>
                            </tr>

                            <tr>

                                <td colSpan="9" className="table-h2 text-center">Temporary</td>
                                <td colSpan="9" className="table-h2 text-center">Non-Guaranteed hours </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="table-h3 text-center">Male</td>
                                <td colSpan="3" className="table-h3 text-center">Female</td>
                                <td colSpan="3" className="table-h3 text-center">LGBTQ+</td>
                                <td colSpan="3" className="table-h3 text-center">Male</td>
                                <td colSpan="3" className="table-h3 text-center">Female</td>
                                <td colSpan="3" className="table-h3 text-center">LGBTQ+</td>

                            </tr>
                            <tr>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>


                            </tr>
                            <tr>


                                <td className="table-no">{emp[1].type2[0].type3[0].data[0].DPAN418}</td>
                                <td className="table-no">{emp[1].type2[0].type3[0].data[1].DPAN419}</td>
                                <td className="table-no">{emp[1].type2[0].type3[0].data[2].DPAN420}</td>
                                <td className="table-no">{emp[1].type2[0].type3[1].data[0].DPAN421}</td>
                                <td className="table-no">{emp[1].type2[0].type3[1].data[1].DPAN422}</td>
                                <td className="table-no">{emp[1].type2[0].type3[1].data[2].DPAN423}</td>
                                <td className="table-no">{emp[1].type2[0].type3[2].data[0].DPAN424}</td>
                                <td className="table-no">{emp[1].type2[0].type3[2].data[1].DPAN425}</td>
                                <td className="table-no">{emp[1].type2[0].type3[2].data[2].DPAN426}</td>

                                <td className="table-no">{emp[1].type2[1].type3[0].data[0].DPAN427}</td>
                                <td className="table-no">{emp[1].type2[1].type3[0].data[1].DPAN428}</td>
                                <td className="table-no">{emp[1].type2[1].type3[0].data[2].DPAN429}</td>
                                <td className="table-no">{emp[1].type2[1].type3[1].data[0].DPAN430}</td>
                                <td className="table-no">{emp[1].type2[1].type3[1].data[1].DPAN431}</td>
                                <td className="table-no">{emp[1].type2[1].type3[1].data[2].DPAN432}</td>
                                <td className="table-no">{emp[1].type2[1].type3[2].data[0].DPAN433}</td>
                                <td className="table-no">{emp[1].type2[1].type3[2].data[1].DPAN434}</td>
                                <td className="table-no">{emp[1].type2[1].type3[2].data[2].DPAN435}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
const Diversity_of_Employees = ({ data, getData, setData, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState();
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [
        {
            id: 1,
            title: "Senior Management",
            type2: [
                { id: 5, title: "Male", data: [{ DPK0032: null }, { DPK0033: null }, { DPK0034: null }] },
                {
                    id: 6,
                    title: "Female",
                    data: [{ DPK0028: null }, { DPK0029: null }, { DPK0030: null }],
                },
                {
                    id: 7,
                    title: "LGBTQ +",
                    data: [{ DPAN436: null }, { DPAN437: null }, { DPAN438: null }],
                },
            ],
        },
        {
            id: 2,
            title: "Middle Management",
            type2: [
                { id: 8, title: "Male", data: [{ DPKN030: null }, { DPKN031: null }, { DPKN032: null }] },
                {
                    id: 9,
                    title: "Female",
                    data: [{ DPK0036: null }, { DPK0037: null }, { DPK0038: null }],
                },
                {
                    id: 10,
                    title: "LGBTQ +",
                    data: [{ DPAN439: null }, { DPAN440: null }, { DPAN441: null }],
                },
            ],
        },
        {
            id: 3,
            title: "Other Management category",
            type2: [
                {
                    id: 11,
                    title: "Male",
                    data: [{ DPKN033: null }, { DPKN034: null }, { DPKN035: null }],
                },
                {
                    id: 12,
                    title: "Female",
                    data: [{ DPK0039: null }, { DPK0040: null }, { DPK0041: null }],
                },
                {
                    id: 13,
                    title: "LGBTQ +",
                    data: [{ DPAN442: null }, { DPAN443: null }, { DPAN444: null }],
                },
            ],
        },
        {
            id: 4,
            title: "Staff",
            type2: [
                {
                    id: 14,
                    title: "Male",
                    data: [{ DPAN445: null }, { DPAN446: null }, { DPAN447: null }],
                },
                {
                    id: 15,
                    title: "Female",
                    data: [{ DPAN448: null }, { DPAN449: null }, { DPAN450: null }],
                },
                {
                    id: 16,
                    title: "LGBTQ +",
                    data: [{ DPAN451: null }, { DPAN452: null }, { DPAN453: null }],
                },
            ],
        },
    ];
    console.log(data)
    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [emp]);
    getData(emp);
    const updateVal = (obj_, val_) => {
        console.log(val_);
        let loc = val;
        loc[obj_] = val_;
        if (obj_ === "type") {
            loc.type2 = null;
            setObj(null);
            let a = [];
            temp.forEach((i) => {
                if (i.id === val_) {
                    a = i.type2;
                }
            });
            setType(a);
        }
        if (obj_ === "type2") {
            let a = [];
            emp.forEach((i) => {
                if (i.id === loc["type"]) {
                    i.type2.forEach((j) => {

                        if (j.id === val_) {
                            console.log(j)
                            setObj(j.data);
                            forceUpdate();
                        }
                    });
                }
            });

        }
        console.log(obj, loc)
        setVal(loc);
        forceUpdate();
    };
    const updateNumbers = (index, value) => {
        console.log(value);
        let loc = JSON.parse(JSON.stringify(emp));
        let loc2 = JSON.parse(JSON.stringify(obj));
        loc.forEach((i) => {
            if (i.id === val.type) {
                i.type2.forEach((j) => {

                    if (j.id === val.type2) {
                        const key = Object.keys(j.data[index])[0];
                        j.data[index][key] = value;
                    }


                });
            }
        });
        const key = Object.keys(loc2[index])[0];

        loc2[index][key] = value;
        setObj(loc2);
        setEmp(loc);
        forceUpdate();
    };
    const groupTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <div>{option.title}</div>
            </div>
        );
    };
    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <TabMenu model={[{ label: 'Senior Management', id: 1 }, { label: 'Middle Management', id: 2 }, { label: 'Other Management category', id: 3 }, { label: 'Staff', id: 4 }]} activeIndex={activeindex} onTabChange={(e) => { updateVal("type", e.value.id); setActiveIndex(e.index) }} />
                    {activeindex === 0 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox value={val.type2} onChange={(e) => updateVal("type2", e.value)} options={type} optionLabel="title" optionValue="id" className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type2 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>
                    }
                    {activeindex === 1 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox value={val.type2} onChange={(e) => updateVal("type2", e.value)} optionValue="id" options={type} optionLabel="title" className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type2 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>
                    }
                    {activeindex === 2 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox value={val.type2} onChange={(e) => updateVal("type2", e.value)} optionValue="id" options={type} optionLabel="title" className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type2 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>
                    }
                    {activeindex === 3 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox value={val.type2} onChange={(e) => updateVal("type2", e.value)} optionValue="id" options={type} optionLabel="title" className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type2 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>
                    }
                </div>
            ) : (
                <div>
                    <div className="gridlines-container">
                        <table className="gridlines">
                            <tbody>
                                <td className="table-h1 text-center" colSpan="9">Senior Management</td>
                                <td className="table-h1 text-center" colSpan="9"> Middle Management</td>

                                <tr>
                                    <td colSpan="3" className=" text-center">Male</td>
                                    <td colSpan="3" className=" text-center">Female</td>
                                    <td colSpan="3" className=" text-center"> LGBTQ+</td>
                                    <td colSpan="3" className=" text-center">Male</td>
                                    <td colSpan="3" className=" text-center">Female</td>
                                    <td colSpan="3" className=" text-center"> LGBTQ+</td>

                                </tr>

                                <tr>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>


                                </tr>
                                <tr>
                                    <td className="table-no">{emp[0].type2[0].data[0].DPK0032}</td>
                                    <td className="table-no">{emp[0].type2[0].data[1].DPK0033}</td>
                                    <td className="table-no">{emp[0].type2[0].data[2].DPK0034}</td>
                                    <td className="table-no">{emp[0].type2[1].data[0].DPK0028}</td>
                                    <td className="table-no">{emp[0].type2[1].data[1].DPK0029}</td>
                                    <td className="table-no">{emp[0].type2[1].data[2].DPK0030}</td>
                                    <td className="table-no">{emp[0].type2[2].data[0].DPAN436}</td>
                                    <td className="table-no">{emp[0].type2[2].data[1].DPAN437}</td>
                                    <td className="table-no">{emp[0].type2[2].data[2].DPAN438}</td>

                                    <td className="table-no">{emp[1].type2[0].data[0].DPKN030}</td>
                                    <td className="table-no">{emp[1].type2[0].data[1].DPKN031}</td>
                                    <td className="table-no">{emp[1].type2[0].data[2].DPKN032}</td>
                                    <td className="table-no">{emp[1].type2[1].data[0].DPK0036}</td>
                                    <td className="table-no">{emp[1].type2[1].data[1].DPK0037}</td>
                                    <td className="table-no">{emp[1].type2[1].data[2].DPK0038}</td>
                                    <td className="table-no">{emp[1].type2[2].data[0].DPAN439}</td>
                                    <td className="table-no">{emp[1].type2[2].data[1].DPAN440}</td>
                                    <td className="table-no">{emp[1].type2[2].data[2].DPAN441}</td>


                                </tr>
                            </tbody>
                        </table>
                        <table className="gridlines">
                            <tbody>
                                <tr>
                                    <td className="table-h1 text-center" colSpan="9">Other Management category</td>
                                    <td className="table-h1 text-center" colSpan="9"> Staff</td>
                                </tr>

                                <tr>
                                    <td colSpan="3" className=" text-center">Male</td>
                                    <td colSpan="3" className=" text-center">Female</td>
                                    <td colSpan="3" className=" text-center"> LGBTQ+</td>
                                    <td colSpan="3" className=" text-center">Male</td>
                                    <td colSpan="3" className=" text-center">Female</td>
                                    <td colSpan="3" className=" text-center"> LGBTQ+</td>

                                </tr>

                                <tr>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>


                                </tr>
                                <tr>
                                    <td className="table-no">{emp[2].type2[0].data[0].DPKN033}</td>
                                    <td className="table-no">{emp[2].type2[0].data[1].DPKN034}</td>
                                    <td className="table-no">{emp[2].type2[0].data[2].DPKN035}</td>
                                    <td className="table-no">{emp[2].type2[1].data[0].DPK0039}</td>
                                    <td className="table-no">{emp[2].type2[1].data[1].DPK0040}</td>
                                    <td className="table-no">{emp[2].type2[1].data[2].DPK0041}</td>
                                    <td className="table-no">{emp[2].type2[2].data[0].DPAN442}</td>
                                    <td className="table-no">{emp[2].type2[2].data[1].DPAN443}</td>
                                    <td className="table-no">{emp[2].type2[2].data[2].DPAN444}</td>

                                    <td className="table-no">{emp[3].type2[0].data[0].DPAN445}</td>
                                    <td className="table-no">{emp[3].type2[0].data[1].DPAN446}</td>
                                    <td className="table-no">{emp[3].type2[0].data[2].DPAN447}</td>
                                    <td className="table-no">{emp[3].type2[1].data[0].DPAN448}</td>
                                    <td className="table-no">{emp[3].type2[1].data[1].DPAN449}</td>
                                    <td className="table-no">{emp[3].type2[1].data[2].DPAN450}</td>
                                    <td className="table-no">{emp[3].type2[2].data[0].DPAN451}</td>
                                    <td className="table-no">{emp[3].type2[2].data[1].DPAN452}</td>
                                    <td className="table-no">{emp[3].type2[2].data[2].DPAN453}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

const Capital_Goods = ({
    standard,
    data, allowedCategories = [],
    getData,
    setData,
    edit, dates = { minDate: null, maxDate: null }
}) => {
    const [pgdata, setPGData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [pgdata]);
    getData(pgdata);
    const [priceconvert, setPriceConvert] = useState({
        amount: 0,
        from: null,
        to: "USD",
    });
    const [currencysugesstion, setCurrencySugesstion] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [pgFilter, setPgFilter] = useState(null);
    const [conversiondialog, setConversionDialog] = useState(false);
    const [pgDialog, setPgDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [category, setCategory] = useState([]);
    const [pgVal, setPgVal] = useState({
        DPAN1131: null,
        DPAN1130: "",
        DPAN1128: 0,
        DPAN1127: 0,
        DPAN1129: null,
        attachment: null
    });
    // DPAN1127 - One item
    // DPAN1128 - Qty
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };

        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 11);

                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    const updatePgVal = (obj, val) => {
        let local = pgVal;
        local[obj] = val;
        console.log(val);
        setPgVal(local);

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Purchased_goods_and_services") {
                    let local = {};
                    let local2 = pgdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_PG") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                let index = category.findIndex((i) => {
                                    return (
                                        i.title.trim().toLowerCase() ===
                                        items.item_class.toString().trim().toLowerCase()
                                    );
                                });
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.item_name !== undefined &&
                                    items.item_name.toString().trim().length !== 0 &&
                                    items.qty_purchased !== undefined &&
                                    parseFloat(items.qty_purchased.toString()) > 0 &&
                                    items.price_per_item !== undefined &&
                                    parseFloat(items.price_per_item.toString()) > 0 &&
                                    items.currency_code !== undefined &&
                                    (currency_codes.findIndex((i) => {
                                        return (
                                            i.name.split("-")[0].toLowerCase() ===
                                            items.currency_code.toString().toLowerCase()
                                        );
                                    }) !== -1 ||
                                        items.currency_code.toString().toLowerCase() === "usd") &&
                                    items.item_class !== undefined &&
                                    index !== -1
                                ) {
                                    console.log(typeof items.date === "number");
                                    if (items.currency_code.toString().toLowerCase() === "usd") {
                                        local["DPAN1130"] = items.item_name;
                                        local["DPAN1129"] = category[index].id;
                                        local["DPAN1131"] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["DPAN1128"] = parseFloat(
                                            items.qty_purchased.toString()
                                        );
                                        local["attachment"] = null;
                                        local["DPAN1127"] = parseFloat(
                                            items.price_per_item.toString()
                                        );
                                        local2.push(local);
                                    } else {
                                        let obj = {
                                            from: items.currency_code,
                                            to: "USD",
                                            amount: parseInt(items.price_per_item.toString()),
                                            date: moment(excelDateToJSDate(items.date)).format(
                                                "YYYY-MM-DD"
                                            ),
                                        };
                                        const options = {
                                            method: "GET",
                                            url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                                            params: obj,
                                            headers: {
                                                "X-RapidAPI-Key":
                                                    "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                                                "X-RapidAPI-Host":
                                                    "currency-conversion-and-exchange-rates.p.rapidapi.com",
                                            },
                                        };
                                        Axios.request(options)
                                            .then(function (response) {
                                                local["DPAN1130"] = items.item_name;
                                                local["DPAN1129"] = category[index].id;
                                                local["DPAN1131"] = moment(
                                                    excelDateToJSDate(items.date)
                                                )._d;
                                                local["id"] = getID();
                                                local["DPAN1128"] = items.qty_purchased.toString();
                                                local["attachment"] = null;
                                                local["DPAN1127"] = response.data.result
                                                    .toFixed(2)
                                                    .toString();

                                                local2.push(local);

                                                setPGData(local2);
                                                forceUpdate();
                                            })
                                            .catch(function (error) {
                                                console.error(error);
                                            });
                                    }
                                }
                            });
                        }
                    });

                    setPGData(local2);
                    forceUpdate();
                    console.log(local2);
                }
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const pgheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setPgVal({
                                DPAN1131: null,
                                DPAN1130: "",
                                DPAN1128: 0,
                                DPAN1127: 0,
                                DPAN1129: null,
                                attachment: null
                            });

                            setSubmitted(false);
                            setPgDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setPgFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const editPgVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPAN1131 = moment.utc(item.DPAN1131).toDate();
        items.DPAN1128 = parseFloat(items.DPAN1128);
        items.DPAN1127 = parseFloat(items.DPAN1127);
        setPgVal(items);
        console.log(items);
        setPgDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = pgdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setPGData(loc);
        forceUpdate();
    };
    const savePurchaseGoodsServices = () => {
        setSubmitted(true);
        console.log(pgVal);
        if (
            pgVal.DPAN1130.trim().length !== 0 &&
            pgVal.DPAN1129 !== null &&
            parseFloat(pgVal.DPAN1128) > 0 &&
            parseFloat(pgVal.DPAN1127) > 0 &&
            pgVal.DPAN1131 !== null

        ) {
            if (pgVal.id === undefined) {
                pgVal["id"] = getID();
                setPGData((prev) => [pgVal, ...prev]);
            } else {
                let loc = pgdata;
                let index = loc.findIndex((k) => {
                    return k.id === pgVal.id;
                });
                loc[index] = pgVal;
                setPGData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setPgDialog(false);
        }

        forceUpdate();
    };
    const convertCurrency = () => {
        if (
            priceconvert.from !== null &&
            parseInt(priceconvert.amount) > 0 &&
            pgVal.DPAN1131 !== null
        ) {
            let obj = {
                from: priceconvert.from.name.split("-")[0],
                to: "USD",
                amount: parseInt(priceconvert.amount),
                date: moment(pgVal.DPAN1131).format("YYYY-MM-DD"),
            };
            const options = {
                method: "GET",
                url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                params: obj,
                headers: {
                    "X-RapidAPI-Key": "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                    "X-RapidAPI-Host":
                        "currency-conversion-and-exchange-rates.p.rapidapi.com",
                },
            };
            Axios.request(options)
                .then(function (response) {
                    let loc = pgVal;
                    loc.DPAN1127 = response.data.result.toFixed(2).toString();
                    setPgVal(loc);
                    forceUpdate();
                    setConversionDialog(false);
                    setPriceConvert({ from: "", to: "USD", amount: 0 });
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    };
    const pgDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={savePurchaseGoodsServices}
            />
        </>
    );
    const pgAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplatePG = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editPgVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const itemClassificationTemplate = (rowData) => {
        let index = category.findIndex((i) => i.id === rowData.DPAN1129);
        let text = "Not Found";
        if (index !== -1) {
            text = category[index].title;
        }
        return <>{text}</>;
    };
    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...currency_codes];
            } else {
                _filteredCountries = currency_codes.filter((country) => {
                    console.log(country);
                    return country.name
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }

            setCurrencySugesstion(_filteredCountries);
        }, 100);
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        exportToCSV(
                                            [
                                                {
                                                    sno: null,
                                                    date: "",
                                                    item_name: "",
                                                    item_class: "",
                                                    qty_purchased: "",
                                                    price_per_item: "",
                                                    currency_code: "",
                                                },
                                            ],
                                            "S3_PG",
                                            "Purchased_goods_and_services"
                                        );
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Purchased_goods_and_services");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={() => {
                                setPgVal({
                                    DPAN1131: null,
                                    DPAN1130: "",
                                    DPAN1128: 0,
                                    DPAN1127: 0,
                                    DPAN1129: null,
                                    attachment: null
                                });

                                setSubmitted(false);
                                setPgDialog(true);
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={pgdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={pgFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && pgheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0285"
                    header="Date of Purchase"
                    body={dateFormatting_cg}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN1130"
                    header="Item Name"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0287.name"
                    header="Item Classification"
                    body={itemClassificationTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPAN1128"
                    header="Quantity Purchased"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN1127"
                    header="Cost per Item "
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={pgAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplatePG}> </Column>}
            </DataTable>
            <Dialog
                visible={pgDialog}
                style={{
                    width: "450px",
                }}
                header="Add Purchase Item"
                modal
                className="p-fluid"
                footer={pgDialogFooter}
                onHide={() => {
                    setPgDialog(false);
                }}
            >
                <div>
                    <div className="field">
                        <label htmlFor="refdate"> Date of purchase </label>
                        <Calendar
                            minDate={dates.minDate} maxDate={dates.maxDate}
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            showMinMaxRange={true}
                            showOtherMonths={false}
                            viewDate={dates.minDate}
                            value={pgVal.DPAN1131}
                            onChange={(e) => updatePgVal("DPAN1131", e.value)}
                        ></Calendar>
                    </div>

                    {pgVal.DPA0285 !== null && (
                        <>
                            <div className="field">
                                <label htmlFor="DPAN1130">Name of the Item Purchased</label>
                                <InputText
                                    value={pgVal.DPAN1130}
                                    onChange={(e) => {
                                        updatePgVal("DPAN1130", e.target.value);
                                    }}
                                />
                                {submitted && pgVal.DPAN1130.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Item name is required
                                    </small>
                                )}
                            </div>
                            <div className="field">
                                <label htmlFor="DPAN1129">
                                    Classification of Item Purcahsed
                                </label>
                                <Dropdown
                                    value={pgVal.DPAN1129}
                                    onChange={(e) => {
                                        updatePgVal("DPAN1129", e.value);
                                    }}
                                    options={category.filter(i => { return allowedCategories.includes('1-' + i.id) || allowedCategories.length === 0 })}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && pgVal.DPAN1129 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Classification is required
                                    </small>
                                )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Quantity Purchased </label>

                                <InputNumber
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    value={pgVal.DPAN1128}
                                    onChange={(e) => {
                                        updatePgVal("DPAN1128", e.value);
                                    }}
                                    placeholder="Quantity purchased"
                                />

                                {submitted && parseInt(pgVal.DPAN1128) <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Quantity is required
                                    </small>
                                )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Price per item ( in USD ) </label>

                                <InputNumber
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    value={pgVal.DPAN1127}
                                    onChange={(e) => {
                                        updatePgVal("DPAN1127", e.value);
                                    }}
                                    placeholder="Price per item in USD"
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "green",
                                            textDecoration: "underline",
                                        }}
                                        onClick={() => {
                                            setConversionDialog(true);
                                        }}
                                    >
                                        convert to USD
                                    </span>
                                </div>

                                {submitted && parseInt(pgVal.DPAN1127) < 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        price is required
                                    </small>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(pgVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], pgVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = pgVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setPgVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(pgVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {pgVal.attachment !== null && (
                    <>
                        {pgVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    pgVal.attachment.splice(index, 1);
                                                    if (pgVal.attachment.length === 0) {
                                                        pgVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
            <Dialog
                visible={conversiondialog}
                style={{
                    width: "50%",
                }}
                header="Currency conversion"
                modal
                className="p-fluid"
                onHide={() => {
                    setConversionDialog(false);
                }}
            >
                <div>
                    <div className="field ">
                        <label htmlFor="gasrefill"> Enter price for conversion </label>

                        <InputText
                            keyfilter={"num"}
                            value={priceconvert.amount}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.amount = e.target.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                            placeholder="Price per item in USD"
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="gasrefill">select your currency code</label>
                        <AutoComplete
                            field="name"
                            forceSelection
                            value={priceconvert.from}
                            suggestions={currencysugesstion}
                            completeMethod={search}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.from = e.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                        />
                    </div>
                    <Button
                        label="Convert & Save"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={convertCurrency}
                    />
                </div>
            </Dialog>
        </div>
    );
};
const Scope3_Investments = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    let DPAN1133Options = [
        { name: "Equity Based", id: 1 },
        { name: "Revenue based", id: 2 },
        { name: "Investment Specific to a Project", id: 3 },
    ];


    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN1132: '', DPAN1133: null, DPAN1134: null, DPAN1135: null, DPAN1136: null, DPAN1137: null, DPAN1138: null, DPAN1140: null, DPAN1141: null, DPAN1142: null, DPAN1143: null, DPAN1144: ''
    });
    getData(badata);
    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        if (obj === 'DPAN1133') {
            local.DPAN1134 = null
            local.DPAN1135 = null
            local.DPAN1136 = null
            local.DPAN1137 = null
            local.DPAN1138 = null
            local.DPAN1140 = null
            local.DPAN1141 = null
            local.DPAN1142 = null
            local.DPAN1143 = null
        }
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata]);

    function extractData(data) {
        console.log(data)
        let mappedData = data
            .map((i) => ([{

                currentId: i.DPAN1133,
                parentId: i.DPAN1133,
                label: DPAN1133Options.find((it) => it.id === i.DPAN1133)?.name + '>' + (i.DPAN1133 === 1 ? 'Share of Equity' : i.DPAN1133 === 2 ? 'Total revenue of the investee company' : 'Total Investment made by the organization in the project'),
                title: i.DPAN1133 === 1 ? 'Share of Equity' : i.DPAN1133 === 2 ? 'Total revenue of the investee company' : 'Total Investment made by the organization in the project',
                uom: i.DPAN1133 === 1 ? '%' : 'INR',
                value: i.DPAN1133 === 1 ? i.DPAN1134 : i.DPAN1133 === 2 ? i.DPAN1136 : i.DPAN1140,
                formType: 2,
                dataType: 2,
                isNull: false,
                uniqueId: `${i.DPAN1133}-1`,
                maskId: i.id,
            }, {
                currentId: i.DPAN1133,
                parentId: i.DPAN1133,
                label: DPAN1133Options.find((it) => it.id === i.DPAN1133)?.name + '>' + ((i.DPAN1133 === 1 || i.DPAN1133 === 2) ? 'Total Emissions of the investee company' : 'Total Scope 1 Emissions from the Project'),
                title: (i.DPAN1133 === 1 || i.DPAN1133 === 2) ? 'Total Emissions of the investee company' : 'Total Scope 1 Emissions from the Project',
                uom: 'tCo2e',
                value: i.DPAN1133 === 1 ? i.DPAN1135 : i.DPAN1133 === 2 ? i.DPAN1138 : i.DPAN1142,
                formType: 2,
                dataType: 2,
                isNull: false,
                uniqueId: `${i.DPAN1133}-2`,
                maskId: i.id,
            }, i.DPAN1133 === 3 ? {
                key: `${i.DPAN1133}`,
                currentId: i.DPAN1133,
                parentId: i.DPAN1133,
                label: DPAN1133Options.find((it) => it.id === i.DPAN1133)?.name + '>' + 'Total Scope 2 Emissions from the Project',
                title: 'Total Scope 2 Emissions from the Project',
                uom: 'tCo2e',
                value: i.DPAN1143,
                formType: 2,
                dataType: 2,
                isNull: false,
                uniqueId: `${i.DPAN1133}-3`,
                maskId: i.id,
            } : {}
            ].flatMap(x => x).filter(x => x)))
            .flatMap(x => x).filter(x => x)
            .filter((i) => i.uniqueId)




        console.log(mappedData, "RESULT");
        return mappedData;
    }
    const openNewMc = () => {
        setDataVal({
            DPAN1132: '', DPAN1133: null, DPAN1134: null, DPAN1135: null, DPAN1136: null, DPAN1137: null, DPAN1138: null, DPAN1140: null, DPAN1141: null, DPAN1142: null, DPAN1143: null, DPAN1144: ''
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = [...badata];
        if (local.DPAN1132.trim().length && local.DPAN1133 !== null && ((local.DPAN1133 === 1 ? (local.DPAN1134 > 0 && local.DPAN1135 > 0) : local.DPAN1133 === 2 ? (local.DPAN1136 > 0 && local.DPAN1137 > 0 && local.DPAN1138 > 0) : local.DPAN1133 === 3 ? (local.DPAN1140 > 0 && local.DPAN1141 > 0 && local.DPAN1142 > 0 && local.DPAN1143 > 0) : false))) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setSubmitted(false);
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const typeTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN1133Options.findIndex((i) => i.id === rowData.DPAN1133);

        if (index !== -1) {
            txt = DPAN1133Options[index].name;
        }
        console.log(rowData.DPAN1133, index);
        return <> {txt} </>;
    };



    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Sector of The Investee Company"
                    field="DPAN1132"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Type of Investment"
                    body={(rowData) => {
                        return typeTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Remarks"
                    field="DPAN1144"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Sector of The Investee Company</label>
                    <InputText

                        value={dataVal.DPAN1132}
                        onChange={(e) => {
                            updateDataVal("DPAN1132", e.target.value);
                        }}
                    />
                    {submitted && !dataVal.DPAN1132.trim().length && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Sector required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Type of Investment</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN1133}
                        options={DPAN1133Options}
                        onChange={(e) => {
                            updateDataVal("DPAN1133", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN1133 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select Investment Type
                        </small>
                    )}
                </div>
                {dataVal.DPAN1133 === 1 ?
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">Share of Equity (% of equity in the investee company)</label>
                            <InputNumber
                                min={1}
                                value={dataVal.DPAN1134}

                                onChange={(e) => {
                                    updateDataVal("DPAN1134", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN1134 <= 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Share of Equity required
                                </small>
                            )}
                        </div>
                        <div className="field">
                            <label htmlFor="ecpsngr">Total Emissions of the investee company (in tCo2e)</label>
                            <InputNumber
                                min={1}
                                value={dataVal.DPAN1135}

                                onChange={(e) => {
                                    updateDataVal("DPAN1135", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN1135 <= 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    required total emission
                                </small>
                            )}
                        </div>
                    </> :
                    dataVal.DPAN1133 === 2 ?
                        <>
                            <div className="field">
                                <label htmlFor="ecpsngr"> Total revenue of the investee company (in INR) </label>
                                <InputNumber
                                    min={1}
                                    value={dataVal.DPAN1136}

                                    onChange={(e) => {
                                        updateDataVal("DPAN1136", e.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN1136 <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Share of Equity required
                                    </small>
                                )}
                            </div>
                            <div className="field">
                                <label htmlFor="ecpsngr">Revenue from the investment made (in INR)</label>
                                <InputNumber
                                    min={1}
                                    value={dataVal.DPAN1137}

                                    onChange={(e) => {
                                        updateDataVal("DPAN1137", e.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN1137 <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        required revenue
                                    </small>
                                )}
                            </div>
                            <div className="field">
                                <label htmlFor="ecpsngr">Total Emissions of the investee company (in tCo2e)</label>
                                <InputNumber
                                    min={1}
                                    value={dataVal.DPAN1138}

                                    onChange={(e) => {
                                        updateDataVal("DPAN1138", e.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN1138 <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        required total emission
                                    </small>
                                )}
                            </div>
                        </> : dataVal.DPAN1133 === 3 ?
                            <>
                                <div className="field">
                                    <label htmlFor="ecpsngr"> Total Investment made by the organization in the project (in INR) </label>
                                    <InputNumber
                                        min={1}
                                        value={dataVal.DPAN1140}

                                        onChange={(e) => {
                                            updateDataVal("DPAN1140", e.value);
                                        }}
                                    />
                                    {submitted && dataVal.DPAN1140 <= 0 && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            required
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="ecpsngr">Total Value of the project (in INR)</label>
                                    <InputNumber
                                        min={1}
                                        value={dataVal.DPAN1141}

                                        onChange={(e) => {
                                            updateDataVal("DPAN1141", e.value);
                                        }}
                                    />
                                    {submitted && dataVal.DPAN1141 <= 0 && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            required total value
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="ecpsngr">Total Scope 1 Emissions from the Project (in tCo2e)</label>
                                    <InputNumber
                                        min={1}
                                        value={dataVal.DPAN1142}

                                        onChange={(e) => {
                                            updateDataVal("DPAN1142", e.value);
                                        }}
                                    />
                                    {submitted && dataVal.DPAN1142 <= 0 && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            required total scope 1 emission
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="ecpsngr">Total Scope 2 Emissions from the Project (in tCo2e)</label>
                                    <InputNumber
                                        min={1}
                                        value={dataVal.DPAN1143}

                                        onChange={(e) => {
                                            updateDataVal("DPAN1143", e.value);
                                        }}
                                    />
                                    {submitted && dataVal.DPAN1143 <= 0 && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            required total scope 2 emission
                                        </small>
                                    )}
                                </div>
                            </> : <></>
                }

                <div className="field">
                    <label htmlFor="ecpsngr">Remarks (if any)</label>
                    <InputTextarea

                        value={dataVal.DPAN1144}
                        onChange={(e) => {
                            updateDataVal("DPAN1144", e.target.value);
                        }}
                    />

                </div>
            </Dialog>
        </div>
    );
};
const Upstream_Trans_Dist = ({ data, getData, setData, edit, standard, dates = { minDate: null, maxDate: null } }) => {
    const [mcdata, setMCData] = useState(data);
    const [mcDialog, setMCDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [attachment, setAttachment] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [mcFilter, setMCFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [category, setCategory] = useState([]);
    const [category2, setCategory2] = useState([]);

    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [unitlist, setUnitList] = useState([]);
    const mode = [{ name: 'Road', id: 1 }, { name: 'Air', id: 2 }, { name: 'Rail', id: 3 }, { name: 'Sea', id: 4 }]
    const [mcVal, setMcVal] = useState({
        DPAN1150: null, DPAN1151: null, DPAN1152: null, DPAN1153: null, DPAN1155: null, DPAN1154: null, DPAN1156: 0, DPAN1157: 0

    });

    useEffect(() => {
        let veh_cat = [];
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 42);

                        console.log(cat_index);
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }

                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    getData(mcdata);
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }

    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Mobile Combustion Emissions") {
                    let local = {};
                    let local2 = mcdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_OV") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                console.log(items);
                                if (
                                    items.fuel_type !== undefined &&
                                    items.fuel_type.toString().length !== 0 &&
                                    items.category !== undefined &&
                                    items.vehicle_mode !== undefined &&
                                    (items.vehicle_mode.toString().trim().toLowerCase() ===
                                        "passenger" ||
                                        items.vehicle_mode.toString().trim().toLowerCase() ===
                                        "delivery") &&
                                    items.vehicle_no !== undefined &&
                                    items.vehicle_no.toString().trim().length !== 0 &&
                                    items.category.toString().trim().length !== 0 &&
                                    (items.category.toString().toLowerCase() === "fuel" ||
                                        items.category.toString().toLowerCase() === "distance")
                                ) {
                                    if (items.category.toString().toLowerCase() === "distance") {
                                        console.log(items);
                                        if (
                                            items.unit !== undefined &&
                                            items.unit.toString().trim().length !== 0 &&
                                            items.km_travelled !== undefined &&
                                            parseFloat(items.km_travelled.toString()) > 0 &&
                                            items.vehicle_type !== undefined &&
                                            items.vehicle_type.toString().length !== 0 &&
                                            items.vehicle_category !== undefined &&
                                            items.vehicle_category.toString().length !== 0
                                        ) {
                                            if (
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "passenger"
                                            ) {
                                                const { result, id } = findMatchingIds(
                                                    category,
                                                    items.vehicle_category.toString(),
                                                    items.vehicle_type.toString(),
                                                    items.fuel_type.toString(),
                                                    items.unit.toString()
                                                );
                                                console.log(id);
                                                if (result) {
                                                    local["DPA0341"] = items.vehicle_no;
                                                    local["DPA0140"] = id[0];
                                                    local["DPA0139"] = id[1];
                                                    local["DPA0141"] = id[2];
                                                    local["DPA0144"] = parseFloat(
                                                        items.km_travelled.toString()
                                                    );
                                                    local["DPA0339"] = id[3];
                                                    local["DPGMode"] = 6;
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    console.log(local);
                                                    local2.push(local);
                                                }
                                            } else if (
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "delivery"
                                            ) {
                                                const { result, id } = findMatchingIds(
                                                    category2,
                                                    items.vehicle_category.toString(),
                                                    items.vehicle_type.toString(),
                                                    items.fuel_type.toString(),
                                                    items.unit.toString()
                                                );

                                                if (result) {
                                                    local["DPA0341"] = items.vehicle_no;
                                                    local["DPA0140"] = id[0];
                                                    local["DPA0139"] = id[1];
                                                    local["DPA0141"] = id[2];
                                                    local["DPA0144"] = parseFloat(
                                                        items.km_travelled.toString()
                                                    );
                                                    local["DPA0339"] = id[3];
                                                    local["DPGMode"] = 7;
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    local2.push(local);
                                                }
                                            }
                                        }
                                    } else if (
                                        items.category.toString().toLowerCase() === "fuel"
                                    ) {
                                        console.log(typeof items.fuel_refill_date);
                                        let fuel_type_f = [
                                            { title: "Diesel", id: 13 },
                                            { title: "Petrol", id: 19 },
                                            { title: "CNG", id: 2 },
                                        ];
                                        let findex = fuel_type_f.findIndex(
                                            (x) =>
                                                x.title.trim().toLowerCase() ===
                                                items.fuel_type.toString().trim().toLowerCase()
                                        );
                                        if (
                                            findex !== -1 &&
                                            items.fuel_refill_date !== undefined &&
                                            typeof items.fuel_refill_date === "number" &&
                                            items.fuel_refilled !== undefined &&
                                            parseFloat(items.fuel_refilled) > 0
                                        ) {
                                            local["attachment"] = null;
                                            local["DP_MODE"] = false;
                                            local["DPA0341"] = items.vehicle_no;
                                            local["DPA0140"] = null;
                                            local["DPA0139"] = null;
                                            local["DPA0141"] = fuel_type_f[findex].id;
                                            local["DPA0142"] = moment(
                                                excelDateToJSDate(items.fuel_refill_date)
                                            )._d;
                                            local["DPA0143"] = parseFloat(
                                                items.fuel_refilled.toString()
                                            );
                                            local["DPA0339"] = null;
                                            local["id"] = getID();
                                            local2.push(local);
                                        }
                                    }

                                    // if (items.vehicle_category !== null && items.vehicle_category.toString().trim().length !== 0) {
                                    //     let typeind = mobile_combustion.findIndex((i) => { return i.vehicle_category === items.vehicle_category && i.vehicle_type.trim().toLowerCase() === items.vehicle_type.trim().toString().toLowerCase() })
                                    //     if (items.vehicle_type !== null && items.vehicle_type.toString().trim().length !== 0 && typeind !== -1) {
                                    //         let fuelind = mobile_combustion[typeind].fuel_type.findIndex((i) => { return i.name.toString().trim().toLowerCase() === items.fuel_type.trim().toString().toLowerCase() })
                                    //         if (items.fuel_type !== null && items.fuel_type.toString().trim().length !== 0 && fuelind !== -1) {
                                    //             if (items.vehicle_category.toString().toLowerCase().includes('hgv')) {
                                    //                 if (items.laden_percentage !== null && (items.laden_percentage.toString() === '0%' || items.laden_percentage.toString() === '50%' || items.laden_percentage.toString() === '100%' || items.laden_percentage.toString() === 'Average')) {
                                    //                     if (items.category === 'fuel' && (items.fuel_refilled !== null && parseInt(items.fuel_refilled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                         local['attachment'] = null
                                    //                         local['DP_MODE'] = false
                                    //                         local['DPA0341'] = items.vehicle_no
                                    //                         local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                         local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                         local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                         local['DPA0142'] = moment(excelDateToJSDate(items.fuel_refill_date))._d
                                    //                         local['DPA0143'] = items.fuel_refilled
                                    //                         local['DPA0340'] = { name: items.laden_percentage }
                                    //                         local['id'] = getID()
                                    //                         local2.push(local)

                                    //                     } else if (items.category === 'distance' && (items.km_travelled !== null && parseInt(items.km_travelled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                         if (items.distance_measurement_unit !== null && (items.distance_measurement_unit.toString().trim() === 'km' || items.distance_measurement_unit.toString().trim() === 'mi')) {
                                    //                             local['DPA0341'] = items.vehicle_no
                                    //                             local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                             local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                             local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                             local['DPA0144'] = items.km_travelled.toString()
                                    //                             local['DPA0339'] = { name: items.distance_measurement_unit }
                                    //                             local['DPA0340'] = { name: items.laden_percentage }
                                    //                             local['attachment'] = null
                                    //                             local['DP_MODE'] = true
                                    //                             local['id'] = getID()
                                    //                             local2.push(local)
                                    //                         }
                                    //                     }
                                    //                 }

                                    //             } else {
                                    //                 if (items.category === 'fuel' && (items.fuel_refilled !== null && parseInt(items.fuel_refilled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                     local['DPA0341'] = items.vehicle_no
                                    //                     local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                     local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                     local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                     local['DPA0142'] = moment(excelDateToJSDate(items.fuel_refill_date))._d
                                    //                     local['DPA0143'] = items.fuel_refilled.toString()
                                    //                     local['DPA0340'] = null
                                    //                     local['attachment'] = null
                                    //                     local['DP_MODE'] = false
                                    //                     local['id'] = getID()
                                    //                     local2.push(local)

                                    //                 } else if (items.category === 'distance' && (items.km_travelled !== null && parseInt(items.km_travelled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                     if (items.distance_measurement_unit !== null && (items.distance_measurement_unit.toString().trim() === 'km' || items.distance_measurement_unit.toString().trim() === 'mi')) {
                                    //                         local['DPA0341'] = items.vehicle_no
                                    //                         local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                         local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                         local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                         local['DPA0144'] = items.km_travelled
                                    //                         local['DPA0339'] = { name: items.distance_measurement_unit }
                                    //                         local['DPA0340'] = null
                                    //                         local['attachment'] = null
                                    //                         local['DP_MODE'] = true
                                    //                         local['id'] = getID()
                                    //                         local2.push(local)

                                    //                     }
                                    //                 }
                                    //             }

                                    //         }

                                    //     }

                                    // }
                                }
                            });
                        }
                    });

                    setMCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });

        e.options.clear();
    };


    const openNewMc = () => {
        setMcVal({
            DPAN1150: null, DPAN1151: null, DPAN1152: null, DPAN1153: null, DPAN1155: null, DPAN1154: null, DPAN1156: 0, DPAN1157: 0
        });
        setMCDialog(true);
        setSubmitted(false);
    };
    const saveMobileCombustion = () => {
        setSubmitted(true);
        let local = mcVal;
        let local2 = mcdata;
        if (mcVal.DPAN1150 !== null && mcVal.DPAN1151 !== null && mcVal.DPAN1152 !== null && (mcVal.DPAN1150 === 3 ? (mcVal.DPAN1155 !== null) : (mcVal.DPAN1151 === 316 || mcVal.DPAN1151 === 317) ? mcVal.DPAN1154 !== null : mcVal.DPAN1153 !== null) && parseFloat(mcVal.DPAN1156) >= 0 && parseFloat(mcVal.DPAN1157) >= 0) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setMCDialog(false)
            setSubmitted(false);
            setMCData(local2)
        }
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setMCFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const mcDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveMobileCombustion();
                }}
            />
        </>
    );
    const updateMcVal = (obj, val) => {
        let local = mcVal;
        let loccat = JSON.parse(JSON.stringify(category))
        let vehcat = [], vehtype = [], fueltype = [];
        if (obj === 'DPAN1150') {
            if (val === 1) {
                setVehCatList(loccat.slice(0, 3));
            } else if (val === 2) {
                setVehCatList(loccat.slice(3, 4));
            } else if (val === 3) {
                setVehCatList(loccat.slice(4, 5));
            } else if (val === 4) {
                setVehCatList(loccat.slice(5, 7));
            }
            local.DPAN1151 = null
            local.DPAN1152 = null
            local.DPAN1153 = null
            local.DPAN1154 = null
            local.DPAN1155 = null
        }
        if (obj === 'DPAN1151') {
            loccat.forEach((i) => {
                if (i.id === val && i.newEfSubcategory2s) {
                    vehtype = i.newEfSubcategory2s
                }
            })
            setVehTypeList(vehtype)
            local.DPAN1152 = null
            local.DPAN1153 = null
            local.DPAN1154 = null
            local.DPAN1155 = null
        }
        if (local.DPAN1150 === 3) {
            if (obj === 'DPAN1152') {
                loccat.forEach((i) => {
                    if (i.id === local.DPAN1151 && i.newEfSubcategory2s) {
                        i.newEfSubcategory2s.forEach((j) => {
                            if (j.id === val && j.newEfSubcategory3s) {
                                vehtype = j.newEfSubcategory3s
                            }
                        })
                    }
                })
                local.DPAN1153 = null
                local.DPAN1154 = null
                local.DPAN1155 = null
                setUnitList(vehtype)
            }

        } else {
            if (obj === 'DPAN1152') {
                loccat.forEach((i) => {
                    if (i.id === local.DPAN1151 && i.newEfSubcategory2s) {
                        i.newEfSubcategory2s.forEach((j) => {
                            if (j.id === val && j.newEfSubcategory3s) {
                                vehtype = j.newEfSubcategory3s
                            }
                        })
                    }
                })

                local.DPAN1153 = null
                local.DPAN1154 = null
                local.DPAN1155 = null
                setVehFuelList(vehtype)
            }
            if (obj === 'DPAN1153' || obj === 'DPAN1154') {
                loccat.forEach((i) => {
                    if (i.id === local.DPAN1151 && i.newEfSubcategory2s) {
                        i.newEfSubcategory2s.forEach((j) => {
                            if ((j.id === local.DPAN1152 || j.id === local.DPAN1154) && j.newEfSubcategory3s) {
                                j.newEfSubcategory3s.forEach((k) => {
                                    if (k.id === val && k.newEfSubcategory4s) {
                                        console.log(k)

                                        vehtype = k.newEfSubcategory4s
                                    }
                                })
                            }
                        })
                    }
                })
                local.DPAN1155 = null
                console.log(vehtype)
                setUnitList(vehtype)
            }
        }



        local[obj] = val;
        setMcVal(local);
        forceUpdate();
    };
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        let loccat = JSON.parse(JSON.stringify(category))
        if (items.DPAN1150) {
            let locvehcatlist = []
            if (items.DPAN1150 === 1) {
                locvehcatlist = loccat.slice(0, 3)
                setVehCatList(loccat.slice(0, 3));
            } else if (items.DPAN1150 === 2) {
                locvehcatlist = loccat.slice(3, 4)
                setVehCatList(loccat.slice(3, 4));
            } else if (items.DPAN1150 === 3) {
                locvehcatlist = loccat.slice(4, 5)
                setVehCatList(loccat.slice(4, 5));
            } else if (items.DPAN1150 === 4) {
                locvehcatlist = loccat.slice(5, 7)
                setVehCatList(loccat.slice(5, 7));
            }

            if (items.DPAN1151) {
                let index1 = loccat.findIndex(i => i.id === items.DPAN1151)
                if (index1 !== -1) {
                    setVehTypeList(loccat[index1].newEfSubcategory2s)
                    if (items.DPAN1150 !== 3) {
                        let index2 = loccat[index1].newEfSubcategory2s.findIndex(i => i.id === items.DPAN1152)
                        if (index2 !== -2) {
                            setVehFuelList(loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s)
                            if (items.DPAN1151 === 316 || items.DPAN1151 === 317) {
                                let index3 = loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s.findIndex(i => i.id === items.DPAN1154)
                                if (index3 !== -1) {
                                    setUnitList(loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].newEfSubcategory4s)
                                }

                            } else {
                                let index3 = loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s.findIndex(i => i.id === items.DPAN1153)
                                if (index3 !== -1) {
                                    setUnitList(loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].newEfSubcategory4s)
                                }

                            }

                        }

                    } else {
                        let index2 = loccat[index1].newEfSubcategory2s.findIndex(i => i.id === items.DPAN1152)

                        if (index2 !== -2) {
                            setUnitList(loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s)
                        }
                    }



                }

            }

        }
        setMcVal(items);
        console.log(vehcatlist, vehfuellist, unitlist);
        setMCDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = mcdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setMCData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const mcAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const vehicleCatTemplate = (rowData) => {
        let text = "NA";

        let index = category.findIndex((i) => i.id === rowData.DPAN1151);
        if (index !== -1) {
            text = category[index].title;
        }

        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleTypeTemplate = (rowData) => {
        let text = "NA";

        let index = category.findIndex((i) => i.id === rowData.DPAN1151);
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex(
                (i) => i.id === rowData.DPAN1152
            );
            if (index2 !== -1) {
                text = category[index].newEfSubcategory2s[index2].title;
            }
        }


        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleFuelTemplate = (rowData) => {
        let text = "NA";

        let index = category.findIndex((i) => i.id === rowData.DPAN1151);
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex(
                (i) => i.id === rowData.DPAN1152
            );
            if (
                index2 !== -1 &&
                category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                undefined
            ) {
                if (rowData.DPAN1150 === 3) {
                    let index3 = category[index].newEfSubcategory2s[
                        index2
                    ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPAN1155);
                    if (index3 !== -1) {
                        text =
                            category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                index3
                            ].title;
                    }
                } else {
                    if (rowData.DPAN1151 === 316 || rowData.DPAN1151 === 317) {
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPAN1154);
                        if (index3 !== -1) {
                            let index4 = category[index].newEfSubcategory2s[
                                index2
                            ].newEfSubcategory3s[index3].newEfSubcategory4s.findIndex((i) => i.id === rowData.DPAN1155);
                            if (index4 !== -1) {
                                text = category[index].newEfSubcategory2s[
                                    index2
                                ].newEfSubcategory3s[index3].newEfSubcategory4s[index4].title
                            }


                        }
                    } else {
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPAN1153);
                        if (index3 !== -1) {
                            let index4 = category[index].newEfSubcategory2s[
                                index2
                            ].newEfSubcategory3s[index3].newEfSubcategory4s.findIndex((i) => i.id === rowData.DPAN1155);
                            if (index4 !== -1) {
                                text = category[index].newEfSubcategory2s[
                                    index2
                                ].newEfSubcategory3s[index3].newEfSubcategory4s[index4].title
                            }
                        }
                    }
                }

            }
        }


        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}

            <DataTable
                value={mcdata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={mcFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Mode of Transport of goods"
                    body={(rowData) => {
                        return <span>{mode.find(i => i.id === rowData.DPAN1150).name}</span>;
                    }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0140.name"
                    header="Type of vehicle used"
                    body={vehicleCatTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0139.name"
                    header="Category  of Vehicle used"
                    body={vehicleTypeTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0141.name"
                    header="Unit of Measurement"
                    body={vehicleFuelTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN1156"
                    header="Total Qunatity of Goods"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN1157"
                    header="Total Distance of transportation"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={mcDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={mcDialogFooter}
                onHide={() => {
                    setMCDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr"> Mode of Transport of goods</label>
                    <Dropdown
                        value={mcVal.DPAN1150}
                        onChange={(e) => {
                            updateMcVal("DPAN1150", e.value);
                        }}
                        options={mode}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && mcVal.DPAN1150 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select Mode
                        </small>
                    )}
                </div>
                {mcVal.DPAN1150 !== null &&
                    <div>

                        <div className="field">
                            <label htmlFor="ecpsngr"> Type of vehicle used</label>
                            <Dropdown
                                value={mcVal.DPAN1151}
                                onChange={(e) => {
                                    updateMcVal("DPAN1151", e.value);
                                }}
                                options={vehcatlist}
                                optionLabel="title"
                                optionValue="id"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPAN1151 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Used
                                </small>
                            )}
                        </div>

                        {mcVal.DPAN1151 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Category  of Vehicle used </label>
                                <Dropdown
                                    value={mcVal.DPAN1152}
                                    onChange={(e) => {
                                        updateMcVal("DPAN1152", e.value);
                                    }}
                                    options={vehtypelist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPAN1152 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Category
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPAN1152 !== null && mcVal.DPAN1150 !== 3 && (mcVal.DPAN1151 !== 316 && mcVal.DPAN1151 !== 317) && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Type of Fuel/Vehicle size used </label>
                                <Dropdown
                                    value={mcVal.DPAN1153}
                                    onChange={(e) => {
                                        updateMcVal("DPAN1153", e.value);
                                    }}
                                    options={vehfuellist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPAN1153 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Category
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPAN1152 !== null && mcVal.DPAN1150 !== 3 && (mcVal.DPAN1151 === 316 || mcVal.DPAN1151 === 317) && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> % of Laden  </label>
                                <Dropdown
                                    value={mcVal.DPAN1154}
                                    onChange={(e) => {
                                        updateMcVal("DPAN1154", e.value);
                                    }}
                                    options={vehfuellist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPAN1154 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Laden %
                                    </small>
                                )}
                            </div>
                        )}
                        {(mcVal.DPAN1150 === 3 ? mcVal.DPAN1152 !== null : (mcVal.DPAN1153 !== null || mcVal.DPAN1154 !== null)) && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Unit of Measurement </label>
                                <Dropdown
                                    value={mcVal.DPAN1155}
                                    onChange={(e) => {
                                        updateMcVal("DPAN1155", e.value);
                                    }}
                                    options={unitlist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPAN1155 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Category
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPAN1155 !== null && (
                            <div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="gasrefill">
                                            Total Quantity of Goods
                                        </label>
                                        <div id="gasrefill" className="p-inputgroup">
                                            <InputNumber

                                                useGrouping={false}
                                                value={mcVal.DPAN1156}
                                                onChange={(e) => {
                                                    updateMcVal("DPAN1156", e.value);
                                                }}
                                            />
                                        </div>
                                        {submitted && parseFloat(mcVal.DPAN1156) < 0 && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Total Quantity of Goods is required
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="gasrefill1">
                                            Total Distance of transportation
                                        </label>
                                        <div id="gasrefill1" className="p-inputgroup">
                                            <InputNumber

                                                useGrouping={false}
                                                value={mcVal.DPAN1157}
                                                onChange={(e) => {
                                                    updateMcVal("DPAN1157", e.value);
                                                }}
                                            />
                                        </div>
                                        {submitted && parseFloat(mcVal.DPAN1157) < 0 && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Total Distance is required
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                }



            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};

const Downstream_Trans_Dist = ({ data, getData, setData, edit, standard, dates = { minDate: null, maxDate: null } }) => {
    const [mcdata, setMCData] = useState(data);
    const [mcDialog, setMCDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [attachment, setAttachment] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [mcFilter, setMCFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [category, setCategory] = useState([]);
    const [category2, setCategory2] = useState([]);

    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [unitlist, setUnitList] = useState([]);
    const mode = [{ name: 'Road', id: 1 }, { name: 'Air', id: 2 }, { name: 'Rail', id: 3 }, { name: 'Sea', id: 4 }]
    const [mcVal, setMcVal] = useState({
        DPAN1208: null, DPAN1209: null, DPAN1210: null, DPAN1211: null, DPAN1213: null, DPAN1212: null, DPAN1214: 0, DPAN1215: 0

    });

    useEffect(() => {
        let veh_cat = [];
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 42);

                        console.log(cat_index);
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }

                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    getData(mcdata)
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }

    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Mobile Combustion Emissions") {
                    let local = {};
                    let local2 = mcdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_OV") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                console.log(items);
                                if (
                                    items.fuel_type !== undefined &&
                                    items.fuel_type.toString().length !== 0 &&
                                    items.category !== undefined &&
                                    items.vehicle_mode !== undefined &&
                                    (items.vehicle_mode.toString().trim().toLowerCase() ===
                                        "passenger" ||
                                        items.vehicle_mode.toString().trim().toLowerCase() ===
                                        "delivery") &&
                                    items.vehicle_no !== undefined &&
                                    items.vehicle_no.toString().trim().length !== 0 &&
                                    items.category.toString().trim().length !== 0 &&
                                    (items.category.toString().toLowerCase() === "fuel" ||
                                        items.category.toString().toLowerCase() === "distance")
                                ) {
                                    if (items.category.toString().toLowerCase() === "distance") {
                                        console.log(items);
                                        if (
                                            items.unit !== undefined &&
                                            items.unit.toString().trim().length !== 0 &&
                                            items.km_travelled !== undefined &&
                                            parseFloat(items.km_travelled.toString()) > 0 &&
                                            items.vehicle_type !== undefined &&
                                            items.vehicle_type.toString().length !== 0 &&
                                            items.vehicle_category !== undefined &&
                                            items.vehicle_category.toString().length !== 0
                                        ) {
                                            if (
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "passenger"
                                            ) {
                                                const { result, id } = findMatchingIds(
                                                    category,
                                                    items.vehicle_category.toString(),
                                                    items.vehicle_type.toString(),
                                                    items.fuel_type.toString(),
                                                    items.unit.toString()
                                                );
                                                console.log(id);
                                                if (result) {
                                                    local["DPA0341"] = items.vehicle_no;
                                                    local["DPA0140"] = id[0];
                                                    local["DPA0139"] = id[1];
                                                    local["DPA0141"] = id[2];
                                                    local["DPA0144"] = parseFloat(
                                                        items.km_travelled.toString()
                                                    );
                                                    local["DPA0339"] = id[3];
                                                    local["DPGMode"] = 6;
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    console.log(local);
                                                    local2.push(local);
                                                }
                                            } else if (
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "delivery"
                                            ) {
                                                const { result, id } = findMatchingIds(
                                                    category2,
                                                    items.vehicle_category.toString(),
                                                    items.vehicle_type.toString(),
                                                    items.fuel_type.toString(),
                                                    items.unit.toString()
                                                );

                                                if (result) {
                                                    local["DPA0341"] = items.vehicle_no;
                                                    local["DPA0140"] = id[0];
                                                    local["DPA0139"] = id[1];
                                                    local["DPA0141"] = id[2];
                                                    local["DPA0144"] = parseFloat(
                                                        items.km_travelled.toString()
                                                    );
                                                    local["DPA0339"] = id[3];
                                                    local["DPGMode"] = 7;
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    local2.push(local);
                                                }
                                            }
                                        }
                                    } else if (
                                        items.category.toString().toLowerCase() === "fuel"
                                    ) {
                                        console.log(typeof items.fuel_refill_date);
                                        let fuel_type_f = [
                                            { title: "Diesel", id: 13 },
                                            { title: "Petrol", id: 19 },
                                            { title: "CNG", id: 2 },
                                        ];
                                        let findex = fuel_type_f.findIndex(
                                            (x) =>
                                                x.title.trim().toLowerCase() ===
                                                items.fuel_type.toString().trim().toLowerCase()
                                        );
                                        if (
                                            findex !== -1 &&
                                            items.fuel_refill_date !== undefined &&
                                            typeof items.fuel_refill_date === "number" &&
                                            items.fuel_refilled !== undefined &&
                                            parseFloat(items.fuel_refilled) > 0
                                        ) {
                                            local["attachment"] = null;
                                            local["DP_MODE"] = false;
                                            local["DPA0341"] = items.vehicle_no;
                                            local["DPA0140"] = null;
                                            local["DPA0139"] = null;
                                            local["DPA0141"] = fuel_type_f[findex].id;
                                            local["DPA0142"] = moment(
                                                excelDateToJSDate(items.fuel_refill_date)
                                            )._d;
                                            local["DPA0143"] = parseFloat(
                                                items.fuel_refilled.toString()
                                            );
                                            local["DPA0339"] = null;
                                            local["id"] = getID();
                                            local2.push(local);
                                        }
                                    }

                                    // if (items.vehicle_category !== null && items.vehicle_category.toString().trim().length !== 0) {
                                    //     let typeind = mobile_combustion.findIndex((i) => { return i.vehicle_category === items.vehicle_category && i.vehicle_type.trim().toLowerCase() === items.vehicle_type.trim().toString().toLowerCase() })
                                    //     if (items.vehicle_type !== null && items.vehicle_type.toString().trim().length !== 0 && typeind !== -1) {
                                    //         let fuelind = mobile_combustion[typeind].fuel_type.findIndex((i) => { return i.name.toString().trim().toLowerCase() === items.fuel_type.trim().toString().toLowerCase() })
                                    //         if (items.fuel_type !== null && items.fuel_type.toString().trim().length !== 0 && fuelind !== -1) {
                                    //             if (items.vehicle_category.toString().toLowerCase().includes('hgv')) {
                                    //                 if (items.laden_percentage !== null && (items.laden_percentage.toString() === '0%' || items.laden_percentage.toString() === '50%' || items.laden_percentage.toString() === '100%' || items.laden_percentage.toString() === 'Average')) {
                                    //                     if (items.category === 'fuel' && (items.fuel_refilled !== null && parseInt(items.fuel_refilled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                         local['attachment'] = null
                                    //                         local['DP_MODE'] = false
                                    //                         local['DPA0341'] = items.vehicle_no
                                    //                         local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                         local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                         local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                         local['DPA0142'] = moment(excelDateToJSDate(items.fuel_refill_date))._d
                                    //                         local['DPA0143'] = items.fuel_refilled
                                    //                         local['DPA0340'] = { name: items.laden_percentage }
                                    //                         local['id'] = getID()
                                    //                         local2.push(local)

                                    //                     } else if (items.category === 'distance' && (items.km_travelled !== null && parseInt(items.km_travelled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                         if (items.distance_measurement_unit !== null && (items.distance_measurement_unit.toString().trim() === 'km' || items.distance_measurement_unit.toString().trim() === 'mi')) {
                                    //                             local['DPA0341'] = items.vehicle_no
                                    //                             local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                             local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                             local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                             local['DPA0144'] = items.km_travelled.toString()
                                    //                             local['DPA0339'] = { name: items.distance_measurement_unit }
                                    //                             local['DPA0340'] = { name: items.laden_percentage }
                                    //                             local['attachment'] = null
                                    //                             local['DP_MODE'] = true
                                    //                             local['id'] = getID()
                                    //                             local2.push(local)
                                    //                         }
                                    //                     }
                                    //                 }

                                    //             } else {
                                    //                 if (items.category === 'fuel' && (items.fuel_refilled !== null && parseInt(items.fuel_refilled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                     local['DPA0341'] = items.vehicle_no
                                    //                     local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                     local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                     local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                     local['DPA0142'] = moment(excelDateToJSDate(items.fuel_refill_date))._d
                                    //                     local['DPA0143'] = items.fuel_refilled.toString()
                                    //                     local['DPA0340'] = null
                                    //                     local['attachment'] = null
                                    //                     local['DP_MODE'] = false
                                    //                     local['id'] = getID()
                                    //                     local2.push(local)

                                    //                 } else if (items.category === 'distance' && (items.km_travelled !== null && parseInt(items.km_travelled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                     if (items.distance_measurement_unit !== null && (items.distance_measurement_unit.toString().trim() === 'km' || items.distance_measurement_unit.toString().trim() === 'mi')) {
                                    //                         local['DPA0341'] = items.vehicle_no
                                    //                         local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                         local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                         local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                         local['DPA0144'] = items.km_travelled
                                    //                         local['DPA0339'] = { name: items.distance_measurement_unit }
                                    //                         local['DPA0340'] = null
                                    //                         local['attachment'] = null
                                    //                         local['DP_MODE'] = true
                                    //                         local['id'] = getID()
                                    //                         local2.push(local)

                                    //                     }
                                    //                 }
                                    //             }

                                    //         }

                                    //     }

                                    // }
                                }
                            });
                        }
                    });

                    setMCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });

        e.options.clear();
    };


    const openNewMc = () => {
        setMcVal({
            DPAN1208: null, DPAN1209: null, DPAN1210: null, DPAN1211: null, DPAN1213: null, DPAN1212: null, DPAN1214: 0, DPAN1215: 0
        });
        setMCDialog(true);
        setSubmitted(false);
    };
    const saveMobileCombustion = () => {
        setSubmitted(true);
        let local = mcVal;
        let local2 = mcdata;
        if (mcVal.DPAN1208 !== null && mcVal.DPAN1209 !== null && mcVal.DPAN1210 !== null && (mcVal.DPAN1208 === 3 ? (mcVal.DPAN1213 !== null) : (mcVal.DPAN1209 === 316 || mcVal.DPAN1209 === 317) ? mcVal.DPAN1212 !== null : mcVal.DPAN1211 !== null) && parseFloat(mcVal.DPAN1214) >= 0 && parseFloat(mcVal.DPAN1215) >= 0) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setMCDialog(false)
            setSubmitted(false);
            setMCData(local2)
        }
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setMCFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const mcDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveMobileCombustion();
                }}
            />
        </>
    );
    const updateMcVal = (obj, val) => {
        let local = mcVal;
        let loccat = JSON.parse(JSON.stringify(category))
        let vehcat = [], vehtype = [], fueltype = [];
        if (obj === 'DPAN1208') {
            if (val === 1) {
                setVehCatList(loccat.slice(0, 3));
            } else if (val === 2) {
                setVehCatList(loccat.slice(3, 4));
            } else if (val === 3) {
                setVehCatList(loccat.slice(4, 5));
            } else if (val === 4) {
                setVehCatList(loccat.slice(5, 7));
            }
            local.DPAN1209 = null
            local.DPAN1210 = null
            local.DPAN1211 = null
            local.DPAN1212 = null
            local.DPAN1213 = null
        }
        if (obj === 'DPAN1209') {
            loccat.forEach((i) => {
                if (i.id === val && i.newEfSubcategory2s) {
                    vehtype = i.newEfSubcategory2s
                }
            })
            setVehTypeList(vehtype)
            local.DPAN1210 = null
            local.DPAN1211 = null
            local.DPAN1212 = null
            local.DPAN1213 = null
        }
        if (local.DPAN1208 === 3) {
            if (obj === 'DPAN1210') {
                loccat.forEach((i) => {
                    if (i.id === local.DPAN1209 && i.newEfSubcategory2s) {
                        i.newEfSubcategory2s.forEach((j) => {
                            if (j.id === val && j.newEfSubcategory3s) {
                                vehtype = j.newEfSubcategory3s
                            }
                        })
                    }
                })
                local.DPAN1211 = null
                local.DPAN1212 = null
                local.DPAN1213 = null
                setUnitList(vehtype)
            }

        } else {
            if (obj === 'DPAN1210') {
                loccat.forEach((i) => {
                    if (i.id === local.DPAN1209 && i.newEfSubcategory2s) {
                        i.newEfSubcategory2s.forEach((j) => {
                            if (j.id === val && j.newEfSubcategory3s) {
                                vehtype = j.newEfSubcategory3s
                            }
                        })
                    }
                })

                local.DPAN1211 = null
                local.DPAN1212 = null
                local.DPAN1213 = null
                setVehFuelList(vehtype)
            }
            if (obj === 'DPAN1211' || obj === 'DPAN1212') {
                loccat.forEach((i) => {
                    if (i.id === local.DPAN1209 && i.newEfSubcategory2s) {
                        i.newEfSubcategory2s.forEach((j) => {
                            if ((j.id === local.DPAN1210 || j.id === local.DPAN1212) && j.newEfSubcategory3s) {
                                j.newEfSubcategory3s.forEach((k) => {
                                    if (k.id === val && k.newEfSubcategory4s) {
                                        console.log(k)

                                        vehtype = k.newEfSubcategory4s
                                    }
                                })
                            }
                        })
                    }
                })
                local.DPAN1213 = null
                console.log(vehtype)
                setUnitList(vehtype)
            }
        }



        local[obj] = val;
        setMcVal(local);
        forceUpdate();
    };
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        let loccat = JSON.parse(JSON.stringify(category))
        if (items.DPAN1208) {
            let locvehcatlist = []
            if (items.DPAN1208 === 1) {
                locvehcatlist = loccat.slice(0, 3)
                setVehCatList(loccat.slice(0, 3));
            } else if (items.DPAN1208 === 2) {
                locvehcatlist = loccat.slice(3, 4)
                setVehCatList(loccat.slice(3, 4));
            } else if (items.DPAN1208 === 3) {
                locvehcatlist = loccat.slice(4, 5)
                setVehCatList(loccat.slice(4, 5));
            } else if (items.DPAN1208 === 4) {
                locvehcatlist = loccat.slice(5, 7)
                setVehCatList(loccat.slice(5, 7));
            }

            if (items.DPAN1209) {
                let index1 = loccat.findIndex(i => i.id === items.DPAN1209)
                if (index1 !== -1) {
                    setVehTypeList(loccat[index1].newEfSubcategory2s)
                    if (items.DPAN1208 !== 3) {
                        let index2 = loccat[index1].newEfSubcategory2s.findIndex(i => i.id === items.DPAN1210)
                        if (index2 !== -2) {
                            setVehFuelList(loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s)
                            if (items.DPAN1209 === 316 || items.DPAN1209 === 317) {
                                let index3 = loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s.findIndex(i => i.id === items.DPAN1212)
                                if (index3 !== -1) {
                                    setUnitList(loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].newEfSubcategory4s)
                                }

                            } else {
                                let index3 = loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s.findIndex(i => i.id === items.DPAN1211)
                                if (index3 !== -1) {
                                    setUnitList(loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].newEfSubcategory4s)
                                }

                            }

                        }

                    } else {
                        let index2 = loccat[index1].newEfSubcategory2s.findIndex(i => i.id === items.DPAN1210)

                        if (index2 !== -2) {
                            setUnitList(loccat[index1].newEfSubcategory2s[index2].newEfSubcategory3s)
                        }
                    }



                }

            }

        }
        setMcVal(items);
        console.log(vehcatlist, vehfuellist, unitlist);
        setMCDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = mcdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setMCData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const mcAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const vehicleCatTemplate = (rowData) => {
        let text = "NA";

        let index = category.findIndex((i) => i.id === rowData.DPAN1209);
        if (index !== -1) {
            text = category[index].title;
        }

        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleTypeTemplate = (rowData) => {
        let text = "NA";

        let index = category.findIndex((i) => i.id === rowData.DPAN1209);
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex(
                (i) => i.id === rowData.DPAN1210
            );
            if (index2 !== -1) {
                text = category[index].newEfSubcategory2s[index2].title;
            }
        }


        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleFuelTemplate = (rowData) => {
        let text = "NA";

        let index = category.findIndex((i) => i.id === rowData.DPAN1209);
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex(
                (i) => i.id === rowData.DPAN1210
            );
            if (
                index2 !== -1 &&
                category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                undefined
            ) {
                if (rowData.DPAN1208 === 3) {
                    let index3 = category[index].newEfSubcategory2s[
                        index2
                    ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPAN1213);
                    if (index3 !== -1) {
                        text =
                            category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                index3
                            ].title;
                    }
                } else {
                    if (rowData.DPAN1209 === 316 || rowData.DPAN1209 === 317) {
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPAN1212);
                        if (index3 !== -1) {
                            let index4 = category[index].newEfSubcategory2s[
                                index2
                            ].newEfSubcategory3s[index3].newEfSubcategory4s.findIndex((i) => i.id === rowData.DPAN1213);
                            if (index4 !== -1) {
                                text = category[index].newEfSubcategory2s[
                                    index2
                                ].newEfSubcategory3s[index3].newEfSubcategory4s[index4].title
                            }


                        }
                    } else {
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPAN1211);
                        if (index3 !== -1) {
                            let index4 = category[index].newEfSubcategory2s[
                                index2
                            ].newEfSubcategory3s[index3].newEfSubcategory4s.findIndex((i) => i.id === rowData.DPAN1213);
                            if (index4 !== -1) {
                                text = category[index].newEfSubcategory2s[
                                    index2
                                ].newEfSubcategory3s[index3].newEfSubcategory4s[index4].title
                            }
                        }
                    }
                }

            }
        }


        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={mcdata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={mcFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Mode of Transport of goods"
                    body={(rowData) => {
                        return <span>{mode.find(i => i.id === rowData.DPAN1208).name}</span>;
                    }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0140.name"
                    header="Type of vehicle used"
                    body={vehicleCatTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0139.name"
                    header="Category  of Vehicle used"
                    body={vehicleTypeTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0141.name"
                    header="Unit of Measurement"
                    body={vehicleFuelTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN1214"
                    header="Total Qunatity of Goods"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN1215"
                    header="Total Distance of transportation"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={mcDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={mcDialogFooter}
                onHide={() => {
                    setMCDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr"> Mode of Transport of goods</label>
                    <Dropdown
                        value={mcVal.DPAN1208}
                        onChange={(e) => {
                            updateMcVal("DPAN1208", e.value);
                        }}
                        options={mode}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && mcVal.DPAN1208 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select Mode
                        </small>
                    )}
                </div>
                {mcVal.DPAN1208 !== null &&
                    <div>

                        <div className="field">
                            <label htmlFor="ecpsngr"> Type of vehicle used</label>
                            <Dropdown
                                value={mcVal.DPAN1209}
                                onChange={(e) => {
                                    updateMcVal("DPAN1209", e.value);
                                }}
                                options={vehcatlist}
                                optionLabel="title"
                                optionValue="id"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPAN1209 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Used
                                </small>
                            )}
                        </div>

                        {mcVal.DPAN1209 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Category  of Vehicle used </label>
                                <Dropdown
                                    value={mcVal.DPAN1210}
                                    onChange={(e) => {
                                        updateMcVal("DPAN1210", e.value);
                                    }}
                                    options={vehtypelist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPAN1210 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Category
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPAN1210 !== null && mcVal.DPAN1208 !== 3 && (mcVal.DPAN1209 !== 316 && mcVal.DPAN1209 !== 317) && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Type of Fuel/Vehicle size used </label>
                                <Dropdown
                                    value={mcVal.DPAN1211}
                                    onChange={(e) => {
                                        updateMcVal("DPAN1211", e.value);
                                    }}
                                    options={vehfuellist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPAN1211 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Category
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPAN1210 !== null && mcVal.DPAN1208 !== 3 && (mcVal.DPAN1209 === 316 || mcVal.DPAN1209 === 317) && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> % of Laden  </label>
                                <Dropdown
                                    value={mcVal.DPAN1212}
                                    onChange={(e) => {
                                        updateMcVal("DPAN1212", e.value);
                                    }}
                                    options={vehfuellist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPAN1212 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Laden %
                                    </small>
                                )}
                            </div>
                        )}
                        {(mcVal.DPAN1208 === 3 ? mcVal.DPAN1210 !== null : (mcVal.DPAN1211 !== null || mcVal.DPAN1212 !== null)) && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Unit of Measurement </label>
                                <Dropdown
                                    value={mcVal.DPAN1213}
                                    onChange={(e) => {
                                        updateMcVal("DPAN1213", e.value);
                                    }}
                                    options={unitlist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPAN1213 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Category
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPAN1213 !== null && (
                            <div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="gasrefill">
                                            Total Quantity of Goods
                                        </label>
                                        <div id="gasrefill" className="p-inputgroup">
                                            <InputNumber

                                                useGrouping={false}
                                                value={mcVal.DPAN1214}
                                                onChange={(e) => {
                                                    updateMcVal("DPAN1214", e.value);
                                                }}
                                            />
                                        </div>
                                        {submitted && parseFloat(mcVal.DPAN1214) < 0 && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Total Quantity of Goods is required
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="gasrefill1">
                                            Total Distance of transportation
                                        </label>
                                        <div id="gasrefill1" className="p-inputgroup">
                                            <InputNumber

                                                useGrouping={false}
                                                value={mcVal.DPAN1215}
                                                onChange={(e) => {
                                                    updateMcVal("DPAN1215", e.value);
                                                }}
                                            />
                                        </div>
                                        {submitted && parseFloat(mcVal.DPAN1215) < 0 && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Total Distance is required
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                }



            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Employee_Demographics_263 = ({ data, extractedData = () => { }, getData, setData, isValidResponse, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPAN600: 0,
        DPAN601: 0,
        DPAN602: 0,
        DPAN628: 0,
        DPAN629: 0,
        DPAN630: 0,
        DPAN603: 0,
        DPAN604: 0,
        DPAN605: 0,
        DPAN606: 0,
        DPAN607: 0,
        DPAN608: 0,
        DPAN609: 0,
        DPAN610: 0,
        DPAN611: 0,
        DPAN612: 0,
        DPAN613: 0,
        DPAN614: 0,
        DPAN615: 0,
        DPAN616: 0,
        DPAN617: 0,
        DPAN618: 0,
        DPAN619: 0,
        DPAN620: 0,
        DPAN621: 0,
        DPAN622: 0,
        DPAN623: 0,
        DPAN624: 0,
        DPAN625: 0,
        DPAN626: 0,
        DPAN627: 0,
        remarks: ''
    }]

    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        console.log(saveEmpCatDiversity())
        setData();
        isValidResponse(saveEmpCatDiversity())
    }, [emp]);
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(data));
        } else {
            console.error("extractedData is not a function");
        }
    }, [data]);

    function extractData(data) {
        const result = [
            {
                maskId: "DPAN600",
                uniqueId: "DPAN600",
                title: "Permanent Employees-Male",
                label: "Permanent Employees-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN600,
                uom: "nos",
            },
            {
                maskId: "DPAN601",
                uniqueId: "DPAN601",
                title: "Permanent Employees-Female",
                label: "Permanent Employees-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN601,
                uom: "nos",
            },
            {
                maskId: "DPAN602",
                uniqueId: "DPAN602",
                title: "Temporary Employees (Contract Workers)-Male",
                label: "Temporary Employees (Contract Workers)-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN602,
                uom: "nos",
            },
            {
                maskId: "DPAN603",
                uniqueId: "DPAN603",
                title: "Temporary Employees (Contract Workers)-Female",
                label: "Temporary Employees (Contract Workers)-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN603,
                uom: "nos",
            },
            {
                maskId: "DPAN604",
                uniqueId: "DPAN604",
                title: "Full-time Employees-Male",
                label: "Full-time Employees-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN604,
                uom: "nos",
            },
            {
                maskId: "DPAN605",
                uniqueId: "DPAN605",
                title: "Full-time Employees-Female",
                label: "Full-time Employees-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN605,
                uom: "nos",
            },
            {
                maskId: "DPAN606",
                uniqueId: "DPAN606",
                title: "Part-time Employees-Male",
                label: "Part-time Employees-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN606,
                uom: "nos",
            },
            {
                maskId: "DPAN607",
                uniqueId: "DPAN607",
                title: "Part-time Employees-Female",
                label: "Part-time Employees-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN607,
                uom: "nos",
            },
            {
                maskId: "DPAN608",
                uniqueId: "DPAN608",
                title: "Non-guaranteed Hours Employees-Male",
                label: "Non-guaranteed Hours Employees-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN608,
                uom: "nos",
            },
            {
                maskId: "DPAN609",
                uniqueId: "DPAN609",
                title: "Non-guaranteed Hours Employees-Female",
                label: "Non-guaranteed Hours Employees-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN609,
                uom: "nos",
            },
            {
                maskId: "DPAN610",
                uniqueId: "DPAN610",
                title: "Employees who are < 30 years old-Male",
                label: "Employees who are < 30 years old-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN610,
                uom: "nos",
            },
            {
                maskId: "DPAN628",
                uniqueId: "DPAN628",
                title: "Employees who are < 30 years old-Female",
                label: "Employees who are < 30 years old-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN628,
                uom: "nos",
            },
            {
                maskId: "DPAN611",
                uniqueId: "DPAN611",
                title: "Employees who are 30-50 years old-Male",
                label: "Employees who are 30-50 years old-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN611,
                uom: "nos",
            },
            {
                maskId: "DPAN629",
                uniqueId: "DPAN629",
                title: "Employees who are 30-50 years old-Female",
                label: "Employees who are 30-50 years old-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN629,
                uom: "nos",
            },
            {
                maskId: "DPAN612",
                uniqueId: "DPAN612",
                title: "Employees who are >50 years old-Male",
                label: "Employees who are >50 years old-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN612,
                uom: "nos",
            },
            {
                maskId: "DPAN630",
                uniqueId: "DPAN630",
                title: "Employees who are >50 years old-Female",
                label: "Employees who are >50 years old-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN630,
                uom: "nos",
            },
            {
                maskId: "DPAN613",
                uniqueId: "DPAN613",
                title: "Senior Management-Male",
                label: "Senior Management-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN613,
                uom: "nos",
            },
            {
                maskId: "DPAN614",
                uniqueId: "DPAN614",
                title: "Senior Management-Female",
                label: "Senior Management-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN614,
                uom: "nos",
            },
            {
                maskId: "DPAN615",
                uniqueId: "DPAN615",
                title: "Middle Management-Male",
                label: "Middle Management-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN615,
                uom: "nos",
            },
            {
                maskId: "DPAN616",
                uniqueId: "DPAN616",
                title: "Middle Management-Female",
                label: "Middle Management-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN616,
                uom: "nos",
            },
            {
                maskId: "DPAN617",
                uniqueId: "DPAN617",
                title: "Non Management-Male",
                label: "Non Management-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN617,
                uom: "nos",
            },
            {
                maskId: "DPAN618",
                uniqueId: "DPAN618",
                title: "Non Management-Female",
                label: "Non Management-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN618,
                uom: "nos",
            },
            {
                maskId: "DPAN619",
                uniqueId: "DPAN619",
                title: "Senior Management < 30years",
                label: "Senior Management < 30years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN619,
                uom: "nos",
            },
            {
                maskId: "DPAN620",
                uniqueId: "DPAN620",
                title: "Senior Management 30-50 years",
                label: "Senior Management 30-50 years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN620,
                uom: "nos",
            },
            {
                maskId: "DPAN621",
                uniqueId: "DPAN621",
                title: "Senior Management > 50 years",
                label: "Senior Management > 50 years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN621,
                uom: "nos",
            },
            {
                maskId: "DPAN622",
                uniqueId: "DPAN622",
                title: "Middle Management < 30 years",
                label: "Middle Management < 30 years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN622,
                uom: "nos",
            },
            {
                maskId: "DPAN623",
                uniqueId: "DPAN623",
                title: "Middle Management 30-50 years",
                label: "Middle Management 30-50 years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN623,
                uom: "nos",
            },
            {
                maskId: "DPAN624",
                uniqueId: "DPAN624",
                title: "Middle Management > 50 years",
                label: "Middle Management > 50 years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN624,
                uom: "nos",
            },
            {
                maskId: "DPAN625",
                uniqueId: "DPAN625",
                title: "Non Management < 30 years",
                label: "Non Management < 30 years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN625,
                uom: "nos",
            },
            {
                maskId: "DPAN626",
                uniqueId: "DPAN626",
                title: "Non Management 30-50 years",
                label: "Non Management 30-50 years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN626,
                uom: "nos",
            },
            {
                maskId: "DPAN627",
                uniqueId: "DPAN627",
                title: "Non Management > 50 years",
                label: "Non Management > 50 years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN627,
                uom: "nos",
            },
        ];
        console.log(result, "RESULT");
        return result;
    }
    getData(emp);
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            if (obj === 'remarks') {
                loc[0][obj] = ''
            } else {
                loc[0][obj] = 0
            }

        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };

    const saveEmpCatDiversity = () => {
        let set1 = emp[0].DPAN600 + emp[0].DPAN601 + emp[0].DPAN602 + emp[0].DPAN603
        let set2 = emp[0].DPAN604 + emp[0].DPAN605 + emp[0].DPAN606 + emp[0].DPAN607 + emp[0].DPAN608 + emp[0].DPAN609
        let set3 = emp[0].DPAN610 + emp[0].DPAN611 + emp[0].DPAN612 + emp[0].DPAN628 + emp[0].DPAN629 + emp[0].DPAN630
        let set4 = emp[0].DPAN613 + emp[0].DPAN614 + emp[0].DPAN615 + emp[0].DPAN616 + emp[0].DPAN617 + emp[0].DPAN618
        let set5 = emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621 + emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624 + emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627
        if (set1 && (set1 === set2) && (set2 === set3) && (set3 === set4) && (set4 === set5)) {
            return true
        } else if (emp[0].remarks && emp[0].remarks.trim().length) {
            return true
        } else {

            return false
        }

    }
    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN600} onChange={(e) => { updateVal('DPAN600', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN601} onChange={(e) => { updateVal('DPAN601', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN600 + emp[0].DPAN601} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Temporary Employees (Contract Workers) <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN602} onChange={(e) => { updateVal('DPAN602', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN603} onChange={(e) => { updateVal('DPAN603', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN602 + emp[0].DPAN603} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN600 + emp[0].DPAN601 + emp[0].DPAN602 + emp[0].DPAN603}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".ftemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Full-time Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 ftemp`} data-pr-tooltip={`Employee whose working hours per week, month, or year are defined according to national law or practice regarding working time.`}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN604} onChange={(e) => { updateVal('DPAN604', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN605} onChange={(e) => { updateVal('DPAN605', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN604 + emp[0].DPAN605} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".ptemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Part-time Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 ptemp`} data-pr-tooltip={`Employee whose working hours per week, month, or year are less than the number of working hours for full - time employees.`}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN606} onChange={(e) => { updateVal('DPAN606', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN607} onChange={(e) => { updateVal('DPAN607', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN606 + emp[0].DPAN607} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".nghe"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Non-guaranteed Hours Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 nghe`} data-pr-tooltip={`Employee who is not guaranteed a minimum or fixed number of working hours per day, week, or month, but who may need to make themselves available for work as required.`}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN608} onChange={(e) => { updateVal('DPAN608', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN609} onChange={(e) => { updateVal('DPAN609', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN608 + emp[0].DPAN609} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN604 + emp[0].DPAN605 + emp[0].DPAN606 + emp[0].DPAN607 + emp[0].DPAN608 + emp[0].DPAN609}
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Employees who are < 30 years old'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN610} onChange={(e) => { updateVal('DPAN610', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN628} onChange={(e) => { updateVal('DPAN628', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN610 + emp[0].DPAN628} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Employees who are 30-50 years old <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN611} onChange={(e) => { updateVal('DPAN611', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN629} onChange={(e) => { updateVal('DPAN629', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN611 + emp[0].DPAN629} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Employees who are >50 years old <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN612} onChange={(e) => { updateVal('DPAN612', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN630} onChange={(e) => { updateVal('DPAN630', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN612 + emp[0].DPAN630} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN610 + emp[0].DPAN611 + emp[0].DPAN612 + emp[0].DPAN628 + emp[0].DPAN629 + emp[0].DPAN630}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empsm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Senior Management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empsm`} data-pr-tooltip={' Vice Presidents and above'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN613} onChange={(e) => { updateVal('DPAN613', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN614} onChange={(e) => { updateVal('DPAN614', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN613 + emp[0].DPAN614} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empmm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Middle Management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empmm`} data-pr-tooltip={'Managers to Senior Directors'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN615} onChange={(e) => { updateVal('DPAN615', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN616} onChange={(e) => { updateVal('DPAN616', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN615 + emp[0].DPAN616} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empnm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Non-management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empnm`} data-pr-tooltip={'Assistant Managers and below'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN617} onChange={(e) => { updateVal('DPAN617', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN618} onChange={(e) => { updateVal('DPAN618', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN617 + emp[0].DPAN618} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN613 + emp[0].DPAN614 + emp[0].DPAN615 + emp[0].DPAN616 + emp[0].DPAN617 + emp[0].DPAN618}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            {'< 30 years'}
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            30-50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            > 50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empssm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Senior Management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empssm`} data-pr-tooltip={'Vice Presidents and above'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN619} onChange={(e) => { updateVal('DPAN619', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN620} onChange={(e) => { updateVal('DPAN620', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN621} onChange={(e) => { updateVal('DPAN621', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empsmm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Middle Management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empsmm`} data-pr-tooltip={'Managers to Senior Directors'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN622} onChange={(e) => { updateVal('DPAN622', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN623} onChange={(e) => { updateVal('DPAN623', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN624} onChange={(e) => { updateVal('DPAN624', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empsnm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Non-management   <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empsnm`} data-pr-tooltip={'Assistant Managers and below'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN625} onChange={(e) => { updateVal('DPAN625', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN626} onChange={(e) => { updateVal('DPAN626', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN627} onChange={(e) => { updateVal('DPAN627', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-2">
                            Total = {emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621 + emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624 + emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627}
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-5"> Remarks</div>

                        <div className="col-5">
                            <InputTextarea value={emp[0].remarks} onChange={(e) => { updateVal('remarks', e.target.value) }} style={{ width: '100%', height: 100, overflow: 'auto' }} />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Permanent Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN600} onChange={(e) => { updateVal('DPAN600', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN601} onChange={(e) => { updateVal('DPAN601', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN600 + emp[0].DPAN601} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Temporary Employees  (Contract Workers)
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN602} onChange={(e) => { updateVal('DPAN602', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN603} onChange={(e) => { updateVal('DPAN603', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN602 + emp[0].DPAN603} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN600 + emp[0].DPAN601 + emp[0].DPAN602 + emp[0].DPAN603}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Full-time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN604} onChange={(e) => { updateVal('DPAN604', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN605} onChange={(e) => { updateVal('DPAN605', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN604 + emp[0].DPAN605} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Part-time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN606} onChange={(e) => { updateVal('DPAN606', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN607} onChange={(e) => { updateVal('DPAN607', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN606 + emp[0].DPAN607} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-guaranteed Hours Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN608} onChange={(e) => { updateVal('DPAN608', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN609} onChange={(e) => { updateVal('DPAN609', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN608 + emp[0].DPAN609} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN604 + emp[0].DPAN605 + emp[0].DPAN606 + emp[0].DPAN607 + emp[0].DPAN608 + emp[0].DPAN609}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'}
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN610} onChange={(e) => { updateVal('DPAN610', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN628} onChange={(e) => { updateVal('DPAN628', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN610 + emp[0].DPAN628} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN611} onChange={(e) => { updateVal('DPAN611', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN629} onChange={(e) => { updateVal('DPAN629', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN611 + emp[0].DPAN629} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                    Employees who are >50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN612} onChange={(e) => { updateVal('DPAN612', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN630} onChange={(e) => { updateVal('DPAN630', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN612 + emp[0].DPAN630} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN610 + emp[0].DPAN611 + emp[0].DPAN612 + emp[0].DPAN628 + emp[0].DPAN629 + emp[0].DPAN630}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN613} onChange={(e) => { updateVal('DPAN613', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN614} onChange={(e) => { updateVal('DPAN614', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN613 + emp[0].DPAN614} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN615} onChange={(e) => { updateVal('DPAN615', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN616} onChange={(e) => { updateVal('DPAN616', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN615 + emp[0].DPAN616} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN617} onChange={(e) => { updateVal('DPAN617', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN618} onChange={(e) => { updateVal('DPAN618', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN617 + emp[0].DPAN618} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN613 + emp[0].DPAN614 + emp[0].DPAN615 + emp[0].DPAN616 + emp[0].DPAN617 + emp[0].DPAN618}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            {'< 30 years'}
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            30-50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                        > 50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN619} onChange={(e) => { updateVal('DPAN619', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN620} onChange={(e) => { updateVal('DPAN620', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN621} onChange={(e) => { updateVal('DPAN621', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN622} onChange={(e) => { updateVal('DPAN622', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN623} onChange={(e) => { updateVal('DPAN623', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN624} onChange={(e) => { updateVal('DPAN624', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-management
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN625} onChange={(e) => { updateVal('DPAN625', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN626} onChange={(e) => { updateVal('DPAN626', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN627} onChange={(e) => { updateVal('DPAN627', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-2">
                            Total = {emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621 + emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624 + emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627}
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-5"> Remarks</div>

                        <div className="col-5">
                            <InputTextarea disabled value={emp[0].remarks} style={{ width: '100%', height: 100, overflow: 'auto' }} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
const Employee_Category_Diversity_STT = ({ data, getData, setData, isValidResponse, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPAN600: 0,
        DPAN601: 0,
        DPAN602: 0,
        DPAN628: 0,
        DPAN629: 0,
        DPAN630: 0,
        DPAN603: 0,
        DPAN604: 0,
        DPAN605: 0,
        DPAN606: 0,
        DPAN607: 0,
        DPAN608: 0,
        DPAN609: 0,
        DPAN610: 0,
        DPAN611: 0,
        DPAN612: 0,
        DPAN613: 0,
        DPAN614: 0,
        DPAN615: 0,
        DPAN616: 0,
        DPAN617: 0,
        DPAN618: 0,
        DPAN619: 0,
        DPAN620: 0,
        DPAN621: 0,
        DPAN622: 0,
        DPAN623: 0,
        DPAN624: 0,
        DPAN625: 0,
        DPAN626: 0,
        DPAN627: 0,
        remarks: ''
    }]

    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        console.log(saveEmpCatDiversity())
        setData();
        isValidResponse(saveEmpCatDiversity())
    }, [emp]);

    getData(emp);
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            if (obj === 'remarks') {
                loc[0][obj] = ''
            } else {
                loc[0][obj] = 0
            }

        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };

    const saveEmpCatDiversity = () => {
        let set1 = emp[0].DPAN600 + emp[0].DPAN601 + emp[0].DPAN602 + emp[0].DPAN603
        let set2 = emp[0].DPAN604 + emp[0].DPAN605 + emp[0].DPAN606 + emp[0].DPAN607 + emp[0].DPAN608 + emp[0].DPAN609
        let set3 = emp[0].DPAN610 + emp[0].DPAN611 + emp[0].DPAN612 + emp[0].DPAN628 + emp[0].DPAN629 + emp[0].DPAN630
        let set4 = emp[0].DPAN613 + emp[0].DPAN614 + emp[0].DPAN615 + emp[0].DPAN616 + emp[0].DPAN617 + emp[0].DPAN618
        let set5 = emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621 + emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624 + emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627
        if (set1 && (set1 === set2) && (set2 === set3) && (set3 === set4) && (set4 === set5)) {
            return true
        } else if (emp[0].remarks && emp[0].remarks.trim().length) {
            return true
        } else {

            return false
        }

    }
    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN600} onChange={(e) => { updateVal('DPAN600', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN601} onChange={(e) => { updateVal('DPAN601', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN600 + emp[0].DPAN601} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Temporary Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN602} onChange={(e) => { updateVal('DPAN602', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN603} onChange={(e) => { updateVal('DPAN603', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN602 + emp[0].DPAN603} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN600 + emp[0].DPAN601 + emp[0].DPAN602 + emp[0].DPAN603}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".ftemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Full-time Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 ftemp`} data-pr-tooltip={`Employee whose working hours per week, month, or year are defined according to national law or practice regarding working time.`}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN604} onChange={(e) => { updateVal('DPAN604', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN605} onChange={(e) => { updateVal('DPAN605', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN604 + emp[0].DPAN605} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".ptemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Part-time Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 ptemp`} data-pr-tooltip={`Employee whose working hours per week, month, or year are less than the number of working hours for full - time employees.`}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN606} onChange={(e) => { updateVal('DPAN606', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN607} onChange={(e) => { updateVal('DPAN607', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN606 + emp[0].DPAN607} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".nghe"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Non-guaranteed Hours Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 nghe`} data-pr-tooltip={`Employee who is not guaranteed a minimum or fixed number of working hours per day, week, or month, but who may need to make themselves available for work as required.`}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN608} onChange={(e) => { updateVal('DPAN608', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN609} onChange={(e) => { updateVal('DPAN609', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN608 + emp[0].DPAN609} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN604 + emp[0].DPAN605 + emp[0].DPAN606 + emp[0].DPAN607 + emp[0].DPAN608 + emp[0].DPAN609}
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Employees who are < 30 years old'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN610} onChange={(e) => { updateVal('DPAN610', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN628} onChange={(e) => { updateVal('DPAN628', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN610 + emp[0].DPAN628} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Employees who are 30-50 years old <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN611} onChange={(e) => { updateVal('DPAN611', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN629} onChange={(e) => { updateVal('DPAN629', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN611 + emp[0].DPAN629} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Employees who are >50 years old <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN612} onChange={(e) => { updateVal('DPAN612', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN630} onChange={(e) => { updateVal('DPAN630', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN612 + emp[0].DPAN630} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN610 + emp[0].DPAN611 + emp[0].DPAN612 + emp[0].DPAN628 + emp[0].DPAN629 + emp[0].DPAN630}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empsm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Senior Management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empsm`} data-pr-tooltip={' Vice Presidents and above'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN613} onChange={(e) => { updateVal('DPAN613', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN614} onChange={(e) => { updateVal('DPAN614', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN613 + emp[0].DPAN614} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empmm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Middle Management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empmm`} data-pr-tooltip={'Managers to Senior Directors'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN615} onChange={(e) => { updateVal('DPAN615', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN616} onChange={(e) => { updateVal('DPAN616', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN615 + emp[0].DPAN616} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empnm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Non-management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empnm`} data-pr-tooltip={'Assistant Managers and below'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN617} onChange={(e) => { updateVal('DPAN617', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN618} onChange={(e) => { updateVal('DPAN618', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN617 + emp[0].DPAN618} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN613 + emp[0].DPAN614 + emp[0].DPAN615 + emp[0].DPAN616 + emp[0].DPAN617 + emp[0].DPAN618}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            {'< 30 years'}
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            30-50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            > 50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empssm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Senior Management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empssm`} data-pr-tooltip={'Vice Presidents and above'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN619} onChange={(e) => { updateVal('DPAN619', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN620} onChange={(e) => { updateVal('DPAN620', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN621} onChange={(e) => { updateVal('DPAN621', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empsmm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Middle Management  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empsmm`} data-pr-tooltip={'Managers to Senior Directors'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN622} onChange={(e) => { updateVal('DPAN622', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN623} onChange={(e) => { updateVal('DPAN623', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN624} onChange={(e) => { updateVal('DPAN624', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empsnm"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Non-management   <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empsnm`} data-pr-tooltip={'Assistant Managers and below'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN625} onChange={(e) => { updateVal('DPAN625', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN626} onChange={(e) => { updateVal('DPAN626', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN627} onChange={(e) => { updateVal('DPAN627', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-2">
                            Total = {emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621 + emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624 + emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627}
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-5"> Remarks</div>

                        <div className="col-5">
                            <InputTextarea value={emp[0].remarks} onChange={(e) => { updateVal('remarks', e.target.value) }} style={{ width: '100%', height: 100, overflow: 'auto' }} />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Permanent Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN600} onChange={(e) => { updateVal('DPAN600', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN601} onChange={(e) => { updateVal('DPAN601', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN600 + emp[0].DPAN601} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Temporary Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN602} onChange={(e) => { updateVal('DPAN602', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN603} onChange={(e) => { updateVal('DPAN603', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN602 + emp[0].DPAN603} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN600 + emp[0].DPAN601 + emp[0].DPAN602 + emp[0].DPAN603}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Full-time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN604} onChange={(e) => { updateVal('DPAN604', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN605} onChange={(e) => { updateVal('DPAN605', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN604 + emp[0].DPAN605} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Part-time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN606} onChange={(e) => { updateVal('DPAN606', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN607} onChange={(e) => { updateVal('DPAN607', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN606 + emp[0].DPAN607} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-guaranteed Hours Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN608} onChange={(e) => { updateVal('DPAN608', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN609} onChange={(e) => { updateVal('DPAN609', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN608 + emp[0].DPAN609} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN604 + emp[0].DPAN605 + emp[0].DPAN606 + emp[0].DPAN607 + emp[0].DPAN608 + emp[0].DPAN609}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'}
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN610} onChange={(e) => { updateVal('DPAN610', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN628} onChange={(e) => { updateVal('DPAN628', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN610 + emp[0].DPAN628} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN611} onChange={(e) => { updateVal('DPAN611', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN629} onChange={(e) => { updateVal('DPAN629', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN611 + emp[0].DPAN629} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                    Employees who are >50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN612} onChange={(e) => { updateVal('DPAN612', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN630} onChange={(e) => { updateVal('DPAN630', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN612 + emp[0].DPAN630} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN610 + emp[0].DPAN611 + emp[0].DPAN612 + emp[0].DPAN628 + emp[0].DPAN629 + emp[0].DPAN630}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN613} onChange={(e) => { updateVal('DPAN613', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN614} onChange={(e) => { updateVal('DPAN614', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN613 + emp[0].DPAN614} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN615} onChange={(e) => { updateVal('DPAN615', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN616} onChange={(e) => { updateVal('DPAN616', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN615 + emp[0].DPAN616} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN617} onChange={(e) => { updateVal('DPAN617', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN618} onChange={(e) => { updateVal('DPAN618', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN617 + emp[0].DPAN618} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN613 + emp[0].DPAN614 + emp[0].DPAN615 + emp[0].DPAN616 + emp[0].DPAN617 + emp[0].DPAN618}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            {'< 30 years'}
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            30-50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                        > 50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN619} onChange={(e) => { updateVal('DPAN619', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN620} onChange={(e) => { updateVal('DPAN620', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN621} onChange={(e) => { updateVal('DPAN621', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN622} onChange={(e) => { updateVal('DPAN622', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN623} onChange={(e) => { updateVal('DPAN623', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN624} onChange={(e) => { updateVal('DPAN624', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-management
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN625} onChange={(e) => { updateVal('DPAN625', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN626} onChange={(e) => { updateVal('DPAN626', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN627} onChange={(e) => { updateVal('DPAN627', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-2">
                            Total = {emp[0].DPAN619 + emp[0].DPAN620 + emp[0].DPAN621 + emp[0].DPAN622 + emp[0].DPAN623 + emp[0].DPAN624 + emp[0].DPAN625 + emp[0].DPAN626 + emp[0].DPAN627}
                        </div>
                    </div>
                    <div className="col-12 grid">

                        <div className="col-5"> Remarks</div>

                        <div className="col-5">
                            <InputTextarea disabled value={emp[0].remarks} style={{ width: '100%', height: 100, overflow: 'auto' }} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
const Total_No_of_New_Employee_STT = ({ data, getData, setData, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPAN631: 0,
        DPAN632: 0,
        DPAN633: 0,
        DPAN634: 0,
        DPAN635: 0,
        DPAN636: 0,
        DPAN637: 0,
        DPAN638: 0,
        DPAN639: 0,
        DPAN640: 0,
        DPAN641: 0,
        DPAN642: 0,
        DPAN643: 0,
        DPAN644: 0,
        DPAN645: 0,
        DPAN646: 0,
        DPAN647: 0,
        DPAN648: 0,
        DPAN649: 0,
        DPAN650: 0,
        DPAN651: 0,
        DPAN652: 0,
        DPAN653: 0,
        DPAN654: 0,
        DPAN655: 0,
        DPAN656: 0,
        DPAN657: 0,
        DPAN658: 0,
        DPAN659: 0,
        DPAN660: 0,
        DPAN661: 0
    }
    ]

    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [emp]);
    getData(emp);
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            loc[0][obj] = 0
        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };

    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Permanent Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN631} onChange={(e) => { updateVal('DPAN631', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN632} onChange={(e) => { updateVal('DPAN632', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN631 + emp[0].DPAN632} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Fixed Term/Temporary Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN633} onChange={(e) => { updateVal('DPAN633', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN634} onChange={(e) => { updateVal('DPAN634', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN633 + emp[0].DPAN634} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN631 + emp[0].DPAN632 + emp[0].DPAN633 + emp[0].DPAN634}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Full-Time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN635} onChange={(e) => { updateVal('DPAN635', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN636} onChange={(e) => { updateVal('DPAN636', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN635 + emp[0].DPAN636} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Part-Time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN637} onChange={(e) => { updateVal('DPAN637', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN638} onChange={(e) => { updateVal('DPAN638', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN637 + emp[0].DPAN638} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Guaranteed Hours Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN639} onChange={(e) => { updateVal('DPAN639', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN640} onChange={(e) => { updateVal('DPAN640', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN639 + emp[0].DPAN640} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN635 + emp[0].DPAN636 + emp[0].DPAN637 + emp[0].DPAN638 + emp[0].DPAN639 + emp[0].DPAN640}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'}
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN641} onChange={(e) => { updateVal('DPAN641', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN644} onChange={(e) => { updateVal('DPAN644', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN641 + emp[0].DPAN644} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN642} onChange={(e) => { updateVal('DPAN642', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN645} onChange={(e) => { updateVal('DPAN645', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN642 + emp[0].DPAN645} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                        Employees who are >50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN643} onChange={(e) => { updateVal('DPAN643', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN646} onChange={(e) => { updateVal('DPAN646', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN643 + emp[0].DPAN646} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN641 + emp[0].DPAN642 + emp[0].DPAN643 + emp[0].DPAN644 + emp[0].DPAN645 + emp[0].DPAN646}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN647} onChange={(e) => { updateVal('DPAN647', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN648} onChange={(e) => { updateVal('DPAN648', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN647 + emp[0].DPAN648} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN649} onChange={(e) => { updateVal('DPAN649', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN650} onChange={(e) => { updateVal('DPAN650', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN649 + emp[0].DPAN650} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN651} onChange={(e) => { updateVal('DPAN651', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN652} onChange={(e) => { updateVal('DPAN652', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN651 + emp[0].DPAN652} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN647 + emp[0].DPAN648 + emp[0].DPAN649 + emp[0].DPAN650 + emp[0].DPAN651 + emp[0].DPAN652}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            {'< 30 years'}
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            30-50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            > 50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN653} onChange={(e) => { updateVal('DPAN653', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN654} onChange={(e) => { updateVal('DPAN654', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN655} onChange={(e) => { updateVal('DPAN655', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN653 + emp[0].DPAN654 + emp[0].DPAN655} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN656} onChange={(e) => { updateVal('DPAN656', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN657} onChange={(e) => { updateVal('DPAN657', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN658} onChange={(e) => { updateVal('DPAN658', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN656 + emp[0].DPAN657 + emp[0].DPAN658} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN659} onChange={(e) => { updateVal('DPAN659', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN660} onChange={(e) => { updateVal('DPAN660', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN661} onChange={(e) => { updateVal('DPAN661', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN659 + emp[0].DPAN660 + emp[0].DPAN661} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-2">
                            Total = {emp[0].DPAN653 + emp[0].DPAN654 + emp[0].DPAN655 + emp[0].DPAN656 + emp[0].DPAN657 + emp[0].DPAN658 + emp[0].DPAN659 + emp[0].DPAN660 + emp[0].DPAN661}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Permanent Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN631} onChange={(e) => { updateVal('DPAN631', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN632} onChange={(e) => { updateVal('DPAN632', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN631 + emp[0].DPAN632} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Fixed Term/Temporary Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN633} onChange={(e) => { updateVal('DPAN633', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN634} onChange={(e) => { updateVal('DPAN634', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN633 + emp[0].DPAN634} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN631 + emp[0].DPAN632 + emp[0].DPAN633 + emp[0].DPAN634}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Full-Time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN635} onChange={(e) => { updateVal('DPAN635', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN636} onChange={(e) => { updateVal('DPAN636', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN635 + emp[0].DPAN636} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Part-Time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN637} onChange={(e) => { updateVal('DPAN637', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN638} onChange={(e) => { updateVal('DPAN638', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN637 + emp[0].DPAN638} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Guaranteed Hours Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN639} onChange={(e) => { updateVal('DPAN639', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN640} onChange={(e) => { updateVal('DPAN640', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN639 + emp[0].DPAN640} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN635 + emp[0].DPAN636 + emp[0].DPAN637 + emp[0].DPAN638 + emp[0].DPAN639 + emp[0].DPAN640}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'}
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN641} onChange={(e) => { updateVal('DPAN641', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN644} onChange={(e) => { updateVal('DPAN644', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN641 + emp[0].DPAN644} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN642} onChange={(e) => { updateVal('DPAN642', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN645} onChange={(e) => { updateVal('DPAN645', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN642 + emp[0].DPAN645} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                    Employees who are >50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN643} onChange={(e) => { updateVal('DPAN643', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN646} onChange={(e) => { updateVal('DPAN646', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN643 + emp[0].DPAN646} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN641 + emp[0].DPAN642 + emp[0].DPAN643 + emp[0].DPAN644 + emp[0].DPAN645 + emp[0].DPAN646}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN647} onChange={(e) => { updateVal('DPAN647', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN648} onChange={(e) => { updateVal('DPAN648', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN647 + emp[0].DPAN648} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN649} onChange={(e) => { updateVal('DPAN649', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN650} onChange={(e) => { updateVal('DPAN650', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN649 + emp[0].DPAN650} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN651} onChange={(e) => { updateVal('DPAN651', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN652} onChange={(e) => { updateVal('DPAN652', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN651 + emp[0].DPAN652} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN647 + emp[0].DPAN648 + emp[0].DPAN649 + emp[0].DPAN650 + emp[0].DPAN651 + emp[0].DPAN652}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            {'< 30 years'}
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            30-50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                        > 50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN653} onChange={(e) => { updateVal('DPAN653', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN654} onChange={(e) => { updateVal('DPAN654', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN655} onChange={(e) => { updateVal('DPAN655', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN653 + emp[0].DPAN654 + emp[0].DPAN655} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN656} onChange={(e) => { updateVal('DPAN656', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN657} onChange={(e) => { updateVal('DPAN657', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN658} onChange={(e) => { updateVal('DPAN658', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN656 + emp[0].DPAN657 + emp[0].DPAN658} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN659} onChange={(e) => { updateVal('DPAN659', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN660} onChange={(e) => { updateVal('DPAN660', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN661} onChange={(e) => { updateVal('DPAN661', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN659 + emp[0].DPAN660 + emp[0].DPAN661} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-2">
                            Total = {emp[0].DPAN653 + emp[0].DPAN654 + emp[0].DPAN655 + emp[0].DPAN656 + emp[0].DPAN657 + emp[0].DPAN658 + emp[0].DPAN659 + emp[0].DPAN660 + emp[0].DPAN661}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
const Total_No_of_Employee_Left_STT = ({ data, getData, setData, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPAN662: 0,
        DPAN663: 0,
        DPAN664: 0,
        DPAN665: 0,
        DPAN666: 0,
        DPAN667: 0,
        DPAN668: 0,
        DPAN669: 0,
        DPAN670: 0,
        DPAN671: 0,
        DPAN672: 0,
        DPAN673: 0,
        DPAN674: 0,
        DPAN675: 0,
        DPAN676: 0,
        DPAN677: 0,
        DPAN678: 0,
        DPAN679: 0,
        DPAN680: 0,
        DPAN681: 0,
        DPAN682: 0,
        DPAN683: 0,
        DPAN684: 0,
        DPAN685: 0,
        DPAN686: 0,
        DPAN687: 0,
        DPAN688: 0,
        DPAN689: 0,
        DPAN690: 0,
        DPAN691: 0,
        DPAN692: 0
    }
    ]
    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [emp]);
    getData(emp);
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            loc[0][obj] = 0
        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };



    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Permanent Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN662} onChange={(e) => { updateVal('DPAN662', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN663} onChange={(e) => { updateVal('DPAN663', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN662 + emp[0].DPAN663} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Fixed Term/Temporary Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN664} onChange={(e) => { updateVal('DPAN664', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN665} onChange={(e) => { updateVal('DPAN665', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN664 + emp[0].DPAN665} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN662 + emp[0].DPAN663 + emp[0].DPAN664 + emp[0].DPAN665}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Full-Time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN666} onChange={(e) => { updateVal('DPAN666', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN667} onChange={(e) => { updateVal('DPAN667', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN666 + emp[0].DPAN667} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Part-Time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN668} onChange={(e) => { updateVal('DPAN668', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN669} onChange={(e) => { updateVal('DPAN669', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN668 + emp[0].DPAN669} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Guaranteed Hours Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN670} onChange={(e) => { updateVal('DPAN670', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN671} onChange={(e) => { updateVal('DPAN671', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN670 + emp[0].DPAN671} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN666 + emp[0].DPAN667 + emp[0].DPAN668 + emp[0].DPAN669 + emp[0].DPAN670 + emp[0].DPAN671}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'}
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN672} onChange={(e) => { updateVal('DPAN672', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN675} onChange={(e) => { updateVal('DPAN675', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN672 + emp[0].DPAN675} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN673} onChange={(e) => { updateVal('DPAN673', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN676} onChange={(e) => { updateVal('DPAN676', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN673 + emp[0].DPAN676} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                        Employees who are >50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN674} onChange={(e) => { updateVal('DPAN674', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN677} onChange={(e) => { updateVal('DPAN677', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN674 + emp[0].DPAN677} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN672 + emp[0].DPAN673 + emp[0].DPAN674 + emp[0].DPAN675 + emp[0].DPAN676 + emp[0].DPAN677}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN678} onChange={(e) => { updateVal('DPAN678', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN679} onChange={(e) => { updateVal('DPAN679', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN678 + emp[0].DPAN679} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN680} onChange={(e) => { updateVal('DPAN680', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN681} onChange={(e) => { updateVal('DPAN681', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN680 + emp[0].DPAN681} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN682} onChange={(e) => { updateVal('DPAN682', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN683} onChange={(e) => { updateVal('DPAN683', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN682 + emp[0].DPAN683} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN678 + emp[0].DPAN679 + emp[0].DPAN680 + emp[0].DPAN681 + emp[0].DPAN682 + emp[0].DPAN683}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            {'< 30 years'}
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            30-50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            > 50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN684} onChange={(e) => { updateVal('DPAN684', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN685} onChange={(e) => { updateVal('DPAN685', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN686} onChange={(e) => { updateVal('DPAN686', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN684 + emp[0].DPAN685 + emp[0].DPAN686} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN687} onChange={(e) => { updateVal('DPAN687', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN688} onChange={(e) => { updateVal('DPAN688', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN689} onChange={(e) => { updateVal('DPAN689', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN687 + emp[0].DPAN688 + emp[0].DPAN689} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN690} onChange={(e) => { updateVal('DPAN690', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN691} onChange={(e) => { updateVal('DPAN691', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN692} onChange={(e) => { updateVal('DPAN692', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN690 + emp[0].DPAN691 + emp[0].DPAN692} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-2">
                            Total = {emp[0].DPAN684 + emp[0].DPAN685 + emp[0].DPAN686 + emp[0].DPAN687 + emp[0].DPAN688 + emp[0].DPAN689 + emp[0].DPAN690 + emp[0].DPAN691 + emp[0].DPAN692}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Permanent Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN662} onChange={(e) => { updateVal('DPAN662', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN663} onChange={(e) => { updateVal('DPAN663', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN662 + emp[0].DPAN663} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Fixed Term/Temporary Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN664} onChange={(e) => { updateVal('DPAN664', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN665} onChange={(e) => { updateVal('DPAN665', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPAN664 + emp[0].DPAN665} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN662 + emp[0].DPAN663 + emp[0].DPAN664 + emp[0].DPAN665}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Full-Time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN666} onChange={(e) => { updateVal('DPAN666', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN667} onChange={(e) => { updateVal('DPAN667', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN666 + emp[0].DPAN667} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Part-Time Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN668} onChange={(e) => { updateVal('DPAN668', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN669} onChange={(e) => { updateVal('DPAN669', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN668 + emp[0].DPAN669} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Guaranteed Hours Employees
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN670} onChange={(e) => { updateVal('DPAN670', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN671} onChange={(e) => { updateVal('DPAN671', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN670 + emp[0].DPAN671} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN666 + emp[0].DPAN667 + emp[0].DPAN668 + emp[0].DPAN669 + emp[0].DPAN670 + emp[0].DPAN671}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'}
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN672} onChange={(e) => { updateVal('DPAN672', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN675} onChange={(e) => { updateVal('DPAN675', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN672 + emp[0].DPAN675} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN673} onChange={(e) => { updateVal('DPAN673', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN676} onChange={(e) => { updateVal('DPAN676', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN673 + emp[0].DPAN676} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                        Employees who are >50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN674} onChange={(e) => { updateVal('DPAN674', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN677} onChange={(e) => { updateVal('DPAN677', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN674 + emp[0].DPAN677} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN672 + emp[0].DPAN673 + emp[0].DPAN674 + emp[0].DPAN675 + emp[0].DPAN676 + emp[0].DPAN677}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN678} onChange={(e) => { updateVal('DPAN678', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN679} onChange={(e) => { updateVal('DPAN679', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN678 + emp[0].DPAN679} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN680} onChange={(e) => { updateVal('DPAN680', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN681} onChange={(e) => { updateVal('DPAN681', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN680 + emp[0].DPAN681} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN682} onChange={(e) => { updateVal('DPAN682', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN683} onChange={(e) => { updateVal('DPAN683', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN682 + emp[0].DPAN683} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN678 + emp[0].DPAN679 + emp[0].DPAN680 + emp[0].DPAN681 + emp[0].DPAN682 + emp[0].DPAN683}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            {'< 30 years'}
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            30-50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            > 50 years
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            Senior Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN684} onChange={(e) => { updateVal('DPAN684', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN685} onChange={(e) => { updateVal('DPAN685', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN686} onChange={(e) => { updateVal('DPAN686', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN684 + emp[0].DPAN685 + emp[0].DPAN686} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Middle Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN687} onChange={(e) => { updateVal('DPAN687', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN688} onChange={(e) => { updateVal('DPAN688', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN689} onChange={(e) => { updateVal('DPAN689', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN687 + emp[0].DPAN688 + emp[0].DPAN689} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Non-Management
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN690} onChange={(e) => { updateVal('DPAN690', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN691} onChange={(e) => { updateVal('DPAN691', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN692} onChange={(e) => { updateVal('DPAN692', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN690 + emp[0].DPAN691 + emp[0].DPAN692} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-2">
                            Total = {emp[0].DPAN684 + emp[0].DPAN685 + emp[0].DPAN686 + emp[0].DPAN687 + emp[0].DPAN688 + emp[0].DPAN689 + emp[0].DPAN690 + emp[0].DPAN691 + emp[0].DPAN692}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
const Total_No_of_Employee_Hire_TurnOver_STT = ({ data, getData, setData, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPAN641: 0,
        DPAN642: 0,
        DPAN643: 0,
        DPAN644: 0,
        DPAN645: 0,
        DPAN646: 0,
        DPAN672: 0,
        DPAN673: 0,
        DPAN674: 0,
        DPAN675: 0,
        DPAN676: 0,
        DPAN677: 0
    }
    ]
    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [emp]);
    getData(emp);
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            loc[0][obj] = 0
        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };



    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <Tooltip className="form-tt-1" target={".empnewhire"} mouseTrack mouseTrackLeft={10} />
                    <div className="flex fw-7 fs-16">
                        New Hires <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material - icons fs - 14 empnewhire`} data-pr-tooltip={'New hires within the reporting period.'}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN641} onChange={(e) => { updateVal('DPAN641', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN644} onChange={(e) => { updateVal('DPAN644', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN641 + emp[0].DPAN644} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN642} onChange={(e) => { updateVal('DPAN642', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN645} onChange={(e) => { updateVal('DPAN645', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN642 + emp[0].DPAN645} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                        Employees who are >50 years old <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN643} onChange={(e) => { updateVal('DPAN643', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN646} onChange={(e) => { updateVal('DPAN646', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN643 + emp[0].DPAN646} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN641 + emp[0].DPAN642 + emp[0].DPAN643 + emp[0].DPAN644 + emp[0].DPAN645 + emp[0].DPAN646}
                        </div>
                    </div>


                    <Tooltip className="form-tt-1" target={".empnewturn"} mouseTrack mouseTrackLeft={10} />


                    <div className="flex fw-7 fs-16">
                        Employee Turnover <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material - icons fs - 14 empnewturn`} data-pr-tooltip={'Employees who leave the organization voluntarily or due to dismissal, retirement, or death in service'}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN672} onChange={(e) => { updateVal('DPAN672', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN675} onChange={(e) => { updateVal('DPAN675', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN672 + emp[0].DPAN675} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN673} onChange={(e) => { updateVal('DPAN673', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN676} onChange={(e) => { updateVal('DPAN676', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN673 + emp[0].DPAN676} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                        Employees who are >50 years old <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN674} onChange={(e) => { updateVal('DPAN674', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN677} onChange={(e) => { updateVal('DPAN677', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN674 + emp[0].DPAN677} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN672 + emp[0].DPAN673 + emp[0].DPAN674 + emp[0].DPAN675 + emp[0].DPAN676 + emp[0].DPAN677}
                        </div>
                    </div>

                </div>
            ) : (
                <div>

                    <div className="flex fw-7 fs-16">
                        New Hires
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'}
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN641} onChange={(e) => { updateVal('DPAN641', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN644} onChange={(e) => { updateVal('DPAN644', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN641 + emp[0].DPAN644} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN642} onChange={(e) => { updateVal('DPAN642', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN645} onChange={(e) => { updateVal('DPAN645', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN642 + emp[0].DPAN645} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                Employees who are >50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN643} onChange={(e) => { updateVal('DPAN643', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN646} onChange={(e) => { updateVal('DPAN646', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN643 + emp[0].DPAN646} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN641 + emp[0].DPAN642 + emp[0].DPAN643 + emp[0].DPAN644 + emp[0].DPAN645 + emp[0].DPAN646}
                        </div>
                    </div>

                    <div className="flex fw-7 fs-16">
                        Employee Turnover
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            {'Employees who are < 30 years old'}
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN672} onChange={(e) => { updateVal('DPAN672', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN675} onChange={(e) => { updateVal('DPAN675', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN672 + emp[0].DPAN675} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                            Employees who are 30-50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN673} onChange={(e) => { updateVal('DPAN673', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN676} onChange={(e) => { updateVal('DPAN676', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN673 + emp[0].DPAN676} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-4">
                Employees who are >50 years old
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN674} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPAN677} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPAN674 + emp[0].DPAN677} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPAN672 + emp[0].DPAN673 + emp[0].DPAN674 + emp[0].DPAN675 + emp[0].DPAN676 + emp[0].DPAN677}
                        </div>
                    </div>

                </div>
            )}
        </div>
    );
};
const Employee_Training_Hours_STT = ({ data, extractedData = () => { }, getData, setData, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPKN048: 0,
        DPKN049: 0,
        DPKN050: 0,
        DPKN051: 0,
        DPKN052: 0,
        DPKN053: 0
    }
    ]
    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [emp]);
    getData(emp);
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(data));
        } else {
            console.error("extractedData is not a function");
        }
    }, [data]);

    function extractData(data) {
        const result = [
            {
                maskId: "DPKN048",
                uniqueId: "DPKN048",
                title: "Total number of training hours - Senior Management - Male",
                label: "Total number of training hours - Senior Management - Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPKN048,
                uom: "nos",
            },
            {
                maskId: "DPKN049",
                uniqueId: "DPKN049",
                title: "Total number of training hours - Senior Management - Female",
                label: "Total number of training hours - Senior Management - Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPKN049,
                uom: "nos",
            },

            {
                maskId: "DPKN050",
                uniqueId: "DPKN050",
                title: "Total number of training hours - Middle Management - Male",
                label: "Total number of training hours - Middle Management - Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPKN050,
                uom: "nos",
            },
            {
                maskId: "DPKN051",
                uniqueId: "DPKN051",
                title: "Total number of training hours - Middle Management - Female",
                label: "Total number of training hours - Middle Management - Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPKN051,
                uom: "nos",
            },
            {
                maskId: "DPKN052",
                uniqueId: "DPKN052",
                title: "Total number of training hours - Non Management - Male",
                label: "Total number of training hours - Non Management - Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPKN052,
                uom: "nos",
            },
            {
                maskId: "DPKN053",
                uniqueId: "DPKN053",
                title: "Total number of training hours - Non Management - Female",
                label: "Total number of training hours - Non Management - Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPKN053,
                uom: "nos",
            },
        ];

        console.log(result, "RESULT");
        return result;
    }
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            loc[0][obj] = 0
        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };



    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}

                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empsmtrain"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            {'Total number of training hours - Senior Management'} <span className="mandatory"> *</span>
                            <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empsmtrain`} data-pr-tooltip={'Vice Presidents and above.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN048} onChange={(e) => { updateVal('DPKN048', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN049} onChange={(e) => { updateVal('DPKN049', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPKN048 + emp[0].DPKN049} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empmmtrain"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Total number of training hours - Middle Management<span className="mandatory"> *</span>
                            <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empmmtrain`} data-pr-tooltip={'Managers to Senior Directors'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN050} onChange={(e) => { updateVal('DPKN050', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN051} onChange={(e) => { updateVal('DPKN051', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPKN050 + emp[0].DPKN051} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empnmtrain"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Total number of training hours - Non-management<span className="mandatory"> *</span>
                            <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empnmtrain`} data-pr-tooltip={'Assistant Managers and below'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN052} onChange={(e) => { updateVal('DPKN052', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN053} onChange={(e) => { updateVal('DPKN053', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPKN052 + emp[0].DPKN053} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPKN048 + emp[0].DPKN049 + emp[0].DPKN050 + emp[0].DPKN051 + emp[0].DPKN052 + emp[0].DPKN053}
                        </div>
                    </div>









                </div>
            ) : (
                <div>

                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empsmtrain"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            {'Total number of training hours - Senior Management'} <span className="mandatory"> *</span>
                            <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empsmtrain`} data-pr-tooltip={'Vice Presidents and above.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN048} onChange={(e) => { updateVal('DPKN048', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN049} onChange={(e) => { updateVal('DPKN049', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPKN048 + emp[0].DPKN049} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empmmtrain"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Total number of training hours - Middle Management<span className="mandatory"> *</span>
                            <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empmmtrain`} data-pr-tooltip={'Managers to Senior Directors'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN050} onChange={(e) => { updateVal('DPKN050', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN051} onChange={(e) => { updateVal('DPKN051', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPKN050 + emp[0].DPKN051} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".empnmtrain"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Total number of training hours - Non-management<span className="mandatory"> *</span>
                            <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 empnmtrain`} data-pr-tooltip={'Assistant Managers and below'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN052} onChange={(e) => { updateVal('DPKN052', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPKN053} onChange={(e) => { updateVal('DPKN053', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPKN052 + emp[0].DPKN053} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPKN048 + emp[0].DPKN049 + emp[0].DPKN050 + emp[0].DPKN051 + emp[0].DPKN052 + emp[0].DPKN053}
                        </div>
                    </div>









                </div>
            )}
        </div>
    );
};
const Stationary_Combustion_Rotary = ({ extractedData = () => { }, data, getData, setData, allowedCategories = [], edit = 0, standard, dates = { minDate: null, maxDate: null } }) => {
    console.log(standard, allowedCategories)
    const [rejecteddata, setRejectedData] = useState([])
    const [accepteddata, setAcceptedData] = useState([])
    const [rejectdialog, setRejectDialog] = useState(false)
    const [fields, setFields] = useState([])
    const [scdata, setSCData] = useState(data);
    const [load, setLoad] = useState(true)
    const forceUpdate = useForceUpdate();
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [scfilter, setScFilter] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [fuelcatlist, setFuelCatList] = useState([]);
    const [fuellist, setFuelList] = useState([]);
    const [category, setCategory] = useState([]);
    const DualOptions = [
        { name: 'RTC', id: 1 },
        { name: 'REL', id: 2 },
        { name: 'REI', id: 3 },
        { name: 'RMC', id: 4 },
        { name: 'RMEC', id: 5 },
        { name: 'RIMC', id: 6 },
        { name: 'REIT', id: 7 },
        { name: 'TREL', id: 8 },
        { name: 'PTREI', id: 9 },
        { name: 'RELQ', id: 10 },
        { name: 'REF', id: 11 },
        { name: 'RESA', id: 12 },
        { name: 'Supermec', id: 13 }

    ]
    const DPAW0001Options = [
        { name: 'Generator', id: 1 },
        { name: 'Air Compressor', id: 2 },
        { name: 'Pumping Equipment', id: 3 },
        { name: 'Welding Generator Set', id: 4 },
        { name: 'Mobile Lighting Equipment', id: 5 },
        { name: 'Cutting Equipment', id: 6 },
        { name: 'Diesel Engine', id: 8 },
        { name: 'Others', id: 7 }
    ]
    const [scVal, setSCVal] = useState({
        DPAW0011: '',
        DPAW0005: null,
        DPAW0006: null,
        DPAW0006A: '',
        DPAW0001: null,
        DPAW0001A: '',
        DPAW0005A: '',
        DPAN460: null,
        DPA0130: null,
        DPA0131: null,
        DPA0132: null,
        DPAW0009: '',
        DPAW0008: '',
        DPA0336: 0,
        attachment: null
    });
    const [scdialog, setScDialog] = useState(false);
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            setLoad(false)
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === standard
                );
                console.log(JSON.parse(JSON.stringify(res.data))[stdindex])
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 1);
                        setCategory(
                            JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories[
                                cat_index
                            ].newEfSubcategory1s
                        );
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setFuelCatList(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);

    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(scdata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [scdata, category]);
    getData(scdata);

    function extractData(data) {
        let mappedData = category
            .flatMap(
                (i) =>
                    i.newEfSubcategory2s?.length &&
                    i.newEfSubcategory2s.flatMap(
                        (x) =>
                            x.newEfSubcategory3s?.length &&
                            x.newEfSubcategory3s.map((y) => ({
                                key: i.id + "-" + x.id + "-" + y.id,
                                parent0id: i.id,
                                currentId: y.id,
                                parentId: x.id,
                                title1: i.title,
                                title2: x.title,
                                title3: y.title,
                            }))
                    )
            )
            .filter((i) => i);
        let result = [];

        for (const item of data) {
            let index = mappedData.find(
                (i) =>
                    i.key ===
                    item["DPA0130"] + "-" + item["DPA0131"] + "-" + item["DPA0132"]
            );
            console.log(index, mappedData);
            if (index) {

                result.push({
                    title: index.title1 + ">" + index.title2 + ">" + index.title3,
                    label: index.title2,
                    value: item.DPA0336,
                    subCategory1: index.parent0id,
                    subCategory2: index.parentId,
                    subCategory3: index.currentId,
                    currentId: index.currentId,
                    parentId: index.parentId,
                    formType: 2,
                    dataType: 1,
                    attachment: item.attachment,
                    uom: index.title3,
                    uniqueId: index.key,
                    maskId: item.id,
                    date: item.DPAN460,
                    additionalValue1: item.DPA0133,
                    additionalValue2: item.DPA0134,
                });

            }
        }
        console.log(result, "RESULT");
        return result;
    }
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    const [unitlist, setUnitList] = useState([]);
    const scheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setSCVal({
                                DPAW0011: '',
                                DPAW0005: null,
                                DPAW0006: null,
                                DPAW0006A: '',
                                DPAW0001: null,
                                DPAW0005A: '',
                                DPAN460: null,
                                DPA0130: null,
                                DPA0131: null,
                                DPA0132: null,
                                DPA0336: 0,
                                DPAW0001A: '',
                                attachment: null,
                                DPAW0009: '',
                                DPAW0008: '',
                            });
                            setSubmitted(false);

                            setScDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setScFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const saveStationary = () => {
        setSubmitted(true);

        if (scVal.DPAW0001 !== null && scVal.DPAW0011.trim().length && scVal.DPAW0008.trim().length && scVal.DPAW0009.trim().length && (scVal.DPAW0001 === 7 ? scVal.DPAW0001A.trim().length : true) && scVal.DPAW0005 !== null && (scVal.DPAW0005 === 14 ? scVal.DPAW0005A.trim().length : true) && scVal.DPAW0006 !== null &&
            (scVal.DPAW0006 === 14 ? scVal.DPAW0006A.trim().length : true) &&
            scVal.DPA0130 !== null && scVal.DPA0131 !== null && scVal.DPA0132 !== null && scVal.DPAN460 !== null &&
            parseFloat(scVal.DPA0336) >= 0
        ) {
            if (scVal.id === undefined) {
                scVal["id"] = getID();
                setSCData((prev) => [scVal, ...prev]);
            } else {
                let loc = [...scdata];

                let index = loc.findIndex((k) => {
                    return k.id === scVal.id;
                });
                loc[index] = scVal;
                setSCData(loc);
            }
            console.log(scVal);
            forceUpdate();
            setSubmitted(false);
            setScDialog(false);
        }

        forceUpdate();
    };
    function validateAndConvertDate(serialOrString) {
        let date;

        // Check if the input is a number (serial date)
        if (!isNaN(serialOrString)) {
            // Convert serial number to date
            const excelEpoch = DateTime.fromISO("1899-12-30"); // Excel's base date for serial dates
            date = excelEpoch.plus({ days: Number(serialOrString) });

        } else {
            // Assume it's a string and parse the date
            date = DateTime.fromFormat(serialOrString, 'dd-MM-yyyy');
        }

        // Check if the date is valid
        if (!date.isValid) {
            return { isValid: false, message: 'Invalid date format or serial number.' };
        }

        // Convert to UTC string
        const utcDate = date.toUTC().toISO();

        return { isValid: true, utcDate };
    }
    const downloadReport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[]]);

        // Headers starting from the 2nd row and 2nd column (B2)
        let headers = fields
        headers.forEach((header, index) => {
            const cellAddress = XLSX.utils.encode_cell({ c: index + 1, r: 1 });
            ws[cellAddress] = { v: header };
        });

        // Set data starting from the 3rd row and 2nd column (B3)
        rejecteddata.forEach((row, rowIndex) => {
            Object.values(row).forEach((value, colIndex) => {
                const cellAddress = XLSX.utils.encode_cell({ c: colIndex + 1, r: rowIndex + 2 });
                ws[cellAddress] = { v: value };
            });
        });

        // Define the range to include headers and data
        const range = {
            s: { c: 1, r: 1 },
            e: { c: headers.length, r: rejecteddata.length + 2 }
        };
        ws['!ref'] = XLSX.utils.encode_range(range);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Data Entry');

        // Write the workbook
        XLSX.writeFile(wb, 'Error_In_Multiple_Import_Report.xlsx');
    }
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                let json = [];
                for (const sheet in wb.Sheets) {
                    console.log(sheet)
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0 && sheet === 'Data Entry'

                    ) {

                        const headerRow = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1, // Get all rows as arrays
                            range: 'B2:N2', // Specify that you're reading the second row for headers
                        });

                        // The headers should be the first element in the array
                        const headers = headerRow[0]; // Extract headers from the second row
                        setFields([...headers, 'Error(s)'])
                        console.log(headers)
                        // Now, extract the rest of the data starting from the third row onwards
                        const data = XLSX.utils.sheet_to_json(worksheet, {
                            header: headers, // Use the extracted headers for the data
                            range: 'B3:BZ1000', // Start reading from the second row
                            defval: null, // Default value for empty cells
                            blankrows: false, // Ignore blank rows
                        });
                        console.log(data)
                        json.push({
                            [sheet]: data
                        });
                    }
                }


                let local = {};
                let local2 = [];
                let rejected = [], accepted = []
                json.forEach((sname, i) => {
                    if (Object.keys(sname)[0] === "Data Entry") {

                        sname[Object.keys(sname)[0]].forEach((items) => {
                            local = {};
                            let errorArray = []
                            if (!items['Project Code'] || !items['Project Code'].toString().trim().length) {
                                errorArray.push('Project Code Field is Required')
                            }
                            if (!items['Type of Equipment'] || !items['Type of Equipment'].toString().trim().length) {
                                errorArray.push('Type of Equipment Field is Required')
                            } else if (items['Type of Equipment']) {
                                let typeIndex = DPAW0001Options.findIndex((i) => i.name.trim().toLowerCase() === items['Type of Equipment'].toString().toLowerCase().trim())
                                if (typeIndex !== -1) {
                                    if (DPAW0001Options[typeIndex].id === 7 && (!items['Type of Equipment Specify "Other" type of Vehicle'] || !items['Type of Equipment Specify "Other" type of Vehicle'].toString().trim().length)) {
                                        errorArray.push('Type of Equipment Specify "Other" type of Vehicle Field is Required')
                                    }
                                } else {
                                    errorArray.push('Type of Equipment Option Not Valid')

                                }
                            }
                            if (!items['Equipment Name'] || !items['Equipment Name'].toString().trim().length) {
                                errorArray.push('Equipment Name Field is Required')
                            }
                            if (!items['Equipment Tag'] || !items['Equipment Tag'].toString().trim().length) {
                                errorArray.push('Equipment Tag Field is Required')
                            }
                            if (!items['Owner of the Equipment'] || !items['Owner of the Equipment'].toString().trim().length) {
                                errorArray.push('Owner of the Equipment Field is Required')
                            } else if (items['Owner of the Equipment']) {
                                let ownerIndex = DualOptions.findIndex((i) => i.name.trim().toLowerCase() === items['Owner of the Equipment'].toString().toLowerCase().trim())
                                if (ownerIndex !== -1) {
                                    if (DualOptions[ownerIndex].id === 14 && (!items[`Owner of the Equipment - Name of the "Other Vendor"`] || !items[`Owner of the Equipment - Name of the "Other Vendor"`].toString().trim().length)) {
                                        errorArray.push(`Owner of the Equipment - Name of the "Other Vendor" Field is Required`)
                                    }
                                } else {
                                    errorArray.push('Owner of the Equipment Option Not Valid')

                                }

                            }
                            if (!items['Fuel provided by'] || !items['Fuel provided by'].toString().trim().length) {
                                errorArray.push('Fuel provided by Field is Required')
                            } else if (items['Fuel provided by']) {
                                let fuelProIndex = DualOptions.findIndex((i) => i.name.trim().toLowerCase() === items['Fuel provided by'].toString().toLowerCase().trim())
                                if (fuelProIndex !== -1) {
                                    if (DualOptions[fuelProIndex].id === 14 && (!items[`Fuel provided by - Name of the "Other Vendor"`] || !items[`Fuel provided by - Name of the "Other Vendor"`].toString().trim().length)) {
                                        errorArray.push(`Fuel provided by - Name of the "Other Vendor" Field is Required`)
                                    }
                                } else {
                                    errorArray.push('Fuel provided by Option Not Valid')

                                }
                            }
                            if (!items['Fuel Category'] || !items['Fuel Category'].toString().trim().length) {
                                errorArray.push('Fuel Category Field is Required')
                            }
                            if (!items['Fuel Type'] || !items['Fuel Type'].toString().trim().length) {
                                errorArray.push('Fuel Type Field is Required')
                            }
                            if (!items['Unit'] || !items['Unit'].toString().trim().length) {
                                errorArray.push('Unit Field is Required')
                            }
                            if (!items['Quantity of Fuel Used'] || (!parseFloat(items['Quantity of Fuel Used'].toString().trim()) && parseFloat(items['Quantity of Fuel Used'].toString().trim()) !== 0)) {
                                errorArray.push('Quantity of Fuel Used Field is Required')
                            }
                            if (!items['Date']) {
                                errorArray.push('Date Field is Required')
                            } else if (items["Date"] && !validateAndConvertDate(items['Date']).isValid) {


                                errorArray.push('Date Format is Invalid')



                            } else if (items["Date"] && validateAndConvertDate(items['Date']).isValid && !isDateInRange(dates, validateAndConvertDate(items['Date']).utcDate)) {
                                errorArray.push('Date Is Not Matching With Reporting Period')
                            }
                            if (items['Fuel Category'] && items['Fuel Category'].toString().trim().length && items['Fuel Type'] && items['Fuel Type'].toString().trim().length && items['Unit'] && items['Unit'].toString().trim().length) {
                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Fuel Category'].toString(),
                                    items['Fuel Type'].toString(),
                                    items['Unit'].toString()
                                );
                                if (result) {

                                } else {
                                    errorArray.push('Combination of Fuel Category & Fuel Type & Unit is Mismatch ')
                                }
                            }

                            console.log(items)
                            if (
                                items['Project Code'] && items['Project Code'].toString().trim().length &&
                                items['Type of Equipment'] && items['Type of Equipment'].toString().trim().length !== 0 &&
                                items['Equipment Name'] && items['Equipment Name'].toString().trim().length !== 0 &&
                                items['Equipment Tag'] && items['Equipment Tag'].toString().trim().length !== 0 &&
                                items['Owner of the Equipment'] && items['Owner of the Equipment'].toString().trim().length !== 0 &&
                                items['Fuel provided by'] && items['Fuel provided by'].toString().trim().length !== 0 &&
                                items['Fuel Category'] && items['Fuel Category'].toString().trim().length !== 0 &&
                                items['Fuel Type'] && items['Fuel Type'].toString().trim().length !== 0 &&
                                (items['Quantity of Fuel Used'] === 0 || items['Quantity of Fuel Used']) && parseFloat(items['Quantity of Fuel Used'].toString()) >= 0 &&
                                items['Unit'] && items['Unit'].toString().trim().length !== 0 &&
                                items['Date'] && validateAndConvertDate(items['Date']).isValid && isDateInRange(dates, validateAndConvertDate(items['Date']).utcDate)

                            ) {
                                console.log(items)
                                let typeIndex = DPAW0001Options.findIndex((i) => i.name.trim().toLowerCase() === items['Type of Equipment'].toString().toLowerCase().trim())
                                let ownerIndex = DualOptions.findIndex((i) => i.name.trim().toLowerCase() === items['Owner of the Equipment'].toString().toLowerCase().trim())
                                let fuelProIndex = DualOptions.findIndex((i) => i.name.trim().toLowerCase() === items['Fuel provided by'].toString().toLowerCase().trim())

                                if (typeIndex !== -1 && (DPAW0001Options[typeIndex].id === 7 ? (items['Type of Equipment Specify "Other" type of Vehicle'] && items['Type of Equipment Specify "Other" type of Vehicle'].toString().trim().length) : true) && ownerIndex !== -1 && fuelProIndex !== -1 && (DualOptions[ownerIndex].id === 14 ? (items[`Owner of the Equipment - Name of the "Other Vendor"`] && items[`Owner of the Equipment - Name of the "Other Vendor"`].toString().trim().length) : true) && (DualOptions[fuelProIndex].id === 14 ? (items[`Fuel provided by - Name of the "Other Vendor"`] && items[`Fuel provided by - Name of the "Other Vendor"`].toString().trim().length) : true)) {
                                    const { result, id } = findMatchingIds(
                                        category,
                                        items['Fuel Category'].toString(),
                                        items['Fuel Type'].toString(),
                                        items['Unit'].toString()
                                    );
                                    console.log(result, id, items);
                                    if (result) {
                                        local["DPA0130"] = id[0];
                                        local["DPA0131"] = id[1];
                                        local["DPA0132"] = id[2];
                                        local["DPAW0011"] = items[`Project Code`].toString().trim()
                                        local["DPAW0008"] = items[`Equipment Name`].toString().trim()
                                        local["DPAW0009"] = items[`Equipment Tag`].toString().trim()
                                        local['DPAN460'] = validateAndConvertDate(items['Date']).utcDate
                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPA0336"] = parseFloat(items['Quantity of Fuel Used'].toString());
                                        local["DPAW0001"] = DPAW0001Options[typeIndex].id
                                        local["DPAW0005"] = DualOptions[ownerIndex].id
                                        if (DPAW0001Options[typeIndex].id === 7) {
                                            local["DPAW0001A"] = items[`Type of Equipment Specify "Other" type of Vehicle`].toString().trim()
                                        } else {
                                            local["DPAW0001A"] = ''
                                        }
                                        if (DualOptions[ownerIndex].id === 14) {
                                            local["DPAW0005A"] = items[`Owner of the Equipment - Name of the "Other Vendor"`].toString().trim()
                                        } else {
                                            local["DPAW0005A"] = ''
                                        }
                                        if (DualOptions[fuelProIndex].id === 14) {
                                            local["DPAW0006A"] = items[`Fuel provided by - Name of the "Other Vendor"`].toString().trim()
                                        } else {
                                            local["DPAW0006A"] = ''
                                        }
                                        local["DPAW0006"] = DualOptions[fuelProIndex].id
                                        accepted.push({ ...local })
                                        local2.push(local);
                                    } else {
                                        rejected.push({ ...items, 'Error(s)': errorArray.join(',') })
                                    }
                                } else {
                                    rejected.push({ ...items, 'Error(s)': errorArray.join(',') })
                                }

                            } else {
                                rejected.push({ ...items, 'Error(s)': errorArray.join(',') })
                            }
                        });
                    }
                });

                // setSCData(local2)
                if (rejected.length) {
                    setRejectedData(rejected)
                    setAcceptedData(accepted)
                    setRejectDialog(true)

                } else if (accepted.length) {
                    setSCData(local2)
                } else {
                    Swal.fire({
                        title: `No Entries Found In Uploaded Excel`,

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read motore about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                        }
                    })
                }


                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const updateScVal = (obj, val) => {
        let local = scVal;

        local[obj] = val;
        if (obj === "DPA0130") {
            local["DPA0132"] = null;
            local["DPA0131"] = null;
            let fuel_type = [];
            fuelcatlist.forEach((i) => {
                console.log(i, val);
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === val) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        } else if (obj === "DPA0131") {
            local["DPA0132"] = null;

            let units = [];
            let index = fuelcatlist.findIndex((i) => {
                return i.id === local["DPA0130"];
            });
            fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                console.log(i, val);
                if (i.newEfSubcategory3s !== undefined) {
                    if (i.id === val) {
                        units = i.newEfSubcategory3s;
                    }
                }
            });
            setUnitList(units);
            // fuelcategory.forEach((i) => {
            //     if (i.fuel === val.name && i.fuel_type === local['DPA0130'].name) {
            //         if (units.findIndex((k) => { return k.name === i.unit }) === -1) {
            //             units.push({ name: i.unit })
            //         }
            //     }

            // })
        }
        if (obj === "DPA0132") {

            local.DPA0336 = 0;

        }
        if (obj === "DPAW0005") {

            local.DPAW0005A = ''

        }
        if (obj === "DPAW0006") {

            local.DPAW0006A = ''

        }
        setSCVal(local);

        forceUpdate();
    };
    const editScVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPAN460 = DateTime.fromISO(items.DPAN460, { zone: 'utc' }).toJSDate()
        let fuel_type = [],
            units = [];
        if (item.DPA0131 !== null) {
            fuelcatlist.forEach((i) => {
                console.log(i, item.DPA0130);
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === item.DPA0130) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        }
        if (item.DPA0132 !== null) {
            let index = fuelcatlist.findIndex((i) => {
                return i.id === item.DPA0130;
            });
            if (index !== -1 && fuelcatlist[index].newEfSubcategory2s !== undefined) {
                fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                    console.log(i, item.DPA0131, item.DPA0132);
                    if (i.newEfSubcategory3s !== undefined) {
                        if (i.id === item.DPA0131) {
                            units = i.newEfSubcategory3s;
                        }
                    }
                });
            }

            setUnitList(units);
        }
        items.DPA0133 = parseFloat(items.DPA0133);
        items.DPA0134 = parseFloat(items.DPA0134);
        items.DPA0336 = parseFloat(items.DPA0336);
        items.DPAN1000 = parseFloat(items.DPAN1000);
        setSCVal(items);
        console.log(items);
        setScDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...scdata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setSCData(loc);
        forceUpdate();
    };
    const scDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveStationary();
                }}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const subcategory1template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1) {
            text = category[index].title;
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory2template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPA0131;
            });
            if (index2 !== -1) {
                text = category[index].newEfSubcategory2s[index2].title;
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory3template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPA0131;
            });
            if (
                index2 !== -1 &&
                category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                undefined
            ) {
                let index3 = category[index].newEfSubcategory2s[
                    index2
                ].newEfSubcategory3s.findIndex((i) => {
                    return i.id === rowData.DPA0132;
                });
                if (index3 !== -1) {
                    text =
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                            index3
                        ].title;
                }
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const actionBodyTemplateSC = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editScVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const vehicleTypeTemplate = (rowData) => {
        let txt = 'NA'
        let index = DPAW0001Options.findIndex((i) => i.id === rowData.DPAW0001);
        if (index !== -1) {
            if (rowData.DPAW0001 === 7) {
                txt = DPAW0001Options[index].name + ' (' + rowData.DPAW0001A + ')'
            } else {
                txt = DPAW0001Options[index].name
            }
        }
        return <>{txt}</>

    }
    const vehicleOwnerTemplate = (rowData) => {
        let txt = 'NA'
        let index = DualOptions.findIndex((i) => i.id === rowData.DPAW0005);
        if (index !== -1) {
            if (rowData.DPAW0005 === 14) {
                txt = DualOptions[index].name + ' (' + rowData.DPAW0005A + ')'
            } else {
                txt = DualOptions[index].name
            }
        }
        return <>{txt}</>

    }
    const fuelProvidedTemplate = (rowData) => {
        let txt = 'NA'
        let index = DualOptions.findIndex((i) => i.id === rowData.DPAW0006);
        if (index !== -1) {
            if (rowData.DPAW0006 === 14) {
                txt = DualOptions[index].name + ' (' + rowData.DPAW0006A + ')'
            } else {
                txt = DualOptions[index].name
            }
        }
        return <>{txt}</>
    }

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        window.open('https://api.eisqr.com/docs/1726669416338Rotary_SC_Template.xlsx')
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Stationary Combustion Emissions");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={() => {
                                setSCVal({
                                    DPAW0011: '',
                                    DPAW0005: null,
                                    DPAW0006: null,
                                    DPAW0006A: '',
                                    DPAW0001: null,
                                    DPAW0005A: '',
                                    DPAN460: null,
                                    DPA0130: null,
                                    DPA0131: null,
                                    DPA0132: null,
                                    DPA0336: 0,
                                    DPAW0001A: '',
                                    attachment: null,
                                    DPAW0009: '',
                                    DPAW0008: '',
                                });
                                setSubmitted(false);

                                setScDialog(true);
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={scdata}
                loading={load}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0130.name", "DPA0131.name", "DPA0132.name"]}
                globalFilter={scfilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && scheader}
                responsiveLayout="scroll"
            >
                <Column
                    field={'DPAW0011'}
                    header="Project Code"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={(rowData) => { return <>{getDate(rowData.DPAN460)} </> }}
                    header="Date"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column
                    body={vehicleTypeTemplate}
                    header="Type of Equipment"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={vehicleOwnerTemplate}
                    header="Owner of the equipment"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field={'DPAW0008'}
                    header="Equipment Name"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field={'DPAW0009'}
                    header="Equipment Tag"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory1template}
                    header="Fuel Category"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory2template}
                    header="Fuel Type"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={fuelProvidedTemplate}
                    header="Fuel provided by"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column
                    body={subcategory3template}
                    header="Unit"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0336"
                    header="Quantity Used"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateSC}> </Column>}
            </DataTable>
            <Dialog
                visible={scdialog}
                style={{
                    width: "450px",
                }}
                header="Add New"
                modal
                className="p-fluid"
                footer={scDialogFooter}
                onHide={() => {
                    setScDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="vehno1"> Project Code <span className='mandatory'>*</span> </label>
                    <InputText
                        value={scVal.DPAW0011}
                        onChange={(e) => {
                            updateScVal("DPAW0011", e.target.value);
                        }}
                        placeholder="Project Code"
                    />
                    {submitted && scVal.DPAW0011.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Provide Project Code
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="refdate">Date <span className='mandatory'>*</span> </label>
                    <Calendar
                        showIcon minDate={dates.minDate} maxDate={dates.maxDate} dateFormat={'dd/mm/yy'}
                        value={scVal.DPAN460}

                        showMinMaxRange={true}
                        showOtherMonths={false}
                        viewDate={dates.minDate}
                        onChange={(e) => updateScVal("DPAN460", e.value)}
                    ></Calendar>
                    {submitted && scVal.DPAN460 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Date required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="refdate">Type of Equipment <span className='mandatory'>*</span> </label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        options={DPAW0001Options}
                        value={scVal.DPAW0001}

                        onChange={(e) => updateScVal("DPAW0001", e.target.value)}
                    />
                    {submitted && scVal.DPAW0001 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select Equipment Type
                        </small>
                    )}
                </div>
                {scVal.DPAW0001 !== null && scVal.DPAW0001 === 7 &&
                    <div className="field">
                        <label htmlFor="vehno1"> Type of Equipment
                            Name of the "Other" <span className='mandatory'>*</span> </label>
                        <InputText
                            value={scVal.DPAW0001A}
                            onChange={(e) => {
                                updateScVal("DPAW0001A", e.target.value);
                            }}
                            placeholder="Other Equipment Name"
                        />
                        {submitted && scVal.DPAW0001A.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Provide Other Equipment Type Name
                            </small>
                        )}
                    </div>
                }
                <div className="field">
                    <label htmlFor="vehno"> Owner of the equipment <span className='mandatory'>*</span> </label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        options={DualOptions}
                        value={scVal.DPAW0005}

                        onChange={(e) => updateScVal("DPAW0005", e.target.value)}
                    />
                    {submitted && scVal.DPAW0005 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Owner of the equipment is required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno1">Equipment Name <span className='mandatory'>*</span> </label>
                    <InputText
                        value={scVal.DPAW0008}
                        onChange={(e) => {
                            updateScVal("DPAW0008", e.target.value);
                        }}
                        placeholder="Equipment Name"
                    />
                    {submitted && scVal.DPAW0008.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Provide Equipment Name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="vehno1">Equipment Tag <span className='mandatory'>*</span> </label>
                    <InputText
                        value={scVal.DPAW0009}
                        onChange={(e) => {
                            updateScVal("DPAW0009", e.target.value);
                        }}
                        placeholder="Equipment Tag"
                    />
                    {submitted && scVal.DPAW0009.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Provide Equipment Tag
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="DPA0130">Select Fuel Category <span className='mandatory'>*</span> </label>
                    <Dropdown
                        value={scVal.DPA0130}
                        onChange={(e) => {
                            updateScVal("DPA0130", e.value);
                        }}
                        optionValue="id"
                        options={fuelcatlist.filter(i => { return allowedCategories.includes('1-' + i.id) || allowedCategories.length === 0 })}
                        optionLabel="title"
                        placeholder="Select"
                    />
                    {submitted && scVal.DPA0130 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Fuel Category is required
                        </small>
                    )}
                </div>
                {scVal.DPA0130 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0131">Select Fuel Type <span className='mandatory'>*</span> </label>
                        <Dropdown
                            value={scVal.DPA0131}
                            onChange={(e) => {
                                updateScVal("DPA0131", e.value);
                            }}
                            options={fuellist.filter(i => { return allowedCategories.includes('2-' + i.id) || allowedCategories.length === 0 })}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0131 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Fuel type is required
                            </small>
                        )}
                    </div>
                )}
                <div className="field">
                    <label htmlFor="vehno1"> Fuel provided by<span className='mandatory'>*</span> </label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        options={DualOptions}
                        value={scVal.DPAW0006}

                        onChange={(e) => updateScVal("DPAW0006", e.target.value)}
                    />
                    {submitted && scVal.DPAW0006 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Fuel provided by is required
                        </small>
                    )}
                </div>

                {scVal.DPA0131 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0132">Select Unit <span className='mandatory'>*</span> </label>
                        <Dropdown
                            value={scVal.DPA0132}
                            onChange={(e) => {
                                updateScVal("DPA0132", e.value);
                            }}
                            options={unitlist.filter(i => { return allowedCategories.includes('3-' + i.id) || allowedCategories.length === 0 })}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0132 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Unit
                            </small>
                        )}
                    </div>
                )}
                {(scVal.DPA0130 !== null &&
                    scVal.DPA0131 !== null &&
                    scVal.DPA0132 !== null)
                    && (
                        <>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="gasrefill"> Quantity of fuel used <span className='mandatory'>*</span> </label>
                                    <div id="gasrefill" className="p-inputgroup">
                                        <InputNumber
                                            maxFractionDigits={4}
                                            useGrouping={false}
                                            value={scVal.DPA0336}
                                            onChange={(e) => {
                                                updateScVal("DPA0336", e.value);
                                            }}
                                            placeholder="Quantity"
                                        />
                                        {/* {scVal.DPA0132 !== null && <span className="p-inputgroup-addon">{scVal.DPA0132.name} </span>} */}
                                    </div>
                                    {submitted && (parseFloat(scVal.DPA0336) < 0 || scVal.DPA0336 === null) && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Quantity is required
                                        </small>
                                    )}
                                </div>
                            </div>

                        </>
                    )}

                <>
                    <FileUpload
                        mode="basic"
                        customUpload
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(scVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], scVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = scVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setSCVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(scVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={1000000}
                    />

                </>
                {scVal.attachment !== null && (
                    <>
                        {scVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    scVal.attachment.splice(index, 1);
                                                    if (scVal.attachment.length === 0) {
                                                        scVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
            <Dialog visible={rejectdialog} modal
                className="p-fluid"
                onHide={() => {
                    setRejectDialog(false);
                }} style={{ width: '65%' }} header={'Import Status'} >
                <div>

                    <DataTable value={[...rejecteddata]} scrollable rows={10} rowsPerPageOptions={[10, 20, 50, 100]} >

                        {fields.map((i) => {
                            return (
                                <Column field={i} header={i} />
                            )
                        })}

                    </DataTable>
                    <div className='col-12 flex justify-content-end'>
                        <Button outlined label='Export Error Report' style={{ width: 170 }} onClick={downloadReport} ></Button>
                        {accepteddata.length && <Button className='ml-3' outlined style={{ width: 230 }} label={'Update Table with ' + accepteddata.length + ' Entries'} onClick={() => { setSCData(accepteddata); setRejectDialog(false); forceUpdate() }} ></Button>}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const Mobile_Combustion_Rotary = ({ extractedData = () => { }, data, getData, setData, edit, standard, dates = { minDate: null, maxDate: null } }) => {
    const [mcdata, setMCData] = useState(data);
    const [mcDialog, setMCDialog] = useState(false);
    const [load, setLoad] = useState(true);
    const forceUpdate = useForceUpdate();
    const [attachment, setAttachment] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [mcFilter, setMCFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [category, setCategory] = useState([]);
    const [category2, setCategory2] = useState([]);
    const [rejecteddata, setRejectedData] = useState([])
    const [accepteddata, setAcceptedData] = useState([])
    const [rejectdialog, setRejectDialog] = useState(false)
    const [fields, setFields] = useState([])
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [unitlist, setUnitList] = useState([]);
    const DualOptions = [
        { name: 'RTC', id: 1 },
        { name: 'REL', id: 2 },
        { name: 'REI', id: 3 },
        { name: 'RMC', id: 4 },
        { name: 'RMEC', id: 5 },
        { name: 'RIMC', id: 6 },
        { name: 'REIT', id: 7 },
        { name: 'TREL', id: 8 },
        { name: 'PTREI', id: 9 },
        { name: 'RELQ', id: 10 },
        { name: 'REF', id: 11 },
        { name: 'RESA', id: 12 },
        { name: 'Supermec', id: 13 }
    ]
    const [mcVal, setMcVal] = useState({
        DPAW0012: '',
        DPAW0003A: '',
        DPAW0010: '',
        DPAW0004A: '',
        DPAW0003: null,
        DPAW0004: null,
        DPAW0002: null,
        DPAW0002A: '',
        DPAW0007: null,
        DP_MODE: false,
        DPA0341: "",
        DPA0141: null,
        DPA0143: 0,
        DPA0142: null,
        attachment: null
    });
    useState(() => {
        setData();
    }, [mcdata]);
    getData(mcdata);
    useEffect(() => {
        let veh_cat = [];
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            setLoad(false)
            if (standard !== null && standard !== undefined) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === 29
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 50);

                        console.log(cat_index);
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat && subcat[0].id === 338) {
                                setCategory(subcat[0].newEfSubcategory2s)
                                console.log(subcat[0].newEfSubcategory2s);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }

                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    useEffect(() => {
        console.log(typeof extractedData)
        if (typeof extractedData === "function") {
            extractedData(extractData(mcdata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [mcdata, category]);


    function extractData(data) {
        let mappedData = category.flatMap(i =>
            i.newEfSubcategory3s?.flatMap(x =>
                x?.newEfSubcategory4s?.map(y => ({
                    key: 338 + "-" + i.id + "-" + x.id + "-" + y.id,
                    parent0id: i.id,
                    currentId: y.id,
                    parentId: x.id,
                    title1: i.title,
                    title2: x.title,
                    title3: y.title
                }))
            )
        ).filter((i) => i);
        let result = [];
        console.log(category)
        for (const item of data) {
            let index = mappedData.find(
                (i) =>
                    i.key ===
                    338 + "-" + item["DPAW0002"] + "-" + item["DPA0141"] + "-" + item["DPAW0007"]
            );
            console.log(index, mappedData);
            if (index) {

                result.push({
                    title: (item.DPAW0002 === 557 ? index.title1 + "/" + item.DPAW0002A : index.title1) + ">" + index.title2 + ">" + index.title3,
                    subCategory1: 338,
                    subCategory2: index.parent0id,
                    subCategory3: index.parentId,
                    subCategory4: index.currentId,
                    label: index.title2,
                    value: parseFloat(item.DPA0143),
                    currentId: index.currentId,
                    parentId: index.parentId,
                    formType: 2,
                    dataType: 0,
                    attachment: item.attachment,
                    uom: index.title3,
                    uniqueId: index.key,
                    maskId: item.id,
                    reportedDate: item.DPA0142
                });

            }
        }
        console.log(result, "RESULT");
        return result;
    }
    function findMatchingIds_level2(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 3}s`] !== undefined
                    ? item[`newEfSubcategory${i + 3}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    function validateAndConvertDate(serialOrString) {
        let date;

        // Check if the input is a number (serial date)
        if (!isNaN(serialOrString)) {
            // Convert serial number to date
            const excelEpoch = DateTime.fromISO("1899-12-30"); // Excel's base date for serial dates
            date = excelEpoch.plus({ days: Number(serialOrString) });

        } else {
            // Assume it's a string and parse the date
            date = DateTime.fromFormat(serialOrString, 'dd-MM-yyyy');
        }

        // Check if the date is valid
        if (!date.isValid) {
            return { isValid: false, message: 'Invalid date format or serial number.' };
        }

        // Convert to UTC string
        const utcDate = date.toUTC().toISO();

        return { isValid: true, utcDate };
    }
    const downloadReport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[]]);

        // Headers starting from the 2nd row and 2nd column (B2)
        let headers = fields
        headers.forEach((header, index) => {
            const cellAddress = XLSX.utils.encode_cell({ c: index + 1, r: 1 });
            ws[cellAddress] = { v: header };
        });

        // Set data starting from the 3rd row and 2nd column (B3)
        rejecteddata.forEach((row, rowIndex) => {
            Object.values(row).forEach((value, colIndex) => {
                const cellAddress = XLSX.utils.encode_cell({ c: colIndex + 1, r: rowIndex + 2 });
                ws[cellAddress] = { v: value };
            });
        });

        // Define the range to include headers and data
        const range = {
            s: { c: 1, r: 1 },
            e: { c: headers.length, r: rejecteddata.length + 2 }
        };
        ws['!ref'] = XLSX.utils.encode_range(range);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Data Entry');

        // Write the workbook
        XLSX.writeFile(wb, 'Error_In_Multiple_Import_Report.xlsx');
    }
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                let json = [];
                for (const sheet in wb.Sheets) {
                    console.log(sheet)
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0 && sheet === 'Data Entry'

                    ) {

                        const headerRow = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1, // Get all rows as arrays
                            range: 'B2:M2', // Specify that you're reading the second row for headers
                        });

                        // The headers should be the first element in the array
                        const headers = headerRow[0]; // Extract headers from the second row
                        console.log(headers)
                        setFields([...headers, 'Error(s)'])
                        // Now, extract the rest of the data starting from the third row onwards
                        const data = XLSX.utils.sheet_to_json(worksheet, {
                            header: headers, // Use the extracted headers for the data
                            range: 'B3:BZ1000', // Start reading from the second row
                            defval: null, // Default value for empty cells
                            blankrows: false, // Ignore blank rows
                        });
                        console.log(data)
                        json.push({
                            [sheet]: data
                        });
                    }
                }


                let local = {};
                let local2 = [];
                let accepted = [], rejected = []
                json.forEach((sname, i) => {
                    if (Object.keys(sname)[0] === "Data Entry") {

                        sname[Object.keys(sname)[0]].forEach((items) => {
                            local = {};

                            let index = -1;
                            let errorArray = []

                            if (!items['Project Code'] || !items['Project Code'].toString().trim().length) {
                                errorArray.push('Project Code Field is Required')
                            }
                            if (!items['Vehicle Name'] || !items['Vehicle Name'].toString().trim().length) {
                                errorArray.push('Vehicle Name Field is Required')
                            }
                            if (!items['Vehicle Registration Number'] || !items['Vehicle Registration Number'].toString().trim().length) {
                                errorArray.push('Vehicle Registration Number Field is Required')
                            }
                            if (!items['Vehicle/Equipment Type'] || !items['Vehicle/Equipment Type'].toString().trim().length) {
                                errorArray.push('Vehicle/Equipment Type Field is Required')
                            } else if (items['Vehicle/Equipment Type']) {
                                let typeIndex = category.findIndex((i) => i.title.trim().toLowerCase() === items['Vehicle/Equipment Type'].toString().toLowerCase().trim())
                                if (typeIndex !== -1) {
                                    if (items['Vehicle/Equipment Type'].toString().toLowerCase().trim() === 'others' && (!items[`Vehicle / Equipment Type Specify "Other" type of Vehicle`] || !items[`Vehicle / Equipment Type Specify "Other" type of Vehicle`].toString().trim().length)) {
                                        errorArray.push(`Vehicle / Equipment Type Specify "Other" type of Vehicle Field is Required`)
                                    }
                                } else {
                                    errorArray.push('Vehicle/Equipment Type Option Not Valid')

                                }
                            }

                            if (!items['Owner of the Vehicle/Equipment'] || !items['Owner of the Vehicle/Equipment'].toString().trim().length) {
                                errorArray.push('Owner of the Equipment is Required')
                            } else if (items['Owner of the Vehicle/Equipment']) {
                                let ownerIndex = DualOptions.findIndex((i) => i.name.trim().toLowerCase() === items['Owner of the Vehicle/Equipment'].toString().toLowerCase().trim())
                                if (ownerIndex !== -1) {
                                    if (DualOptions[ownerIndex].id === 14 && (!items[`Owner of the Vehicle / Equipment Name of the "Other Vendor"`] || !items[`Owner of the Vehicle / Equipment Name of the "Other Vendor"`].toString().trim().length)) {
                                        errorArray.push(`Owner of the Vehicle / Equipment Name of the "Other Vendor" Field is Required`)
                                    }
                                } else {
                                    errorArray.push('Owner of the Vehicle/Equipment Option Not Valid')

                                }

                            }
                            if (!items['Fuel provided by'] || !items['Fuel provided by'].toString().trim().length) {
                                errorArray.push('Fuel provided by is Required')
                            } else if (items['Fuel provided by']) {
                                let fuelProIndex = DualOptions.findIndex((i) => i.name.trim().toLowerCase() === items['Fuel provided by'].toString().toLowerCase().trim())
                                if (fuelProIndex !== -1) {
                                    if (DualOptions[fuelProIndex].id === 14 && (!items[`Fuel provided by - Name of the "Other Vendor"`] || !items[`Fuel provided by - Name of the "Other Vendor"`].toString().trim().length)) {
                                        errorArray.push(`Fuel provided by - Name of the "Other Vendor" is Required`)
                                    }
                                } else {
                                    errorArray.push('Fuel provided by Option Not Valid')

                                }
                            }
                            if (!items['Fuel Type'] || !items['Fuel Type'].toString().trim().length) {
                                errorArray.push('Fuel Type Field is Required')
                            }
                            if (!items['Unit'] || !items['Unit'].toString().trim().length) {
                                errorArray.push('Unit Field is Required')
                            }
                            if (!items['Total Fuel Filled'] || (!parseFloat(items['Total Fuel Filled'].toString().trim()) && parseFloat(items['Total Fuel Filled'].toString().trim()) !== 0)) {
                                errorArray.push('Total Fuel Filled is Required')
                            }
                            if (!items['Date of Refill']) {
                                errorArray.push('Date of Refill Field is Required')
                            } else if (items["Date of Refill"] && !validateAndConvertDate(items['Date of Refill']).isValid) {


                                errorArray.push('Date Format is Invalid')



                            } else if (items["Date of Refill"] && validateAndConvertDate(items['Date of Refill']).isValid && !isDateInRange(dates, validateAndConvertDate(items['Date of Refill']).utcDate)) {


                                errorArray.push('Date Is Not Matching With Reporting Period')



                            }
                            if (items['Vehicle/Equipment Type'] && items['Vehicle/Equipment Type'].toString().trim().length && items['Fuel Type'] && items['Fuel Type'].toString().trim().length && items['Unit'] && items['Unit'].toString().trim().length) {
                                const { result, id } = findMatchingIds_level2(
                                    category,
                                    items['Vehicle/Equipment Type'].toString(),
                                    items['Fuel Type'].toString(),
                                    items['Unit'].toString()
                                );
                                console.log(items, id)
                                if (result) {

                                } else {
                                    errorArray.push('Combination of Vehicle/Equipment Type & Fuel Type & Unit is Mismatch ')
                                }
                            }

                            if (
                                items['Project Code'] && items['Project Code'].toString().trim().length &&
                                items['Vehicle Name'] && items['Vehicle Name'].toString().trim().length !== 0 &&
                                items['Vehicle Registration Number'] && items['Vehicle Registration Number'].toString().trim().length &&
                                items['Vehicle/Equipment Type'] && items['Vehicle/Equipment Type'].toString().trim().length !== 0 &&
                                items['Owner of the Vehicle/Equipment'] && items['Owner of the Vehicle/Equipment'].toString().trim().length !== 0 &&
                                items['Fuel provided by'] && items['Fuel provided by'].toString().trim().length !== 0 &&
                                items['Fuel Type'] && items['Fuel Type'].toString().trim().length !== 0 &&
                                (items['Total Fuel Filled'] === 0 || items['Total Fuel Filled']) && parseFloat(items['Total Fuel Filled'].toString()) >= 0 &&
                                items['Unit'] && items['Unit'].toString().trim().length !== 0 &&
                                items['Date of Refill'] && validateAndConvertDate(items['Date of Refill']).isValid && isDateInRange(dates, validateAndConvertDate(items['Date of Refill']).utcDate)



                            ) {

                                let typeIndex = category.findIndex((i) => i.title.trim().toLowerCase() === items['Vehicle/Equipment Type'].toString().toLowerCase().trim())
                                let ownerIndex = DualOptions.findIndex((i) => i.name.trim().toLowerCase() === items['Owner of the Vehicle/Equipment'].toString().toLowerCase().trim())
                                let fuelProIndex = DualOptions.findIndex((i) => i.name.trim().toLowerCase() === items['Fuel provided by'].toString().toLowerCase().trim())
                                console.log(items, category, typeIndex, ownerIndex, items[`Owner of the Vehicle / Equipment Name of the "Other Vendor"`], fuelProIndex, items[`Fuel provided by - Name of the "Other Vendor"`])
                                if (typeIndex !== -1 && (items['Vehicle/Equipment Type'].toString().toLowerCase().trim() === 'others' ? (items[`Vehicle / Equipment Type Specify "Other" type of Vehicle`] && items[`Vehicle / Equipment Type Specify "Other" type of Vehicle`].toString().trim().length) : true) && ownerIndex !== -1 && fuelProIndex !== -1 && (DualOptions[ownerIndex].id === 14 ? (items[`Owner of the Vehicle / Equipment Name of the "Other Vendor"`] && items[`Owner of the Vehicle / Equipment Name of the "Other Vendor"`].toString().trim().length) : true) && (DualOptions[fuelProIndex].id === 14 ? (items[`Fuel provided by - Name of the "Other Vendor"`] && items[`Fuel provided by - Name of the "Other Vendor"`].toString().trim().length) : true)) {
                                    const { result, id } = findMatchingIds_level2(
                                        category,
                                        items['Vehicle/Equipment Type'].toString(),
                                        items['Fuel Type'].toString(),
                                        items['Unit'].toString()
                                    );
                                    console.log(result, id, items);
                                    if (result) {
                                        local["DPAW0012"] = items[`Project Code`].toString().trim()
                                        local["DP_MODE"] = false
                                        local["DPAW0002"] = id[0];
                                        local["DPA0141"] = id[1];
                                        local["DPAW0007"] = id[2];
                                        local["id"] = getID();
                                        local["DPAW0010"] = items['Vehicle Name'].toString().trim()
                                        local["attachment"] = null;
                                        local["DPA0143"] = parseFloat(items['Total Fuel Filled'].toString());
                                        if (items['Vehicle Registration Number'] && items['Vehicle Registration Number'].toString().trim().length) {
                                            local['DPA0341'] = items['Vehicle Registration Number'].toString()
                                        } else {
                                            local['DPA0341'] = ''
                                        }
                                        if (items['Vehicle/Equipment Type'].toString().toLowerCase().trim() === 'others') {
                                            local['DPAW0002A'] = items[`Vehicle / Equipment Type Specify "Other" type of Vehicle`].toString().trim()
                                        } else {
                                            local['DPAW0002A'] = ''
                                        }
                                        if (items['Date of Refill'] && validateAndConvertDate(items['Date of Refill']).isValid) {
                                            local['DPA0142'] = validateAndConvertDate(items['Date of Refill']).utcDate
                                        } else {
                                            local['DPA0142'] = null
                                        }
                                        local["DPAW0003"] = DualOptions[ownerIndex].id
                                        local["DPAW0004"] = DualOptions[fuelProIndex].id
                                        if (DualOptions[ownerIndex].id === 14) {
                                            local["DPAW0003A"] = items[`Owner of the Vehicle / Equipment Name of the "Other Vendor"`].toString().trim()
                                        } else {
                                            local["DPAW0003A"] = ''
                                        }
                                        if (DualOptions[fuelProIndex].id === 14) {
                                            local["DPAW0004A"] = items[`Fuel provided by - Name of the "Other Vendor"`].toString().trim()
                                        } else {
                                            local["DPAW0004A"] = ''
                                        }
                                        accepted.push({ ...local })

                                        local2.push(local);
                                    } else {
                                        rejected.push({ ...items, 'Error(s)': errorArray.join(',') })
                                    }
                                } else {
                                    rejected.push({ ...items, 'Error(s)': errorArray.join(',') })
                                }

                            } else {
                                rejected.push({ ...items, 'Error(s)': errorArray.join(',') })
                            }
                        });
                    }
                });
                if (rejected.length) {
                    setRejectedData(rejected)
                    setAcceptedData(accepted)
                    setRejectDialog(true)

                } else if (accepted.length) {
                    setMCData(local2)
                } else {
                    Swal.fire({
                        title: `No Entries Found In Uploaded Excel`,

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read motore about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                        }
                    })
                }



                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const updateMcVal = (obj, val) => {
        let local = mcVal;
        local[obj] = val;
        if (obj === 'DPAW0002') {
            setVehFuelList(category.filter(i => i.id === val).flatMap(i => i.newEfSubcategory3s))
            local['DPAW0007'] = null
            local['DPA0141'] = null
            local['DPAW0002A'] = ''

        } else if (obj === 'DPA0141') {
            setUnitList(vehfuellist.filter(i => i.id === val).flatMap(i => i.newEfSubcategory4s))

            local['DPAW0007'] = null

        }
        setMcVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setMcVal({
            DPAW0012: '',
            DPAW0003A: '',
            DPAW0002A: '',
            DPAW0010: '',
            DPAW0004A: '',
            DPAW0003: null,
            DPAW0004: null,
            DPAW0002: null,
            DPAW0007: null,
            DP_MODE: false,
            DPA0341: "",
            DPA0141: null,
            DPA0143: 0,
            DPA0142: null,
            attachment: null
        });
        setMCDialog(true);
        setSubmitted(false);
    };
    const saveMobileCombustion = () => {
        setSubmitted(true);
        let local = mcVal;
        let local2 = [...mcdata];
        let index = category.findIndex((i) => i.id === mcVal.DPAW0002);
        if (mcVal.DPAW0010.trim().length && mcVal.DPAW0012.trim().length && mcVal.DPA0142 !== null && mcVal.DPA0341.trim().length && mcVal.DPAW0002 !== null && (index !== -1 ? (category[index].title.trim().toLowerCase() === 'others' ? mcVal.DPAW0002A.trim().length : true) : false) && mcVal.DPAW0007 !== null && mcVal.DPAW0003 !== null && mcVal.DPAW0004 !== null && (mcVal.DPAW0003 === 14 ? mcVal.DPAW0003A.trim().length : true) && (mcVal.DPAW0004 === 14 ? mcVal.DPAW0004A.trim().length : true) &&
            mcVal.DPA0141 !== null && parseFloat(mcVal.DPA0143) >= 0
        ) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setMcVal({
                DPAW0012: '',
                DPAW0003A: '',
                DPAW0004A: '',
                DPAW0010: '',
                DPAW0003: null,
                DPAW0004: null,
                DPAW0002: null,
                DPAW0007: null,
                DP_MODE: false,
                DPA0341: "",
                DPA0141: null,
                DPA0143: 0,
                DPA0142: null,
                attachment: null
            });
            setMCData(local2);
            forceUpdate();
            setMCDialog(false);

        }
        console.log(mcdata);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setMCFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const mcDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveMobileCombustion();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        if (items.DPA0142) {
            items.DPA0142 = DateTime.fromISO(items.DPA0142, { zone: 'utc' }).toJSDate()
        }
        let index = category.findIndex((i) => i.id === items.DPAW0002);
        if (index !== -1 && category[index].newEfSubcategory3s !== undefined) {
            setVehFuelList(category[index].newEfSubcategory3s)
            let index2 = category[index].newEfSubcategory3s.findIndex(
                (i) => i.id === items.DPA0141
            );
            if (index2 !== -1) {
                setUnitList(category[index].newEfSubcategory3s[index2].newEfSubcategory4s)

            }
        }

        items.DPA0143 = parseFloat(items.DPA0143);

        setMcVal(items);
        setMCDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...mcdata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setMCData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const mcAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const vehicleTypeTemplate = (rowData) => {
        let text = "NA";

        let index = category.findIndex((i) => i.id === rowData.DPAW0002);
        if (index !== -1) {
            text = category[index].title;
        }


        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleOwnerTemplate = (rowData) => {
        let txt = 'NA'
        let index = DualOptions.findIndex((i) => i.id === rowData.DPAW0003);
        if (index !== -1) {
            if (rowData.DPAW0003 === 14) {
                txt = DualOptions[index].name + ' (' + rowData.DPAW0003A + ')'
            } else {
                txt = DualOptions[index].name
            }
        }
        return <>{txt}</>

    }
    const fuelProvidedTemplate = (rowData) => {
        let txt = 'NA'
        let index = DualOptions.findIndex((i) => i.id === rowData.DPAW0004);
        if (index !== -1) {
            if (rowData.DPAW0004 === 14) {
                txt = DualOptions[index].name + ' (' + rowData.DPAW0004A + ')'
            } else {
                txt = DualOptions[index].name
            }
        }
        return <>{txt}</>
    }
    const vehicleFuelTemplate = (rowData) => {
        let text = "NA";
        let index = category.findIndex((i) => i.id === rowData.DPAW0002);
        if (index !== -1 && category[index].newEfSubcategory3s !== undefined) {
            let index2 = category[index].newEfSubcategory3s.findIndex(
                (i) => i.id === rowData.DPA0141
            );
            if (index2 !== -1) {
                text = category[index].newEfSubcategory3s[index2].title;
            }
        }
        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleUnitTemplate = (rowData) => {
        let text = "NA";

        let index = category.findIndex((i) => i.id === rowData.DPAW0002);
        if (index !== -1 && category[index].newEfSubcategory3s !== undefined) {
            let index2 = category[index].newEfSubcategory3s.findIndex(
                (i) => i.id === rowData.DPA0141
            );
            if (index2 !== -1) {
                let index3 = category[index].newEfSubcategory3s[index2].newEfSubcategory4s.findIndex(
                    (i) => i.id === rowData.DPAW0007
                );
                if (index3 !== -1) {
                    text = category[index].newEfSubcategory3s[index2].newEfSubcategory4s[index3].title
                }

            }
        }
        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        window.open('https://api.eisqr.com/docs/1726669291668Rotary_MC_Template.xlsx')
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Mobile Combustion Emissions");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={mcdata}
                dataKey="id"
                loading={load}
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={mcFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    field={'DPAW0012'}
                    header="Project Code"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field={"DPAW0002"}
                    body={vehicleTypeTemplate}
                    header="Vehicle Type"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field={"DPAW0003"}
                    body={vehicleOwnerTemplate}
                    header="Vehicle Owner"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field={"DPAW0010"}

                    header="Vehicle Name"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field={"DPA0341"}

                    header="Vehicle Registration Number"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field={"DPAW0004"}
                    body={fuelProvidedTemplate}
                    header="Fuel Provided By"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Fuel Category"
                    body={vehicleFuelTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field={"DPA0143"}
                    header="Total Fuel Filled"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Unit"
                    body={vehicleUnitTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />



                <Column
                    field="attachment"
                    header="Attachment"
                    body={mcAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={mcDialog}
                style={{
                    width: "450px",
                }}
                header="Add Mobile Combustion"
                modal
                className="p-fluid"
                footer={mcDialogFooter}
                onHide={() => {
                    setMCDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="vehno1"> Project Code <span className='mandatory'>*</span> </label>
                    <InputText
                        value={mcVal.DPAW0012}
                        onChange={(e) => {
                            updateMcVal("DPAW0012", e.target.value);
                        }}
                        placeholder="Project Code"
                    />
                    {submitted && mcVal.DPAW0012.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Provide Project Code
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="vehno"> Vehicle Type <span className='mandatory'>*</span> </label>
                    <Dropdown
                        optionLabel="title"
                        optionValue="id"
                        options={category}
                        value={mcVal.DPAW0002}
                        onChange={(e) => updateMcVal("DPAW0002", e.target.value)}
                    />
                    {submitted && mcVal.DPAW0002 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Vehicle Type is required
                        </small>
                    )}
                </div>
                {mcVal.DPAW0002 !== null && (category.find(i => i.id === mcVal.DPAW0002).title.trim().toLowerCase() === 'others') &&
                    <div className="field">
                        <label htmlFor="vehno1"> Vehicle/Equipment Type Specify "Other" type of Vehicle <span className='mandatory'>*</span> </label>
                        <InputText
                            value={mcVal.DPAW0002A}
                            onChange={(e) => {
                                updateMcVal("DPAW0002A", e.target.value);
                            }}
                            placeholder="Other Vehicle/Equipment Name"
                        />
                        {submitted && mcVal.DPAW0002A.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Provide Vehicle/Equipment vendor name
                            </small>
                        )}
                    </div>
                }
                <div className="field">
                    <label htmlFor="vehno">  Owner of the Vehicle <span className='mandatory'>*</span> </label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        options={DualOptions}
                        value={mcVal.DPAW0003}

                        onChange={(e) => updateMcVal("DPAW0003", e.target.value)}
                    />
                    {submitted && mcVal.DPAW0003 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Owner of the vehicle is required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> Vehicle Name </label>
                    <InputText
                        value={mcVal.DPAW0010}
                        onChange={(e) => {
                            updateMcVal("DPAW0010", e.target.value);
                        }}
                        placeholder="Vehicle Name"
                    />
                    {submitted && mcVal.DPAW0010.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Provide Vehicle Name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="vehno"> Vehicle Registration Number </label>
                    <InputText
                        value={mcVal.DPA0341}
                        onChange={(e) => {
                            updateMcVal("DPA0341", e.target.value);
                        }}
                        placeholder="Vehicle Number"
                    />
                    {submitted && mcVal.DPA0341.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Provide Vehicle Registration Number
                        </small>
                    )}
                </div>
                {mcVal.DPAW0002 !== null &&
                    <div className="field">
                        <label htmlFor="vehno"> Fuel Category <span className='mandatory'>*</span> </label>
                        <Dropdown
                            optionLabel="title"
                            optionValue="id"
                            options={vehfuellist}
                            value={mcVal.DPA0141}
                            onChange={(e) => updateMcVal("DPA0141", e.target.value)}
                        />
                        {submitted && mcVal.DPA0141 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Fuel Category is required
                            </small>
                        )}
                    </div>
                }
                <div className="field">
                    <label htmlFor="vehno1"> Fuel provided by<span className='mandatory'>*</span> </label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        options={DualOptions}
                        value={mcVal.DPAW0004}

                        onChange={(e) => updateMcVal("DPAW0004", e.target.value)}
                    />
                    {submitted && mcVal.DPAW0004 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Fuel provided by is required
                        </small>
                    )}
                </div>




                {mcVal.DPA0141 !== null &&
                    <div className="field">
                        <label htmlFor="vehno">  Unit <span className='mandatory'>*</span> </label>
                        <Dropdown
                            optionLabel="title"
                            optionValue="id"
                            options={unitlist}
                            value={mcVal.DPAW0007}
                            onChange={(e) => updateMcVal("DPAW0007", e.target.value)}
                        />
                        {submitted && mcVal.DPAW0007 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Unit is required
                            </small>
                        )}
                    </div>
                }



                <div>
                    <div className="field">
                        <label htmlFor="refdate"> Date of Refill </label>
                        <Calendar
                            minDate={dates.minDate} maxDate={dates.maxDate}
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            showMinMaxRange={true}
                            showOtherMonths={false}
                            viewDate={dates.minDate}
                            value={mcVal.DPA0142}
                            onChange={(e) => updateMcVal("DPA0142", e.value)}
                        ></Calendar>
                        {submitted && mcVal.DPA0142 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Date of Refill
                            </small>
                        )}
                    </div>

                    <div className="field">
                        <label htmlFor="gasrefill">Total Fuel Filled <span className='mandatory'>*</span> </label>
                        <div id="gasrefill" className="p-inputgroup">
                            <InputNumber
                                maxFractionDigits={4}
                                useGrouping={false}
                                value={mcVal.DPA0143}
                                onChange={(e) => {
                                    updateMcVal("DPA0143", e.value);
                                }}
                                placeholder="Fuel Refilled"
                            />
                        </div>
                        {submitted && (parseInt(mcVal.DPA0143) < 0 || mcVal.DPA0143 === null) && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Fuel is required
                            </small>
                        )}
                    </div>
                </div>






                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(mcVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], mcVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = mcVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setMcVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(mcVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                        maxFileSize={10000000}
                    />

                </>
                {mcVal.attachment !== null && (
                    <>
                        {mcVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(API.Docs + i.name);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    mcVal.attachment.splice(index, 1);
                                                    if (mcVal.attachment.length === 0) {
                                                        mcVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(API.Docs + i.name);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
            <Dialog visible={rejectdialog} modal
                className="p-fluid"
                onHide={() => {
                    setRejectDialog(false);
                }} style={{ width: '65%' }} header={'Import Status'} >
                <div>

                    <DataTable value={[...rejecteddata]} scrollable rows={10} rowsPerPageOptions={[10, 20, 50, 100]} >

                        {fields.map((i) => {
                            return (
                                <Column field={i} header={i} />
                            )
                        })}

                    </DataTable>
                    <div className='col-12 flex justify-content-end'>
                        <Button outlined label='Export Error Report' style={{ width: 170 }} onClick={downloadReport} ></Button>
                        {accepteddata.length && <Button className='ml-3' outlined style={{ width: 230 }} label={'Update Table with ' + accepteddata.length + ' Entries'} onClick={() => { setMCData(accepteddata); setRejectDialog(false); forceUpdate() }} ></Button>}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const Hazardous_NonHazardous_Rotary = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    let DPAN0040Options = [
        { name: "Hazardous Waste", id: 1 },
        { name: "Non- Hazardous waste", id: 2 },
    ];
    let DPAN0042Options = [
        { name: "Food Waste", id: 14 },
        { name: "Plastic Waste", id: 2 },
        { name: "Metals - all types", id: 3 },
        { name: "Inert Waste (e.g. soil, rubble, sand etc.)", id: 15 },
        { name: "Asphalt Waste", id: 16 },
        { name: "Domestic Waste / Office Waste", id: 17 },
        { name: "Concrete and masonry waste", id: 18 },
        { name: "Glass - all types", id: 11 },
        { name: "Others", id: 12 },
    ];
    let DPAN0041Options = [
        { name: 'Spent Chemical Waste (Acid,  Alkali, Solvents etc.) and Packaging', id: 7 },
        { name: 'Metal Waste containing hazardous substances', id: 8 },
        { name: 'Flammable Material', id: 9 },
        { name: 'Welding Waste', id: 10 },
        { name: 'Metal Finishing Waste', id: 11 },
        { name: 'Waste Paint and Packaging Cans', id: 12 },
        { name: 'Cement Waste', id: 13 },
        { name: 'Abrasive Blasting Waste', id: 14 },
        { name: 'Oil and Petroleum Waste, Lubricants and Grease', id: 15 },
        { name: 'Waste electrical cables, electrical waste products', id: 16 },
        { name: 'Others', id: 6 }
    ];
    let DPAN0044Options = [
        { name: "Recovery of waste", id: 1 },
        { name: "Disposal of waste", id: 2 },
    ];

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN0040: null,
        DPAN0041: null,
        DPAN0042A: "",
        DPAN0041A: "",
        DPAN0042: null,
        DPAN0043: 0,
        DPAN0044: null,
        DPA0068A: false,
        DPA0069A: false,
        DPA0070A: false,
        DPA0068: 0,
        DPA0069: 0,
        DPA0070: [
            { mode: "", qty: 0 },
            { mode: "", qty: 0 },
        ],
        DPA0087A: false,
        DPA0088A: false,
        DPA0089A: false,
        DPA0087: 0,
        DPA0088: 0,
        DPA0089: 0,
        DPA0090A: false,
        DPA0090: [
            { mode: "", qty: 0 },
            { mode: "", qty: 0 },
        ],
    });
    useEffect(() => {
        console.log(typeof extractedData)
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata]);
    getData(badata)

    function extractData(data) {
        let mappedData = data
            .map((i) => ({
                key: `${i.DPAN0040} -${i?.DPAN0041 || i?.DPAN0042}`,
                currentId: i.DPAN0040 === 1 ? i?.DPAN0041 : i?.DPAN0042,
                parentId: i.DPAN0040,
                title1: i.DPAN0040 === 1 ? i.DPAN0041 === 6 ? 'Others/' + i.DPAN0041A : DPAN0041Options.find((it) => it.id === i.DPAN0041)?.name : i.DPAN0042 === 12 ? 'Others/' + i.DPAN0042A : DPAN0042Options.find((it) => it.id === i.DPAN0042)?.name,
                title2: DPAN0040Options.find((it) => it.id === i.DPAN0040)?.name,
                title3: 'MT',
            }))
            .filter((i) => i.key);

        let result = [];

        for (const item of data) {
            let index = mappedData.find(
                (i) => i.key === `${item.DPAN0040} -${item?.DPAN0041 || item?.DPAN0042}`
            );

            if (index) {
                result.push({
                    title: index.title2 + ">" + index.title1,
                    label: index.title1,
                    value: item.DPAN0043,
                    currentId: index.currentId,
                    parentId: index.parentId,
                    formType: 2,
                    dataType: 2,
                    isNull: false,
                    attachment: item.attachment || null,
                    uom: index.title3,
                    uniqueId: index.key,
                    maskId: item.id,
                    additionalValue1: DPAN0044Options.find((i) => i.id === item?.DPAN0044)?.name || null
                });
            }
        }
        console.log(result, "RESULT");
        return result;
    }
    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        if (obj === "DPAN0040") {
            local["DPAN0041"] = null;
            local["DPAN0043"] = 0;
            local["DPAN0042"] = null;
            local["DPAN0044"] = null;
        }
        if (obj === "DPAN0044") {
            local["DPA0068A"] = false;
            local["DPA0069A"] = false;
            local["DPGRWAD7"] = false;
            local["DPGRWAD8"] = false;
            local["DPGRWAD9"] = false;
            local["DPGRWAD10"] = false;
            local["DPGRWAD11"] = false;

            local["DPA0068"] = 0;
            local["DPA0069"] = 0;
            local["DPA0087"] = 0;
            local["DPA0088"] = 0;
            local["DPA0089"] = 0;
            local["DPA0070"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
            local["DPA0090"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        if (obj === "DPA0068A") {
            local["DPA0068"] = 0;
        }
        if (obj === "DPA0069A") {
            local["DPA0069"] = 0;
        }
        if (obj === "DPA0087A") {
            local["DPA0087"] = 0;
        }
        if (obj === "DPA0088A") {
            local["DPA0088"] = 0;
        }
        if (obj === "DPA0089A") {
            local["DPA0089"] = 0;
        }
        if (obj === "DPA0070A") {
            local["DPA0070"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        if (obj === "DPA0090A") {
            local["DPA0090"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPAN0040: null,
            DPAN0041: null,
            DPAN0042A: "",
            DPAN0041A: "",
            DPAN0042: null,
            DPAN0043: 0,
            DPAN0044: null,
            DPA0068A: false,
            DPA0069A: false,
            DPA0070A: false,
            DPA0068: 0,
            DPA0069: 0,
            DPA0070: [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ],
            DPA0087A: false,
            DPA0088A: false,
            DPA0089A: false,
            DPA0087: 0,
            DPA0088: 0,
            DPA0089: 0,
            DPA0090A: false,
            DPA0090: [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ],
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = [...badata];

        if (
            dataVal.DPAN0040 !== null &&
            dataVal.DPAN0044 !== null &&
            dataVal.DPAN0043 >= 0 &&
            (dataVal.DPAN0040 === 1
                ? dataVal.DPAN0041 === 6 ?
                    dataVal.DPAN0041A.trim().length !== 0
                    : true
                : true


            ) &&
            (dataVal.DPAN0040 === 2
                ? dataVal.DPAN0042 === 12
                    ? dataVal.DPAN0042A.trim().length !== 0
                    : true
                : true)
        ) {
            if (dataVal.DPAN0044 === 1) {
                if (
                    (dataVal.DPA0068A === true && dataVal.DPA0068 > 0) ||
                    (dataVal.DPA0069A === true && dataVal.DPA0069 > 0) ||
                    (dataVal.DPA0070A === true &&
                        dataVal.DPA0070.some(
                            (item) => item.mode.trim().length !== 0 && item.qty > 0
                        ))
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }
                    setBAData(local2);
                    forceUpdate();
                    setBADialog(false);
                }
            } else {
                if (
                    (dataVal.DPA0087A === true && dataVal.DPA0087 > 0) ||
                    (dataVal.DPA0088A === true && dataVal.DPA0088 > 0) ||
                    (dataVal.DPA0089A === true && dataVal.DPA0089 > 0) ||
                    (dataVal.DPA0090A === true &&
                        dataVal.DPA0090.some(
                            (item) => item.mode.trim().length !== 0 && item.qty > 0
                        ))
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }
                    setBAData(local2);
                    forceUpdate();
                    setBADialog(false);
                }
            }
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const categoryTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN0040Options.findIndex((i) => i.id === rowData.DPAN0040);
        if (index !== -1) {
            txt = DPAN0040Options[index].name;
        }
        console.log(rowData.DPAN0040, index);
        return <> {txt} </>;
    };
    const typeTemplate = (rowData) => {
        let txt = "Not Found";
        if (rowData.DPAN0040 === 1) {
            let index = DPAN0041Options.findIndex((i) => i.id === rowData.DPAN0041);
            if (index !== -1) {
                txt = DPAN0041Options[index].name;
            }
        } else {
            let index = DPAN0042Options.findIndex((i) => i.id === rowData.DPAN0042);
            if (index !== -1) {
                txt = DPAN0042Options[index].name;
            }
        }

        return <> {txt} </>;
    };
    const managementTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN0044Options.findIndex((i) => i.id === rowData.DPAN0044);
        if (index !== -1) {
            txt = DPAN0044Options[index].name;
        }
        return <> {txt} </>;
    };

    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Category of Waste Generated"
                    body={(rowData) => {
                        return categoryTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Type of Hazardous waste generated"
                    body={(rowData) => {
                        return typeTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Quantity Generated"
                    field="DPAN0043"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Management of waste"
                    body={(rowData) => {
                        return managementTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Category of Waste Generated</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN0040}
                        options={DPAN0040Options}
                        onChange={(e) => {
                            updateDataVal("DPAN0040", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0040 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select category
                        </small>
                    )}
                </div>
                {dataVal.DPAN0040 === 1 ? (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">
                                Type of Hazardous waste generated
                            </label>
                            <Dropdown
                                optionLabel="name"
                                optionValue="id"
                                value={dataVal.DPAN0041}
                                options={DPAN0041Options}
                                onChange={(e) => {
                                    updateDataVal("DPAN0041", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN0041 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select category
                                </small>
                            )}
                        </div>
                        {dataVal.DPAN0041 === 6 && (
                            <div className="field">
                                <label htmlFor="ecpsngr">Others(please mention type)</label>
                                <InputText
                                    type={"text"}
                                    value={dataVal.DPAN0041A}
                                    onChange={(e) => {
                                        updateDataVal("DPAN0041A", e.target.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN0041A.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Enter type of Hazardous
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">
                                Type of Non-Hazardous waste generated
                            </label>
                            <Dropdown
                                optionLabel="name"
                                optionValue="id"
                                value={dataVal.DPAN0042}
                                options={DPAN0042Options}
                                onChange={(e) => {
                                    updateDataVal("DPAN0042", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN0042 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select category
                                </small>
                            )}
                        </div>
                        {dataVal.DPAN0042 === 12 && (
                            <div className="field">
                                <label htmlFor="ecpsngr">Others(please mention type)</label>
                                <InputText
                                    type={"text"}
                                    value={dataVal.DPAN0042A}
                                    onChange={(e) => {
                                        updateDataVal("DPAN0042A", e.target.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN0042A.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Enter type of Non-Hazardous
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                )}

                <div className="field">
                    <label htmlFor="ecpsngr">Quantity Generated (In Metric Tonnes)</label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPAN0043}
                        onChange={(e) => {
                            updateDataVal("DPAN0043", e.value);
                        }}
                    />
                    {submitted && (dataVal.DPAN0043 === null || dataVal.DPAN0043 < 0) && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Management of waste</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN0044}
                        options={DPAN0044Options}
                        onChange={(e) => {
                            updateDataVal("DPAN0044", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0044 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select type
                        </small>
                    )}
                </div>
                {dataVal.DPAN0044 === 1 ? (
                    <div className="flex grid justify-content-center gap-3">
                        <div className="flex align-items-center col-12">
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient1"
                                    name="pizza"
                                    value="Cheese"
                                    onChange={(e) => {
                                        updateDataVal("DPA0068A", e.checked);
                                    }}
                                    checked={dataVal.DPA0068A === true}
                                />
                                <label htmlFor="ingredient1" className="ml-2">
                                    Preparation for reuse
                                </label>
                            </div>
                            <div className="col-4">
                                <InputNumber
                                    value={dataVal.DPA0068}
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    disabled={dataVal.DPA0068A === false}
                                    onValueChange={(e) => {
                                        updateDataVal("DPA0068", e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex align-items-center col-12">
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient2"
                                    name="pizza"
                                    value="Mushroom"
                                    onChange={(e) => {
                                        updateDataVal("DPA0069A", e.checked);
                                    }}
                                    checked={dataVal.DPA0069A === true}
                                />
                                <label htmlFor="ingredient2" className="ml-2">
                                    Recycling
                                </label>
                            </div>
                            <div className="col-4">
                                <InputNumber
                                    value={dataVal.DPA0069}
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    disabled={dataVal.DPA0069A === false}
                                    onValueChange={(e) => {
                                        updateDataVal("DPA0069", e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex  col-12" style={{ flexDirection: "column" }}>
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient3"
                                    name="pizza"
                                    value="Pepper"
                                    onChange={(e) => {
                                        updateDataVal("DPA0070A", e.checked);
                                    }}
                                    checked={dataVal.DPA0070A === true}
                                />
                                <label htmlFor="ingredient3" className="ml-2">
                                    Other recovery operations
                                </label>
                            </div>
                            {dataVal.DPA0070A === true && (
                                <div className="col-12">
                                    <div className="col-12 grid">
                                        <div className="col-8">Mode</div>
                                        <div className="col-4">Quantity</div>
                                    </div>
                                    {dataVal.DPA0070.map((item) => {
                                        return (
                                            <div className="col-12 grid">
                                                <div className="col-8">
                                                    <InputText
                                                        type={"text"}
                                                        value={item.mode}
                                                        onChange={(e) => {
                                                            item.mode = e.target.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <InputNumber
                                                        maxFractionDigits={4}
                                                        useGrouping={false}
                                                        value={item.qty}
                                                        onChange={(e) => {
                                                            item.qty = e.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    dataVal.DPAN0044 === 2 && (
                        <div className="flex grid justify-content-center gap-3">
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="pizza"
                                        value="Cheese"
                                        onChange={(e) => {
                                            updateDataVal("DPA0087A", e.checked);
                                        }}
                                        checked={dataVal.DPA0087A === true}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        Incineration (with energy recovery)
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0087}
                                        disabled={dataVal.DPA0087A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0087", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="pizza"
                                        value="Cheese"
                                        onChange={(e) => {
                                            updateDataVal("DPA0088A", e.checked);
                                        }}
                                        checked={dataVal.DPA0088A === true}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        Incineration (without energy recovery)
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0088}
                                        disabled={dataVal.DPA0088A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0088", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient2"
                                        name="pizza"
                                        value="Mushroom"
                                        onChange={(e) => {
                                            updateDataVal("DPA0089A", e.checked);
                                        }}
                                        checked={dataVal.DPA0089A === true}
                                    />
                                    <label htmlFor="ingredient2" className="ml-2">
                                        Landfilling
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0089}
                                        disabled={dataVal.DPA0089A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0089", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex  col-12" style={{ flexDirection: "column" }}>
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient3"
                                        name="pizza"
                                        value="Pepper"
                                        onChange={(e) => {
                                            updateDataVal("DPA0090A", e.checked);
                                        }}
                                        checked={dataVal.DPA0090A === true}
                                    />
                                    <label htmlFor="ingredient3" className="ml-2">
                                        Other disposal operations
                                    </label>
                                </div>
                                {dataVal.DPA0090A === true && (
                                    <div className="col-12">
                                        <div className="col-12 grid">
                                            <div className="col-8">Mode</div>
                                            <div className="col-4">Quantity</div>
                                        </div>
                                        {dataVal.DPA0090.map((item) => {
                                            return (
                                                <div className="col-12 grid">
                                                    <div className="col-8">
                                                        <InputText
                                                            type={"text"}
                                                            value={item.mode}
                                                            onChange={(e) => {
                                                                item.mode = e.target.value;
                                                                forceUpdate();
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <InputNumber
                                                            maxFractionDigits={4}
                                                            useGrouping={false}
                                                            value={item.qty}
                                                            onChange={(e) => {
                                                                item.qty = e.value;
                                                                forceUpdate();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                )}
            </Dialog>
        </div>
    );
};
const VehicleSold = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const QLBP7L1D4Options = [
        { name: "Annually", id: 1 },
        { name: "Half yearly", id: 2 },
        { name: "Quarterly", id: 3 },
        { name: "Others", id: 4 },
    ];

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPTVS0001: "",
        DPTVS0002: 0,
    });
    getData(badata)
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata]);

    function extractData(data) {
        console.log(data)
        let mappedData = data.map(i => ({
            label: i.DPTVS0001,
            title: i.DPTVS0001 + ">nos",
            uom: "nos",
            isNull: false,
            uniqueId: `DPTVS0001`,
            formType: 2,
            dataType: 1,
            maskId: i.id,
            value: i.DPTVS0002,
        }))


        console.log(mappedData, "RESULT");
        return mappedData;
    }
    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPTVS0001: "",
            DPTVS0002: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = [...badata];

        if (
            dataVal.DPTVS0001.trim().length !== 0 &&

            dataVal.DPTVS0002 >= 0

        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPTVS0001: "",

                DPTVS0002: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {

                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>

                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    console.log(e);
                                }}
                            />
                        </div> */}

                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPS0184", "DPS0185", "DPS0186"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Name of the Vehicle"
                    field="DPTVS0001"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total number of vehicle sold"
                    field="DPTVS0002"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Name of the Vehicle
                    </label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPTVS0001}
                        onChange={(e) => {
                            updateDataVal("DPTVS0001", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0001.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Name of the Vehicle
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="ecpsngr">
                        Total number of vehicle sold
                    </label>
                    <InputNumber

                        useGrouping={false}
                        value={dataVal.DPTVS0002}
                        onChange={(e) => {
                            updateDataVal("DPTVS0002", e.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0002 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Total number of vehicle sold
                        </small>
                    )}
                </div>


            </Dialog>
        </div>
    );
};
const DistanceTravelledVehicle = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const QLBP7L1D4Options = [
        { name: "Annually", id: 1 },
        { name: "Half yearly", id: 2 },
        { name: "Quarterly", id: 3 },
        { name: "Others", id: 4 },
    ];

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPTVS0001: "",

        DPTVS0078: 0,
    });
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata]);
    getData(badata)
    function extractData(data) {
        console.log(data)
        let mappedData = data.map(i => ({
            label: i.DPTVS0001,
            title: i.DPTVS0001 + ">km",
            uom: "km",
            isNull: false,
            uniqueId: `DPTVS0001`,
            formType: 2,
            dataType: 1,
            maskId: i.id,
            value: i.DPTVS0078,
        }))


        console.log(mappedData, "RESULT");
        return mappedData;
    }
    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPTVS0001: "",

            DPTVS0078: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = [...badata];

        if (
            dataVal.DPTVS0001.trim().length !== 0 &&

            dataVal.DPTVS0078 >= 0

        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPTVS0001: "",

                DPTVS0078: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {

                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>

                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    console.log(e);
                                }}
                            />
                        </div> */}

                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPS0184", "DPS0185", "DPS0186"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Name of the Vehicle"
                    field="DPTVS0001"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Average Lifetime KM driven by the vehicle"
                    field="DPTVS0078"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Name of the Vehicle
                    </label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPTVS0001}
                        onChange={(e) => {
                            updateDataVal("DPTVS0001", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0001.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Name of the Vehicle
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="ecpsngr">
                        Average Lifetime KM driven by the vehicle
                    </label>
                    <InputNumber

                        useGrouping={false}
                        value={dataVal.DPTVS0078}
                        onChange={(e) => {
                            updateDataVal("DPTVS0078", e.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0078 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Average Lifetime KM driven by the vehicle
                        </small>
                    )}
                </div>


            </Dialog>
        </div>
    );
};
const VehicleInformation = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);



    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPTVS0001: "",
        DPTVS0003: "",

        DPTVS0004: 0,
        DPTVS0005: 0,
    });
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata]);

    function extractData(data) {
        console.log(data)
        let mappedData = data
            .map((i) => ([{
                label: i.DPTVS0001,
                title: i.DPTVS0001 + ">year(s)",
                uom: "year(s)",
                isNull: false,
                uniqueId: `DPTVS0001-DPTVS0004`,
                formType: 2,
                dataType: 1,
                maskId: i.id,
                value: i.DPTVS0004,
            }, {
                label: i.DPTVS0003,
                title: i.DPTVS0003 + ">km",
                uom: "km",
                isNull: false,
                uniqueId: `DPTVS0003-DPTVS0005`,
                formType: 2,
                dataType: 1,
                maskId: i.id + 'DPTVS0003',
                value: i.DPTVS0005,
            }]))
            .flatMap(x => x).filter((i) => i.maskId);




        console.log(mappedData, "RESULT");
        return mappedData;
    }
    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPTVS0001: "",
            DPTVS0003: "",
            DPTVS0004: 0,
            DPTVS0005: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = [...badata];

        if (
            dataVal.DPTVS0001.trim().length !== 0 &&
            dataVal.DPTVS0003.trim().length !== 0 &&

            dataVal.DPTVS0004 >= 0 &&
            dataVal.DPTVS0005 >= 0
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPTVS0001: "",
                DPTVS0003: "",

                DPTVS0004: 0,
                DPTVS0005: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    getData(badata)
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {

                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>

                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    console.log(e);
                                }}
                            />
                        </div> */}

                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPS0184", "DPS0185", "DPS0186"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Name of the Vehicle"
                    field="DPTVS0001"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Type of Energy Used"
                    field="DPTVS0003"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Average Lifetime of the product"
                    field="DPTVS0004"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Average Mileage of the Vehicle"
                    field="DPTVS0005"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Name of the Vehicle
                    </label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPTVS0001}
                        onChange={(e) => {
                            updateDataVal("DPTVS0001", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0001.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Name of the Vehicle
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Type of Energy Used</label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPTVS0003}
                        onChange={(e) => {
                            updateDataVal("DPTVS0003", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0003.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Type of Energy Used
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Average Lifetime of the product
                    </label>
                    <InputNumber

                        useGrouping={false}
                        value={dataVal.DPTVS0004}
                        onChange={(e) => {
                            updateDataVal("DPTVS0004", e.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0004 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Average Lifetime of the product
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Average Mileage of the Vehicle</label>
                    <InputNumber

                        useGrouping={false}
                        value={dataVal.DPTVS0005}
                        onChange={(e) => {
                            updateDataVal("DPTVS0005", e.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0005 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Average Mileage of the Vehicle
                        </small>
                    )}
                </div>


            </Dialog>
        </div>
    );
};
const SoldProduct = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);
    const [products, setProducts] = useState([]);
    const [materials, setMaterials] = useState([]);

    const [newMaterial, setNewMaterial] = useState("");
    const [detailsDialog, setDetailsDialog] = useState(false);
    const [materialDialog, setMaterialDialog] = useState(false);
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [materialDetails, setMaterialDetails] = useState({});
    const [formData, setFormData] = useState({
        DPTVS0009: 0,
        DPTVS0010: 0,
        DPTVS0011: 0
    });
    const [materialsData, setMaterialsData] = useState("")
    const [newProduct, setNewProduct] = useState("");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);

    const [editMaterialDialog, setEditMaterialDialog] = useState(false);
    const [editingDetailIndex, setEditingDetailIndex] = useState(null);

    const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(null);


    const forceUpdate = useForceUpdate();

    const [MatVal, setDataMatVal] = useState({
        DPTVS0007: "",
        DPTVS0008: "",
        DPTVS0009: 0,
        DPTVS0010: 0,
        DPTVS0011: 0
    });
    getData(badata)

    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata]);

    function extractData(data) {
        console.log(data)
        let mappedData = []


        console.log(mappedData, "RESULT");
        return mappedData;
    }
    const updateDataVal = (obj, val) => {
        let local = MatVal;

        local[obj] = val;
        console.log(obj, val);
        setDataMatVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataMatVal({
            DPTVS0001: "",
            DPTVS0003: "",

            DPTVS0004: 0,
            DPTVS0005: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };



    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataMatVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    // const openDetailsDialog = (material) => {
    //     setSelectedMaterial(material);
    //     setDetailsDialog(true);
    // };

    const openDetailsDialog = (material, detail = null, detailIndex = null) => {
        setSelectedMaterial(material);
        setEditingDetailIndex(detailIndex); // Store index if editing
        if (detail) {
            // If editing, pre-fill the form
            setFormData({ ...detail });
        } else {
            // If adding, clear the form
            setFormData({ DPTVS0009: 0, DPTVS0010: 0, DPTVS0011: 0 });
        }
        setDetailsDialog(true);
    };


    // Add a new product
    const addProduct = () => {
        if (newProduct.trim()) {
            const newId = products.length ? products[products.length - 1].id + 1 : 1;
            setProducts([...products, { id: newId, name: newProduct, materials: [] }]);
            setNewProduct("");
            setBADialog(false);
        }
    };

    // material add check ttesting
    const addMaterial = () => {
        if (newMaterial.trim() && selectedProductIndex !== null) {
            const updatedProducts = [...products];
            updatedProducts[selectedProductIndex].materials.push(newMaterial);
            setProducts(updatedProducts);
            setNewMaterial("");
            setMaterialDialog(false);
        }
    };



    // Open dialog for adding a new product
    const openProductDialog = () => {
        setSelectedProduct(null);  // Reset selection
        setNewProduct("");  // Clear input
        setBADialog(true);
    };

    // Open Material Dialog
    const openMaterialDialog = (index) => {
        setSelectedProductIndex(index);
        setMaterialDialog(true);
        setSelectedMaterial(null);  // Reset selection
        setNewMaterial("");  // Clear input

    };

    // Open dialog for editing an existing product
    const openEditProduct = (product) => {
        setSelectedProduct(product);
        setNewProduct(product.name);
        setBADialog(true);
    };



    // Add or edit product
    const saveProduct = () => {
        if (newProduct.trim()) {
            if (selectedProduct) {
                // Editing existing product
                setProducts((prevProducts) =>
                    prevProducts.map((p) =>
                        p.id === selectedProduct.id ? { ...p, name: newProduct } : p
                    )
                );
            } else {
                // Adding new product
                const newId = products.length ? products[products.length - 1].id + 1 : 1;
                setProducts([...products, { id: newId, name: newProduct, materials: [] }]);
            }
            setBADialog(false);
            setNewProduct("");
        }
    };


    // Add Material to Product
    // const addMaterial = () => {
    //     if (newMaterial.trim() && selectedProductIndex !== null) {
    //         const updatedProducts = [...products];
    //         updatedProducts[selectedProductIndex].materials.push(newMaterial);
    //         setProducts(updatedProducts);
    //         setNewMaterial("");
    //         setMaterialDialog(false);
    //     }
    // };
    const handleChange = (e) => {
        if (e?.originalEvent?.target?.name) {

            setFormData({ ...formData, [e?.originalEvent?.target?.name]: e.value });
        }
        // 
    };

    const saveDetails = () => {
        if (selectedProductIndex !== null && selectedMaterial !== null) {
            const updatedProducts = [...products];
            updatedProducts[selectedProductIndex].materials[selectedMaterial].details.push({
                DPTVS0009: formData.DPTVS0009,
                DPTVS0010: formData.DPTVS0010,
                DPTVS0011: formData.DPTVS0011
            });

            setProducts(updatedProducts);
        }
        setFormData({ DPTVS0009: 0, DPTVS0010: 0, DPTVS0011: 0 });
        setDetailsDialog(false);
    };

    // const addMaterialDetails = () => {
    //     if (!formData.weight || !formData.recycling || !formData.landfill) return;

    //     setMaterialDetails((prevDetails) => ({
    //         ...prevDetails,
    //         [selectedMaterial]: [...(prevDetails[selectedMaterial] || []), { ...formData }]
    //     }));

    //     setDetailsDialog(false);
    // };

    const addMaterialDetails = () => {
        if (!(formData.DPTVS0010 != null) || !(formData.DPTVS0011 != null) || !(formData.DPTVS0009 != null)) return;

        const updatedDetails = { ...materialDetails };
        if (!updatedDetails[selectedMaterial]) {
            updatedDetails[selectedMaterial] = [];
        }

        if (editingDetailIndex !== null) {
            // If editing, update existing detail
            updatedDetails[selectedMaterial][editingDetailIndex] = { ...formData };
        } else {
            // If adding, push new entry
            updatedDetails[selectedMaterial].push({ ...formData });
        }

        setMaterialDetails(updatedDetails);
        setDetailsDialog(false);
        setEditingDetailIndex(null); // Reset after save
    };

    const deleteProduct = (index) => {
        setProducts(products.filter((_, i) => i !== index));
    };

    const deleteMaterial = (productIdx, matIdx) => {
        const updatedProducts = [...products];

        if (updatedProducts[productIdx] && updatedProducts[productIdx].materials[matIdx]) {
            updatedProducts[productIdx].materials.splice(matIdx, 1);
            setProducts(updatedProducts);
        }
    };

    const openEditMaterialDialog = (productIndex, matIndex) => {
        setSelectedProductIndex(productIndex);
        setSelectedMaterialIndex(matIndex);
        setNewMaterial(products[productIndex].materials[matIndex]); // Pre-fill with selected material
        setSelectedMaterial(products[productIndex].materials[matIndex]); // Mark it for editing
        setMaterialDialog(true);
    };

    const saveEditedMaterial = () => {
        if (newMaterial.trim() && selectedProductIndex !== null && selectedMaterialIndex !== null) {
            const updatedProducts = [...products];
            updatedProducts[selectedProductIndex].materials[selectedMaterialIndex] = newMaterial; // Update material name
            setProducts(updatedProducts);

            // Reset state after editing
            setMaterialDialog(false);
            setNewMaterial("");
            setSelectedMaterial(null);
            setSelectedMaterialIndex(null);
        }
    };

    // Delete Material Detail
    const deleteMaterialDetail = (material, index) => {
        const updatedDetails = materialDetails[material].filter((_, i) => i !== index);
        setMaterialDetails({
            ...materialDetails,
            [material]: updatedDetails,
        });
    };

    return (
        <div>
            {/* Add Product Button */}
            {/* <div className="col-12 p-0 align-items-center" style={{ display: "flex", margin: "10px 0px", flexDirection: "row" }}> */}
            <div className="col-12 flex p-0 justify-content-end" style={{ margin: '10px 0px' }}>
                <Button
                    label="Add Product"
                    icon="pi pi-plus"
                    className="navy-button"
                    style={{ width: 150 }}
                    // onClick={() => setBADialog(true)}
                    onClick={openProductDialog}
                />
            </div>
            {/* </div> */}

            {/* Accordion with Products and Materials */}

            <Accordion >
                {products.map((product, index) => (
                    <AccordionTab
                        key={index}
                        headerClassName="accfull"
                        header={
                            <div className="flex align-items-center gap-2 w-full">
                                <div className="col-11"> <span style={{ fontWeight: "bold" }}>{product.name}</span> </div>
                                <div className="flex align-items-center gap-2 w-full">
                                    <Button
                                        icon="pi pi-pencil"
                                        className="p-button-rounded p-button-text"
                                        onClick={() => openEditProduct(product)}


                                    />

                                    <Button
                                        icon="pi pi-trash"
                                        className="p-button-rounded p-button-text p-button-danger"
                                        onClick={() => deleteProduct(index)}


                                    />
                                </div>
                            </div>


                        }


                    >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "10px" }}>
                            <span style={{ fontWeight: "bold", fontSize: "14px", color: "#333" }}>
                                Materials List
                            </span>
                            <Button
                                label="Add Material"
                                icon="pi pi-plus"
                                className="navy-button"
                                style={{ width: 150 }}
                                onClick={() => openMaterialDialog(index)}
                            />
                        </div>

                        {/* Materials List */}
                        {product.materials.length > 0 ? (
                            <Accordion>
                                {product.materials.map((material, matIndex) => (
                                    <AccordionTab key={matIndex} headerClassName="accfull" header={
                                        <div className="flex align-items-center gap-2 w-full">

                                            <div className="col-11"> <span>{material}</span> </div>
                                            <div className="flex align-items-center gap-2 w-full">
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="p-button-rounded p-button-text"
                                                    onClick={() => openEditMaterialDialog(selectedProductIndex, matIndex)}
                                                />

                                                <Button
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-text p-button-danger"
                                                    onClick={() => deleteMaterial(selectedProductIndex, matIndex)}
                                                />
                                            </div>
                                        </div>
                                    }>

                                        <div style={{ padding: "10px", background: "#f9f9f9", borderRadius: "8px" }}>
                                            {/* Add Details Button Inside Material Accordion */}
                                            <Button
                                                label="Add Details"
                                                icon="pi pi-plus"
                                                className="navy-button"
                                                style={{ width: 150 }}
                                                onClick={() => openDetailsDialog(material)}
                                                style={{ float: 'right' }}
                                            />

                                            {/* Table Format for Material Details */}
                                            {materialDetails[material] && materialDetails[material].length > 0 ? (
                                                <table style={{ width: "100%", borderCollapse: "collapse", background: "#fff" }}>
                                                    <thead>
                                                        <tr style={{ background: "#eaeaea", textAlign: "left" }}>
                                                            <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Weight Used</th>
                                                            <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Recycled</th>
                                                            <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Landfilled</th>
                                                            <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {materialDetails[material].map((detail, detailIndex) => (
                                                            <tr key={detailIndex}>
                                                                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{detail.DPTVS0009}</td>
                                                                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{detail.DPTVS0010}</td>
                                                                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{detail.DPTVS0011}

                                                                </td>
                                                                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>

                                                                    <Button
                                                                        icon="pi pi-pencil"
                                                                        className="p-button-text p-button-sm"
                                                                        onClick={() => openDetailsDialog(material, detail, detailIndex)}
                                                                    />

                                                                    <Button
                                                                        icon="pi pi-trash"
                                                                        className="p-button-rounded p-button-danger p-button-text"
                                                                        onClick={() => deleteMaterialDetail(material, detailIndex)}

                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <p style={{ color: "#888" }}>No details added yet.</p>
                                            )}


                                        </div>
                                    </AccordionTab>
                                ))}
                            </Accordion>
                        ) : (
                            <p style={{ padding: "10px", color: "#888" }}>No materials added yet.</p>
                        )}

                    </AccordionTab>
                ))}
            </Accordion>


            {/* Dialog for Adding a Product */}
            <Dialog
                visible={baDialog}
                style={{ width: "450px" }}
                // header="Add Product"
                header={selectedProduct ? "Edit Product" : "Add Product"}
                modal
                className="p-fluid"
                onHide={() => setBADialog(false)}
                footer={
                    <Button
                        label={selectedProduct ? "Save" : "Add"}
                        className="p-button-primary"
                        onClick={saveProduct}
                        disabled={!newProduct.trim()}
                    />
                }
            >
                <div className="p-field">
                    <label htmlFor="productName">Product Name</label>
                    <InputText id="productName" value={newProduct} onChange={(e) => setNewProduct(e.target.value)} placeholder="Enter product name" />
                </div>
            </Dialog>

            {/* Dialog for Adding Material */}
            <Dialog
                visible={materialDialog}
                style={{ width: "450px" }}
                // header="Add Material"
                header={selectedMaterial !== null ? "Edit Material" : "Add Material"}
                modal
                className="p-fluid"
                // onHide={() => setMaterialDialog(false)}
                onHide={() => {
                    setMaterialDialog(false);
                    setSelectedMaterial(null); // Reset selection when closing
                    setNewMaterial(""); // Clear input
                }}
                footer={
                    <Button
                        // label="Add"
                        label={selectedMaterial !== null ? "Save" : "Add"}
                        className="p-button-primary"
                        // onClick={addMaterial}
                        onClick={selectedMaterial !== null ? saveEditedMaterial : addMaterial}
                        disabled={!newMaterial.trim()}
                    />
                }
            >
                <div className="p-field">
                    <label htmlFor="materialName">Material Name</label>
                    <InputText id="materialName" value={newMaterial} onChange={(e) => setNewMaterial(e.target.value)} placeholder="Enter material name" />
                </div>
            </Dialog>

            {/* Dialog for Adding Material Details */}
            <Dialog
                visible={detailsDialog}
                style={{ width: "400px" }}
                // header={`Add Details for ${ selectedMaterial }`}
                header={editingDetailIndex !== null ? "Edit Details" : `Add Details for ${selectedMaterial}`}
                modal
                className="p-fluid"
                // onHide={() => setDetailsDialog(false)}
                onHide={() => {
                    setDetailsDialog(false);
                    setEditingDetailIndex(null); // Reset editing state
                }}
                footer={
                    <Button
                        label={editingDetailIndex !== null ? "Update" : "Save"}
                        className="p-button-primary mt-3"
                        onClick={addMaterialDetails}
                    />
                }
            >
                {[{ name: "weight", dpid: 'DPTVS0009' }, { name: "recycling", dpid: 'DPTVS0010' }, { name: "landfill", dpid: 'DPTVS0011' }].map((field) => (
                    <div className="p-field" key={field.dpid}>
                        <label>{`Total ${field.name.charAt(0).toUpperCase() + field.name.slice(1)} of Material`}</label>
                        <InputNumber name={field.dpid} value={formData[field.dpid]} onChange={handleChange} placeholder={`Enter ${field.name}`} />
                    </div>
                ))}
            </Dialog>
        </div>
    );


};
const Employee_Diversity_TVS = ({ data, extractedData = () => { }, getData, setData, isValidResponse, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPTVS0090: 0,
        DPTVS0091: 0,
        DPTVS0092: 0,
        DPTVS0093: 0,
        DPTVS0094: 0,
        DPTVS0095: 0,
        DPTVS0096: 0,
        DPTVS0097: 0,
        DPTVS0098: 0,
        DPTVS0099: 0,
        DPTVS0100: 0,
        DPTVS0101: 0,
        DPTVS0102: 0,
        DPTVS0103: 0,
        DPTVS0104: 0,
        DPTVS0105: 0,
        DPTVS0106: 0,
        DPTVS0107: 0,
        DPTVS0108: 0,
        DPTVS0109: 0,
        DPTVS0110: 0,
        DPTVS0111: 0,
        DPTVS0112: 0,
        DPTVS0113: 0,
        DPTVS0114: 0,
        DPTVS0115: 0,
        DPTVS0116: 0,
        DPTVS0117: 0,
        DPTVS0118: 0,
        DPTVS0119: 0,
        DPTVS0120: 0,
        DPTVS0121: 0,
        remarks: ''
    }]

    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        console.log(saveEmpCatDiversity())
        setData();
        isValidResponse(saveEmpCatDiversity())
    }, [emp]);
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(data));
        } else {
            console.error("extractedData is not a function");
        }
    }, [data]);

    function extractData(data) {
        const result = [
            {
                maskId: "DPTVS0090",
                uniqueId: "DPTVS0090",
                title: "Permanent Employees-Male",
                label: "Permanent Employees-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0090,
                uom: "nos",
            },
            {
                maskId: "DPTVS0091",
                uniqueId: "DPTVS0091",
                title: "Permanent Employees-Female",
                label: "Permanent Employees-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0091,
                uom: "nos",
            },
            {
                maskId: "DPTVS0092",
                uniqueId: "DPTVS0092",
                title: "other than Permanent male Employees",
                label: "other than Permanent male Employees",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0092,
                uom: "nos",
            },
            {
                maskId: "DPTVS0093",
                uniqueId: "DPTVS0093",
                title: "other than Permanent female Employees",
                label: "other than Permanent female Employees",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0093,
                uom: "nos",
            },
            {
                maskId: "DPTVS0094",
                uniqueId: "DPTVS0094",
                title: "Senior Management male<30 years ",
                label: "Senior Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0094,
                uom: "nos",
            },
            {
                maskId: "DPTVS0095",
                uniqueId: "DPTVS0095",
                title: "Senior Management female<30 years ",
                label: "Senior Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0095,
                uom: "nos",
            },
            {
                maskId: "DPTVS0096",
                uniqueId: "DPTVS0096",
                title: "Senior Management male 30-50 Years",
                label: "Senior Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0096,
                uom: "nos",
            },
            {
                maskId: "DPTVS0097",
                uniqueId: "DPTVS0097",
                title: "Senior Management female 30-50 Years",
                label: "Senior Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0097,
                uom: "nos",
            },
            {
                maskId: "DPTVS0098",
                uniqueId: "DPTVS0098",
                title: "Senior Management male >50 years ",
                label: "Senior Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0098,
                uom: "nos",
            },
            {
                maskId: "DPTVS0099",
                uniqueId: "DPTVS0099",
                title: "Senior Management female >50 years ",
                label: "Senior Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0099,
                uom: "nos",
            },
            {
                maskId: "DPTVS0100",
                uniqueId: "DPTVS0100",
                title: "Middle Management male<30 years ",
                label: "Middle Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0100,
                uom: "nos",
            },
            {
                maskId: "DPTVS0101",
                uniqueId: "DPTVS0101",
                title: "Middle Management female<30 years ",
                label: "Middle Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0101,
                uom: "nos",
            },
            {
                maskId: "DPTVS0102",
                uniqueId: "DPTVS0102",
                title: "Middle Management male 30-50 Years",
                label: "Middle Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0102,
                uom: "nos",
            },
            {
                maskId: "DPTVS0103",
                uniqueId: "DPTVS0103",
                title: "Middle Management female 30-50 Years",
                label: "Middle Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0103,
                uom: "nos",
            },
            {
                maskId: "DPTVS0104",
                uniqueId: "DPTVS0104",
                title: "Middle Management male >50 years ",
                label: "Middle Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0104,
                uom: "nos",
            },
            {
                maskId: "DPTVS0105",
                uniqueId: "DPTVS0105",
                title: "Middle Management female >50 years ",
                label: "Middle Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0105,
                uom: "nos",
            },
            {
                maskId: "DPTVS0106",
                uniqueId: "DPTVS0106",
                title: "Junior Management male<30 years ",
                label: "Junior Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0106,
                uom: "nos",
            },
            {
                maskId: "DPTVS0107",
                uniqueId: "DPTVS0107",
                title: "Junior Management female<30 years ",
                label: "Junior Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0107,
                uom: "nos",
            },
            {
                maskId: "DPTVS0108",
                uniqueId: "DPTVS0108",
                title: "Junior Management male 30-50 Years",
                label: "Junior Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0108,
                uom: "nos",
            },
            {
                maskId: "DPTVS0109",
                uniqueId: "DPTVS0109",
                title: "Junior Management female 30-50 Years",
                label: "Junior Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0109,
                uom: "nos",
            },
            {
                maskId: "DPTVS0110",
                uniqueId: "DPTVS0110",
                title: "Junior Management male >50 years ",
                label: "Junior Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0110,
                uom: "nos",
            },
            {
                maskId: "DPTVS0111",
                uniqueId: "DPTVS0111",
                title: "Junior Management female >50 years ",
                label: "Junior Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0111,
                uom: "nos",
            },
            {
                maskId: "DPTVS0112",
                uniqueId: "DPTVS0112",
                title: "Non-supervisor Management male<30 years ",
                label: "Non-supervisor Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0112,
                uom: "nos",
            },
            {
                maskId: "DPTVS0113",
                uniqueId: "DPAN620",
                title: "Non-supervisor Management female<30 years ",
                label: "Non-supervisor Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPAN620,
                uom: "nos",
            },
            {
                maskId: "DPTVS0114",
                uniqueId: "DPTVS0114",
                title: "Non-supervisor Management male 30-50 Years",
                label: "Non-supervisor Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0114,
                uom: "nos",
            },
            {
                maskId: "DPTVS0115",
                uniqueId: "DPTVS0115",
                title: "Non-supervisor Management female 30-50 Years",
                label: "Non-supervisor Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0115,
                uom: "nos",
            },
            {
                maskId: "DPTVS0116",
                uniqueId: "DPTVS0116",
                title: "Non-supervisor Management male >50 years ",
                label: "Non-supervisor Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0116,
                uom: "nos",
            },
            {
                maskId: "DPTVS0117",
                uniqueId: "DPTVS0117",
                title: "Non-supervisor Management female >50 years ",
                label: "Non-supervisor Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0117,
                uom: "nos",
            },
            {
                maskId: "DPTVS0118",
                uniqueId: "DPTVS0118",
                title: "Permanent Male Workers ",
                label: "Permanent Male Workers ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0118,
                uom: "nos",
            },
            {
                maskId: "DPTVS0119",
                uniqueId: "DPTVS0119",
                title: "Permanent FeMale Workers",
                label: "Permanent FeMale Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0119,
                uom: "nos",
            },
            {
                maskId: "DPTVS0120",
                uniqueId: "DPTVS0120",
                title: "other than Permanent male Workers",
                label: "other than Permanent male Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0120,
                uom: "nos",
            },
            {
                maskId: "DPTVS0121",
                uniqueId: "DPTVS0121",
                title: "other than Permanent Female Workers",
                label: "other than Permanent Female Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS0121,
                uom: "nos",
            },
        ];
        console.log(result, "RESULT");
        return result;
    }
    getData(emp);
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            if (obj === 'remarks') {
                loc[0][obj] = ''
            } else {
                loc[0][obj] = 0
            }

        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };

    const saveEmpCatDiversity = () => {
        // let set1 = emp[0].DPTVS0090 + emp[0].DPTVS0091 + emp[0].DPTVS0092 + emp[0].DPTVS0093
        // let set2 = emp[0].DPTVS0094 + emp[0].DPTVS0095 + emp[0].DPTVS0096 + emp[0].DPTVS0097 + emp[0].DPTVS0098 + emp[0].DPTVS0099
        // let set3 = emp[0].DPTVS0100 + emp[0].DPTVS0101 + emp[0].DPTVS0102 + emp[0].DPTVS0103 + emp[0].DPTVS0104 + emp[0].DPTVS0105

        // let set4 = emp[0].DPTVS0106 + emp[0].DPTVS0107 + emp[0].DPTVS0108 + emp[0].DPTVS0109 + emp[0].DPTVS0110 + emp[0].DPTVS0111

        // let set5 = emp[0].DPTVS0112 + emp[0].DPTVS0113 + emp[0].DPTVS0114 + emp[0].DPTVS0115 + emp[0].DPTVS0116 + emp[0].DPTVS0117

        // let set6 = emp[0].DPTVS0118 + emp[0].DPTVS0119 + emp[0].DPTVS0120 + emp[0].DPTVS0121



        return true


    }

    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0090} onChange={(e) => { updateVal('DPTVS0090', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0091} onChange={(e) => { updateVal('DPTVS0091', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS0090 + emp[0].DPTVS0091} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other than Permanent Employees <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0092} onChange={(e) => { updateVal('DPTVS0092', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0093} onChange={(e) => { updateVal('DPTVS0093', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS0092 + emp[0].DPTVS0093} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0090 + emp[0].DPTVS0091 + emp[0].DPTVS0092 + emp[0].DPTVS0093}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Senior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0094} onChange={(e) => { updateVal('DPTVS0094', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0095} onChange={(e) => { updateVal('DPTVS0095', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0094 + emp[0].DPTVS0095} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Senior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0096} onChange={(e) => { updateVal('DPTVS0096', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0097} onChange={(e) => { updateVal('DPTVS0097', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0096 + emp[0].DPTVS0097} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Senior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0098} onChange={(e) => { updateVal('DPTVS0098', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0099} onChange={(e) => { updateVal('DPTVS0099', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0098 + emp[0].DPTVS0099} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0094 + emp[0].DPTVS0095 + emp[0].DPTVS0096 + emp[0].DPTVS0097 + emp[0].DPTVS0098 + emp[0].DPTVS0099}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Middle Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0100} onChange={(e) => { updateVal('DPTVS0100', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0101} onChange={(e) => { updateVal('DPTVS0101', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0100 + emp[0].DPTVS0101} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Middle Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0102} onChange={(e) => { updateVal('DPTVS0102', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0103} onChange={(e) => { updateVal('DPTVS0103', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0102 + emp[0].DPTVS0103} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Middle Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0104} onChange={(e) => { updateVal('DPTVS0104', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0105} onChange={(e) => { updateVal('DPTVS0105', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0104 + emp[0].DPTVS0105} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0100 + emp[0].DPTVS0101 + emp[0].DPTVS0102 + emp[0].DPTVS0103 + emp[0].DPTVS0104 + emp[0].DPTVS0105}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Junior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0106} onChange={(e) => { updateVal('DPTVS0106', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0107} onChange={(e) => { updateVal('DPTVS0107', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0106 + emp[0].DPTVS0107} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Junior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0108} onChange={(e) => { updateVal('DPTVS0108', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0109} onChange={(e) => { updateVal('DPTVS0109', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0108 + emp[0].DPTVS0109} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Junior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0110} onChange={(e) => { updateVal('DPTVS0110', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0111} onChange={(e) => { updateVal('DPTVS0111', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0110 + emp[0].DPTVS0111} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0106 + emp[0].DPTVS0107 + emp[0].DPTVS0108 + emp[0].DPTVS0109 + emp[0].DPTVS0110 + emp[0].DPTVS0111}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Non-supervisor Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0112} onChange={(e) => { updateVal('DPTVS0112', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0113} onChange={(e) => { updateVal('DPTVS0113', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0112 + emp[0].DPTVS0113} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Non-supervisor Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0114} onChange={(e) => { updateVal('DPTVS0114', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0115} onChange={(e) => { updateVal('DPTVS0115', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0114 + emp[0].DPTVS0115} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Non-supervisor Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0116} onChange={(e) => { updateVal('DPTVS0116', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0117} onChange={(e) => { updateVal('DPTVS0117', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0116 + emp[0].DPTVS0117} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0112 + emp[0].DPTVS0113 + emp[0].DPTVS0114 + emp[0].DPTVS0115 + emp[0].DPTVS0116 + emp[0].DPTVS0117}
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0118} onChange={(e) => { updateVal('DPTVS0118', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0119} onChange={(e) => { updateVal('DPTVS0119', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS0118 + emp[0].DPTVS0119} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0120} onChange={(e) => { updateVal('DPTVS0120', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0121} onChange={(e) => { updateVal('DPTVS0121', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS0120 + emp[0].DPTVS0121} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0118 + emp[0].DPTVS0119 + emp[0].DPTVS0120 + emp[0].DPTVS0121}
                        </div>
                    </div>


                </div>
            ) : (
                <div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0090} onChange={(e) => { updateVal('DPTVS0090', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0091} onChange={(e) => { updateVal('DPTVS0091', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS0090 + emp[0].DPTVS0091} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other than Permanent Employees <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0092} onChange={(e) => { updateVal('DPTVS0092', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0093} onChange={(e) => { updateVal('DPTVS0093', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS0092 + emp[0].DPTVS0093} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0090 + emp[0].DPTVS0091 + emp[0].DPTVS0092 + emp[0].DPTVS0093}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Senior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0094} onChange={(e) => { updateVal('DPTVS0094', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0095} onChange={(e) => { updateVal('DPTVS0095', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0094 + emp[0].DPTVS0095} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Senior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0096} onChange={(e) => { updateVal('DPTVS0096', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0097} onChange={(e) => { updateVal('DPTVS0097', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0096 + emp[0].DPTVS0097} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Senior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0098} onChange={(e) => { updateVal('DPTVS0098', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0099} onChange={(e) => { updateVal('DPTVS0099', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0098 + emp[0].DPTVS0099} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0094 + emp[0].DPTVS0095 + emp[0].DPTVS0096 + emp[0].DPTVS0097 + emp[0].DPTVS0098 + emp[0].DPTVS0099}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Middle Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0100} onChange={(e) => { updateVal('DPTVS0100', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0101} onChange={(e) => { updateVal('DPTVS0101', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0100 + emp[0].DPTVS0101} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Middle Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0102} onChange={(e) => { updateVal('DPTVS0102', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0103} onChange={(e) => { updateVal('DPTVS0103', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0102 + emp[0].DPTVS0103} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Middle Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0104} onChange={(e) => { updateVal('DPTVS0104', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0105} onChange={(e) => { updateVal('DPTVS0105', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0104 + emp[0].DPTVS0105} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0100 + emp[0].DPTVS0101 + emp[0].DPTVS0102 + emp[0].DPTVS0103 + emp[0].DPTVS0104 + emp[0].DPTVS0105}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Junior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0106} onChange={(e) => { updateVal('DPTVS0106', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0107} onChange={(e) => { updateVal('DPTVS0107', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0106 + emp[0].DPTVS0107} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Junior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0108} onChange={(e) => { updateVal('DPTVS0108', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0109} onChange={(e) => { updateVal('DPTVS0109', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0108 + emp[0].DPTVS0109} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Junior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0110} onChange={(e) => { updateVal('DPTVS0110', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0111} onChange={(e) => { updateVal('DPTVS0111', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0110 + emp[0].DPTVS0111} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0106 + emp[0].DPTVS0107 + emp[0].DPTVS0108 + emp[0].DPTVS0109 + emp[0].DPTVS01110 + emp[0].DPTVS0111}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Non-supervisor Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0112} onChange={(e) => { updateVal('DPTVS0112', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0113} onChange={(e) => { updateVal('DPTVS0113', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0112 + emp[0].DPTVS0113} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Non-supervisor Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0114} onChange={(e) => { updateVal('DPTVS0114', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0115} onChange={(e) => { updateVal('DPTVS0115', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0114 + emp[0].DPTVS0115} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Non-supervisor Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0116} onChange={(e) => { updateVal('DPTVS0116', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0117} onChange={(e) => { updateVal('DPTVS0117', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS0116 + emp[0].DPTVS0117} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0112 + emp[0].DPTVS0113 + emp[0].DPTVS0114 + emp[0].DPTVS0115 + emp[0].DPTVS0116 + emp[0].DPTVS0117}
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0118} onChange={(e) => { updateVal('DPTVS0118', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0119} onChange={(e) => { updateVal('DPTVS0119', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS0118 + emp[0].DPTVS0119} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0120} onChange={(e) => { updateVal('DPTVS0120', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS0121} onChange={(e) => { updateVal('DPTVS0121', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS0120 + emp[0].DPTVS0121} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS0118 + emp[0].DPTVS0119 + emp[0].DPTVS0120 + emp[0].DPTVS0121}
                        </div>
                    </div>


                </div>
            )}
        </div>
    );
};
const Emissions_Due_Downstream_TransportationAndDistribution_SpendBased = ({ extractedData = () => { }, data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);



    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPTVS0012: "",
        DPTVS0013: "",

        DPTVS0014: 0,
    });
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(badata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [badata]);

    function extractData(data) {
        console.log(data)
        let mappedData = data
            .map((i) => ({
                label: i.DPTVS0013,
                title: i.DPTVS0013 + ">INR",
                uom: "INR",
                isNull: false,
                uniqueId: `DPTVS0013-DPTVS0014`,
                formType: 2,
                dataType: 1,
                maskId: i.id,
                value: i.DPTVS0014,
            }))
            .filter((i) => i.maskId);




        console.log(mappedData, "RESULT");
        return mappedData;
    }
    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPTVS0012: "",
            DPTVS0013: "",

            DPTVS0014: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    getData(badata)
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = [...badata];

        if (
            dataVal.DPTVS0012.trim().length !== 0 &&
            dataVal.DPTVS0013.trim().length !== 0 &&
            dataVal.DPTVS0014 >= 0
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPTVS0012: "",
                DPTVS0013: "",

                DPTVS0014: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...badata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">

                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPTVS0012", "DPTVS0013"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Description of Material Transported "
                    field="DPTVS0012"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Mode of Transport"
                    field="DPTVS0013"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total Spend on transportation (in INR) "
                    field="DPTVS0014"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />


                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header={dataVal?.id ? 'Edit' : "Add"}
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Description of Material Transported
                    </label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPTVS0012}
                        onChange={(e) => {
                            updateDataVal("DPTVS0012", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0012.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter Description
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Mode of Transport</label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPTVS0013}
                        onChange={(e) => {
                            updateDataVal("DPTVS0013", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0013.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter Mode of Transport
                        </small>
                    )}
                </div>



                <div className="field">
                    <label htmlFor="ecpsngr">
                        Total Spend on transportation (in INR)
                    </label>
                    <InputNumber

                        useGrouping={false}
                        value={dataVal.DPTVS0014}
                        onChange={(e) => {
                            updateDataVal("DPTVS0014", e.value);
                        }}
                    />
                    {submitted && dataVal.DPTVS0014 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Total Spend on transportation
                        </small>
                    )}
                </div>


            </Dialog>
        </div>
    );
};
const HazardousWaste_TVS = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);
    getData(badata)
    let DPAN0040Options = [
        { name: "Hazardous Waste", id: 1 },
        { name: "Non- Hazardous waste", id: 2 },
    ];
    let DPAN0042Options = [
        { name: "Paper - all types", id: 1 },
        { name: "Plastic - all types", id: 2 },
        { name: "Metals - all types", id: 3 },
        { name: "Electrical items - WEEE - (ex. printers)", id: 4 },
        { name: "General Waste - Commecial and industrial waste", id: 6 },
        { name: "General Waste - Organic: mixed food and garden waste", id: 7 },
        { name: "General Waste - Organic: garden waste", id: 8 },
        { name: "General Waste - Organic: food and drink waste", id: 9 },
        { name: "General Waste - Household residual waste", id: 10 },
        { name: "Glass - all types", id: 11 },
        { name: "Construction and demolition waste", id: 19 },
        { name: "Others", id: 12 },
    ];
    let DPAN0044Options = [
        { name: "Recovery of waste", id: 1 },
        { name: "Disposal of waste", id: 2 },
    ];
    let DPAN0041Options = [
        { name: "Used Oil", id: 17 },
        { name: "Wastes/Residues Containing Oil", id: 18 },
        { name: "Phosphate Sludge", id: 19 },
        { name: "Waste Thinner", id: 20 },
        { name: "Electrical items - Batteries", id: 25 },
        { name: "Wastes and Residues (Paint Sludge)", id: 21 },
        { name: "Acid/Chemical/Paint/Thinner Containers", id: 22 },
        { name: "Chemical Sludge from Waste Water Treatment Plants", id: 23 },
        { name: "Furnace Oil Sludge", id: 24 },
        { name: "ATFD Salt", id: 24 },
        { name: "Bio-medical waste", id: 7 },
        { name: "Radioactive waste", id: 8 },
        { name: "Others - Please include in remarks", id: 6 },
    ];
    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN0040: null,
        DPAN0041: null,
        DPAN0041A: "",
        DPAN0042A: "",
        DPAN0042: null,
        DPAN0043: 0,
        DPAN0044: null,
        DPA0068A: false,
        DPA0069A: false,
        DPA0070A: false,
        DPA0068: 0,
        DPA0069: 0,
        DPA0070: [
            { mode: "", qty: 0 },
            { mode: "", qty: 0 },
        ],
        DPA0087A: false,
        DPA0088A: false,
        DPA0089A: false,
        DPA0091A: false,
        DPA0087: 0,
        DPA0088: 0,
        DPA0089: 0,
        DPA0091: 0,
        DPA0090A: false,
        DPA0090: [
            { mode: "", qty: 0 },
            { mode: "", qty: 0 },
        ],
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        if (obj === "DPAN0040") {
            local["DPAN0041"] = null;
            local["DPAN0041A"] = '';
            local["DPAN0043"] = 0;
            local["DPAN0042"] = null;
            local["DPAN0044"] = null;
        }
        if (obj === "DPAN0044") {
            local["DPA0068A"] = false;
            local["DPA0069A"] = false;
            local["DPGRWAD7"] = false;
            local["DPGRWAD8"] = false;
            local["DPGRWAD9"] = false;
            local["DPGRWAD10"] = false;
            local["DPGRWAD11"] = false;

            local["DPA0068"] = 0;
            local["DPA0069"] = 0;
            local["DPA0087"] = 0;
            local["DPA0088"] = 0;
            local["DPA0089"] = 0;
            local["DPA0091"] = 0;
            local["DPA0070"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
            local["DPA0090"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        if (obj === "DPA0068A") {
            local["DPA0068"] = 0;
        }
        if (obj === "DPA0069A") {
            local["DPA0069"] = 0;
        }
        if (obj === "DPA0087A") {
            local["DPA0087"] = 0;
        }
        if (obj === "DPA0088A") {
            local["DPA0088"] = 0;
        }
        if (obj === "DPA0089A") {
            local["DPA0089"] = 0;
        }
        if (obj === "DPA0091A") {
            local["DPA0091"] = 0;
        }
        if (obj === "DPA0070A") {
            local["DPA0070"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        if (obj === "DPA0090A") {
            local["DPA0090"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPAN0040: null,
            DPAN0041: null,
            DPAN0041A: "",
            DPAN0042A: "",
            DPAN0042: null,
            DPAN0043: 0,
            DPAN0044: null,
            DPA0068A: false,
            DPA0069A: false,
            DPA0070A: false,
            DPA0068: 0,
            DPA0069: 0,
            DPA0070: [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ],
            DPA0087A: false,
            DPA0088A: false,
            DPA0089A: false,
            DPA0091A: false,
            DPA0087: 0,
            DPA0088: 0,
            DPA0089: 0,
            DPA0091: 0,
            DPA0090A: false,
            DPA0090: [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ],
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPAN0040 !== null &&
            dataVal.DPAN0044 !== null &&
            dataVal.DPAN0043 >= 0 &&
            (dataVal.DPAN0040 === 1
                ? dataVal.DPAN0041 !== null
                : dataVal.DPAN0042 !== null) &&
            (dataVal.DPAN0040 === 1
                ? dataVal.DPAN0041 === 6
                    ? dataVal.DPAN0041A.trim().length !== 0
                    : true
                : true) &&
            (dataVal.DPAN0040 === 2
                ? dataVal.DPAN0042 === 12
                    ? dataVal.DPAN0042A.trim().length !== 0
                    : true
                : true)
        ) {
            if (dataVal.DPAN0044 === 1) {
                if (
                    (dataVal.DPA0068A === true && dataVal.DPA0068 >= 0) ||
                    (dataVal.DPA0069A === true && dataVal.DPA0069 >= 0) ||
                    (dataVal.DPA0070A === true &&
                        dataVal.DPA0070.some(
                            (item) => item.mode.trim().length !== 0 && (item.qty != null) && item.qty >= 0
                        ))
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }
                    setBAData(local2);
                    forceUpdate();
                    setBADialog(false);
                }
            } else {
                if (
                    (dataVal.DPA0087A === true && dataVal.DPA0087 >= 0) ||
                    (dataVal.DPA0088A === true && dataVal.DPA0088 >= 0) ||
                    (dataVal.DPA0089A === true && dataVal.DPA0089 >= 0) ||
                    (dataVal.DPA0090A === true &&
                        dataVal.DPA0090.some(
                            (item) => item.mode.trim().length !== 0 && (item.qty != null) && item.qty >= 0
                        ))
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }
                    setBAData(local2);
                    forceUpdate();
                    setBADialog(false);
                }
            }
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const categoryTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN0040Options.findIndex((i) => i.id === rowData.DPAN0040);
        if (index !== -1) {
            txt = DPAN0040Options[index].name;
        }
        console.log(rowData.DPAN0040, index);
        return <> {txt} </>;
    };
    const typeTemplate = (rowData) => {
        let txt = "Not Found";
        if (rowData.DPAN0040 === 1) {
            let index = DPAN0041Options.findIndex((i) => i.id === rowData.DPAN0041);
            if (index !== -1) {
                txt = DPAN0041Options[index].name;
            }

        } else {
            let index = DPAN0042Options.findIndex((i) => i.id === rowData.DPAN0042);
            if (index !== -1) {
                txt = DPAN0042Options[index].name;
            }
        }

        return <> {txt} </>;
    };
    const managementTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN0044Options.findIndex((i) => i.id === rowData.DPAN0044);
        if (index !== -1) {
            txt = DPAN0044Options[index].name;
        }
        return <> {txt} </>;
    };

    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>

            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center "
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        {/* <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        
									   }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                 uploadHandler={(e) => {
                               console.log(e);
                            }}
                            />
                        </div> */}
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Category of Waste Generated"
                    body={(rowData) => {
                        return categoryTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Type of Hazardous waste generated"
                    body={(rowData) => {
                        return typeTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Quantity Generated"
                    field="DPAN0043"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Management of waste"
                    body={(rowData) => {
                        return managementTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Category of Waste Generated</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN0040}
                        options={DPAN0040Options}
                        onChange={(e) => {
                            updateDataVal("DPAN0040", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0040 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select category
                        </small>
                    )}
                </div>
                {dataVal.DPAN0040 === 1 ? (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">Type of Hazardous waste generated</label>
                            <Dropdown
                                optionLabel="name"
                                optionValue="id"
                                value={dataVal.DPAN0041}
                                options={DPAN0041Options}
                                onChange={(e) => {
                                    updateDataVal("DPAN0041", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN0041 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select category
                                </small>
                            )}
                        </div>
                        {dataVal.DPAN0041 === 6 && (
                            <div className="field">
                                <label htmlFor="ecpsngr">Please mention type  <span className="mandatory"> *</span> </label>
                                <InputText
                                    type={"text"}
                                    value={dataVal.DPAN0041A}
                                    onChange={(e) => {
                                        updateDataVal("DPAN0041A", e.target.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN0041A.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Mention hazardous Other category
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">
                                Type of Non-Hazardous waste generated
                            </label>
                            <Dropdown
                                optionLabel="name"
                                optionValue="id"
                                value={dataVal.DPAN0042}
                                options={DPAN0042Options}
                                onChange={(e) => {
                                    updateDataVal("DPAN0042", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN0042 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select category
                                </small>
                            )}
                        </div>
                        {dataVal.DPAN0042 === 12 && (
                            <div className="field">
                                <label htmlFor="ecpsngr">Please mention type  <span className="mandatory"> *</span> </label>
                                <InputText
                                    type={"text"}
                                    value={dataVal.DPAN0042A}
                                    onChange={(e) => {
                                        updateDataVal("DPAN0042A", e.target.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN0042A.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Mention Non-hazardous Other category
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                )}

                <div className="field">
                    <label htmlFor="ecpsngr">Quantity Generated (In Metric Tonnes)</label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPAN0043}
                        onChange={(e) => {
                            updateDataVal("DPAN0043", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0043 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Management of waste</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN0044}
                        options={DPAN0044Options}
                        onChange={(e) => {
                            updateDataVal("DPAN0044", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0044 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select type
                        </small>
                    )}
                </div>
                {dataVal.DPAN0044 === 1 ? (
                    <div className="flex grid justify-content-center gap-3">
                        <div className="flex align-items-center col-12">
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient1"
                                    name="pizza"
                                    value="Cheese"
                                    onChange={(e) => {
                                        updateDataVal("DPA0068A", e.checked);
                                    }}
                                    checked={dataVal.DPA0068A === true}
                                />
                                <label htmlFor="ingredient1" className="ml-2">
                                    Preparation for reuse
                                </label>
                            </div>
                            <div className="col-4">
                                <InputNumber
                                    value={dataVal.DPA0068}
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    disabled={dataVal.DPA0068A === false}
                                    onValueChange={(e) => {
                                        updateDataVal("DPA0068", e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex align-items-center col-12">
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient2"
                                    name="pizza"
                                    value="Mushroom"
                                    onChange={(e) => {
                                        updateDataVal("DPA0069A", e.checked);
                                    }}
                                    checked={dataVal.DPA0069A === true}
                                />
                                <label htmlFor="ingredient2" className="ml-2">
                                    Recycling
                                </label>
                            </div>
                            <div className="col-4">
                                <InputNumber
                                    value={dataVal.DPA0069}
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    disabled={dataVal.DPA0069A === false}
                                    onValueChange={(e) => {
                                        updateDataVal("DPA0069", e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex  col-12" style={{ flexDirection: "column" }}>
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient3"
                                    name="pizza"
                                    value="Pepper"
                                    onChange={(e) => {
                                        updateDataVal("DPA0070A", e.checked);
                                    }}
                                    checked={dataVal.DPA0070A === true}
                                />
                                <label htmlFor="ingredient3" className="ml-2">
                                    Other recovery operations
                                </label>
                            </div>
                            {dataVal.DPA0070A === true && (
                                <div className="col-12">
                                    <div className="col-12 grid">
                                        <div className="col-8">Mode</div>
                                        <div className="col-4">Quantity (In MT)</div>
                                    </div>
                                    {dataVal.DPA0070.map((item) => {
                                        return (
                                            <div className="col-12 grid">
                                                <div className="col-8">
                                                    <InputText
                                                        type={"text"}
                                                        value={item.mode}
                                                        onChange={(e) => {
                                                            item.mode = e.target.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <InputNumber
                                                        maxFractionDigits={4}
                                                        useGrouping={false}
                                                        value={item.qty}
                                                        onChange={(e) => {
                                                            item.qty = e.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    dataVal.DPAN0044 === 2 && (
                        <div className="flex grid justify-content-center gap-3">
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="pizza"
                                        value="Cheese"
                                        onChange={(e) => {
                                            updateDataVal("DPA0087A", e.checked);
                                        }}
                                        checked={dataVal.DPA0087A === true}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        Incineration (with energy recovery)
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0087}
                                        disabled={dataVal.DPA0087A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0087", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="pizza"
                                        value="Cheese"
                                        onChange={(e) => {
                                            updateDataVal("DPA0088A", e.checked);
                                        }}
                                        checked={dataVal.DPA0088A === true}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        Incineration (without energy recovery)
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0088}
                                        disabled={dataVal.DPA0088A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0088", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient2"
                                        name="pizza"
                                        value="Mushroom"
                                        onChange={(e) => {
                                            updateDataVal("DPA0089A", e.checked);
                                        }}
                                        checked={dataVal.DPA0089A === true}
                                    />
                                    <label htmlFor="ingredient2" className="ml-2">
                                        Landfilling
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0089}
                                        disabled={dataVal.DPA0089A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0089", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient2"
                                        name="pizza"
                                        value="Mushroom"
                                        onChange={(e) => {
                                            updateDataVal("DPA0091A", e.checked);
                                        }}
                                        checked={dataVal.DPA0091A === true}
                                    />
                                    <label htmlFor="ingredient2" className="ml-2">
                                        Co-Processing
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0091}
                                        disabled={dataVal.DPA0091A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0091", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex  col-12" style={{ flexDirection: "column" }}>
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient3"
                                        name="pizza"
                                        value="Pepper"
                                        onChange={(e) => {
                                            updateDataVal("DPA0090A", e.checked);
                                        }}
                                        checked={dataVal.DPA0090A === true}
                                    />
                                    <label htmlFor="ingredient3" className="ml-2">
                                        Other disposal operations
                                    </label>
                                </div>
                                {dataVal.DPA0090A === true && (
                                    <div className="col-12">
                                        <div className="col-12 grid">
                                            <div className="col-8">Mode</div>
                                            <div className="col-4">Quantity (in MT)</div>
                                        </div>
                                        {dataVal.DPA0090.map((item) => {
                                            return (
                                                <div className="col-12 grid">
                                                    <div className="col-8">
                                                        <InputText
                                                            type={"text"}
                                                            value={item.mode}
                                                            onChange={(e) => {
                                                                item.mode = e.target.value;
                                                                forceUpdate();
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <InputNumber
                                                            maxFractionDigits={4}
                                                            useGrouping={false}
                                                            value={item.qty}
                                                            onChange={(e) => {
                                                                item.qty = e.value;
                                                                forceUpdate();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                )}
            </Dialog>
        </div>
    );
};
const New_Employee_Turnover_TVS = ({ data, extractedData = () => { }, getData, setData, isValidResponse, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPTVS732: 0,
        DPTVS733: 0,
        DPTVS734: 0,
        DPTVS735: 0,
        DPTVS736: 0,
        DPTVS737: 0,
        DPTVS738: 0,
        DPTVS739: 0,
        DPTVS740: 0,
        DPTVS741: 0,
        DPTVS742: 0,
        DPTVS743: 0,
        DPTVS744: 0,
        DPTVS745: 0,
        DPTVS746: 0,
        DPTVS747: 0,
        DPTVS748: 0,
        DPTVS749: 0,
        DPTVS750: 0,
        DPTVS751: 0,
        DPTVS752: 0,
        DPTVS753: 0,
        DPTVS754: 0,
        DPTVS755: 0,
        DPTVS756: 0,
        DPTVS757: 0,
        DPTVS758: 0,
        DPTVS759: 0,
        DPTVS760: 0,
        DPTVS761: 0,
        DPTVS762: 0,
        DPTVS763: 0,
        remarks: ''
    }]

    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        console.log(saveEmpCatDiversity())
        setData();
        isValidResponse(saveEmpCatDiversity())
    }, [emp]);
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(data));
        } else {
            console.error("extractedData is not a function");
        }
    }, [data]);

    function extractData(data) {
        const result = [
            {
                maskId: "DPTVS732",
                uniqueId: "DPTVS732",
                title: "Permanent Employees-Male",
                label: "Permanent Employees-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS732,
                uom: "nos",
            },
            {
                maskId: "DPTVS748",
                uniqueId: "DPTVS748",
                title: "Permanent Employees-Female",
                label: "Permanent Employees-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS748,
                uom: "nos",
            },
            {
                maskId: "DPTVS733",
                uniqueId: "DPTVS733",
                title: "other than Permanent male Employees",
                label: "other than Permanent male Employees",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS733,
                uom: "nos",
            },
            {
                maskId: "DPTVS749",
                uniqueId: "DPTVS749",
                title: "other than Permanent female Employees",
                label: "other than Permanent female Employees",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS749,
                uom: "nos",
            },
            {
                maskId: "DPTVS734",
                uniqueId: "DPTVS734",
                title: "Senior Management male<30 years ",
                label: "Senior Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS734,
                uom: "nos",
            },
            {
                maskId: "DPTVS750",
                uniqueId: "DPTVS750",
                title: "Senior Management female<30 years ",
                label: "Senior Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS750,
                uom: "nos",
            },
            {
                maskId: "DPTVS735",
                uniqueId: "DPTVS735",
                title: "Senior Management male 30-50 Years",
                label: "Senior Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS735,
                uom: "nos",
            },
            {
                maskId: "DPTVS751",
                uniqueId: "DPTVS751",
                title: "Senior Management female 30-50 Years",
                label: "Senior Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS751,
                uom: "nos",
            },
            {
                maskId: "DPTVS736",
                uniqueId: "DPTVS736",
                title: "Senior Management male >50 years ",
                label: "Senior Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS736,
                uom: "nos",
            },
            {
                maskId: "DPTVS752",
                uniqueId: "DPTVS752",
                title: "Senior Management female >50 years ",
                label: "Senior Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS752,
                uom: "nos",
            },
            {
                maskId: "DPTVS737",
                uniqueId: "DPTVS737",
                title: "Middle Management male<30 years ",
                label: "Middle Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS737,
                uom: "nos",
            },
            {
                maskId: "DPTVS753",
                uniqueId: "DPTVS753",
                title: "Middle Management female<30 years ",
                label: "Middle Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS753,
                uom: "nos",
            },
            {
                maskId: "DPTVS738",
                uniqueId: "DPTVS738",
                title: "Middle Management male 30-50 Years",
                label: "Middle Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS738,
                uom: "nos",
            },
            {
                maskId: "DPTVS754",
                uniqueId: "DPTVS754",
                title: "Middle Management female 30-50 Years",
                label: "Middle Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS754,
                uom: "nos",
            },
            {
                maskId: "DPTVS739",
                uniqueId: "DPTVS739",
                title: "Middle Management male >50 years ",
                label: "Middle Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS739,
                uom: "nos",
            },
            {
                maskId: "DPTVS755",
                uniqueId: "DPTVS755",
                title: "Middle Management female >50 years ",
                label: "Middle Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS755,
                uom: "nos",
            },
            {
                maskId: "DPTVS740",
                uniqueId: "DPTVS740",
                title: "Junior Management male<30 years ",
                label: "Junior Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS740,
                uom: "nos",
            },
            {
                maskId: "DPTVS756",
                uniqueId: "DPTVS756",
                title: "Junior Management female<30 years ",
                label: "Junior Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS756,
                uom: "nos",
            },
            {
                maskId: "DPTVS741",
                uniqueId: "DPTVS741",
                title: "Junior Management male 30-50 Years",
                label: "Junior Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS741,
                uom: "nos",
            },
            {
                maskId: "DPTVS757",
                uniqueId: "DPTVS757",
                title: "Junior Management female 30-50 Years",
                label: "Junior Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS757,
                uom: "nos",
            },
            {
                maskId: "DPTVS742",
                uniqueId: "DPTVS742",
                title: "Junior Management male >50 years ",
                label: "Junior Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS742,
                uom: "nos",
            },
            {
                maskId: "DPTVS758",
                uniqueId: "DPTVS758",
                title: "Junior Management female >50 years ",
                label: "Junior Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS758,
                uom: "nos",
            },
            {
                maskId: "DPTVS743",
                uniqueId: "DPTVS743",
                title: "Non-supervisor Management male<30 years ",
                label: "Non-supervisor Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS743,
                uom: "nos",
            },
            {
                maskId: "DPTVS759",
                uniqueId: "DPTVS759",
                title: "Non-supervisor Management female<30 years ",
                label: "Non-supervisor Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS759,
                uom: "nos",
            },
            {
                maskId: "DPTVS744",
                uniqueId: "DPTVS744",
                title: "Non-supervisor Management male 30-50 Years",
                label: "Non-supervisor Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS744,
                uom: "nos",
            },
            {
                maskId: "DPTVS760",
                uniqueId: "DPTVS760",
                title: "Non-supervisor Management female 30-50 Years",
                label: "Non-supervisor Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS760,
                uom: "nos",
            },
            {
                maskId: "DPTVS745",
                uniqueId: "DPTVS745",
                title: "Non-supervisor Management male >50 years ",
                label: "Non-supervisor Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS745,
                uom: "nos",
            },
            {
                maskId: "DPTVS761",
                uniqueId: "DPTVS761",
                title: "Non-supervisor Management female >50 years ",
                label: "Non-supervisor Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS761,
                uom: "nos",
            },
            {
                maskId: "DPTVS746",
                uniqueId: "DPTVS746",
                title: "Permanent Male Workers ",
                label: "Permanent Male Workers ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS746,
                uom: "nos",
            },
            {
                maskId: "DPTVS762",
                uniqueId: "DPTVS762",
                title: "Permanent FeMale Workers",
                label: "Permanent FeMale Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS762,
                uom: "nos",
            },
            {
                maskId: "DPTVS747",
                uniqueId: "DPTVS747",
                title: "other than Permanent male Workers",
                label: "other than Permanent male Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS747,
                uom: "nos",
            },
            {
                maskId: "DPTVS763",
                uniqueId: "DPTVS763",
                title: "other than Permanent Female Workers",
                label: "other than Permanent Female Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS763,
                uom: "nos",
            },
        ];
        console.log(result, "RESULT");
        return result;
    }
    getData(emp);
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            if (obj === 'remarks') {
                loc[0][obj] = ''
            } else {
                loc[0][obj] = 0
            }

        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };

    const saveEmpCatDiversity = () => {
        // let set1 = emp[0].DPTVS0090 + emp[0].DPTVS0091 + emp[0].DPTVS0092 + emp[0].DPTVS0093
        // let set2 = emp[0].DPTVS0094 + emp[0].DPTVS0095 + emp[0].DPTVS0096 + emp[0].DPTVS0097 + emp[0].DPTVS0098 + emp[0].DPTVS0099
        // let set3 = emp[0].DPTVS0100 + emp[0].DPTVS0101 + emp[0].DPTVS0102 + emp[0].DPTVS0103 + emp[0].DPTVS0104 + emp[0].DPTVS0105

        // let set4 = emp[0].DPTVS0106 + emp[0].DPTVS0107 + emp[0].DPTVS0108 + emp[0].DPTVS0109 + emp[0].DPTVS0110 + emp[0].DPTVS0111

        // let set5 = emp[0].DPTVS0112 + emp[0].DPTVS0113 + emp[0].DPTVS0114 + emp[0].DPTVS0115 + emp[0].DPTVS0116 + emp[0].DPTVS0117

        // let set6 = emp[0].DPTVS0118 + emp[0].DPTVS0119 + emp[0].DPTVS0120 + emp[0].DPTVS0121



        return true


    }

    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS732} onChange={(e) => { updateVal('DPTVS732', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS748} onChange={(e) => { updateVal('DPTVS748', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS732 + emp[0].DPTVS748} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other than Permanent Employees <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS733} onChange={(e) => { updateVal('DPTVS733', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS749} onChange={(e) => { updateVal('DPTVS749', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS733 + emp[0].DPTVS749} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS732 + emp[0].DPTVS733 + emp[0].DPTVS748 + emp[0].DPTVS749}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Senior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS734} onChange={(e) => { updateVal('DPTVS734', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS750} onChange={(e) => { updateVal('DPTVS750', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS734 + emp[0].DPTVS750} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Senior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS735} onChange={(e) => { updateVal('DPTVS735', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS751} onChange={(e) => { updateVal('DPTVS751', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS735 + emp[0].DPTVS751} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Senior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS736} onChange={(e) => { updateVal('DPTVS736', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS752} onChange={(e) => { updateVal('DPTVS752', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS736 + emp[0].DPTVS752} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS734 + emp[0].DPTVS735 + emp[0].DPTVS736 + emp[0].DPTVS750 + emp[0].DPTVS751 + emp[0].DPTVS752}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Middle Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS737} onChange={(e) => { updateVal('DPTVS737', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS753} onChange={(e) => { updateVal('DPTVS753', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS737 + emp[0].DPTVS753} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Middle Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS738} onChange={(e) => { updateVal('DPTVS738', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS754} onChange={(e) => { updateVal('DPTVS754', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS738 + emp[0].DPTVS754} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Middle Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS739} onChange={(e) => { updateVal('DPTVS739', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS755} onChange={(e) => { updateVal('DPTVS755', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS739 + emp[0].DPTVS755} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS737 + emp[0].DPTVS738 + emp[0].DPTVS739 + emp[0].DPTVS753 + emp[0].DPTVS754 + emp[0].DPTVS755}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Junior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS740} onChange={(e) => { updateVal('DPTVS740', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS756} onChange={(e) => { updateVal('DPTVS756', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS740 + emp[0].DPTVS756} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Junior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS741} onChange={(e) => { updateVal('DPTVS741', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS757} onChange={(e) => { updateVal('DPTVS757', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS741 + emp[0].DPTVS757} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Junior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS742} onChange={(e) => { updateVal('DPTVS742', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS758} onChange={(e) => { updateVal('DPTVS758', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS742 + emp[0].DPTVS758} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS740 + emp[0].DPTVS741 + emp[0].DPTVS742 + emp[0].DPTVS756 + emp[0].DPTVS757 + emp[0].DPTVS758}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Non-supervisor Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS743} onChange={(e) => { updateVal('DPTVS743', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS759} onChange={(e) => { updateVal('DPTVS759', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS743 + emp[0].DPTVS759} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Non-supervisor Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS744} onChange={(e) => { updateVal('DPTVS744', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS760} onChange={(e) => { updateVal('DPTVS760', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS744 + emp[0].DPTVS760} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Non-supervisor Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS745} onChange={(e) => { updateVal('DPTVS745', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS761} onChange={(e) => { updateVal('DPTVS761', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS745 + emp[0].DPTVS761} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS743 + emp[0].DPTVS744 + emp[0].DPTVS745 + emp[0].DPTVS759 + emp[0].DPTVS760 + emp[0].DPTVS761}
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS746} onChange={(e) => { updateVal('DPTVS746', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS762} onChange={(e) => { updateVal('DPTVS762', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS746 + emp[0].DPTVS762} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS747} onChange={(e) => { updateVal('DPTVS747', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS763} onChange={(e) => { updateVal('DPTVS763', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS747 + emp[0].DPTVS763} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS746 + emp[0].DPTVS747 + emp[0].DPTVS762 + emp[0].DPTVS763}
                        </div>
                    </div>


                </div>
            ) : (
                <div>
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS732} onChange={(e) => { updateVal('DPTVS732', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS748} onChange={(e) => { updateVal('DPTVS748', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS732 + emp[0].DPTVS748} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other than Permanent Employees <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS733} onChange={(e) => { updateVal('DPTVS733', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS749} onChange={(e) => { updateVal('DPTVS749', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS733 + emp[0].DPTVS749} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS732 + emp[0].DPTVS733 + emp[0].DPTVS748 + emp[0].DPTVS749}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Senior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS734} onChange={(e) => { updateVal('DPTVS734', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS750} onChange={(e) => { updateVal('DPTVS750', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS734 + emp[0].DPTVS750} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Senior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS735} onChange={(e) => { updateVal('DPTVS735', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS751} onChange={(e) => { updateVal('DPTVS751', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS735 + emp[0].DPTVS751} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Senior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS736} onChange={(e) => { updateVal('DPTVS736', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS752} onChange={(e) => { updateVal('DPTVS752', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS736 + emp[0].DPTVS752} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS734 + emp[0].DPTVS735 + emp[0].DPTVS736 + emp[0].DPTVS750 + emp[0].DPTVS751 + emp[0].DPTVS752}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Middle Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS737} onChange={(e) => { updateVal('DPTVS737', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS753} onChange={(e) => { updateVal('DPTVS753', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS737 + emp[0].DPTVS753} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Middle Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS738} onChange={(e) => { updateVal('DPTVS738', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS754} onChange={(e) => { updateVal('DPTVS754', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS738 + emp[0].DPTVS754} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Middle Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS739} onChange={(e) => { updateVal('DPTVS739', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS755} onChange={(e) => { updateVal('DPTVS755', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS739 + emp[0].DPTVS755} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS737 + emp[0].DPTVS738 + emp[0].DPTVS739 + emp[0].DPTVS753 + emp[0].DPTVS754 + emp[0].DPTVS755}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Junior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS740} onChange={(e) => { updateVal('DPTVS740', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS756} onChange={(e) => { updateVal('DPTVS756', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS740 + emp[0].DPTVS756} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Junior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS741} onChange={(e) => { updateVal('DPTVS741', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS757} onChange={(e) => { updateVal('DPTVS757', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS741 + emp[0].DPTVS757} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Junior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS742} onChange={(e) => { updateVal('DPTVS742', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS758} onChange={(e) => { updateVal('DPTVS758', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS742 + emp[0].DPTVS758} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS740 + emp[0].DPTVS741 + emp[0].DPTVS742 + emp[0].DPTVS756 + emp[0].DPTVS757 + emp[0].DPTVS758}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Non-supervisor Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS743} onChange={(e) => { updateVal('DPTVS743', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS759} onChange={(e) => { updateVal('DPTVS759', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS743 + emp[0].DPTVS759} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Non-supervisor Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS744} onChange={(e) => { updateVal('DPTVS744', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS760} onChange={(e) => { updateVal('DPTVS760', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS744 + emp[0].DPTVS760} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Non-supervisor Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS745} onChange={(e) => { updateVal('DPTVS745', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS761} onChange={(e) => { updateVal('DPTVS761', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS745 + emp[0].DPTVS761} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS743 + emp[0].DPTVS744 + emp[0].DPTVS745 + emp[0].DPTVS759 + emp[0].DPTVS760 + emp[0].DPTVS761}
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS746} onChange={(e) => { updateVal('DPTVS746', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS762} onChange={(e) => { updateVal('DPTVS762', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS746 + emp[0].DPTVS762} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS747} onChange={(e) => { updateVal('DPTVS747', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS763} onChange={(e) => { updateVal('DPTVS763', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS747 + emp[0].DPTVS763} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS746 + emp[0].DPTVS747 + emp[0].DPTVS762 + emp[0].DPTVS763}
                        </div>
                    </div>


                </div>
            )}
        </div>
    );
};
const New_Employee_Hires_TVS = ({ data, extractedData = () => { }, getData, setData, isValidResponse, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [{
        DPTVS700: 0,
        DPTVS701: 0,
        DPTVS702: 0,
        DPTVS703: 0,
        DPTVS704: 0,
        DPTVS705: 0,
        DPTVS706: 0,
        DPTVS707: 0,
        DPTVS708: 0,
        DPTVS709: 0,
        DPTVS710: 0,
        DPTVS711: 0,
        DPTVS712: 0,
        DPTVS713: 0,
        DPTVS714: 0,
        DPTVS715: 0,
        DPTVS716: 0,
        DPTVS717: 0,
        DPTVS718: 0,
        DPTVS719: 0,
        DPTVS720: 0,
        DPTVS721: 0,
        DPTVS722: 0,
        DPTVS723: 0,
        DPTVS724: 0,
        DPTVS725: 0,
        DPTVS726: 0,
        DPTVS727: 0,
        DPTVS728: 0,
        DPTVS729: 0,
        DPTVS730: 0,
        DPTVS731: 0,
        remarks: ''
    }]

    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        console.log(saveEmpCatDiversity())
        setData();
        isValidResponse(saveEmpCatDiversity())
    }, [emp]);
    useEffect(() => {
        if (typeof extractedData === "function") {
            extractedData(extractData(data));
        } else {
            console.error("extractedData is not a function");
        }
    }, [data]);

    function extractData(data) {
        const result = [
            {
                maskId: "DPTVS700",
                uniqueId: "DPTVS700",
                title: "Permanent Employees-Male",
                label: "Permanent Employees-Male",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS700,
                uom: "nos",
            },
            {
                maskId: "DPTVS716",
                uniqueId: "DPTVS716",
                title: "Permanent Employees-Female",
                label: "Permanent Employees-Female",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS716,
                uom: "nos",
            },
            {
                maskId: "DPTVS701",
                uniqueId: "DPTVS701",
                title: "other than Permanent male Employees",
                label: "other than Permanent male Employees",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS701,
                uom: "nos",
            },
            {
                maskId: "DPTVS717",
                uniqueId: "DPTVS717",
                title: "other than Permanent female Employees",
                label: "other than Permanent female Employees",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS717,
                uom: "nos",
            },
            {
                maskId: "DPTVS702",
                uniqueId: "DPTVS702",
                title: "Senior Management male<30 years ",
                label: "Senior Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS702,
                uom: "nos",
            },
            {
                maskId: "DPTVS718",
                uniqueId: "DPTVS718",
                title: "Senior Management female<30 years ",
                label: "Senior Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS718,
                uom: "nos",
            },
            {
                maskId: "DPTVS703",
                uniqueId: "DPTVS703",
                title: "Senior Management male 30-50 Years",
                label: "Senior Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS703,
                uom: "nos",
            },
            {
                maskId: "DPTVS719",
                uniqueId: "DPTVS719",
                title: "Senior Management female 30-50 Years",
                label: "Senior Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS719,
                uom: "nos",
            },
            {
                maskId: "DPTVS704",
                uniqueId: "DPTVS704",
                title: "Senior Management male >50 years ",
                label: "Senior Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS704,
                uom: "nos",
            },
            {
                maskId: "DPTVS720",
                uniqueId: "DPTVS720",
                title: "Senior Management female >50 years ",
                label: "Senior Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS720,
                uom: "nos",
            },
            {
                maskId: "DPTVS705",
                uniqueId: "DPTVS705",
                title: "Middle Management male<30 years ",
                label: "Middle Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS705,
                uom: "nos",
            },
            {
                maskId: "DPTVS721",
                uniqueId: "DPTVS721",
                title: "Middle Management female<30 years ",
                label: "Middle Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS721,
                uom: "nos",
            },
            {
                maskId: "DPTVS706",
                uniqueId: "DPTVS706",
                title: "Middle Management male 30-50 Years",
                label: "Middle Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS706,
                uom: "nos",
            },
            {
                maskId: "DPTVS722",
                uniqueId: "DPTVS722",
                title: "Middle Management female 30-50 Years",
                label: "Middle Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS722,
                uom: "nos",
            },
            {
                maskId: "DPTVS707",
                uniqueId: "DPTVS707",
                title: "Middle Management male >50 years ",
                label: "Middle Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS707,
                uom: "nos",
            },
            {
                maskId: "DPTVS723",
                uniqueId: "DPTVS723",
                title: "Middle Management female >50 years ",
                label: "Middle Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS723,
                uom: "nos",
            },
            {
                maskId: "DPTVS708",
                uniqueId: "DPTVS708",
                title: "Junior Management male<30 years ",
                label: "Junior Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS708,
                uom: "nos",
            },
            {
                maskId: "DPTVS724",
                uniqueId: "DPTVS724",
                title: "Junior Management female<30 years ",
                label: "Junior Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS724,
                uom: "nos",
            },
            {
                maskId: "DPTVS709",
                uniqueId: "DPTVS709",
                title: "Junior Management male 30-50 Years",
                label: "Junior Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS709,
                uom: "nos",
            },
            {
                maskId: "DPTVS725",
                uniqueId: "DPTVS725",
                title: "Junior Management female 30-50 Years",
                label: "Junior Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS725,
                uom: "nos",
            },
            {
                maskId: "DPTVS710",
                uniqueId: "DPTVS710",
                title: "Junior Management male >50 years ",
                label: "Junior Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS710,
                uom: "nos",
            },
            {
                maskId: "DPTVS726",
                uniqueId: "DPTVS726",
                title: "Junior Management female >50 years ",
                label: "Junior Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS726,
                uom: "nos",
            },
            {
                maskId: "DPTVS711",
                uniqueId: "DPTVS711",
                title: "Non-supervisor Management male<30 years ",
                label: "Non-supervisor Management male<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS711,
                uom: "nos",
            },
            {
                maskId: "DPTVS727",
                uniqueId: "DPTVS727",
                title: "Non-supervisor Management female<30 years ",
                label: "Non-supervisor Management female<30 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS727,
                uom: "nos",
            },
            {
                maskId: "DPTVS712",
                uniqueId: "DPTVS712",
                title: "Non-supervisor Management male 30-50 Years",
                label: "Non-supervisor Management male 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS712,
                uom: "nos",
            },
            {
                maskId: "DPTVS728",
                uniqueId: "DPTVS728",
                title: "Non-supervisor Management female 30-50 Years",
                label: "Non-supervisor Management female 30-50 Years",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS728,
                uom: "nos",
            },
            {
                maskId: "DPTVS713",
                uniqueId: "DPTVS713",
                title: "Non-supervisor Management male >50 years ",
                label: "Non-supervisor Management male >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS713,
                uom: "nos",
            },
            {
                maskId: "DPTVS729",
                uniqueId: "DPTVS729",
                title: "Non-supervisor Management female >50 years ",
                label: "Non-supervisor Management female >50 years ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS729,
                uom: "nos",
            },
            {
                maskId: "DPTVS714",
                uniqueId: "DPTVS714",
                title: "Permanent Male Workers ",
                label: "Permanent Male Workers ",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS714,
                uom: "nos",
            },
            {
                maskId: "DPTVS730",
                uniqueId: "DPTVS730",
                title: "Permanent FeMale Workers",
                label: "Permanent FeMale Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS730,
                uom: "nos",
            },
            {
                maskId: "DPTVS715",
                uniqueId: "DPTVS715",
                title: "other than Permanent male Workers",
                label: "other than Permanent male Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS715,
                uom: "nos",
            },
            {
                maskId: "DPTVS731",
                uniqueId: "DPTVS731",
                title: "other than Permanent Female Workers",
                label: "other than Permanent Female Workers",
                formType: 3,
                dataType: 3,
                value: data[0]?.DPTVS731,
                uom: "nos",
            },
        ];
        console.log(result, "RESULT");
        return result;
    }
    getData(emp);
    const updateVal = (obj, val_) => {
        let loc = JSON.parse(JSON.stringify(emp))

        if (val_ === null) {
            if (obj === 'remarks') {
                loc[0][obj] = ''
            } else {
                loc[0][obj] = 0
            }

        } else {
            loc[0][obj] = val_
        }
        setEmp(loc)
        forceUpdate()

    };

    const saveEmpCatDiversity = () => {
        // let set1 = emp[0].DPTVS0090 + emp[0].DPTVS0091 + emp[0].DPTVS0092 + emp[0].DPTVS0093
        // let set2 = emp[0].DPTVS0094 + emp[0].DPTVS0095 + emp[0].DPTVS0096 + emp[0].DPTVS0097 + emp[0].DPTVS0098 + emp[0].DPTVS0099
        // let set3 = emp[0].DPTVS0100 + emp[0].DPTVS0101 + emp[0].DPTVS0102 + emp[0].DPTVS0103 + emp[0].DPTVS0104 + emp[0].DPTVS0105

        // let set4 = emp[0].DPTVS0106 + emp[0].DPTVS0107 + emp[0].DPTVS0108 + emp[0].DPTVS0109 + emp[0].DPTVS0110 + emp[0].DPTVS0111

        // let set5 = emp[0].DPTVS0112 + emp[0].DPTVS0113 + emp[0].DPTVS0114 + emp[0].DPTVS0115 + emp[0].DPTVS0116 + emp[0].DPTVS0117

        // let set6 = emp[0].DPTVS0118 + emp[0].DPTVS0119 + emp[0].DPTVS0120 + emp[0].DPTVS0121



        return true


    }

    return (
        <div>
            {edit === 1 ? (
                <div>
                    {edit === 1 && (
                        <div
                            className="col-12  p-0 align-items-center hidden"
                            style={{
                                display: "flex", margin: '10px 0px',
                                flexDirection: "row",
                            }}
                        >
                            <div className="col-10 grid m-0 p-0 justify-content-start">
                                <div >
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (e.target.tagName === "SPAN") {

                                            }
                                        }}
                                    >
                                        <FileUpload
                                            chooseOptions={{
                                                label: "Template",
                                                icon: "pi pi-download",
                                                className: "navy-button",
                                            }}

                                            mode="basic"
                                            name="demo[]"
                                            auto
                                            customUpload
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="form-upload-btn"
                                        />
                                    </div>
                                </div>
                                <div className="ml-2">
                                    <FileUpload
                                        chooseOptions={{
                                            label: "Import",
                                            icon: "pi pi-file-excel",
                                            className: "navy-button",
                                        }}

                                        mode="basic"
                                        name="demo[]"
                                        auto
                                        customUpload
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className="form-import-btn"
                                        uploadHandler={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 flex p-0 justify-content-end">

                            </div>
                        </div>
                    )}
                    <div className="col-12 grid">
                        <div className="col-4">

                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Male
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Female
                        </div>
                        <div className="col-2 flex justify-content-center fw-7">
                            Total
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Employees  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS700} onChange={(e) => { updateVal('DPTVS700', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS716} onChange={(e) => { updateVal('DPTVS716', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS700 + emp[0].DPTVS716} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other than Permanent Employees <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS701} onChange={(e) => { updateVal('DPTVS701', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS717} onChange={(e) => { updateVal('DPTVS717', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS701 + emp[0].DPTVS717} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS700 + emp[0].DPTVS716 + emp[0].DPTVS701 + emp[0].DPTVS717}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Senior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS702} onChange={(e) => { updateVal('DPTVS702', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS718} onChange={(e) => { updateVal('DPTVS718', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS702 + emp[0].DPTVS718} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Senior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS703} onChange={(e) => { updateVal('DPTVS703', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS719} onChange={(e) => { updateVal('DPTVS719', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS703 + emp[0].DPTVS719} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Senior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS704} onChange={(e) => { updateVal('DPTVS704', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS720} onChange={(e) => { updateVal('DPTVS720', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS704 + emp[0].DPTVS720} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS702 + emp[0].DPTVS703 + emp[0].DPTVS704 + emp[0].DPTVS718 + emp[0].DPTVS719 + emp[0].DPTVS720}
                        </div>
                    </div>


                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Middle Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS705} onChange={(e) => { updateVal('DPTVS705', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS721} onChange={(e) => { updateVal('DPTVS721', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS705 + emp[0].DPTVS721} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Middle Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS706} onChange={(e) => { updateVal('DPTVS706', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS722} onChange={(e) => { updateVal('DPTVS722', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS706 + emp[0].DPTVS722} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Middle Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS707} onChange={(e) => { updateVal('DPTVS707', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS723} onChange={(e) => { updateVal('DPTVS723', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS707 + emp[0].DPTVS723} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS705 + emp[0].DPTVS706 + emp[0].DPTVS707 + emp[0].DPTVS721 + emp[0].DPTVS722 + emp[0].DPTVS723}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Junior Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS708} onChange={(e) => { updateVal('DPTVS708', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS724} onChange={(e) => { updateVal('DPTVS724', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS708 + emp[0].DPTVS724} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Junior Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS709} onChange={(e) => { updateVal('DPTVS709', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS725} onChange={(e) => { updateVal('DPTVS725', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS709 + emp[0].DPTVS725} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Junior Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS710} onChange={(e) => { updateVal('DPTVS710', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS726} onChange={(e) => { updateVal('DPTVS726', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS710 + emp[0].DPTVS726} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS708 + emp[0].DPTVS709 + emp[0].DPTVS710 + emp[0].DPTVS724 + emp[0].DPTVS725 + emp[0].DPTVS726}
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            {'Non-supervisor Management < 30 years'} <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS711} onChange={(e) => { updateVal('DPTVS711', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS727} onChange={(e) => { updateVal('DPTVS727', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS711 + emp[0].DPTVS727} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                            Non-supervisor Management 30-50 years <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS712} onChange={(e) => { updateVal('DPTVS712', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS728} onChange={(e) => { updateVal('DPTVS728', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS712 + emp[0].DPTVS728} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">

                        <div className="col-4 ">
                        Non-supervisor Management >50 years  <span className="mandatory"> *</span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS713} onChange={(e) => { updateVal('DPTVS713', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS729} onChange={(e) => { updateVal('DPTVS729', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS713 + emp[0].DPTVS729} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS711 + emp[0].DPTVS712 + emp[0].DPTVS713 + emp[0].DPTVS727 + emp[0].DPTVS728 + emp[0].DPTVS729}
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>

                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS714} onChange={(e) => { updateVal('DPTVS714', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS730} onChange={(e) => { updateVal('DPTVS730', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS714 + emp[0].DPTVS730} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-12 grid">
                        <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                        <div className="col-4 flex">
                            Other Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                marginTop: 2,
                                marginLeft: 3
                            }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"> help</i></span>
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS715} onChange={(e) => { updateVal('DPTVS715', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS731} onChange={(e) => { updateVal('DPTVS731', e.value) }} />
                        </div>
                        <div className="col-2">
                            <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS715 + emp[0].DPTVS731} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                        </div>
                    </div>


                    <div className="col-12 flex fw-7 fs-16 justify-content-end">
                        <div className="col-4">
                            Total = {emp[0].DPTVS714 + emp[0].DPTVS715 + emp[0].DPTVS730 + emp[0].DPTVS731}
                        </div>
                    </div>


                </div>
            ) :
                (
                    <div>
                        <div className="col-12 grid">
                            <div className="col-4">

                            </div>
                            <div className="col-2 flex justify-content-center fw-7">
                                Male
                            </div>
                            <div className="col-2 flex justify-content-center fw-7">
                                Female
                            </div>
                            <div className="col-2 flex justify-content-center fw-7">
                                Total
                            </div>
                        </div>

                        <div className="col-12 grid">
                            <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                            <div className="col-4 flex">
                                Permanent Employees  <span className="mandatory"> *</span>       <span><i style={{
                                    marginTop: 2,
                                    marginLeft: 3
                                }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                    data-pr-position="right"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2"> help</i></span>

                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS700} onChange={(e) => { updateVal('DPTVS700', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS716} onChange={(e) => { updateVal('DPTVS716', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS700 + emp[0].DPTVS716} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                            </div>
                        </div>


                        <div className="col-12 grid">
                            <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                            <div className="col-4 flex">
                                Other than Permanent Employees <span className="mandatory"> *</span>       <span><i style={{
                                    marginTop: 2,
                                    marginLeft: 3
                                }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                    data-pr-position="right"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2"> help</i></span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS701} onChange={(e) => { updateVal('DPTVS701', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS717} onChange={(e) => { updateVal('DPTVS717', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS701 + emp[0].DPTVS717} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 flex fw-7 fs-16 justify-content-end">
                            <div className="col-4">
                                Total = {emp[0].DPTVS700 + emp[0].DPTVS716 + emp[0].DPTVS701 + emp[0].DPTVS717}
                            </div>
                        </div>


                        <div className="col-12 grid">

                            <div className="col-4 ">
                                {'Senior Management < 30 years'} <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS702} onChange={(e) => { updateVal('DPTVS702', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS718} onChange={(e) => { updateVal('DPTVS718', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS702 + emp[0].DPTVS718} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                                Senior Management 30-50 years <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS703} onChange={(e) => { updateVal('DPTVS703', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS719} onChange={(e) => { updateVal('DPTVS719', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS703 + emp[0].DPTVS719} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                        Senior Management >50 years  <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS704} onChange={(e) => { updateVal('DPTVS704', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS720} onChange={(e) => { updateVal('DPTVS720', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS704 + emp[0].DPTVS720} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 flex fw-7 fs-16 justify-content-end">
                            <div className="col-4">
                                Total = {emp[0].DPTVS702 + emp[0].DPTVS703 + emp[0].DPTVS704 + emp[0].DPTVS718 + emp[0].DPTVS719 + emp[0].DPTVS720}
                            </div>
                        </div>


                        <div className="col-12 grid">

                            <div className="col-4 ">
                                {'Middle Management < 30 years'} <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS705} onChange={(e) => { updateVal('DPTVS705', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS721} onChange={(e) => { updateVal('DPTVS721', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS705 + emp[0].DPTVS721} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                                Middle Management 30-50 years <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS706} onChange={(e) => { updateVal('DPTVS706', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS722} onChange={(e) => { updateVal('DPTVS722', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS706 + emp[0].DPTVS722} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                        Middle Management >50 years  <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS707} onChange={(e) => { updateVal('DPTVS707', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS723} onChange={(e) => { updateVal('DPTVS723', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS707 + emp[0].DPTVS723} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 flex fw-7 fs-16 justify-content-end">
                            <div className="col-4">
                                Total = {emp[0].DPTVS705 + emp[0].DPTVS706 + emp[0].DPTVS707 + emp[0].DPTVS721 + emp[0].DPTVS722 + emp[0].DPTVS723}
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                                {'Junior Management < 30 years'} <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS708} onChange={(e) => { updateVal('DPTVS708', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS724} onChange={(e) => { updateVal('DPTVS724', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS708 + emp[0].DPTVS724} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                                Junior Management 30-50 years <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS709} onChange={(e) => { updateVal('DPTVS709', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS725} onChange={(e) => { updateVal('DPTVS725', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS709 + emp[0].DPTVS725} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                        Junior Management >50 years  <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS710} onChange={(e) => { updateVal('DPTVS710', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS726} onChange={(e) => { updateVal('DPTVS726', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS710 + emp[0].DPTVS726} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 flex fw-7 fs-16 justify-content-end">
                            <div className="col-4">
                                Total = {emp[0].DPTVS708 + emp[0].DPTVS709 + emp[0].DPTVS710 + emp[0].DPTVS724 + emp[0].DPTVS725 + emp[0].DPTVS726}
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                                {'Non-supervisor Management < 30 years'} <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS711} onChange={(e) => { updateVal('DPTVS711', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS727} onChange={(e) => { updateVal('DPTVS727', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS711 + emp[0].DPTVS727} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                                Non-supervisor Management 30-50 years <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS712} onChange={(e) => { updateVal('DPTVS712', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS728} onChange={(e) => { updateVal('DPTVS728', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS712 + emp[0].DPTVS728} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">

                            <div className="col-4 ">
                        Non-supervisor Management >50 years  <span className="mandatory"> *</span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS713} onChange={(e) => { updateVal('DPTVS713', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS729} onChange={(e) => { updateVal('DPTVS729', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber disabled min={0} useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} onWheel={(e) => e.target.blur()} value={emp[0].DPTVS713 + emp[0].DPTVS729} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 flex fw-7 fs-16 justify-content-end">
                            <div className="col-4">
                                Total = {emp[0].DPTVS711 + emp[0].DPTVS712 + emp[0].DPTVS713 + emp[0].DPTVS727 + emp[0].DPTVS728 + emp[0].DPTVS729}
                            </div>
                        </div>

                        <div className="col-12 grid">
                            <Tooltip className="form-tt-1" target={".tooltip1"} mouseTrack mouseTrackLeft={10} />
                            <div className="col-4 flex">
                                Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                    marginTop: 2,
                                    marginLeft: 3
                                }} className={`material - icons fs - 14 tooltip1`} data-pr-tooltip={'Employee with a contract for an indeterminate period (i.e., indefinite contract) for full-time or part-time work.'}
                                    data-pr-position="right"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2"> help</i></span>

                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS714} onChange={(e) => { updateVal('DPTVS714', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS730} onChange={(e) => { updateVal('DPTVS730', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS714 + emp[0].DPTVS730} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                            </div>
                        </div>

                        <div className="col-12 grid">
                            <Tooltip className="form-tt-1" target={".tempemp"} mouseTrack mouseTrackLeft={10} />
                            <div className="col-4 flex">
                                Other Permanent Workers  <span className="mandatory"> *</span>       <span><i style={{
                                    marginTop: 2,
                                    marginLeft: 3
                                }} className={`material - icons fs - 14 tempemp`} data-pr-tooltip={`Employee with a contract for a limited period(i.e., fixed term contract) that ends when the specific
                        time period expires, or when the specific task or event that has an attached time estimate is completed(e.g., the end of a project or return of replaced employees).`  }
                                    data-pr-position="right"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2"> help</i></span>
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS715} onChange={(e) => { updateVal('DPTVS715', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%' }} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={emp[0].DPTVS731} onChange={(e) => { updateVal('DPTVS731', e.value) }} />
                            </div>
                            <div className="col-2">
                                <InputNumber min={0} disabled useGrouping={false} inputStyle={{ width: '100%', background: '#80808020' }} value={emp[0].DPTVS715 + emp[0].DPTVS731} onWheel={(e) => e.target.blur()} style={{ width: '100%' }} />
                            </div>
                        </div>


                        <div className="col-12 flex fw-7 fs-16 justify-content-end">
                            <div className="col-4">
                                Total = {emp[0].DPTVS714 + emp[0].DPTVS715 + emp[0].DPTVS730 + emp[0].DPTVS731}
                            </div>
                        </div>


                    </div>
                )}
        </div>
    );
};
const Employee_Commutte_TVS = ({ extractedData = () => { }, data, getData, setData, edit, standard, dates = { minDate: null, maxDate: null } }) => {
    const [mcdata, setMCData] = useState(data);
    const [mcDialog, setMCDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [load, setLoad] = useState(true)
    const [attachment, setAttachment] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [mcFilter, setMCFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [category, setCategory] = useState([]);
    const [category2, setCategory2] = useState([]);
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [unitlist, setUnitList] = useState([]);
    const [mcVal, setMcVal] = useState({
        DPTVS3000: "",
        DPTVS3007: "",
        DPTVS3001: null,
        DPTVS3002: null,
        DPTVS3003: null,
        DPTVS3004: null,
        DPTVS3005: null,
        DPTVS3006: null
    });

    getData(mcdata);

    useEffect(() => {
        let veh_cat = [];
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {

            let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                (i) => i.id === 1
            );
            console.log(stdindex);
            if (stdindex !== -1) {
                if (
                    JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                    undefined
                ) {
                    let cat_index = JSON.parse(JSON.stringify(res.data))[
                        stdindex
                    ].newEfCategories.findIndex((i) => i.id === 57);


                    if (cat_index !== -1) {
                        let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                            .newEfCategories[cat_index].newEfSubcategory1s;
                        if (subcat !== undefined) {
                            setLoad(false)
                            setCategory(subcat);
                            console.log(subcat);
                        } else {
                            setLoad(true)
                            console.log("missing3");
                        }
                    } else {
                        console.log("missing2");
                    }

                } else {
                    console.log("missing1");
                }
            } else {
                console.log("missing0");
            }

        });
    }, []);
    //typeOfVehicle & unit - mandatory , unit id based on root 
    function findMatchingIdsDynamic(array, typeOfVehicle, categoryOfVehicle, typeOfFuel, unit) {
        let result = { DPTVS3003: null, DPTVS3004: null, DPTVS3005: null, DPTVS3006: null, result: false };
        console.log(array, typeOfVehicle, categoryOfVehicle, typeOfFuel, unit)
        // Type of Vehicle & Unit are mandatory
        if (!typeOfVehicle?.trim() || !unit?.trim()) {
            return result;
        }

        // Step 1: Find Type of Vehicle
        let typeOfVehicleItem = array.find(item => item.title.trim().toLowerCase() === typeOfVehicle.trim().toLowerCase());
        if (!typeOfVehicleItem) return result;
        result.DPTVS3003 = typeOfVehicleItem.id;

        // Step 2: If only 4th is provided (Category & Fuel missing)
        if (!categoryOfVehicle?.trim() && !typeOfFuel?.trim()) {
            let unitItem = typeOfVehicleItem.newEfSubcategory2s?.find(item =>
                item.title.trim().toLowerCase() === unit.trim().toLowerCase() &&
                (!item.newEfSubcategory3s || item.newEfSubcategory3s.length === 0) // Ensure no children in 3rd level
            );

            if (unitItem) {
                result.DPTVS3004 = unitItem.id;
                result.result = true;
            }

            return result;
        }

        // Step 3: Find 2nd category if provided
        let categoryItem = categoryOfVehicle?.trim() ? typeOfVehicleItem.newEfSubcategory2s?.find(item =>
            item.title.trim().toLowerCase() === categoryOfVehicle.trim().toLowerCase()
        ) : null;

        if (categoryItem) {
            result.DPTVS3004 = categoryItem.id;

            // Case 2: 2nd & 4th provided, 3rd missing
            if (!typeOfFuel?.trim() && unit?.trim()) {
                let categoryUnit = categoryItem.newEfSubcategory3s?.find(item =>
                    item.title.trim().toLowerCase() === unit.trim().toLowerCase() &&
                    (!item.newEfSubcategory4s || item.newEfSubcategory4s.length === 0) // Ensure no children in 4th level
                );

                if (categoryUnit) {
                    result.DPTVS3005 = categoryUnit.id; // No 4th level, so 3rd is the unit
                    result.result = true;
                }

                return result;
            }

            // Step 4: Find 3rd category if provided
            let fuelItem = typeOfFuel?.trim() ? categoryItem.newEfSubcategory3s?.find(item =>
                item.title.trim().toLowerCase() === typeOfFuel.trim().toLowerCase()
            ) : null;

            if (fuelItem) {
                result.DPTVS3005 = fuelItem.id;

                // Step 5: Find 4th (Unit) if provided
                let unitItem = fuelItem.newEfSubcategory4s?.find(item =>
                    item.title.trim().toLowerCase() === unit.trim().toLowerCase()
                );

                if (unitItem) {
                    result.DPTVS3006 = unitItem.id;
                    result.result = true;
                }
            }
        }

        return result;
    }




    useEffect(() => {
        if (typeof extractedData === "function") {
            console.log(mcdata)
            extractedData(extractData(mcdata));
        } else {
            console.error("extractedData is not a function");
        }
    }, [mcdata, category]);
    let fuel_type_f = [
        { title: "Diesel", id: 13, key: "4-13-51" },
        { title: "Petrol", id: 19, key: "4-19-75" },
        { title: "CNG", id: 2, key: "1-2-7" },
    ];
    function extractTitles(categories, subCategory1, subCategory2, subCategory3, subCategory4) {
        const category = categories.find(cat => cat.id === subCategory1);
        if (!category) return null;

        const subCat2 = category.newEfSubcategory2s?.find(sub => sub.id === subCategory2);
        if (!subCat2) return null;

        const subCat3 = subCat2.newEfSubcategory3s?.find(sub => sub.id === subCategory3);
        const subCat4 = subCat3?.newEfSubcategory4s?.find(sub => sub.id === subCategory4);

        return {
            title: `${category.title} > ${subCat4 ? subCat4.title : subCat3 ? subCat3.title : subCat2.title}`,
            unit: subCat4 ? subCat4.title : subCat3 ? subCat3.title : subCat2.title
        };
    }

    function generateExpectedArray(categories, data) {
        return data.reduce((acc, item) => {
            const titles = extractTitles(categories, item.DPTVS3003, item.DPTVS3004, item.DPTVS3005, item.DPTVS3006);
            if (titles) {
                const uniqueIdParts = [item.DPTVS3003, item.DPTVS3004, item.DPTVS3005, item.DPTVS3006].filter(Boolean).join('-');
                acc.push({
                    uniqueId: uniqueIdParts,
                    maskId: item.id,
                    formType: 2,
                    dataType: 1,
                    title: titles.title,
                    label: titles.title,
                    unit: titles.unit,
                    subCategory1: item.DPTVS3003,
                    subCategory2: item.DPTVS3004,
                    subCategory3: item.DPTVS3005 ?? null,
                    subCategory4: item.DPTVS3006 ?? null,
                    value: item.DPTVS3002
                });
            }
            return acc;
        }, []);
    }
    function extractData(data) {
        const result = generateExpectedArray(category, data)
        console.log(result)

        return result
    }
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Employee Commutte") {
                    let local = {};
                    let local2 = [...mcdata];

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_EC") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                console.log(items);

                                // Direct mapping from Excel
                                local.sno = i + 1; // Direct from Excel (index + 1)
                                local.DPTVS3000 = items["Work Location"] ?? "";
                                local.DPTVS3007 = items["Current Residential Location"] ?? "";
                                local.DPTVS3001 = items["Average Days Commuting (per month)"] ?? "";
                                local.DPTVS3002 = items["One-way distance between home and workplace"] ?? ""; // In case of duplicate

                                if (local.DPTVS3000 && local.DPTVS3001 && local.DPTVS3002 && local.DPTVS3007 && items["Type of Vehicle"] && items["Unit"]) {
                                    const { result, ...rest } = findMatchingIdsDynamic(
                                        category,
                                        items["Type of Vehicle"].toString(),
                                        items["Category of Vehicle"]?.toString() || '',
                                        items['Type of Fuel']?.toString() || '',
                                        items['Unit'].toString()
                                    );
                                    console.log(result, rest)

                                    if (result) {
                                        local = { id: getID(), ...local, ...rest }
                                        local2.push(local)
                                    }
                                }
                            });
                        }
                    });

                    setMCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });

        e.options.clear();
    };
    const updateMcVal = (key, value) => {
        setMcVal((prev) => ({ ...prev, [key]: value }));

        // Reset dependent dropdowns
        if (key === "DPTVS3003") {
            setMcVal((prev) => ({ ...prev, DPTVS3004: null, DPTVS3005: null, DPTVS3006: null }));
        } else if (key === "DPTVS3004") {
            setMcVal((prev) => ({ ...prev, DPTVS3005: null, DPTVS3006: null }));
        } else if (key === "DPTVS3005") {
            setMcVal((prev) => ({ ...prev, DPTVS3006: null }));
        }
    };
    const isUnit = (item, level) => {
        if (level === "DPTVS3003" && !item.newEfSubcategory2s) return true;
        if (level === "DPTVS3004" && (!item.newEfSubcategory3s || item.newEfSubcategory3s.length === 0)) return true;
        if (level === "DPTVS3005" && (!item.newEfSubcategory4s || item.newEfSubcategory4s.length === 0)) return true;
        return false;
    };

    const getOptions = (level) => {
        if (level === "DPTVS3003") return category.map((item) => ({ id: item.id, title: item.title, isUnit: isUnit(item, level) }));
        if (level === "DPTVS3004") {
            const parent = category.find((item) => item.id === mcVal.DPTVS3003);
            return parent ? parent?.newEfSubcategory2s?.map((item) => ({ id: item.id, title: item.title, isUnit: isUnit(item, level) })) : [];
        }
        if (level === "DPTVS3005") {
            const parent = category.flatMap((c) => c.newEfSubcategory2s).find((item) => item.id === mcVal.DPTVS3004);
            return parent ? parent?.newEfSubcategory3s?.map((item) => ({ id: item.id, title: item.title, isUnit: isUnit(item, level) })) : [];
        }
        if (level === "DPTVS3006") {
            const parent = category.flatMap((c) => c.newEfSubcategory2s)
                .flatMap((c) => c.newEfSubcategory3s)
                .find((item) => item.id === mcVal.DPTVS3005);
            return parent ? parent?.newEfSubcategory4s?.map((item) => ({ id: item.id, title: item.title, isUnit: isUnit(item, level) })) : [];
        }
        return [];
    };

    const openNewMc = () => {
        setMcVal({
            DPTVS3000: "",
            DPTVS3007: "",
            DPTVS3001: null,
            DPTVS3002: null,
            DPTVS3003: null,
            DPTVS3004: null,
            DPTVS3005: null,
            DPTVS3006: null
        });
        setMCDialog(true);
        setSubmitted(false);
    };

    const saveMobileCombustion = () => {
        setSubmitted(true);

        // Check if a given category level has branches
        const hasBranch = (id, level) => {
            if (!id) return false; // If no id, it means no selection was made

            const findCategory = (categories, level) => {
                for (let cat of categories) {
                    if (cat.id === id) {
                        if (level === 1 && cat?.newEfSubcategory2s?.length > 0) return true;
                        if (level === 2 && cat?.newEfSubcategory3s?.length > 0) return true;
                        if (level === 3 && cat?.newEfSubcategory4s?.length > 0) return true;
                    }
                    // Recursively search in subcategories
                    if (cat.newEfSubcategory2s) {
                        if (findCategory(cat?.newEfSubcategory2s, level)) return true;
                    }
                    if (cat.newEfSubcategory3s) {
                        if (findCategory(cat?.newEfSubcategory3s, level)) return true;
                    }
                    if (cat.newEfSubcategory4s) {
                        if (findCategory(cat?.newEfSubcategory4s, level)) return true;
                    }
                }
                return false;
            };

            return findCategory(category, level);
        };

        // Validation checks
        if (
            mcVal?.DPTVS3000?.trim().length !== 0 && // Work Location required
            mcVal?.DPTVS3007?.trim().length !== 0 && // Current Residential Location required
            mcVal.DPTVS3001 != null && mcVal.DPTVS3001 >= 1 && mcVal.DPTVS3001 <= 31 && // Days must be > 0
            mcVal.DPTVS3002 != null && mcVal.DPTVS3002 >= 1 && mcVal.DPTVS3002 <= 80 && mcVal.DPTVS3003 !== null && // Distance must be > 0
            (hasBranch(mcVal.DPTVS3003, 1) ? mcVal.DPTVS3004 != null : true) && // Level 2 required if Level 1 has branches
            (hasBranch(mcVal.DPTVS3004, 2) ? mcVal.DPTVS3005 != null : true) && // Level 3 required if Level 2 has branches
            (hasBranch(mcVal.DPTVS3005, 3) ? mcVal.DPTVS3006 != null : true)    // Level 4 required if Level 3 has branches
        ) {
            let local = { ...mcVal }; // Clone existing data
            let localData = [...mcdata];

            if (local.id) {
                // Update existing entry
                let index = localData.findIndex((i) => i.id === local.id);
                if (index !== -1) {
                    localData[index] = local;
                }
            } else {
                // Add new entry
                local.id = getID(); // Generate unique ID
                localData.push(local);
            }

            setMcVal({
                DPTVS3000: "", // Reset Work Location
                DPTVS3007: "", // Reset Residential Location
                DPTVS3001: null, // Reset commuting days
                DPTVS3002: null, // Reset distance
                DPTVS3003: null, // Reset Vehicle Type
                DPTVS3004: null, // Reset Vehicle Category
                DPTVS3005: null, // Reset Fuel Type
                DPTVS3006: null, // Reset Unit
            });

            setMCData(localData); // Update state
            forceUpdate(); // Trigger re-render if needed
            setMCDialog(false); // Close Dialog
        }


    };


    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setMCFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const mcDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveMobileCombustion();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setMcVal(items);

        setMCDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = [...mcdata];
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setMCData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    const vehicleCatTemplate = (rowData) => {
        let text = "N/A";

        let index = category.findIndex((i) => i.id === rowData.DPTVS3003);
        if (index !== -1) {
            text = category[index].title;
        }


        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleTypeTemplate = (rowData) => {
        let text = "N/A";

        let index = category.findIndex((i) => i.id === rowData.DPTVS3003);
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined && rowData.DPTVS3005) {
            let index2 = category[index].newEfSubcategory2s.findIndex(
                (i) => i.id === rowData.DPTVS3004
            );
            if (index2 !== -1) {
                text = category[index].newEfSubcategory2s[index2].title;
            }
        }


        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };

    const vehicleFuelTemplate = (rowData) => {
        let text = "N/A";

        let index = category.findIndex((i) => i.id === rowData.DPTVS3003);
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined && rowData.DPTVS3006) {
            let index2 = category[index].newEfSubcategory2s.findIndex(
                (i) => i.id === rowData.DPTVS3004
            );
            if (
                index2 !== -1 &&
                category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                undefined
            ) {
                let index3 = category[index].newEfSubcategory2s[
                    index2
                ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPTVS3005);
                if (index3 !== -1) {
                    text =
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                            index3
                        ].title;
                }
            }
        }

        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const valueTemplate = (rowData) => {
        let distance = rowData.DPTVS3002 ?? "N/A"; // Distance Value
        let unit = "N/A"; // Default Unit

        // Find the root category using DPTVS3003
        let rootCategory = category.find((item) => item.id === rowData.DPTVS3003);

        if (rootCategory) {
            // Find unit in newEfSubCategory2s
            let subCategory2 = rootCategory.newEfSubcategory2s?.find(
                (item) => item.id === rowData.DPTVS3004
            );

            // Find unit in newEfSubCategory3s
            let subCategory3 = subCategory2?.newEfSubcategory3s?.find(
                (item) => item.id === rowData.DPTVS3005
            );

            // Find unit in newEfSubCategory4s
            let subCategory4 = subCategory3?.newEfSubcategory4s?.find(
                (item) => item.id === rowData.DPTVS3006
            );

            // Select the most specific available unit title
            unit = subCategory4?.title || subCategory3?.title || subCategory2?.title || "NA";
        }

        return (
            <>
                <span className="p-column-title"> Distance & Unit </span>
                {`${distance} ${unit}`}
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12  p-0 align-items-center"
                    style={{
                        display: "flex", margin: '10px 0px',
                        flexDirection: "row",
                    }}
                >
                    <div className="col-10 grid m-0 p-0 justify-content-start">
                        <div >
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === "SPAN") {
                                        exportToCSV(
                                            [
                                                {
                                                    sno: null,
                                                    "Work Location": null,
                                                    "Current Residential Location": null,
                                                    "Average Days Commuting (per month)": null,
                                                    "Type of Vehicle": null,
                                                    "Category of Vehicle": null,
                                                    "Type of Fuel": null,
                                                    "Unit": null,
                                                    "One-way distance between home and workplace": null,

                                                },
                                            ],
                                            "S3_EC",
                                            "Employee Commutte"
                                        );
                                    }
                                }}
                            >
                                <FileUpload
                                    chooseOptions={{
                                        label: "Template",
                                        icon: "pi pi-download",
                                        className: "navy-button",
                                    }}

                                    mode="basic"
                                    name="demo[]"
                                    auto
                                    customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-upload-btn"
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            <FileUpload
                                chooseOptions={{
                                    label: "Import",
                                    icon: "pi pi-file-excel",
                                    className: "navy-button",
                                }}

                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-import-btn"
                                uploadHandler={(e) => {
                                    importFromExcel(e, "Employee Commutte");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-2 flex p-0 justify-content-end">
                        <Button
                            label="Add"
                            icon="pi pi-plus"
                            className="navy-button"
                            style={{
                                width: 120,
                            }}
                            onClick={openNewMc}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={mcdata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                loading={load}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={mcFilter}
                emptyMessage="No data entered yet"
                // header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column field="DPTVS3000" header="Work Location" />
                <Column field="DPTVS3007" header="Current Residential Location" />

                <Column field="DPTVS3001" header="Average Days Commuting (per month)" />

                <Column field="DPTVS3003" header="Vehicle Type" body={vehicleCatTemplate} />
                <Column field="DPTVS3004" header="Vehicle Category" body={vehicleTypeTemplate} />
                <Column field="DPTVS3005" header="Fuel Type" body={vehicleFuelTemplate} />
                <Column field="DPTVS3006" header="One-way distance between home and workplace" body={valueTemplate} />
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={mcDialog}
                header="Add Employee Commute"
                style={{ width: "450px" }}
                className="p-fluid"
                onHide={() => setMCDialog(false)}
                modal
            >

                <div className="field">
                    <label>
                        Work Location <span className="mandatory"> *</span>
                    </label>
                    <InputText value={mcVal.DPTVS3000} onChange={(e) => updateMcVal("DPTVS3000", e.target.value)} />
                    {submitted && !mcVal?.DPTVS3000?.trim() && (
                        <small className="p-invalid" style={{ color: "red" }}>Work Location is required.</small>
                    )}
                </div>

                <div className="field">
                    <label>
                        Current Residential Location <span className="mandatory"> *</span>
                    </label>
                    <InputText value={mcVal.DPTVS3007} onChange={(e) => updateMcVal("DPTVS3007", e.target.value)} />
                    {submitted && !mcVal?.DPTVS3007?.trim() && (
                        <small className="p-invalid" style={{ color: "red" }}>Current Residential Location is required.</small>
                    )}
                </div>

                <div className="field">
                    <label>
                        Average Days Commuting (per month) <span className="mandatory"> *</span>
                    </label>
                    <InputNumber min={1} max={31} value={mcVal.DPTVS3001} onChange={(e) => updateMcVal("DPTVS3001", parseFloat(e.value))} />
                    {submitted && (mcVal.DPTVS3001 === null || mcVal.DPTVS3001 <= 0 || mcVal.DPTVS3002 > 31) && (
                        <small className="p-invalid" style={{ color: "red" }}>Must be greater than 0.</small>
                    )}
                </div>

                <div className="field">
                    <label>
                        One-way Distance (KM) <span className="mandatory"> *</span>
                    </label>
                    <InputNumber maxFractionDigits={2} min={0} max={80} value={mcVal.DPTVS3002} onChange={(e) => updateMcVal("DPTVS3002", parseFloat(e.value))} />
                    {submitted && (mcVal.DPTVS3002 === null || mcVal.DPTVS3002 <= 0 || mcVal.DPTVS3002 > 79) && (
                        <small className="p-invalid" style={{ color: "red" }}>Must be greater than 0 and lesser than 80</small>
                    )}
                </div>

                <div className="field">
                    <label>
                        Type of Vehicle <span className="mandatory"> *</span>
                    </label>
                    <Dropdown value={mcVal.DPTVS3003} onChange={(e) => updateMcVal("DPTVS3003", e.value)} options={getOptions("DPTVS3003")} optionLabel="title" optionValue="id" placeholder="Select" />
                    {submitted && mcVal.DPTVS3003 === null && (
                        <small className="p-invalid" style={{ color: "red" }}>Select vehicle type</small>
                    )}
                </div>

                {mcVal.DPTVS3003 && getOptions("DPTVS3004")?.length > 0 && (
                    <div className="field">
                        <label>
                            Category <span className="mandatory"> *</span>
                        </label>
                        <Dropdown value={mcVal.DPTVS3004} onChange={(e) => updateMcVal("DPTVS3004", e.value)} options={getOptions("DPTVS3004")} optionLabel="title" optionValue="id" placeholder="Select" />
                        {submitted && mcVal.DPTVS3004 === null && (
                            <small className="p-invalid" style={{ color: "red" }}>Select category</small>
                        )}
                    </div>
                )}

                {mcVal.DPTVS3004 && getOptions("DPTVS3005")?.length > 0 && (
                    <div className="field">
                        <label>
                            Fuel Type <span className="mandatory"> *</span>
                        </label>
                        <Dropdown value={mcVal.DPTVS3005} onChange={(e) => updateMcVal("DPTVS3005", e.value)} options={getOptions("DPTVS3005")} optionLabel="title" optionValue="id" placeholder="Select" />
                        {submitted && mcVal.DPTVS3005 === null && (
                            <small className="p-invalid" style={{ color: "red" }}>Select fuel type</small>
                        )}
                    </div>
                )}

                {mcVal.DPTVS3005 && getOptions("DPTVS3006")?.length > 0 && (
                    <div className="field">
                        <label>
                            Unit <span className="mandatory"> *</span>
                        </label>
                        <Dropdown value={mcVal.DPTVS3006} onChange={(e) => updateMcVal("DPTVS3006", e.value)} options={getOptions("DPTVS3006")} optionLabel="title" optionValue="id" placeholder="Select" />
                        {submitted && mcVal.DPTVS3006 === null && (
                            <small className="p-invalid" style={{ color: "red" }}>Select unit</small>
                        )}
                    </div>
                )}

                <div className="p-d-flex p-jc-end">
                    <Button label="Save" onClick={saveMobileCombustion} />
                </div>
            </Dialog>


        </div>
    );
};
export {
    Stationary_Combustion, //no need
    Stationary_Combustion_,
    Fugitive,//no need
    Fugitive_,
    Business_Travel,//no need
    Business_Travel_,
    Mobile_Combustion,//no need
    Mobile_Combustion_,
    Purchase_Goods_Services,//no need
    Purchase_Goods_Services_,
    BP8EQ2,
    BP2LQ3,
    BP2LQ5,
    GR2LQ1,
    GR2LQ2,
    GR2LQ3,
    HotelStay,
    Electricity,
    Employee_Category,
    Diversity_of_Employees,
    Capital_Goods,
    Scope3_Investments,
    Upstream_Trans_Dist,
    Downstream_Trans_Dist,
    Employee_Category_Diversity_STT,
    Total_No_of_New_Employee_STT,
    Total_No_of_Employee_Left_STT,
    Total_No_of_Employee_Hire_TurnOver_STT,
    Employee_Training_Hours_STT,
    Stationary_Combustion_Rotary,
    Mobile_Combustion_Rotary,
    Hazardous_NonHazardous_Rotary,
    Employee_Demographics_263, VehicleInformation, VehicleSold, DistanceTravelledVehicle, SoldProduct, Employee_Diversity_TVS, Emissions_Due_Downstream_TransportationAndDistribution_SpendBased, HazardousWaste_TVS,
    New_Employee_Turnover_TVS, New_Employee_Hires_TVS, Employee_Commutte_TVS
};
