import React, { useState, useEffect } from "react";
import APIServices from "../service/APIService";
import { API } from "../constants/api_url";
import { AttachmentComponent } from "../components/Forms/Attachment";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

const ApproveDealerAction = ({ data, refresh }) => {
    const [rootCause, setRootCause] = useState("");
    const [correctiveAction, setCorrectiveAction] = useState("");
    const [actionDesc, setActionDesc] = useState("");
    const [document, setDocument] = useState([]);
    const [applicableLaw, setApplicableLaw] = useState("");
    const [commands, setCommands] = useState("");      // <-- New state for Commands
    const [errors, setErrors] = useState({});
    const login_data = useSelector((state) => state.user.userdetail);
    const vendorCode = useSelector((state) => state.user.currentVendorCode);

    const nonComplianceOptions = [
        { label: "Regulatory (Major)", id: 1 },
        { label: "Regulatory (Minor)", id: 2 },
        { label: "Minor", id: 3 },
    ];
    const nonComplianceLabel =
        nonComplianceOptions.find((option) => option.id === data.nonComplianceType)?.label || "Unknown";

    // Fetch applicable law
    useEffect(() => {
        APIServices.get(API.GetList_DD(2))
            .then((res) => {
                if (res?.data && Array.isArray(res.data)) {
                    const foundLaw = res.data.find((law) => law.id === data.applicableLaw);
                    setApplicableLaw(foundLaw ? foundLaw.title : "Not Available");
                } else {
                    setApplicableLaw("Not Available");
                }
            })
            .catch((error) => {
                console.error("Error fetching applicable law:", error);
                setApplicableLaw("Error fetching data");
            });
    }, [data.applicableLaw]);

    // Extract supplier action history including attachments
    useEffect(() => {
        // if (data.supplierActionHistories && data.supplierActionHistories.length > 0) {
        //     const lastAction = data.supplierActionHistories[data.supplierActionHistories?.length -1];
        //     setRootCause(lastAction.rootCause || "");
        //     setCorrectiveAction(lastAction.correctiveAction || "");
        setActionDesc(data.actionTaken || "");
        // }
    }, [data]);

    // Validation
    const validateFields = () => {
        let newErrors = {};

        // if (!rootCause.trim()) newErrors.rootCause = "Root cause is required.";
        // if (!correctiveAction.trim()) newErrors.correctiveAction = "Corrective action is required.";
        // if (!actionDesc.trim()) newErrors.actionDesc = "Action description is required.";
        // if (document.length === 0) newErrors.document = "You must upload at least one document.";
        if (!commands.trim()) newErrors.commands = "Commands field is required."; // <-- Validation for the new field

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Common function to submit the patch call (approve = true or false)
    const handleSubmit = (rejectValue) => {
        if (!validateFields()) return;


        if (rejectValue === 0) {
            try {
                APIServices.patch(API.ActionDealerApprover(data.id), {
                    comments: commands
                })


            } catch {

            } finally {
                refresh()
            }


        } else if (rejectValue === 1) {
            try {
                APIServices.patch(API.ActionDealerReturn(data.id), {

                    comments: commands
                }).then((res) => {

                })


            } catch {

            } finally {
                refresh()
            }

        }


    };
    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }
    return (
        <div className="container-fluid">
            {/* Action Details */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">{data.maskId} ({data.category === "2" ? "Service" : "Sales"})</h4>
                <Divider />
                <div className="row">
                    <div className="col-md-12 ">
                        {/* <p><strong>ID:</strong> </p> */}
                        <p><strong>Description:</strong> {data.description}</p>
                        <p><strong>Action To be Taken:</strong> {data.actionToBeTaken}</p>
                        <p><strong>Person Responsible:</strong> {data.applicationDetails.personResponsible
                        }</p>
                        <p><strong>Due Date:</strong> {(() => {
                            if (!data?.dueDate) return "";

                            // Parse the incoming date
                            const parsedDate = new Date(data.dueDate);

                            // If invalid, just show the raw value
                            if (isNaN(parsedDate)) return data.dueDate;

                            // Format: DD-MM-YYYY
                            const day = String(parsedDate.getDate()).padStart(2, "0");
                            const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
                            const year = parsedDate.getFullYear();

                            return `${day}-${month}-${year}`;
                        })()}</p>

                        {/* <p><strong>Category:</strong> {}</p> */}
                    </div>
                    <div className="col-md-12">





                    </div>
                </div>
            </Card>

            {/* Input Fields */}
            <Card className="mb-4 shadow-sm">
                {/* <h4 className="mb-3 clr-navy">Action Taken by Supplier</h4> */}
                {/* <Divider /> */}

                {/* Root Cause */}
                {/* <div className="mb-4">
                    <h5 className="text-secondary">Identify the Root Cause(s)</h5>
                    <div
                        className="border p-2"
                        style={{ backgroundColor: "#f8f9fa" }}
                        dangerouslySetInnerHTML={{ __html: rootCause }}
                    />
                    {errors.rootCause && <Message severity="error" text={errors.rootCause} />}
                </div> */}

                {/* Corrective Actions */}
                {/* <div className="mb-4">
                    <h5 className="text-secondary">Identified Corrective Actions</h5>
                    <div
                        className="border p-2"
                        style={{ backgroundColor: "#f8f9fa" }}
                        dangerouslySetInnerHTML={{ __html: correctiveAction }}
                    />
                    {errors.correctiveAction && <Message severity="error" text={errors.correctiveAction} />}
                </div> */}

                {/* Action Description */}
                <div className="mb-4">
                    <h5 className="text-secondary">Description of the Action Taken</h5>
                    <div
                        className="border p-2"
                        style={{ backgroundColor: "#f8f9fa" }}
                        dangerouslySetInnerHTML={{ __html: actionDesc }}
                    />
                    {errors.actionDesc && <Message severity="error" text={errors.actionDesc} />}
                </div>
            </Card>

            {/* Attachment */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">Upload Evidence</h4>
                <Divider />
                <div style={{
                    border: '1px solid #6366F170',
                    borderRadius: '10px'
                }}>
                    {data.evidence && data.evidence.map((file, findex) => {
                        console.log(getFileExtension(file))
                        return (
                            <>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center', margin: 5
                                }} >
                                    <div className="flex align-items-center text-three-dot col-10">
                                        {(getFileExtension(file) === 'pdf') ?
                                            <img src={require('../assets/images/pdf_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                            (getFileExtension(file) === 'xlsx' || getFileExtension(file) === 'xls') ?
                                                <img src={require('../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                (getFileExtension(file) === 'pptx' || getFileExtension(file) === 'ppt') ?
                                                    <img src={require('../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                    (getFileExtension(file) === 'docx' || getFileExtension(file) === 'doc') ?
                                                        <img src={require('../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                        <img alt={file} role="presentation" src={API.Docs + file} width={100} style={{ borderRadius: 10 }} />}
                                        <span className="flex align-items-center text-left ml-3  clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file) }}>
                                            {file}
                                            {/* <small>{new Date().toLocaleDateString()}</small> */}
                                        </span>
                                    </div>


                                </div>

                            </>
                        )
                    })

                    }
                </div>

                {/* <AttachmentComponent
                    mandatory={true}
                    edit={0}
                    getFiles={(e) => setDocument(e)}
                    documents={
                        data.supplierActionHistories[data.supplierActionHistories.length - 1]?.supplierAttachments || []
                    }
                    labels="Please attach any evidence or records to support the data input."
                    hint=""
                /> */}
                {errors.document && <Message severity="error" text={errors.document} />}
            </Card>

            {/* Commands Textarea */}
            <Card className="mb-4 shadow-sm">
                <h4 className="mb-3 clr-navy">{'Comments'}</h4>
                <Divider />
                <textarea
                    className="form-control"
                    rows={3}
                    value={commands}
                    onChange={(e) => setCommands(e.target.value)}
                    placeholder="Enter your comments here..."
                />
                {errors.commands && <Message severity="error" text={errors.commands} />}
            </Card>

            {/* Approve / Reject Buttons */}
            <div className="flex justify-content-end mt-3 gap-2">
                <Button
                    label="Approve"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => handleSubmit(0)}   // reject=0
                />
                <Button
                    label="Reject"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => handleSubmit(1)}   // reject=1
                />
            </div>
        </div>
    );
};

export default ApproveDealerAction;
