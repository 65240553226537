import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { BP8EQ2, BP2LQ3, BP2LQ5, Stationary_Combustion_, Fugitive_, Mobile_Combustion_, Purchase_Goods_Services_, GR2LQ1, GR2LQ2, GR2LQ3, Business_Travel_, HotelStay, Electricity, Employee_Category, Diversity_of_Employees, Scope3_Investments, Capital_Goods, Upstream_Trans_Dist, Downstream_Trans_Dist, Employee_Category_Diversity_STT, Total_No_of_New_Employee_STT, Total_No_of_Employee_Left_STT, Total_No_of_Employee_Hire_TurnOver_STT, Employee_Training_Hours_STT, Stationary_Combustion_Rotary, Mobile_Combustion_Rotary, Hazardous_NonHazardous_Rotary, Employee_Demographics_263, VehicleInformation, VehicleSold, DistanceTravelledVehicle, SoldProduct, Employee_Diversity_TVS, Emissions_Due_Downstream_TransportationAndDistribution_SpendBased, HazardousWaste_TVS, New_Employee_Hires_TVS, New_Employee_Turnover_TVS, Employee_Commutte_TVS } from "../../components/hardcoded/hardcodedforms";
import { Editor } from "primereact/editor";
import { hardcoded } from "../../components/hardcoded/hardcodedid";
import APIServices from "../../service/APIService";
import { DateTime } from "luxon";
import { StickyContainer, Sticky } from 'react-sticky';
import { DCFSubmissionReturn, DCFSubmission } from "../../constants/emailTemplate";
import { InputNumber } from "primereact/inputnumber";
import { AttachmentComponent } from "../../components/Forms/Attachment";
import { getReportingFiscalYearByReportingperiod, getRPTextFormat } from "../../components/BGHF/helper";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { BlockUI } from 'primereact/blockui';
import { ReadMoreComponent } from "../../components/Forms/ReadMoreComponent";
import { getComparisionData } from "../../Dashboard/dcfsubcomparisonhelper";
import DCFFormTemplate, { DCF363, DCF363Template } from "../../components/ExcelTemplate/DCFFormTemplate";
import { FileUpload } from "primereact/fileupload";
import * as XLSX from "xlsx";

window.jQuery = $;
window.$ = $;

const QuantitativeNewSubmission = () => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail)

    const [structuredResponse, setStructuredResponse] = useState([])
    const [data, setData] = useState([])
    const dpnamerefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
    const [tempload, setTempLoad] = useState(false)
    const [remarksdialog, setRemarksDialog] = useState(false)
    const [remarksdata, setRemarksData] = useState([])
    const [assignment, setAssignment] = useState([])
    const [pastdata, setPastData] = useState([])
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [assigneedialog, setAssigneeDialog] = useState(false)
    const [assigneeobj, setAssigneeObj] = useState({ reviewer_ids: [], reporter_ids: [], approver_ids: [] })
    const doneDcf = [11, 257, 10, 16, 275, 263, 284]

    const [document, setDocument] = useState(null)
    const [efcatass, setEFCatAss] = useState([])
    const [actual, setActual] = useState([])
    const [submisisonStatus, setSubmissionStatus] = useState(false)
    const [show, setShow] = useState(false)
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    let params = useLocation()
    if (params.state === undefined) {
        params = { state: JSON.parse(sessionStorage.getItem('newer')) }
    }
    let std = params.state.draft === undefined ? params.state.standard : params.state.draft.standard
    const userList = useSelector(state => state.userlist.userList)
    const [ass, setAss] = useState([])
    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188', '195', '196','245']
    useEffect(async () => {
        console.log(params)
        console.log(DCFSubmission(1, 2, 3, 4, 5, 0))
        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let site_url = API.LocationOne_UP(params.state.company_id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        const promise0 = APIServices.get(API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`)
        const promise1 = APIServices.get(API.EF_SC1);
        const promise2 = APIServices.get(API.EF_SC2);
        const promise3 = APIServices.get(API.EF_SC3);
        const promise4 = APIServices.get(API.EF_SC4);
        Promise.all([
            promise0,
            promise1,
            promise2,
            promise3,
            promise4]).then((values) => {

                if (!checkHardcoded(id)) {
                    APIServices.post(API.CurPrevfyMonthStatusQN, { userProfileId: admin_data.id, dcfId: parseFloat(id), level: params.state.level, locationId: params.state.locationId, fymonth, year: getReportingFiscalYearByReportingperiod(params.state.reporting_period) }).then((res) => {
                        console.log(res.data)
                        let pastmonthData = []
                        setAssignment(res.data.assignment)
                        let names = [
                            {
                                name: JSON.parse(
                                    JSON.stringify(
                                        res.data.dcf.data1.filter((obj) => obj.type === "number").map(i => ({ label: i.dp, unit: i.unit }))
                                    )
                                ),
                            },
                        ]
                        res.data.assignment.forEach((i) => {
                            let newObj2 = { refobj: i, frequency: i.frequency, standard: i.standard, entityUserAssId: i.id, company_id: admin_data.id, level: i.level, locationId: i.locationId, entityAssId: i.entityAssId }
                            let index = pastmonthData.findIndex(x => x.year === i.year)
                            if (index !== -1) {
                                pastmonthData[index]['dp_array'].push(getValueByMonthly(parseFloat(id), i.tier0_id, i.tier1_id, i.tier2_id, i.tier3_id, [getRPTextFormat(i.reporting_period)], (res.data.dcf && res.data.dcf.data1 && res.data.dcf.data1.length) ? res.data.dcf.data1.filter((obj) => obj.type === "number").map(i => i.name) : [], res.data.assignment.flatMap(i => i.data).filter(i => i), (!i.reviewer_ids || !i.reviewer_ids.length) ? true : false, newObj2))

                            } else {
                                pastmonthData.push({ year: i.year, dcfId: parseFloat(id), form_type: 1, dp_array: [...names, getValueByMonthly(parseFloat(id), i.tier0_id, i.tier1_id, i.tier2_id, i.tier3_id, [getRPTextFormat(i.reporting_period)], (res.data.dcf && res.data.dcf.data1 && res.data.dcf.data1.length) ? res.data.dcf.data1.filter((obj) => obj.type === "number").map(i => i.name) : [], res.data.assignment.flatMap(i => i.data).filter(i => i), (!i.reviewer_ids || !i.reviewer_ids.length) ? true : false, newObj2)] })
                            }

                        })
                        console.log(pastmonthData)
                        setPastData(pastmonthData)


                    })
                } else if (doneDcf.includes(parseFloat(id))) {
                    APIServices.post(API.CurPrevfyMonthStatusQN, { userProfileId: admin_data.id, dcfId: parseFloat(id), level: params.state.level, locationId: params.state.locationId, fymonth, year: getReportingFiscalYearByReportingperiod(params.state.reporting_period) }).then((res) => {
                        console.log(res.data)
                        let pastmonthData = []
                        setAssignment(res.data.assignment)

                        res.data.assignment.forEach((i) => {
                            let newObj2 = { refobj: i, frequency: i.frequency, standard: i.standard, entityUserAssId: i.id, company_id: admin_data.id, level: i.level, locationId: i.locationId, entityAssId: i.entityAssId, self: (!i.reviewer_ids || !i.reviewer_ids.length) ? true : false }
                            let index = pastmonthData.findIndex(x => x.year === i.year)
                            if (index !== -1) {
                                pastmonthData[index]['dp_array'].push(getComparisionData(parseFloat(id), i.tier0_id, i.tier1_id, i.tier2_id, i.tier3_id, [getRPTextFormat(i.reporting_period)], res.data.assignment.flatMap(i => i.data).filter(i => i), (!i.reviewer_ids || !i.reviewer_ids.length) ? true : false, newObj2, values[0].data, { cat1: values[1].data, cat2: values[2].data, cat3: values[3].data, cat4: values[4].data }))

                            } else {
                                pastmonthData.push({ year: i.year, dcfId: parseFloat(id), form_type: 1, dp_array: [getComparisionData(parseFloat(id), i.tier0_id, i.tier1_id, i.tier2_id, i.tier3_id, [getRPTextFormat(i.reporting_period)], res.data.assignment.flatMap(i => i.data).filter(i => i), (!i.reviewer_ids || !i.reviewer_ids.length) ? true : false, newObj2, values[0].data, { cat1: values[1].data, cat2: values[2].data, cat3: values[3].data, cat4: values[4].data })] })
                            }

                        })
                        let openedfy = getReportingFiscalYearByReportingperiod(params.state.reporting_period)
                        let one = pastmonthData.find(i => i.year === openedfy)
                        let two = pastmonthData.find(i => i.year === openedfy - 1)
                        let msn_ = []
                        if (one) {

                            let names_ = getDPNameHC(one.dp_array)
                            one.dp_array.forEach((i) => {
                                Object.values(i).forEach((j) => {
                                    let oldObj = {}
                                    let oldObjInd = j.findIndex(l => { return (l.id !== null && l.id !== undefined) })
                                    if (oldObjInd !== -1) {
                                        console.log(j[oldObjInd])
                                        oldObj = { id: j[oldObjInd].id, type: j[oldObjInd].type, status: j[oldObjInd].status, remarks: j[oldObjInd].remarks === undefined ? null : j[oldObjInd].remarks, self: j[oldObjInd].self }
                                    }
                                    names_.forEach((k) => {
                                        let nameindex = j.findIndex(l => l.dp === k.label)

                                        if (nameindex === -1 && k.label !== null) {
                                            j.push({ ...oldObj, category: null, diff: false, form_type: 2, current: '-', dp: k.label, order: k.order })
                                        } else if (k.label !== null) {
                                            j[nameindex].order = k.order
                                        }
                                    })
                                    j.sort((a, b) => { return a.order - b.order }, 0)
                                })
                            })


                            one['dp_array'] = [{ name: names_.filter(i => { return i.label !== null }) }, ...one['dp_array']]
                        }
                        if (two) {
                            let names_ = getDPNameHC(two.dp_array)
                            two.dp_array.forEach((i) => {
                                Object.values(i).forEach((j) => {
                                    let oldObj = {}
                                    let oldObjInd = j.findIndex(l => { return (l.id !== null && l.id !== undefined) })
                                    if (oldObjInd !== -1) {
                                        console.log(j[oldObjInd])
                                        oldObj = { id: j[oldObjInd].id, type: j[oldObjInd].type, status: j[oldObjInd].status, remarks: j[oldObjInd].remarks === undefined ? null : j[oldObjInd].remarks, self: j[oldObjInd].self }
                                    }
                                    names_.forEach((k) => {
                                        let nameindex = j.findIndex(l => l.dp === k.label)

                                        if (nameindex === -1 && k.label !== null) {
                                            j.push({ ...oldObj, category: null, diff: false, form_type: 2, current: '-', dp: k.label, order: k.order })
                                        } else if (k.label !== null) {
                                            j[nameindex].order = k.order
                                        }
                                    })
                                    j.sort((a, b) => { return a.order - b.order }, 0)
                                })
                            })


                            two['dp_array'] = [{ name: names_.filter(i => { return i.label !== null }) }, ...two['dp_array']]
                        }
                        console.log(one, two)
                        setPastData([one, two].filter(i => i))


                    })
                }
                APIServices.get(API.DCF_Edit(id)).then((res) => {
                    setShow(true)
                    setData(res.data)
                    setActual(JSON.stringify(res.data.data1))


                }).catch((res) => {
                    setShow(false)
                })
            })

        APIServices.get(API.Client_EF_Cat_Ass_Up(admin_data.id)).then((res) => {
            setEFCatAss(res.data)
        })


        APIServices.get(site_url).then((res) => {

            const shapedSite = res.data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            setSiteList(shapedSite)
        })
        APIServices.get(API.DCF_Entity_User_UP(admin_data.id)).then((res) => {
            setAss(res.data)
        })
    }, [login_data]);

    const checkUpdation = () => {
        let a = JSON.stringify(data.data1)
        let b = actual
        console.log(a === b)
        return a === b

    }
    const attachObj = (arr, refobj) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i] = { ...arr[i], refobj: refobj.refobj }
        }
        return arr
    }
    const getDPNameHC = (arr) => {
        let result = [], count = 0
        console.log(arr)
        arr.forEach((i) => {
            Object.values(i).forEach((j) => {
                j.forEach((k) => {
                    if (result.findIndex(x => x.label === k.dp) === -1) {
                        count++
                        result.push({ label: k.dp, order: count })
                    }
                })

            })
        })
        console.log(result)
        return result
    }
    function getNumberOfColumn(monthString) {
        if (monthString.includes("to")) {
            const [startMonth, endMonth] = monthString.split(" to ");

            const startDate = DateTime.fromFormat(startMonth, "LLL-yyyy");
            let endDate = DateTime.local(); // Default to current month and year
            if (endMonth) {
                endDate = DateTime.fromFormat(endMonth, "LLL-yyyy");
            }

            // Calculate the difference in months
            const diffMonths = endDate.diff(startDate, "months").months + 1;

            return diffMonths;
        } else {
            return 1;
        }
    }
    function getPreviousPeriod(frequency, key) {
        if (frequency === 1) {
            const [month, year] = key.split("-");
            const previousDate = DateTime.fromFormat(
                `${month}-1-${year}`,
                "LLL-d-yyyy"
            ).minus({ months: 1 });
            const previousMonth = previousDate.toFormat("LLL");
            const previousYear = previousDate.year;
            return previousMonth + "-" + previousYear;
        } else {
            const endMonth = key.split(" to ")[0].split("-")[0].trim();
            const endYear = key.split(" to ")[0].split("-")[1].trim();
            const previousDate = DateTime.fromFormat(
                `${endMonth}-1-${endYear}`,
                "LLL-d-yyyy"
            ).minus({ months: 1 });
            const previousMonth = previousDate.toFormat("LLL");
            const previousYear = previousDate.year;
            return previousMonth + "-" + previousYear;
        }
    }
    function calculatePercentage(value1, value2) {
        if ((value1 === null || value1 === 0) && (value2 === null || value2 === 0)) {
            return '-'
        }
        if (value2 === 0) {
            return '100 %'
        }
        console.log(value1, value2)
        return parseInt(Math.abs(((value1 - value2) / value2) * 100)) + '%'
    }
    const getValueByMonthly = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let final_result = {};

        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: dpnames.map((i) => ({
                    ...refobj,
                    remarks: null,
                    category: null,
                    form_type: 1,
                    current: "",
                    percentage: "",
                    text: "Pending Submission",
                    col: getNumberOfColumn(obj),
                    status: null,
                })),
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });


            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });

                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    dpnames.forEach((dpname) => {
                        let dp_index = qn_submit_list[index].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index !== -1) {
                            value = (qn_submit_list[index].response[dp_index].value !== undefined && qn_submit_list[index].response[dp_index].value !== null) ? parseFloat(
                                qn_submit_list[index].response[dp_index].value
                            ) : 0
                        } else {
                            value = 0
                        }
                        let dp_index2 = qn_submit_list[index2].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index2 !== -1) {
                            value2 = (qn_submit_list[index2].response[dp_index2].value !== undefined && qn_submit_list[index2].response[dp_index2].value !== null) ? parseFloat(
                                qn_submit_list[index2].response[dp_index2].value
                            ) : 0
                        } else {
                            value = 0
                        }

                        result_arr.push({
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            category: 2,
                            form_type: 1,
                            diff: value === value2 ? null : value > value2 ? false : true,
                            current: value,
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: calculatePercentage(value, value2),
                            type,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Requires Resubmission"
                                        : type === 1 && reject === 1
                                            ? "Requires Re-Review"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submssion",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 1
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        });
                    });
                    result = { [obj]: attachObj(result_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];

                    let value = null;
                    result_arr = [];

                    dpnames.forEach((dpname) => {
                        let dp_index = qn_submit_list[index].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index !== -1) {
                            value = (qn_submit_list[index].response[dp_index].value !== undefined && qn_submit_list[index].response[dp_index].value !== null) ? parseFloat(
                                qn_submit_list[index].response[dp_index].value
                            ) : '-'
                        } else {
                            value = '-'
                        }
                        result_arr.push({
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            category: 1,
                            form_type: 1,
                            diff: false,
                            current: value,
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Requires Resubmission"
                                        : type === 1 && reject === 1
                                            ? "Requires Re-Review"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 1
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        });
                    });
                    result = { [obj]: attachObj(result_arr, refobj) };
                }
            }
            final_result = { ...final_result, ...result };
        });
        console.log(final_result, arr);
        return final_result;
    };
    const reviewAction = async (dcfId, _id, index1, index2, year, rp_name) => {

        let ref = JSON.parse(JSON.stringify(pastdata))


        let dt = DateTime.utc()

        setTempLoad(true)
        Swal.fire({
            title: "Review  " + rp_name,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Quick Review",
            confirmButtonColor: '#5B8FF7',
            denyButtonText: `Check & Review`,
            denyButtonColor: 'green'
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const { value: accept } = await Swal.fire({

                    html: `<div >Do you wish to review this without opening the detailed submission ?</div>`,

                    confirmButtonColor: 'green',
                    showCancelButton: true,

                    confirmButtonText:
                        'Yes, Review',

                })

                if (accept) {
                    let newObj = { type: 2, reject: 0, reviewer_modified_on: DateTime.utc(), reviewer_modified_by: login_data.id, last_modified_on: DateTime.utc(), last_modified_by: login_data.id }
                    APIServices.patch(API.QN_Submission_Edit(_id), newObj).then(async (res) => {
                        try {
                            Object.values(ref[index1].dp_array[index2]).forEach(i => {
                                for (let j = 0; j < i.length; i++) {
                                    i[0].status = 4
                                    i[0].type = 2

                                }
                            }
                            )


                            setPastData(ref)

                            setTempLoad(false)
                        } catch (e) {
                            console.log(e)
                            setTempLoad(true)
                        }
                    }).catch((e) => {
                        setTempLoad(false)
                    })
                } else {
                    setTempLoad(false)
                }

            } else if (result.isDenied) {
                setTempLoad(false)
                window.open(
                    window.location
                        .origin +
                    "/data_input_reviewer/" +
                    dcfId +
                    "/" +
                    _id
                );
            } else {
                setTempLoad(false)
            }
        });


    }
    const recallReview = async (dcfId, _id, index1, index2, year) => {
        let ref = JSON.parse(JSON.stringify(pastdata))


        setTempLoad(true)
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Review</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this review</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Revoke submission',

        })
        if (accept) {
            let newObj = { type: 1, last_modified_on: DateTime.utc(), last_modified_by: login_data.id }
            APIServices.patch(API.QN_Submission_Edit(_id), newObj).then(async (res) => {
                try {
                    Object.values(ref[index1].dp_array[index2]).forEach(i => {
                        for (let j = 0; j < i.length; i++) {
                            i[0].status = 3
                            i[0].type = 1

                        }
                    }
                    )


                    setPastData(ref)
                    forceUpdate()
                    setTempLoad(false)
                } catch (e) {
                    console.log(e)
                    setTempLoad(true)
                }
            }).catch((e) => {
                setTempLoad(false)
            })
        } else {
            setTempLoad(false)
        }

    }
    const recall = async (dcfId, _id, index1, index2, year) => {
        let ref = JSON.parse(JSON.stringify(pastdata))


        setTempLoad(true)

        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Review</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this review</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Revoke submission',

        })
        if (accept) {
            let newObj = { type: 0, last_modified_on: DateTime.utc(), last_modified_by: login_data.id }
            APIServices.patch(API.QN_Submission_Edit(_id), newObj).then(async (res) => {
                try {
                    Object.values(ref[index1].dp_array[index2]).forEach(i => {
                        for (let j = 0; j < i.length; i++) {
                            i[0].status = 0
                            i[0].type = 0

                        }
                    }
                    )
                    setPastData(ref);
                    forceUpdate()
                    setTempLoad(false)

                } catch (e) {
                    setTempLoad(true)
                }
            }).catch((e) => {
                setTempLoad(false)
            })
        } else {
            setTempLoad(false)
        }

    }
    const getDisplayStatus = (rp) => {
        const [startMonth, endMonth] = rp.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');

        const endOfMonth = DateTime.fromObject({
            year: parseInt(year),
            month: DateTime.fromFormat(monthValue, 'LLL').month
        }).endOf('month');

        const currentDate = DateTime.local();

        // If the end month is before the current month → true
        if (endOfMonth < currentDate.startOf('month')) return true;

        // If the end month is the same as the current month → check if current date is >= 15
        if (endOfMonth.hasSame(currentDate, 'month')) return currentDate.day >= 15;

        return false;

    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }

    function getMinMaxDates(dateStrings) {
        // Parse date strings and sort them
        const dates = dateStrings.map(dateStr => DateTime.fromFormat(dateStr, 'MM-yyyy')).sort((a, b) => a - b);

        // Get min and max dates
        const minDate = dates[0].startOf('month').toJSDate();
        const maxDate = dates[dates.length - 1].endOf('month').toJSDate();

        return {
            minDate,
            maxDate
        };
    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const getEfCategoryList = (stdid, catid) => {
        let data = efcatass.filter((i) => { return i.efStandardId === stdid && i.efCategoryId === catid })
        if (data.length) {
            return (data[0].selected_ids === undefined || data[0].selected_ids === null) ? [] : data[0].selected_ids
        } else {
            return []
        }
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <Calendar placeholder={item.placeholder} className="col-5 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputNumber min={0} placeholder={item.placeholder} maxFractionDigits={(item.fraction === undefined || item.fraction) ? 0 : item.fraction < 0 ? 0 : item.fraction} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-18 fw-5 text-justify fw-7" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>} </label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}

                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <Editor className="text-area col-5" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >
                        <div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF,.xls,.xlsx,.ppt,.doc,.docx,.pptx" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>
                        {item.value != null && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()
            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }
    function checkEditorValue(htmlString) {
        if (!htmlString) {
            return true
        }
        const regex = /^<p>\s*<\/p>$/;
        return regex.test(htmlString);
    }
    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && item.value !== null && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined && item.value !== null) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value !== null && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            } else if (item.type === 'file') {
                item['value'] = []
            }


        })


        return data_
    }

    const saveDCF = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse()) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 1
            if (data.data1) {
                newObj['response2'] = getStructuredResponse(data.data1)
            }
            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.self === true ? 2 : 1
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['standard'] = std
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id

            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true
            })



            if (params.state.id === undefined && isConfirmed) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 1, created_on: dt, formId: data.id, remarks: return_remarks }]
                if (typeof return_remarks === 'string' && return_remarks.trim()) {
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]

                }

                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {


                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === login_data.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {



                        }
                    }
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const checkResponse_ = () => {
        console.log(data)
        if (data.data1.length === 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Data set is Empty, requires minimum 1 record to submit/draft",
                showConfirmButton: false,
                timer: 1500,
            });
            return false
        } else {

            return true
        }
    }
    const checkResponse_263 = () => {
        let numbers = []
        data.data1.forEach((i) => {
            i.type2.forEach((j) => {
                j.data.forEach((l) => {
                    numbers.push(Object.values(l)[0])
                })

            })
        })
        console.log(numbers, data.data1)
        console.log(numbers.filter((i) => { return i === null }).length === 0)
        return numbers.filter((i) => { return i === null }).length === 0
    }
    const saveDCF_275 = async () => {
        let newObj = {}
        let dt = DateTime.utc()

        if (checkResponse_275()) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 3
            if (Number(id) === 295) {

                newObj['response2'] = structuredResponse.map((x) => ({ ...x, userProfileId: admin_data.id, userId: login_data.id }))
            }
            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.self === true ? 2 : 1
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std

            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true
            })


            if (params.state.id === undefined && isConfirmed) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 1, created_on: dt, formId: data.id, remarks: return_remarks }]
                if (typeof return_remarks === 'string' && return_remarks.trim()) {
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]
                }

                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {

                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === login_data.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {


                        }
                    }
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }
        }
        forceUpdate()

    }
    const checkResponse_275 = () => {
        let val = Object.keys(data.data1[0]).length
        console.log(val)
        return Object.values(data.data1[0]).filter(i => (i >= 0 && i !== null)).length === val
    }
    const draftDCF_HC_275 = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (true) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 3
            if (Number(id) === 295) {

                newObj['response2'] = structuredResponse.map((x) => ({ ...x, userProfileId: admin_data.id, userId: login_data.id }))
            }
            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['standard'] = std
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 0, created_on: dt, formId: data.id }]
                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const saveDCF2 = async () => {
        let newObj = {}
        let dt = DateTime.utc()



        if (submisisonStatus) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 3
            if (Number(id) === 263) {

                newObj['response2'] = structuredResponse.map((x) => ({ ...x, userProfileId: admin_data.id, userId: login_data.id }))
            }
            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.self === true ? 2 : 1
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std

            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true

            })


            if (params.state.id === undefined && isConfirmed) {
                if (typeof return_remarks === 'string' && return_remarks.trim()) {
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]
                }
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 1, created_on: dt, formId: data.id, remarks: return_remarks }]


                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {

                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === login_data.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {


                        }
                    }
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const draftDCF2 = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (submisisonStatus) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 3
            if (Number(id) === 263) {

                newObj['response2'] = structuredResponse.map((x) => ({ ...x, userProfileId: admin_data.id, userId: login_data.id }))
            }
            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std
            if (params.state.id === undefined) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 0, created_on: dt, formId: data.id }]
                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const saveDCF_263 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_263()) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 3

            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.self === true ? 2 : 1
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['standard'] = std
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id

            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true
            })


            if (params.state.id === undefined && isConfirmed) {

                if (typeof return_remarks === 'string' && return_remarks.trim()) {
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]
                }
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 1, created_on: dt, formId: data.id, remarks: return_remarks }]
                APIServices.post(API.QN_Submit_UP(login_data.clientId), newObj).then((res) => {

                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === login_data.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {


                        }
                    }
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const draftDCF_HC_263 = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_263()) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 3

            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['standard'] = std
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 0, created_on: dt, formId: data.id }]
                APIServices.post(API.QN_Submit_UP(login_data.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const checkResponse_262 = () => {
        let numbers = []
        data.data1.forEach((i) => {
            i.type2.forEach((j) => {
                j.type3.forEach((k) => {
                    k.data.forEach((l) => {
                        numbers.push(Object.values(l)[0])
                    })
                })
            })
        })
        console.log(numbers, data.data1)
        console.log(numbers.filter((i) => { return i === null }).length === 0)
        return numbers.filter((i) => { return i === null }).length === 0
    }
    const saveDCF_262 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_262()) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 3

            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.self === true ? 2 : 1
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std

            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true
            })


            if (params.state.id === undefined && isConfirmed) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 1, created_on: dt, formId: data.id, remarks: return_remarks }]
                if (typeof return_remarks === 'string' && return_remarks.trim()) {
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]
                }

                APIServices.post(API.QN_Submit_UP(login_data.clientId), newObj).then((res) => {

                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === login_data.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {


                        }
                    }
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const draftDCF_HC_262 = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_262()) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 3

            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 0, created_on: dt, formId: data.id }]
                APIServices.post(API.QN_Submit_UP(login_data.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const saveDCF_ = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_()) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 2
            if (Number(id) === 10 || Number(id) === 11 || Number(id) === 257 || Number(id) === 246 || Number(id) === 247 || Number(id) === 310 || Number(id) === 311 || Number(id) === 316 || Number(id) === 15 || Number(id) === 387) {

                newObj['response2'] = structuredResponse.map((x) => ({ ...x, userProfileId: admin_data.id, userId: login_data.id }))
            }
            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.self === true ? 2 : 1
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std

            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true

            })


            if (params.state.id === undefined && isConfirmed) {
                if (typeof return_remarks === 'string' && return_remarks.trim()) {
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]
                }
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 1, created_on: dt, formId: data.id, remarks: return_remarks }]


                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {

                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === login_data.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {


                        }
                    }
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const getStructuredResponse = (daTa) => {
        let loc = JSON.parse(JSON.stringify(daTa)).filter(x => x.type !== 'paragraph' && x.name)
        let result = []
        for (const element of loc) {
            if (element.type === 'radio-group' || element.type === 'checkbox-group') {
                result.push({
                    maskId: element.name, uniqueId: element.name, uom: element.dpunit, userId: login_data.id, userProfileId: admin_data.id, isManualForm: true, valueType: 'array', value: element?.values?.filter(x => x.selected).map(x => x.label), title: element.label, label: element.label?.replace(/(<([^>]+)>)/gi, "")
                        ?.replace(/\n/g, " ")
                        ?.replace(/&nbsp;/g, " ")
                        ?.replace("&amp;", "&") || null, formType: 1, dataType: 1
                })
            } else {
                result.push({
                    maskId: element.name, uniqueId: element.name, uom: element.dpunit, userId: login_data.id, userProfileId: admin_data.id, isManualForm: true, valueType: element.value === null ? null : typeof element.value, value: element?.value, title: element.label, label: element.label?.replace(/(<([^>]+)>)/gi, "")
                        ?.replace(/\n/g, " ")
                        ?.replace(/&nbsp;/g, " ")
                        ?.replace("&amp;", "&") || null, formType: 1, dataType: 1
                })

            }
        }
        return result
    }
    const draftDCF = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (true) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 1
            if (data.data1) {
                newObj['response2'] = getStructuredResponse(data.data1)
            }
            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 0, created_on: dt, formId: data.id }]
                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const draftDCF_HC = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_()) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = data.data1
            newObj['self'] = params.state.self
            newObj['form_type'] = 2
            if (Number(id) === 10 || Number(id) === 11 || Number(id) === 257 || Number(id) === 246 || Number(id) === 247 || Number(id) === 310 || Number(id) === 311 || Number(id) === 316 || Number(id) === 15 || Number(id) === 387) {
                newObj['response2'] = structuredResponse.map((x) => ({ ...x, userProfileId: admin_data.id, userId: login_data.id }))
            }

            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 0, created_on: dt, formId: data.id }]
                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }

        }
        forceUpdate()

    }
    const emptyDCF = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        let { value: return_remarks } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Please confirm that you have no submission to make for this reporting period. Please enter comments, if any. </div>`,
            input: 'textarea',
            inputValue: '',
            confirmButtonText: 'Yes, I have nothing to report',
            cancelButtonText: 'No, open the form again',
            allowOutsideClick: false,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value.trim()) {
                    return 'remarks mandatory'
                }
            }
        })

        if (return_remarks) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            let dt = DateTime.utc()
            newObj['response2'] = [{
                userId: login_data.id, userProfileId: admin_data.id, dcfId: Number(id), isManualForm: true, title: "No submission for this reporting period", label: "No submission for this reporting period", value: '-', formType: 1, isNull: true, uom: '-'

            }]
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = []
            newObj['self'] = params.state.self
            newObj['form_type'] = 1

            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.self === true ? 2 : 1
            newObj['edit'] = 1
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            newObj['standard'] = std

            if (params.state.id === undefined) {


                newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]

                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 999, created_on: dt, formId: data.id, remarks: return_remarks }]
                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {

                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === login_data.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {


                        }
                    }
                    Swal.fire({
                        title: "Data submitted as null report",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }
        }
    }
    const emptyDCF_ = async () => {
        let newObj = {}
        let { value: return_remarks } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Please confirm that you have no submission to make for this reporting period. Please enter comments, if any. </div>`,
            input: 'textarea',
            inputValue: '',
            allowOutsideClick: false,
            confirmButtonText: 'Yes, I have nothing to report',
            cancelButtonText: 'No, open the form again',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value.trim()) {
                    return 'remarks mandatory'
                }
            }
        })
        if (return_remarks) {
            if (document) {
                newObj['documents'] = document
            } else {
                newObj['documents'] = null
            }
            let dt = DateTime.utc()
            if (Number(id) === 10 || Number(id) === 11 || Number(id) === 257 || Number(id) === 246 || Number(id) === 247 || Number(id) === 310 || Number(id) === 311 || Number(id) === 316 || Number(id) === 15 || Number(id) === 387) {

                newObj['response2'] = [{
                    userId: login_data.id, userProfileId: admin_data.id, dcfId: Number(id), title: "No submission for this reporting period", label: "No submission for this reporting period", value: '-', formType: 2, isNull: true, uom: '-'

                }]
            }
            newObj['dcfId'] = data.id
            newObj['locationId'] = params.state.locationId
            newObj[`tier${params.state.level}_id`] = params.state.locationId
            newObj['level'] = params.state.level
            newObj['reporting_period'] = getRPLuxon(params.state.reporting_period)
            newObj['response'] = []
            newObj['self'] = params.state.self
            newObj['form_type'] = 2

            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.self === true ? 2 : 1
            newObj['edit'] = 1
            newObj['entityUserAssId'] = params.state.entityUserAssId
            newObj['entityAssId'] = params.state.entityAssId
            newObj['frequency'] = params.state.frequency
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = dt
            newObj['standard'] = std
            newObj['last_modified_on'] = dt
            newObj['reporter_modified_on'] = dt
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id


            if (params.state.id === undefined) {

                newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: dt }]

                newObj['logs'] = [{ user_id: login_data.id, user_type: 1, type: 1, category: 999, created_on: dt, formId: data.id, remarks: return_remarks }]

                APIServices.post(API.QN_Submit_Custom_UP(login_data.clientId), newObj).then((res) => {

                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === login_data.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {


                        }
                    }
                    Swal.fire({
                        title: "Data submitted as null report",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            localStorage.setItem("reloadaio", "true");
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {

                            window.close()
                        }
                    })

                })
            }
        }
    }
    const getUser = (id) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }

    const checkHardcoded = () => {
        if (hardcoded.dcf.includes(id) || hardcoded.dcf2.includes(id)) {
            return true
        } else {
            return false
        }

    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = ''

        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return text
    }
    const closeForm = async () => {
        Swal.fire({
            title: "Are you sure you want to close this screen? Any unsaved changes will be lost.",
            showDenyButton: true,
            confirmButtonText: "Close Form",
            confirmButtonColor: '#FA8072',
            denyButtonText: `No stay here`,
            denyButtonColor: '#9FE2BF'
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                window.close()
            } else {
                Swal.close()
            }
        })

    }


    const importFromExcelForManualForm = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[1];
                const ws = wb.Sheets['Data Entry'];
                // Define the specific range to extract (C3:C40)
                const range = XLSX.utils.decode_range('C3:C94');

                const values = [];

                for (let row = range.s.r; row <= range.e.r; row++) {
                    // Get cell reference for Column C (index 2 = C)
                    const cellRef = XLSX.utils.encode_cell({ r: row, c: 2 });
                    const cell = ws[cellRef];
                    values.push(cell ? cell.v : 0); // Push cell value or null if empty
                }

                let jsonData = values.filter(x => x !== 'nos' && x >= 0)
                console.log(jsonData.length)
                const loc = [...data?.data1 || []]
                if (jsonData.length === 82) {
                    let count = 0
                    for (const item of loc) {
                        if (item.type === 'number') {

                            item.value = jsonData[count]
                            count++
                        }

                    }
                    setData((prev) => ({ ...prev, data1: loc }))
                }

            };

            reader.readAsArrayBuffer(file);
        });

        e.options.clear();
    };

    return (
        <div className="col-12 font-lato" style={{ margin: 10 }}>
            <StickyContainer>
                <div className="col-12">
                    {(login_data.id !== undefined && data.length !== 0) ?
                        <div >
                            <Sticky topOffset={0}>
                                {({ style, isSticky }) => (<div style={{ ...style, top: 98, background: isSticky && 'white', zIndex: 100 }}>
                                    <div className="fs-20 col-12 grid m-0  fw-4  clr-gray-900 flex align-items-center" style={{ padding: isSticky ? 15 : 0 }}>
                                        <div className="col-11 text-justify" >Reporting of data pertaining to <span className="fw-7 clr-navy">{data.title}</span>  &nbsp; <small style={{ color: 'grey' }}>(DCF {data.id})</small>   </div>
                                        <div className="col-1 flex align-items-center justify-content-end" > <i onClick={() => { closeForm() }} className="pi pi-times close-icon cur-pointer" /> </div>
                                    </div>
                                    {/* paragraph="" */}
                                    {!checkHardcoded() ?
                                        <div className="readmore" hidden={isSticky ? true : false}> To enter <strong>{getCoverageText(params.state, sitelist)}</strong> data for the period <strong>{params.state.reporting_period}</strong>, open the tab below and kindly fill all necessary fields.When finished, click "Save and Submit" to complete, or "Save as Draft" to continue updating later.
                                            <br />  <br /> If there is no data that is reportable for this particular time period, press the "No Update" button, enter your comments and submit your response.
                                            <br />  <br />  You can view previously submitted data in the yearly "Submission Status" section at the bottom of this screen and access other submission screens directly from there. </div>
                                        :

                                        <div className="readmore" hidden={isSticky ? true : false}> To enter <strong>{getCoverageText(params.state, sitelist)}</strong> data for the period <strong>{params.state.reporting_period}</strong>, open the tab below and click "Add" to begin.
                                            <br /><br />
                                            For bulk uploads, download the Excel template, fill it out, and upload using the “Import” button to populate all required fields. After importing, you can attach any supporting evidence. When finished, click "Save and Submit" to complete, or "Save as Draft" to continue updating later.
                                            <br />  <br /> If there is no data that is reportable for this particular time period, press the "No Update" button, enter your comments and submit your response.

                                            <br />  <br />  You can view previously submitted data in the yearly "Submission Status" section at the bottom of this screen and access other submission screens directly from there. </div>
                                    }


                                    {/* <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }} >
                                        <div className="grid justify-content-between col-12">
                                            <div className="col-4 fs-16" style={{ flexDirection: 'row' }}>         <span className="clr-gray-3 fw-4">Reporting period:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.reporting_period}   </span>  </div>
                                            <div className="col-5 fs-16 " style={{ flexDirection: 'row', textAlign: 'end' }}>          <span className="clr-gray-3 fw-4">Coverage:&nbsp;</span> <span className="clr-gray-900 fw-7"> {getCoverageText(params.state, sitelist)} </span>  </div>

                                          
                                        </div>

                                    </div> */}
                                </div>)}
                            </Sticky>
                            <div className="bg-white" style={{ borderRadius: 4 }}>
                                {data.comments && <ReadMoreComponent content={data.comments} />}
                                {(data.data1.length !== 0 && !checkHardcoded()) ?
                                    <div>


                                        <div className="p-2">
                                            <Accordion  >
                                                <AccordionTab headerClassName="currentrp"
                                                    header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                    {data?.id === 363 && <div
                                                        className="col-12"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <div className="col-2">
                                                            <div
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (e.target.tagName === "SPAN") {
                                                                        DCF363Template(data?.id);
                                                                    }
                                                                }}
                                                            >
                                                                <FileUpload
                                                                    chooseOptions={{
                                                                        label: "Template",
                                                                        icon: "pi pi-download",
                                                                        className: "navy-button",
                                                                    }}
                                                                    mode="basic"
                                                                    name="demo[]"
                                                                    auto
                                                                    customUpload
                                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                    className="mr-2"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <FileUpload
                                                                chooseOptions={{
                                                                    label: "Import",
                                                                    icon: "pi pi-file-excel",
                                                                    className: "navy-button",
                                                                }}
                                                                mode="basic"
                                                                name="demo[]"
                                                                auto
                                                                customUpload
                                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                className="mr-2"
                                                                uploadHandler={(e) => {
                                                                    importFromExcelForManualForm(e, data.id);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>}

                                                    {data.data1.map((item, index) => {

                                                        return renderItems(item, index)
                                                    })

                                                    }
                                                    <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                                    <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                        {getDisplayStatus(params.state.reporting_period) && <Button label='No Update' className="ml-4" outlined onClick={() => { emptyDCF() }} ></Button>}
                                                        <Button label='Save as Draft' onClick={() => { draftDCF() }} className={"ml-4"}  ></Button>
                                                        {getDisplayStatus(params.state.reporting_period) && <Button label='Save and Submit' onClick={() => { saveDCF() }} className="ml-4" ></Button>}

                                                    </div>
                                                </AccordionTab>
                                            </Accordion>

                                        </div>
                                        {pastdata.length !== 0 && <div className="p-2">

                                            <BlockUI blocked={tempload}>

                                                <Accordion  >
                                                    {pastdata.map((item, index) => {
                                                        console.log(item)
                                                        return (
                                                            <AccordionTab
                                                                header={`Submission Status for ${fymonth === 1 ? item.year : (item.year - 1) + '-' + item.year.toString().slice(2)} - ` + getCoverageText(params.state, sitelist)} >
                                                                <div>
                                                                    <div
                                                                        className="col-12 flex "
                                                                        style={{ flexDirection: "row" }}
                                                                    >
                                                                        <div className="col-3 p-0">
                                                                            <div
                                                                                className="p-card m-1 p-1  flex "
                                                                                style={{
                                                                                    flexDirection: "column",
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                <div className='flex col-12 p-0 m-1' style={{ flexDirection: 'row', height: item.form_type !== 3 ? 30 : 25, borderBottom: '1px solid gray' }}>

                                                                                    <div className="fw-7 fs-14 flex col-6 p-0 m-1 " >
                                                                                        Datapoint(s)
                                                                                    </div>
                                                                                    <hr
                                                                                        style={{
                                                                                            borderWidth: 2,
                                                                                            margin: 1,
                                                                                        }}
                                                                                    />
                                                                                    <div className="fw-7 fs-14 flex col-6 p-0 m-1 " >
                                                                                        Unit of Measure
                                                                                    </div>
                                                                                </div>
                                                                                {item.dp_array.map((dp, index2) => {
                                                                                    console.log(index2);
                                                                                    if (index2 === 0) {
                                                                                        {
                                                                                            return dp.name.map((x, index3) => {
                                                                                                console.log(x)
                                                                                                return (
                                                                                                    <div>
                                                                                                        <div className=" justify-content-center">
                                                                                                            <div className="col-12 flex p-0 m-1" style={{ flexDirection: 'row', height: 30 }}>
                                                                                                                <div
                                                                                                                    className="col-6 fw-4 fs-14 p-0 text-three-dot block m-1 align-items-center cur-pointer"
                                                                                                                    onClick={(e) => { dpnamerefs[index3].current.toggle(e) }}
                                                                                                                >

                                                                                                                    {item.form_type === 2 ? x.label.split('^')[0] : x.label}
                                                                                                                </div>
                                                                                                                <hr
                                                                                                                    style={{
                                                                                                                        borderWidth: 1,
                                                                                                                        margin: 1,
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div
                                                                                                                    className="col-6 fw-4 fs-14 p-0 block m-1 text-three-dot align-items-center"

                                                                                                                >

                                                                                                                    {item.form_type === 2 ? x.label.split('^')[1] : x.unit}
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <OverlayPanel key={index} ref={(ref) => { dpnamerefs[index3].current = ref }}>
                                                                                                                {x.label}
                                                                                                            </OverlayPanel>


                                                                                                            <hr
                                                                                                                style={{
                                                                                                                    borderWidth: 2,
                                                                                                                    margin: 1,
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })}

                                                                                <div style={{ marginTop: 3 }}>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Current Status
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Remarks (if any )
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Action
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Assignee(s)
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-9 p-0">
                                                                            <div
                                                                                style={{
                                                                                    flexDirection: "row",
                                                                                    overflowX: "auto",
                                                                                    display: "-webkit-box", justifyContent: 'space-between'
                                                                                }}
                                                                            >
                                                                                {item.dp_array.map((dp, index2) => {
                                                                                    console.log(dp)
                                                                                    if (index2 !== 0) {
                                                                                        {
                                                                                            return Object.entries(dp).map((x) => {
                                                                                                console.log(x, item.dcfId);
                                                                                                return (
                                                                                                    <div
                                                                                                        className="p-card m-1 p-1  flex"
                                                                                                        style={{
                                                                                                            flexDirection: "column",
                                                                                                            width: 200,
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className="status-tag-gray flex justify-content-center align-items-center p-0 m-1" style={{ height: 30, fontSize: 14, fontWeight: 700 }}>
                                                                                                            {x[0]}
                                                                                                        </div>
                                                                                                        {x[1].map((z) => {
                                                                                                            console.log(z)
                                                                                                            if ((z.form_type === 1 || z.form_type === 2) && (z.dp !== undefined ? z.dp !== null : true)) {


                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div className="">
                                                                                                                            <div
                                                                                                                                className="fw-5 fs-14 flex m-1 justify-content-center align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                            >
                                                                                                                                {z.current}
                                                                                                                                {z.category === 2 && z.diff !== null &&
                                                                                                                                    <label className="ml-2 flex">
                                                                                                                                        (
                                                                                                                                        <span className="flex align-items-center    " style={{ marginLeft: 1, marginRight: 1 }}>
                                                                                                                                            {z.diff ?
                                                                                                                                                <>
                                                                                                                                                    <small style={{ color: 'green' }}>{z.percentage}</small>
                                                                                                                                                    <i
                                                                                                                                                        className="material-icons"
                                                                                                                                                        style={{
                                                                                                                                                            color: z.current
                                                                                                                                                                ? "green"
                                                                                                                                                                : "white",
                                                                                                                                                            fontSize: 14,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        arrow_downward
                                                                                                                                                    </i>
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <small style={{ color: 'red' }}>{z.percentage}</small>
                                                                                                                                                    <i
                                                                                                                                                        className="material-icons"
                                                                                                                                                        style={{
                                                                                                                                                            color: z.current
                                                                                                                                                                ? "red"
                                                                                                                                                                : "white",
                                                                                                                                                            fontSize: 14,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        arrow_upward
                                                                                                                                                    </i>
                                                                                                                                                </>
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                        )
                                                                                                                                    </label>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <hr
                                                                                                                            style={{
                                                                                                                                borderWidth: 2,
                                                                                                                                margin: 1,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                );
                                                                                                            }
                                                                                                        })}

                                                                                                        {x[1].length !== 0 && x[1][0].id !== undefined
                                                                                                            ? (
                                                                                                                <div style={{ marginTop: 3 }}>
                                                                                                                    <div className="flex justify-content-center" style={{ height: 30 }}>
                                                                                                                        <Tag
                                                                                                                            className={((x[1][0].status === null || x[1][0].status === 0) ? 'status-tag-red'
                                                                                                                                : x[1][0].status === 1 ? 'status-tag-orange'
                                                                                                                                    : (x[1][0].status === 2 || x[1][0].status === 3) ? 'status-tag-blue'
                                                                                                                                        : x[1][0].status === 4 ? "status-tag-yellow" : "status-tag-green") + ' flex align-items-center'
                                                                                                                            } >

                                                                                                                            {x[1][0].status === null ? 'Pending Submissions' : x[1][0].status === 0 ? 'Drafted' : x[1][0].status === 1 ? 'Requires Resubmissions' : x[1][0].status === 2 ? 'Requires Re-review' : x[1][0].status === 3 ? 'Pending Review' : x[1][0].status === 4 ? 'Pending Approval' : 'Approved'}
                                                                                                                        </Tag>
                                                                                                                    </div>
                                                                                                                    {(x[1][0].remarks !== null) ?
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                className="cur-pointer text-underline flex align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                                onClick={() => {
                                                                                                                                    setRemarksData(x[1][0].remarks)
                                                                                                                                    setRemarksDialog(true)
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                View
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                style={{ height: 30 }}
                                                                                                                            >

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    }
                                                                                                                    {(x[1][0].status === null || x[1][0].status === 0 || x[1][0].status === 1 || x[1][0].status === 2 || x[1][0].status === 3 || x[1][0].status === 4 || x[1][0].status === 5) ?
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                className=" fw-5 fs-16 col-12 clr-navy flex justify-content-center align-items-center"
                                                                                                                                style={{ height: 30 }}

                                                                                                                            >
                                                                                                                                {x[0] !== params.state.reporting_period && <>
                                                                                                                                    <div className="cur-pointer p-2 text-underline" onClick={() => {
                                                                                                                                        window.open(
                                                                                                                                            window.location
                                                                                                                                                .origin +
                                                                                                                                            "/data_input_view/" +
                                                                                                                                            item.dcfId +
                                                                                                                                            "/" +
                                                                                                                                            x[1][0].id
                                                                                                                                        );
                                                                                                                                    }}> View   </div>    <div className="cur-pointer p-2 text-underline" onClick={() => {
                                                                                                                                        if (x[1][0].status !== 5) {

                                                                                                                                            if ((x[1][0].status === null && x[1][0].refobj.reporter_ids.includes(login_data.id)) || (x[1][0].status === 0 && x[1][0].refobj.reporter_ids.includes(login_data.id)) || (x[1][0].status === 1 && x[1][0].refobj.reporter_ids.includes(login_data.id))) {
                                                                                                                                                window.open(
                                                                                                                                                    window.location
                                                                                                                                                        .origin +
                                                                                                                                                    "/data_input_past/" +
                                                                                                                                                    item.dcfId +
                                                                                                                                                    "/" +
                                                                                                                                                    x[1][0].id
                                                                                                                                                );
                                                                                                                                            } else if (x[1][0].refobj.reporter_ids.includes(login_data.id) && (x[1][0].status === 3 || (x[1][0].status === 4 && !x[1][0].refobj.reviewer_ids.length))) {
                                                                                                                                                recall(item.dcfId, x[1][0].id, index, index2, item.year)

                                                                                                                                            } else if (x[1][0].status === 3 && x[1][0].refobj.reviewer_ids.includes(login_data.id)) {
                                                                                                                                                reviewAction(item.dcfId, x[1][0].id, index, index2, item.year, x[0])

                                                                                                                                            } else if (x[1][0].status === 4 && x[1][0].refobj.reviewer_ids.includes(login_data.id)) {

                                                                                                                                                recallReview(item.dcfId, x[1][0].id, index, index2, item.year)
                                                                                                                                            }

                                                                                                                                        }

                                                                                                                                    }}>  {(x[1][0].status === null && x[1][0].refobj.reporter_ids.includes(login_data.id)) ? 'Open Form' : (x[1][0].status === 0 && x[1][0].refobj.reporter_ids.includes(login_data.id)) ? 'Update Form' : (x[1][0].refobj.reporter_ids.includes(login_data.id) && !x[1][0].refobj.reviewer_ids.includes(login_data.id) && (x[1][0].status === 3 || (x[1][0].status === 4 && !x[1][0].refobj.reviewer_ids.length))) ? 'Recall' : (x[1][0].status === 1 && x[1][0].refobj.reporter_ids.includes(login_data.id)) ? 'Revise Form' : (x[1][0].status === 2 && x[1][0].refobj.reviewer_ids.includes(login_data.id)) ? 'Re-Review' : (x[1][0].status === 3 && x[1][0].refobj.reviewer_ids.includes(login_data.id)) ? 'Review' : (x[1][0].status === 4 && x[1][0].refobj.reviewer_ids.includes(login_data.id)) ? 'Recall' : ''}</div>

                                                                                                                                </>}
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        :

                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div

                                                                                                                                style={{ height: 30 }}

                                                                                                                            >

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div class="flex align-items-center"
                                                                                                                            onClick={() => { setAssigneeDialog(true); setAssigneeObj(x[1][0].refobj) }}
                                                                                                                            style={{ height: 30 }}

                                                                                                                        >
                                                                                                                            View Assignees
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            ) :
                                                                                                            (
                                                                                                                <div style={{ marginTop: 3 }}>
                                                                                                                    <div className="flex justify-content-center" style={{ height: 30 }}>
                                                                                                                        <Tag
                                                                                                                            className="status-tag-red"
                                                                                                                        >

                                                                                                                            {'Pending Submission'}
                                                                                                                        </Tag>
                                                                                                                    </div>
                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div

                                                                                                                            style={{ height: 30 }}

                                                                                                                        >

                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        {x[0] !== params.state.reporting_period ? <div className="cur-pointer text-underline fw-5 fs-16 clr-navy flex align-items-center"
                                                                                                                            style={{ height: 30 }}
                                                                                                                            onClick={() => {
                                                                                                                                if (x[1][0].refobj.reporter_ids.includes(login_data.id)) {
                                                                                                                                    sessionStorage.setItem('newer', JSON.stringify({ ...x[1][0], reporting_period: x[0] }))
                                                                                                                                    window.open(
                                                                                                                                        window.location
                                                                                                                                            .origin +
                                                                                                                                        "/data_input_newer/" +
                                                                                                                                        item.dcfId

                                                                                                                                    )
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        >

                                                                                                                            {x[1][0].refobj.reporter_ids.includes(login_data.id) ? 'Open Form' : 'NA'}


                                                                                                                        </div> :
                                                                                                                            <div

                                                                                                                                style={{ height: 30 }}

                                                                                                                            >

                                                                                                                            </div>

                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div class="flex align-items-center"
                                                                                                                            onClick={() => { setAssigneeDialog(true); setAssigneeObj(x[1][0].refobj) }}
                                                                                                                            style={{ height: 30 }}

                                                                                                                        >
                                                                                                                            View Assignees
                                                                                                                        </div>
                                                                                                                    </div>



                                                                                                                </div>
                                                                                                            )


                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </AccordionTab>
                                                        )
                                                    })

                                                    }
                                                </Accordion>



                                            </BlockUI>


                                        </div>}



                                    </div>
                                    :
                                    <div >
                                        {hardcoded.dcf.includes(id) ?
                                            <div >


                                                <div className="p-2">
                                                    <Accordion  >
                                                        <AccordionTab headerClassName="currentrp"
                                                            header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                            {

                                                                (id === '10') ?
                                                                    <div>
                                                                        <Fugitive_ extractedData={(e) => { setStructuredResponse(e) }} allowedCategories={getEfCategoryList(std, 5)} dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                    </div>

                                                                    :
                                                                    (id === '11') ?
                                                                        <div>
                                                                            <Stationary_Combustion_ extractedData={(e) => { setStructuredResponse(e) }} allowedCategories={getEfCategoryList(std, 1)} dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                            {/* <Scope3_Investments  data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} /> */}

                                                                        </div>
                                                                        :
                                                                        (id === '36') ?
                                                                            <div>
                                                                                < Business_Travel_ dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                            </div>

                                                                            :
                                                                            (id === '15') ?
                                                                                <div>
                                                                                    <Mobile_Combustion_ dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                </div>

                                                                                :
                                                                                (id === '16') ?
                                                                                    <div>
                                                                                        <Purchase_Goods_Services_ allowedCategories={getEfCategoryList(std, 11)} dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                    </div>
                                                                                    : id === '188' ?
                                                                                        <div>
                                                                                            <BP8EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                        </div>
                                                                                        : id === '195' ?
                                                                                            <div>
                                                                                                <BP2LQ3 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                            </div>
                                                                                            : id === '196' ?
                                                                                                <div>
                                                                                                    <BP2LQ5 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                </div>
                                                                                                : id === '245' ?
                                                                                                    <div>
                                                                                                        <GR2LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                    </div>
                                                                                                    : id === '246' ?
                                                                                                        <div>
                                                                                                            {/* water_withdrawl */}
                                                                                                            <GR2LQ2 extractedData={(e) => { setStructuredResponse(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                        </div>
                                                                                                        : id === '247' ?
                                                                                                            <div>
                                                                                                                {/* water_disposal */}
                                                                                                                <GR2LQ3 extractedData={(e) => { setStructuredResponse(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                            </div>
                                                                                                            : id === '254' ?
                                                                                                                <div>
                                                                                                                    <HotelStay dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                </div>
                                                                                                                : id === '257' ?
                                                                                                                    <div>
                                                                                                                        <Electricity extractedData={(e) => { setStructuredResponse(e) }} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                    </div>
                                                                                                                    : id === '282' ?
                                                                                                                        <div>
                                                                                                                            <Capital_Goods allowedCategories={getEfCategoryList(std, 11)} dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                        </div>
                                                                                                                        : id === '283' ?
                                                                                                                            <div>
                                                                                                                                <Scope3_Investments extractedData={(e) => { setStructuredResponse(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                            </div> : id === '292' ?
                                                                                                                                <div>
                                                                                                                                    <Upstream_Trans_Dist standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                </div> :
                                                                                                                                id === '293' ?
                                                                                                                                    <div>
                                                                                                                                        <Downstream_Trans_Dist standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                    </div> :
                                                                                                                                    id === '310' ?
                                                                                                                                        <div>
                                                                                                                                            <Stationary_Combustion_Rotary extractedData={(e) => { setStructuredResponse(e) }} allowedCategories={getEfCategoryList(std, 1)} dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                        </div> :
                                                                                                                                        id === '311' ?
                                                                                                                                            <div>
                                                                                                                                                <Mobile_Combustion_Rotary extractedData={(e) => { setStructuredResponse(e) }} allowedCategories={getEfCategoryList(std, 1)} dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                            </div> :
                                                                                                                                            id === '316' ?
                                                                                                                                                <div>
                                                                                                                                                    <Hazardous_NonHazardous_Rotary extractedData={(e) => { setStructuredResponse(e) }} allowedCategories={getEfCategoryList(std, 1)} dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                                </div> : id === '355' ?
                                                                                                                                                    <div>
                                                                                                                                                        <VehicleInformation data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                                    </div> : id === '356' ?
                                                                                                                                                        <div>
                                                                                                                                                            <VehicleSold data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                                        </div> : id === '357' ?
                                                                                                                                                            <div>
                                                                                                                                                                <DistanceTravelledVehicle data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                                            </div> : id === '358' ?
                                                                                                                                                                <div>
                                                                                                                                                                    <SoldProduct data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                                                </div> : id === '382' ?
                                                                                                                                                                    <div>
                                                                                                                                                                        <Emissions_Due_Downstream_TransportationAndDistribution_SpendBased data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                                                    </div> : id === '383' ?
                                                                                                                                                                        <div>
                                                                                                                                                                            <HazardousWaste_TVS data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                                                        </div> : id === '387' ?
                                                                                                                                                                            <div>
                                                                                                                                                                                <Employee_Commutte_TVS extractedData={(e) => { setStructuredResponse(e) }}   data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                                                                            </div> :

                                                                                                                                                                            'Contact Admin'



                                                            }
                                                            <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />

                                                            <div >
                                                                <div className="flex justify-content-end " style={{ background: 'white', padding: 10 }}>

                                                                    {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" outlined label='No Update' onClick={() => { emptyDCF_() }} ></Button>}

                                                                    <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC() }} ></Button>
                                                                    {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}



                                                                </div>
                                                            </div>
                                                        </AccordionTab></Accordion>

                                                </div>




                                            </div>
                                            : id === '262' ?
                                                <div>
                                                    <div className="p-2">
                                                        <Accordion  >
                                                            <AccordionTab headerClassName="currentrp"
                                                                header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                <Employee_Category data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                                                <div >
                                                                    <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>


                                                                        {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" outlined label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                        <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC_262() }} ></Button>
                                                                        {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_262() }}></Button>}

                                                                    </div>
                                                                </div>
                                                            </AccordionTab>    </Accordion>
                                                    </div>


                                                </div> : id === '263' ?
                                                    <div>
                                                        <div className="p-2">
                                                            <Accordion  >
                                                                <AccordionTab headerClassName="currentrp"
                                                                    header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                    <Employee_Demographics_263 extractedData={(e) => { setStructuredResponse(e) }} isValidResponse={(e) => { setSubmissionStatus(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                    <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                                                    <div >
                                                                        <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                            {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                            <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF2() }} ></Button>
                                                                            {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF2() }}></Button>}

                                                                        </div>
                                                                    </div>
                                                                </AccordionTab></Accordion></div>


                                                    </div> :
                                                    id === '275' ?
                                                        <div>
                                                            <div className="p-2">
                                                                <Accordion  >
                                                                    <AccordionTab headerClassName="currentrp"
                                                                        header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                        <Employee_Category_Diversity_STT isValidResponse={(e) => { setSubmissionStatus(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                        <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />

                                                                        <div >
                                                                            <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                                {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                                <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF2() }} ></Button>
                                                                                {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF2() }}></Button>}

                                                                            </div>
                                                                        </div>
                                                                    </AccordionTab></Accordion>   </div>

                                                        </div>
                                                        :
                                                        id === '277' ?
                                                            <div>
                                                                <div className="p-2">
                                                                    <Accordion  >
                                                                        <AccordionTab headerClassName="currentrp"
                                                                            header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                            <Total_No_of_New_Employee_STT data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                            <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                                                            <div >
                                                                                <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                                    {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                                    <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC_275() }} ></Button>
                                                                                    {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_275() }}></Button>}

                                                                                </div>
                                                                            </div>
                                                                        </AccordionTab>  </Accordion>    </div>

                                                            </div> : id === '278' ?
                                                                <div>
                                                                    <div className="p-2">
                                                                        <Accordion  >
                                                                            <AccordionTab headerClassName="currentrp"
                                                                                header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                                <Total_No_of_Employee_Left_STT data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                                                                <div >
                                                                                    <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                                        {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                                        <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC_275() }} ></Button>
                                                                                        {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_275() }}></Button>}

                                                                                    </div>
                                                                                </div>
                                                                            </AccordionTab></Accordion>    </div>

                                                                </div> : id === '284' ?
                                                                    <div>
                                                                        <div className="p-2">
                                                                            <Accordion  >
                                                                                <AccordionTab headerClassName="currentrp"
                                                                                    header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                                    <Total_No_of_Employee_Hire_TurnOver_STT data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                    <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                                                                    <div>
                                                                                        <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                                            {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                                            <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC_275() }} ></Button>
                                                                                            {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_275() }}></Button>}

                                                                                        </div>
                                                                                    </div>
                                                                                </AccordionTab> </Accordion>
                                                                        </div>

                                                                    </div> : id === '295' ?
                                                                        <div>
                                                                            <div className="p-2">
                                                                                <Accordion  >
                                                                                    <AccordionTab headerClassName="currentrp"
                                                                                        header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                                        <Employee_Training_Hours_STT extractedData={(e) => { setStructuredResponse(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                        <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />
                                                                                        <div >
                                                                                            <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                                                {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                                                <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC_275() }} ></Button>
                                                                                                {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_275() }}></Button>}

                                                                                            </div>
                                                                                        </div>
                                                                                    </AccordionTab>
                                                                                </Accordion>  </div>

                                                                        </div> : id === '359' ?
                                                                            <div>
                                                                                <div className="p-2">
                                                                                    <Accordion  >
                                                                                        <AccordionTab headerClassName="currentrp"
                                                                                            header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                                            <Employee_Diversity_TVS isValidResponse={(e) => { setSubmissionStatus(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                            <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />

                                                                                            <div >
                                                                                                <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                                                    {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                                                    <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF2() }} ></Button>
                                                                                                    {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF2() }}></Button>}

                                                                                                </div>
                                                                                            </div>
                                                                                        </AccordionTab></Accordion>   </div>

                                                                            </div> : id === '384' ?
                                                                                <div>
                                                                                    <div className="p-2">
                                                                                        <Accordion  >
                                                                                            <AccordionTab headerClassName="currentrp"
                                                                                                header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                                                <New_Employee_Hires_TVS isValidResponse={(e) => { setSubmissionStatus(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />

                                                                                                <div >
                                                                                                    <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                                                        {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                                                        <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF2() }} ></Button>
                                                                                                        {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF2() }}></Button>}

                                                                                                    </div>
                                                                                                </div>
                                                                                            </AccordionTab></Accordion>   </div>

                                                                                </div> : id === '385' &&
                                                                                <div>
                                                                                    <div className="p-2">
                                                                                        <Accordion  >
                                                                                            <AccordionTab headerClassName="currentrp"
                                                                                                header={" Data Submission Form (" + getCoverageText(params.state, sitelist) + ', ' + params.state.reporting_period + ')'} >
                                                                                                <New_Employee_Turnover_TVS isValidResponse={(e) => { setSubmissionStatus(e) }} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                <AttachmentComponent mandatory={true} edit={1} getFiles={(e) => { setDocument(e) }} documents={document} labels={'Please attach any evidence or records to support the data input.'} hint={''} />

                                                                                                <div >
                                                                                                    <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                                                                        {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                                                                        <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF2() }} ></Button>
                                                                                                        {getDisplayStatus(params.state.reporting_period) && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF2() }}></Button>}

                                                                                                    </div>
                                                                                                </div>
                                                                                            </AccordionTab></Accordion>   </div>

                                                                                </div>

                                        }
                                        {pastdata.length !== 0 && doneDcf.includes(parseFloat(id)) && <div className="p-2">

                                            <BlockUI blocked={tempload}>

                                                <Accordion  >
                                                    {pastdata.map((item, index) => {
                                                        console.log(item)
                                                        return (
                                                            <AccordionTab
                                                                header={`Submission Status for ${fymonth === 1 ? item.year : (item.year - 1) + '-' + item.year.toString().slice(2)} - ` + getCoverageText(params.state, sitelist)} >
                                                                <div>
                                                                    <div
                                                                        className="col-12 flex "
                                                                        style={{ flexDirection: "row" }}
                                                                    >
                                                                        <div className="col-3 p-0">
                                                                            <div
                                                                                className="p-card m-1 p-1  flex "
                                                                                style={{
                                                                                    flexDirection: "column",
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                <div className='flex col-12 p-0 m-1' style={{ flexDirection: 'row', height: item.form_type !== 3 ? 30 : 25, borderBottom: '1px solid gray' }}>

                                                                                    <div className="fw-7 fs-14 flex col-6 p-0 m-1 " >
                                                                                        Datapoint(s)
                                                                                    </div>
                                                                                    <hr
                                                                                        style={{
                                                                                            borderWidth: 2,
                                                                                            margin: 1,
                                                                                        }}
                                                                                    />
                                                                                    <div className="fw-7 fs-14 flex col-6 p-0 m-1 " >
                                                                                        Unit of Measure
                                                                                    </div>
                                                                                </div>
                                                                                {item.dp_array.map((dp, index2) => {
                                                                                    console.log(index2);
                                                                                    if (index2 === 0) {
                                                                                        {
                                                                                            return dp.name.map((x, index3) => {
                                                                                                console.log(x)
                                                                                                return (
                                                                                                    <div>
                                                                                                        <div className=" justify-content-center">
                                                                                                            <div className="col-12 flex p-0 m-1" style={{ flexDirection: 'row', height: 30 }}>
                                                                                                                <div
                                                                                                                    className="col-6 fw-4 fs-14 p-0 text-three-dot block m-1 align-items-center cur-pointer"
                                                                                                                    onClick={(e) => { dpnamerefs[index3].current.toggle(e) }}
                                                                                                                >

                                                                                                                    {item.form_type === 2 ? x.label.split('^')[0] : x.label}
                                                                                                                </div>
                                                                                                                <hr
                                                                                                                    style={{
                                                                                                                        borderWidth: 1,
                                                                                                                        margin: 1,
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div
                                                                                                                    className="col-6 fw-4 fs-14 p-0 block m-1 text-three-dot align-items-center"

                                                                                                                >

                                                                                                                    {item.form_type === 2 ? x.label.split('^')[1] : x.unit}
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <OverlayPanel key={index} ref={(ref) => { dpnamerefs[index3].current = ref }}>
                                                                                                                {x.label}
                                                                                                            </OverlayPanel>


                                                                                                            <hr
                                                                                                                style={{
                                                                                                                    borderWidth: 2,
                                                                                                                    margin: 1,
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })}

                                                                                <div style={{ marginTop: 3 }}>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Current Status
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Remarks (if any )
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Action
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Assignee(s)
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-9 p-0">
                                                                            <div
                                                                                style={{
                                                                                    flexDirection: "row",
                                                                                    overflowX: "auto",
                                                                                    display: "-webkit-box", justifyContent: 'space-between'
                                                                                }}
                                                                            >
                                                                                {item.dp_array.map((dp, index2) => {
                                                                                    console.log(dp)
                                                                                    if (index2 !== 0) {
                                                                                        {
                                                                                            return Object.entries(dp).map((x) => {
                                                                                                console.log(x, item.dcfId);
                                                                                                return (
                                                                                                    <div
                                                                                                        className="p-card m-1 p-1  flex"
                                                                                                        style={{
                                                                                                            flexDirection: "column",
                                                                                                            width: 200,
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className="status-tag-gray flex justify-content-center align-items-center p-0 m-1" style={{ height: 30, fontSize: 14, fontWeight: 700 }}>
                                                                                                            {x[0]}
                                                                                                        </div>
                                                                                                        {x[1].map((z) => {
                                                                                                            console.log(z)
                                                                                                            if ((z.form_type === 1 || z.form_type === 2) && (z.dp !== undefined ? z.dp !== null : true)) {


                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div className="">
                                                                                                                            <div
                                                                                                                                className="fw-5 fs-14 flex m-1 justify-content-center align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                            >
                                                                                                                                {z.current}
                                                                                                                                {z.category === 2 && z.diff !== null &&
                                                                                                                                    <label className="ml-2 flex">
                                                                                                                                        (
                                                                                                                                        <span className="flex align-items-center    " style={{ marginLeft: 1, marginRight: 1 }}>
                                                                                                                                            {z.diff ?
                                                                                                                                                <>
                                                                                                                                                    <small style={{ color: 'green' }}>{z.percentage}</small>
                                                                                                                                                    <i
                                                                                                                                                        className="material-icons"
                                                                                                                                                        style={{
                                                                                                                                                            color: z.current
                                                                                                                                                                ? "green"
                                                                                                                                                                : "white",
                                                                                                                                                            fontSize: 14,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        arrow_downward
                                                                                                                                                    </i>
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <small style={{ color: 'red' }}>{z.percentage}</small>
                                                                                                                                                    <i
                                                                                                                                                        className="material-icons"
                                                                                                                                                        style={{
                                                                                                                                                            color: z.current
                                                                                                                                                                ? "red"
                                                                                                                                                                : "white",
                                                                                                                                                            fontSize: 14,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        arrow_upward
                                                                                                                                                    </i>
                                                                                                                                                </>
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                        )
                                                                                                                                    </label>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <hr
                                                                                                                            style={{
                                                                                                                                borderWidth: 2,
                                                                                                                                margin: 1,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                );
                                                                                                            }
                                                                                                        })}

                                                                                                        {x[1].length !== 0 && x[1][0].id !== undefined
                                                                                                            ? (
                                                                                                                <div style={{ marginTop: 3 }}>
                                                                                                                    <div className="flex justify-content-center" style={{ height: 30 }}>
                                                                                                                        <Tag
                                                                                                                            className={((x[1][0].status === null || x[1][0].status === 0) ? 'status-tag-red'
                                                                                                                                : x[1][0].status === 1 ? 'status-tag-orange'
                                                                                                                                    : (x[1][0].status === 2 || x[1][0].status === 3) ? 'status-tag-blue'
                                                                                                                                        : x[1][0].status === 4 ? "status-tag-yellow" : "status-tag-green") + ' flex align-items-center'
                                                                                                                            } >

                                                                                                                            {x[1][0].status === null ? 'Pending Submissions' : x[1][0].status === 0 ? 'Drafted' : x[1][0].status === 1 ? 'Requires Resubmissions' : x[1][0].status === 2 ? 'Requires Re-review' : x[1][0].status === 3 ? 'Pending Review' : x[1][0].status === 4 ? 'Pending Approval' : 'Approved'}
                                                                                                                        </Tag>
                                                                                                                    </div>
                                                                                                                    {(x[1][0].remarks !== null) ?
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                className="cur-pointer text-underline flex align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                                onClick={() => {
                                                                                                                                    setRemarksData(x[1][0].remarks)
                                                                                                                                    setRemarksDialog(true)
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                View
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                style={{ height: 30 }}
                                                                                                                            >

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    }
                                                                                                                    {(x[1][0].status === null || x[1][0].status === 0 || x[1][0].status === 1 || x[1][0].status === 2 || x[1][0].status === 3 || x[1][0].status === 4 || x[1][0].status === 5) ?
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                className=" fw-5 fs-16 col-12 clr-navy flex justify-content-center align-items-center"
                                                                                                                                style={{ height: 30 }}

                                                                                                                            >
                                                                                                                                {x[0] !== params.state.reporting_period && <>
                                                                                                                                    <div className="cur-pointer p-2 text-underline" onClick={() => {
                                                                                                                                        window.open(
                                                                                                                                            window.location
                                                                                                                                                .origin +
                                                                                                                                            "/data_input_view/" +
                                                                                                                                            item.dcfId +
                                                                                                                                            "/" +
                                                                                                                                            x[1][0].id
                                                                                                                                        );
                                                                                                                                    }}> View   </div>    <div className="cur-pointer p-2 text-underline" onClick={() => {
                                                                                                                                        if (x[1][0].status !== 5) {

                                                                                                                                            if ((x[1][0].status === null && x[1][0].refobj.reporter_ids.includes(login_data.id)) || (x[1][0].status === 0 && x[1][0].refobj.reporter_ids.includes(login_data.id)) || (x[1][0].status === 1 && x[1][0].refobj.reporter_ids.includes(login_data.id))) {
                                                                                                                                                window.open(
                                                                                                                                                    window.location
                                                                                                                                                        .origin +
                                                                                                                                                    "/data_input_past/" +
                                                                                                                                                    item.dcfId +
                                                                                                                                                    "/" +
                                                                                                                                                    x[1][0].id
                                                                                                                                                );
                                                                                                                                            } else if (x[1][0].refobj.reporter_ids.includes(login_data.id) && (x[1][0].status === 3 || (x[1][0].status === 4 && !x[1][0].refobj.reviewer_ids.length))) {
                                                                                                                                                recall(item.dcfId, x[1][0].id, index, index2, item.year)

                                                                                                                                            } else if (x[1][0].status === 3 && x[1][0].refobj.reviewer_ids.includes(login_data.id)) {
                                                                                                                                                reviewAction(item.dcfId, x[1][0].id, index, index2, item.year, x[0])

                                                                                                                                            } else if (x[1][0].status === 4 && x[1][0].refobj.reviewer_ids.includes(login_data.id)) {

                                                                                                                                                recallReview(item.dcfId, x[1][0].id, index, index2, item.year)
                                                                                                                                            }

                                                                                                                                        }

                                                                                                                                    }}>  {(x[1][0].status === null && x[1][0].refobj && x[1][0].refobj.reporter_ids.includes(login_data.id)) ? 'Open Form' : (x[1][0].status === 0 && x[1][0].refobj && x[1][0].refobj.reporter_ids.includes(login_data.id)) ? 'Update Form' : (x[1][0].refobj && x[1][0].refobj.reporter_ids.includes(login_data.id) && x[1][0].refobj && !x[1][0].refobj.reviewer_ids.includes(login_data.id) && (x[1][0].status === 3 || (x[1][0].status === 4 && x[1][0].refobj && !x[1][0].refobj.reviewer_ids.length))) ? 'Recall' : (x[1][0].status === 1 && x[1][0].refobj && x[1][0].refobj.reporter_ids.includes(login_data.id)) ? 'Revise Form' : (x[1][0].status === 2 && x[1][0].refobj && x[1][0].refobj.reviewer_ids.includes(login_data.id)) ? 'Re-Review' : (x[1][0].status === 3 && x[1][0].refobj && x[1][0].refobj.reviewer_ids.includes(login_data.id)) ? 'Review' : (x[1][0].status === 4 && x[1][0].refobj && x[1][0].refobj.reviewer_ids.includes(login_data.id)) ? 'Recall' : ''}</div>

                                                                                                                                </>}
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        :

                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div

                                                                                                                                style={{ height: 30 }}

                                                                                                                            >

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div class="flex align-items-center"
                                                                                                                            onClick={() => { setAssigneeDialog(true); setAssigneeObj(x[1][0].refobj) }}
                                                                                                                            style={{ height: 30 }}

                                                                                                                        >
                                                                                                                            View Assignees
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            ) :
                                                                                                            (
                                                                                                                <div style={{ marginTop: 3 }}>
                                                                                                                    <div className="flex justify-content-center" style={{ height: 30 }}>
                                                                                                                        <Tag
                                                                                                                            className="status-tag-red"
                                                                                                                        >

                                                                                                                            {'Pending Submission'}
                                                                                                                        </Tag>
                                                                                                                    </div>
                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div

                                                                                                                            style={{ height: 30 }}

                                                                                                                        >

                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        {x[0] !== params.state.reporting_period ? <div className="cur-pointer text-underline fw-5 fs-16 clr-navy flex align-items-center"
                                                                                                                            style={{ height: 30 }}
                                                                                                                            onClick={() => {
                                                                                                                                if (x[1][0].refobj.reporter_ids.includes(login_data.id)) {
                                                                                                                                    sessionStorage.setItem('newer', JSON.stringify({ ...x[1][0], reporting_period: x[0] }))
                                                                                                                                    window.open(
                                                                                                                                        window.location
                                                                                                                                            .origin +
                                                                                                                                        "/data_input_newer/" +
                                                                                                                                        item.dcfId

                                                                                                                                    )
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        >

                                                                                                                            {x[1][0].refobj.reporter_ids.includes(login_data.id) ? 'Open Form' : 'NA'}


                                                                                                                        </div> :
                                                                                                                            <div

                                                                                                                                style={{ height: 30 }}

                                                                                                                            >

                                                                                                                            </div>

                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div class="flex align-items-center"
                                                                                                                            onClick={() => { setAssigneeDialog(true); setAssigneeObj(x[1][0].refobj) }}
                                                                                                                            style={{ height: 30 }}

                                                                                                                        >
                                                                                                                            View Assignees
                                                                                                                        </div>
                                                                                                                    </div>



                                                                                                                </div>
                                                                                                            )


                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </AccordionTab>
                                                        )
                                                    })

                                                    }
                                                </Accordion>



                                            </BlockUI>


                                        </div>}
                                    </div>
                                }
                            </div>




                        </div>
                        :
                        <div className="col-12 card">Form not found</div>
                        // <div className="col-12 card">You have no rights to access this page </div>

                    }
                </div>
                <Dialog visible={assigneedialog} modal
                    className="p-fluid"
                    onHide={() => {
                        setAssigneeDialog(false);
                    }} style={{ width: '65%' }} header={'Assignees '} >
                    <div>
                        <Accordion multiple activeIndex={[0]}>
                            <AccordionTab header={"Submitter " + (assigneeobj.reporter_ids ? `(${assigneeobj.reporter_ids.length})` : '')}>
                                {userList.filter(i => assigneeobj.reporter_ids.includes(i.id)).map((i, index) => {
                                    return (
                                        <p>{index + 1} . {i.information.empname} </p>
                                    )
                                }
                                )

                                }
                            </AccordionTab>
                            {assigneeobj.reviewer_ids && assigneeobj.reviewer_ids.length !== 0 &&
                                <AccordionTab header={"Reviewer " + (assigneeobj.reviewer_ids ? `(${assigneeobj.reviewer_ids.length})` : '')}>
                                    {userList.filter(i => assigneeobj.reviewer_ids.includes(i.id)).map((i, index) => {
                                        return (
                                            <p>{index + 1} . {i.information.empname} </p>
                                        )
                                    }
                                    )

                                    }
                                </AccordionTab>}

                        </Accordion>
                    </div>
                </Dialog>
                <Dialog visible={remarksdialog} modal
                    className="p-fluid"
                    onHide={() => {
                        setRemarksDialog(false);
                    }} style={{ width: '65%' }} header={'Remarks'} >
                    <div>
                        {
                            remarksdata.map((cmnt) => {
                                return (
                                    <div className="col-12 grid " style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                        <div className="col-5">
                                            <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                            <div className="mt-2" > {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                        </div>
                                        <div className="col-5">
                                            {cmnt.remarks}

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Dialog>
            </StickyContainer>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QuantitativeNewSubmission, comparisonFn);
