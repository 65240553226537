import React, { useState, useEffect } from "react";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import useForceUpdate from "use-force-update";
import { Button } from "primereact/button";
import ChartDL from "../../assets/js/chartjsplugin";
import { useHistory } from 'react-router-dom'

import chart, { Chart } from "chart.js/dist/chart";
import { registerables } from "chart.js";
import { MultiSelect } from 'primereact/multiselect'
import { Dialog } from "primereact/dialog";
import { TRUE } from "sass";
import Swal from "sweetalert2";
import { get } from "jquery";
import { InputText } from "primereact/inputtext";
import { SelectButton } from 'primereact/selectbutton';
import { Sticky, StickyContainer } from "react-sticky";

Chart.register(...registerables);


const QuantitativeApproverDB = () => {
    const [filters, setFilters] = useState({

        submitted_by: { value: null, matchMode: 'in' },
        dcf: { value: null, matchMode: 'in' },
        site: { value: null, matchMode: 'in' },
    });
    const [filters2, setFilters2] = useState({
        submitter: { value: null, matchMode: 'contains' },
        'dcf.id': { value: null, matchMode: 'in' },
        'site.id': { value: null, matchMode: 'in' },
    });
    let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let color = ['#D7E7EF', '#93D3F1', '#41C7F3', '#0797D6', '#1F60AB', '#09276D']
    const [selected, setSelected] = useState({ year: 2022, country: 0, city: 0, site: 0, scope1: { country: 0, city: 0, site: 0 }, scope2: { country: 0, city: 0, site: 0 }, scope3: { country: 0, city: 0, site: 0 }, hr: { country: 0, city: 0, site: 0 }, pue: { country: 0, city: 0, site: 0 }, water: { country: 0, city: 0, site: 0 }, hr_enter: { country: 0, city: 0, site: 0 }, hr_leave: { country: 0, city: 0, site: 0 } })
    const [selectedList, setSelectedList] = useState({ country: [], city: [], site: [], scope1: { country: [], city: [], site: [] }, scope2: { country: [], city: [], site: [] }, scope3: { country: [], city: [], site: [] }, hr: { country: [], city: [], site: [] }, pue: { country: [], city: [], site: [] }, water: { country: [], city: [], site: [] }, hr_enter: { country: [], city: [], site: [] }, hr_leave: { country: [], city: [], site: [] } })
    const [selectedSite, setSelectedSite] = useState(null)
    const [toggle, setToggle] = useState({ hr: 1 })
    const [siteRawList, setRawSitelist] = useState([])
    const [searchindex, setSearchIndex] = useState(0)
    const [dcfresponse, setDCFResponse] = useState([])
    const [dcflist, setDCFList] = useState([])
    const [active, setActive] = useState(0)
    const [sitelist, setSiteList] = useState([])
    const [indicator, setIndicator] = useState([{ title: 'Energy & Emissions', color: 'black', bgc: 'white', border: '1px solid #71C0B1', base_color: '#71C0B1' }, { title: 'Energy', color: 'black', bgc: 'white', border: '1px solid #71C0B1', base_color: '#71C0B1' }])
    const [status, setStatus] = useState({ one: false, two: false, three: false, four: false })
    const login_data = useSelector((state) => state.user.userdetail)
    const client_info = useSelector((state) => state.userlist.admindetail)
    const [approvedList, setApprovedList] = useState({ scope1: [], scope2: [], scope3: [], hr: [], pue: [], water: [], hr_enter: [], hr_leave: [] })
    const [pendingList, setPendingList] = useState({ scope1: [], scope2: [], scope3: [], hr: [], pue: [], water: [], hr_enter: [], hr_leave: [] })
    const [approvedListBK, setApprovedListBK] = useState({ scope1: [], scope2: [], scope3: [], hr: [], pue: [], water: [], hr_enter: [], hr_leave: [] })
    const [pendingListBK, setPendingListBK] = useState({ scope1: [], scope2: [], scope3: [], hr: [], pue: [], water: [], hr_enter: [], hr_leave: [] })
    const [dialogapplist, setDialogAppList] = useState([])
    const [dialogpenlist, setDialogPenList] = useState([])
    const [dialogapplistBK, setDialogAppListBK] = useState([])
    const [dialogpenlistBK, setDialogPenListBK] = useState([])

    const [showappdialog, setShowAppDialog] = useState(false)
    const [showpendialog, setShowPenDialog] = useState(false)
    const [approvedListBk, setApprovedListBk] = useState([])
    const [userdcfass, setUserDCFAss] = useState([])
    const [penListByYear, setPenListByYear] = useState([])
    const [searchstr, setSearchStr] = useState({ app: '', pen: '' })
    const [penyearcount, setPenYearCount] = useState(0)
    const [apief, setApiEF] = useState([])
    const [subcat, setSubCat] = useState({ one: [], two: [], three: [], four: [] })
    const userList = useSelector(state => state.userlist.userList)
    const [remarksdialog, setRemarksDialog] = useState(false)
    const [remarksdata, setRemarksData] = useState({ return_remarks: [] })
    const forceUpdate = useForceUpdate()
    const navigate = useHistory()
    useEffect(() => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]
        }
        let uriString3 = {
            "include": [{ "relation": "newDataPoints" }]
        }
        let uriString2 = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]
        }
        let ef_complete = API.EF_Std + `?filter=%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfDates%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfs%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfItems%22%20%7D%5D%20%7D%20%7D%5D%20%7D%20%7D%5D%7D`
        let dcf_list = [], dcf_submitted = [], locloc = []
        let site_url = API.LocationOne_UP(login_data.information.cid) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
        const promise0 = APIServices.get(site_url)
        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.QN_Submit_UP(login_data.information.cid))
        const promise3 = APIServices.get(API.DCF_Entity_UP(login_data.information.cid))
        const promise4 = APIServices.get(ef_complete)
        const promise5 = APIServices.get(API.EF_SC1)
        const promise6 = APIServices.get(API.EF_SC2)
        const promise7 = APIServices.get(API.EF_SC3)
        const promise8 = APIServices.get(API.EF_SC4)
        const promise9 = APIServices.get(API.DCF_Entity_User_UP(login_data.information.cid))

        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9]).then(function (values) {

            console.log(values)
            const shapedSite = values[0].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            shapedSite.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                i.locationTwos.forEach((j) => {
                    city.push({ name: j.name, id: j.id })
                    j.locationThrees.forEach((k) => {
                        site.push({ name: k.name, id: k.id })
                    })
                })
            })

            setSelectedList((prev) => { return { ...prev, country, city, site, scope1: { country, city, site }, scope2: { country, city, site }, scope3: { country, city, site }, hr: { country, city, site }, pue: { country, city, site }, water: { country, city, site }, hr_enter: { country, city, site }, hr_leave: { country, city, site } } })
            setRawSitelist(shapedSite)
            let site_list = values[0].data.flatMap(i =>
                (i.locationTwos ?? []).flatMap(j =>
                    (j.locationThrees ?? []).map((k) => { return { ...k, country: i.name, country_id: i.id, city_id: j.id, city: j.name } })
                )
            );
            console.log(site_list)
            let site_ids = values[0].data.flatMap(item =>
                (item.locationTwos ?? []).flatMap(locationTwo =>
                    (locationTwo.locationThrees ?? []).map((k) => { return k.id })
                )
            );
            setSiteList([{ name: 'All', id: 0 }, ...site_list])
            let dcf_ids = values[1].data.map(i => i.id)
            dcf_list = values[1].data
            setDCFList(dcf_list)
            setDCFResponse(values[2].data)
            setApiEF(values[4].data)
            let loc_subcat = subcat
            loc_subcat.one = values[5].data
            loc_subcat.two = values[6].data
            loc_subcat.three = values[7].data
            loc_subcat.four = values[8].data
            setSubCat(loc_subcat)
            // let sify = filterResponseBySIFY(values[2].data,1, site_ids, [264,257],DateTime.utc().toLocal().year,0)
            // console.log(splitDataByMonth(sify,0))
            let approved_data = values[2].data.filter(i => { return (site_ids.includes(i.tier3_id) && (i.type === 1 || i.type === 2 || i.type === 3) && dcf_ids.includes(i.dcfId)) })
            approved_data.forEach(item => {
                item.dcf_ = dcf_list.find(i => i.id === item.dcfId)
                item.site_ = getCoverageText(item, shapedSite)
                item.rp = getRPMonth(item.reporting_period)
            })
            console.log(approved_data)
            setApprovedListBk(approved_data)

            let filtered_qn_ass = values[9].data.filter(
                (i) =>
                    dcf_list.map((j) => j.id).includes(i.dcfId) &&
                    checkEnity(i, values[3].data, shapedSite, "dcfId")
            );
            console.log(filtered_qn_ass)
            // values[3].data.filter(i => { return (site_ids.includes(i.tier0_id) && dcf_ids.includes(i.dcfId) && i.frequency === 1) })
            let user_ass = filtered_qn_ass.filter(i => { return (dcf_ids.includes(i.dcfId)) })
            setUserDCFAss(user_ass)
            setSelectedSite(0)
        })

    }, [])
    useEffect(() => {
        if (approvedListBk.length && userdcfass.length) {
            updatePendingTable(selected.year)
        }
    }, [approvedListBk, userdcfass])
    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];
            console.log(entity);
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            }
        } else {
            return false;
        }
    };
    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";
        console.log(rowData);
        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };

    const updateSiteSelection_scope1 = (obj, val) => {
        let loc = selected.scope1
        loc[obj] = val
        let site_ids = [], country_ids = [], city_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    country_ids.push(i.id)
                    i.locationTwos.forEach((j) => {
                        city_ids.push(j.id)
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList((prev) => { return { ...prev, scope1: { country, city, site } } })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    country_ids.push(i.id)
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            city_ids.push(j.id)
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList((prev) => { return { ...prev, scope1: { country, city, site } } })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    country_ids.push(i.id)
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            city_ids.push(j.id)
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }
                                site.push({ name: k.name, id: k.id })

                            })
                        }
                    })
                }
            })
            setSelectedList((prev) => { return { ...prev, scope1: { country, city, site } } })
        }
        updateScope1Graph(country_ids, city_ids, site_ids, selected.year)
        setSelected((prev) => { return { ...prev, scope1: loc } })
        forceUpdate()
    }
    const updateSiteSelection_scope2 = (obj, val) => {
        let loc = selected.scope2
        loc[obj] = val
        let site_ids = [], country_ids = [], city_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    country_ids.push(i.id)
                    i.locationTwos.forEach((j) => {
                        city_ids.push(j.id)
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList((prev) => { return { ...prev, scope1: { country, city, site } } })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    country_ids.push(i.id)
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            city_ids.push(j.id)
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList((prev) => { return { ...prev, scope1: { country, city, site } } })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    country_ids.push(i.id)
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            city_ids.push(j.id)
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }
                                site.push({ name: k.name, id: k.id })

                            })
                        }
                    })
                }
            })
            setSelectedList((prev) => { return { ...prev, scope1: { country, city, site } } })
        }
        updateScope2Graph(country_ids, city_ids, site_ids, selected.year)
        setSelected((prev) => { return { ...prev, scope2: loc } })
        forceUpdate()
    }
    const updateSiteSelection_water = (obj, val) => {
        let loc = selected.water
        loc[obj] = val
        let site_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList((prev) => { return { ...prev, water: { country, city, site } } })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList((prev) => { return { ...prev, water: { country, city, site } } })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }
                                site.push({ name: k.name, id: k.id })

                            })
                        }
                    })
                }
            })
            setSelectedList((prev) => { return { ...prev, water: { country, city, site } } })
        }
        updateWaterGraph(site_ids, selected.year)
        setSelected((prev) => { return { ...prev, water: loc } })
        forceUpdate()
    }
    const updateSiteSelection_pue = (obj, val) => {
        let loc = selected.pue
        loc[obj] = val
        let site_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList((prev) => { return { ...prev, pue: { country, city, site } } })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList((prev) => { return { ...prev, pue: { country, city, site } } })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }
                                site.push({ name: k.name, id: k.id })

                            })
                        }
                    })
                }
            })
            setSelectedList((prev) => { return { ...prev, pue: { country, city, site } } })
        }
        updatePUEGraph(site_ids, selected.year)
        setSelected((prev) => { return { ...prev, pue: loc } })
        forceUpdate()
    }

    const updateSiteSelection_hr = (obj, val) => {
        let loc = selected.hr
        loc[obj] = val
        let site_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList((prev) => { return { ...prev, hr: { country, city, site } } })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList((prev) => { return { ...prev, hr: { country, city, site } } })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }

                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            setSelectedList((prev) => { return { ...prev, hr: { country, city, site } } })
        }

        updateHRData_STT(site_ids, selected.year, toggle.hr)
        setSelected((prev) => { return { ...prev, hr: loc } })
        forceUpdate()
    }
    const updateSiteSelection_hr_leave = (obj, val) => {
        let loc = selected.hr_leave
        loc[obj] = val
        let site_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList((prev) => { return { ...prev, hr_leave: { country, city, site } } })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList((prev) => { return { ...prev, hr_leave: { country, city, site } } })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }

                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            setSelectedList((prev) => { return { ...prev, hr_leave: { country, city, site } } })
        }

        updateHRData_Leave_STT(site_ids, selected.year)
        setSelected((prev) => { return { ...prev, hr_leave: loc } })
        forceUpdate()
    }
    const updateSiteSelection_hr_enter = (obj, val) => {
        let loc = selected.hr_enter
        loc[obj] = val
        let site_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList((prev) => { return { ...prev, hr_enter: { country, city, site } } })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList((prev) => { return { ...prev, hr_enter: { country, city, site } } })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }

                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            setSelectedList((prev) => { return { ...prev, hr_enter: { country, city, site } } })
        }

        updateHRData_Enter_STT(site_ids, selected.year)
        setSelected((prev) => { return { ...prev, hr_enter: loc } })
        forceUpdate()
    }
    // PUE Graph
    const showStackedBarChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []


                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6
                            const meta = chart.getDatasetMeta(indx);
                            if (custom.type === 1) {
                                let x1 = chart.getDatasetMeta(ind).data[indx].x
                                let y1 = (chart.getDatasetMeta(ind).data[indx].base + chart.getDatasetMeta(ind).data[indx].y) / 2;
                                const data = j;
                                const text = data.toString(); // Get the label text

                                ctx.fillStyle = '#000'; // Set text color
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                console.log(parseFloat(j) > 0)
                                if (parseFloat(j) > 0) {
                                    ctx.fillText(text, x1, y1);
                                }
                            }
                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (custom.incomplete[ind] === null || custom.incomplete[ind] === 0) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    } else if (custom.incomplete[ind] === 2) {
                        ctx.fillStyle = "deepskyblue";
                        ctx.font = "12px Arial";
                        ctx.fillText('✓', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,

                    callbacks: {
                        title: toolTipTitleRender,
                        label: toolTipLabelRender,
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,

                        }
                    }
                },
                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showStackedBarChartIncompleteSC = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        console.log(custom)
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6

                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (custom.incomplete[ind] === null || custom.incomplete[ind] === 0) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    } else if (custom.incomplete[ind] === 2) {
                        ctx.fillStyle = "deepskyblue";
                        ctx.font = "12px Arial";
                        ctx.fillText('✓', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }

        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const getCustomToolTip = (chart) => {
            let divElement = chart.canvas.parentNode.querySelector('div')
            console.log(divElement)
            console.log(divElement)
            if (!divElement) {
                divElement = document.createElement('DIV')
                divElement.classList.add('chartjs-tooltip')
                let ulElement = document.createElement('UL')
                ulElement.classList.add('chartjs-tooltipul')
                divElement.appendChild(ulElement)
                chart.canvas.parentNode.appendChild(divElement)

            }
            return divElement
        }
        const tootltipLabels = (items) => {
            let sctooltip = custom.data[items.dataIndex]
            let label_value = chartdata[items.datasetIndex].label
            let tooltip_value = sctooltip.filter(i => i.fuel_type === label_value)[0]
            let unit = tooltip_value.fuel_type.includes('Ref') ? ' kg' : ' Litres'
            return (
                [`${tooltip_value.fuel_type}`, `Consumed - ${tooltip_value.usage} ` + unit, `Emission - ${tooltip_value.ghg} tCo2e`]
            )
        }
        function customTooltip(tooltipModel) {
            var tooltipEl = document.getElementById('tooltip');

            // Hide tooltip if no tooltipModel is provided or no tooltip is visible
            if (!tooltipModel || !tooltipModel.body) {
                tooltipEl.style.display = 'none';
                return;
            }

            // Set tooltip content
            var bodyLines = tooltipModel.body.map(function (bodyItem) {
                return bodyItem.lines;
            });

            var innerHtml = '<div>';
            bodyLines.forEach(function (line) {
                innerHtml += '<div>' + line + '</div>';
            });
            innerHtml += '</div>';

            var position = this._chart.canvas.getBoundingClientRect();

            // Position tooltip
            tooltipEl.innerHTML = innerHtml;
            tooltipEl.style.display = 'block';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        }
        const toolTipLabelRender = (context) => {
            const { chart, tooltip } = context
            const ttelement = getCustomToolTip(chart)
            if (tooltip.opacity === 0) {
                ttelement.style.opacity = 0
                return;
            }
            if (tooltip.body) {
                const titlelines = tooltip.title || []
                const bodyLines = tooltip.body.map(b => b.lines)
            }


        };

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,
                        }
                    }
                },
                legend: {
                    display: false,

                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                        // labels: {
                        //     // Define your custom legend labels here
                        //     generateLabels: function(chart) {
                        //       const cdata = chart.data;
                        //       let clabels =[]
                        //       clabels =   cdata.datasets.map(i => i.label) 

                        //       clabels.push('InComplete')

                        //       if (cdata.labels.length && cdata.datasets.length) {
                        //         return clabels.map(function(label, index) {

                        //             const dataset = chart.data.datasets[index];
                        //             if(label === 'InComplete'){
                        //                 return {
                        //                     text: '●',
                        //                     color: 'red',

                        //                     // You can add additional properties as needed
                        //                   };
                        //             }else{
                        //                 return {
                        //                     text: label,
                        //                     fillStyle: dataset.backgroundColor[index],
                        //                     strokeStyle: dataset.borderColor[index],
                        //                     lineWidth: dataset.borderWidth,
                        //                     // You can add additional properties as needed
                        //                   };
                        //             }

                        //         });
                        //       }
                        //       return [];
                        //     }
                        //   }
                    },
                    tooltip: {
                        displayColors: false,
                        // external: toolTipLabelRender
                        callbacks: {
                            title: toolTipTitleRender,
                            label: tootltipLabels,
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showStackedBarChartIncomplete = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6

                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (custom.incomplete[ind] === null || custom.incomplete[ind] === 0 || custom.incomplete[ind] === false) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    } else if (custom.incomplete[ind] === 2) {
                        ctx.fillStyle = "deepskyblue";
                        ctx.font = "12px Arial";
                        ctx.fillText('✓', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }

        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,
                        }
                    }
                },
                legend: {
                    display: false,

                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                        // labels: {
                        //     // Define your custom legend labels here
                        //     generateLabels: function(chart) {
                        //       const cdata = chart.data;
                        //       let clabels =[]
                        //       clabels =   cdata.datasets.map(i => i.label) 

                        //       clabels.push('InComplete')

                        //       if (cdata.labels.length && cdata.datasets.length) {
                        //         return clabels.map(function(label, index) {

                        //             const dataset = chart.data.datasets[index];
                        //             if(label === 'InComplete'){
                        //                 return {
                        //                     text: '●',
                        //                     color: 'red',

                        //                     // You can add additional properties as needed
                        //                   };
                        //             }else{
                        //                 return {
                        //                     text: label,
                        //                     fillStyle: dataset.backgroundColor[index],
                        //                     strokeStyle: dataset.borderColor[index],
                        //                     lineWidth: dataset.borderWidth,
                        //                     // You can add additional properties as needed
                        //                   };
                        //             }

                        //         });
                        //       }
                        //       return [];
                        //     }
                        //   }
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showStackedBarChartIncomplete2 = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()
                console.log(scope1c.config.data.datasets)

                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6

                            if (ind === 0 && (custom.total_ic[indx] === null || custom.total_ic[indx] === 0)) {

                                ctx.fillStyle = "red";
                                ctx.font = "12px Arial";
                                ctx.fillText('●', x, y)
                            } else if (ind === 1 && (custom.it_ic[indx] === null || custom.it_ic[indx] === 0)) {

                                ctx.fillStyle = "red";
                                ctx.font = "12px Arial";
                                ctx.fillText('●', x, y)


                            } else if (ind === 0 && (custom.total_ic[indx] === 2)) {
                                ctx.fillStyle = "deepskyblue";
                                ctx.font = "12px Arial";
                                ctx.fillText('✓', x, y)
                            } else if (ind === 1 && (custom.it_ic[indx] === 2)) {
                                ctx.fillStyle = "deepskyblue";
                                ctx.font = "12px Arial";
                                ctx.fillText('✓', x, y)

                            }


                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (custom.total_ic[ind] === null || custom.total_ic[ind] === false) {

                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }
        const trendline = {
            id: 'trendline',
            afterDraw: (chart, easing, options) => {

                const ctx = chart.ctx;
                const datasets = chart.data.datasets;
                let trendLine = []
                datasets.forEach((dataset, datasetIndex) => {
                    const meta = chart.getDatasetMeta(datasetIndex);
                    console.log(meta)
                    meta.data.forEach((bar, index) => {
                        const x = bar.x; // X-coordinate of the center of the bar
                        const y = bar.y; // Y-coordinate of the top of the bar
                        const height = bar.height; // Height of the bar
                        const width = bar.width; // Width of the bar
                        const label = dataset.data[index]; // Value of the bar
                        if (datasetIndex === 0) {
                            trendLine.push({ x, y, data: bar['$context'].raw })
                        } else {
                            trendLine[index].x = trendLine[index].x / x
                            trendLine[index].y = trendLine[index].y / x
                            trendLine[index].data = trendLine[index].data / bar['$context'].raw
                        }
                        // console.log(`Bar ${index + 1}: X = ${x}, Y = ${y}, Height = ${height}, Width = ${width}, Value = ${label}`);
                    });
                });
                console.log(trendLine)
            }
        }
        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    pue: {
                        beginAtZero: true,
                        type: 'linear',
                        position: 'left',
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 0,
                            minRotation: 0,
                            // callback: function (value, index, ticks) {
                            //     return value;
                            // },

                        }
                    },
                    pue1: {
                        beginAtZero: true,
                        type: 'linear',
                        position: 'right',
                        title: {
                            display: true,
                            text: 'PUE',
                        },
                        grid: {
                            display: false
                        }

                    },
                    x: {



                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,
                        }
                    }

                },
                legend: {
                    display: false,

                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                        // labels: {
                        //     // Define your custom legend labels here
                        //     generateLabels: function(chart) {
                        //       const cdata = chart.data;
                        //       let clabels =[]
                        //       clabels =   cdata.datasets.map(i => i.label) 

                        //       clabels.push('InComplete')

                        //       if (cdata.labels.length && cdata.datasets.length) {
                        //         return clabels.map(function(label, index) {

                        //             const dataset = chart.data.datasets[index];
                        //             if(label === 'InComplete'){
                        //                 return {
                        //                     text: '●',
                        //                     color: 'red',

                        //                     // You can add additional properties as needed
                        //                   };
                        //             }else{
                        //                 return {
                        //                     text: label,
                        //                     fillStyle: dataset.backgroundColor[index],
                        //                     strokeStyle: dataset.borderColor[index],
                        //                     lineWidth: dataset.borderWidth,
                        //                     // You can add additional properties as needed
                        //                   };
                        //             }

                        //         });
                        //       }
                        //       return [];
                        //     }
                        //   }
                    },


                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete, trendline],
        })

    }
    // Scope 2 Graph
    const showStackedBarChartScope2 = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6

                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (custom.incomplete[ind] === null || custom.incomplete[ind] === 0) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    } else if (custom.incomplete[ind] === 2) {

                        ctx.fillStyle = "deepskyblue";
                        ctx.font = "12px Arial";
                        ctx.fillText('✓', filtered_y.x, filtered_y.y)
                    }


                })



            }
        }
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,

                        }
                    }
                },
                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }

    // HR Graph
    const showHorizontalBarChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            console.log(items)
            let arr = []
            chartdata.forEach((i) => {
                i.data.forEach((x, y) => {
                    if (y === items.dataIndex) {
                        arr.push(x)
                    }
                })

            })
            console.log(arr)
            let total = calculatePercentages(arr)

            return items.dataset.label + ' - ' + total[items.datasetIndex] + ' %';
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6
                            ctx.fillStyle = "black";
                            ctx.font = "12px Arial";
                            if (j !== 0) {
                                let number = j.toString().length
                                if (number === 2) {
                                    ctx.fillText(j, x - 10, y + 8)
                                } else if (number >= 3) {
                                    ctx.fillText(j, x - 14, y + 8)
                                } else {
                                    ctx.fillText(j, x + 8, y + 8)
                                }

                            }


                        })

                    }
                })

            }
        }

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x'
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },


                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        }
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showDummyGraph = (canvasId, text) => {
        if (Chart.getChart(canvasId) !== undefined) {
            Chart.getChart(canvasId).destroy();
        }
        const canvas = document.getElementById(canvasId);
        const ctx = canvas.getContext('2d');
        const fontSize = 20; // Adjust the font size as needed
        ctx.font = `${fontSize}px Arial`;

        // Measure the width of the text
        const textWidth = ctx.measureText(text).width;

        // Calculate the center coordinates
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        // Set the text alignment to center
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Draw the text at the center
        ctx.fillText(text, centerX, centerY);
    }
    const showStackedBarChartNoIC = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            let arr = []
            chartdata.forEach((i) => {
                i.data.forEach((x, y) => {
                    if (y === items.dataIndex) {
                        arr.push(x)
                    }
                })

            })
            console.log(arr)
            let total = calculatePercentages(arr)

            return items.dataset.label + ' - ' + total[items.datasetIndex] + ' %';
        };
        const category = {
            id: 'category',
            afterDraw: (chart) => {
                const ctx = chart.ctx;
                const xAxis = chart.scales['x'];
                const yAxis = chart.scales['y'];
                const chartWidth = xAxis.right - xAxis.left;
                const chartHeight = yAxis.bottom - yAxis.top;
                const xOffset = xAxis.left + chartWidth / 4; // Half of the chart width
                const yOffset = yAxis.bottom + 25; // Adjust the offset as needed
                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'top';
                ctx.fillStyle = 'black'; // Adjust the color as needed
                // ctx.fillText('Male', xOffset, yOffset);
                // ctx.fillText('Female', xOffset + chartWidth / 2, yOffset);
                ctx.restore();
            }
        }
        const dataLabels = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {

                const { ctx, chartArea } = chart;
                ctx.save();
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {
                        ds.data.forEach((dataset, datasetIndex) => {
                            const meta = chart.getDatasetMeta(datasetIndex);

                            let x = chart.getDatasetMeta(ind).data[datasetIndex].x
                            let y = (chart.getDatasetMeta(ind).data[datasetIndex].base + chart.getDatasetMeta(ind).data[datasetIndex].y) / 2;
                            const data = dataset;
                            const text = data.toString(); // Get the label text

                            ctx.fillStyle = '#000'; // Set text color
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            if (parseInt(text) !== 0) {
                                ctx.fillText(text, x, y);
                            }


                        });
                    }
                })


                ctx.restore();

            }
        }
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []


                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6
                            const meta = chart.getDatasetMeta(indx);

                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (custom.ic === 0) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    } else if (custom.ic === 2) {
                        ctx.fillStyle = "deepskyblue";
                        ctx.font = "12px Arial";
                        ctx.fillText('✓', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 0
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            minRotation: 0,

                        }
                    }
                },

                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    tooltip: {
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete, dataLabels, category],
        })

    }
    const showDoughnutChart = (id, chartdata, labels, custom) => {
        let chartref = document.getElementById(id);
        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        let scope1 = chartref.getContext("2d");
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {

                const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                const { datasets } = data;
                console.log(datasets)
                const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                const originalRadius = chart.width / 2;
                const innerRadius = chart.chartArea.width / 24;
                const reducedRadiusPercentage = 0.8;
                const adjustedRadius = (originalRadius - innerRadius) * reducedRadiusPercentage + innerRadius;
                ctx.save()
                if (scope1c !== undefined) {
                    console.log(scope1c)
                    scope1c.config.data.datasets.forEach((ds, ind) => {
                        if (scope1c.isDatasetVisible(ind)) {

                            ds.data.forEach((element, indx) => {

                                const startAngle = chart.getDatasetMeta(0).data[indx].startAngle;
                                const angle = startAngle + (Math.PI * 2 * element) / 100 / 2;

                                const x = centerX + Math.cos(angle) * adjustedRadius * 0.5;
                                const y = centerY + Math.sin(angle) * adjustedRadius * 0.5;
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                ctx.fillStyle = '#fff';
                                const label = element;

                                console.log(label)

                                ctx.fillText(label + ' %', x, y);



                            });
                        }
                    });


                }





            }
        }
        const toolTipTitleRender = (items) => {
            return ''
        }
        const toolTipLabelRender = (items) => {
            console.log(items)
            return items.label + '- ' + custom.data[items.dataIndex]
        }
        var scope1c = new Chart(scope1, {
            type: 'doughnut',
            data: {
                labels,
                datasets: [{
                    data: chartdata,
                    backgroundColor: [color[3], color[1]]
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                plugins: {
                    legend: {
                        onClick: () => { },
                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },

                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        }
                    }

                },
                animation: {
                    animateRotate: true,
                    animateScale: true,
                    duration: 2500,
                },
            },
            plugins: [ChartDL, incomplete]
        });


    }
    //Scope Chart
    const showVeritcalGroupBarChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6
                            if (custom.type === 0) {
                                if (custom.incomplete[indx] === null || custom.incomplete[indx] === 0) {
                                    // ★
                                    ctx.fillStyle = "red";
                                    ctx.font = "12px Arial";
                                    ctx.fillText('●', x, y)
                                } else if (custom.incomplete[indx] === 2) {
                                    ctx.fillStyle = "deepskyblue";
                                    ctx.font = "12px Arial";
                                    ctx.fillText('✓', x, y)
                                }
                            } else if (custom.type === 1) {

                                if (ind === 0) {

                                    if (custom.sc_missing[indx] === null || custom.sc_missing[indx] === 0) {
                                        // ★
                                        ctx.fillStyle = "red";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('●', x, y)
                                    } else if (custom.sc_missing[indx] === 2) {
                                        ctx.fillStyle = "deepskyblue";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('✓', x, y)
                                    }
                                } else if (ind === 1) {

                                    if (custom.fg_missing[indx] === null || custom.fg_missing[indx] === 0) {
                                        // ★
                                        ctx.fillStyle = "red";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('●', x, y)
                                    } else if (custom.fg_missing[indx] === 2) {
                                        ctx.fillStyle = "deepskyblue";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('✓', x, y)
                                    }
                                } else if (ind === 2) {

                                    if (custom.mc_missing[indx] === null || custom.mc_missing[indx] === 0) {
                                        // ★
                                        ctx.fillStyle = "red";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('●', x, y)
                                    } else if (custom.mc_missing[indx] === 2) {
                                        ctx.fillStyle = "deepskyblue";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('✓', x, y)
                                    }
                                }
                            }

                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })




            }
        }

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {

                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        title: toolTipTitleRender,
                        label: toolTipLabelRender,
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: custom.measure,
                        }
                    }
                },
                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    function calculatePercentages(numbers) {

        // Calculate the sum of all numbers in the array
        const sum = numbers.reduce((acc, curr) => acc + curr, 0);

        // Calculate the percentage of each number
        const percentages = numbers.map(num => ((num / sum) * 100).toFixed(2));

        return percentages;
    }
    const showPieChart = (id, chartdata, labels, custom) => {
        let chartref = document.getElementById(id);
        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        let scope1 = chartref.getContext("2d");
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {

                const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                const { datasets } = data;
                console.log(datasets)
                const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                const originalRadius = chart.width / 2;
                const innerRadius = chart.chartArea.width / 24;
                const reducedRadiusPercentage = 0.65;
                const adjustedRadius = (originalRadius - innerRadius) * reducedRadiusPercentage + innerRadius;
                ctx.save()
                if (scope1c !== undefined) {
                    console.log(scope1c)
                    scope1c.config.data.datasets.forEach((ds, ind) => {
                        if (scope1c.isDatasetVisible(ind)) {

                            ds.data.forEach((element, indx) => {

                                const startAngle = chart.getDatasetMeta(0).data[indx].startAngle;
                                const angle = startAngle + (Math.PI * 2 * element) / 100 / 2;

                                const x = centerX + Math.cos(angle) * adjustedRadius * 0.5;
                                const y = centerY + Math.sin(angle) * adjustedRadius * 0.4;
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                ctx.fillStyle = '#fff';
                                const label = element;

                                console.log(label)
                                if (custom.hideDataLabel !== true) {
                                    ctx.fillText(label + ' %', x, y);
                                }




                            });
                        }
                    });


                }





            }
        }
        const tootltipLabels = (items) => {
            console.log(items)
            if (custom.data !== undefined) {
                console.log(custom.data)
                let sctooltip = custom.data[items.dataIndex].ghg
                let label_value = custom.data[items.dataIndex].gas_type
                let tooltip_value = custom.data[items.dataIndex].usage
                return (
                    [`${label_value}`, `Consumed - ${tooltip_value} kg`, `Emission - ${sctooltip} tCo2e`]
                )
            } else {
                return items.label + " - " + items.formattedValue + custom.measure;
            }

        }
        var scope1c = new Chart(scope1, {
            type: 'pie',
            data: {
                labels,
                datasets: [{
                    data: chartdata,
                    backgroundColor: ['#D7E7EF', '#93D3F1', '#41C7F3', '#0797D6', '#1F60AB']
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                radius: '75%',

                plugins: {
                    legend: {
                        onClick: () => { },
                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },

                    },
                    tooltip: {
                        displayColors: false,
                        // external: toolTipLabelRender
                        callbacks: {

                            label: tootltipLabels
                        },
                    },

                },
                animation: {
                    animateRotate: true,
                    animateScale: true,
                    duration: 2500,
                },
            },
            plugins: [ChartDL, incomplete]
        });


    }

    const mergeTwoICArraytoOne = (array1, array2) => {
        console.log(array1, array2)
        // Check if arrays have the same length
        if (array1.length !== array2.length) {
            throw new Error('Arrays must have the same length');
        }

        // Create a new array to store the merged values
        const mergedArray = [];

        // Iterate through the arrays and apply the merging logic
        for (let i = 0; i < array1.length; i++) {
            const value1 = array1[i];
            const value2 = array2[i];

            // Apply the merging logic
            if (value1 === null || value1 === 0) {
                mergedArray.push(0);
            } else if (value1 === 2) {
                if (value2 === 2) {
                    mergedArray.push(value1);
                } else {
                    mergedArray.push(0);
                }

            } else if (value1 === 1) {
                if (value2 === 1) {
                    mergedArray.push(value1);
                } else {
                    mergedArray.push(0);
                }

            }
        }

        return mergedArray;
    }
    const submittedDateTemplate = (rowData) => {

        return (
            <span>{DateTime.fromISO(rowData.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy HH:mm')} hrs</span>
        )
    }
    function removeDuplicates(array, key) {
        // Use a Set to keep track of unique values
        const seen = new Set();

        // Use filter to iterate through the array and keep only the unique objects
        const uniqueArray = array.filter(obj => {
            // Extract the value of the specified key
            const value = obj[key];
            console.log(key)
            // If the value is not in the Set, add it and return true (keep the object)
            if (!seen.has(value)) {
                seen.add(value);
                return true;
            }

            // If the value is already in the Set, return false (remove the object)
            return false;
        });

        return uniqueArray;
    }
    const submitterFilterElement = (options) => {
        console.log(options)
        return (
            <MultiSelect
                showSelectAll
                value={options.value}
                options={removeDuplicates(dialogapplist, 'submitted_by').map(item => { return { name: getUser(item.submitted_by), value: item.submitted_by } })}
                itemTemplate={submitterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="value"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const siteFilterElement = (options) => {
        console.log(options)
        return (
            <MultiSelect
                showSelectAll
                value={options.value}
                options={removeDuplicates(dialogapplist.map(item => { return { name: item.site_, value: item.site } }), 'name')}
                itemTemplate={submitterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="value"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const dcfFilterElement = (options) => {

        return (
            <MultiSelect
                showSelectAll

                value={options.value}
                options={removeDuplicates(dialogapplist.map(item => { return { name: item.dcf_.title, value: item.dcfId } }), 'name')}
                itemTemplate={submitterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="value"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const siteFilterElement_ = (options) => {
        console.log(options)
        return (
            <MultiSelect
                showSelectAll
                value={options.value}
                options={removeDuplicates(dialogpenlist.map(item => { return { name: item.site, value: item.site } }), 'name')}
                itemTemplate={submitterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="value"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const dcfFilterElement_ = (options) => {

        return (
            <MultiSelect
                showSelectAll

                value={options.value}
                options={removeDuplicates(dialogpenlist.map(item => { return { name: item.dcf.title, value: item.dcf.id } }), 'name')}
                itemTemplate={submitterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="value"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const submitterElement = (options) => {
        console.log(dialogpenlist)
        return (
            <MultiSelect
                showSelectAll

                value={options.value}
                options={removeDuplicates(dialogpenlist.map((item, index) => { return { name: item.submitter } }), 'name')}
                itemTemplate={submitterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="name"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const submitterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };
    const actionTemplate = (rowData) => {

        return (<span className="cur-pointer fw-5 fs-16" onClick={() => { rowData.form_type === 2 ? navigate.push({ pathname: "/cf_input_approve/" + rowData.dcfId, state: rowData }) : navigate.push({ pathname: "/data_input_approve/" + rowData.dcfId, state: rowData }) }}>Approve</span>)

    }
    const dcfTemplate = (rowData) => {
        return (rowData.type === 3 ?
            <span className="cur-pointer text-underline" onClick={() => { navigate.push({ pathname: "/submission_preview/" + rowData.dcfId, state: rowData }) }}>{rowData.dcf_.title}</span>
            :

            <span className="cur-pointer text-underline" onClick={() => { window.open(window.location.origin + '/data_input_approve_new/' + rowData.id) }}>{rowData.dcf_.title}</span>
        )

    }
    const siteTemplate = (rowData) => {
        console.log(rowData)
        return (
            <span >{getCoverageText(rowData,siteRawList)}</span>

        )
    }
    const dcfTemplate_ = (rowData) => {
        console.log(rowData)
        return (
            <span >{rowData.dcf.title}</span>
        )

    }
    const siteTemplate_ = (rowData) => {
        console.log(rowData)
        return (
            <span >{rowData.site}</span>

        )
    }
    const submitterTemplate = (rowData) => {
        let text = 'Not Found'
        let index = userList.findIndex(i => i.id === rowData.submitted_by)
        if (index !== -1) {
            text = userList[index].information.empname
        }
        return (
            <span>{text}</span>
        )
    }
    const remarksTemplate = (rowData) => {
        let remarks = rowData.return_remarks
        return (
            <>{(remarks === null || remarks === undefined) ?
                <label> </label> :
                <div className="fw-5 fs-14 cur-pointer text-underline" onClick={() => { setRemarksDialog(true); setRemarksData({ return_remarks: rowData.return_remarks }) }}>
                    View Remarks
                </div>}
            </>

        )
    }
    const sortRP = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {

                let dateA = DateTime.fromFormat(a.rp, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.rp, 'MMM-yyyy');
                if (a.rp.includes('to')) {

                    dateA = DateTime.fromFormat(a.rp.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.rp.includes('to')) {
                    dateB = DateTime.fromFormat(b.rp.split('to')[0].trim(), 'MMM-yyyy');
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.rp, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.rp, 'MMM-yyyy');
                if (a.rp.includes('to')) {

                    dateA = DateTime.fromFormat(a.rp.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.rp.includes('to')) {
                    dateB = DateTime.fromFormat(b.rp.split('to')[0].trim(), 'MMM-yyyy');
                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    const sortRP2 = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {

                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    const sortSubmission = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {
                // Parse the UTC string dates using Luxon
                const dateA = DateTime.fromISO(a.last_modified_on);
                const dateB = DateTime.fromISO(b.last_modified_on);

                // Compare the dates
                if (dateA < dateB) {
                    return -1; // dateA comes before dateB
                } else if (dateA > dateB) {
                    return 1; // dateA comes after dateB
                } else {
                    return 0; // dates are equal
                }
            })

        } else {
            return e.data.sort((a, b) => {
                // Parse the UTC string dates using Luxon
                const dateA = DateTime.fromISO(a.last_modified_on);
                const dateB = DateTime.fromISO(b.last_modified_on);

                // Compare the dates
                if (dateA < dateB) {
                    return -1; // dateA comes before dateB
                } else if (dateA > dateB) {
                    return 1; // dateA comes after dateB
                } else {
                    return 0; // dates are equal
                }
            }).reverse()
        }


    }
    const getSubmitter = (rowData) => {
        let text = 'Not Found'
        let index = userdcfass.findIndex(i => (i.tier0_id === rowData.site.id && i.dcfId === rowData.dcfId.id && i.type === 0))
        console.log(userdcfass, rowData, index)
        if (index !== -1) {
            let index2 = userList.findIndex(i => i.id === userdcfass[index].user_id)
            if (index2 !== -1) {
                text = userList[index2].information.empname
            }
        }
        return text
    }
    const submitterTemplate_ = (rowData) => {
        let text = 'Not Found'
        let index = userdcfass.findIndex(i => (i.tier0_id === rowData.site.id && i.dcfId === rowData.dcf.id && i.type === 0))
        console.log(userdcfass, rowData, index)
        if (index !== -1) {
            let index2 = userList.findIndex(i => i.id === userdcfass[index].user_id)
            if (index2 !== -1) {
                text = userList[index2].information.empname
            }
        }
        rowData.submitter = text
        return (
            <span>{text}</span>
        )
    }
    const searchApp = (val) => {
        setSearchStr((prev) => { return { ...prev, app: val } })
        let str = val.trim().toLowerCase()
        let loc = dialogapplistBK.filter((i) => { return (i.dcf_.title.trim().toLowerCase().includes(str) || i.site_.trim().toLowerCase().includes(str) || getUser(i.submitted_by).trim().toLowerCase().includes(str) || i.rp.trim().toLowerCase().includes(str)) })
        setDialogAppList(loc)

        forceUpdate()
    }
    const searchPen = (val) => {
        setSearchStr((prev) => { return { ...prev, pen: val } })
        let str = val.trim().toLowerCase()
        let loc = dialogpenlistBK.filter((i) => { return (i.dcfId.title.trim().toLowerCase().includes(str) || i.site.name.trim().toLowerCase().includes(str) || getSubmitter(i).trim().toLowerCase().includes(str) || i.reporting_period.trim().toLowerCase().includes(str)) })
        setDialogPenList(loc)

        forceUpdate()
    }
    const getRPMonth = (item) => {

        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
                const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('MMM-yyyy');
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
            }
        }
    }
    const onSelectedChange = (obj, val) => {
        let site_ids = [], country_ids = [], city_ids = [], loc = { ...selected, [obj]: val }
        let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
        if (obj === 'country') {
            loc.city = 0
            loc.site = 0
        } else if (obj === 'city') {

            loc.site = 0
        }
        siteRawList.forEach((i) => {
            country.push({ id: i.id, name: i.name })
            if (i.id === loc.country || loc.country === 0) {
                country_ids.push(i.id)
                i.locationTwos.forEach((j) => {
                    city.push({ id: j.id, name: j.name })
                    if (j.id === loc.city || loc.city === 0) {
                        city_ids.push(j.id)
                        j.locationThrees.forEach((k) => {
                            site.push({ id: k.id, name: k.name })
                            if (k.id === loc.site || loc.site === 0) {
                                site_ids.push(k.id)
                            }
                        })
                    }
                })
            }
        })
        if (active === 0) {
         

            if (indicator[0].selected === true) {


                updateScope1Graph(country_ids, city_ids, site_ids, loc.year)
                updateScope2Graph(country_ids, city_ids, site_ids, loc.year)
            }
     
        }

        updatePendingTable(loc.year)
        setSelectedList((prev) => { return { ...prev, country, city, site } })
        setSelected(loc)

    }
    const onSelectedChangeBKP = (obj, val) => {
        let site_ids = [], site_ids2 = [], site_ids3 = []

        if (active === 0) {
            if (indicator[1].selected === true) {
                siteRawList.forEach((i) => {

                    if (i.id === selected.pue.country || selected.pue.country === 0) {
                        i.locationTwos.forEach((j) => {

                            if (j.id === selected.pue.city || selected.pue.city === 0) {
                                j.locationThrees.forEach((k) => {
                                    if (k.id === selected.site || selected.site === 0) {
                                        site_ids.push(k.id)
                                    }




                                })
                            }
                        })
                    }
                })
                updatePUEGraph(site_ids, val)
            }
            if (indicator[2].selected === true) {
                siteRawList.forEach((i) => {

                    if (i.id === selected.hr.country || selected.hr.country === 0) {
                        i.locationTwos.forEach((j) => {

                            if (j.id === selected.hr.city || selected.hr.city === 0) {
                                j.locationThrees.forEach((k) => {
                                    if (k.id === selected.site || selected.site === 0) {
                                        site_ids.push(k.id)
                                    }




                                })
                            }
                        })
                    }
                })
                siteRawList.forEach((i) => {

                    if (i.id === selected.hr_enter.country || selected.hr_enter.country === 0) {
                        i.locationTwos.forEach((j) => {

                            if (j.id === selected.hr_enter.city || selected.hr_enter.city === 0) {
                                j.locationThrees.forEach((k) => {
                                    if (k.id === selected.hr_enter.site || selected.hr_enter.site === 0) {
                                        site_ids2.push(k.id)
                                    }




                                })
                            }
                        })
                    }
                })
                siteRawList.forEach((i) => {

                    if (i.id === selected.hr_leave.country || selected.hr_leave.country === 0) {
                        i.locationTwos.forEach((j) => {

                            if (j.id === selected.hr_leave.city || selected.hr_leave.city === 0) {
                                j.locationThrees.forEach((k) => {
                                    if (k.id === selected.hr_leave.site || selected.hr_leave.site === 0) {
                                        site_ids3.push(k.id)
                                    }




                                })
                            }
                        })
                    }
                })
                updateHRData_STT(site_ids, val, toggle.hr)
                updateHRData_Enter_STT(site_ids2, val)
                updateHRData_Leave_STT(site_ids3, val)
            }
            if (indicator[0].selected === true) {

                siteRawList.forEach((i) => {

                    if (i.id === selected.scope1.country || selected.scope1.country === 0) {
                        i.locationTwos.forEach((j) => {

                            if (j.id === selected.scope1.city || selected.scope1.city === 0) {
                                j.locationThrees.forEach((k) => {
                                    if (k.id === selected.site || selected.site === 0) {
                                        site_ids.push(k.id)
                                    }




                                })
                            }
                        })
                    }
                })
                siteRawList.forEach((i) => {

                    if (i.id === selected.scope2.country || selected.scope2.country === 0) {
                        i.locationTwos.forEach((j) => {

                            if (j.id === selected.scope2.city || selected.scope2.city === 0) {
                                j.locationThrees.forEach((k) => {
                                    if (k.id === selected.site || selected.site === 0) {
                                        site_ids2.push(k.id)
                                    }




                                })
                            }
                        })
                    }
                })
                updateScope1Graph(site_ids, val)
                updateScope2Graph(site_ids2, val)
            }
            if (indicator[3].selected === true) {

                siteRawList.forEach((i) => {

                    if (i.id === selected.water.country || selected.water.country === 0) {
                        i.locationTwos.forEach((j) => {

                            if (j.id === selected.water.city || selected.water.city === 0) {
                                j.locationThrees.forEach((k) => {
                                    if (k.id === selected.site || selected.site === 0) {
                                        site_ids.push(k.id)
                                    }




                                })
                            }
                        })
                    }
                })

                updateWaterGraph(site_ids, val)

            }
        }

        updatePendingTable(val)

        setSelected((state) => { return { ...state, [obj]: val } })

    }
    const openItemBkp = (index, view) => {
        let site_ids = []
        let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
        siteRawList.forEach((i) => {
            country.push({ name: i.name, id: i.id })
            i.locationTwos.forEach((j) => {
                city.push({ name: j.name, id: j.id })
                j.locationThrees.forEach((k) => {
                    site.push({ name: k.name, id: k.id })
                    site_ids.push(k.id)
                })
            })
        })
        if (index === 1 && view !== true) {
            updatePUEGraph(site_ids, selected.year)
        }
        if (index === 2 && view !== true) {
            updateHRData_STT(site_ids, selected.year, toggle.hr)
            updateHRData_Enter_STT(site_ids, selected.year)
            updateHRData_Leave_STT(site_ids, selected.year)
        }
        if (index === 3 && view !== true) {
            updateWaterGraph(site_ids, selected.year)
        }
        if (index === 0 && view !== true) {
            updateScope1Graph(site_ids, selected.year)
            updateScope2Graph(site_ids, selected.year)
        }
        indicator.forEach((item, ind) => {
            if (ind === index) {
                item.selected = item.selected === undefined ? true : !item.selected; forceUpdate()
            } else {
                item.selected = false
            }
        })
        setSelected({ year: selected.year, scope1: { country: 0, city: 0, site: 0 }, scope2: { country: 0, city: 0, site: 0 }, scope3: { country: 0, city: 0, site: 0 }, hr: { country: 0, city: 0, site: 0 }, pue: { country: 0, city: 0, site: 0 }, water: { country: 0, city: 0, site: 0 }, hr_enter: { country: 0, city: 0, site: 0 }, hr_leave: { country: 0, city: 0, site: 0 } })
    }
    const openItem = (index, view) => {
        let site_ids = [], country_ids = [], city_ids = []

        siteRawList.forEach((i) => {
            if (i.id === selected.country || selected.country === 0) {
                country_ids.push(i.id)
                i.locationTwos.forEach((j) => {
                    if (j.id === selected.city || selected.city === 0) {
                        city_ids.push(j.id)
                        j.locationThrees.forEach((k) => {
                            if (k.id === selected.site || selected.site === 0) {
                                site_ids.push(k.id)
                            }
                        })
                    }
                })
            }
        })
        if (index === 1 && view !== true) {
            updatePUEGraph(site_ids, selected.year)
        }
        if (index === 2 && view !== true) {
            updateHRData_STT(site_ids, selected.year, toggle.hr)
            updateHRData_Enter_STT(site_ids, selected.year)
            updateHRData_Leave_STT(site_ids, selected.year)
        }
        if (index === 3 && view !== true) {
            updateWaterGraph(site_ids, selected.year)
        }
        if (index === 0 && view !== true) {
            updateScope1Graph(country_ids, city_ids, site_ids, selected.year)
            updateScope2Graph(country_ids, city_ids, site_ids, selected.year)
        }
        indicator.forEach((item, ind) => {
            if (ind === index) {
                item.selected = item.selected === undefined ? true : !item.selected; forceUpdate()
            } else {
                item.selected = false
            }
        })
        // setSelected({ year: selected.year, scope1: { country: 0, city: 0, site: 0 }, scope2: { country: 0, city: 0, site: 0 }, scope3: { country: 0, city: 0, site: 0 }, hr: { country: 0, city: 0, site: 0 }, pue: { country: 0, city: 0, site: 0 }, water: { country: 0, city: 0, site: 0 }, hr_enter: { country: 0, city: 0, site: 0 }, hr_leave: { country: 0, city: 0, site: 0 } })
    }
    const splitDataByMonth = (data, rp_index = 0) => {
        const monthData = {};

        // Iterate over the data array
        data.forEach(item => {
            // Determine the reporting period based on rp_index or default to the first element
            const rp = DateTime.fromFormat(item.reporting_period[rp_index], 'LL-yyyy').toFormat('LLL') || DateTime.fromFormat(item.reporting_period[0], 'LL-yyyy').toFormat('LLL')

            // Initialize an array for the month if it doesn't exist yet
            if (!monthData[rp]) {
                monthData[rp] = [];
            }

            // Check if an item with the same dcf, site, and reporting_period already exists in the array
            const existingItem = monthData[rp].find(existingItem =>
                existingItem.dcfId === item.dcfId &&
                existingItem.site === item.site &&
                existingItem.reporting_period.toString() === item.reporting_period.toString()
            );

            // If the item does not already exist, push it to the array
            if (!existingItem) {
                monthData[rp].push(item);
            }
        });

        return monthData;
    }
    const filterResponseBySIFY = (array, frequency, siteIds, dcfIds, year, rpIndex) => {

        return array.filter(item => {
            // Check if the item matches the type

            if (item.frequency !== frequency) {
                return false;
            }
            // Check if the site is included in siteIds
            if (!siteIds.includes(item.site)) {
                return false;
            }

            // Check if the dcf is included in dcfIds
            if (!dcfIds.includes(item.dcfId)) {
                return false;
            }

            // Get the reporting period based on rpIndex
            const reportingPeriod = rpIndex === 0 ? item.reporting_period[0] : item.reporting_period[item.reporting_period.length - 1];

            // Convert the reporting period string to a Luxon DateTime object
            const reportingPeriodDate = DateTime.fromFormat(reportingPeriod, 'MM-yyyy');

            // Check if the reporting period is in the given year
            if (reportingPeriodDate.year !== year) {
                return false;
            }

            return true;
        });
    }
    const filterResponseBySIFY_New = (array, frequency, country_ids, city_ids, siteIds, dcfIds, year, rpIndex) => {

        return array.filter(item => {
            // Check if the item matches the type

            if (item.frequency !== frequency) {
                return false;
            }
            if (item.tier0_id) {
                return false
            }
            // Check if the site is included in siteIds
            if (item.tier1_id ? !country_ids.includes(item.tier1_id) : item.tier2_id ? !city_ids.includes(item.tier2_id) : !siteIds.includes(item.tier3_id)) {
                console.log(item)
                return false;
            }

            // Check if the dcf is included in dcfIds
            if (!dcfIds.includes(item.dcfId)) {
                return false;
            }

            // Get the reporting period based on rpIndex
            const reportingPeriod = rpIndex === 0 ? item.reporting_period[0] : item.reporting_period[item.reporting_period.length - 1];

            // Convert the reporting period string to a Luxon DateTime object
            const reportingPeriodDate = DateTime.fromFormat(reportingPeriod, 'MM-yyyy');

            // Check if the reporting period is in the given year
            if (reportingPeriodDate.year !== year) {
                return false;
            }

            return true;
        });
    }
    function getMonthsBetween(startDateUTC, endDateUTC) {
        // Parse the start date and end date strings as DateTime objects
        const startDate = DateTime.fromISO(startDateUTC, { zone: 'utc' }).startOf('day').toLocal();
        const endDate = endDateUTC ? DateTime.fromISO(endDateUTC, { zone: 'utc' }).startOf('day').toLocal() : DateTime.local().startOf('day');

        // Initialize an array to store the months between the start and end dates
        const betweenMonths = [];

        // Iterate over the months between the start and end dates
        let currentDate = startDate;
        while (currentDate <= endDate) {
            betweenMonths.push(currentDate.toFormat('LL-yyyy'));
            currentDate = currentDate.plus({ months: 1 }).startOf('month');
        }

        return betweenMonths;
    }

    const getPUECalculation = (one, two) => {
        let pue_data = []
        one.forEach((i, j) => {
            if (i !== null && two[j] !== null && i !== 0 && two[j] !== 0) {
                console.log(i, two[j])
                pue_data[j] = i / two[j]
            } else {
                pue_data[j] = 0
            }
        })
        return pue_data
    }
    const updateScope1Graph = (crid, ctid, sid, yr) => {
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return (crid.includes(i.tier1_id) || ctid.includes(i.tier2_id) || site_ids.includes(i.tier3_id)) && (i.type === 1 || i.type === 2 || i.type === 3) && i.reporting_period[0].split('-')[1] === yr.toString() && [11, 10].includes(i.dcfId) })
        setApprovedList((prev) => { return { ...prev, scope1: loc_approvedList } })
        console.log(loc_approvedList, approvedListBk, crid, ctid, sid)
        // setApprovedListBK((prev) => { return { ...prev, scope1: loc_approvedList } })
        let sify = filterResponseBySIFY_New(loc_approvedList, 1, crid, ctid, site_ids, [11, 10], yr, 0)
        let elec_r = [null, null, null, null, null, null, null, null, null, null, null, null]
        let elec_nr = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_r = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_nr = [null, null, null, null, null, null, null, null, null, null, null, null]
        let fg_missing = [null, null, null, null, null, null, null, null, null, null, null, null]
        let mc_missing = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_missing = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_ghg = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_data = [null, null, null, null, null, null, null, null, null, null, null, null]
        let fg_ghg = [null, null, null, null, null, null, null, null, null, null, null, null]
        let mc_ghg = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_fuel_usage = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_fg_fuel_usage = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_fg_fuel_type = ['Refrigerants']
        let fg_gas_type = []
        let sc_fuel_type = []
        let mc_sc_data = [null, null, null, null, null, null, null, null, null, null, null, null]
        let month_obj = splitDataByMonth(sify, 0)
        let ignore1 = userdcfass.filter((r) => { return (crid.includes(r.tier1_id) || ctid.includes(r.tier2_id) || site_ids.includes(r.tier3_id)) && r.dcfId === 10 && r.type === 1 }).map(r => r.tier3_id)
        let ignore2 = userdcfass.filter((r) => { return (crid.includes(r.tier1_id) || ctid.includes(r.tier2_id) || site_ids.includes(r.tier3_id)) && r.dcfId === 11 && r.type === 1 }).map(r => r.tier3_id)
        let ignore3 = userdcfass.filter((r) => { return (crid.includes(r.tier1_id) || ctid.includes(r.tier2_id) || site_ids.includes(r.tier3_id)) && r.dcfId === 15 && r.type === 1 }).map(r => r.tier3_id)
        let added_month = [], pending = []
        console.log(sify, month_obj)

        Object.keys(month_obj).forEach(key => {
            added_month.push(key.toUpperCase())
            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.tier3_id === site_id && i.dcfId === 10).length === 0 && !ignore1.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 10), site: getCoverageText({ locationId: site_id, level: 3 }, siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
                if (month_obj[key].filter(i => i.tier3_id === site_id && i.dcfId === 11).length === 0 && !ignore2.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 11), site: getCoverageText({ locationId: site_id, level: 3 }, siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }


            })
            ctid.forEach(site_id => {
                if (month_obj[key].filter(i => i.tier2_id === site_id && i.dcfId === 10).length === 0 && !ignore1.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 10), site: getCoverageText({ locationId: site_id, level: 2 }, siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
                if (month_obj[key].filter(i => i.tier2_id === site_id && i.dcfId === 11).length === 0 && !ignore2.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 11), site: getCoverageText({ locationId: site_id, level: 2 }, siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }


            })
            crid.forEach(site_id => {
                if (month_obj[key].filter(i => i.tier1_id === site_id && i.dcfId === 10).length === 0 && !ignore1.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 10), site: getCoverageText({ locationId: site_id, level: 1 }, siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
                if (month_obj[key].filter(i => i.tier1_id === site_id && i.dcfId === 11).length === 0 && !ignore2.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 11), site: getCoverageText({ locationId: site_id, level: 1 }, siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }


            })
            let month_ind = months.findIndex(i => i.trim().toLowerCase() === key.trim().toLowerCase())

            sc_missing[month_ind] = month_obj[key].filter(i => i.dcfId === 11).length === (site_ids.length + crid.length + ctid.length ) ? getStatusCode(month_obj[key].filter(i => i.dcfId === 11)) : 0
            fg_missing[month_ind] = month_obj[key].filter(i => i.dcfId === 10).length ===(site_ids.length + crid.length + ctid.length ) ? getStatusCode(month_obj[key].filter(i => i.dcfId === 10)) : 0

            month_obj[key].forEach((item, index) => {

                if (item.dcfId === 11) {
                    let result = getEmissionFactorCalculation(item, yr)
                    sc_ghg[month_ind] = result.reduce((a, b) => a + b.ghg, 0)
                    result.forEach(item => {
                        if (!sc_fuel_type.map(i => i.trim().toLowerCase()).includes(item.fuel_type.trim().toLowerCase())) {
                            sc_fuel_type.push(item.fuel_type);
                        }
                        if (!sc_fg_fuel_type.map(i => i.trim().toLowerCase()).includes(item.fuel_type.trim().toLowerCase())) {
                            sc_fg_fuel_type.push(item.fuel_type);
                        }

                    })
                    console.log(result)
                    sc_data[month_ind] = result
                    sc_r[month_ind] = result.reduce((a, b) => a + b.r, 0)
                    sc_nr[month_ind] = result.reduce((a, b) => a + b.nr, 0)
                    sc_fuel_usage[month_ind] = result
                    if (sc_fg_fuel_usage[month_ind] === null) {
                        sc_fg_fuel_usage[month_ind] = result
                    } else {
                        sc_fg_fuel_usage[month_ind] = [...result, ...sc_fg_fuel_usage[month_ind]]
                    }

                } else if (item.dcfId === 10) {
                    let result = getEmissionFactorCalculation(item, yr)
                    fg_ghg[month_ind] = result.reduce((a, b) => a + b.ghg, 0)
                    if (result.length > 0) {

                        result.forEach((item) => {
                            let gas_index = fg_gas_type.findIndex(i => i.gas_type.trim().toLowerCase() === item.gas_type.trim().toLowerCase())
                            if (gas_index === -1) {
                                fg_gas_type.push({ gas_type: item.gas_type, usage: item.usage, ghg: item.ghg })
                            } else {
                                fg_gas_type[gas_index].usage += item.usage
                                fg_gas_type[gas_index].ghg += item.ghg
                            }

                            item.fuel_type = 'Refrigerants'
                        })
                        if (sc_fg_fuel_usage[month_ind] === null) {

                            sc_fg_fuel_usage[month_ind] = [{ fuel_type: 'Refrigerants', usage: result.reduce((a, b) => { return a + b.usage }, 0), ghg: result.reduce((a, b) => { return a + b.ghg }, 0) }]
                        } else {
                            let sc_fg_fuel_usage_index = sc_fg_fuel_usage[month_ind].findIndex(i => i.fuel_type === 'Refrigerants')
                            if (sc_fg_fuel_usage_index === -1) {
                                sc_fg_fuel_usage[month_ind] = [...[{ fuel_type: 'Refrigerants', usage: result.reduce((a, b) => { return a + b.usage }, 0), ghg: result.reduce((a, b) => { return a + b.ghg }, 0) }], ...sc_fg_fuel_usage[month_ind]]
                            } else {
                                sc_fg_fuel_usage[month_ind][sc_fg_fuel_usage_index].usage += result.reduce((a, b) => { return a + b.usage }, 0)
                                sc_fg_fuel_usage[month_ind][sc_fg_fuel_usage_index].ghg += result.reduce((a, b) => { return a + b.ghg }, 0)
                            }

                        }


                    }
                    console.log(result)
                } else if (item.dcfId === 15) {
                    let result = getEmissionFactorCalculation(item, yr)
                    mc_ghg[month_ind] = result.reduce((a, b) => a + b.ghg, 0)
                }


            })
        })
        console.log(fg_ghg)
        let colors = ['#D7E7EF', '#93D3F1', '#41C7F3', '#0797D6', '#1F60AB']
        months.forEach((i) => {

            if (!added_month.includes(i)) {

                site_ids.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 10), site: getCoverageText({ locationId: j, level: 3 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                    if (!ignore2.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 11), site: getCoverageText({ locationId: j, level: 3 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                    if (!ignore3.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 15), site: getCoverageText({ locationId: j, level: 3 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }

                })
                ctid.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 10), site: getCoverageText({ locationId: j, level: 2 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                    if (!ignore2.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 11), site: getCoverageText({ locationId: j, level: 2 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                    if (!ignore3.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 15), site: getCoverageText({ locationId: j, level: 2 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }

                })
                crid.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 10), site: getCoverageText({ locationId: j, level: 1 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                    if (!ignore2.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 11), site: getCoverageText({ locationId: j, level: 1 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                    if (!ignore3.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 15), site: getCoverageText({ locationId: j, level: 1 }, siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }

                })

            }
        })
        setPendingList((prev) => { return { ...prev, scope1: pending } })
        console.log(sc_fg_fuel_usage)
        // setPendingListBK((prev) => { return { ...prev, scope1: pending } })
        let fuel_consumption_dataset = [], fuel_consumption_dataset_2 = []
        sc_fuel_type.forEach((item, index) => {
            fuel_consumption_dataset.push({
                label: item,
                data: JSON.parse(JSON.stringify(sc_fuel_usage)).map(i => i !== null ? i.filter(j => j.fuel_type.trim().toLowerCase() === item.trim().toLowerCase()).reduce((a, b) => { return a + b.usage }, null) : null),
                backgroundColor: colors[index]
            })
        })
        sc_fg_fuel_type.forEach((item, index) => {
            console.log(item)
            fuel_consumption_dataset_2.push({
                label: item,
                data: JSON.parse(JSON.stringify(sc_fg_fuel_usage)).map(i => i !== null ? i.filter(j => j.fuel_type.trim().toLowerCase() === item.trim().toLowerCase()).reduce((a, b) => { return a + b.usage }, null) : null),
                backgroundColor: colors[index]
            })
        })
        let sc_rnr_consumption_dataset = [{
            label: 'Renewable',
            data: sc_r,
            backgroundColor: colors[3]
        },
        {
            label: 'Non-renewable',
            data: sc_nr,
            backgroundColor: colors[4]
        }]
        console.log(sc_r, sc_nr)
        let scope1datset = [{
            label: 'Stationary',
            data: sc_ghg,
            backgroundColor: color[0]
        },
        {
            label: 'Fugitive',
            data: fg_ghg,
            backgroundColor: color[2]
        }
        ]
        let s1_ = 0, s2_ = 0, s3_ = 0
        let a = sc_ghg.reduce((a, b) => { return a + b }, 0), b = fg_ghg.reduce((a, b) => { return a + b }, 0), c = mc_ghg.reduce((a, b) => { return a + b }, 0)
        let total = a + b + c
        if (total !== 0) {
            s1_ = ((a / total) * 100).toFixed(0)
            s2_ = ((b / total) * 100).toFixed(0)
            s3_ = ((c / total) * 100).toFixed(0)
        }
        setTimeout(() => {
            console.log(mergeTwoICArraytoOne(sc_missing, fg_missing))
            showStackedBarChartIncompleteSC(fuel_consumption_dataset_2, 's1_1_0', { labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: mergeTwoICArraytoOne(sc_missing, fg_missing), type: 1, measure: " tCO" + "\u2082" + "e", data: sc_fg_fuel_usage })
            showPieChart('s1_2_0', fg_gas_type.map(i => i.usage), fg_gas_type.map(i => i.gas_type), { hideDataLabel: true, data: fg_gas_type })
            // showStackedBarChartIncompleteSC(fuel_consumption_dataset, 's1_1', { labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: sc_missing, type: 0, measure: ' Tonnes', data: sc_data })
            showVeritcalGroupBarChart(sc_rnr_consumption_dataset, 's1_2', { labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: sc_missing, type: 0, measure: " TJ" })
            // showPieChart('s1_t1', [s1_, s2_, s3_], ['Stationary', 'Fugitive', 'Mobile'], { hideDataLabel: false, measure: " tCO" + "\u2082" + "e" })
            showVeritcalGroupBarChart(scope1datset, 's1_t2', { labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], sc_missing, fg_missing, mc_missing, type: 1, measure: " tCO" + "\u2082" + "e" })

        }, 500)

    }
    const getStatusCode = (list) => {
        return list.filter(i => i.type === 1).length === 0 ? 2 : 1
    }
    const updateScope2Graph = (crid, ctid, sid, yr) => {
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return (crid.includes(i.tier1_id) || ctid.includes(i.tier2_id) || site_ids.includes(i.tier3_id)) && (i.type === 1 || i.type === 2 || i.type === 3) && i.reporting_period[0].split('-')[1] === yr.toString() && [257].includes(i.dcfId) })
        setApprovedList((prev) => { return { ...prev, scope2: loc_approvedList } })
        // setApprovedListBK((prev) => { return { ...prev, scope2: loc_approvedList } })
        let sify = filterResponseBySIFY_New(loc_approvedList, 1, crid, ctid, site_ids, [257], yr, 0)

        let elec_r = [null, null, null, null, null, null, null, null, null, null, null, null]
        let elec_nr = [null, null, null, null, null, null, null, null, null, null, null, null]
        let elec_ghg = [null, null, null, null, null, null, null, null, null, null, null, null]
        let elec_missing = [null, null, null, null, null, null, null, null, null, null, null, null]
        let elec_data = []
        let elec_type = []
        let month_obj = splitDataByMonth(sify, 0)
        let ignore = userdcfass.filter((r) => { return (crid.includes(r.tier1_id) || ctid.includes(r.tier2_id) || site_ids.includes(r.tier3_id))  && r.dcfId === 257 && r.type === 1 }).map(r => r.tier3_id)
        let added_month = [], pending = []

        Object.keys(month_obj).forEach(key => {
            added_month.push(key.toUpperCase())
            site_ids.filter(i => !ignore.includes(i)).forEach(site_id => {
                if (month_obj[key].filter(i => i.tier3_id === site_id && i.dcfId === 257).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: getCoverageText({locationId:site_id,level:3},siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }

            })
            ctid.filter(i => !ignore.includes(i)).forEach(site_id => {
                if (month_obj[key].filter(i => i.tier2_id === site_id && i.dcfId === 257).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: getCoverageText({locationId:site_id,level:2},siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }

            })
            crid.filter(i => !ignore.includes(i)).forEach(site_id => {
                if (month_obj[key].filter(i => i.tier1_id === site_id && i.dcfId === 257).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: getCoverageText({locationId:site_id,level:1},siteRawList), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }

            })
            
            let month_ind = months.findIndex(i => i.trim().toLowerCase() === key.trim().toLowerCase())
            elec_missing[month_ind] = month_obj[key].filter(i => i.dcfId === 257).length === ((site_ids.length + crid.length + ctid.length )- ignore.length) ? getStatusCode(month_obj[key].filter(i => i.dcfId === 257)) : 0
            month_obj[key].forEach((item, index) => {

                if (item.dcfId === 257) {
                    let result = getEmissionFactorCalculation(item, yr)
                    if (month_ind === 0) {
                        console.log(result, item)
                    }
                    result.forEach((item) => {

                        let elec_type_index = elec_data.findIndex(i => i.type.trim().toLowerCase() === item.type.trim().toLowerCase() && i.order === month_ind)
                        if (elec_type_index === -1) {
                            console.log(month_ind, item.ghg)
                            !elec_type.includes(item.type.trim()) && elec_type.push(item.type.trim())
                            elec_data.push({ type: item.type.trim(), ghg: item.ghg, order: month_ind })
                        } else {
                            elec_data[elec_type_index].ghg += item.ghg
                        }
                    })

                    elec_ghg[month_ind] += result.reduce((a, b) => a + b.ghg, 0)
                    elec_r[month_ind] += result.reduce((a, b) => a + b.r, 0)
                    elec_nr[month_ind] += result.reduce((a, b) => a + b.nr, 0)
                }


            })
        })
        months.forEach((i) => {

            if (!added_month.includes(i)) {

                site_ids.filter(i => !ignore.includes(i)).forEach((j) => {

                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: getCoverageText({locationId:j,level:3},siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                })
                ctid.filter(i => !ignore.includes(i)).forEach((j) => {

                    pending.push({ dcf: dcflist.find(i => i.id === 257), site:getCoverageText({locationId:j,level:2},siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                })
                crid.filter(i => !ignore.includes(i)).forEach((j) => {

                    pending.push({ dcf: dcflist.find(i => i.id === 257), site:getCoverageText({locationId:j,level:1},siteRawList), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                })


            }
        })
        setPendingList((prev) => { return { ...prev, scope2: pending } })
        // setPendingListBK((prev) => { return { ...prev, scope2: pending } })
        let colors = ['#D7E7EF', '#93D3F1', '#41C7F3', '#0797D6', '#1F60AB']
        const { renew, nonrenew } = getPercentageOf2Array(elec_r, elec_nr)
        console.log(elec_data, elec_ghg)
        elec_type.forEach((item) => {
            let indexes = elec_data.filter(i => i.type.trim().toLowerCase() === item.trim().toLowerCase()).map(j => j.order)
            for (let i = 0; i < 12; i++) {
                if (!indexes.includes(i)) {
                    elec_data.push({ type: item, order: i, ghg: 0 })
                }
            }
        })
        let datasets2 = []
        elec_type.forEach((label, ind) => {
            datasets2.push({
                label: label,
                data: elec_data.filter(i => i.type.trim().toLowerCase() === label.trim().toLowerCase()).sort((a, b) => { return a.order - b.order }).map(j => j.ghg),
                backgroundColor: colors[ind]
            })
        })
        console.log(datasets2)
        let datasets1 = [{
            label: 'Renewable',
            data: elec_r,
            backgroundColor: color[2],

        },
        {
            label: 'Non-renewable',
            data: elec_nr,
            backgroundColor: color[4],

        }]
        // let datasets2 = [{
        //     label: 'Non-renewable',
        //     data: elec_ghg.map(i => i / 1000),
        //     backgroundColor: color[4],

        // }]
        setTimeout(() => {
            // showStackedBarChartScope2(datasets1, 's2_1', { measure: '%', labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: elec_missing })
            showStackedBarChart(datasets2, 's2_2', { labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: elec_missing, measure: " tCO" + "\u2082" + "e" })
            showStackedBarChartIncomplete(datasets1, 's2_1', { measure: ' MWh', labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: elec_missing })
            // showVeritcalGroupBarChart(datasets2, 's2_2', { measure: " tCO" + "\u2082" + "e", labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: elec_missing, type: 0 })
        }, 500)

    }
    const updatePUEGraph = (sid, yr) => {
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.tier3_id) && (i.type === 1 || i.type === 2 || i.type === 3) && i.reporting_period[0].split('-')[1] === yr.toString() && [264, 257].includes(i.dcfId) })

        setApprovedList((prev) => { return { ...prev, pue: loc_approvedList } })

        // setApprovedListBK((prev) => { return { ...prev, pue: loc_approvedList.filter(i => i.dcfId === 264) } })
        let sify = filterResponseBySIFY(loc_approvedList, 1, site_ids, [264, 257], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        let r = [null, null, null, null, null, null, null, null, null, null, null, null]
        let nr = [null, null, null, null, null, null, null, null, null, null, null, null]
        let ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let total_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let it_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let total_load = [null, null, null, null, null, null, null, null, null, null, null, null]
        let it_Load = [null, null, null, null, null, null, null, null, null, null, null, null]
        let both_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let pending = []
        let added_month = []
        let ignore = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && (r.dcfId === 264 || r.dcfId === 257) && r.type === 1 }).map(r => r.tier3_id)
        let ignore1 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 264 && r.type === 1 }).map(r => r.tier3_id)
        let ignore2 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 257 && r.type === 1 }).map(r => r.tier3_id)

        Object.keys(month_obj).forEach(key => {
            added_month.push(key.toUpperCase())
            site_ids.forEach(site_id => {

                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 264).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 264), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 257).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
            })
            let month_ind = months.findIndex(i => i.toLowerCase() === key.toLowerCase())
            ic[month_ind] = month_obj[key].filter(i => i.dcfId === 257).length === (site_ids.length - ignore2.length) ? getStatusCode(month_obj[key].filter(i => i.dcfId === 257)) : 0
            total_load[month_ind] = month_obj[key].flatMap(i => i.dcfId === 257 && i.response.map(j => j.DPAN098)).reduce((a, b) => { return a + b }, 0)
            it_Load[month_ind] = month_obj[key].flatMap(i => i.dcfId === 264 && i.response.map(j => j.value)).reduce((a, b) => { return a + b }, 0)
            both_ic[month_ind] = month_obj[key].filter(i => i.dcfId === 257 || i.dcfId === 264).length === (site_ids.length - ignore.length) * 2 ? getStatusCode(month_obj[key].filter(i => (i.dcfId === 257 || i.dcfId === 264))) : 0
            total_ic[month_ind] = month_obj[key].filter(i => i.dcfId === 257).length === (site_ids.length - ignore2.length) ? getStatusCode(month_obj[key].filter(i => i.dcfId === 257)) : 0
            it_ic[month_ind] = month_obj[key].filter(i => i.dcfId === 264).length === (site_ids.length - ignore1.length) ? getStatusCode(month_obj[key].filter(i => i.dcfId === 264)) : 0

            month_obj[key].forEach((i) => {

                if (i.dcfId === 257) {
                    let result = getEmissionFactorCalculation(i, yr)
                    r[month_ind] += result.reduce((a, b) => a + b.r, 0)
                    nr[month_ind] += result.reduce((a, b) => a + b.nr, 0)
                }
                // i.response.forEach((j) => {
                //     console.log(j)
                //     total += j.DPAN098
                //     if (j.DPAN096 === 468) {
                //         console.log(i)
                //         r[month_ind] = r[month_ind] === null ? (j.DPAN098 / 1000) : r[month_ind] + (j.DPAN098 / 1000)
                //     } else if (j.DPAN096 === 469) {
                //         nr[month_ind] = nr[month_ind] === null ? (j.DPAN098 / 1000) : nr[month_ind] + (j.DPAN098 / 1000)
                //     }
                // })

            })
        })

        months.forEach((i) => {

            if (!added_month.includes(i)) {

                site_ids.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 264), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                    }
                    if (!ignore2.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 257), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                })

            }
        })
        console.log(ic)
        setPendingList((prev) => { return { ...prev, pue: pending } })
        // setPendingListBK((prev) => { return { ...prev, pue: pending } })
        const { renew, nonrenew } = getPercentageOf2Array(r, nr)
        let datasets1 = [{
            label: 'Renewable',
            data: renew,
            backgroundColor: 'rgba(5, 63, 92, 1)',
            borderColor: 'rgba(5, 63, 92, 1)',
            borderWidth: 1
        },
        {
            label: 'Non-renewable',
            data: nonrenew,
            backgroundColor: 'rgba(66, 158, 189,1)',
            borderColor: 'rgba(66, 158, 189,1)',
            borderWidth: 1
        }]
        let datasets2 = [{
            label: 'Renewable',
            data: r,
            backgroundColor: 'rgba(5, 63, 92, 1)',
            borderColor: 'rgba(5, 63, 92, 1)',
            borderWidth: 1
        },
        {
            label: 'Non-renewable',
            data: nr,
            backgroundColor: 'rgba(66, 158, 189,1)',
            borderColor: 'rgba(66, 158, 189,1)',
            borderWidth: 1

        }]
        let datasets3 = [{
            label: 'Total Load',
            data: total_load.map(i => i / 1000),
            backgroundColor: 'rgba(54, 84, 134,1)',
            borderColor: 'rgba(54, 84, 134,1)',
            borderWidth: 1,
            order: 2,
            yAxisID: 'pue'
        },
        {
            label: 'IT Load',
            data: it_Load.map(i => i / 1000),
            backgroundColor: 'rgb(127, 199, 217,1)',
            borderColor: 'rgb(127, 199, 217,1)',
            borderWidth: 1,
            order: 2,
            yAxisID: 'pue'
        },
        {
            label: 'PUE',
            data: getPUECalculation(total_load, it_Load),
            backgroundColor: 'rgb(251, 139, 36)',
            borderColor: 'rgb(251, 139, 36)',
            yAxisID: 'pue1',
            order: 1,
            type: 'line'
        }
        ]

        setTimeout(() => {
            showPieChart('pue_1', calculatePercentages([r.map(i => i / 1000).reduce((a, b) => a + b), nr.map(i => i / 1000).reduce((a, b) => a + b)]), ['Renewable', 'Non-renewable'], { hideDataLabel: false, measure: " %", data: [r.map(i => i / 1000).reduce((a, b) => a + b), nr.map(i => i / 1000).reduce((a, b) => a + b)] })
            // showStackedBarChart(datasets1, 'pue_1', { measure: '%', labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: ic })
            showStackedBarChartIncomplete(datasets2, 'pue_2', { measure: ' MWh', labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: ic })
            showStackedBarChartIncomplete2(datasets3, 'pue_3', { measure: ' MWh', labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], it_ic, total_ic })
        }, 500)

    }
    const updateHRData = (sid, yr) => {
        console.log(sid)
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.tier3_id) && i.type === 1 && i.reporting_period[0].split('-')[1] === yr.toString() && [262, 263].includes(i.dcfId) })
        setApprovedList((prev) => { return { ...prev, hr: loc_approvedList } })
        // setApprovedListBK((prev) => { return { ...prev, hr: loc_approvedList } })
        let sify = filterResponseBySIFY(loc_approvedList, 1, site_ids, [262, 263], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        console.log(month_obj)
        let added = [null, null, null, null, null, null, null, null, null, null, null, null]
        let lastIndex = -1, lastIndex2 = -1
        let ignore1 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 262 && r.type === 1 }).map(r => r.tier3_id)
        let ignore2 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 263 && r.type === 1 }).map(r => r.tier3_id)

        let added_month = [], pending = []

        Object.keys(month_obj).forEach((key, ind) => {
            added_month.push(key.toUpperCase())
            let month_ind = months.findIndex(i => i.toLowerCase() === key.toLowerCase())
            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 262).length === 0 && !ignore1.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 262), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 263).length === 0 && !ignore2.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 263), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
            })
            if (month_obj[key].filter(i => i.dcfId === 262).length !== 0) {
                if (lastIndex < month_ind) {
                    lastIndex = month_ind
                }
            }
            if (month_obj[key].filter(i => i.dcfId === 263).length !== 0) {
                console.log(month_obj[key].filter(i => i.dcfId === 263))
                if (lastIndex2 < month_ind) {
                    lastIndex2 = month_ind
                }
            }
        })
        if (lastIndex !== -1) {
            let Male_u30 = 0, Male_t50 = 0, Male_a50 = 0, Female_u30 = 0, Female_t50 = 0, Female_a50 = 0
            let last_month_ind = Object.keys(month_obj).findIndex(i => i.toLowerCase() === months[lastIndex].toLowerCase())
            console.log(Object.values(month_obj)[last_month_ind])
            let dcfindex = Object.values(month_obj)[last_month_ind].findIndex(i => i.dcfId === 262)
            let recent_employee_data = Object.values(month_obj)[last_month_ind][dcfindex].response.flatMap(i => i.type2.flatMap(j => j.type3.map(k => k)))
            let male = 0, female = 0

            recent_employee_data.forEach(i => {
                if (i.title === 'Male') {
                    Male_u30 += Object.values(i.data[0])[0]
                    Male_t50 += Object.values(i.data[1])[0]
                    Male_a50 += Object.values(i.data[2])[0]
                    male += i.data.reduce((total, obj) => {
                        const values = Object.values(obj);
                        return total + (values.length > 0 ? values[0] : 0);
                    }, 0);
                } else if (i.title === "Female") {
                    Female_u30 += Object.values(i.data[0])[0]
                    Female_t50 += Object.values(i.data[1])[0]
                    Female_a50 += Object.values(i.data[2])[0]
                    female += i.data.reduce((total, obj) => {
                        const values = Object.values(obj);
                        return total + (values.length > 0 ? values[0] : 0);
                    }, 0);
                }

            })
            let dataset1 = [{
                label: 'Male',
                backgroundColor: color[3],

                data: [Male_a50, Male_t50, Male_u30]
            },
            {
                label: 'Female',
                backgroundColor: color[1],

                borderWidth: 1,
                data: [Female_a50, Female_t50, Female_u30]
            }]
            console.log(dataset1)
            let avg = (male + female)
            setTimeout(() => {
                showDoughnutChart('hr1', [((male / avg) * 100).toFixed(1), ((female / avg) * 100).toFixed(1)], ['Male', 'Female'], { data: [male, female] })
                showHorizontalBarChart(dataset1, 'hr2', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })
            }, 200)

            console.log(recent_employee_data, male, female)
        } else {

            setTimeout(() => {
                showDummyGraph('hr1', 'No Data Found')
                showDummyGraph('hr2', 'No Data Found')
            }, 200)
        }
        months.forEach((i) => {

            if (!added_month.includes(i)) {

                site_ids.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 262), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                    if (!ignore2.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 263), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }


                })

            }
        })
        console.log(pending, loc_approvedList)
        setPendingList((prev) => { return { ...prev, hr: pending } })
        // setPendingListBK((prev) => { return { ...prev, hr: pending } })
        if (lastIndex2 !== -1) {
            let Male_u30 = 0, Male_t50 = 0, Male_a50 = 0, Female_u30 = 0, Female_t50 = 0, Female_a50 = 0
            let last_month_ind = Object.keys(month_obj).findIndex(i => i.toLowerCase() === months[lastIndex2].toLowerCase())
            let dcfindex = Object.values(month_obj)[last_month_ind].findIndex(i => i.dcfId === 263)
            let recent_employee_data = Object.values(month_obj)[last_month_ind][dcfindex].response.flatMap(i => i.type2)
            let male = 0, female = 0, dataset2_ = [[], [], []]
            Object.values(month_obj)[last_month_ind][dcfindex].response.forEach((item, ind) => {
                if (ind === 0) {
                    dataset2_[0] = [...item.type2[0].data.map(obj => Object.values(obj)[0]), ...item.type2[1].data.map(obj => Object.values(obj)[0])]
                } else if (ind === 1) {
                    dataset2_[1] = [...item.type2[0].data.map(obj => Object.values(obj)[0]), ...item.type2[1].data.map(obj => Object.values(obj)[0])]
                } else if (ind === 2) {
                    dataset2_[2] = [...item.type2[0].data.map(obj => Object.values(obj)[0]), ...item.type2[1].data.map(obj => Object.values(obj)[0])]
                } else if (ind === 3) {
                    let array1 = [...item.type2[0].data.map(obj => Object.values(obj)[0]), ...item.type2[1].data.map(obj => Object.values(obj)[0])]
                    let array2 = dataset2_[2]
                    dataset2_[2] = array1.map((val, index) => val + array2[index])
                }
            })

            recent_employee_data.forEach(i => {

                if (i.title === 'Male') {
                    Male_u30 += Object.values(i.data[0])[0]
                    Male_t50 += Object.values(i.data[1])[0]
                    Male_a50 += Object.values(i.data[2])[0]
                    male += i.data.reduce((total, obj) => {
                        const values = Object.values(obj);
                        return total + (values.length > 0 ? values[0] : 0);
                    }, 0);
                } else if (i.title === "Female") {
                    Female_u30 += Object.values(i.data[0])[0]
                    Female_t50 += Object.values(i.data[1])[0]
                    Female_a50 += Object.values(i.data[2])[0]
                    female += i.data.reduce((total, obj) => {
                        const values = Object.values(obj);
                        return total + (values.length > 0 ? values[0] : 0);
                    }, 0);
                }

            })
            let dataset1 = [{
                label: 'Male',
                backgroundColor: color[3],

                data: [Male_a50, Male_t50, Male_u30]
            },
            {
                label: 'Female',
                backgroundColor: color[1],

                data: [Female_a50, Female_t50, Female_u30]
            }]
            let dataset2 = [{
                label: 'Senior Management',
                backgroundColor: color[0],
                data: dataset2_[0].slice(0, 3)
            },
            {
                label: 'Mid-Management',
                backgroundColor: color[1],

                data: dataset2_[1].slice(0, 3)
            }, {
                label: 'Entry-Level',
                backgroundColor: color[3],

                data: dataset2_[2].slice(0, 3)
            },
            ]
            let dataset3 = [{
                label: 'Senior Management',
                backgroundColor: color[0],
                data: dataset2_[0].slice(3, 6)
            },
            {
                label: 'Mid-Management',
                backgroundColor: color[1],

                data: dataset2_[1].slice(3, 6)
            }, {
                label: 'Entry-Level',
                backgroundColor: color[3],

                data: dataset2_[2].slice(3, 6)
            },
            ]
            console.log(dataset2)
            let avg = (male + female)
            setTimeout(() => {
                // ['Male Over 50yrs', 'Male 30 - 50yrs', 'Male Under 30yrs','Female Over 50yrs', 'Female 30 - 50yrs', 'Female Under 30yrs']
                showHorizontalBarChart(dataset1, 'hr3', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })
                showStackedBarChartNoIC(dataset2, 'hr4', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })
                showStackedBarChartNoIC(dataset3, 'hr5', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })
            }, 200)

            console.log(recent_employee_data, male, female)
        } else {
            setTimeout(() => {
                showDummyGraph('hr3', 'No Data Found')
                showDummyGraph('hr4', 'No Data Found')
                showDummyGraph('hr5', 'No Data Found')
            }, 200)
        }
    }
    function checkRPSpan(dateArray, year, startMonth, endMonth) {
        // Convert start and end month numbers to strings with leading zeros
        const startMonthStr = startMonth.toString().padStart(2, '0');
        const endMonthStr = endMonth.toString().padStart(2, '0');

        // Generate an array of expected months in the format 'MM-YYYY'
        const expectedMonths = [];
        for (let month = startMonth; month <= endMonth; month++) {
            expectedMonths.push(`${month.toString().padStart(2, '0')}-${year}`);
        }

        // Check if all expected months are present in the date array
        const allMonthsPresent = expectedMonths.every(month => dateArray.includes(month));

        // Check if there are no additional months in the date array
        const noExtraMonths = dateArray.every(month => expectedMonths.includes(month));

        return allMonthsPresent && noExtraMonths;
    }
    const updateHRData_STT_Switch = (value) => {
        if (value !== null) {
            setToggle((prev) => { return { ...prev, hr: value } })
            let site_ids = []
            console.log(value)
            siteRawList.forEach((i) => {

                if (i.id === selected.hr.country || selected.hr.country === 0) {
                    i.locationTwos.forEach((j) => {

                        if (j.id === selected.hr.city || selected.hr.city === 0) {
                            j.locationThrees.forEach((k) => {
                                if (k.id === selected.site || selected.site === 0) {
                                    site_ids.push(k.id)
                                }




                            })
                        }
                    })
                }
            })

            updateHRData_STT(site_ids, selected.year, value)
        }


    }

    const updateHRData_STT = (sid, yr, toggle) => {
        console.log(toggle)
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.tier3_id) && (i.type === 1 || i.type === 2 || i.type === 3) && i.frequency === 5 && (toggle === 1 ? checkRPSpan(i.reporting_period, yr, 1, 6) : checkRPSpan(i.reporting_period, yr, 7, 12)) && [275].includes(i.dcfId) })
        setApprovedList((prev) => { return { ...prev, hr: loc_approvedList } })
        // setApprovedListBK((prev) => { return { ...prev, hr: loc_approvedList } })
        let sify = filterResponseBySIFY(loc_approvedList, 5, site_ids, [275], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        let added = [null, null, null, null, null, null, null, null, null, null, null, null]
        let ignore1 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 275 && r.type === 1 }).map(r => r.tier3_id)
        console.log(month_obj, loc_approvedList)
        let added_month = [], pending = [], month_ = ['jan', 'jul']

        Object.keys(month_obj).forEach((key, ind) => {

            added_month.push(key.toLowerCase())
            let month_case = ''
            if (key.toLowerCase() === 'jan') {
                month_case = "01"
            } else {
                month_case = '07'
            }
            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 275 && i.reporting_period[0].split('-')[0] === month_case).length === 0 && !ignore1.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 275), site: sitelist.find(i => i.id === site_id), reporting_period: month_case === '01' ? 'Jan-' + yr + ' to ' + ' Jun-' + yr : 'Jul-' + yr + ' to ' + ' Dec-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(month_case === '01' ? 'Jul-' + yr : 'Jan-' + (yr + 1), 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }

            })

        })

        if (Object.keys(month_obj).length !== 0) {
            let male = 0, female = 0, male_30a = 0, male_30t50 = 0, male_50a = 0, female_30a = 0, female_30t50 = 0, female_50a = 0
                , s_male = 0, m_male = 0, n_male = 0, s_female = 0, m_female = 0, n_female = 0, s_30a = 0, s_30t50 = 0, s_50a = 0, m_30a = 0, m_30t50 = 0, m_50a = 0, n_30a = 0, n_30t50 = 0, n_50a = 0, total = 0
            let type = ['Senior Management', 'Middle Management', 'Non-Management']

            site_ids.filter(i => !ignore1.includes(i)).forEach(site_id => {
                let lastIndex = Object.keys(month_obj).findIndex(key => key.toLowerCase() === 'jul' && month_obj[key].filter(i => i.site === site_id && i.dcfId === 275).length > 0);
                let lastIndex2 = Object.keys(month_obj).findIndex(key => key.toLowerCase() === 'jan' && month_obj[key].filter(i => i.site === site_id && i.dcfId === 275).length > 0);
                console.log(lastIndex, lastIndex2)

                if (toggle === 2) {

                    if (lastIndex !== -1) {
                        total = total + 1;
                        let data = month_obj[Object.keys(month_obj)[lastIndex]].filter(i => i.site === site_id && i.dcfId === 275)[0].response[0]
                        console.log(data)
                        male += data.DPAN600 + data.DPAN602 + data.DPAN604 + data.DPAN606 + data.DPAN608 + data.DPAN610 + data.DPAN611 + data.DPAN612 + data.DPAN613 + data.DPAN615 + data.DPAN617
                        female += data.DPAN601 + data.DPAN603 + data.DPAN605 + data.DPAN607 + data.DPAN609 + data.DPAN628 + data.DPAN629 + data.DPAN630 + data.DPAN614 + data.DPAN616 + data.DPAN618

                        male_30a += data.DPAN610
                        male_30t50 += data.DPAN611
                        male_50a += data.DPAN612
                        female_30a += data.DPAN628
                        female_30t50 += data.DPAN629
                        female_50a += data.DPAN630

                        s_male += data.DPAN613
                        m_male += data.DPAN615
                        n_male += data.DPAN617
                        s_female += data.DPAN614
                        m_female += data.DPAN616
                        n_female += data.DPAN618

                        s_30a += data.DPAN619
                        m_30a += data.DPAN622
                        n_30a += data.DPAN625
                        s_30t50 += data.DPAN620
                        m_30t50 += data.DPAN623
                        n_30t50 += data.DPAN626
                        s_50a += data.DPAN621
                        m_50a += data.DPAN624
                        n_50a += data.DPAN627

                    }
                } else if (toggle === 1) {

                    if (lastIndex2 !== -1) {
                        total = total + 1;
                        let data = month_obj[Object.keys(month_obj)[lastIndex2]].filter(i => i.site === site_id && i.dcfId === 275)[0].response[0]
                        male += data.DPAN600 + data.DPAN602 + data.DPAN604 + data.DPAN606 + data.DPAN608 + data.DPAN610 + data.DPAN611 + data.DPAN612 + data.DPAN613 + data.DPAN615 + data.DPAN617
                        female += data.DPAN601 + data.DPAN603 + data.DPAN605 + data.DPAN607 + data.DPAN609 + data.DPAN628 + data.DPAN629 + data.DPAN630 + data.DPAN614 + data.DPAN616 + data.DPAN618

                        male_30a += data.DPAN610
                        male_30t50 += data.DPAN611
                        male_50a += data.DPAN612
                        female_30a += data.DPAN628
                        female_30t50 += data.DPAN629
                        female_50a += data.DPAN630

                        s_male += data.DPAN613
                        m_male += data.DPAN615
                        n_male += data.DPAN617
                        s_female += data.DPAN614
                        m_female += data.DPAN616
                        n_female += data.DPAN618

                        s_30a += data.DPAN619
                        m_30a += data.DPAN622
                        n_30a += data.DPAN625
                        s_30t50 += data.DPAN620
                        m_30t50 += data.DPAN623
                        n_30t50 += data.DPAN626
                        s_50a += data.DPAN621
                        m_50a += data.DPAN624
                        n_50a += data.DPAN627
                    }
                }
            })
            console.log(total, site_ids.filter(i => !ignore1.includes(i)).length)
            let dataset1 = [{
                label: 'Male',
                backgroundColor: color[3],

                data: [male_50a, male_30t50, male_30a]
            },
            {
                label: 'Female',
                backgroundColor: color[1],

                borderWidth: 1,
                data: [female_50a, female_30t50, female_30a]
            }]

            let dataset3 = [{
                label: 'Senior Management',
                backgroundColor: color[0],
                data: [s_male, s_female]
            },
            {
                label: 'Middle Management',
                backgroundColor: color[1],

                data: [m_male, m_female]
            }, {
                label: 'Non-Management',
                backgroundColor: color[3],

                data: [n_male, n_female]
            },
            ]
            let dataset4 = [{
                label: 'Senior Management',
                backgroundColor: color[0],
                data: [s_30a, s_30t50, s_50a]
            },
            {
                label: 'Middle Management',
                backgroundColor: color[1],

                data: [m_30a, m_30t50, m_50a]
            }, {
                label: 'Non-Management',
                backgroundColor: color[3],

                data: [n_30a, n_30t50, n_50a]
            },
            ]
            setTimeout(() => {
                if (male === 0 && female === 0) {
                    showDummyGraph('hr1', 'No Data Found')
                    showDummyGraph('hr2', 'No Data Found')
                    showDummyGraph('hr3', 'No Data Found')
                    showDummyGraph('hr4', 'No Data Found')
                } else {
                    showDoughnutChart('hr1', calculatePercentages([male, female]), ['Male', 'Female'], { data: [male, female] })
                    showHorizontalBarChart(dataset1, 'hr2', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })

                    // showHorizontalBarChart(dataset3, 'hr3', { labels: ['Male', 'Female'] })
                    showStackedBarChartNoIC(dataset3, 'hr3', { labels: ['Male', 'Female'], ic: month_obj[toggle === 1 ? 'Jan' : 'Jul'].filter(i => i.dcfId === 275).length === (site_ids.length - ignore1.length) ? getStatusCode(month_obj[toggle === 1 ? 'Jan' : 'Jul'].filter(i => i.dcfId === 275)) : 0 })
                    showStackedBarChartNoIC(dataset4, 'hr4', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'], ic: month_obj[toggle === 1 ? 'Jan' : 'Jul'].filter(i => i.dcfId === 275).length === (site_ids.length - ignore1.length) ? getStatusCode(month_obj[toggle === 1 ? 'Jan' : 'Jul'].filter(i => i.dcfId === 275)) : 0 })
                }

            }, 200)

        } else {
            setTimeout(() => {
                showDummyGraph('hr1', 'No Data Found')
                showDummyGraph('hr2', 'No Data Found')
                showDummyGraph('hr3', 'No Data Found')
                showDummyGraph('hr4', 'No Data Found')
            }, 500)


        }

        month_.forEach((i, x) => {

            if (!added_month.includes(i) && toggle === x + 1) {

                site_ids.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 275), site: sitelist.find(i => i.id === j), reporting_period: x === 0 ? 'Jan-' + yr + ' to ' + 'Jun-' + yr : 'Jul-' + yr + ' to ' + 'Dec-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(x === 0 ? 'Jul-' + yr : 'Jan-' + (yr + 1), 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                        // pending.push({ dcf: dcflist.find(i => i.id === 257), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                })

            }
        })

        setPendingList((prev) => { return { ...prev, hr: pending } })

    }
    const updateHRData_Enter_STT = (sid, yr) => {
        console.log(sid)
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.tier3_id) && (i.type === 1 || i.type === 2 || i.type === 3) && i.frequency === 4 && (checkRPSpan(i.reporting_period, yr, 1, 12)) && [277].includes(i.dcfId) })
        setApprovedList((prev) => { return { ...prev, hr_enter: loc_approvedList } })
        // setApprovedListBK((prev) => { return { ...prev, hr: loc_approvedList } })
        let sify = filterResponseBySIFY(loc_approvedList, 4, site_ids, [277], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        let added = [null, null, null, null, null, null, null, null, null, null, null, null]
        let ignore1 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 277 && r.type === 1 }).map(r => r.tier3_id)
        console.log(month_obj, loc_approvedList)
        let added_month = [], pending = [], month_ = ['jan']

        Object.keys(month_obj).forEach((key, ind) => {

            added_month.push(key.toLowerCase())

            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 277 && i.reporting_period[0].split('-')[0] === '01').length === 0 && !ignore1.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 277), site: sitelist.find(i => i.id === site_id), reporting_period: 'Jan-' + yr + ' to ' + ' Dec-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat('Jan-' + (yr + 1), 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }

            })

        })

        if (Object.keys(month_obj).length !== 0) {
            let male = 0, female = 0, male_30a = 0, male_30t50 = 0, male_50a = 0, female_30a = 0, female_30t50 = 0, female_50a = 0
                , s_male = 0, m_male = 0, n_male = 0, s_female = 0, m_female = 0, n_female = 0, s_30a = 0, s_30t50 = 0, s_50a = 0, m_30a = 0, m_30t50 = 0, m_50a = 0, n_30a = 0, n_30t50 = 0, n_50a = 0
            let type = ['Senior Management', 'Middle Management', 'Non-Management']
            site_ids.forEach(site_id => {
                let lastIndex = Object.keys(month_obj).findIndex(key => key.toLowerCase() === 'jan' && month_obj[key].filter(i => i.site === site_id && i.dcfId === 277).length > 0);

                if (lastIndex !== -1) {
                    let data = month_obj[Object.keys(month_obj)[lastIndex]].filter(i => i.site === site_id && i.dcfId === 277)[0].response[0]
                    console.log(data)
                    male += data.DPAN631 + data.DPAN633 + data.DPAN635 + data.DPAN637 + data.DPAN639 + data.DPAN641 + data.DPAN642 + data.DPAN643 + data.DPAN647 + data.DPAN649 + data.DPAN651
                    female += data.DPAN632 + data.DPAN634 + data.DPAN636 + data.DPAN638 + data.DPAN640 + data.DPAN644 + data.DPAN645 + data.DPAN646 + data.DPAN648 + data.DPAN650 + data.DPAN652

                    male_30a += data.DPAN641
                    male_30t50 += data.DPAN642
                    male_50a += data.DPAN643
                    female_30a += data.DPAN644
                    female_30t50 += data.DPAN645
                    female_50a += data.DPAN646

                    s_male += data.DPAN647
                    m_male += data.DPAN649
                    n_male += data.DPAN651
                    s_female += data.DPAN648
                    m_female += data.DPAN650
                    n_female += data.DPAN652

                    s_30a += data.DPAN653
                    m_30a += data.DPAN656
                    n_30a += data.DPAN659
                    s_30t50 += data.DPAN654
                    m_30t50 += data.DPAN657
                    n_30t50 += data.DPAN660
                    s_50a += data.DPAN655
                    m_50a += data.DPAN658
                    n_50a += data.DPAN661

                }
            })

            let dataset1 = [{
                label: 'Male',
                backgroundColor: color[3],

                data: [male_50a, male_30t50, male_30a]
            },
            {
                label: 'Female',
                backgroundColor: color[1],

                borderWidth: 1,
                data: [female_50a, female_30t50, female_30a]
            }]

            let dataset3 = [{
                label: 'Senior Management',
                backgroundColor: color[0],
                data: [s_male, s_female]
            },
            {
                label: 'Middle Management',
                backgroundColor: color[1],

                data: [m_male, m_female]
            }, {
                label: 'Non-Management',
                backgroundColor: color[3],

                data: [n_male, n_female]
            },
            ]
            let dataset4 = [{
                label: 'Senior Management',
                backgroundColor: color[0],
                data: [s_30a, s_30t50, s_50a]
            },
            {
                label: 'Middle Management',
                backgroundColor: color[1],

                data: [m_30a, m_30t50, m_50a]
            }, {
                label: 'Non-Management',
                backgroundColor: color[3],

                data: [n_30a, n_30t50, n_50a]
            },
            ]
            setTimeout(() => {
                showDoughnutChart('hr_enter_1', calculatePercentages([male, female]), ['Male', 'Female'], { data: [male, female] })
                showHorizontalBarChart(dataset1, 'hr_enter_2', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })
                // showHorizontalBarChart(dataset3, 'hr3', { labels: ['Male', 'Female'] })
                showStackedBarChartNoIC(dataset3, 'hr_enter_3', { labels: ['Male', 'Female'], ic: month_obj['Jan'].filter(i => i.dcfId === 277).length === (site_ids.length - ignore1.length) ? getStatusCode(month_obj['Jan'].filter(i => i.dcfId === 277)) : 0 })
                showStackedBarChartNoIC(dataset4, 'hr_enter_4', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'], ic: month_obj['Jan'].filter(i => i.dcfId === 277).length === (site_ids.length - ignore1.length) ? getStatusCode(month_obj['Jan'].filter(i => i.dcfId === 277)) : 0 })
            }, 200)

        } else {
            setTimeout(() => {
                showDummyGraph('hr_enter_1', 'No Data Found')
                showDummyGraph('hr_enter_2', 'No Data Found')
                showDummyGraph('hr_enter_3', 'No Data Found')
                showDummyGraph('hr_enter_4', 'No Data Found')
            }, 500)
        }

        month_.forEach((i, x) => {

            if (!added_month.includes(i)) {

                site_ids.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 277), site: sitelist.find(i => i.id === j), reporting_period: 'Jan-' + yr + ' to ' + 'Dec-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat('Jan-' + (yr + 1), 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                    }
                })

            }
        })

        setPendingList((prev) => { return { ...prev, hr_enter: pending } })

    }
    const updateHRData_Leave_STT = (sid, yr) => {
        console.log(sid)
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.tier3_id) && (i.type === 1 || i.type === 2 || i.type === 3) && i.frequency === 4 && (checkRPSpan(i.reporting_period, yr, 1, 12)) && [278].includes(i.dcfId) })
        setApprovedList((prev) => { return { ...prev, hr_leave: loc_approvedList } })
        // setApprovedListBK((prev) => { return { ...prev, hr: loc_approvedList } })
        let sify = filterResponseBySIFY(loc_approvedList, 4, site_ids, [278], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        let added = [null, null, null, null, null, null, null, null, null, null, null, null]
        let ignore1 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 278 && r.type === 1 }).map(r => r.tier3_id)
        console.log(month_obj, loc_approvedList)
        let added_month = [], pending = [], month_ = ['jan']

        Object.keys(month_obj).forEach((key, ind) => {

            added_month.push(key.toLowerCase())

            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 278 && i.reporting_period[0].split('-')[0] === '01').length === 0 && !ignore1.includes(site_id)) {
                    pending.push({ dcf: dcflist.find(i => i.id === 278), site: sitelist.find(i => i.id === site_id), reporting_period: 'Jan-' + yr + ' to ' + ' Dec-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat('Jan-' + (yr + 1), 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }

            })

        })

        if (Object.keys(month_obj).length !== 0) {
            let male = 0, female = 0, male_30a = 0, male_30t50 = 0, male_50a = 0, female_30a = 0, female_30t50 = 0, female_50a = 0
                , s_male = 0, m_male = 0, n_male = 0, s_female = 0, m_female = 0, n_female = 0, s_30a = 0, s_30t50 = 0, s_50a = 0, m_30a = 0, m_30t50 = 0, m_50a = 0, n_30a = 0, n_30t50 = 0, n_50a = 0
            let type = ['Senior Management', 'Middle Management', 'Non-Management']
            site_ids.forEach(site_id => {
                let lastIndex = Object.keys(month_obj).findIndex(key => key.toLowerCase() === 'jan' && month_obj[key].filter(i => i.site === site_id && i.dcfId === 278).length > 0);

                if (lastIndex !== -1) {
                    let data = month_obj[Object.keys(month_obj)[lastIndex]].filter(i => i.site === site_id && i.dcfId === 278)[0].response[0]
                    console.log(data)
                    male += data.DPAN662 + data.DPAN664 + data.DPAN666 + data.DPAN668 + data.DPAN670 + data.DPAN672 + data.DPAN673 + data.DPAN674 + data.DPAN678 + data.DPAN680 + data.DPAN682
                    female += data.DPAN663 + data.DPAN665 + data.DPAN667 + data.DPAN669 + data.DPAN671 + data.DPAN675 + data.DPAN676 + data.DPAN677 + data.DPAN679 + data.DPAN681 + data.DPAN683

                    male_30a += data.DPAN672
                    male_30t50 += data.DPAN673
                    male_50a += data.DPAN674
                    female_30a += data.DPAN675
                    female_30t50 += data.DPAN676
                    female_50a += data.DPAN677

                    s_male += data.DPAN678
                    m_male += data.DPAN680
                    n_male += data.DPAN682
                    s_female += data.DPAN679
                    m_female += data.DPAN681
                    n_female += data.DPAN683

                    s_30a += data.DPAN684
                    m_30a += data.DPAN687
                    n_30a += data.DPAN690
                    s_30t50 += data.DPAN685
                    m_30t50 += data.DPAN688
                    n_30t50 += data.DPAN691
                    s_50a += data.DPAN686
                    m_50a += data.DPAN689
                    n_50a += data.DPAN692

                }
            })

            let dataset1 = [{
                label: 'Male',
                backgroundColor: color[3],

                data: [male_50a, male_30t50, male_30a]
            },
            {
                label: 'Female',
                backgroundColor: color[1],

                borderWidth: 1,
                data: [female_50a, female_30t50, female_30a]
            }]

            let dataset3 = [{
                label: 'Senior Management',
                backgroundColor: color[0],
                data: [s_male, s_female]
            },
            {
                label: 'Middle Management',
                backgroundColor: color[1],

                data: [m_male, m_female]
            }, {
                label: 'Non-Management',
                backgroundColor: color[3],

                data: [n_male, n_female]
            },
            ]
            let dataset4 = [{
                label: 'Senior Management',
                backgroundColor: color[0],
                data: [s_30a, s_30t50, s_50a]
            },
            {
                label: 'Middle Management',
                backgroundColor: color[1],

                data: [m_30a, m_30t50, m_50a]
            }, {
                label: 'Non-Management',
                backgroundColor: color[3],

                data: [n_30a, n_30t50, n_50a]
            },
            ]
            setTimeout(() => {
                showDoughnutChart('hr_leave_1', calculatePercentages([male, female]), ['Male', 'Female'], { data: [male, female] })
                showHorizontalBarChart(dataset1, 'hr_leave_2', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })
                // showHorizontalBarChart(dataset3, 'hr3', { labels: ['Male', 'Female'] })
                showStackedBarChartNoIC(dataset3, 'hr_leave_3', { labels: ['Male', 'Female'], ic: month_obj['Jan'].filter(i => i.dcfId === 278).length === (site_ids.length - ignore1.length) ? getStatusCode(month_obj['Jan'].filter(i => i.dcfId === 278)) : 0 })
                showStackedBarChartNoIC(dataset4, 'hr_leave_4', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'], ic: month_obj['Jan'].filter(i => i.dcfId === 278).length === (site_ids.length - ignore1.length) ? getStatusCode(month_obj['Jan'].filter(i => i.dcfId === 278)) : 0 })
            }, 200)

        } else {
            setTimeout(() => {
                showDummyGraph('hr_leave_1', 'No Data Found')
                showDummyGraph('hr_leave_2', 'No Data Found')
                showDummyGraph('hr_leave_3', 'No Data Found')
                showDummyGraph('hr_leave_4', 'No Data Found')
            }, 500)
        }

        month_.forEach((i, x) => {

            if (!added_month.includes(i)) {

                site_ids.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 278), site: sitelist.find(i => i.id === j), reporting_period: 'Jan-' + yr + ' to ' + 'Dec-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat('Jan-' + (yr + 1), 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                    }
                })

            }
        })

        setPendingList((prev) => { return { ...prev, hr_leave: pending } })

    }
    const updateWaterGraph = (sid, yr) => {
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.tier3_id) && (i.type === 1 || i.type === 2 || i.type === 3) && i.reporting_period[0].split('-')[1] === yr.toString() && [246, 247].includes(i.dcfId) })
        setApprovedList((prev) => { return { ...prev, water: loc_approvedList } })
        // setApprovedListBK((prev) => { return { ...prev, water: loc_approvedList } })
        let sify = filterResponseBySIFY(loc_approvedList, 1, site_ids, [246, 247], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        let withdraw_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let disposal_ic = [null, null, null, null, null, null, null, null, null, null, null, null]

        let water_type = ["Surface water", "Ground Water", "Sea Water", "Produced Water", "Third-Party Water"]
        let water_withdraw_type_data = [], water_disposal_type_data = []
        // DPAN0049
        const DPAN0048Options = [
            { name: "Surface water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: "Produced Water", id: 4 },
            { name: "Third-Party Water", id: 5 },
        ];
        // DPAN0053
        const DPAN0052Options = [
            { name: "Surface water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: "Produced Water", id: 4 },
            { name: "Third-Party Water", id: 5 },
        ];
        let pending = []
        let added_month = []
        let ignore = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && (r.dcfId === 246 || r.dcfId === 247) && r.type === 1 }).map(r => r.tier3_id)
        let ignore1 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 246 && r.type === 1 }).map(r => r.tier3_id)
        let ignore2 = userdcfass.filter((r) => { return site_ids.includes(r.tier3_id) && r.dcfId === 247 && r.type === 1 }).map(r => r.tier3_id)

        Object.keys(month_obj).forEach(key => {
            added_month.push(key.toUpperCase())
            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 246).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 246), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
                if (month_obj[key].filter(i => i.site === site_id && i.dcfId === 247).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 247), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
            })
            let month_ind = months.findIndex(i => i.toLowerCase() === key.toLowerCase())
            withdraw_ic[month_ind] = month_obj[key].filter(i => i.dcfId === 246).length === (site_ids.length - ignore1.length) ? getStatusCode(month_obj[key].filter(i => i.dcfId === 246)) : 0
            disposal_ic[month_ind] = month_obj[key].filter(i => i.dcfId === 247).length === (site_ids.length - ignore2.length) ? getStatusCode(month_obj[key].filter(i => i.dcfId === 247)) : 0
            month_obj[key].forEach((i) => {
                let total = 0
                if (i.dcfId === 246) {
                    i.response.forEach((j) => {
                        let ftindex = water_withdraw_type_data.findIndex(i => { return i.name === DPAN0048Options.find(i => i.id === j.DPAN0048).name && i.order === month_ind })
                        if (ftindex !== -1) {
                            water_withdraw_type_data[ftindex].qty = water_withdraw_type_data[ftindex].qty + parseFloat(j.DPAN0049)
                        } else {
                            water_withdraw_type_data.push({ name: DPAN0048Options.find(i => i.id === j.DPAN0048).name, qty: parseFloat(j.DPAN0049), order: month_ind })
                        }
                    })
                }
                if (i.dcfId === 247) {
                    i.response.forEach((j) => {
                        let ftindex = water_disposal_type_data.findIndex(i => { return i.name === DPAN0052Options.find(i => i.id === j.DPAN0052).name && i.order === month_ind })
                        if (ftindex !== -1) {
                            water_disposal_type_data[ftindex].qty = water_disposal_type_data[ftindex].qty + parseFloat(j.DPAN0053)
                        } else {
                            water_disposal_type_data.push({ name: DPAN0052Options.find(i => i.id === j.DPAN0052).name, qty: parseFloat(j.DPAN0053), order: month_ind })
                        }
                    })
                }


            })
        })

        months.forEach((i) => {

            if (!added_month.includes(i)) {
                console.log(i, added_month)
                site_ids.forEach((j) => {
                    if (!ignore1.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 246), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                    if (!ignore2.includes(j)) {
                        pending.push({ dcf: dcflist.find(i => i.id === 247), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                    }
                })

            }
        })
        water_type.forEach((item) => {
            let indexes = water_withdraw_type_data.filter(i => i.name === item).map(j => j.order)
            for (let i = 0; i < 12; i++) {
                if (!indexes.includes(i)) {
                    water_withdraw_type_data.push({ name: item, order: i, qty: 0 })
                }
            }
        })

        water_type.forEach((item) => {
            let indexes = water_disposal_type_data.filter(i => i.name === item).map(j => j.order)
            for (let i = 0; i < 12; i++) {
                if (!indexes.includes(i)) {
                    water_disposal_type_data.push({ name: item, order: i, qty: 0 })
                }
            }
        })
        let datasets1 = [], datasets2 = []
        let colors = ['#D7E7EF', '#93D3F1', '#41C7F3', '#0797D6', '#1F60AB']
        water_type.forEach((label, ind) => {
            datasets1.push({
                label: label,
                data: water_withdraw_type_data.filter(i => i.name === label).sort((a, b) => { return a.order - b.order }).map(j => j.qty),
                backgroundColor: colors[ind]
            })
        })
        water_type.forEach((label, ind) => {
            datasets2.push({
                label: label,
                data: water_disposal_type_data.filter(i => i.name === label).sort((a, b) => { return a.order - b.order }).map(j => j.qty),
                backgroundColor: colors[ind]
            })
        })
        setPendingList((prev) => { return { ...prev, water: pending } })
        console.log(water_disposal_type_data, water_withdraw_type_data, datasets1, datasets2)
        let total_withdrawl = datasets1.map(i => i.data.reduce((a, b) => { return a + b }, 0)).reduce((a, b) => { return a + b }, 0)
        let total_disposal = datasets2.map(i => i.data.reduce((a, b) => { return a + b }, 0)).reduce((a, b) => { return a + b }, 0)
        let datasets3 = [null, null, null, null, null]
        let datasets4 = [null, null, null, null, null]
        if (total_withdrawl > 0) {
            datasets3 = datasets1.map(i => i.data.reduce((a, b) => { return a + b }, 0)).map(i => parseFloat(((i / total_withdrawl) * 100).toFixed(0)))
        }
        if (total_disposal > 0) {
            datasets4 = datasets2.map(i => i.data.reduce((a, b) => { return a + b }, 0)).map(i => parseFloat(((i / total_disposal) * 100).toFixed(0)))
        }
        setTimeout(() => {
            showStackedBarChart(datasets1, 'water_1', { measure: '', labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: withdraw_ic, type: 1, measure: 'Million Litre' })
            showStackedBarChart(datasets2, 'water_3', { measure: '', labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], incomplete: disposal_ic, type: 1, measure: 'Million Litre' })
            showPieChart('water_2', datasets3, water_type, { hideDataLabel: false, measure: " %" })
            showPieChart('water_4', datasets4, water_type, { hideDataLabel: false, measure: " %" })
        }, 500)

    }
    const isDateWithinPeriod = (rp, period) => {
        // Convert date strings to Luxon DateTime objects
        const rpDates = rp.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));
        const periodDates = period.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));

        // Find the matching dates in rp within the period
        const matchingDates = rpDates.filter(rpDate =>
            periodDates.some(periodDate => rpDate.toMillis() === periodDate.toMillis())
        );

        return matchingDates.length > 0 ? { match: true, dates: matchingDates.map(date => date.toFormat('MM-yyyy')) } : { match: false, dates: null };
    };
    const findIndexByDate = (array, dateToFind) => {
        const targetDate = DateTime.fromFormat(dateToFind, 'MM-yyyy');

        if (array.length === 1) {
            return 0; // If array length is 1, return index 0
        }

        let foundIndex = -1;

        // Check for an object with both start and end not null and matching the passed date
        const matchedObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            const endDate = DateTime.fromISO(obj.end);

            return startDate <= targetDate && targetDate <= endDate;
        });

        if (matchedObject) {
            return array.indexOf(matchedObject);
        }

        // Check for an object with end as null and start date lesser than passed date
        const endIsNullObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            return obj.end === null && startDate <= targetDate;
        });

        if (endIsNullObject) {
            return array.indexOf(endIsNullObject);
        }

        // If none of the above conditions match, find the object with the lesser start date
        let lesserStartDateObject = array.reduce((prev, curr) => {
            const prevStartDate = DateTime.fromISO(prev.start);
            const currStartDate = DateTime.fromISO(curr.start);
            return prevStartDate < currStartDate ? prev : curr;
        });

        return array.indexOf(lesserStartDateObject);
    };
    const submitPUE = (item) => {
        Swal.fire({
            html: '<span class="fs-16 fw-5">Are you sure you want to authorize data from this submission? You will not be able to make any further changes or edits.</span>',
            showCancelButton: true,
            backdrop: false,
            confirmButtonColor: 'green',

        })
    }
    function getPercentageOf2Array(r, nr) {
        const updatedR = [];
        const updatedNR = [];

        for (let i = 0; i < r.length; i++) {
            if (r[i] !== null || nr[i] !== null) {
                const total = r[i] + nr[i];
                updatedR.push(Math.round((r[i] / total) * 100));
                updatedNR.push(Math.round((nr[i] / total) * 100));
            } else {
                updatedR.push(null);
                updatedNR.push(null);
            }
        }

        return { renew: updatedR, nonrenew: updatedNR };
    }
    const getBetweenMonths = (year) => {
        const months = [];
        for (let i = 1; i <= 12; i++) {
            const month = DateTime.fromObject({ year, month: i });
            const formattedMonth = month.toFormat('MM-yyyy');
            months.push(formattedMonth);
        }
        return months;
    }
    const getEmissionFactorCalculation = (item, year) => {
        let betweenMonths = getBetweenMonths(year)
        let month_data = []
        if (item.dcfId === 11) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {

                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                console.log('Matched_SC', standard_index)
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            console.log('Dates_SC', dates)
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0130 && i.subcategory2 === sc.DPA0131 && i.subcategory3 === sc.DPA0132 })
                                        console.log(sc_index, sc)
                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0336) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                            console.log(sc.DPA0336, ef)
                                            let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0131 })
                                            let unit_index = subcat.three.findIndex((i) => { return i.id === sc.DPA0132 })
                                            let fuel_cat_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0130 })

                                            let fuel_type = 'Other', usage = 0, r = 0, nr = 0
                                            if (fuel_type_index !== -1) {
                                                fuel_type = subcat.two[fuel_type_index].title
                                            }
                                            if (unit_index !== -1) {
                                                let unit_type = subcat.three[fuel_type_index].title

                                                if (unit_type.includes('ton')) {
                                                    if (fuel_cat_index !== -1) {
                                                        let cat_type = subcat.one[fuel_cat_index].title

                                                        if (cat_type.trim().toLowerCase().includes('bio')) {
                                                            r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))
                                                        } else {

                                                            nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))

                                                        }
                                                    }
                                                    usage = parseFloat((sc.DPA0336 / 0.81).toFixed(3))

                                                } else {
                                                    if (fuel_cat_index !== -1) {

                                                        let cat_type = subcat.one[fuel_cat_index].title
                                                        if (cat_type.trim().toLowerCase().includes('bio')) {
                                                            r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                        } else {

                                                            nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                        }
                                                    }
                                                    usage = parseFloat(sc.DPA0336)
                                                }
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.fuel_type === fuel_type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, fuel_type, usage, r, nr })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].usage += usage
                                                month_data[month_index].r += r
                                                month_data[month_index].nr += nr
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 10) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)
                                console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 5 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0136 })
                                        console.log(sc_index, sc.DPA0136, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                        if (sc_index !== -1) {
                                            console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0138) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                            console.log(sc.DPA0138, ef)
                                            let gas_type_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0136 })
                                            let gas_type = 'Other'
                                            if (gas_type_index !== -1) {
                                                gas_type = subcat.one[gas_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.gas_type === gas_type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, gas_type, usage: parseFloat(sc.DPA0138) })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].usage += parseFloat(sc.DPA0138)
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 16) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 11 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0287 })

                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0288) * parseFloat(sc.DPA0289) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            let item_class_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0287 })
                                            let item_class = 'Other'
                                            if (item_class_index !== -1) {
                                                item_class = subcat.one[item_class_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.item_class === item_class })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, item_class })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 15) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    if (!sc.DP_MODE) {
                                        let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                        if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                            let sc_index = -1
                                            if (sc.DPA0141 === 2) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 1 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 7 })
                                            } else if (sc.DPA0141 === 13) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 51 })
                                            } else if (sc.DPA0141 === 19) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 75 })

                                            }
                                            if (sc_index !== -1) {
                                                let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                                let ghg = parseFloat((((parseFloat(sc.DPA0143) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                                let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0141 })
                                                let fuel_type = 'Other'
                                                if (fuel_type_index !== -1) {
                                                    fuel_type = subcat.two[fuel_type_index].title
                                                }
                                                let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.type === 'byFuel' && i.fuel_type === fuel_type })
                                                if (month_index === -1) {
                                                    month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, fuel_type, type: 'byFuel' })
                                                } else {
                                                    month_data[month_index].ghg += ghg
                                                }

                                            }

                                        }
                                    } else {
                                        let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === sc.DPGMode })
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                        if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                            let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0140 && i.subcategory2 === sc.DPA0139 && i.subcategory3 === sc.DPA0141 && i.subcategory4 === sc.DPA0339 })

                                            if (sc_index !== -1) {
                                                let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                                let ghg = parseFloat((((parseFloat(sc.DPA0144) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                                let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0139 })
                                                let fuel_type = 'Other'
                                                if (fuel_type_index !== -1) {
                                                    fuel_type = subcat.two[fuel_type_index].title
                                                }
                                                let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.fuel_type === fuel_type && i.type === 'byDistance' })
                                                if (month_index === -1) {
                                                    month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, fuel_type, type: 'byDistance' })
                                                } else {
                                                    month_data[month_index].ghg += ghg
                                                }

                                            }

                                        }
                                    }

                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 257) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {

                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)
                                console.log(sc, item.id)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i, j) => { return j === 0 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN095 && i.subcategory2 === sc.DPAN096 && i.subcategory3 === sc.DPAN099 })
                                        console.log(sc_index)
                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = 0

                                            console.log(sc.DPA0138, ef)
                                            let source_index = subcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                            let type_index = subcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                            let type = 'Other', source_type = 'Other', r = 0, nr = 0
                                            if (type_index !== -1) {
                                                type = subcat.one[type_index].title
                                            }
                                            if (source_index !== -1) {

                                                source_type = subcat.two[source_index].title
                                                console.log(source_type, sc.DPAN096)
                                                if (source_type.trim().toLowerCase().includes('non')) {
                                                    ghg = parseFloat((((parseFloat(sc.DPAN098) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                                    nr = parseFloat(sc.DPAN098 / 1000)
                                                } else {
                                                    r = parseFloat(sc.DPAN098 / 1000)
                                                }
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 2 && i.source_type === source_type && i.type === type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 2, ghg, type, source_type, r, nr })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].r += r
                                                month_data[month_index].nr += nr
                                            }

                                        } else {

                                            if (subcat.one.map(i => i.id).includes(sc.DPAN095) && subcat.two.map(i => i.id).includes(sc.DPAN096) && subcat.three.map(i => i.id).includes(sc.DPAN099)) {
                                                let source_type = '', r = 0, nr = 0, type = ''
                                                let source_index = subcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                                let type_index = subcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                                type = subcat.one[type_index].title
                                                source_type = subcat.two[source_index].title
                                                if (!source_type.trim().toLowerCase().includes('non')) {
                                                    r = parseFloat(sc.DPAN098 / 1000)
                                                }
                                                let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 2 && i.source_type === source_type && i.type === type })
                                                if (month_index === -1) {
                                                    month_data.push({ year: rpm.split('-')[1], month: rpm, ef: {}, scope: 2, ghg: 0, type, source_type, r, nr })
                                                } else {
                                                    month_data[month_index].ghg += 0
                                                    month_data[month_index].r += r
                                                    month_data[month_index].nr += nr
                                                }
                                            }
                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        }
        console.log(month_data, item.dcfId)
        return month_data
    }

    const getApprovalStatusAnnually = (list, dcf_ids, sid) => {
        console.log(list)
        let month_range = ['01-' + selected.year, '02-' + selected.year, '03-' + selected.year, '04-' + selected.year, '05-' + selected.year, '06-' + selected.year, '07-' + selected.year, '08-' + selected.year, '09-' + selected.year, '10-' + selected.year, '11-' + selected.year, '12-' + selected.year]
        let check_month = '01'

        if (list.length !== 0) {
            let filtered_data = list.filter((i) => { return dcf_ids.includes(i.dcfId) && i.site === sid && check_month === i.reporting_period[0].split('-')[0] })

            if (filtered_data.length !== 0) {
                console.log(month_range, filtered_data.length === dcf_ids.length * 1 ? filtered_data.filter(i => i.type === 3).length !== 0 ? 2 : 1 : 0)
                return filtered_data.length === dcf_ids.length * 1 ? filtered_data.filter(i => i.type === 3).length !== 0 ? 2 : 1 : 0
            } else {
                return 0
            }

        } else {
            return 0
        }

    }
    const approveListAnnually = async (list, dcf_ids, sid) => {
        console.log(list)
        let month_range = ['01-' + selected.year, '02-' + selected.year, '03-' + selected.year, '04-' + selected.year, '05-' + selected.year, '06-' + selected.year, '07-' + selected.year, '08-' + selected.year, '09-' + selected.year, '10-' + selected.year, '11-' + selected.year, '12-' + selected.year]
        let check_month = '01'

        if (list.length !== 0) {
            let filtered_data = list.filter((i) => { return dcf_ids.includes(i.dcfId) && i.type === 1 && i.site === sid && i.reporting_period[0].split('-')[0] === check_month })

            if (filtered_data.length !== 0) {

                if (filtered_data.length === dcf_ids.length * 1) {


                    const { value: accept } = await Swal.fire({
                        title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Approve Data</div>`,
                        html: '<span class="fs-16 fw-5">Are you sure you want to authorize data from this submission? You will not be able to make any further changes or edits.</span>',
                        confirmButtonColor: 'green',
                        showCancelButton: true,
                        confirmButtonText:
                            'Approve',

                    })
                    if (accept) {
                        approvedListBk.forEach((i) => {
                            if (filtered_data.map(i => i.id).includes(i.id)) {
                                i.approved_on = DateTime.utc()
                                i.type = 3
                            }
                        })
                        console.log(approvedListBk)
                        if (dcf_ids.includes(277)) {
                            updateSiteSelection_hr_enter('site', selected.hr_enter.site)
                        } else if (dcf_ids.includes(278)) {
                            updateSiteSelection_hr_leave('site', selected.hr_leave.site)
                        }

                    }

                }
            } else {
                return 0
            }

        } else {
            return 0
        }
    }

    const getApprovalStatusBiAnnually = (list, dcf_ids, sid, range) => {
        let month_range = ['01-' + selected.year, '02-' + selected.year, '03-' + selected.year, '04-' + selected.year, '05-' + selected.year, '06-' + selected.year, '07-' + selected.year, '08-' + selected.year, '09-' + selected.year, '10-' + selected.year, '11-' + selected.year, '12-' + selected.year]
        let check_month = '00'
        if (range === 1) {
            check_month = '01'
        } else if (range === 2) {
            check_month = '07'
        }
        console.log(1)
        if (list.length !== 0) {
            let filtered_data = list.filter((i) => { return dcf_ids.includes(i.dcfId) && i.site === sid && i.reporting_period[0].split('-')[0] === check_month })
            console.log(2)
            if (filtered_data.length !== 0) {
                console.log(3)
                console.log(filtered_data.length === dcf_ids.length * 1 ? filtered_data.filter(i => i.type === 3).length !== 0 ? 2 : 1 : 0)
                return filtered_data.length === dcf_ids.length * 1 ? filtered_data.filter(i => i.type === 3).length !== 0 ? 2 : 1 : 0
            } else {
                return 0
            }

        } else {
            return 0
        }
    }
    const getApprovalStatus = (list, dcf_ids, sid, range, prereq = []) => {
        console.log(list)
        let month_range = ['01-' + selected.year, '02-' + selected.year, '03-' + selected.year, '04-' + selected.year, '05-' + selected.year, '06-' + selected.year, '07-' + selected.year, '08-' + selected.year, '09-' + selected.year, '10-' + selected.year, '11-' + selected.year, '12-' + selected.year]
        let check_month = []
        if (range === 1) {
            check_month = month_range.slice(0, 3)
        } else if (range === 2) {
            check_month = month_range.slice(3, 6)
        } else if (range === 3) {
            check_month = month_range.slice(6, 9)
        } else if (range === 4) {
            check_month = month_range.slice(9, 12)
        }
        if (list.length !== 0) {
            let filtered_data = list.filter((i) => { return dcf_ids.includes(i.dcfId) && i.site === sid && check_month.includes(i.reporting_period[0]) })
            console.log(filtered_data)
            if (filtered_data.length !== 0) {
                let check = filtered_data.filter(i => (!prereq.includes(i.dcfId))).length + filtered_data.filter(i => (prereq.includes(i.dcfId))).length === (dcf_ids.filter(i => (prereq.includes(i.dcfId))).length + dcf_ids.filter(i => (!prereq.includes(i.dcfId))).length) * 3
                console.log(check, filtered_data.filter(i => (!prereq.includes(i.dcfId))).filter(i => i.type === 3).length)
                return check ? filtered_data.filter(i => (!prereq.includes(i.dcfId))).filter(i => i.type === 3).length !== 0 ? 2 : 1 : 0
            } else {
                return 0
            }

        } else {
            return 0
        }

    }
    const approveListBiAnnually = async (list, dcf_ids, sid, range) => {
        console.log(list)
        let month_range = ['01-' + selected.year, '02-' + selected.year, '03-' + selected.year, '04-' + selected.year, '05-' + selected.year, '06-' + selected.year, '07-' + selected.year, '08-' + selected.year, '09-' + selected.year, '10-' + selected.year, '11-' + selected.year, '12-' + selected.year]
        let check_month = '00'
        if (range === 1) {
            check_month = '01'
        } else if (range === 2) {
            check_month = '02'
        }
        if (list.length !== 0) {
            let filtered_data = list.filter((i) => { return dcf_ids.includes(i.dcfId) && i.type === 1 && i.site === sid && i.reporting_period[0].split('-')[0] === check_month })

            if (filtered_data.length !== 0) {

                if (filtered_data.length === dcf_ids.length * 1) {


                    const { value: accept } = await Swal.fire({
                        title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Approve Data</div>`,
                        html: '<span class="fs-16 fw-5">Are you sure you want to authorize data from this submission? You will not be able to make any further changes or edits.</span>',
                        confirmButtonColor: 'green',
                        showCancelButton: true,
                        confirmButtonText:
                            'Approve',

                    })
                    if (accept) {
                        approvedListBk.forEach((i) => {
                            if (filtered_data.map(i => i.id).includes(i.id)) {
                                i.approved_on = DateTime.utc()
                                i.type = 3
                            }
                        })
                        console.log(approvedListBk)
                        if (dcf_ids.includes(275)) {
                            updateSiteSelection_hr('site', selected.site)
                        }

                    }

                }
            } else {
                return 0
            }

        } else {
            return 0
        }
    }
    const approveList = async (list, dcf_ids, sid, range, prereq = []) => {
        console.log(list)
        let month_range = ['01-' + selected.year, '02-' + selected.year, '03-' + selected.year, '04-' + selected.year, '05-' + selected.year, '06-' + selected.year, '07-' + selected.year, '08-' + selected.year, '09-' + selected.year, '10-' + selected.year, '11-' + selected.year, '12-' + selected.year]
        let check_month = []
        if (range === 1) {
            check_month = month_range.slice(0, 3)
        } else if (range === 2) {
            check_month = month_range.slice(3, 6)
        } else if (range === 3) {
            check_month = month_range.slice(6, 9)
        } else if (range === 4) {
            check_month = month_range.slice(9, 12)
        }
        if (list.length !== 0) {
            let filtered_data = list.filter((i) => { return dcf_ids.includes(i.dcfId) && i.type === 1 && i.site === sid && check_month.includes(i.reporting_period[0]) })
            let filtered_data_ignore = list.filter((i) => { return dcf_ids.filter(i => !prereq.includes(i)).includes(i.dcfId) && i.type === 1 && i.site === sid && check_month.includes(i.reporting_period[0]) })

            if (filtered_data.length !== 0 || filtered_data_ignore.length !== 0) {

                if (filtered_data.length === dcf_ids.length * 3 || filtered_data_ignore.length === dcf_ids.filter(i => !prereq.includes(i)).length * 3) {


                    const { value: accept } = await Swal.fire({
                        title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Approve Data</div>`,
                        html: '<span class="fs-16 fw-5">Are you sure you want to authorize data from this submission? You will not be able to make any further changes or edits.</span>',
                        confirmButtonColor: 'green',
                        showCancelButton: true,
                        confirmButtonText:
                            'Approve',

                    })
                    if (accept) {
                        approvedListBk.forEach((i) => {
                            if (filtered_data.map(i => i.id).includes(i.id)) {
                                i.approved_on = DateTime.utc()
                                i.type = 3
                            }
                        })
                        if (dcf_ids.includes(257) && !dcf_ids.includes(264)) {
                            updateSiteSelection_scope2('site', selected.site)
                        } else if (dcf_ids.includes(10)) {
                            updateSiteSelection_scope1('site', selected.site)
                        } else if (dcf_ids.includes(264)) {
                            updateSiteSelection_pue('site', selected.site)
                        } else if (dcf_ids.includes(246)) {
                            updateSiteSelection_water('site', selected.site)
                        } else if (dcf_ids.includes(275)) {
                            updateSiteSelection_hr('site', selected.site)
                        }

                    }

                }
            } else {
                return 0
            }

        } else {
            return 0
        }
    }
    const getUser = (id) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }
    const getBetweenMonthsByYearFromStart_EndDate = (start_date, end_date, year) => {
        // Parse start_date and end_date
        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).toLocal();

        const endDate = end_date ? DateTime.fromISO(end_date, { zone: 'utc' }).toLocal() : DateTime.utc().toLocal();

        // If end_date is null, set it to current date
        if (!end_date) {
            end_date = endDate.toISODate();
        }

        // Initialize result and between_months array
        let result = false;
        const between_months = [];

        // Iterate through months between start_date and end_date
        let currentMonth = startDate;
        while (currentMonth <= endDate) {
            // Check if the month falls within the given year
            if (currentMonth.year === parseInt(year)) {
                between_months.push(currentMonth.toFormat('LL-yyyy'));
                result = true;
            }

            // Move to the next month
            currentMonth = currentMonth.plus({ months: 1 });
        }

        // Return the result and between_months array
        return { result, between_months };
    }

    const updatePendingTable = (yr) => {
        let pending = [], submitted = 0, total = 0
        userdcfass.filter(i => (i.type === 0 && i.frequency === 1)).forEach((ass) => {
            const { result, between_months } = getBetweenMonthsByYearFromStart_EndDate(ass.start_date, ass.end_date, yr)
            if (result && between_months.length) {
                between_months.forEach((mons) => {
                    total += 1
                    let pending_index = approvedListBk.findIndex((i) => { return i.dcfId === ass.dcfId && i.site === ass.site[0] && (i.type === 1 || i.type === 2 || i.type === 3) && mons === i.reporting_period[0] })
                    if (pending_index === -1) {
                        pending.push({ dcf: ass.dcfId, site: ass.site[0], reporting_period: getRPMonth([mons]), user_id: ass.userId })
                    } else {
                        submitted += 1
                        console.log('submitted', approvedListBk[pending_index])
                    }
                })

            }

        })
        pending.forEach((i) => {
            i.dcfId = dcflist.find((j) => j.id === i.dcfId)
            i.site = sitelist.find((j) => j.id === i.site)

        })
        setPenListByYear(pending)
        if (total !== 0) {
            setPenYearCount(100 - parseFloat(((submitted / total) * 100).toFixed(2)))
        } else {
            setPenYearCount(0)
        }
        forceUpdate()
        console.log(pending)
    }

    return (
        <div className="bg-smoke font-lato" >
            <StickyContainer>
                <div className="col-12">
                    <div className="col-12 flex justify-content-center" >
                        <label className="text-big-one clr-navy flex fs-16 flex justify-content-start" > Data Approver Portal
                        </label>
                    </div>
                    <Sticky topOffset={0}>
                        {({ style, isSticky }) => (
                            <div style={{ ...style, top: 98, background: isSticky && 'white', zIndex: 100 }}>
                                <div className="flex col-12 justify-content-between " style={{ flexDirection: 'row' }} >
                                    <div className='col-3 grid align-items-center'>
                                        <div className="col-4">
                                            <label className="fs-16 fw-5">Select Year</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="year" style={{ width: '100%' }} options={[{ name: '2022', year: 2022 }, { name: '2023', year: 2023 }, { name: '2024', year: 2024 }]} value={selected.year} onChange={(e) => { onSelectedChange('year', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-3 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Country</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.country} options={selectedList.country} onChange={(e) => { onSelectedChange('country', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-3 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">City</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.city} options={selectedList.city} onChange={(e) => { onSelectedChange('city', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-3 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Site</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.site} options={selectedList.site} onChange={(e) => { onSelectedChange('site', e.value) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    </Sticky>
                    <div className="col-12 " style={{ flexDirection: 'column' }}>
                        <label className="text-big-one clr-gray-900 fw-7 p-2 flex fs-16 flex"> Your Approvals </label>
                        <div className="col-12 flex justify-content-center">
                            <div className="col-12 grid ">
                                <div className="col-4">
                                    <div className="admin-card flex justify-content-between" onClick={() => { setActive(0) }} style={{ height: 150, background: active === 0 ? 'lightgrey' : 'white' }}>
                                        <div className="justify-content-between grid">
                                            <div className="big-number-navy fs-36">{indicator.length}</div>
                                            <div className="icon-layout" style={{ background: 'rgba(128, 234, 213, 0.20)' }}>
                                                <img src={require('../../assets/images/newui/admin/barchart.png').default} width={24} height={23} />

                                            </div>

                                        </div>
                                        <div >
                                            <div className="text-bold clr-navy fs-16"></div>

                                        </div>
                                        <div className="justify-content-between grid">
                                            <div className="clr-navy fs-12 text-bold">QUANTITATIVE INDICATORS</div>
                                            <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="admin-card flex justify-content-between"  style={{ height: 150, background: active === 1 ? 'lightgrey' : 'white' }}>
                                        <div className="justify-content-between grid">
                                            <div className="big-number-navy fs-36">0</div>
                                            <div className="icon-layout" style={{ background: 'rgba(128, 234, 213, 0.20)' }}>
                                                <img src={require('../../assets/images/newui/admin/barchart.png').default} width={24} height={23} />

                                            </div>

                                        </div>
                                        <div >
                                            <div className="text-bold clr-navy fs-16"></div>

                                        </div>
                                        <div className="justify-content-between grid">
                                            <div className="clr-navy fs-12 text-bold">QUALITATIVE INDICATORS</div>
                                            <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="admin-card flex justify-content-between"  style={{ height: 150, background: active === 2 ? 'lightgrey' : 'white' }}>
                                        <div className="justify-content-between grid">
                                            <div className="big-number-navy fs-36">{penyearcount.toFixed(2)} %</div>
                                            <div className="icon-layout" style={{ background: 'rgba(128, 234, 213, 0.20)' }}>
                                                <img src={require('../../assets/images/newui/admin/barchart.png').default} width={24} height={23} />

                                            </div>

                                        </div>
                                        <div >
                                            <div className="text-bold clr-navy fs-16"></div>

                                        </div>
                                        <div className="justify-content-between grid">
                                            <div className="clr-navy fs-12 text-bold">PENDING DATA SUBMISSIONS</div>
                                            <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {active === 0 &&
                        <div>

                            {indicator.map((item, index) => {

                                return (
                                    <div >
                                        <div onClick={() => { openItem(index, item.selected) }} className="p-2 m-2 fw-5 fs-16 flex br-1 justify-content-between align-items-center" style={{ background: item.bgc.bgc, color: item.color, border: item.border }}> <div> {item.title} - {selected.year}  </div>

                                            {(item.selected === false || item.selected === undefined) ? <i style={{
                                                background: 'white',
                                                color: '#003153',
                                                padding: '3px',
                                                borderRadius: '20px'
                                            }} className="material-icons">navigate_next</i>
                                                : <i className="material-icons" style={{
                                                    background: 'white',
                                                    color: '#003153',
                                                    padding: '3px',
                                                    borderRadius: '20px'
                                                }}>expand_less</i>
                                            }
                                        </div>
                                        {item.selected === true &&
                                            <div className="p-2 flex justify-content-center" >
                                                {index === 0 &&
                                                    <div className="col-12">
                                                        <div className="col-12">
                                                            <div className="col-12 fw-7 fs-18 flex justify-content-center br-1 mb-1" style={{ color: 'white', background: item.base_color }}> Scope 1 Emissions</div>
                                                            <div hidden >
                                                                <div className='col-4 grid align-items-center'>
                                                                    <div className="col-3">
                                                                        <label className="fs-16 fw-5">Country</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.scope1.country} options={selectedList.scope1.country} onChange={(e) => { updateSiteSelection_scope1('country', e.value) }} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-4 grid align-items-center'>
                                                                    <div className="col-3">
                                                                        <label className="fs-16 fw-5">City</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.scope1.city} options={selectedList.scope1.city} onChange={(e) => { updateSiteSelection_scope1('city', e.value) }} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-4 grid align-items-center'>
                                                                    <div className="col-3">
                                                                        <label className="fs-16 fw-5">Site</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.site} options={selectedList.scope1.site} onChange={(e) => { updateSiteSelection_scope1('site', e.value) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 grid ">
                                                                <div className="col-6 flex justify-content-center" >
                                                                    <label className="clr-stt fw-7 fs-16">Scope 1 Emission Breakup</label>

                                                                </div>
                                                                <div className="col-6 flex justify-content-center" >
                                                                    <label className="clr-stt fw-7 fs-16">Scope 1 Refrigerants</label>

                                                                </div>

                                                            </div>

                                                            <div className="col-12 grid ">
                                                                <div className="col-6" >
                                                                    <canvas id='s1_1_0' className="col-4" />
                                                                    {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                                </div>
                                                                <div className="col-6">
                                                                    <canvas id='s1_2_0' className="col-4" />
                                                                    {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                                </div>

                                                            </div>
                                                            <div className="col-12 grid ">
                                                                <div className="col-6 flex justify-content-center" >
                                                                    <label className="clr-stt fw-7 fs-16">Energy Consumption (TJ)</label>
                                                                </div>
                                                                <div className="col-6 flex justify-content-center">
                                                                    <label className="clr-stt fw-7 fs-16">Scope 1 Emissions</label>
                                                                </div>

                                                            </div>

                                                            <div className="col-12 grid ">

                                                                <div className="col-6">
                                                                    <canvas id='s1_2' className="col-4" />
                                                                    {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                                </div>
                                                                <div className="col-6">
                                                                    <canvas id='s1_t2' className="col-4" />
                                                                    {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                                </div>
                                                            </div>

                                                            <div className="col-12 grid ">
                                                                <div className="col-4 flex justify-content-center" >
                                                                    <a onClick={() => { setDialogAppList(approvedList.scope1); setSearchStr({ app: '', pen: '' }); setDialogAppListBK(approvedList.scope1); setShowAppDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Computation data sources</a>
                                                                </div>
                                                                <div className="col-4 flex justify-content-center">
                                                                    <span className="fw-5 fs-16"> <span className="clr-delete ">● - Incomplete data</span>  <span className="ml-2" style={{ color: 'deepskyblue' }}>✓ - Authorised data</span> </span>
                                                                </div>
                                                                <div className="col-4 flex justify-content-center">
                                                                    <a onClick={() => { setDialogPenList(pendingList.scope1); setSearchStr({ app: '', pen: '' }); setDialogPenListBK(pendingList.scope1); setShowPenDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Missing data inputs </a>
                                                                </div>

                                                            </div>
                                                            {selected.site !== 0 && <div className='col-12 flex justify-content-between'>
                                                                {getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 1) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 1) === 0} onClick={() => { approveList(approvedList.scope1, [10, 11], selected.site, 1) }} label="Approve (Q1)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 1) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                                {getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 2) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 2) === 0} onClick={() => { approveList(approvedList.scope1, [10, 11], selected.site, 2) }} label="Approve (Q2)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 2) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                                {getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 3) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 3) === 0} onClick={() => { approveList(approvedList.scope1, [10, 11], selected.site, 3) }} label="Approve (Q3)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 3) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                                {getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 4) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 4) === 0} onClick={() => { approveList(approvedList.scope1, [10, 11], selected.site, 4) }} label="Approve (Q4)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.scope1, [10, 11], selected.site, 4) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            </div>}
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="col-12 fw-7 fs-18 flex justify-content-center br-1 mb-1" style={{ color: 'white', background: item.base_color }}> Scope 2 Emissions</div>
                                                            <div hidden>
                                                                <div className='col-4 grid align-items-center'>
                                                                    <div className="col-3">
                                                                        <label className="fs-16 fw-5">Country</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.scope2.country} options={selectedList.scope2.country} onChange={(e) => { updateSiteSelection_scope2('country', e.value) }} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-4 grid align-items-center'>
                                                                    <div className="col-3">
                                                                        <label className="fs-16 fw-5">City</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.scope2.city} options={selectedList.scope2.city} onChange={(e) => { updateSiteSelection_scope2('city', e.value) }} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-4 grid align-items-center'>
                                                                    <div className="col-3">
                                                                        <label className="fs-16 fw-5">Site</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.site} options={selectedList.scope2.site} onChange={(e) => { updateSiteSelection_scope2('site', e.value) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 grid ">
                                                                <div className="col-6 flex justify-content-center" >
                                                                    <label className="clr-stt fw-7 fs-16">Source of Electricity</label>
                                                                </div>
                                                                <div className="col-6 flex justify-content-center">
                                                                    <label className="clr-stt fw-7 fs-16">Scope 2 Emissions</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 grid ">
                                                                <div className="col-6" >
                                                                    <canvas id='s2_1' className="col-4" />
                                                                    {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                                </div>
                                                                <div className="col-6">
                                                                    <canvas id='s2_2' className="col-4" />
                                                                    {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                                </div>

                                                            </div>
                                                            <div className="col-12 grid ">
                                                                <div className="col-4 flex justify-content-center" >
                                                                    <a onClick={() => { setDialogAppList(approvedList.scope2); setSearchStr({ app: '', pen: '' }); setDialogAppListBK(approvedList.scope2); setShowAppDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Computation data sources</a>
                                                                </div>
                                                                <div className="col-4 flex justify-content-center">
                                                                    <span className="fw-5 fs-16"> <span className="clr-delete ">● - Incomplete data</span>  <span className="ml-2" style={{ color: 'deepskyblue' }}>✓ - Authorised data</span> </span>
                                                                </div>
                                                                <div className="col-4 flex justify-content-center">
                                                                    <a onClick={() => { setDialogPenList(pendingList.scope2); setSearchStr({ app: '', pen: '' }); setDialogPenListBK(pendingList.scope2); setShowPenDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Missing data inputs </a>
                                                                </div>

                                                            </div>
                                                            {selected.site !== 0 && <div className='col-12 flex justify-content-between'>
                                                                {getApprovalStatus(approvedList.scope2, [257], selected.site, 1) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.scope2, [257], selected.site, 1) === 0} onClick={() => { approveList(approvedList.scope2, [257], selected.site, 1) }} label="Approve (Q1)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.scope2, [257], selected.site, 1) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                                {getApprovalStatus(approvedList.scope2, [257], selected.site, 2) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.scope2, [257], selected.site, 2) === 0} onClick={() => { approveList(approvedList.scope2, [257], selected.site, 2) }} label="Approve (Q2)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.scope2, [257], selected.site, 2) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                                {getApprovalStatus(approvedList.scope2, [257], selected.site, 3) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.scope2, [257], selected.site, 3) === 0} onClick={() => { approveList(approvedList.scope2, [257], selected.site, 3) }} label="Approve (Q3)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.scope2, [257], selected.site, 3) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                                {getApprovalStatus(approvedList.scope2, [257], selected.site, 4) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.scope2, [257], selected.site, 4) === 0} onClick={() => { approveList(approvedList.scope2, [257], selected.site, 4) }} label="Approve (Q4)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.scope2, [257], selected.site, 4) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            </div>}
                                                        </div>

                                                    </div>
                                                }
                                                {index === 1 &&
                                                    <div className="col-12">
                                                        <div hidden>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">Country</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.pue.country} options={selectedList.pue.country} onChange={(e) => { updateSiteSelection_pue('country', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">City</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.pue.city} options={selectedList.pue.city} onChange={(e) => { updateSiteSelection_pue('city', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">Site</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.site} options={selectedList.pue.site} onChange={(e) => { updateSiteSelection_pue('site', e.value) }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 grid ">
                                                            <div className="col-4 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">Annual Renewable Energy Percentage</label>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <label className="clr-stt fw-7 fs-16">Energy Consumption Trends</label>
                                                            </div>

                                                            <div className="col-4 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">PUE Trend</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-4" >
                                                                <canvas id='pue_1' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>
                                                            <div className="col-4">
                                                                <canvas id='pue_2' className="col-4" />

                                                            </div>
                                                            <div className="col-4">
                                                                <canvas id='pue_3' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3_i.png').default} /> */}
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-4 flex justify-content-center" >
                                                                <a onClick={() => { setDialogAppList(approvedList.pue); setSearchStr({ app: '', pen: '' }); setDialogAppListBK(approvedList.pue); setShowAppDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Computation data sources</a>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <span className="fw-5 fs-16"> <span className="clr-delete ">● - Incomplete data</span>  <span className="ml-2" style={{ color: 'deepskyblue' }}>✓ - Authorised data</span> </span>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <a onClick={() => { setDialogPenList(pendingList.pue); setSearchStr({ app: '', pen: '' }); setDialogPenListBK(pendingList.pue); setShowPenDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Missing data inputs </a>
                                                            </div>

                                                        </div>
                                                        {selected.site !== 0 && <div className='col-12 flex justify-content-between'>
                                                            {getApprovalStatus(approvedList.pue, [264, 257], selected.site, 1, [257]) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.pue, [264, 257], selected.site, 1, [257]) === 0} onClick={() => { approveList(approvedList.pue, [264, 257], selected.site, 1, [257]) }} label="Approve (Q1)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.pue, [264, 257], selected.site, 1, [257]) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            {getApprovalStatus(approvedList.pue, [264, 257], selected.site, 2, [257]) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.pue, [264, 257], selected.site, 2, [257]) === 0} onClick={() => { approveList(approvedList.pue, [264, 257], selected.site, 2, [257]) }} label="Approve (Q2)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.pue, [264, 257], selected.site, 2, [257]) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            {getApprovalStatus(approvedList.pue, [264, 257], selected.site, 3, [257]) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.pue, [264, 257], selected.site, 3, [257]) === 0} onClick={() => { approveList(approvedList.pue, [264, 257], selected.site, 3, [257]) }} label="Approve (Q3)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.pue, [264, 257], selected.site, 3, [257]) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            {getApprovalStatus(approvedList.pue, [264, 257], selected.site, 4, [257]) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.pue, [264, 257], selected.site, 4, [257]) === 0} onClick={() => { approveList(approvedList.pue, [264, 257], selected.site, 4, [257]) }} label="Approve (Q4)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.pue, [264, 257], selected.site, 4, [257]) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                        </div>}
                                                    </div>}

                                                {index === 2 &&
                                                    <div className="col-12">
                                                        <div className="col-12 fw-7 fs-18 flex justify-content-center br-1 mb-1" style={{ color: 'white', background: 'skyblue' }}> Diversity of Employee </div>
                                                        <div hidden>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5"> Country</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.hr.country} options={selectedList.hr.country} onChange={(e) => { updateSiteSelection_hr('country', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">City</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.hr.city} options={selectedList.hr.city} onChange={(e) => { updateSiteSelection_hr('city', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">Site</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.site} options={selectedList.hr.site} onChange={(e) => { updateSiteSelection_hr('site', e.value) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-content-end m-2'>
                                                            <SelectButton value={toggle.hr} onChange={(e) => { updateHRData_STT_Switch(e.value) }} optionValue="id" optionLabel="title" options={[{ title: 'View H1', id: 1 }, { title: 'View H2', id: 2 }]} />
                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">Employee Gender Breakup %</label>
                                                            </div>
                                                            <div className="col-6 flex justify-content-center">
                                                                <label className="clr-stt fw-7 fs-16">Employee Diversity By Age Group & Gender</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center align-items-center" >
                                                                <canvas id='hr1' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>
                                                            <div className="col-6 flex justify-content-center align-items-center">
                                                                <canvas id='hr2' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center">
                                                                <label className="clr-stt fw-7 fs-16">Employee Diversity By Gender  & Category</label>
                                                            </div>
                                                            <div className="col-6 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">Diversity Of  Employee By Category & Age</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center align-items-center">
                                                                <canvas id='hr3' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                            </div>
                                                            <div className="col-6 flex justify-content-center align-items-center" >
                                                                <canvas id='hr4' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>


                                                        </div>

                                                        <div className="col-12 grid ">
                                                            <div className="col-4 flex justify-content-center" >
                                                                <a onClick={() => { setDialogAppList(approvedList.hr); setSearchStr({ app: '', pen: '' }); setDialogAppListBK(approvedList.hr); setShowAppDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Computation data sources</a>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <span className="fw-5 fs-16"> <span className="clr-delete ">● - Incomplete data</span>  <span className="ml-2" style={{ color: 'deepskyblue' }}>✓ - Authorised data</span> </span>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <a onClick={() => { setDialogPenList(pendingList.hr); setSearchStr({ app: '', pen: '' }); setDialogPenListBK(pendingList.hr); setShowPenDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Missing data inputs </a>
                                                            </div>

                                                        </div>
                                                        {selected.site !== 0 && <div className='col-12 flex justify-content-center'>
                                                            {(getApprovalStatusBiAnnually(approvedList.hr, [275], selected.site, 1) === 2 || toggle.hr === 2) ? <div /> : <Button title="Approve" disabled={getApprovalStatusBiAnnually(approvedList.hr, [275], selected.site, 1) === 0} onClick={() => { approveListBiAnnually(approvedList.hr, [275], selected.site, 1) }} label="Approve H1" className={"col-3 br-1 " + (getApprovalStatusBiAnnually(approvedList.hr, [275], selected.site, 1) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            {(getApprovalStatusBiAnnually(approvedList.hr, [275], selected.site, 2) === 2 || toggle.hr === 1) ? <div /> : <Button title="Approve" disabled={getApprovalStatusBiAnnually(approvedList.hr, [275], selected.site, 2) === 0} onClick={() => { approveListBiAnnually(approvedList.hr, [275], selected.site, 2) }} label="Approve H2" className={"col-3 br-1 " + (getApprovalStatusBiAnnually(approvedList.hr, [275], selected.site, 2) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}

                                                        </div>}
                                                        <div className="col-12 fw-7 fs-18 flex justify-content-center br-1 mb-1" style={{ color: 'white', background: 'skyblue' }}> New Hires  </div>
                                                        <div hidden>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5"> Country</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.hr_enter.country} options={selectedList.hr_enter.country} onChange={(e) => { updateSiteSelection_hr_enter('country', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">City</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.hr_enter.city} options={selectedList.hr_enter.city} onChange={(e) => { updateSiteSelection_hr_enter('city', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">Site</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.hr_enter.site} options={selectedList.hr_enter.site} onChange={(e) => { updateSiteSelection_hr_enter('site', e.value) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">New Hires Gender </label>
                                                            </div>
                                                            <div className="col-6 flex justify-content-center">
                                                                <label className="clr-stt fw-7 fs-16">New Hires By Age Group & Gender</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center align-items-center" >
                                                                <canvas id='hr_enter_1' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>
                                                            <div className="col-6 flex justify-content-center align-items-center">
                                                                <canvas id='hr_enter_2' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center">
                                                                <label className="clr-stt fw-7 fs-16">New Hires By Gender  & Category</label>
                                                            </div>
                                                            <div className="col-6 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">New Hires By Category & Age</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center align-items-center">
                                                                <canvas id='hr_enter_3' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                            </div>
                                                            <div className="col-6 flex justify-content-center align-items-center" >
                                                                <canvas id='hr_enter_4' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>


                                                        </div>

                                                        <div className="col-12 grid ">
                                                            <div className="col-4 flex justify-content-center" >
                                                                <a onClick={() => { setDialogAppList(approvedList.hr_enter); setSearchStr({ app: '', pen: '' }); setDialogAppListBK(approvedList.hr_enter); setShowAppDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Computation data sources</a>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <span className="fw-5 fs-16"> <span className="clr-delete ">● - Incomplete data</span>  <span className="ml-2" style={{ color: 'deepskyblue' }}>✓ - Authorised data</span> </span>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <a onClick={() => { setDialogPenList(pendingList.hr_enter); setSearchStr({ app: '', pen: '' }); setDialogPenListBK(pendingList.hr_enter); setShowPenDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Missing data inputs </a>
                                                            </div>

                                                        </div>
                                                        {selected.hr_enter.site !== 0 && <div className='col-12 flex justify-content-center'>
                                                            {getApprovalStatusAnnually(approvedList.hr_enter, [277], selected.hr_enter.site, 1) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatusAnnually(approvedList.hr_enter, [277], selected.hr_enter.site, 1) === 0} onClick={() => { approveListAnnually(approvedList.hr_enter, [277], selected.hr_enter.site, 1) }} label="Approve" className={"col-3 br-1 " + (getApprovalStatusAnnually(approvedList.hr_enter, [277], selected.hr_enter.site, 1) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}


                                                        </div>}
                                                        <div className="col-12 fw-7 fs-18 flex justify-content-center br-1 mb-1" style={{ color: 'white', background: 'skyblue' }}>  Employee Attrition</div>
                                                        <div hidden>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5"> Country</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.hr_leave.country} options={selectedList.hr_leave.country} onChange={(e) => { updateSiteSelection_hr_leave('country', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">City</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.hr_leave.city} options={selectedList.hr_leave.city} onChange={(e) => { updateSiteSelection_hr_leave('city', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">Site</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.hr_leave.site} options={selectedList.hr_leave.site} onChange={(e) => { updateSiteSelection_hr_leave('site', e.value) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">Employee Attrition By Gender  </label>
                                                            </div>
                                                            <div className="col-6 flex justify-content-center">
                                                                <label className="clr-stt fw-7 fs-16">Employee Attrition By Age Group & Gender</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center align-items-center" >
                                                                <canvas id='hr_leave_1' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>
                                                            <div className="col-6 flex justify-content-center align-items-center">
                                                                <canvas id='hr_leave_2' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center">
                                                                <label className="clr-stt fw-7 fs-16">Employee Attrition By Gender  & Category</label>
                                                            </div>
                                                            <div className="col-6 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">Employee Attrition By Category & Age</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6 flex justify-content-center align-items-center">
                                                                <canvas id='hr_leave_3' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                                            </div>
                                                            <div className="col-6 flex justify-content-center align-items-center" >
                                                                <canvas id='hr_leave_4' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>


                                                        </div>

                                                        <div className="col-12 grid ">
                                                            <div className="col-4 flex justify-content-center" >
                                                                <a onClick={() => { setDialogAppList(approvedList.hr_leave); setSearchStr({ app: '', pen: '' }); setDialogAppListBK(approvedList.hr_leave); setShowAppDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Computation data sources</a>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <span className="fw-5 fs-16"> <span className="clr-delete ">● - Incomplete data</span>  <span className="ml-2" style={{ color: 'deepskyblue' }}>✓ - Authorised data</span> </span>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <a onClick={() => { setDialogPenList(pendingList.hr_leave); setSearchStr({ app: '', pen: '' }); setDialogPenListBK(pendingList.hr_leave); setShowPenDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Missing data inputs </a>
                                                            </div>

                                                        </div>
                                                        {selected.hr_leave.site !== 0 && <div className='col-12 flex justify-content-center'>
                                                            {getApprovalStatusAnnually(approvedList.hr_leave, [278], selected.hr_leave.site, 1) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatusAnnually(approvedList.hr_leave, [278], selected.hr_leave.site, 1) === 0} onClick={() => { approveListAnnually(approvedList.hr_leave, [278], selected.hr_leave.site, 1) }} label="Approve" className={"col-3 br-1 " + (getApprovalStatusAnnually(approvedList.hr_leave, [278], selected.hr_leave.site, 1) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}

                                                        </div>}
                                                    </div>

                                                }
                                                {index === 3 &&
                                                    <div className="col-12">
                                                        <div hidden>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">Country</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.water.country} options={selectedList.water.country} onChange={(e) => { updateSiteSelection_water('country', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">City</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.water.city} options={selectedList.water.city} onChange={(e) => { updateSiteSelection_water('city', e.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-4 grid align-items-center'>
                                                                <div className="col-3">
                                                                    <label className="fs-16 fw-5">Site</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.site} options={selectedList.water.site} onChange={(e) => { updateSiteSelection_water('site', e.value) }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 grid ">
                                                            <div className="col-12 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">Water Withdrawal By Source</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6" >
                                                                <canvas id='water_1' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>
                                                            {/* <div className="col-4">
                                                            <canvas id='pue_2' className="col-4" />
                                                          
                                                        </div> */}
                                                            <div className="col-6">
                                                                <canvas id='water_2' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3_i.png').default} /> */}
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-12 flex justify-content-center" >
                                                                <label className="clr-stt fw-7 fs-16">Water Discharged By Source</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-6" >
                                                                <canvas id='water_3' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>
                                                            <div className="col-6" >
                                                                <canvas id='water_4' className="col-4" />
                                                                {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                                            </div>

                                                        </div>
                                                        <div className="col-12 grid ">
                                                            <div className="col-4 flex justify-content-center" >
                                                                <a onClick={() => { setDialogAppList(approvedList.water); setSearchStr({ app: '', pen: '' }); setDialogAppListBK(approvedList.water); setShowAppDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Computation data sources</a>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <span className="fw-5 fs-16"> <span className="clr-delete ">● - Incomplete data</span>  <span className="ml-2" style={{ color: 'deepskyblue' }}>✓ - Authorised data</span> </span>
                                                            </div>
                                                            <div className="col-4 flex justify-content-center">
                                                                <a onClick={() => { setDialogPenList(pendingList.water); setSearchStr({ app: '', pen: '' }); setDialogPenListBK(pendingList.water); setShowPenDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Missing data inputs </a>
                                                            </div>

                                                        </div>
                                                        {selected.site !== 0 && <div className='col-12 flex justify-content-between'>
                                                            {getApprovalStatus(approvedList.water, [246, 247], selected.site, 1) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.water, [246, 247], selected.site, 1) === 0} onClick={() => { approveList(approvedList.water, [246, 247], selected.site, 1) }} label="Approve (Q1)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.water, [246, 247], selected.site, 1) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            {getApprovalStatus(approvedList.water, [246, 247], selected.site, 2) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.water, [246, 247], selected.site, 2) === 0} onClick={() => { approveList(approvedList.water, [246, 247], selected.site, 2) }} label="Approve (Q2)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.water, [246, 247], selected.site, 2) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            {getApprovalStatus(approvedList.water, [246, 247], selected.site, 3) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.water, [246, 247], selected.site, 3) === 0} onClick={() => { approveList(approvedList.water, [246, 247], selected.site, 3) }} label="Approve (Q3)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.water, [246, 247], selected.site, 3) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                            {getApprovalStatus(approvedList.water, [246, 247], selected.site, 4) === 2 ? <div className="col-3" /> : <Button title="Approve" disabled={getApprovalStatus(approvedList.water, [246, 247], selected.site, 4) === 0} onClick={() => { approveList(approvedList.water, [246, 247], selected.site, 4) }} label="Approve (Q4)" className={"col-3 br-1 " + (getApprovalStatus(approvedList.water, [246, 247], selected.site, 4) === 1 ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                                        </div>}
                                                    </div>}
                                            </div>

                                        }
                                    </div>
                                )
                            })

                            }
                        </div>
                    }
                    {active === 2 &&
                        <div className="col-12">
                            <DataTable scrollable value={penListByYear} >
                                <Column header='Form' field="dcf.title" />
                                <Column header='Site' field='site.name' />
                                <Column header='Reporting Period' field='reporting_period' />
                                <Column header='Submitter' body={submitterTemplate_} />
                            </DataTable>
                        </div>
                    }
                    <Dialog visible={showappdialog} header={'Data Source List (' + dialogapplist.length + ')'} style={{ width: '80%' }} onHide={() => { setShowAppDialog(false) }} className="dialog-box" >
                        <div >
                            <div hidden>
                                <div className="col-4  align-items-center ">
                                    <span className="p-input-icon-left" >
                                        <i className="pi pi-search" />
                                        <InputText placeholder="Search form/site/month/reviewer" style={{ width: 350 }} value={searchstr.app} onChange={(e) => { searchApp(e.target.value) }} />
                                    </span>
                                </div>
                            </div>
                            <DataTable scrollable value={dialogapplist} filters={filters} filterDisplay="row"  >
                                <Column header='Form' field='dcf' showFilterMenu={false} filter filterElement={dcfFilterElement} body={dcfTemplate} />
                                <Column header='Entity' field='site' showFilterMenu={false} body={siteTemplate} />
                                <Column header='Reporting Period' sortable sortFunction={sortRP} field='rp' />
                                {/* <Column header='Submitter' field="submitted_by" showFilterMenu={false} filter filterElement={submitterFilterElement} body={submitterTemplate} /> */}
                                <Column header='Submitted On' field="submitted_on" sortable sortFunction={sortSubmission} body={submittedDateTemplate} />
                                <Column header='Remarks' body={remarksTemplate} />
                            </DataTable>
                        </div>
                    </Dialog>
                    <Dialog visible={showpendialog} header={'Pending data submissions (' + dialogpenlist.length + ')'} style={{ width: '80%' }} onHide={() => { setShowPenDialog(false) }} className="dialog-box" >
                        <div >
                            <div hidden>
                                <div className="col-5  align-items-center justify-content-end">
                                    <span className="p-input-icon-left" >
                                        <i className="pi pi-search" />
                                        <InputText placeholder="Search form/site/month/reviewer" style={{ width: 350 }} value={searchstr.pen} onChange={(e) => { searchPen(e.target.value) }} />
                                    </span>
                                </div>
                            </div>
                            <DataTable scrollable value={dialogpenlist} filters={filters2} filterDisplay="row"  >
                                <Column header='Form' field='dcf.id' showFilterMenu={false} filter filterElement={dcfFilterElement_} body={dcfTemplate_} />
                                <Column header='Entity' field='site.id' showFilterMenu={false} body={siteTemplate_} />
                                <Column header='Reporting Period' sortable sortFunction={sortRP2} field='reporting_period' />

                                {/* <Column header='Submitter' field="submitter" showFilterMenu={false} filter filterElement={submitterElement} body={submitterTemplate_} /> */}
                                <Column header='Overdue Day(s)' field='overdue' />

                            </DataTable>
                        </div>
                    </Dialog>
                    <Dialog visible={remarksdialog} modal
                        className="p-fluid"
                        onHide={() => {
                            setRemarksDialog(false);
                        }} style={{ width: '65%' }} header={'Remarks'} >
                        <div>
                            {remarksdata.return_remarks !== null &&
                                remarksdata.return_remarks.map((cmnt) => {
                                    return (
                                        <div className="col-12 grid " style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                            <div className="col-5">
                                                <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                <div className="mt-2" > {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                            </div>
                                            <div className="col-5">
                                                {cmnt.remarks}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Dialog>
                </div>
            </StickyContainer>
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QuantitativeApproverDB, comparisonFn);