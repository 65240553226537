import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { DateTime } from "luxon";
import useForceUpdate from "use-force-update";
import { BlockUI } from "primereact/blockui";
import { index } from "d3";
import { Dialog } from "primereact/dialog";



export const AttachmentAsIcon = ({ index, 
    refId,
    sectionId,
    subsection1Id,
    subsection2Id,
    documents = null,
    mandatory = false,
    edit = 0,
    labels = '',
    hint = '',
    getFiles = () => { },

}) => {
    console.log(documents)
    const [document, setDocument] = useState(documents)
    const [blocked, setBlocked] = useState(false)
    const [modalVisible, setModalVisible] = useState(false);
    const forceUpdate = useForceUpdate()
    useEffect(() => {
        getFiles(document)
    }, [document])
    useEffect(() => {
        return () => setDocument(null);
    }, []);
    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }


    const handleFileUpload_HC = (e) => {
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.bmp', '.pdf', '.PDF', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx']
        setBlocked(true)
        let filtered_files = Object.values(e.target.files).filter(i => allowedext.includes(i.name.substr(i.name.lastIndexOf('.'))))
        let existingValue = document ? document.reduce((a, b) => { return a + b.size }, 0) : 0
        let formData = new FormData()
        let count = 0, allowedCount = document?.length || 0
        Object.values(filtered_files).forEach(file => {

            if ((file.size + existingValue) <= 60000000 && file.size <= 20000000 && allowedCount <= 11) {
                file.extension = file.name.substr(file.name.lastIndexOf('.'))
                formData.append('file', file);

                existingValue += file.size
                count = 1
                allowedCount++
            }
        })
        e.target.value = null
        if (count) {

            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                setBlocked(false)
                if (document === undefined || document === null) {
                    setDocument(res.data.files)
                } else {
                    let loc = JSON.parse(JSON.stringify(document))

                    loc.push(...res.data.files)
                    setDocument(loc)


                }
                forceUpdate()
            }).catch((e) => {
                setBlocked(false)
            })
        } else {
            setBlocked(false)
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const removeImage_HC = (findex) => {

        document.splice(findex, 1)
        forceUpdate()

    }


    return (
        <div >


            <Tooltip target={".ttdoc"} />
            <div className="col-12">
                <label style={{ display: 'flex' }} className=' fw-5 fs-16 text-justify'>{labels} {hint.length !== 0 && <span><i style={{
                    fontSize: '18px',
                    marginLeft: '5px'
                }} className={`material-icons fs-14 ttdoc`} data-pr-tooltip={hint}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"> help</i></span>}

                </label>

            </div>
            <BlockUI container blocked={blocked} template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>}>
                <div >
                    <div  style={{

                        justifyContent: 'space-between', display: 'flex', alignItems: 'center',


                    }}>
                        <div>
                            {edit && <label htmlFor={'fp' + refId} className="fs-14 clr-nav flex" style={{
                                marginRight: 10,



                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                <span> Add Attachment</span>
                            </label>}
                            {document && document.length > 0 && (
                                <label className="fs-14 clr-navy flex" style={{
                                    marginTop: 10,

                                    background: 'white',


                                }} >


                                    {/* <Button label="View Attachments" className="pi pi-folder-open clr-navy" onClick={() => setModalVisible(true)} />
                                   */}
                                    <a href="#" onClick={(e) => { e.preventDefault(); setModalVisible(true); }}>
                                        View Attachments
                                    </a>
                                </label>
                            )}

                        </div>


                        <input type='file' multiple accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                            id={'fp' + refId} hidden onChange={(e) => { handleFileUpload_HC(e) }} ></input>
                    </div>
                    {(document !== undefined && document !== null && document.length !== 0) ?
                        <div className="col-12" style={{
                            maxHeight: 300,
                            overflow: 'auto',

                        }} >
                            <div style={{

                            }}>
                                {document.map((file, findex) => {
                                    console.log(getFileExtension(file?.originalname || file?.name))
                                    return (
                                        <>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center', margin: 5
                                            }} >
                                                <Dialog header="Attachments" visible={modalVisible} style={{ width: '50vw' }} onHide={() => setModalVisible(false)}>
                                                    {document && document.map((file, index) => (
                                                        <div key={index} style={{ display: 'flex', alignItems: 'center', margin: 5 }}>
                                                            <div className="flex align-items-center text-three-dot col-10">
                                                                {(getFileExtension(file?.originalname || file?.name) === 'pdf') ?
                                                                    <img src={require('../../assets/images/pdf_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                                    (getFileExtension(file?.originalname || file?.name) === 'xlsx' || getFileExtension(file?.originalname || file?.name) === 'xls') ?
                                                                        <img src={require('../../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                                        (getFileExtension(file?.originalname || file?.name) === 'pptx' || getFileExtension(file?.originalname || file?.name) === 'ppt') ?
                                                                            <img src={require('../../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                                            (getFileExtension(file?.originalname || file?.name) === 'docx' || getFileExtension(file?.originalname || file?.name) === 'doc') ?
                                                                                <img src={require('../../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                                                <img alt={file?.originalname || file?.name} role="presentation" src={API.Docs + (file?.originalname || file?.name)} width={100} style={{ borderRadius: 10 }} />}

                                                                {/* File Name as Clickable Link */}
                                                                <span className="flex align-items-center text-left ml-3 clr-navy cur-pointer" onClick={() => { window.open(API.Docs + (file?.originalname || file?.name)) }}>
                                                                    {file?.originalname?.slice(13) || file?.name?.slice(13)}
                                                                    <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />
                                                                </span>
                                                            </div>

                                                            {/* Remove Button */}
                                                            {edit && <div className="col-1">
                                                                <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage_HC(index)} />
                                                            </div>}
                                                        </div>
                                                    ))}
                                                </Dialog>
                                            </div>

                                        </>
                                    )
                                })

                                }
                            </div>
                        </div>
                        :

                        <></>
                    }
                </div>
            </BlockUI>





        </div >
    )
}