
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import { API } from "../../constants/api_url";
import { DateTime } from "luxon";
const initialState = {
    // profile:{id:'12',name:'Venkat',mobno:'0000000'}
    userdetail: {},
    admindetail: null,
    config: [],
    tierLabel: ['Country', 'City', 'Site'],
    fyStartMonth: { fymonth: 1 },
    allRoles: []
}
export const fetchApi = createAsyncThunk('api/fetchApi1', async (token) => {
    return axios.get(API.GetMeNew, { headers: { "Authorization": "Bearer" + " " + token } }).then((res) => res.data)
})
export const fetchApi_TVS = createAsyncThunk('api/fetchApi2', async ({ token, id_token }) => {
    return axios.get(API.TVS_GetMe, {
        headers: {
            "Authorization": "Bearer " + token,
            "x-id-token": id_token
        }
    }).then((res) => res.data)
})

const setData = (state, action) => {

    if (action.payload.role === 'clientuser' || action.payload.role === 'clientadmin' || action.payload.role === 'clientextuser') {
        if (action.payload.information.blocked === undefined || !action.payload.information.blocked) {
            let data = action.payload
            if (data.role === 'clientadmin') {
                data.information['cid'] = data.id
                data.clientId = data.id

                state.admindetail = { ...data }
                state.tierLabel = data.tierLabel ? data.tierLabel : ['Country', 'City', 'Site']
                state.fyStartMonth = data.fyStartMonth ? { fymonth: DateTime.fromISO(data.fyStartMonth, { zone: 'utc' }).month === 12 ? 1 : DateTime.fromISO(data.fyStartMonth, { zone: 'utc' }).month + 1 } : { fymonth: 1 }

            } else {
                let accept_roles = [1, 3, 4,12,13, 17, 18,19, 20]
                let roles = data.roles_data.flatMap(i => i.roles).some(i => accept_roles.includes(i))
                if (roles) {
                    state.allRoles = Array.from(new Set(data.roles_data.flatMap(i => i.roles)))
                    let client_data = data.admin ? data.admin : { tierLabel: ['Country', 'City', 'Site'] }
                    state.tierLabel = client_data.tierLabel ? client_data.tierLabel : ['Country', 'City', 'Site']
                    state.fyStartMonth = client_data.fyStartMonth ? { fymonth: DateTime.fromISO(client_data.fyStartMonth, { zone: 'utc' }).month === 12 ? 1 : DateTime.fromISO(client_data.fyStartMonth, { zone: 'utc' }).month + 1 } : { fymonth: 1 }
                    state.admindetail = data.admin ? data.admin : null
                } else {
                    localStorage.removeItem("token");
                    Swal.fire({
                        icon: 'warning',
                        title: 'Alert',
                        text: 'Seems You Have not Assigned User Role for Access, Contact Your Organization Platform Support Team For Futher Details',
                        returnInputValueOnDeny: () => {
                            console.log('deny')
                        }
                    })
                }
            }
            state.userdetail = data;

        } else {
            localStorage.removeItem("token");
            Swal.fire({
                icon: 'error',
                title: 'Blocked',
                text: 'You have been blocked from platform, contact admin for futher details',
                returnInputValueOnDeny: () => {
                    console.log('deny')
                }
            })

        }
    } else {
        localStorage.removeItem("token");
        Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You have no access to this platform, contact admin for futher details',
            returnInputValueOnDeny: () => {
                console.log('deny')
            }
        })
    }


}

const userProfile = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedUserDetail: (state, action) => { state.userdetail = action.payload },
        resetLoggedUserDetail: (state) => {
            localStorage.removeItem('token');
            sessionStorage.clear(); state.userdetail = {}; state.allRoles = [];
        }, resetLoggedUserDetailRedirect: (state) => {

            sessionStorage.clear(); state.userdetail = {}; state.allRoles = [];
        },

    },
    extraReducers: (builder) => {

        builder.addCase(fetchApi.fulfilled, (state, action) => { setData(state, action) })
            .addCase(fetchApi_TVS.fulfilled, (state, action) => { setData(state, action) })



    }



})

export default userProfile.reducer
export const { setLoggedUserDetail, resetLoggedUserDetail, resetLoggedUserDetailRedirect } = userProfile.actions
