import XlsxPopulate from 'xlsx-populate';

const DCF363Template = async () => {
    const data = [
        { title: 'Fatal Accidents among', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'Permanent Disability due to work related accidents among', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'High Consequence LTI among', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'Lost Time Injuries among', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'RWC among', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'MTC among', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'First aid injury', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'Near Miss Incidents among', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'Total Working days lost due to work related injuries among', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'Total Man Hours worked by', fields: ['Male executive Managers', 'Female executive Managers', 'Male Union employees', 'Female Union employees', 'Male Temporary workman', 'Female Temporary workman', 'Male Contractors', 'Female Contractors'] },
        { title: 'Fire Incidents', fields: ['Total Number of major fire accidents happened in the reporting period', 'Total Number of minor fire accidents that happened in the reporting period'] }
    ];

    // Create workbook
    const workbook = await XlsxPopulate.fromBlankAsync();
    const guidanceSheet = workbook.sheet('Sheet1').name('Guidance');

    const guidanceData = [
        ['Bulk data entry sheet- Health and Safety details'],
        ['Instructions: '],
        ['Please enter the number of employees who received performance and career development reviews in the DATA ENTRY.'],
        ['If you find any issues, please drop a note to support@eisqr.com. We will review and update the sheet.']
    ];

    guidanceData.forEach((row, rowIdx) => {
        row.forEach((cell, colIdx) => {
            const cellRef = guidanceSheet.cell(rowIdx + 1, colIdx + 1);
            cellRef.value(cell)
                .style({
                    bold: rowIdx === 0, // Make the header row bold
                    fontColor: rowIdx === 0 ? 'FF0000' : undefined, // Red color for first row
                    wrapText: true,
                    border: true,
                    horizontalAlignment: 'left',
                    verticalAlignment: 'center'
                });
        });
    });
    // ===== Create Data Entry Sheet =====
    const dataEntrySheet =  workbook.addSheet('Data Entry');

    let rowIndex = 2; // Start from row 2

    data.forEach(({ title, fields }) => {
        // Add title and "nos"
        dataEntrySheet.cell(`B${rowIndex}`).value(title)
            .style({
                bold: true,
                fill: 'd9d9d9', // Light gray background
                border: true,
                horizontalAlignment: 'center',
                verticalAlignment: 'center'
            });

        dataEntrySheet.cell(`C${rowIndex}`).value('nos')
            .style({
                bold: true,
                fill: 'd9d9d9',
                border: true,
                horizontalAlignment: 'center',
                verticalAlignment: 'center'
            });

        rowIndex++;

        // Add fields below the title
        fields.forEach(field => {
            dataEntrySheet.cell(`B${rowIndex}`).value(field)
                .style({
                    border: true,
                    horizontalAlignment: 'left',
                    verticalAlignment: 'center'
                });

            dataEntrySheet.cell(`C${rowIndex}`).style({
                border: true
            });

            rowIndex++;
        });
    });

    // ===== Create Guidance Sheet =====


    // ===== Adjust Column Widths =====
    dataEntrySheet.column('B').width(50);
    dataEntrySheet.column('C').width(15);

    guidanceSheet.column('A').width(100);
    guidanceSheet.column('B').width(100);
    guidanceSheet.column('C').width(100);

    // ===== Write to file =====
    const buffer = await workbook.outputAsync();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Employee Health and Safety details.xlsx';
    link.click();
};

export {DCF363Template}

